import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1.75)
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowSpacing: {
    marginTop: theme.spacing(1.75)
  },
  text: {
    color: theme.palette.common.white
  },
  infoIcon: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(3)
  }
}));

export const DelaLoadingTooltip = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <InfoIcon className={classes.infoIcon} />
      </div>
      <div className={classes.column}>
        <div className={classes.row}>
          <Typography className={classes.text} variant="subtitle1">
            <FormattedMessage id="attachments.delaAnalyzing" />
          </Typography>
        </div>
        <div className={classnames(classes.row, classes.rowSpacing)}>
          <Typography className={classes.text} variant="body2">
            <FormattedMessage id="attachments.delaTimeToFinish" />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DelaLoadingTooltip;
