import ForwardIcon from '@material-ui/icons/ForwardSharp';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  AddResourceIcon,
  ApproveIcon,
  RejectIcon
} from '~/modules/common/components/Icons';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useIsBreakpointUp } from '~/modules/common/hooks';
import { hasPermission } from '~/modules/common/permissions/useHasPermission';
import { useMeContext } from '~/modules/me/useMeContext';
import { ResourceRequestStatus } from '~/types';

export const supporedProjectPolicyRoles = [
  USER_ACCESS_ROLE.CLIENT_MANAGER,
  USER_ACCESS_ROLE.PORTFOLIO_ADMIN,
  USER_ACCESS_ROLE.PORTFOLIO_MANAGER,
  USER_ACCESS_ROLE.PROGRAM_MANAGER,
  USER_ACCESS_ROLE.PROJECT_ADMIN,
  USER_ACCESS_ROLE.PROJECT_MANAGER
];

export const useResourceRequestAllocationActions = ({
  isProjectResourcingTab,
  canEditProject,
  canEditTeam,
  resourceRequest,
  onResourceRequestSubmit,
  onResourceRequestSplit,
  onResourceRequestAccept,
  onResourceRequestAllocationReject,
  onResourceRequestUnsubmit,
  onResourceRequestDuplicate,
  openRemoveRequestDialog,
  openReleaseResourcesDialog,
  openRemoveAllocationDialog,
  onResourceRequestFulfilled,
  onAssignResourceAction,
  onResourceRequestReject,
  onResourceRequestToBeHired,
  onResourceRequestMerge,
  onResourceRequestProposeTopMatch,
  mergeDialogState = null,
  isResourceRequestMergeEnabled
}) => {
  const intl = useIntl();
  const isMDViewAndUp = useIsBreakpointUp('md');
  const {
    userAccessRoles,
    permissionsMap,
    featureFlags: {
      isPsaRmpTaskAllocation1Enabled,
      isPsaPraaResourcingUIEnabled
    }
  } = useMeContext();

  const isViewUserResourceManagerEnabled = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri: 'urn:replicon:user-data-access-level:resource-manager'
  });

  const isViewUserResourcePoolManagerEnabled = hasPermission(permissionsMap)({
    actionUri: 'urn:replicon:user-action:view-user',
    dataAccessLevelUri:
      'urn:replicon:user-data-access-level:resource-pool-manager'
  });

  const isViewUserEnabled =
    isViewUserResourceManagerEnabled || isViewUserResourcePoolManagerEnabled;

  const canEditResourceAssignments = hasPermission(permissionsMap)({
    actionUri:
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
  });

  const isUserHasProjectPolicyRole = supporedProjectPolicyRoles.some(sr =>
    userAccessRoles.includes(sr)
  );

  const isUserHasResourceManagerRole = userAccessRoles.includes(
    USER_ACCESS_ROLE.RESOURCE_MANAGER
  );

  const submitAction = {
    icon: !isUserHasResourceManagerRole ? <ForwardIcon /> : null,
    className: !isUserHasResourceManagerRole ? 'submitIcon' : '',
    label: intl.formatMessage({ id: 'resourceRequestActions.submitRequest' }),
    onClick: onResourceRequestSubmit,
    primary: true
  };

  const proposeResourceAction = {
    icon: <ForwardIcon />,
    className: 'submitIcon',
    label: intl.formatMessage({
      id: 'resourceAllocationActions.proposeResources'
    }),
    onClick: onResourceRequestFulfilled,
    primary: true,
    disabled:
      resourceRequest.resourceAllocations.length === 0 ||
      resourceRequest.resourceAllocations.some(ra => !ra.user.userUri)
  };

  const assignResourceAction = {
    icon: resourceRequest.requestStatus === ResourceRequestStatus.Submitted && (
      <AddResourceIcon />
    ),
    onClick: onAssignResourceAction,
    label: intl.formatMessage({
      id: 'resourceAllocationActions.allocateResource'
    }),
    primary: resourceRequest.requestStatus === ResourceRequestStatus.Submitted
  };

  const rejectRequestAction = {
    label: intl.formatMessage({
      id: 'resourceAllocationActions.rejectRequest'
    }),
    onClick: onResourceRequestReject
  };

  const removeAllResourcesAction = {
    label: intl.formatMessage({
      id: 'resourceAllocationActions.removeAllResources'
    }),
    onClick: openRemoveAllocationDialog
  };

  const recallAction = {
    label: intl.formatMessage({
      id: 'resourceAllocationActions.recall'
    }),
    onClick: onResourceRequestSubmit
  };

  const toBeHiredAction = {
    label: intl.formatMessage({
      id: 'resourceAllocationActions.toBeHired'
    }),
    onClick: onResourceRequestToBeHired
  };

  const cancelRequestRejection = {
    label: intl.formatMessage({
      id: 'resourceAllocationActions.cancelRequestRejection'
    }),
    onClick: onResourceRequestSubmit
  };

  const splitAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.splitRequest'
    }),
    onClick: onResourceRequestSplit
  };

  const proposeTopMatchAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.proposeTopMatch'
    }),
    onClick: onResourceRequestProposeTopMatch
  };

  const isPrimary =
    resourceRequest.requestStatus !== ResourceRequestStatus.Allocationrejected;

  const acceptAction = {
    icon: <ApproveIcon />,
    className: 'approveIcon',
    label: intl.formatMessage({
      id: 'resourceRequestActions.acceptResources'
    }),
    onClick: isResourceRequestMergeEnabled
      ? onResourceRequestMerge
      : onResourceRequestAccept,
    primary: isPrimary
  };

  const rejectResourcesAction = {
    icon: <RejectIcon />,
    className: 'rejectIcon',
    label: intl.formatMessage({
      id: 'resourceRequestActions.rejectResources'
    }),
    onClick: onResourceRequestAllocationReject,
    primary: true
  };

  const unsubmitAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.unsubmitRequest'
    }),
    onClick: onResourceRequestUnsubmit
  };

  const removeRequestAction = {
    className: 'removeIcon',
    label: intl.formatMessage({
      id: 'resourceRequestActions.removeRequest'
    }),
    onClick: openRemoveRequestDialog
  };

  const duplicateAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.duplicateRequest'
    }),
    onClick: onResourceRequestDuplicate
  };

  const releaseResourcesAction = {
    label: intl.formatMessage({
      id: 'resourceRequestActions.releaseResources'
    }),
    onClick: openReleaseResourcesDialog
  };

  const cancelToBeHiredAction = {
    label: intl.formatMessage({
      id: 'resourceAssignmentDialog.cancelToBeHired'
    }),
    onClick: onResourceRequestSubmit
  };

  const canAllocateResources = isViewUserEnabled && canEditResourceAssignments;

  const showAllocateResourcesButton =
    isMDViewAndUp && canAllocateResources && isUserHasResourceManagerRole;

  const allocateResourcesButtonActionForDraftRequest = async () => {
    await onResourceRequestSubmit();
    onAssignResourceAction();
  };

  switch (resourceRequest.requestStatus) {
    case ResourceRequestStatus.Submitted:
      return isUserHasProjectPolicyRole
        ? [
            ...(isUserHasResourceManagerRole
              ? [
                  ...(showAllocateResourcesButton
                    ? [assignResourceAction]
                    : []),
                  {
                    ...rejectRequestAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  ...(canEditResourceAssignments
                    ? [
                        {
                          ...toBeHiredAction,
                          disabled:
                            resourceRequest.resourceAllocations.length > 0
                        },
                        {
                          ...removeAllResourcesAction,
                          disabled:
                            resourceRequest.resourceAllocations.length === 0
                        },
                        ...(isPsaPraaResourcingUIEnabled
                          ? [
                              {
                                ...proposeTopMatchAction,
                                disabled: !resourceRequest.resourceMatches
                                  ?.length
                              }
                            ]
                          : [])
                      ]
                    : [])
                ]
              : []),
            ...(canEditProject && !isProjectResourcingTab
              ? [duplicateAction]
              : []),
            ...(canEditProject
              ? [
                  {
                    ...unsubmitAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  removeRequestAction
                ]
              : [])
          ]
        : [
            ...(canEditResourceAssignments ? [proposeResourceAction] : []),
            ...(showAllocateResourcesButton ? [assignResourceAction] : []),
            ...(canEditResourceAssignments
              ? [
                  {
                    ...rejectRequestAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  {
                    ...toBeHiredAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  {
                    ...removeAllResourcesAction,
                    disabled: resourceRequest.resourceAllocations.length === 0
                  }
                ]
              : [])
          ];
    case ResourceRequestStatus.Tobehired:
      return isUserHasProjectPolicyRole
        ? [
            ...(isUserHasResourceManagerRole
              ? [
                  ...(canEditResourceAssignments || canEditProject
                    ? [cancelToBeHiredAction]
                    : []),
                  {
                    ...rejectRequestAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  }
                ]
              : []),
            ...(canEditProject && !isProjectResourcingTab
              ? [duplicateAction]
              : []),
            ...(canEditProject
              ? [
                  {
                    ...unsubmitAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  removeRequestAction
                ]
              : [])
          ]
        : [
            ...(canEditResourceAssignments || canEditProject
              ? [cancelToBeHiredAction]
              : []),
            {
              ...rejectRequestAction,
              disabled: resourceRequest.resourceAllocations.length > 0
            }
          ];
    case ResourceRequestStatus.Tentative:
      return isUserHasProjectPolicyRole
        ? [
            ...(canEditProject && canEditTeam
              ? [
                  {
                    ...rejectResourcesAction,
                    primary: true,
                    disabled:
                      isResourceRequestMergeEnabled &&
                      mergeDialogState?.existingAllocationState
                        ?.hasExistingAllocationLoading
                  },
                  {
                    ...acceptAction,
                    primary: true,
                    disabled:
                      isResourceRequestMergeEnabled &&
                      mergeDialogState?.existingAllocationState
                        ?.hasExistingAllocationLoading
                  }
                ]
              : []),
            ...(canEditProject &&
            !isProjectResourcingTab &&
            isPsaRmpTaskAllocation1Enabled
              ? [duplicateAction]
              : [])
          ]
        : [
            ...(canEditResourceAssignments || canEditProject
              ? [recallAction]
              : [])
          ];
    case ResourceRequestStatus.Allocationrejected:
      return isUserHasProjectPolicyRole
        ? [
            ...(isUserHasResourceManagerRole
              ? [
                  ...(showAllocateResourcesButton
                    ? [assignResourceAction]
                    : []),
                  ...(canEditResourceAssignments
                    ? [
                        {
                          ...rejectRequestAction,
                          disabled:
                            resourceRequest.resourceAllocations.length > 0
                        },
                        {
                          ...toBeHiredAction,
                          disabled:
                            resourceRequest.resourceAllocations.length > 0
                        },
                        {
                          ...removeAllResourcesAction,
                          disabled:
                            resourceRequest.resourceAllocations.length === 0
                        }
                      ]
                    : [])
                ]
              : []),
            ...(!isUserHasResourceManagerRole
              ? [
                  ...(canEditProject
                    ? [{ ...acceptAction, primary: true }]
                    : [])
                ]
              : []),
            ...(canEditProject && !isProjectResourcingTab
              ? [duplicateAction]
              : [])
          ]
        : [
            ...(canEditResourceAssignments
              ? [
                  {
                    ...proposeResourceAction,
                    disabled: resourceRequest.resourceAllocations.length === 0
                  }
                ]
              : []),
            ...(showAllocateResourcesButton ? [assignResourceAction] : []),
            ...(canEditResourceAssignments
              ? [
                  {
                    ...rejectRequestAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  {
                    ...toBeHiredAction,
                    disabled: resourceRequest.resourceAllocations.length > 0
                  },
                  {
                    ...removeAllResourcesAction,
                    disabled: resourceRequest.resourceAllocations.length === 0
                  }
                ]
              : [])
          ];
    case ResourceRequestStatus.Rejected:
      return isUserHasProjectPolicyRole
        ? [
            ...(isUserHasResourceManagerRole
              ? [
                  cancelRequestRejection,
                  ...(canEditResourceAssignments ? [toBeHiredAction] : []),
                  ...(showAllocateResourcesButton ? [assignResourceAction] : [])
                ]
              : []),
            ...(canEditProject
              ? [{ ...submitAction, primary: !isUserHasResourceManagerRole }]
              : []),
            ...(canEditProject && resourceRequest.quantity > 1
              ? [splitAction]
              : []),
            ...(canEditProject && !isProjectResourcingTab
              ? [duplicateAction]
              : []),
            ...(canEditProject ? [removeRequestAction] : [])
          ]
        : [
            cancelRequestRejection,
            ...(canEditResourceAssignments ? [toBeHiredAction] : []),
            ...(showAllocateResourcesButton ? [assignResourceAction] : [])
          ];
    case ResourceRequestStatus.Complete:
      return isUserHasProjectPolicyRole
        ? [
            releaseResourcesAction,
            ...(canEditProject && !isProjectResourcingTab
              ? [duplicateAction]
              : [])
          ]
        : [];
    default:
      return isUserHasProjectPolicyRole
        ? [
            ...(showAllocateResourcesButton
              ? [
                  {
                    ...assignResourceAction,
                    onClick: allocateResourcesButtonActionForDraftRequest
                  }
                ]
              : []),
            ...(canEditProject
              ? [{ ...submitAction, primary: !isUserHasResourceManagerRole }]
              : []),
            ...(isUserHasResourceManagerRole
              ? [...(canEditResourceAssignments ? [toBeHiredAction] : [])]
              : []),
            ...(canEditProject &&
            resourceRequest.requestStatus === ResourceRequestStatus.Draft &&
            resourceRequest.quantity > 1
              ? [splitAction]
              : []),
            ...(canEditProject && !isProjectResourcingTab
              ? [duplicateAction]
              : []),
            ...(canEditProject ? [removeRequestAction] : [])
          ]
        : [];
  }
};

export default useResourceRequestAllocationActions;
