import React from 'react';
import { makeStyles } from '@material-ui/core';
import { notAuthorized, slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotAuthorized } from '~/modules/common/notAuthorized';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { useVendorPageEditContext } from '../../VendorPageEditContext';
import { VendorBasicInfo } from './VendorBasicInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    minHeight: '-webkit-max-content'
  },
  basicInfo: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexBasis: 200
    }
  }
}));

export const VendorInfo = () => {
  const classes = useStyles();
  const { error, loading, vendor, edit } = useVendorPageEditContext();

  if (error)
    return (
      (slugNotFound({ data: { error } }) && <RedirectToNotFound />) ||
      (notAuthorized({ data: { error } }) && <RedirectToNotAuthorized />)
    );

  return (
    <div className={classes.root}>
      <div className={classes.basicInfo}>
        <VendorBasicInfo edit={edit} vendor={vendor} loading={loading} />
      </div>
    </div>
  );
};

VendorInfo.propTypes = {};

export default VendorInfo;
