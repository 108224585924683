import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Container
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useMeContext } from '~/modules/me';
import SearchField from '~/modules/common/components/SearchField';
import CancelButton from '~/modules/common/components/CancelButton';
import { useIsBreakpointDown, useSessionStorage } from '~/modules/common/hooks';
import { SubstituteUserDialogContent } from './components';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0)
  },
  searchBox: {
    padding: theme.spacing(0)
  }
}));

export const SubstituteUsersDialog = ({ open, closeDialog }) => {
  const classes = useStyles();
  const { substituteUsers, uiRootPath } = useMeContext();

  const [filteredUsers, setFilteredUsers] = useState(substituteUsers);
  const [searchText, setSearchText] = useState('');
  const fullScreen = useIsBreakpointDown('sm');
  const onSearchChange = useCallback(
    event => {
      const searchTxt = event.target && event.target.value;

      setSearchText(searchTxt);
      const matches = substituteUsers.filter(
        u => u.displayText.toLowerCase().indexOf(searchTxt.toLowerCase()) >= 0
      );

      setFilteredUsers(matches);
    },
    [substituteUsers]
  );

  const [isSubstituting, setIsSubstituting] = useState(false);

  const { setValue: setCurrentView } = useSessionStorage('current-view', null);
  const location = useLocation();

  const handleSubmit = useCallback(
    user => {
      setCurrentView(location);
      setIsSubstituting(true);
      window.location = `${uiRootPath}Substitute?switchSubusersSelectValue=${encodeURIComponent(
        user.uri
      )}`;
    },
    [uiRootPath, setCurrentView, setIsSubstituting]
  );

  const searchFieldClasses = useMemo(
    () => ({ searchField: classes.searchBox }),
    [classes.searchBox]
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="entity-confirmation-dialog-title"
      aria-describedby="entity-confirmation-dialog-description"
      maxWidth="sm"
      fullScreen={fullScreen}
    >
      <DialogTitle
        id="entity-confirmation-dialog-title"
        className={classes.title}
      >
        <FormattedMessage id="substituteUsersDialog.title" />
        <Container className={classes.searchContainer}>
          <SearchField
            value={searchText}
            onChange={onSearchChange}
            classes={searchFieldClasses}
          />
        </Container>
      </DialogTitle>
      <SubstituteUserDialogContent
        users={filteredUsers}
        onClick={handleSubmit}
      />
      <DialogActions>
        <CancelButton onClick={closeDialog} disabled={isSubstituting} />
      </DialogActions>
    </Dialog>
  );
};

SubstituteUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired
};

export default SubstituteUsersDialog;
