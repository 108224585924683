import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  name: {
    padding: 0,
    fontSize: theme.typography.caption.fontSize
  },
  assignResourceButton: {
    paddingLeft: theme.spacing(1),
    flexShrink: 1,
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    height: '100%',
    width: '100%',
    justifyContent: 'left'
  },
  delaAssignResourceButton: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'inherit',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px'
  },
  noResource: {
    flexGrow: 1,
    flexShrink: 1,
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    height: '100%'
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    margin: [['-0.28em', '0.28em', '-0.28em', 0]],
    width: '1.95em',
    height: '1.95em',
    flexGrow: 0,
    fontSize: theme.typography.caption.fontSize,
    alignSelf: 'center',
    flexShrink: 0
  },
  userDropDownName: {
    fontSize: theme.typography.caption.fontSize
  },
  noMatchFound: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center'
  },
  resourceAllocationSpacing: {
    paddingLeft: theme.spacing(1)
  },
  infoIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(0.5),
    fontSize: theme.spacing(2)
  }
}));
