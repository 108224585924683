import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';

import EditClientDialogActions from './EditClientDialogActions';
import EditClientRepresentativesDialogForm from './EditClientRepresentativesDialogForm';
import { useUpdateClientRepresentativesOnSubmit } from './useUpdateClientRepresentativesOnSubmit';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const title = <FormattedMessage id="client.clientRepresentatives" />;

const clientRepresentativeDialogRole = {
  role: 'presentation'
};

export const EditClientRepresentativesDialog = ({ open, onClose, details }) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  const { onSubmit } = useUpdateClientRepresentativesOnSubmit(onClose);

  const { id, clientRepresentatives } = details;

  const initialValues = useMemo(
    () => ({
      id,
      clientRepresentatives
    }),
    [clientRepresentatives, id]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        fullScreen={isMobile}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionProps={clientRepresentativeDialogRole}
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <DescriptionIcon className={classes.icon} />
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <EditClientRepresentativesDialogForm
            clientRepresentatives={clientRepresentatives}
          />
        </DialogContent>
        <EditClientDialogActions onClose={onClose} />
      </Dialog>
    </Formik>
  );
};

EditClientRepresentativesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  details: PropTypes.object
};

export default EditClientRepresentativesDialog;
