import get from 'lodash.get';
import { withIntlProvider } from '~/modules/intl';
import compose from '~/util/compose';
import { injectMe } from '~/modules/me';
import { injectCurrencies } from '~/modules/common/components/Money/Currency/withCurrencies';
import UnauthorizedPage from '~/modules/common/unauthorized';
import RootLoader from '~/modules/common/components/RootLoader';
import { withTitleProvider } from '~/modules/common/title';
import { withSidePanelStateProvider } from '../navigation/components/SidePanel';
import withDnd from './withDnd';
import withApollo from './withApollo';
import withRootTheme from './withRootTheme';
import withMuiPickers from './withMuiPickers';

const withAppProviders = compose(
  withDnd,
  withApollo,
  injectMe({
    SpecificErrorHandlers: [
      {
        predicate: (error): boolean =>
          get(error, 'networkError.statusCode') === 403,
        Component: UnauthorizedPage
      }
    ],
    Loading: RootLoader
  }),
  withRootTheme,
  injectCurrencies,
  withIntlProvider,
  withMuiPickers,
  withTitleProvider,
  withSidePanelStateProvider
);

export default withAppProviders;
