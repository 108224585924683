import React from 'react';
import { Grid } from '@material-ui/core';
import FormFieldShimmer from '~/modules/common/components/FormFieldShimmer';

import FormContainer from './FormContainer';

const FormLoading = () => (
  <FormContainer>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormFieldShimmer />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormFieldShimmer />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormFieldShimmer />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormFieldShimmer />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormFieldShimmer />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormFieldShimmer />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormFieldShimmer />
      </Grid>
    </Grid>
  </FormContainer>
);

export default FormLoading;
