import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  PROJECTS_SUMMARY_FILTER_TABS,
  PROJECT_EXECUTION_PHASE,
  PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';

import { mapProjectSearchFilter } from './useProjectFilterMemo.util';

export const useProjectFilterMemo = ({
  searchCriteria,
  quickFilter,
  excludePrograms,
  excludePortfolios,
  nonLinkedProjectsForProjectId,
  includeBaseLinkInList
}) => {
  const { userAccessRoles } = useMeContext();

  return useMemo(() => {
    const enableAllAccess =
      userAccessRoles.includes(USER_ACCESS_ROLE.BILLING_MANAGER) ||
      userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN);

    const searchFilter = mapProjectSearchFilter({
      searchCriteria
    });

    const isInDataAccessLevels = enableAllAccess
      ? undefined
      : [
          'urn:replicon:project-data-access-level:project-management-data-access-scope',
          'urn:replicon:project-data-access-level:project-manager',
          'urn:replicon:project-data-access-level:client-manager',
          'urn:replicon:project-data-access-level:program-manager',
          'urn:replicon:project-data-access-level:portfolio-manager'
        ];
    const nonLinkedProjectFilter = nonLinkedProjectsForProjectId
      ? {
          baseProject: nonLinkedProjectsForProjectId,
          inverseLinkFilter: true
        }
      : {};

    if (quickFilter) {
      return {
        projectFilter: {
          ...searchFilter.projectFilter,
          includeMyProjectsOnly:
            quickFilter.filterByAccess ===
              PROJECTS_SUMMARY_FILTER_TABS.MY_PROJECTS &&
            quickFilter.filterByStatus !== PROJECT_EXECUTION_PHASE.ARCHIVED,
          includeSubPortfolios:
            quickFilter.filterByAccess ===
              PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS &&
            quickFilter.filterByStatus !== PROJECT_EXECUTION_PHASE.ARCHIVED,
          executionPhase: quickFilter.filterByStatus
            ? [quickFilter.filterByStatus]
            : [
                PROJECT_EXECUTION_PHASE.INITIATE,
                PROJECT_EXECUTION_PHASE.EXECUTION,
                PROJECT_EXECUTION_PHASE.PLANNING,
                PROJECT_EXECUTION_PHASE.CLOSEOUT
              ],
          excludePrograms,
          excludePortfolios,
          isInDataAccessLevels,
          includeBaseLinkFilter: includeBaseLinkInList,
          ...nonLinkedProjectFilter
        }
      };
    }

    return {
      ...searchFilter,
      projectFilter: {
        ...searchFilter.projectFilter,
        excludePrograms,
        excludePortfolios,
        isInDataAccessLevels,
        includeBaseLinkFilter: includeBaseLinkInList,
        ...nonLinkedProjectFilter
      }
    };
  }, [
    userAccessRoles,
    searchCriteria,
    nonLinkedProjectsForProjectId,
    quickFilter,
    excludePrograms,
    excludePortfolios,
    includeBaseLinkInList
  ]);
};
