import editBillDrawerRoute from '~/modules/billing-invoicing/bill/components/EditBillDrawer/editBillDrawerRoute';
import editBillPaymentDrawerRoute from '~/modules/billing-invoicing/billPayment/components/EditBillPaymentDrawer/editBillPaymentDrawerRoute';
import editCreditMemoDrawerRoute from '~/modules/billing-invoicing/creditMemo/components/EditCreditMemoDrawer/editCreditMemoDrawerRoute';
import projectRequestDrawerRoute from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestDrawer/projectRequestDrawerRoute';
import resourceRequestDrawerRoute from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestDrawer/resourceRequestDrawerRoute';
import resourceDrawerRoute from '~/modules/resource-management/ResourceDrawer/resourceDrawerRoute';
import { projectDrawerRoute } from '../ProjectDrawer';
import { taskDrawerRoute } from '../TaskDrawer';

// add drawer routes here
const routes = [
  taskDrawerRoute,
  resourceRequestDrawerRoute,
  editBillDrawerRoute,
  editCreditMemoDrawerRoute,
  editBillPaymentDrawerRoute,
  resourceDrawerRoute,
  projectDrawerRoute,
  projectRequestDrawerRoute
];

export const useDrawerRoutes = () => ({ routes });
