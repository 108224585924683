import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { LoadingButton, CancelButton } from '~/modules/common/components';
import AddRoleDialogForm from './AddRoleDialogForm';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1, 2)
  },
  title: {
    color: theme.palette.text.primary,
    backgroundColor: 'inherit'
  }
}));

const addDialogRole = {
  role: 'presentation'
};

export const AddRoleDialogContent = ({ open, onClose, onCancel }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    isSubmitting,
    errors,
    handleReset
  } = useFormikContext();
  const fullScreen = useIsBreakpointDown('xs');

  const onCloseCallBack = useCallback(() => {
    if (onCancel && onCancel()) onCancel();
    onClose();
    handleReset();
  }, [onCancel, onClose, handleReset]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      TransitionProps={addDialogRole}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.title}>
        <FormattedMessage id="addRoleDialog.addRole" />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <AddRoleDialogForm />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCloseCallBack} />
        <LoadingButton
          onClick={handleSubmit}
          color="primary"
          disabled={isSubmitting || Object.keys(errors).length > 0}
          isLoading={isSubmitting}
        >
          <FormattedMessage id="addRoleDialog.addRole" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AddRoleDialogContent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func
};

export default AddRoleDialogContent;
