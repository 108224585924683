import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BULK_MARK_ATTACHMENT_AS_READ = gql`
  mutation useBulkMarkAttachmentAsRead($binaryObjectUris: [String!]!) {
    bulkMarkAttachmentAsRead(binaryObjectUris: $binaryObjectUris)
  }
`;

export const useBulkMarkAttachmentAsRead = () => {
  const [bulkMarkAttachmentAsRead] = useMutation(BULK_MARK_ATTACHMENT_AS_READ);

  return {
    bulkMarkAttachmentAsRead: ({ binaryObjectUris }) =>
      bulkMarkAttachmentAsRead({
        variables: {
          binaryObjectUris
        }
      })
  };
};

export default useBulkMarkAttachmentAsRead;
