import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Decimal, DateRange } from '~/modules/common/components';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'normal'
  },
  negativeValue: {
    color: theme.palette.error.light
  },
  row: {
    display: 'flex',
    width: '100%'
  },
  labelColumn: {
    flex: '1 1 90%',
    paddingRight: theme.spacing(1)
  },
  valueColumn: {
    flex: '1 1 10%'
  }
}));

const useDateRangeStyles = makeStyles(theme => ({
  date: {
    fontSize: 'unset',
    fontWeight: theme.typography.fontWeightBold
  },
  range: {
    fontSize: 'unset'
  }
}));
const useDividerStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(0.5, 0)
  }
}));

const TooltipLayout = ({ startDate, endDate, calculationRows, resultRow }) => {
  const classes = useStyles();
  const dividerClasses = useDividerStyles();
  const dateRangeClasses = useDateRangeStyles();
  const { formatMessage } = useIntl();

  const hoursSuffix = formatMessage({
    id: 'taskAllocationEditor.hoursSuffix'
  });

  const [{ messageId, value }] = resultRow;

  return (
    <div>
      <DateRange start={startDate} end={endDate} classes={dateRangeClasses} />
      <div className={classes.container}>
        {calculationRows.map(row => (
          <div className={classes.row} key={`${row.messageId} - ${row.value}`}>
            <div className={classes.labelColumn}>
              <FormattedMessage id={row.messageId} />
            </div>
            <div className={classes.valueColumn}>
              <Decimal
                value={roundToDecimals(row.value)}
                suffix={hoursSuffix}
              />
            </div>
          </div>
        ))}
      </div>
      <Divider className={dividerClasses.root} />
      <div className={classes.container}>
        <div className={classes.row} key={`${messageId} - ${value}`}>
          <div className={classes.labelColumn}>
            <FormattedMessage id={messageId} />
          </div>
          <div
            className={classNames(classes.valueColumn, {
              [classes.negativeValue]: value < 0
            })}
          >
            <Decimal value={roundToDecimals(value)} suffix={hoursSuffix} />
          </div>
        </div>
      </div>
    </div>
  );
};

TooltipLayout.propTypes = {
  endDate: PropTypes.object,
  startDate: PropTypes.object,
  calculationRows: PropTypes.array,
  resultRow: PropTypes.array
};

export default TooltipLayout;
