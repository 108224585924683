import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { DialogContent, Grid, Typography, makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';
import { ResourceRoleGridItemReadOnly } from '../ResourceRoleGridItem/ResourceRoleGridItem';
import { TaskAllocationCard } from '../TaskAllocationCard/TaskAllocationCard';

export const useStyles = makeStyles(theme => ({
  resourceRole: {
    paddingBottom: theme.spacing(1.5)
  },
  message: {
    padding: theme.spacing(1, 0)
  },
  caption: {
    paddingTop: theme.spacing(1)
  },
  removeAssignment: {
    paddingBottom: theme.spacing(2)
  },
  extendLink: {
    cursor: 'pointer'
  }
}));

const RemoveTaskAllocationDialogContent = ({ onClose }) => {
  const classes = useStyles();
  const {
    task,
    resourceEstimate,
    dialogProps: { openRemoveEstimateDialog }
  } = useTaskEstimateRowContext();

  const onRemoveAssignmentClick = useCallback(() => {
    openRemoveEstimateDialog();
    onClose();
  }, [openRemoveEstimateDialog, onClose]);

  const { handleKeyDown } = useKeyBoardEventHandlers({
    onEnterClick: onRemoveAssignmentClick
  });

  const onKeyDown = useCallback(
    event => {
      if (event?.key === 'Enter') {
        event.preventDefault();
        handleKeyDown(event);
      } else {
        handleKeyDown(event);
      }
    },
    [handleKeyDown]
  );

  return (
    <DialogContent id="remove-task-allocation-dialog-description" role="alert">
      <Grid container className={classes.resourceRole}>
        <ResourceRoleGridItemReadOnly
          canEditTasks={false}
          resourceEstimate={resourceEstimate}
        />
      </Grid>
      <Grid container spacing={2}>
        <TaskAllocationCard
          resourceEstimate={resourceEstimate}
          task={task}
          canEditTasks={false}
          isReadOnlyView
        />
      </Grid>
      <Typography variant="body1" className={classes.message}>
        <FormattedMessage id="removeTaskAllocationDialog.message" />
      </Typography>
      <Typography
        variant="caption"
        component="p"
        color="textSecondary"
        className={classes.caption}
      >
        <strong>
          <FormattedMessage id="removeTaskAllocationDialog.captionNote" />
        </strong>
        <FormattedMessage id="removeTaskAllocationDialog.caption" />
      </Typography>
      <Typography
        variant="caption"
        component="p"
        color="textSecondary"
        className={classes.removeAssignment}
      >
        <Link
          className={classes.extendLink}
          onClick={onRemoveAssignmentClick}
          onKeyDown={onKeyDown}
          role="button"
          tabIndex={0}
          aria-label="remove-task-assignment-link"
        >
          <FormattedMessage id="removeTaskAllocationDialog.removeAssignmentLink" />
        </Link>
        &nbsp;{' '}
        <FormattedMessage id="removeTaskAllocationDialog.removeAssignmentMessage" />
      </Typography>
    </DialogContent>
  );
};

RemoveTaskAllocationDialogContent.propTypes = {
  onClose: PropTypes.func
};

export default RemoveTaskAllocationDialogContent;
