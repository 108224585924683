import { GET_PROJECT_REQUEST_TOTALS_QUERY } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks/useProjectRequestTotals';
import { useSessionStorage } from '~/modules/common/hooks';
import { GET_PROJECT_REQUEST_TOTALS_QUERY_VARIABLES_KEY } from '~/modules/project-request/ProjectRequestPage/hooks/constants';

export const useFetchProjectRequestTotals = apolloClient => {
  const { storedValue: queryVariables } = useSessionStorage(
    GET_PROJECT_REQUEST_TOTALS_QUERY_VARIABLES_KEY,
    null
  );

  return {
    fetchProjectRequestTotals: () =>
      apolloClient.query({
        query: GET_PROJECT_REQUEST_TOTALS_QUERY,
        variables: queryVariables,
        fetchPolicy: 'network-only'
      })
  };
};

export default useFetchProjectRequestTotals;
