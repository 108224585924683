import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core';
import { PortfolioFullPath } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  th: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: theme.palette.background.paper
  }
}));

const portfolioCellStyles = makeStyles(theme => ({
  fullpath: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: theme.palette.text.secondary
  }
}));

const byName = (a, b) => {
  if (a.name === b.name) return 0;

  return a.name > b.name ? 1 : -1;
};

const ConfirmProjectRequestMoveDialogTable = ({ projectRequests }) => {
  const classes = useStyles();
  const portfolioClasses = portfolioCellStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.th}>
            <FormattedMessage id="projectRequest.addExistingProjectRequestDialog.projectRequest" />
          </TableCell>
          <TableCell className={classes.th}>
            <FormattedMessage id="projectRequest.addExistingProjectRequestDialog.portfolio" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projectRequests.sort(byName).map(projectRequest => (
          <TableRow key={projectRequest.name}>
            <TableCell>{projectRequest.name}</TableCell>
            <TableCell>
              <PortfolioFullPath
                portfolio={projectRequest.portfolio}
                classes={portfolioClasses}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ConfirmProjectRequestMoveDialogTable.propTypes = {
  projectRequests: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ConfirmProjectRequestMoveDialogTable;
