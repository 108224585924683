import { useHasSomePermissions } from '~/modules/common/permissions/useHasSomePermissions';
import { PROJECT_DAL, PROJECT_PERMISSION } from '~/modules/common/enums';

export const useCanAddClientProject = () =>
  useHasSomePermissions([
    {
      actionUri: PROJECT_PERMISSION.EDIT_PROJECT,
      dataAccessLevelUri: PROJECT_DAL.CLIENT_MANAGER
    },
    {
      actionUri: PROJECT_PERMISSION.EDIT_PROJECT,
      dataAccessLevelUri: PROJECT_DAL.PROJECT_ADMIN
    }
  ]);
