import PropTypes from 'prop-types';
import React from 'react';
import { Decimal } from '~/modules/common/components';
import useStyles from './useStyles';

export const NumberFormatter = ({ field, record }) => {
  const { isActive } = record;
  const value = record[field];
  const classes = useStyles({ isActive });

  return (
    <div className={classes.root}>
      <Decimal value={value || 0} />
    </div>
  );
};

NumberFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default NumberFormatter;
