import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import {
  FormControlLabel,
  Grid,
  TextField,
  makeStyles,
  InputAdornment,
  Link
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useMeContext } from '~/modules/me/useMeContext';
import { useDialogState } from '~/modules/common/hooks';
import { compareDateObjects } from '~/modules/common/dates/compare';
import {
  DateField,
  GridItem,
  UnsavedChangesSubmitPrompt,
  PortfoliosDropdown
} from '~/modules/common/components';
import { useSimpleChangeHandler } from '~/util';
import { useProjectManagerCanEditOnlyMyProjects } from '~/modules/projects/useProjectManagerCanEditOnlyMyProjects';
import { ProgramDropdownForProject } from '~/modules/projects/components/Common';
import { TimeAndExpenseEntryTypeDropdown } from '~/modules/tasks/components';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import SlackLogo from '../../common/slack.svg';
import {
  SearchableProjectManagerDropdown,
  ProjectTypeDropdown
} from '../../common/components';
import { EarnedRevenueDropdown } from '../../EarnedRevenue/EarnedRevenueDropdown';
import { ProjectLinks } from '../../ProjectLinks';
import ProjectCoManagerSelect from './ProjectCoManagerSelect';
import { projectLeaderApprovalField } from './BasicInfo.util';

const useStyles = makeStyles(theme => ({
  approvalCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  slackLogo: {
    width: '18px'
  }
}));

const transformDropdownResult = result => ({
  displayText: result && result.label,
  id: result && result.value,
  slug: result && result.slug
});

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const EditBasicInfoForm = ({
  formik: { values, handleChange, setFieldValue, errors, handleSubmit, dirty },
  projectPermissions,
  intl,
  isNameRequired,
  isCodeRequired,
  onReschedule,
  setSubmitFromUnsavedPrompt,
  shouldFetchOnlyMyPrograms,
  templateSettings
}) => {
  const {
    name,
    code,
    startDate,
    endDate,
    description,
    program,
    portfolio,
    projectManagerReference,
    coManagers,
    isProjectLeaderApprovalRequired,
    projectTypeReference,
    slackChannel,
    timeAndExpenseEntryType,
    earnedRevenueScript,
    projectLinks
  } = values;
  const { formatMessage } = intl;
  const {
    featureFlags: {
      isPsaEarnedRevenueEnabled,
      isPsaRescheduleProjectHintEnabled,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpProjectLinkEnabled
    },
    hasViewProjectBillingOptions
  } = useMeContext();
  const classes = useStyles();
  const approvalCheckboxGridClasses = useMemo(
    () => ({
      root: classes.approvalCheckbox
    }),
    [classes.approvalCheckbox]
  );

  const {
    open: unsavedDialogOpen,
    openDialog: unsavedOpenDialog,
    closeDialog: unsavedCloseDialog
  } = useDialogState(false);

  const slackFieldInputProps = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <img src={SlackLogo} alt="Slack Logo" className={classes.slackLogo} />
        </InputAdornment>
      ),
      inputProps: getAriaLabel(
        formatMessage({
          id: 'projectBasicInfoCard.slackChannel'
        })
      )
    }),
    [classes.slackLogo, formatMessage]
  );

  const handleStartDateChange = useCallback(
    newStartDate => {
      setFieldValue('startDate', newStartDate);

      const isStartDateAfterEndDate =
        compareDateObjects(newStartDate, endDate) === 1;

      if (isStartDateAfterEndDate) {
        setFieldValue('endDate', newStartDate);
      }
    },
    [endDate, setFieldValue]
  );

  const handleEndDateChange = useCallback(
    newEndDate => {
      setFieldValue('endDate', newEndDate);

      const isEndDateBeforeStartDate =
        compareDateObjects(newEndDate, startDate) === -1;

      if (isEndDateBeforeStartDate) {
        setFieldValue('startDate', newEndDate);
      }
    },
    [startDate, setFieldValue]
  );

  const handleProjectManagerChange = useCallback(
    (_, value) => setFieldValue('projectManagerReference', value),
    [setFieldValue]
  );

  const handleCoManagerChange = useCallback(
    (vals, _) => {
      setFieldValue('coManagers', vals || []);
    },
    [setFieldValue]
  );

  const handlePortfolioChange = useCallback(
    value => {
      setFieldValue('portfolio', value);
    },
    [setFieldValue]
  );

  const handleProgramChange = useCallback(
    value => {
      setFieldValue('program', transformDropdownResult(value));
    },
    [setFieldValue]
  );

  const handleEarnedRevenueScriptChange = useCallback(
    value => {
      setFieldValue('earnedRevenueScript', value);
    },
    [setFieldValue]
  );

  const handlePMApprovalChange = useCallback(
    (_, checked) => {
      setFieldValue('isProjectLeaderApprovalRequired', checked);
    },
    [setFieldValue]
  );

  const handleSlackChannelChange = useCallback(
    event => {
      setFieldValue(
        'slackChannel',
        event.target.value ? event.target.value.replace(/\s/g, '') : ''
      );
    },
    [setFieldValue]
  );

  const handleTimeAndExpenseEntryTypeChange = useCallback(
    e => {
      if (e.id === TIME_AND_EXPENSE_ENTRY_TYPE.NO) {
        setFieldValue('isTimeEntryAllowed', false);
      } else {
        setFieldValue('isTimeEntryAllowed', true);
      }
      setFieldValue('timeAndExpenseEntryType', e);
    },
    [setFieldValue]
  );

  const handleValueChange = useSimpleChangeHandler(setFieldValue);

  const projectLeaderApprovalRequired = useMemo(
    () =>
      projectLeaderApprovalField(
        isProjectLeaderApprovalRequired,
        handlePMApprovalChange
      ),
    [handlePMApprovalChange, isProjectLeaderApprovalRequired]
  );

  const canEditOnlyMyProjects = useProjectManagerCanEditOnlyMyProjects();

  const onRescheduleClick = useCallback(
    e => {
      e.stopPropagation();
      if (dirty) {
        unsavedOpenDialog();
      } else {
        onReschedule();
      }
    },
    [onReschedule, unsavedOpenDialog, dirty]
  );

  const onSaveChanges = useCallback(() => {
    unsavedCloseDialog();
    setSubmitFromUnsavedPrompt(true);
    handleSubmit();
  }, [setSubmitFromUnsavedPrompt, unsavedCloseDialog, handleSubmit]);

  const rescheduleProjectTip = isPsaRescheduleProjectHintEnabled ? (
    <>
      <strong>{formatMessage({ id: 'rescheduleProject.tip.header' })} </strong>
      {formatMessage(
        { id: 'rescheduleProject.tip.message' },
        {
          value: (
            <Link
              component="button"
              variant="caption"
              onClick={onRescheduleClick}
            >
              {formatMessage({ id: 'rescheduleProject.title' })}
            </Link>
          )
        }
      )}
    </>
  ) : null;

  const programTextFieldProps = useMemo(
    () => ({
      error: Boolean(errors.program),
      helperText: errors.program
    }),
    [errors.program]
  );

  const fieldErrors =
    errors &&
    (errors.program ||
      errors.name ||
      errors.code ||
      errors.description ||
      errors.slackChannel);

  const { hasBilling } = templateSettings || {};

  return (
    <>
      {!fieldErrors && !isEmpty(errors) && <FormErrorAlert />}
      <Grid container>
        <GridItem rightPadding>
          <TextField
            autoFocus
            name="name"
            variant="filled"
            data-qe-id="ProjectInfoName"
            label={formatMessage({ id: 'projectBasicInfoCard.name' })}
            inputProps={getAriaLabel(
              formatMessage({ id: 'projectBasicInfoCard.name' })
            )}
            value={name}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.name)}
            helperText={errors.name}
            required={isNameRequired}
          />
        </GridItem>
        <GridItem>
          <TextField
            variant="filled"
            data-qe-id="ProjectInfoCode"
            label={formatMessage({
              id: 'projectBasicInfoCard.code'
            })}
            inputProps={getAriaLabel(
              formatMessage({
                id: 'projectBasicInfoCard.code'
              })
            )}
            name="code"
            value={code}
            onChange={handleChange}
            error={Boolean(errors.code)}
            helperText={errors.code}
            fullWidth
            required={isCodeRequired}
          />
        </GridItem>
        <GridItem rightPadding>
          <DateField
            variant="filled"
            data-qe-id="ProjectInfoStartDate"
            label={formatMessage({
              id: 'projectBasicInfoCard.startDate'
            })}
            ariaLabel={formatMessage({
              id: 'projectBasicInfoCard.startDate'
            })}
            name="startDate"
            value={startDate}
            onChange={handleStartDateChange}
            editable
            helperText={rescheduleProjectTip}
          />
        </GridItem>
        <GridItem>
          <DateField
            variant="filled"
            data-qe-id="ProjectInfoEndDate"
            label={formatMessage({
              id: 'projectBasicInfoCard.endDate'
            })}
            ariaLabel={formatMessage({
              id: 'projectBasicInfoCard.endDate'
            })}
            name="endDate"
            value={endDate}
            onChange={handleEndDateChange}
            editable
          />
        </GridItem>
        <GridItem rightPadding>
          <SearchableProjectManagerDropdown
            dataQeId="ClientProjectManagerDropdown"
            value={projectManagerReference}
            onChange={handleProjectManagerChange}
            disabled={canEditOnlyMyProjects}
          />
        </GridItem>
        <GridItem classes={approvalCheckboxGridClasses}>
          <FormControlLabel
            control={projectLeaderApprovalRequired}
            label={formatMessage({
              id: 'projectBasicInfo.pmApprovalRequired'
            })}
          />
        </GridItem>
        {projectPermissions.canEditCoManagers && (
          <GridItem fullWidth>
            <ProjectCoManagerSelect
              coManagers={coManagers}
              onChange={handleCoManagerChange}
            />
          </GridItem>
        )}
        {projectPermissions.canEditPortfolio && (
          <GridItem rightPadding>
            <PortfoliosDropdown
              value={portfolio}
              onChange={handlePortfolioChange}
            />
          </GridItem>
        )}
        {projectPermissions.canViewProgram && (
          <GridItem rightPadding>
            <ProgramDropdownForProject
              variant="filled"
              dataQeId="ClientProgramDropdown"
              name="program"
              onChange={handleProgramChange}
              value={program}
              editable={projectPermissions.canEditProgram}
              shouldFetchOnlyMyPrograms={shouldFetchOnlyMyPrograms}
              TextFieldProps={programTextFieldProps}
            />
          </GridItem>
        )}
        <GridItem fullWidth={!projectPermissions.canViewProgram}>
          <TextField
            variant="filled"
            data-qe-id="ProjectSlackChannel"
            label={formatMessage({
              id: 'projectBasicInfoCard.slackChannel'
            })}
            name="slackChannel"
            value={slackChannel}
            onChange={handleSlackChannelChange}
            fullWidth
            error={Boolean(errors.slackChannel)}
            helperText={errors.slackChannel}
            InputProps={slackFieldInputProps}
          />
        </GridItem>
        {isPsaEarnedRevenueEnabled && (
          <GridItem rightPadding>
            <EarnedRevenueDropdown
              name="earnedRevenueScript"
              onChange={handleEarnedRevenueScriptChange}
              value={earnedRevenueScript}
            />
          </GridItem>
        )}
        <GridItem fullwidth>
          <ProjectTypeDropdown
            variant="filled"
            dataQeId="EditProjectTypeDropdown"
            name="projectTypeReference"
            value={projectTypeReference}
            onChange={handleValueChange('projectTypeReference')}
            noneOption={false}
            fullWidth
          />
        </GridItem>
        <GridItem fullWidth>
          <TextField
            variant="filled"
            data-qe-id="ProjectInfoDescription"
            label={formatMessage({
              id: 'projectBasicInfoCard.description'
            })}
            inputProps={getAriaLabel(
              formatMessage({
                id: 'projectBasicInfoCard.description'
              })
            )}
            name="description"
            value={description}
            onChange={handleChange}
            fullWidth
            multiline
            rowsMax={10}
            error={Boolean(errors.description)}
            helperText={errors.description}
          />
        </GridItem>
        <GridItem fullWidth>
          <TimeAndExpenseEntryTypeDropdown
            fullWidth
            variant="filled"
            dataQeId="EditAllowEntryWithoutTasksDropdown"
            showBillingOptions={
              isPsaPrpPsaPpmMergerEnabled
                ? hasBilling && hasViewProjectBillingOptions
                : hasViewProjectBillingOptions
            }
            value={timeAndExpenseEntryType && timeAndExpenseEntryType.id}
            onChange={handleTimeAndExpenseEntryTypeChange}
            label={formatMessage({ id: 'addProject.allowEntryWithoutTasks' })}
          />
        </GridItem>
        {isPsaPrpProjectLinkEnabled && (
          <GridItem fullWidth>
            <ProjectLinks
              projectLinks={projectLinks}
              setFieldValue={setFieldValue}
            />
          </GridItem>
        )}
        <UnsavedChangesSubmitPrompt
          message={formatMessage({
            id: 'unsavedChangesPrompt.rescheduleText'
          })}
          open={unsavedDialogOpen}
          onClose={unsavedCloseDialog}
          onSave={onSaveChanges}
        />
      </Grid>
    </>
  );
};

EditBasicInfoForm.propTypes = {
  formik: PropTypes.object,
  projectPermissions: PropTypes.object,
  intl: PropTypes.object,
  onReschedule: PropTypes.func,
  setSubmitFromUnsavedPrompt: PropTypes.func,
  shouldFetchOnlyMyPrograms: PropTypes.bool,
  isNameRequired: PropTypes.bool,
  isCodeRequired: PropTypes.bool,
  templateSettings: PropTypes.object
};

export default connect(EditBasicInfoForm);
