import React, { useMemo } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(theme => ({
  expenseTypesCount: {
    marginRight: theme.spacing(1)
  }
}));

export const ExpenseReadOnlyTitle = () => {
  const classes = useStyles();

  const { values } = useFormikContext();

  const expenseTypesCount = useMemo(
    () => ({
      count: values.allowedExpensesAndEstimates.length || 0
    }),
    [values.allowedExpensesAndEstimates.length]
  );

  return (
    <Grid container spacing={0} justifyContent="space-between">
      <Typography variant="subtitle2">
        <FormattedMessage id="projectTimeAndExpenseCard.allowedExpenseTypes" />
      </Typography>
      <Typography className={classes.expenseTypesCount} variant="caption">
        <FormattedMessage
          id="projectTimeAndExpenseCard.expenseTypes"
          values={expenseTypesCount}
        />
      </Typography>
    </Grid>
  );
};
