import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { BillingInvoicingTab } from '~/modules/billing-invoicing/common/components';
import AvailableToBillSubTab from '~/modules/billing-invoicing/common/components/AvailableToBillSubTab';
import { usePermissions } from '~/modules/billing-invoicing/common/enhancers';
import { BILLING_INVOICING_PERMISSION_URIS } from '~/modules/common/enums';
import { usePageTitle } from '~/modules/common/title';
import { DateRangeFacet } from '~/modules/common/components/SearchBox/Facets';
import { LinearQueryProgress } from '~/modules/common';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import { useMeContext } from '~/modules/me';
import useClientPageEditContext from '../ClientInfo/useClientPageEditContext';
import { OverviewSubTab, TransactionsSubTab } from './components';
import useClientQuery from './useClientQuery';

const searchFacets = [DateRangeFacet];

const useStyles = makeStyles(() => ({
  tabRoot: {
    top: 104,
    zIndex: 10
  }
}));

export const BillingInvoicingPage = props => {
  const { slug } = props;
  const me = useMeContext();
  const { id: userId } = me;

  const classes = useStyles();
  const {
    client,
    loading: clientQueryLoading,
    error: clientQueryError
  } = useClientQuery(slug);

  const {
    addBillPermissions,
    addPaymentPermissions,
    addCreditMemoPermissions,
    ...otherPermissions
  } = usePermissions(
    BILLING_INVOICING_PERMISSION_URIS.VIEW_CLIENT_BILLING_DATA
  );

  const { displayText, clientManager, comanagers } = client;

  const hasClientManagerRole = useMemo(
    () =>
      (clientManager || {}).id === userId ||
      (comanagers || []).some(c => c.id === userId),
    [clientManager, comanagers, userId]
  );

  const permissions = useMemo(
    () => ({
      ...otherPermissions,
      canAddBill: Boolean(
        addBillPermissions.asBillingManager ||
          (addBillPermissions.asClientManager && hasClientManagerRole)
      ),

      canAddPayment: Boolean(
        addPaymentPermissions.asBillingManager ||
          (addPaymentPermissions.asClientManager && hasClientManagerRole)
      ),

      canAddCreditMemo: Boolean(
        addCreditMemoPermissions.asBillingManager ||
          (addCreditMemoPermissions.asClientManager && hasClientManagerRole)
      )
    }),
    []
  );

  usePageTitle(
    clientQueryLoading
      ? []
      : [
          { messageId: 'routes.clients' },
          { title: displayText },
          { messageId: 'client.billingAndInvoicing' }
        ]
  );
  const tabClasses = useMemo(() => ({ root: classes.tabRoot }), [
    classes.tabRoot
  ]);
  const { editable } = useClientPageEditContext();

  const [useSavedCriteria, setUseSavedCriteria] = useState(true);

  if (clientQueryLoading) return <LinearQueryProgress />;
  if (clientQueryError) return <RedirectToUnhandledError />;

  return (
    <BillingInvoicingTab
      searchState="clients-available-to-bill-search"
      classes={tabClasses}
      AvailableToBillSubTab={AvailableToBillSubTab}
      TransactionsSubTab={TransactionsSubTab}
      OverviewSubTab={OverviewSubTab}
      headerLevel={2}
      editable={editable}
      permissions={permissions}
      searchFacets={searchFacets}
      useSavedCriteria={useSavedCriteria}
      setUseSavedCriteria={setUseSavedCriteria}
      client={client}
      drawerClient={client}
      loading={clientQueryLoading}
      me={me}
      {...props}
    />
  );
};

BillingInvoicingPage.propTypes = {
  slug: PropTypes.string
};

export default BillingInvoicingPage;
