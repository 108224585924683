import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RECORD_USER_ACCESS_MUTATION = gql`
  mutation recordUserAccess($accessInput: UserAccessInput!) {
    recordUserAccess(accessInput: $accessInput)
  }
`;

export const useRecordUserAccess = options =>
  useMutation(RECORD_USER_ACCESS_MUTATION, options);
