import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSearchState } from '~/modules/common/components/SearchBox';
import ExistingProjectRequestSearchContext from './ExistingProjectRequestSearchContext';

const searchKey = 'existing-project-request';

export default function ExistingProjectRequestSearchContextProvider({
  children
}) {
  const [searchCriteria, setSearchCriteria] = useSearchState(searchKey);

  return (
    <ExistingProjectRequestSearchContext.Provider
      value={useMemo(
        () => ({
          searchCriteria,
          setSearchCriteria
        }),
        [searchCriteria, setSearchCriteria]
      )}
    >
      {children}
    </ExistingProjectRequestSearchContext.Provider>
  );
}

ExistingProjectRequestSearchContextProvider.propTypes = {
  children: PropTypes.node
};
