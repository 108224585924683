import { Card, CardContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  ChartHeader,
  ProgressChartLoading
} from '~/modules/common/charts/dashboard';
import { ProgressIcon } from '~/modules/common/components';
import { useMeContext } from '~/modules/me';
import { ProgressTypes } from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';
import useProjectsCount from '../../hooks/useProjectsCount';
import NoProject from '../NoProject';
import BudgetBurnedToggle from './BudgetBurnedToggle';
import { usePortfolioProjectsProgressSummary, useToggleState } from './hooks';

import { ProgressCostChart } from './ProgressCostChart';
import ProgressDetails from './ProgressDetails';
import { ProgressHourChart } from './ProgressHourChart';
import { ProgressChartForSumAllTasks } from './ProgressChartForSumAllTasks';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
    overflow: 'visible'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  cardPadding: {
    padding: '0 !important'
  }
}));

const useChartHeaderStyles = makeStyles(theme => ({
  title: {
    flexShrink: 1
  }
}));

const avatar = <ProgressIcon />;
const progressChartAction = ({
  showCostContent,
  budgetBurnedView,
  setBudgetBurnedView,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) =>
  showCostContent && isRolledUpTaskEstimateCalculationMethodEnabled ? (
    <BudgetBurnedToggle
      budgetBurnedView={budgetBurnedView}
      setBudgetBurnedView={setBudgetBurnedView}
    />
  ) : null;

const BudgetProgressCard = ({
  canViewCostData,
  canViewPortfolioProjects,
  portfolioCurrency,
  portfolioId
}) => {
  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled },
    isRolledUpTaskEstimateCalculationMethodEnabled
  } = useMeContext();
  const classes = useStyles();
  const chartHeaderClasses = useChartHeaderStyles();

  const {
    count: totalProjectsCount,
    loading: totalProjectsCountLoading
  } = useProjectsCount({ portfolioId, skip: !canViewPortfolioProjects });

  const {
    loading: progressSummaryLoading,
    projectsBudget,
    projectsActualBudget,
    projectsBudgetHours,
    projectsEstimateAtCompletionHours,
    projectsActualHours,
    projectsEstimateAtCompletionCost
  } = usePortfolioProjectsProgressSummary({
    portfolioId,
    isPsaPpmEstimatedCostAtCompletionEnabled,
    canViewCostData
  });

  const loading = totalProjectsCountLoading || progressSummaryLoading;
  const title = useMemo(
    () => (
      <FormattedMessage
        id={
          isPsaPpmEstimatedCostAtCompletionEnabled
            ? 'portfolio.budgetBurnedCard.chartTitle'
            : 'portfolio.budgetProgressCard.chartTitle'
        }
      />
    ),
    [isPsaPpmEstimatedCostAtCompletionEnabled]
  );

  const { budgetBurnedView, setBudgetBurnedView } = useToggleState();
  const showCostContent =
    canViewCostData &&
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    totalProjectsCount > 0;

  return (
    <Card className={classes.card}>
      <ChartHeader
        title={title}
        avatar={avatar}
        action={progressChartAction({
          showCostContent,
          budgetBurnedView,
          setBudgetBurnedView,
          isRolledUpTaskEstimateCalculationMethodEnabled
        })}
        classes={chartHeaderClasses}
      />
      <CardContent
        className={classNames(
          classes.content,
          isPsaPpmEstimatedCostAtCompletionEnabled ? classes.cardPadding : null
        )}
      >
        {!loading && totalProjectsCount === 0 ? (
          <NoProject />
        ) : (
          <>
            {isPsaPpmEstimatedCostAtCompletionEnabled ? (
              loading ? (
                <ProgressChartLoading />
              ) : (
                <>
                  {isRolledUpTaskEstimateCalculationMethodEnabled ? (
                    showCostContent &&
                    budgetBurnedView === ProgressTypes.COST ? (
                      <ProgressCostChart
                        projectsBudgetCost={projectsBudget}
                        projectsEstimateAtCompletionCost={
                          projectsEstimateAtCompletionCost
                        }
                        projectsActualCost={projectsActualBudget}
                        portfolioCurrency={portfolioCurrency}
                      />
                    ) : (
                      <ProgressHourChart
                        projectsBudgetHours={projectsBudgetHours}
                        projectsEstimateAtCompletionHours={
                          projectsEstimateAtCompletionHours
                        }
                        projectsActualHours={projectsActualHours}
                      />
                    )
                  ) : (
                    <ProgressChartForSumAllTasks
                      projectsBudgetHours={projectsBudgetHours}
                      projectsActualHours={projectsActualHours}
                      projectsBudgetCost={projectsBudget}
                      projectsActualCost={projectsActualBudget}
                      portfolioCurrency={portfolioCurrency}
                      showCostContent={showCostContent}
                    />
                  )}
                </>
              )
            ) : (
              <>
                {canViewCostData ? (
                  <ProgressDetails
                    loading={loading}
                    type={ProgressTypes.COST}
                    actual={projectsActualBudget}
                    estimated={projectsBudget}
                  />
                ) : null}
                <ProgressDetails
                  loading={loading}
                  type={ProgressTypes.HOURS}
                  actual={projectsEstimateAtCompletionHours}
                  estimated={projectsBudgetHours}
                />
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

BudgetProgressCard.propTypes = {
  portfolioId: PropTypes.string,
  canViewPortfolioProjects: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  portfolioCurrency: PropTypes.object
};

export default BudgetProgressCard;
