import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Typography,
  Button
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    bottom: theme.spacing(0),
    zIndex: 11,
    backgroundColor: '#494949',
    padding:
      theme.direction === 'rtl'
        ? theme.spacing(0, 2, 0, 2)
        : theme.spacing(0, 2, 0, 0)
  },
  container: {
    backgroundColor: '#494949',
    height: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-between'
  },
  removeIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    color: '#fff',
    height: theme.spacing(4.5)
  },
  selectedItem: {
    color: '#fff'
  },
  createButton: {
    marginRight: theme.spacing(1.5),
    height: theme.spacing(4.5)
  }
}));

export const BulkActionsForBill = ({
  openDialog,
  selectedBillCount,
  resetSelectedAvailableToBillItems
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const onClick = useCallback(() => resetSelectedAvailableToBillItems(), [
    resetSelectedAvailableToBillItems
  ]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        className={classes.container}
        alignItems="center"
      >
        <Grid item className={classes.removeIcon}>
          <IconButton
            onClick={onClick}
            className={classes.iconButton}
            aria-label={formatMessage({
              id: 'availableToBillList.clearIcon'
            })}
            dataQeId="ClearSelectedItem"
          >
            <ClearIcon />
          </IconButton>
          <Typography
            variant="subtitle1"
            className={classes.selectedItem}
            dataQeId="SelectedItem"
          >
            <FormattedMessage
              id="availableToBillList.selectedItem"
              values={selectedBillCount}
            />
          </Typography>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          onClick={openDialog}
          dataQeId="CreateSelectedBill"
        >
          <FormattedMessage
            id="availableToBillList.createBills"
            values={selectedBillCount}
          />
        </Button>
      </Grid>
    </div>
  );
};

BulkActionsForBill.propTypes = {
  openDialog: PropTypes.func,
  selectedBillCount: PropTypes.object,
  resetSelectedAvailableToBillItems: PropTypes.func
};

export default BulkActionsForBill;
