import { useCallback } from 'react';
import { v4 } from 'uuid';
import { ProjectRequestGateValue } from '~/types';
import { useUpdateGates } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestDrawer/hooks';

export const useGateChangeHandlers = ({
  projectRequestId,
  gates,
  setFieldValue
}) => {
  const { updateGates } = useUpdateGates({ projectRequestId });
  const onGateValueChange = useCallback(
    (checked, gateId, gateIndex) => {
      const newGateValue = checked
        ? ProjectRequestGateValue.Met
        : ProjectRequestGateValue.Pending;
      const updatedGates = gates.map(g =>
        g.uri === gateId ? { ...g, value: newGateValue } : g
      );

      setFieldValue(`gates[${gateIndex}].value`, newGateValue);
      updateGates(updatedGates);
    },
    [gates, setFieldValue, updateGates]
  );

  const onGateDisplayTextChange = useCallback(
    (value, gateIndex) => {
      setFieldValue(`gates[${gateIndex}].displayText`, value, true);
    },
    [setFieldValue]
  );

  const onAddGate = useCallback(
    () =>
      setFieldValue('gates', [
        ...gates,
        { uri: v4(), displayText: '', value: ProjectRequestGateValue.Pending }
      ]),
    [gates, setFieldValue]
  );

  const onRemoveGate = useCallback(
    gateId =>
      setFieldValue(
        'gates',
        gates.filter(x => x.uri !== gateId),
        true
      ),
    [gates, setFieldValue]
  );

  return {
    onGateValueChange,
    onGateDisplayTextChange,
    onAddGate,
    onRemoveGate
  };
};

export default useGateChangeHandlers;
