import React from 'react';
import { FormattedMessage } from 'react-intl';
import PortfolioInfo from './PortfolioInfo';

const route = () => ({
  path: '/portfolios/:slug',
  title: () => <FormattedMessage id="portfolio.tabs.summary" />,
  component: PortfolioInfo
});

export default route;
