import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const ADD_ROLE = gql`
  mutation AddRole(
    $addRoleInput: AddRoleInput!
    $isCurrentRateEnabled: Boolean!
  ) {
    addRole2(addRoleInput: $addRoleInput) {
      id
      displayText
      currentRate @include(if: $isCurrentRateEnabled) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

export const useAddRoleMutation = () => useMutation(ADD_ROLE, {});

export default useAddRoleMutation;
