import React from 'react';
import { useIntl } from 'react-intl';
import { ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0)
  },
  resourcesColumn: {
    minWidth: theme.spacing(20)
  }
}));

export const ResourceMatchListHeader = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <ListItem data-qe-id="ResourceMatchListHeader" className={classes.root}>
      <div className={classes.resourcesColumn}>
        <Typography variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.resources'
          })}
        </Typography>
      </div>
      <div>
        <Typography variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.match'
          })}
        </Typography>
      </div>
      <div>
        <Typography variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.proposeResource'
          })}
        </Typography>
      </div>
    </ListItem>
  );
};

export default ResourceMatchListHeader;
