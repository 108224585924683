import { useMutation, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { MARK_NOTIFICATION_AS_READ_MUTATION } from '../graphql/markNotificationAsReadMutation';
import { notificationFragment } from '../graphql/notificationFragment';
import { UNREAD_NOTIFICATIONS_QUERY } from '../graphql/unreadNotificationsQuery';

export const updateCache = (cache, { data: { markNotificationAsRead } }) => {
  const { notification } = markNotificationAsRead;

  if (!notification) return;

  const { me } = cache.readQuery({
    query: UNREAD_NOTIFICATIONS_QUERY
  });

  const {
    unreadNotifications: cachedNotifications,
    unreadNotificationsCount
  } = me;

  const count = (parseInt(unreadNotificationsCount, 10) - 1).toString();

  cache.writeQuery({
    query: UNREAD_NOTIFICATIONS_QUERY,
    data: {
      me: {
        ...me,
        unreadNotifications: cachedNotifications.filter(
          n => n.id !== notification.id
        ),
        unreadNotificationsCount: count
      }
    }
  });
};

export const useMarkNotificationAsRead = () => {
  const { cache } = useApolloClient();
  const [markNotificationAsRead] = useMutation(
    MARK_NOTIFICATION_AS_READ_MUTATION
  );

  return useCallback(
    id => {
      const cachedNotification = cache.readFragment({
        id: `Notification:${id}`,
        fragment: notificationFragment
      });

      const optimisticResponse = {
        __typename: 'Mutation',
        markNotificationAsRead: {
          __typename: 'MarkNotificationAsReadResult',
          notification: {
            ...cachedNotification,
            unreadOnTimestamp_id: null
          }
        }
      };

      markNotificationAsRead({
        variables: {
          id
        },
        optimisticResponse,
        update: updateCache
      });
    },
    [markNotificationAsRead]
  );
};
