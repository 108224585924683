import React from 'react';
import { FormattedMessage } from 'react-intl';

export const buildFooters = (
  classes,
  editable,
  isPsaPrpManualBulkBillCreationEnabled
) => ({
  ...(isPsaPrpManualBulkBillCreationEnabled && {
    rowSelector: {
      id: 'rowSelector',
      visible: isPsaPrpManualBulkBillCreationEnabled && editable
    }
  }),
  client: {
    id: 'client',
    value: <FormattedMessage id="avaialbleToBillList.total" />,
    fixed: true,
    visible: true,
    align: 'right',
    className: classes.total
  },
  date: {
    id: 'date',
    visible: true
  },
  amount: {
    id: 'amount',
    visible: true,
    align: 'right',
    flip: false
  },
  createBill: {
    id: 'createBill',
    visible: editable
  }
});
