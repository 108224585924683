import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { withRoutes } from '~/modules/common/routes';
import MenuItem from '../MenuItem';

export const BusinessIntelligenceMenuItem = ({ routes }) => {
  const location = useLocation();
  const { businessIntelligence: businessIntelligenceRoute } = routes;

  return (
    <MenuItem
      key={businessIntelligenceRoute.path}
      path={businessIntelligenceRoute.path}
      icon={<businessIntelligenceRoute.icon />}
      title={<businessIntelligenceRoute.title />}
      selected={!!matchPath(location.pathname, businessIntelligenceRoute)}
      divider={businessIntelligenceRoute.showDivider}
    />
  );
};

BusinessIntelligenceMenuItem.propTypes = {
  routes: PropTypes.object
};

export default withRoutes(BusinessIntelligenceMenuItem);
