import { isNullOrUndefined } from '~/modules/common/util';

const getPercentageOrNull = (numerator, denominator) => {
  return denominator && !isNullOrUndefined(numerator)
    ? (numerator / denominator) * 100
    : null;
};

export const getActualsFieldProps = ({ actualAmount, budgetAmount }) => {
  return {
    percentage: budgetAmount
      ? ((actualAmount || 0) * 100) / budgetAmount
      : null,
    value: actualAmount || 0
  };
};

export const getEACFieldProps = ({ eacAmount, budgetAmount }) => {
  return {
    percentage: getPercentageOrNull(eacAmount, budgetAmount),
    value: eacAmount
  };
};
