import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoSharp';
import DisabledIcon from '@material-ui/icons/RemoveCircleSharp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { useStyles } from './styles';
import {
  getNotificationAnnotationMessageId,
  isNotificationPolicySupported
} from './util';

export const NotificationPolicy = ({ policy, updateNotificationPolicy }) => {
  const onWebConfigChange = useCallback(
    () =>
      updateNotificationPolicy({
        ...policy,
        config: {
          ...policy.config,
          isWebNotificationEnabled: !policy.config.isWebNotificationEnabled
        }
      }),
    [policy, updateNotificationPolicy]
  );

  const onEmailConfigChange = useCallback(
    () =>
      updateNotificationPolicy({
        ...policy,
        config: {
          ...policy.config,
          isEmailNotificationEnabled: !policy.config.isEmailNotificationEnabled
        }
      }),
    [policy, updateNotificationPolicy]
  );

  const onPushConfigChange = useCallback(
    () =>
      updateNotificationPolicy({
        ...policy,
        config: {
          ...policy.config,
          isPushNotificationEnabled: !policy.config.isPushNotificationEnabled
        }
      }),
    [policy, updateNotificationPolicy]
  );

  const intl = useIntl();

  const classes = useStyles();

  const { userAccessRoles } = useMeContext();

  const { webInputProps, emailInputProps, pushInputProps } = useMemo(
    () => ({
      webInputProps: {
        'aria-label': `${policy.name}: ${intl.formatMessage({
          id: 'settingsPage.notifications.web'
        })}`,
        'aria-checked': policy.config.isWebNotificationEnabled
      },
      emailInputProps: {
        'aria-label': `${policy.name}: ${intl.formatMessage({
          id: 'settingsPage.notifications.email'
        })}`,
        'aria-checked': policy.config.isEmailNotificationEnabled
      },
      pushInputProps: {
        'aria-label': `${policy.name}: ${intl.formatMessage({
          id: 'settingsPage.notifications.mobilePush'
        })}`,
        'aria-checked': policy.config.isPushNotificationEnabled
      }
    }),
    [
      intl,
      policy.config.isEmailNotificationEnabled,
      policy.config.isPushNotificationEnabled,
      policy.config.isWebNotificationEnabled,
      policy.name
    ]
  );

  const notificationAnnotationMessageId = getNotificationAnnotationMessageId(
    policy.eventType,
    userAccessRoles
  );

  const tooltip = intl.formatMessage({
    id: policy.enabled
      ? `notificationAnnotations.${policy.eventType}${
          notificationAnnotationMessageId
            ? `.${notificationAnnotationMessageId}`
            : ''
        }`
      : 'notificationAnnotations.disabled'
  });

  return (
    <div className={classNames(classes.row, classes.data)} role="row">
      <div
        className={classNames(
          classes.cell,
          classes.label,
          !policy.enabled && classes.disabled
        )}
        role="rowheader"
      >
        <span>
          {intl.formatMessage({
            id: `settingsPage.notificationEventNames.${policy.eventType}`
          })}
        </span>
        <Tooltip
          placement="bottom"
          title={tooltip}
          interactive
          disableTouchListener
        >
          {policy.enabled ? (
            <InfoIcon className={classes.annotationIcon} />
          ) : (
            <DisabledIcon className={classes.annotationIcon} />
          )}
        </Tooltip>
      </div>
      <div className={classNames(classes.cell, classes.channel)} role="cell">
        <Checkbox
          data-qe-id={`${policy.name} web`}
          checked={policy.enabled && policy.config.isWebNotificationEnabled}
          onChange={onWebConfigChange}
          disabled={
            !isNotificationPolicySupported({
              eventType: policy.eventType,
              channel: 'web'
            }) || !policy.enabled
          }
          color="primary"
          className={classes.checkbox}
          inputProps={webInputProps}
        />
      </div>
      <div className={classNames(classes.cell, classes.channel)} role="cell">
        <Checkbox
          data-qe-id={`${policy.name} email`}
          checked={policy.enabled && policy.config.isEmailNotificationEnabled}
          onChange={onEmailConfigChange}
          disabled={
            !isNotificationPolicySupported({
              eventType: policy.eventType,
              channel: 'email'
            }) || !policy.enabled
          }
          color="primary"
          className={classes.checkbox}
          inputProps={emailInputProps}
        />
      </div>
      <div className={classNames(classes.cell, classes.channel)} role="cell">
        <Checkbox
          data-qe-id={`${policy.name} mobilePush`}
          checked={policy.enabled && policy.config.isPushNotificationEnabled}
          onChange={onPushConfigChange}
          disabled={
            !isNotificationPolicySupported({
              eventType: policy.eventType,
              channel: 'mobilePush'
            }) || !policy.enabled
          }
          color="primary"
          className={classes.checkbox}
          inputProps={pushInputProps}
        />
      </div>
    </div>
  );
};

NotificationPolicy.propTypes = {
  policy: PropTypes.object.isRequired,
  updateNotificationPolicy: PropTypes.func.isRequired
};

export default NotificationPolicy;
