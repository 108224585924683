import { ApolloLink } from '@apollo/client';

export class WebSocketLink extends ApolloLink {
  constructor(client) {
    super();

    this.subscriptionClient = client;
  }

  request(operation) {
    return this.subscriptionClient.request(operation);
  }
}
