import React from 'react';
import { retryLoading } from '~/util';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { hasPermission } from '~/modules/common/permissions/useHasPermission';
import { TaskBeatIcon } from './components';

const TaskBeatPage2 = React.lazy(() =>
  retryLoading(() => import('./TaskBeatPage2/TaskBeatPage'))
);

export const canViewTaskBeatRoute = ({ me }) => {
  const hasProjectManagerRole = me.userAccessRoles?.some(
    role => role === USER_ACCESS_ROLE.PROJECT_MANAGER
  );

  return (
    hasProjectManagerRole &&
    hasPermission(me.permissionsMap)({
      actionUri: 'urn:replicon-webui:project-action:view-smartbeats'
    })
  );
};

export const taskBeatRoute = ({ intl, me }) => ({
  path: '/smartbeats',
  title: () => intl.formatMessage({ id: 'routes.smartBeats' }),
  showDivider: true,
  isSideBarRoute: true,
  component: TaskBeatPage2,
  icon: () => <TaskBeatIcon />,
  hasPermission: canViewTaskBeatRoute({ me }),
  hidden: false,
  dataQeId: 'SmartBeats'
});

export default taskBeatRoute;
