import omit from 'lodash/fp/omit';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import map from 'lodash/fp/map';
import {
  hasOverlap,
  shouldExcludeDay
} from '~/modules/resourcing/common/util/scheduleUtil';
import {
  getDateRangeFromScheduleRules,
  getScheduleTotalHours
} from '~/modules/resourcing/common/util';
import {
  mapRepliconDateToUtcObject,
  mapIsoStringtoUtcObject,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { appendScheduleRuleTypeNameFields } from '~/modules/resourcing/common/util/resourceAllocationUtil';

export const omitForbiddenFields = flow(
  update(
    'scheduleRules',
    map(
      flow(
        omit('__typename'),
        update('dateRange', omit('__typename')),
        update('do', flow(omit('__typename')))
      )
    )
  )
);

export const getHoursForDayFromCurrentDayRule = ({ currentDayRule, day }) => {
  if (!currentDayRule) return 0;

  return shouldExcludeDay(day, currentDayRule.do.excludeWeekdays)
    ? 0
    : currentDayRule.do.setHours * ((currentDayRule.do.load || 100) / 100);
};

const getOverlappingScheduleRule = ({ rules, currentSchedule, currentDay }) => {
  if (
    !currentSchedule?.dateRange ||
    currentDay > mapIsoStringtoUtcObject(currentSchedule.dateRange.startDate)
  ) {
    return rules.find(rule =>
      hasOverlap({ start: currentDay, end: currentDay })(rule)
    );
  }

  return currentSchedule;
};

export const getAvailabilityPeriodsFromDates = ({
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  startDate,
  endDate
}) => {
  const firstDate = mapRepliconDateToUtcObject(startDate);
  const lastDate = mapRepliconDateToUtcObject(endDate);

  const availabilityPeriods = [];
  let totalAvailableHours = 0;
  let currentDay = firstDate;
  let overlappingScheduleRuleFromResourceAllocation = null;
  let overlappingScheduleRuleFromOtherTaskAllocations = null;

  while (currentDay <= lastDate) {
    overlappingScheduleRuleFromResourceAllocation = getOverlappingScheduleRule({
      rules: resourceAllocationScheduleRules,
      currentSchedule: overlappingScheduleRuleFromResourceAllocation,
      currentDay
    });

    const resourceAllocationHours = getHoursForDayFromCurrentDayRule({
      day: currentDay,
      currentDayRule: overlappingScheduleRuleFromResourceAllocation
    });

    let otherTaskAllocationHours = 0;

    if (
      resourceAllocationHours &&
      otherTaskAllocationsSummaryScheduleRules?.length
    ) {
      overlappingScheduleRuleFromOtherTaskAllocations = getOverlappingScheduleRule(
        {
          rules: otherTaskAllocationsSummaryScheduleRules,
          currentSchedule: overlappingScheduleRuleFromOtherTaskAllocations,
          currentDay
        }
      );
      otherTaskAllocationHours = getHoursForDayFromCurrentDayRule({
        day: currentDay,
        currentDayRule: overlappingScheduleRuleFromOtherTaskAllocations
      });
    }

    const availableHours = Math.max(
      0,
      resourceAllocationHours - otherTaskAllocationHours
    );

    totalAvailableHours += availableHours;

    availabilityPeriods.push({
      date: currentDay,
      hours: availableHours
    });

    currentDay = currentDay.plus({ days: 1 });
  }

  return { availabilityPeriods, totalAvailableHours };
};

export const buildTaskAllocationPeriodsForUpdatedAllocation = ({
  resourceAllocationScheduleRules,
  effectiveUserScheduleSeriesData,
  userTaskAllocationsSummaryScheduleRules,
  currentTaskAllocationScheduleRules = [],
  periodStartDate,
  periodEndDate,
  updatedTaskAllocationPeriodHours
}) => {
  const {
    availableHoursByDateMap,
    periodAvailableHours
  } = getAvailableHoursByDateMap({
    userTaskAllocationsSummaryScheduleRules,
    resourceAllocationScheduleRules,
    currentTaskAllocationScheduleRules,
    periodStartDate,
    periodEndDate
  });

  let periods = availableHoursByDateMap;

  const overAllocatedHours =
    updatedTaskAllocationPeriodHours - periodAvailableHours;

  if (periodAvailableHours > 0) {
    const availabilityPercentage =
      (overAllocatedHours > 0
        ? periodAvailableHours
        : updatedTaskAllocationPeriodHours) / periodAvailableHours;

    Object.keys(periods).forEach(key => {
      periods[key] *= availabilityPercentage;
    });
  }
  if (overAllocatedHours > 0) {
    periods = buildTaskAllocationPeriodsFromDatesUsingUserSchedule({
      periods,
      effectiveUserScheduleSeriesData,
      overAllocatedHours
    });
  }

  return Object.keys(periods).map(period => ({
    date: mapIsoStringtoUtcObject(period),
    hours: periods[period]
  }));
};

export const buildTaskAllocationPeriodsFromDatesUsingUserSchedule = ({
  periods = {},
  effectiveUserScheduleSeriesData,
  overAllocatedHours
}) => {
  const allocationPeriods = periods;

  const totalEffectiveScheduleHoursForPeriod = effectiveUserScheduleSeriesData.reduce(
    (scheduleHours, currDay) => scheduleHours + currDay.hours,
    0
  );

  const factor =
    totalEffectiveScheduleHoursForPeriod > 0
      ? overAllocatedHours / totalEffectiveScheduleHoursForPeriod
      : overAllocatedHours / effectiveUserScheduleSeriesData.length;

  effectiveUserScheduleSeriesData.forEach(day => {
    const date = mapRepliconDateToMidnightUTCString(day.date);
    const hoursToAdd =
      totalEffectiveScheduleHoursForPeriod > 0 ? day.hours * factor : factor;

    allocationPeriods[date] = (allocationPeriods[date] || 0) + hoursToAdd;
  });

  return allocationPeriods;
};

export const getAvailableHoursByDateMap = ({
  userTaskAllocationsSummaryScheduleRules = [],
  resourceAllocationScheduleRules,
  currentTaskAllocationScheduleRules = [],
  periodStartDate,
  periodEndDate
}) => {
  const firstDate = mapRepliconDateToUtcObject(periodStartDate);
  const lastDate = mapRepliconDateToUtcObject(periodEndDate);

  const availableHoursByDateMap = {};
  let currentDay = firstDate;
  let overlappingScheduleRuleFromResourceAllocation = null;
  let overlappingScheduleRuleFromUserTaskAllocations = null;
  let overlappingScheduleRuleFromCurrentTaskAllocation = null;
  let periodAvailableHours = 0;

  while (currentDay <= lastDate) {
    overlappingScheduleRuleFromResourceAllocation = getOverlappingScheduleRule({
      rules: resourceAllocationScheduleRules,
      currentSchedule: overlappingScheduleRuleFromResourceAllocation,
      currentDay
    });

    const resourceAllocationHours = getHoursForDayFromCurrentDayRule({
      day: currentDay,
      currentDayRule: overlappingScheduleRuleFromResourceAllocation
    });

    let userTaskAllocationHours = 0;
    let currentTaskAllocationHours = 0;

    overlappingScheduleRuleFromUserTaskAllocations = getOverlappingScheduleRule(
      {
        rules: userTaskAllocationsSummaryScheduleRules,
        currentSchedule: overlappingScheduleRuleFromUserTaskAllocations,
        currentDay
      }
    );
    userTaskAllocationHours = getHoursForDayFromCurrentDayRule({
      day: currentDay,
      currentDayRule: overlappingScheduleRuleFromUserTaskAllocations
    });

    overlappingScheduleRuleFromCurrentTaskAllocation = getOverlappingScheduleRule(
      {
        rules: currentTaskAllocationScheduleRules,
        currentSchedule: overlappingScheduleRuleFromCurrentTaskAllocation,
        currentDay
      }
    );
    currentTaskAllocationHours = getHoursForDayFromCurrentDayRule({
      day: currentDay,
      currentDayRule: overlappingScheduleRuleFromCurrentTaskAllocation
    });

    const otherTaskAllocationHours =
      userTaskAllocationHours - currentTaskAllocationHours;

    const availableHours = Math.max(
      resourceAllocationHours - otherTaskAllocationHours,
      0
    );

    periodAvailableHours += availableHours;

    availableHoursByDateMap[currentDay.toISO()] = availableHours;

    currentDay = currentDay.plus({ days: 1 });
  }

  return { availableHoursByDateMap, periodAvailableHours };
};

const getOptimisticTaskAllocation = ({
  id,
  taskResourceEstimate,
  projectId,
  userId,
  scheduleRules
}) => {
  const { startDate, endDate } = getDateRangeFromScheduleRules(scheduleRules);

  const totalHours = getScheduleTotalHours({
    scheduleRules,
    quantity: 1
  });

  return {
    __typename: 'TaskResourceUserAllocation',
    id,
    taskUri: taskResourceEstimate.task.id,
    projectUri: projectId,
    allocationUserUri: userId,
    roleUri: taskResourceEstimate.projectRole?.id || null,
    scheduleRules: (scheduleRules || []).map(appendScheduleRuleTypeNameFields),
    totalHours,
    taskResourceEstimateUri: taskResourceEstimate.estimateUri,
    resourceAllocationUri: null,
    startDate,
    endDate
  };
};

export const buildUpdateTaskResourceUserAllocationOptimisticResponse = ({
  id,
  taskResourceEstimate,
  projectId,
  userId,
  scheduleRules
}) => ({
  __typename: 'Mutation',
  updateTaskResourceUserAllocation: {
    __typename: 'UpdateTaskResourceUserAllocationResult',
    taskResourceUserAllocation: getOptimisticTaskAllocation({
      id,
      taskResourceEstimate,
      projectId,
      userId,
      scheduleRules
    })
  }
});

export const buildCreateTaskResourceUserAllocationOptimisticResponse = ({
  id,
  taskResourceEstimate,
  projectId,
  userId,
  scheduleRules
}) => ({
  __typename: 'Mutation',
  createTaskResourceUserAllocation: {
    __typename: 'CreateTaskResourceUserAllocationResult',
    taskResourceUserAllocation: getOptimisticTaskAllocation({
      id,
      taskResourceEstimate,
      projectId,
      userId,
      scheduleRules
    })
  }
});
