import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { DateRange } from '~/modules/common/components';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import {
  ActualSummaryPeriodPopupContent,
  ActualSummaryPeriodPopupRow,
  ActualSummaryPeriodPopupTableHeader
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    margin: 0,
    padding: theme.spacing(2)
  },
  divider: {
    width: '100%',
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  heading: {
    textAlign: 'center',
    margin: theme.spacing(0, 0, 1, 0)
  },
  date: {
    fontSize: theme.typography.h2.fontSize,
    display: 'block'
  },
  subTitle: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    display: 'block'
  },
  actualsSummaryPopupRows: {
    margin: 0,
    display: 'grid',
    gridTemplateColumns: [['auto', 'auto', 'auto', 'auto']],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0,
    justifyItems: 'stretch',
    alignItems: 'baseline'
  },
  totalCell: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5)
  },
  rowLabel: {
    color: theme.palette.text.secondary
  }
}));

const usePopperStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: theme.direction === 'rtl' && {
    popper: {
      translate: '325px',
      right: 'unset !important'
    },
    arrow: {
      transform: 'unset',
      marginRight: 'unset'
    }
  }
}));

export const ActualSummaryPeriodPopup = ({
  startDate,
  endDate,
  roleAllocatedHours,
  roleActualHours,
  scheduledHours,
  onClose,
  anchorEl,
  userActualTotalHours,
  userAllocatedTotalHours,
  roleDisplayText
}) => {
  const classes = useStyles();
  const popperClasses = usePopperStyles();
  const values = useMemo(() => ({ scheduledHours }), [scheduledHours]);

  const totalRowClasses = useMemo(
    () => ({
      cell: classes.totalCell
    }),
    [classes]
  );

  const rowLabelSecondaryStyles = useMemo(
    () => ({
      rowLabel: classes.rowLabel
    }),
    [classes]
  );

  const periodKey = startDate.toString();

  const totalAllocatedHours =
    (userAllocatedTotalHours || []).find(u => periodKey === u.key)
      ?.totalHours || 0;
  const totalActualHours =
    (userActualTotalHours || {})[periodKey]?.totalHours || 0;

  const otherRoleAllocatedHours = totalAllocatedHours - roleAllocatedHours;
  const otherRoleActualHours = totalActualHours - roleActualHours;

  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  return (
    <ArrowPopup
      id="actuals-timeline-popup"
      anchorEl={anchorEl}
      placement="bottom"
      onClose={onClose}
      preventOverflow
      classes={popperClasses}
    >
      <div className={classes.root} data-qe-id="ActualSummaryPeriodPopup">
        <div className={classes.heading}>
          <DateRange start={startDate} end={endDate} className={classes.date} />
          <div className={classes.subTitle} data-qe-id="scheduledHours">
            <FormattedMessage
              id="actualsSummaryPeriodPopup.scheduledHours"
              values={values}
            />
          </div>
        </div>
        {isPsaRmpTaskAllocation1Enabled ? (
          <ActualSummaryPeriodPopupContent
            totalActualHours={totalActualHours}
            totalAllocatedHours={totalAllocatedHours}
          />
        ) : (
          <div className={classes.actualsSummaryPopupRows}>
            <ActualSummaryPeriodPopupTableHeader />
            <ActualSummaryPeriodPopupRow
              allocatedHours={roleAllocatedHours}
              actualHours={roleActualHours}
              roleDisplayText={roleDisplayText}
              rowLabel={
                roleDisplayText
                  ? 'actualsSummaryPeriodPopup.roleDisplayText'
                  : 'actualsSummaryPeriodPopup.noRole'
              }
              classOverrides={
                !roleDisplayText ? rowLabelSecondaryStyles : undefined
              }
              qeIdTag={roleDisplayText || 'noRole'}
            />
            {Boolean(otherRoleAllocatedHours || otherRoleActualHours) && (
              <>
                <ActualSummaryPeriodPopupRow
                  rowLabel="actualsSummaryPeriodPopup.otherRoles"
                  allocatedHours={otherRoleAllocatedHours}
                  actualHours={otherRoleActualHours}
                  classOverrides={rowLabelSecondaryStyles}
                  qeIdTag="otherRolesRow"
                />
                <ActualSummaryPeriodPopupRow
                  rowLabel="actualsSummaryPeriodPopup.total"
                  allocatedHours={totalAllocatedHours}
                  actualHours={totalActualHours}
                  classOverrides={totalRowClasses}
                  qeIdTag="totalRow"
                />
              </>
            )}
          </div>
        )}
      </div>
    </ArrowPopup>
  );
};

ActualSummaryPeriodPopup.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  roleAllocatedHours: PropTypes.number,
  roleActualHours: PropTypes.number,
  scheduledHours: PropTypes.number,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  userActualTotalHours: PropTypes.object,
  userAllocatedTotalHours: PropTypes.array,
  roleDisplayText: PropTypes.string
};

export default ActualSummaryPeriodPopup;
