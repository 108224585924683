import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Button,
  IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import AddCircleIcon from '@material-ui/icons/AddCircleSharp';
import EditIcon from '@material-ui/icons/EditSharp';
import { useApolloClient } from '@apollo/client';
import { LoadingButton } from '~/modules/common/components';
import { ProjectRequestGateValue } from '~/types';
import { useMeContext } from '~/modules/me';
import { updateGatesInCache } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestDrawer/hooks';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import useAccordionStyles from '../useAccordionStyles';
import { Gate } from './Gate';
import { useGateChangeHandlers } from './useGateChangeHandlers';

const useStyles = makeStyles(theme => ({
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  gatesListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  gatesContainer: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  actionContainer: {
    marginTop: theme.spacing(1)
  },
  actionBtnContainer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center'
  },
  actionBtn: {
    marginLeft: theme.spacing(1)
  },
  errorText: {
    marginLeft: theme.spacing(2)
  }
}));
const expandIcon = <ExpandMoreIcon />;
const addCircleIcon = <AddCircleIcon />;

export const GatesAccordion = ({
  projectRequestId,
  formik,
  editableGates,
  setEditableGates
}) => {
  const {
    accordionClasses,
    summaryClasses,
    titleClass,
    summaryContainerClass
  } = useAccordionStyles();
  const classes = useStyles();

  const { featureFlags } = useMeContext();
  const { cache } = useApolloClient();
  const { canEditProjectRequest } = useProjectRequestObjectPermissions();

  const [expanded, setExpanded] = useState(false);
  const onChange = useCallback(() => setExpanded(!expanded), [expanded]);
  const {
    values: { gates },
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resetForm,
    errors
  } = formik;
  const checkedGates = useMemo(
    () => gates?.filter(x => x.value === ProjectRequestGateValue.Met),
    [gates]
  );
  const onEditGates = useCallback(() => {
    setEditableGates(true);
    updateGatesInCache({
      id: projectRequestId,
      gates,
      featureFlags
    })(cache);
  }, [cache, featureFlags, gates, projectRequestId, setEditableGates]);
  const onCancel = useCallback(() => {
    setEditableGates(false);
    resetForm();
  }, [resetForm, setEditableGates]);
  const gateChangeHandlers = useGateChangeHandlers({
    gates,
    setFieldValue,
    projectRequestId
  });

  const requiredDisplayTextError = useMemo(
    () => errors?.gates?.find(x => Boolean(x))?.displayText,
    [errors]
  );

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      classes={accordionClasses}
      data-qe-id="ProjectRequestGates"
    >
      <AccordionSummary expandIcon={expandIcon} classes={summaryClasses}>
        <div className={summaryContainerClass}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={titleClass}
          >
            <FormattedMessage id="projectRequest.reviewChecklist" />
          </Typography>
          {!expanded && gates?.length > 0 ? (
            <Typography variant="body2">{`${checkedGates.length}/${gates.length}`}</Typography>
          ) : null}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.gatesListContainer}>
          <div className={classes.gatesContainer}>
            {gates?.length > 0 ? (
              gates.map((gate, index) => (
                <Gate
                  key={gate.uri}
                  details={gate}
                  changeHandlers={gateChangeHandlers}
                  indexValue={index}
                  editable={editableGates}
                  errors={errors}
                  canEditProjectRequest={canEditProjectRequest}
                />
              ))
            ) : canEditProjectRequest && !editableGates ? (
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="projectRequest.noChecklistItemsWithEditPermission" />
              </Typography>
            ) : !editableGates ? (
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="projectRequest.noChecklistItems" />
              </Typography>
            ) : null}
          </div>
          {!editableGates && canEditProjectRequest ? (
            <IconButton onClick={onEditGates} data-qe-id="editGates">
              <EditIcon />
            </IconButton>
          ) : null}
        </div>
        {editableGates ? (
          <div className={classes.actionContainer}>
            <Button
              color="primary"
              startIcon={addCircleIcon}
              onClick={gateChangeHandlers.onAddGate}
              className={classes.actionBtn}
            >
              <FormattedMessage id="projectRequest.checklistItem" />
            </Button>
            <div className={classes.actionBtnContainer}>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.actionBtn}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                <FormattedMessage id="projectRequest.save" />
              </LoadingButton>
              <Button
                className={classes.actionBtn}
                variant="contained"
                color="default"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                <FormattedMessage id="addDialog.cancel" />
              </Button>
              <Typography
                variant="body2"
                color="error"
                className={classes.errorText}
              >
                {requiredDisplayTextError}
              </Typography>
            </div>
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
GatesAccordion.propTypes = {
  projectRequestId: PropTypes.string,
  formik: PropTypes.object,
  editableGates: PropTypes.bool,
  setEditableGates: PropTypes.func
};
export default GatesAccordion;
