import React from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import EditIcon from '@material-ui/icons/EditSharp';
import { IconButton } from '@material-ui/core';

import { useHasPermission } from '~/modules/common/permissions';

export const EditTaskButton = ({ onClick, intl }) => {
  const hasEditTaskPermission = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-tasks'
  });

  return (
    hasEditTaskPermission && (
      <IconButton
        data-qe-id="EditTaskButton"
        onClick={onClick}
        aria-label={intl.formatMessage({ id: 'taskDrawerEdit.editButtonAria' })}
      >
        <EditIcon />
      </IconButton>
    )
  );
};

EditTaskButton.propTypes = {
  onClick: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(EditTaskButton);
