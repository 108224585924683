import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import useStyles, { useLoadingStyles } from './useStyles';

export const LoadingMoreTooltipRow = ({ classes, loadingClasses }) => (
  <li className={classNames(classes.item, classes.allocation)}>
    <Skeleton variant="text" className={loadingClasses.tooltipSkeletonColor} />
    <span />
    <Skeleton
      variant="text"
      className={classNames(
        loadingClasses.tooltipSkeletonColor,
        loadingClasses.tooltipSkeletonItem
      )}
    />
    <Skeleton
      variant="text"
      className={classNames(
        loadingClasses.tooltipSkeletonColor,
        loadingClasses.tooltipSkeletonItem
      )}
    />
  </li>
);

const LoadingMoreTooltipSection = () => {
  const classes = useStyles();
  const loadingClasses = useLoadingStyles();

  return (
    <>
      <LoadingMoreTooltipRow
        classes={classes}
        loadingClasses={loadingClasses}
      />
      <LoadingMoreTooltipRow
        classes={classes}
        loadingClasses={loadingClasses}
      />
    </>
  );
};

LoadingMoreTooltipSection.propTypes = {};

LoadingMoreTooltipRow.propTypes = {
  loadingClasses: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default LoadingMoreTooltipSection;
