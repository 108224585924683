import { useState, useMemo, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import { isNullOrUndefined } from '~/modules/common/util';
import { SELECTED_STATUS } from '~/modules/billing-invoicing/common/components/BillingInvoicingTab/enum';

const getSelectableAvailableToBillRows = availableToBillRows =>
  availableToBillRows.filter(
    bill =>
      !isNullOrUndefined(bill.startDate) || !isNullOrUndefined(bill.endDate)
  );

export const useAvailableToBillSelectRows = ({
  availableToBillFilter,
  editable,
  availableToBillRows,
  isPsaPrpManualBulkBillCreationEnabled
}) => {
  const [internalEditable, setInternalEditable] = useState(editable);
  const [
    internalAvailableToBillFilter,
    setInternalAvailableToBillFilter
  ] = useState(availableToBillFilter);

  const [
    selectedAvailableToBillItems,
    setSelectedAvailableToBillItems
  ] = useState([]);

  const selectedBillCount = useMemo(
    () => ({ count: selectedAvailableToBillItems.length }),
    [selectedAvailableToBillItems.length]
  );

  const selectableAvailableToBillRows = useMemo(
    () => getSelectableAvailableToBillRows(availableToBillRows),
    [availableToBillRows]
  );

  const resetSelectedAvailableToBillItems = useCallback(() => {
    setSelectedAvailableToBillItems([]);
  }, []);

  const onSelectAllClick = useCallback(
    checked => {
      if (checked) {
        setSelectedAvailableToBillItems(
          selectableAvailableToBillRows.slice(0, 100)
        );
      } else {
        resetSelectedAvailableToBillItems();
      }
    },
    [resetSelectedAvailableToBillItems, selectableAvailableToBillRows]
  );

  const onSelectClick = useCallback(
    record => event => {
      const { checked } = event.target;

      const updatedSelectedAvailableToBillItems = checked
        ? [...selectedAvailableToBillItems, record]
        : selectedAvailableToBillItems.filter(x => x.id !== record.id);

      setSelectedAvailableToBillItems(updatedSelectedAvailableToBillItems);
    },
    [selectedAvailableToBillItems]
  );

  const selectedAvailableToBillItemSet = useMemo(
    () => new Set(selectedAvailableToBillItems.map(x => x.id)),
    [selectedAvailableToBillItems]
  );

  const isRowSelected = useCallback(
    record => selectedAvailableToBillItemSet.has(record.id),
    [selectedAvailableToBillItemSet]
  );

  const selectAllStatus = useMemo(() => {
    if (selectedAvailableToBillItems?.length === 0) {
      return SELECTED_STATUS.NONE;
    }

    if (selectedAvailableToBillItems?.length === availableToBillRows?.length) {
      return SELECTED_STATUS.ALL;
    }

    return SELECTED_STATUS.PARTIAL;
  }, [availableToBillRows?.length, selectedAvailableToBillItems?.length]);

  useEffect(() => {
    if (
      isPsaPrpManualBulkBillCreationEnabled &&
      !isEqual(editable, internalEditable)
    ) {
      resetSelectedAvailableToBillItems();
      setInternalEditable(editable);
    }
  }, [
    editable,
    internalEditable,
    isPsaPrpManualBulkBillCreationEnabled,
    resetSelectedAvailableToBillItems
  ]);

  useEffect(() => {
    if (
      isPsaPrpManualBulkBillCreationEnabled &&
      !isEqual(availableToBillFilter, internalAvailableToBillFilter)
    ) {
      resetSelectedAvailableToBillItems();
      setInternalAvailableToBillFilter(availableToBillFilter);
    }
  }, [
    availableToBillFilter,
    internalAvailableToBillFilter,
    isPsaPrpManualBulkBillCreationEnabled,
    resetSelectedAvailableToBillItems
  ]);

  return {
    selectedAvailableToBillItems,
    selectedBillCount,
    resetSelectedAvailableToBillItems,
    onSelectClick,
    isRowSelected,
    onSelectAllClick,
    selectAllStatus,
    hasAtleastOneSelectableAvailableToBillRow:
      selectableAvailableToBillRows?.length > 0
  };
};
