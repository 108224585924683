import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NoValue, Decimal, FormattedNumber } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  valueCell: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const useHoursVarianceStyles = makeStyles(theme => ({
  diffPositive: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

export const ActualSummaryPeriodPopupContent2 = ({ totalHours, hoursType }) => {
  const classes = useStyles();

  const hourVarianceStyles = useHoursVarianceStyles();

  const totalHoursValue = useMemo(
    () => ({
      value: <Decimal value={totalHours} />
    }),
    [totalHours]
  );

  return (
    <>
      <div className={classes.row}>
        <FormattedMessage id={`actualsSummaryPeriodPopup.${hoursType}`} />
        <span className={classes.valueCell} data-qe-id={`${hoursType}_hours`}>
          {totalHours ? (
            hoursType === 'totalDifference' ? (
              <FormattedNumber
                classes={hourVarianceStyles}
                value={totalHours}
                showAddIcon={totalHours < 0}
                isHigher={totalHours < 0}
                fixedDecimalScale
                formattedMessageKey="actualsSummaryPeriodPopup.hours"
              />
            ) : (
              <FormattedMessage
                id="actualsSummaryPeriodPopup.hours"
                values={totalHoursValue}
              />
            )
          ) : (
            <NoValue />
          )}
        </span>
      </div>
    </>
  );
};

ActualSummaryPeriodPopupContent2.propTypes = {
  totalHours: PropTypes.number,
  hoursType: PropTypes.string
};

export default ActualSummaryPeriodPopupContent2;
