import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BULK_RELEASE_TASK_OWNERSHIP = gql`
  mutation BulkReleaseTaskOwnership($input: BulkReleaseTaskOwnershipInput!) {
    bulkReleaseTaskOwnership(input: $input) {
      failedTaskIds
      succeededTaskIds
    }
  }
`;

export const useBulkReleaseTaskOwnership = () => {
  const [bulkReleaseTaskOwnership] = useMutation(BULK_RELEASE_TASK_OWNERSHIP);

  return {
    bulkReleaseTaskOwnership: ({ entries, projectId }) => {
      const taskIds = [
        ...new Set(
          entries.flatMap(
            ({ impactedTaskSummary }) => impactedTaskSummary.taskUris
          )
        )
      ];

      if (taskIds.length === 0) return Promise.resolve();

      return bulkReleaseTaskOwnership({
        variables: {
          input: {
            taskIds,
            taskOwnerId: entries[0].id,
            projectId
          }
        }
      });
    }
  };
};

export default useBulkReleaseTaskOwnership;
