import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import { useGetEnabledCustomFieldsQuery } from '~/modules/customFields/graphql';
import { hasValue } from '~/modules/customFields/utils';
import { CollapsibleDetailsPanel } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useMeContext } from '~/modules/me/useMeContext';
import { useClientPermissions } from '../../hooks';
import { useDialogStates } from './useDialogStates';
import { isBillingSameAsAddress } from './isBillingSameAsAddress';
import ClientInfoDetails from './ClientInfoDetails';
import {
  EditClientBasicInfoDialog,
  EditClientContactInfoDialog,
  EditClientAddressDialog,
  EditClientAdditionalInfoDialog,
  EditClientRepresentativesDialog
} from './dialogs';

export const ClientBasicInformation = ({ ...rest }) => {
  const isMobile = useIsBreakpointDown('sm');

  return isMobile ? (
    <CollapsibleDetailsPanel>
      <InfoCard {...rest} />
    </CollapsibleDetailsPanel>
  ) : (
    <InfoCard {...rest} />
  );
};

export const InfoCard = ({
  clientDetails,
  isLoading,
  isClientEditable: editable
}) => {
  const { canViewCustomFields } = useClientPermissions();
  const {
    featureFlags,
    hasViewClientBillingData,
    hasClientRepresentativeRole
  } = useMeContext();
  const {
    basicDialogOpen,
    openBasicDialog,
    closeBasicDialog,
    contactDialogOpen,
    openContactDialog,
    closeContactDialog,
    addressDialogOpen,
    openAddressDialog,
    closeAddressDialog,
    additionalInfoDialogOpen,
    openAdditionalInfoDialog,
    closeAdditionalInfoDialog,
    clientRepDialogOpen,
    openClientRepDialog,
    closeClientRepDialog
  } = useDialogStates();

  const { data: customFieldsData } = useGetEnabledCustomFieldsQuery({
    variables: { groupName: 'Client' }
  });

  const { formatMessage } = useIntl();

  const customFieldDefinitions =
    get(customFieldsData, 'enabledCustomFields') || [];

  const contactInfo = get(clientDetails, 'contactInfo', {});
  const billingContactInfo = get(clientDetails, 'billingContactInfo', {});
  const customFieldValues = get(clientDetails, 'customFieldValues');
  const clientRepresentatives = get(clientDetails, 'clientRepresentatives', []);
  const taxProfile = get(clientDetails, 'taxProfile', {}) || {
    id: null,
    displayText: formatMessage({ id: 'client.noTaxProfile' })
  };

  const showClientRepSection =
    hasClientRepresentativeRole &&
    (editable || clientRepresentatives.length > 0);

  const hasCustomFieldDefinitions = customFieldDefinitions.length > 0;
  const hasCustomFieldValues =
    customFieldValues &&
    customFieldValues.length > 0 &&
    customFieldValues.some(hasValue);
  const showAdditionalInfo =
    (canViewCustomFields && editable && hasCustomFieldDefinitions) ||
    hasCustomFieldValues;

  const billingSameAsAddress = isBillingSameAsAddress({
    contactInfo,
    billingContactInfo
  });

  return (
    <>
      <ClientInfoDetails
        openBasicDialog={openBasicDialog}
        openAdditionalInfoDialog={openAdditionalInfoDialog}
        openContactDialog={openContactDialog}
        openAddressDialog={openAddressDialog}
        openClientRepDialog={openClientRepDialog}
        edit={editable}
        details={clientDetails}
        taxProfile={taxProfile}
        contactInfo={contactInfo}
        billingSameAsAddress={billingSameAsAddress}
        billingContactInfo={billingContactInfo}
        showBillingSection={hasViewClientBillingData}
        showClientRepSection={showClientRepSection}
        clientRepresentatives={clientRepresentatives}
        showAdditionalInfo={showAdditionalInfo}
        isLoading={isLoading}
        featureFlags={featureFlags}
      />

      {!isLoading && (
        <>
          <EditClientBasicInfoDialog
            open={basicDialogOpen}
            onClose={closeBasicDialog}
            details={clientDetails}
          />
          <EditClientContactInfoDialog
            open={contactDialogOpen}
            onClose={closeContactDialog}
            details={clientDetails}
          />
          <EditClientAddressDialog
            open={addressDialogOpen}
            onClose={closeAddressDialog}
            details={clientDetails}
            billingSameAsAddress={billingSameAsAddress}
          />
          <EditClientAdditionalInfoDialog
            customFieldDefinitions={customFieldDefinitions}
            open={additionalInfoDialogOpen}
            onClose={closeAdditionalInfoDialog}
            details={clientDetails}
          />
          {showClientRepSection && (
            <EditClientRepresentativesDialog
              details={clientDetails}
              open={clientRepDialogOpen}
              onClose={closeClientRepDialog}
            />
          )}
        </>
      )}
    </>
  );
};

InfoCard.propTypes = {
  isClientEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  clientDetails: PropTypes.object
};

export default ClientBasicInformation;
