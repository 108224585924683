import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useDialogState } from '~/modules/common/hooks';
import ProjectList from './ProjectList/ProjectList';
import ConfirmProjectMoveDialog from './ConfirmProjectMoveDialog/ConfirmProjectMoveDialog';
import { useAddExistingProjectsFormProps } from './hooks/useAddExistingProjectsFormProps';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  }
}));

const AddExistingProjectForm = ({
  portfolioId,
  portfolioName,
  onCancel,
  enabledColumnGroups,
  enabledColumns,
  sort
}) => {
  const classes = useStyles();

  const { open, openDialog, closeDialog } = useDialogState();

  const {
    selectedProjects,
    selectedCount,
    onRowClick,
    submitting,
    handleSubmit,
    error,
    selectedCountValue,
    assignProjectsToPortfolio,
    selectedProjectsWithPortfolios
  } = useAddExistingProjectsFormProps({
    portfolioId,
    openDialog,
    onCancel
  });

  return (
    <>
      <DialogContent className={classes.content}>
        <ProjectList
          portfolioId={portfolioId}
          selectedProjects={selectedProjects}
          onRowClick={onRowClick}
          enabledColumnGroups={enabledColumnGroups}
          enabledColumns={enabledColumns}
          sort={sort}
          submitting={submitting}
        />
      </DialogContent>
      <DialogActions>
        {error && (
          <Typography color="error" variant="caption">
            <FormattedMessage id="addProject.submitError" />
          </Typography>
        )}
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          type="submit"
          disabled={selectedCount === 0 || submitting}
          color="primary"
          isLoading={submitting}
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="addProject.addXProjects"
            values={selectedCountValue}
          />
        </LoadingButton>
      </DialogActions>
      <ConfirmProjectMoveDialog
        open={open}
        onClose={closeDialog}
        onMove={assignProjectsToPortfolio}
        projects={selectedProjectsWithPortfolios}
        portfolioName={portfolioName}
      />
    </>
  );
};

AddExistingProjectForm.propTypes = {
  portfolioId: PropTypes.string,
  portfolioName: PropTypes.string,
  onCancel: PropTypes.func,
  enabledColumnGroups: PropTypes.array,
  enabledColumns: PropTypes.array,
  sort: PropTypes.object
};

export default AddExistingProjectForm;
