import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    width: 550
  },
  details: {
    flexDirection: 'column'
  }
}));

export const ResourceDrawerHeaderLoading = ({ classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <Skeleton width="20%" height={20} />
      <div className={classes.details}>
        <Skeleton width="80%" height={10} />
        <Skeleton width="80%" height={10} />
      </div>
    </div>
  );
};

ResourceDrawerHeaderLoading.propTypes = {
  classes: PropTypes.object
};

export default ResourceDrawerHeaderLoading;
