import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const microFrontendRenderer = (name, history, props) => () => {
  const renderFn = window[`render${name}`];

  if (!renderFn || typeof renderFn !== 'function') {
    return;
  }

  renderFn(`${name}-container`, { history, ...props });
};

const registerGlobal = (name, reference) => {
  if (!window[name]) {
    window[name] = reference;
  }
};

const unregisterGlobal = name => {
  if (window[name]) {
    delete window[name];
  }
};

const joinUrlParts = (root, subitem) => {
  const rootEndsWithSlash = root.charAt(root.length - 1) === '/';
  const subitemStartsWithSlash = subitem.charAt(0) === '/';

  if (rootEndsWithSlash && subitemStartsWithSlash) {
    return `${root.substring(0, root.length - 1)}${subitem}`;
  }
  if (
    (rootEndsWithSlash && !subitemStartsWithSlash) ||
    (!rootEndsWithSlash && subitemStartsWithSlash)
  ) {
    return `${root}${subitem}`;
  }

  return `${root}/${subitem}`;
};

const MicroFrontend = ({
  name,
  host,
  assetManifestResource = 'asset-manifest.json',
  className,
  props = {}
}) => {
  const containerName = `${name}-container`;
  const history = useHistory();

  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;
    const renderFrontend = microFrontendRenderer(name, history, props);

    registerGlobal('React', React);
    registerGlobal('ReactDOM', ReactDOM);

    const loadFrontend = async () => {
      const manifest = await fetch(joinUrlParts(host, assetManifestResource));

      if (manifest.status !== 200) {
        throw new Error(
          `Unable to load ${assetManifestResource} for ${name} from ${host}.`
        );
      }

      const resultData = await manifest.json();

      if (
        !resultData ||
        !resultData.entrypoints ||
        !Array.isArray(resultData.entrypoints)
      ) {
        throw new Error(`Unable to load entrypoint for ${name}.`);
      }

      const files = Object.values(resultData.files);

      resultData.entrypoints.forEach(entry => {
        // eslint-disable-next-line max-nested-callbacks
        const file = files.find(s => s.endsWith(entry));

        if (!file) {
          throw new Error('Unable to resolve file for entry');
        }

        const script = document.createElement('script');

        script.id = `${scriptId}_${entry}`;
        script.src = file.startsWith('http') ? file : joinUrlParts(host, file);
        script.onload = renderFrontend;
        document.head.appendChild(script);
      });
    };

    if (document.querySelectorAll(`script[id^=${scriptId}]`).length) {
      renderFrontend();
    } else {
      loadFrontend();
    }

    return () => {
      const unmountFn = window[`unmount${name}`];

      if (unmountFn && typeof unmountFn === 'function') {
        unmountFn(containerName);
      }

      unregisterGlobal('React');
      unregisterGlobal('ReactDOM');
    };
    // We never want this to reload once it's been mounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={className} id={containerName} />;
};

MicroFrontend.propTypes = {
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  assetManifestResource: PropTypes.string,
  className: PropTypes.string,
  props: PropTypes.object
};

export default MicroFrontend;
