import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(0, 0, 1, 0),
    textAlign: 'center'
  },
  dateRange: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'bold'
  },
  allocationList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0
  },
  item: {
    padding: 0,
    margin: 0,
    display: 'contents'
  },
  label: {
    textAlign: 'left',
    padding: theme.spacing(0, 1, 0, 0)
  },
  value: {
    textAlign: 'right',
    padding: theme.spacing(0, 0, 0, 1)
  },
  hours: {},
  loading: {},

  header: {},
  timeOff: {},
  allocation: {},
  totals: {
    '& $label, & $value': {
      fontWeight: 'bold',
      borderTop: '1px solid #fff',
      paddingTop: theme.spacing(0.25)
    }
  }
}));

export const useLoadingStyles = makeStyles(theme => ({
  tooltipSkeletonColor: {
    backgroundColor: 'rgba(255,255,255,0.11)'
  },
  tooltipSkeletonItem: {
    padding: 0,
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
