import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useSubstituteUserMetadata } from '~/modules/me/useSubstituteUserMetadata';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(1, 2),
    height: theme.spacing(4),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary,
    zIndex: theme.zIndex.drawer - 1,
    boxShadow: `0px 3px 1px -2px ${alpha(
      theme.palette.common.black,
      0.1
    )}, 0px 2px 2px 0px ${alpha(
      theme.palette.common.black,
      0.07
    )}, 0px 1px 5px 0px ${alpha(theme.palette.common.black, 0.06)}`, // theme.shadows[2],
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    },
    position: 'absolute',
    width: '100%'
  },
  message: {},
  user: {
    paddingLeft: theme.spacing(0.5)
  },
  returnButton: {
    margin: theme.spacing(-1, -1, -1, 3),
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  }
}));

export const SubstituteBanner = () => {
  const classes = useStyles();
  const substituteUserMetadata = useSubstituteUserMetadata();

  if (substituteUserMetadata) {
    return (
      <div className={classes.root}>
        <span className={classes.message}>
          {substituteUserMetadata.bannerText}
        </span>
        <span className={classes.user}>
          <strong>{substituteUserMetadata.userName}</strong>
        </span>
        <Button
          href={substituteUserMetadata.actionHref}
          color="primary"
          className={classes.returnButton}
        >
          {substituteUserMetadata.actionText}
        </Button>
      </div>
    );
  }

  return <></>;
};

export default SubstituteBanner;
