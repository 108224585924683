import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { Decimal, DateRange } from '~/modules/common/components';
import { ResourceAllocationStatus } from '~/types';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import { convertDurationToHours } from '~/modules/common/util';
import { ResourceAllocationRequestStatusBadge } from '~/modules/common/components/ResourceAllocationRequestStatusBadge';
import LoadMoreTooltipSection from './LoadMoreTooltipSection';
import useStyles from './useStyles';

export const AllocationSummaryPeriodContent = ({
  classes,
  entityName,
  allocatedHours,
  status = '',
  showStatus = false,
  scheduledHours
}) => (
  <li className={classNames(classes.item, classes.allocation)}>
    <span className={classes.label}>{entityName}</span>
    {showStatus && status !== ResourceAllocationStatus.Committed ? (
      <ResourceAllocationRequestStatusBadge status={status} />
    ) : (
      <span className={classes.label}></span>
    )}
    <Decimal
      value={allocatedHours}
      className={classNames(classes.value, classes.hours)}
    />
    <Decimal
      value={(allocatedHours * 100) / scheduledHours}
      suffix="%"
      className={classNames(classes.value, classes.loading)}
    />
  </li>
);

const getAllocationEnum = status => {
  switch (status) {
    case ResourceAllocationStatus.Draft:
      return ResourceAllocationStatus.Draft;
    case ResourceAllocationStatus.Proposed:
      return ResourceAllocationStatus.Proposed;
    case ResourceAllocationStatus.Committed:
      return ResourceAllocationStatus.Committed;
    case ResourceAllocationStatus.Rejected:
      return ResourceAllocationStatus.Rejected;
    default:
      return '';
  }
};

const ResourceUserPeriodSummaryContent = ({
  endDate,
  holidays,
  isAllocationsLazyLoaded,
  loading,
  resourceAllocations,
  scheduledHours,
  startDate,
  timeOffs,
  totalAllocatedHours,
  totalHoursByProject = []
}) => {
  const classes = useStyles();
  const dateClasses = useMemo(() => ({ date: classes.dateRange }), [
    classes.dateRange
  ]);

  const projectStatusHoursMap = useMemo(
    () =>
      isAllocationsLazyLoaded
        ? []
        : resourceAllocations
            .map(allocation => ({
              project: allocation.project,
              status: allocation.allocationStatus,
              allocatedHours: getTotalHoursForDateRangeFromScheduleRules({
                start: startDate,
                end: endDate,
                scheduleRules: allocation.scheduleRules
              })
            }))
            .filter(r => r.allocatedHours && r.allocatedHours !== 0)
            .reduce((acc, cur) => {
              if (cur.project && cur.project.displayText) {
                const currentStatus = getAllocationEnum(cur.status);

                if (!acc[`${cur.project.displayText}-${currentStatus}`]) {
                  acc[`${cur.project.displayText}-${currentStatus}`] = {
                    name: cur.project.displayText,
                    status: currentStatus,
                    allocatedHours: 0
                  };
                }
                acc[
                  `${cur.project.displayText}-${currentStatus}`
                ].allocatedHours += cur.allocatedHours;
              }

              return acc;
            }, {}),
    [resourceAllocations, startDate, endDate, isAllocationsLazyLoaded]
  );

  return (
    <>
      <div className={classes.title}>
        <DateRange start={startDate} end={endDate} classes={dateClasses} />
        <div>
          <Decimal
            value={scheduledHours}
            className={classNames(classes.value, classes.hours)}
          />{' '}
          <FormattedMessage id="resourceUserPeriodSummaryContent.scheduledHours" />
        </div>
      </div>
      <ul className={classes.allocationList}>
        <li className={classNames(classes.item, classes.header)}>
          <span className={classes.label}></span>
          <span className={classes.label}></span>
          <span className={classNames(classes.value, classes.hours)}>
            <FormattedMessage id="resourceUserPeriodSummaryContent.hours" />
          </span>
          <span className={classNames(classes.value, classes.percent)}></span>
        </li>
        {timeOffs.map(timeOff => {
          const { entries } = timeOff;
          const totalHours = entries
            .filter(
              e =>
                mapRepliconDateToUtcObject(e.date) >= startDate &&
                mapRepliconDateToUtcObject(e.date) <= endDate
            )
            .reduce((total, current) => total + current.hours, 0);

          return (
            <AllocationSummaryPeriodContent
              classes={classes}
              key={timeOff.timeOffType.displayText}
              allocatedHours={totalHours}
              entityName={timeOff.timeOffType.displayText}
              scheduledHours={scheduledHours}
            />
          );
        })}
        {holidays.map(holiday => (
          <AllocationSummaryPeriodContent
            classes={classes}
            key={holiday.name}
            allocatedHours={convertDurationToHours(
              holiday.duration ?? holiday.effectiveDuration
            )}
            entityName={holiday.name}
            scheduledHours={scheduledHours}
          />
        ))}
        {isAllocationsLazyLoaded ? (
          <>
            {totalHoursByProject.map(entry => (
              <AllocationSummaryPeriodContent
                classes={classes}
                showStatus
                status={entry.allocationStatus}
                key={entry.project.id}
                allocatedHours={entry.totalHours}
                scheduledHours={scheduledHours}
                entityName={entry.project.displayText}
              />
            ))}
            {loading && <LoadMoreTooltipSection />}
          </>
        ) : (
          Object.keys(projectStatusHoursMap).map(entityName => (
            <AllocationSummaryPeriodContent
              classes={classes}
              showStatus
              key={projectStatusHoursMap[entityName].name}
              allocatedHours={projectStatusHoursMap[entityName].allocatedHours}
              status={projectStatusHoursMap[entityName].status}
              entityName={projectStatusHoursMap[entityName].name}
              scheduledHours={scheduledHours}
            />
          ))
        )}
        <li className={classNames(classes.item, classes.totals)}>
          <span className={classes.label}>
            <FormattedMessage id="resourceUserPeriodSummaryContent.total" />
          </span>
          <span className={classes.label}></span>
          <Decimal
            value={totalAllocatedHours}
            className={classNames(classes.value, classes.hours)}
          />
          <Decimal
            value={(totalAllocatedHours * 100) / scheduledHours}
            suffix="%"
            className={classNames(classes.value, classes.loading)}
          />
        </li>
      </ul>
    </>
  );
};

ResourceUserPeriodSummaryContent.propTypes = {
  scheduledHours: PropTypes.number,
  resourceAllocations: PropTypes.array,
  holidays: PropTypes.array,
  timeOffs: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  totalAllocatedHours: PropTypes.number,
  totalHoursByProject: PropTypes.array,
  isAllocationsLazyLoaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool
};

AllocationSummaryPeriodContent.propTypes = {
  classes: PropTypes.object,
  entityName: PropTypes.string,
  allocatedHours: PropTypes.number,
  status: PropTypes.string,
  scheduledHours: PropTypes.number,
  showStatus: PropTypes.bool
};

export default ResourceUserPeriodSummaryContent;
