import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import DeleteForeverSharp from '@material-ui/icons/DeleteForeverSharp';
import { withStyles, Link, IconButton, Tooltip } from '@material-ui/core';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { AttachmentIcon } from '../../icons';
import DelaAttachmentStatus from './DelaAttachmentStatus';
import AttachmentsListSubTitleRow from './AttachmentsListSubTitleRow';

const styles = theme => ({
  root: { display: 'flex', paddingBottom: theme.spacing(2) },
  info: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 2)
  },
  actionButton: {
    marginTop: theme.spacing(-1.25)
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  }
});

export const AttachmentsListRow = ({
  classes,
  attachment,
  deleteAttachment,
  editable,
  setShowDelaBanner,
  isProjectAttachment
}) => {
  const isPsaPraaResourcingUIEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPraaResourcingUIEnabled'
  });

  const onDelete = useCallback(
    () =>
      deleteAttachment({
        uploadUri: attachment.uploadUri,
        type: attachment.type
      }),
    [attachment, deleteAttachment]
  );

  const primaryItem = useMemo(
    () => (
      <Link href={attachment.linkUri} target="_blank" rel="noopener noreferrer">
        {attachment.fileName || attachment.linkUri}
      </Link>
    ),
    [attachment.fileName, attachment.linkUri]
  );

  const secondaryItem = useMemo(
    () => <AttachmentsListSubTitleRow attachment={attachment} />,
    [attachment]
  );

  return (
    <div className={classes.root}>
      <AttachmentIcon attachment={attachment} />
      <div className={classes.info}>
        <Tooltip title={attachment.fileName}>{primaryItem}</Tooltip>
        {secondaryItem}
      </div>
      <div className={classes.spacer} />
      {isPsaPraaResourcingUIEnabled && isProjectAttachment && (
        <DelaAttachmentStatus
          attachment={attachment}
          setShowDelaBanner={setShowDelaBanner}
        />
      )}
      {editable && (
        <div className={classes.actionButton}>
          <IconButton color="secondary" aria-label="delete" onClick={onDelete}>
            <DeleteForeverSharp />
          </IconButton>
        </div>
      )}
    </div>
  );
};

AttachmentsListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  attachment: PropTypes.object.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  setShowDelaBanner: PropTypes.func.isRequired,
  isProjectAttachment: PropTypes.bool.isRequired
};

export default withStyles(styles)(AttachmentsListRow);
