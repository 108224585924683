import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useResourceAssignmentDialogContext } from './ResourceAssignmentDialogContext';
import { useAddUserToVendor } from './hooks/useAddUserToVendor';
import ResourceAssignmentList from './ResourceAssignmentList';
import { ResourceAssignmentDialogSearchBar } from './ResourceAssignmentDialogSearchBar';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold
  },
  searchBar: {
    overflow: 'visible'
  }
}));

export const ResourceAssignmentDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');
  const { vendor } = useResourceAssignmentDialogContext();

  const {
    onRowClick,
    submitting,
    handleSubmit,
    error,
    selectedUser
  } = useAddUserToVendor();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      disableBackdropClick
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h6" component="h1" className={classes.title}>
          <FormattedMessage id="vendorResourceAssignmentDialog.title" />
        </Typography>
        <Typography variant="h6">{vendor?.name}</Typography>
      </DialogTitle>
      <DialogContent className={classes.searchBar}>
        <ResourceAssignmentDialogSearchBar />
      </DialogContent>
      <DialogContent>
        <ResourceAssignmentList
          onRowClick={onRowClick}
          submitting={submitting}
          selectedUser={selectedUser}
        />
      </DialogContent>
      <DialogActions>
        {error && (
          <Typography color="error" variant="caption">
            <FormattedMessage id="vendorResourceAssignmentDialog.errorMsg" />
          </Typography>
        )}
        <Button onClick={onClose}>
          <FormattedMessage id="vendorResourceAssignmentDialog.close" />
        </Button>
        <LoadingButton
          type="submit"
          disabled={!selectedUser || submitting}
          color="primary"
          isLoading={submitting}
          onClick={handleSubmit}
        >
          <FormattedMessage id="vendorResourceAssignmentDialog.assignResource" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ResourceAssignmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ResourceAssignmentDialog;
