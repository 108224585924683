import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useIsRTL, useIsBreakpointDown } from '~/modules/common/hooks';
import { TIME_SERIES_LIST_COLUMN_TYPE } from '~/modules/common/enums';
import {
  ListTable,
  PAGINATION_TYPE,
  ListTableThemeProvider,
  ListTableNavigationContext
} from '~/modules/common/components/ListTable';
import { useListColumns } from './useListColumns';
import { useListHeaders } from './useListHeaders';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    }
  },
  tableHeaderCell: ({ isMobile }) => ({
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(isMobile ? 1 : 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    color: theme.palette.grey[600],
    fontSize: theme.typography.body2.fontSize,
    top: theme.spacing(12),
    '& span': {
      backgroundColor: 'inherit'
    }
  }),
  tableCell: ({ isMobile }) => ({
    height: isMobile ? 30 : 40,
    padding: theme.spacing(0, isMobile ? 1 : 2)
  }),
  headerCellWithNavigationButtons: { width: theme.spacing(17.5) }
}));

const TIME_SERIES_LIST_PAGE_SIZE = 10;

const TaskBurndownList = ({ task, records = [] }) => {
  const isMobile = useIsBreakpointDown('sm');
  const isRTL = useIsRTL();
  const providerClasses = useListTableThemeProviderStyles({ isMobile });
  const { endDate: taskEndDate } = task;

  const [currentPage, setCurrentPage] = useState(1);
  const timeSeriesListNavigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: TIME_SERIES_LIST_PAGE_SIZE,
      disableInfiniteScroll: true
    }),
    [setCurrentPage, currentPage]
  );

  const pageSeries = useMemo(
    () =>
      (records || []).slice(
        (currentPage - 1) * TIME_SERIES_LIST_PAGE_SIZE,
        currentPage * TIME_SERIES_LIST_PAGE_SIZE
      ),
    [records, currentPage]
  );

  const selectedColumns = [
    TIME_SERIES_LIST_COLUMN_TYPE.DATE,
    TIME_SERIES_LIST_COLUMN_TYPE.TOTAL_HOURS_WORKED_TO_DATE,
    TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_REMAINING_HOURS,
    TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_HOURS_AT_COMPLETION,
    ...(isMobile
      ? []
      : [TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_COMPLETION_DATE])
  ];

  const columns = useListColumns({ selectedColumns, isRTL, taskEndDate });
  const headers = useListHeaders({ selectedColumns });

  return (
    <ListTableThemeProvider classes={providerClasses}>
      <ListTableNavigationContext.Provider
        value={timeSeriesListNavigationContext}
      >
        <ListTable
          variant="table"
          records={pageSeries}
          headers={headers}
          columns={columns}
          elevation={0}
          paginationType={PAGINATION_TYPE.NAVIGATION}
        />
      </ListTableNavigationContext.Provider>
    </ListTableThemeProvider>
  );
};

TaskBurndownList.propTypes = {
  task: PropTypes.object,
  records: PropTypes.array
};

export default TaskBurndownList;
