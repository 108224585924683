import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ListAltIcon from '@material-ui/icons/ListAltSharp';

import {
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAssignedProjectsQuery } from '~/modules/common/hooks/useAssignedProjectsQuery';
import { useHasPermission } from '~/modules/common/permissions';
import { ResourceAllocationStatus } from '~/types';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import Decimal from '~/modules/common/components/Decimal';
import DateRange from '~/modules/common/components/DateRange';
import useStyles, { useDateRangeStyles } from './useStyles';
import User from './ProjectHistoryUser';

export const ProjectHistoryTableHeader = ({ classes }) => (
  <TableRow>
    <TableCell
      className={classNames(
        classes.projectName,
        classes.cell,
        classes.cellHeader
      )}
    >
      <FormattedMessage id="resourceDrawer.projectHistorySection.project" />
    </TableCell>
    <TableCell
      className={classNames(classes.range, classes.cell, classes.cellHeader)}
    >
      <FormattedMessage id="resourceDrawer.projectHistorySection.allocationDates" />
    </TableCell>
    <TableCell
      className={classNames(classes.manager, classes.cell, classes.cellHeader)}
    >
      <FormattedMessage id="resourceDrawer.projectHistorySection.projectManager" />
    </TableCell>
    <TableCell
      className={classNames(
        classes.hoursWorked,
        classes.cell,
        classes.cellHeader
      )}
      align="right"
    >
      <FormattedMessage id="resourceDrawer.projectHistorySection.hoursWorked" />
    </TableCell>
    <TableCell className={classNames(classes.cell, classes.cellHeader)}>
      <FormattedMessage id="resourceDrawer.projectHistorySection.role" />
    </TableCell>
  </TableRow>
);

ProjectHistoryTableHeader.propTypes = {
  classes: PropTypes.object
};

export const QUERY_LIMIT = 5;

export const ProjectHistorySection = ({ userUri }) => {
  const classes = useStyles();
  const intl = useIntl();
  const projectDateRangeClasses = useDateRangeStyles();

  const viewProjectPermission = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-project'
  });

  const {
    loadingProjects,
    resourceAllocations,
    nextPageCursor,
    loadMore
  } = useAssignedProjectsQuery(
    {
      userUri,
      allocationStatusList: [
        ResourceAllocationStatus.Committed,
        ResourceAllocationStatus.Proposed
      ],
      limit: QUERY_LIMIT
    },
    viewProjectPermission
  );

  const title = useMemo(
    () => (
      <>
        <ListAltIcon />
        <span className={classes.title}>
          <FormattedMessage id="resourceDrawer.projectHistory" />
        </span>
      </>
    ),
    [classes.title]
  );

  return (
    <InfoSection editable={false} title={title}>
      {!loadingProjects && resourceAllocations.length === 0 ? (
        <FormattedMessage id="resourceDrawer.noProjectsAllocated" />
      ) : loadingProjects && resourceAllocations.length === 0 ? (
        <Grid item className={classes.section}>
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
        </Grid>
      ) : (
        <Table
          className={classes.table}
          size="small"
          aria-label={intl.formatMessage({
            id: 'resourceDrawer.projectHistory'
          })}
          padding="none"
        >
          <TableHead>
            <ProjectHistoryTableHeader classes={classes} />
          </TableHead>
          <TableBody>
            {resourceAllocations.map(row => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classNames(classes.projectName, classes.cell)}
                >
                  <Typography variant="h6" className={classes.project}>
                    {row.project && row.project.displayText}
                  </Typography>
                  <DateRange
                    classes={projectDateRangeClasses}
                    start={row.project && row.project.startDate}
                    end={row.project && row.project.endDate}
                  />
                </TableCell>
                <TableCell className={classNames(classes.range, classes.cell)}>
                  <DateRange start={row.startDate} end={row.endDate} />
                </TableCell>
                <TableCell
                  className={classNames(classes.manager, classes.cell)}
                >
                  {row.project && row.project.projectManagerReference && (
                    <User user={row.project.projectManagerReference} />
                  )}
                </TableCell>
                <TableCell
                  className={classNames(classes.hoursWorked, classes.cell)}
                  align="right"
                >
                  <Decimal value={row.totalHoursWorked} precision={2} />
                </TableCell>
                <TableCell className={classes.cell}>
                  {row.role && row.role.displayText}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!loadingProjects && Boolean(nextPageCursor) && (
        <Button className={classes.showMore} color="primary" onClick={loadMore}>
          <FormattedMessage id="resourceDrawer.projectHistorySection.showMore" />
        </Button>
      )}
    </InfoSection>
  );
};

ProjectHistorySection.propTypes = {
  userUri: PropTypes.string.isRequired
};

export default ProjectHistorySection;
