import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from './AppContext';
import reducer from './reducer';

const initialState = {
  resourceRequestButtonActionMessage: null,
  recalculatingGanttChartData: false
};

const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValues = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppContextProvider;
