import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { DialogActions, makeStyles } from '@material-ui/core';
import { CancelButton, LoadingButton } from '~/modules/common/components';
import { hasErrors } from '~/util';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3, 0, 0)
  }
}));

export const NotificationSettingsDialogActions = ({ onClose }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    handleReset,
    isSubmitting,
    errors
  } = useFormikContext();

  const onCancelClick = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  const formError = hasErrors(errors);
  const disabled = isSubmitting || formError;

  return (
    <DialogActions className={classes.root}>
      <CancelButton onClick={onCancelClick} disabled={isSubmitting} />
      <LoadingButton
        color="primary"
        onClick={handleSubmit}
        disabled={disabled}
        isLoading={isSubmitting}
      >
        <FormattedMessage id="notificationSettingsDialog.save" />
      </LoadingButton>
    </DialogActions>
  );
};

NotificationSettingsDialogActions.propTypes = {
  onClose: PropTypes.func
};

export default NotificationSettingsDialogActions;
