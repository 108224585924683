import OfflinePage from './OfflinePage';

const offlineRoute = ({ intl, isMobile }) => ({
  title: () => intl.formatMessage({ id: 'routes.offline' }),
  hasPermission: true,
  component: OfflinePage,
  hidden: true,
  path: '/offline'
});

export default offlineRoute;
