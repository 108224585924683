import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const clientCostQuery = gql`
  query GetClientCostSeries($slug: String!, $dateRange: DateTimeRangeInput!) {
    client(slug: $slug) {
      id
      costSeriesData(dateRange: $dateRange) {
        currency {
          id
          symbol
        }
        dataPoints {
          period {
            periodStart: periodStart2
            periodEnd: periodEnd2
          }
          costAmountActual
          costAmountEstimated
        }
      }
    }
  }
`;

export const useClientCostSeries = ({
  chartDataInput: { slug, dateRange }
}) => {
  const { data, loading } = useQuery(clientCostQuery, {
    variables: { slug, dateRange }
  });

  return {
    loading,
    costCurrency: (!loading && data.client?.costSeriesData.currency) || null,
    costSeriesData: (!loading && data.client?.costSeriesData.dataPoints) || []
  };
};

export default useClientCostSeries;
