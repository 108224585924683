import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { ListItem, ListItemText } from '@material-ui/core';

const MobileProjectListItemSkeleton = () => (
  <ListItem dense>
    <ListItemText
      primary={<Skeleton variant="rect" height={32} width="100%" />}
    />
  </ListItem>
);

export default MobileProjectListItemSkeleton;
