import { gql } from 'graphql-tag';
import { CustomFieldValueFragmentDoc } from '~/types';

const checkedSpecificResourceFragment = gql`
  fragment CheckedSpecificResourceFragment on ResourceDetails {
    id
    uri
    slug
    displayText
    firstName
    lastName
    employeeId
    isEnabled
    startDate
    endDate
    email
    supervisorAssignment {
      supervisor {
        uri
        displayText
      }
      effectiveDate
    }
    department
    resourceSkill {
      category {
        id
        uri
        displayText
      }
      skillAssignments {
        id
        uri
        displayText
        certificate
        skillLevel {
          id
          uri
          displayText
          rating
        }
        skillCustomMetadata {
          uri
          displayText
          type
          text
          date
          number
        }
      }
    }
    roleDetails @include(if: $hasViewProjectRolePermission) {
      projectRole {
        uri
        name
      }
      isPrimary
    }
    roleScheduleDetails @include(if: $hasViewProjectRolePermission) {
      effectiveDate
      projectRoles {
        projectRole {
          uri
          name
        }
        isPrimary
      }
    }
    locale {
      id
      language {
        displayText
        id
      }
      timeZone {
        displayText
        id
      }
    }
    supervisorAssignments {
      supervisor {
        uri
        displayText
      }
      effectiveDate
    }
    costCenter @include(if: $hasViewCostCenterPermission) {
      effectiveDate
      displayText
      uri
      id
    }
    serviceCenter @include(if: $hasViewServiceCenterPermission) {
      effectiveDate
      displayText
      uri
      id
    }
    location @include(if: $hasViewLocationPermission) {
      effectiveDate
      displayText
      uri
      id
    }
    departmentGroup @include(if: $hasViewDepartmentPermission) {
      effectiveDate
      displayText
      uri
      id
    }
    employeeTypeGroup @include(if: $hasViewEmployeeTypePermission) {
      effectiveDate
      displayText
      uri
      id
    }
    division @include(if: $hasViewDivisionPermission) {
      effectiveDate
      displayText
      uri
      id
    }
    customFieldValues @include(if: $hasViewCustomFieldsPermission) {
      ...CustomFieldValue
    }
    permittedActions
  }
  ${CustomFieldValueFragmentDoc}
`;

export default checkedSpecificResourceFragment;
