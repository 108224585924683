/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';

import EditClientDialogActions from './EditClientDialogActions';
import { useUpdateClientOnSubmit } from './useUpdateClientOnSubmit';
import { FormErrorContext } from './FormErrorContext';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const editClientDialogRole = {
  role: 'presentation'
};

export const EditClientDialog = ({
  title,
  open,
  onClose,
  initialValues,
  validationSchema,
  mapValuesOnSubmit,
  children
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();

  const { onSubmit, error, clearError } = useUpdateClientOnSubmit({
    mapValuesOnSubmit,
    onClose
  });

  const errorContextValue = useMemo(() => ({ error, clearError }), [
    error,
    clearError
  ]);

  return (
    <FormErrorContext.Provider value={errorContextValue}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onReset={clearError}
        enableReinitialize
      >
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="md"
          fullScreen={isMobile}
          disableBackdropClick
          TransitionProps={editClientDialogRole}
          disableEscapeKeyDown
        >
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <DescriptionIcon className={classes.icon} />
            <Typography variant="h6">{title}</Typography>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          <EditClientDialogActions onClose={onClose} />
        </Dialog>
      </Formik>
    </FormErrorContext.Provider>
  );
};

EditClientDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  mapValuesOnSubmit: PropTypes.func,
  children: PropTypes.node
};

export default EditClientDialog;
