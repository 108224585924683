import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core';
import { PortfolioFullPath } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  th: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: theme.palette.background.paper
  }
}));

const portfolioCellStyles = makeStyles(theme => ({
  fullpath: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: theme.palette.text.secondary
  }
}));

const byName = (a, b) => {
  if (a.name === b.name) return 0;

  return a.name > b.name ? 1 : -1;
};

const ConfirmProjectMoveDialogTable = ({ projects }) => {
  const classes = useStyles();
  const portfolioClasses = portfolioCellStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.th}>
            <FormattedMessage id="projectList.project" />
          </TableCell>
          <TableCell className={classes.th}>
            <FormattedMessage id="projectList.portfolio" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projects.sort(byName).map(project => (
          <TableRow key={project.name}>
            <TableCell>{project.name}</TableCell>
            <TableCell>
              <PortfolioFullPath
                portfolio={project.portfolio}
                classes={portfolioClasses}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ConfirmProjectMoveDialogTable.propTypes = {
  projects: PropTypes.array
};

export default ConfirmProjectMoveDialogTable;
