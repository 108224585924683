import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { REJECTED_RESOURCE_REQUEST } from './withMarkResourceRequestAsRejected';

const useMarkResourceRequestAsRejected = ({ resourceRequest }) => {
  const [markResourceRequestAsRejected] = useMutation(
    REJECTED_RESOURCE_REQUEST
  );

  const onMarkResourceRequestAsRejected = useCallback(
    () =>
      markResourceRequestAsRejected(
        mapToServiceRequest({
          request: resourceRequest,
          responseType: 'markResourceRequestAsRejected2',
          responseTypeName: 'MarkResourceRequestAsRejectedResult',
          requestStatus: ResourceRequestStatus.Rejected
        })
      ),
    [markResourceRequestAsRejected, resourceRequest]
  );

  return {
    onMarkResourceRequestAsRejected
  };
};

export default useMarkResourceRequestAsRejected;
