import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import withDefaultIntlProvider from '~/modules/intl/withDefaultIntlProvider';
import { UnauthorizedDescription, UnauthorizedTitle } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
  container: {
    textAlign: 'center'
  },
  logo404: {
    width: '100%',
    maxWidth: '70%',
    marginBottom: theme.spacing(1)
  }
}));

export const UnauthorizedPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <UnauthorizedTitle />
        <UnauthorizedDescription />
      </div>
    </div>
  );
};

UnauthorizedPage.propTypes = {};

export default withDefaultIntlProvider(UnauthorizedPage);
