import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: '40px solid #E5E5E5'
  }
}));

const DonutChartLoading = ({ size }) => {
  const classes = useStyles();

  return (
    <Skeleton classes={classes} width={size} height={size} variant="circle" />
  );
};

DonutChartLoading.propTypes = {
  size: PropTypes.number.isRequired
};

export default DonutChartLoading;
