import { PropTypes } from 'prop-types';
import React from 'react';
import { BillingOverviewTab } from '~/modules/billing-invoicing/common/components';
import { useFixedRange } from '~/modules/common/charts/timeline/calculations';
import { getChartPeriodCount } from '~/modules/billing-invoicing/common/components/BillingInvoicingTab/enhancers';
import useClientBillingOverview from './useClientBillingOverview';

export const OverviewSubTab = ({ client, handleCallToAction, headerLevel }) => {
  const fixedRange = useFixedRange({
    getPeriodCount: getChartPeriodCount,
    anchor: 0,
    isPast: true,
    centerOffset: true
  });

  const { scale, dateRange } = fixedRange;

  const { result, loading } = useClientBillingOverview({
    dateRange,
    scale,
    client
  });

  return (
    <BillingOverviewTab
      result={result}
      loading={loading}
      fixedRange={fixedRange}
      handleCallToAction={handleCallToAction}
      headerLevel={headerLevel}
    />
  );
};

OverviewSubTab.propTypes = {
  client: PropTypes.object,
  handleCallToAction: PropTypes.func,
  headerLevel: PropTypes.number
};

export default OverviewSubTab;
