import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import {
  getTotalHoursForDateRangeFromScheduleRules,
  roundToDecimals
} from '~/modules/resourcing/common/util/scheduleUtil';
import TooltipLayout from '../../common/components/TooltipLayout';

const EditorPopoverTooltip = ({
  allocationRemainingVariance,
  endDate,
  otherTaskAllocationsSummaryScheduleRules,
  resourceAllocationScheduleRules,
  startDate,

  taskAllocationHours
}) => {
  const startDateUtc = mapRepliconDateToUtcObject(startDate);
  const endDateUtc = mapRepliconDateToUtcObject(endDate);

  const otherTaskAllocatedHours =
    otherTaskAllocationsSummaryScheduleRules &&
    getTotalHoursForDateRangeFromScheduleRules({
      start: startDateUtc,
      end: endDateUtc,
      scheduleRules: otherTaskAllocationsSummaryScheduleRules
    });
  const resourceAllocatedHours = getTotalHoursForDateRangeFromScheduleRules({
    start: startDateUtc,
    end: endDateUtc,
    scheduleRules: resourceAllocationScheduleRules
  });

  const calculationRows = useMemo(
    () => [
      {
        messageId: 'taskAllocationEditor.projectAllocation',
        value: roundToDecimals(resourceAllocatedHours)
      },
      {
        messageId: 'taskAllocationEditor.allocatedToOtherTask',
        value: roundToDecimals(otherTaskAllocatedHours || 0)
      },
      {
        messageId: 'taskAllocationEditor.thisTaskAllocation',
        value: taskAllocationHours
      }
    ],
    [otherTaskAllocatedHours, resourceAllocatedHours, taskAllocationHours]
  );

  const resultRow = useMemo(
    () => [
      {
        messageId: 'taskAllocationEditor.projectAllocationRemaining',
        value: allocationRemainingVariance
      }
    ],
    [allocationRemainingVariance]
  );

  return (
    <TooltipLayout
      calculationRows={calculationRows}
      resultRow={resultRow}
      startDate={startDateUtc}
      endDate={endDateUtc}
    />
  );
};

EditorPopoverTooltip.propTypes = {
  endDate: PropTypes.object,
  otherTaskAllocationsSummaryScheduleRules: PropTypes.array,
  allocationRemainingVariance: PropTypes.number,
  resourceAllocationScheduleRules: PropTypes.array,
  startDate: PropTypes.object,
  taskAllocationHours: PropTypes.number
};

export default EditorPopoverTooltip;
