import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { AddFabButton } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useRequiredCustomFieldDefinitions } from '~/modules/customFields/hooks/useRequiredCustomFieldDefinitions';
import { useTableSettings } from '~/modules/common/components/ListTable';
import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import useClientCurrency from '~/modules/projects/project/common/hooks/useClientCurrency';
import { useClientProjectsContext } from '../ClientProjectsContext';
import ClientProjectsContextProvider from '../ClientProjectsContextProvider';
import useClientPageEditContext from '../../ClientInfo/useClientPageEditContext';
import { CLIENT_PAGE_HEADER_QUERY } from '../../graphql';
import { useCanAddClientProject } from './useCanAddClientProject';
import AddClientProjectDialog from './AddClientProjectDialog';
import { CLIENT_PROJECTS_DIALOG_TABLE_KEY } from './clientProjectsDialogTableSettings';

const AddClientProject = ({ slug, ...rest }) => {
  const intl = useIntl();
  const { editable } = useClientPageEditContext();
  const { projectType } = useClientProjectsContext();
  const { open, closeDialog, openDialog } = useDialogState();
  const canAddProject = useCanAddClientProject();

  const { data } = useQuery(CLIENT_PAGE_HEADER_QUERY, {
    variables: { slug }
  });

  const clientName = get(data, 'client.name');
  const clientId = get(data, 'client.id');

  const { clientCurrency } = useClientCurrency({
    client: data && data.client
  });

  const {
    customFieldDefinitionsLoading,
    customFieldDefinitions
  } = useRequiredCustomFieldDefinitions('Project');

  const { enabledProjectColumnGroups } = useEnabledProjectColumns();

  const {
    data: tableSettings,
    loading: tableSettingsLoading
  } = useTableSettings({
    tableKey: CLIENT_PROJECTS_DIALOG_TABLE_KEY
  });

  const enabledColumns = (tableSettings && tableSettings.columns) || [];
  const sort = tableSettings && tableSettings.sort;

  if (customFieldDefinitionsLoading || tableSettingsLoading) return null;

  return (
    <>
      {editable && canAddProject && (
        <AddFabButton
          label={intl.formatMessage({ id: 'projectAddFab.label' })}
          shortLabel={intl.formatMessage({ id: 'projectAddFab.shortLabel' })}
          dataQeId="addProjectOpenDialog"
          onClick={openDialog}
        />
      )}
      <ClientProjectsContextProvider>
        {open && (
          <AddClientProjectDialog
            clientId={clientId}
            clientName={clientName}
            clientCurrency={clientCurrency}
            projectType={projectType}
            open={open}
            onClose={closeDialog}
            customFieldDefinitions={customFieldDefinitions}
            enabledColumnGroups={enabledProjectColumnGroups}
            enabledColumns={enabledColumns}
            sort={sort}
            clientSlug={slug}
          />
        )}
      </ClientProjectsContextProvider>
    </>
  );
};

AddClientProject.propTypes = {
  clientUri: PropTypes.string,
  slug: PropTypes.string
};

export default AddClientProject;
