import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogState, useSessionStorage } from '~/modules/common/hooks';
import { ResourceRequestAccessLevel, ResourceRequestStatus } from '~/types';
import {
  useRedirectToAssignResource,
  useDeleteResourceAllocationsForResourceRequestHandler
} from '~/modules/resourcing/hooks';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY, {
  RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
} from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import {
  useMarkResourceRequestAsCompleteAndMerge,
  useDeleteResourceRequestHandler,
  useResourceRequestAllocationActions,
  useRequestStatusHandlers,
  useSecondaryResourceRequestActionHandlers
} from '../hooks';
import MergeResourceRequestDialog from './MergeResourceRequestDialog';
import RequestActions from './RequestActions';
import ResourceRequestDeleteActions from './ResourceRequestDeleteActions';
import ResourceAllocationDeleteActions from './ResourceAllocationDeleteActions';
import ReleaseResourceRequestDialog from './ReleaseResourceRequestDialog';

export const ResourceRequestAllocationActions = ({
  project,
  resourceRequest,
  isProjectManagerView,
  isProjectResourcingTab,
  requestAccessLevel,
  chartDisplayDateRange,
  allocationChartRef,
  isResourceActualModeEnabled,
  isResourceRequestMergeEnabled,
  mergeDialogState,
  scale,
  displayUnit,
  cacheUpdateParams
}) => {
  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const resourceRequestsQuery =
    resourceRequestsQueryVariables?.showTimeOff ||
    resourceRequestsQueryVariables?.showHolidays
      ? RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
      : RESOURCE_REQUESTS_ALLOCATIONS_QUERY;

  const {
    onMarkResourceRequestAsCompleteAndMerge,
    isLoading: isMergeLoading
  } = useMarkResourceRequestAsCompleteAndMerge({
    resourceRequest,
    allocationChartRef,
    cacheUpdateParams
  });

  const {
    onResourceRequestToBeHired,
    onResourceRequestSubmit,
    onResourceRequestAccept,
    onResourceRequestReject,
    onResourceRequestAllocationReject,
    onResourceRequestFulfilled,
    onResourceRequestUnsubmit,
    onReleaseResourceAllocationsForRequest,
    onResourceRequestMerge,
    onResourceRequestProposeTopMatch
  } = useRequestStatusHandlers({
    resourceRequest,
    requestAccessLevel,
    chartDisplayDateRange,
    allocationChartRef,
    mergeDialogState,
    onMarkResourceRequestAsCompleteAndMerge
  });

  const { deleteResourceRequestFromProject } = useDeleteResourceRequestHandler({
    isResourceActualModeEnabled
  });

  const {
    deleteResourceAllocationsForResourceRequest
  } = useDeleteResourceAllocationsForResourceRequestHandler({
    isResourceActualModeEnabled,
    resourceRequestsQuery,
    resourceRequestsQueryVariables
  });

  const { permittedActionUris = [] } = project || {};

  const canEditProject = permittedActionUris.includes(
    'urn:replicon:project-action:edit-project'
  );

  const canEditTask = permittedActionUris.includes(
    'urn:replicon:project-action:edit-tasks'
  );

  const canEditTeam = permittedActionUris.includes(
    'urn:replicon:project-action:edit-team'
  );

  const { projectUri } = resourceRequest;

  const history = useHistory();
  const onAssignResourceAction = useRedirectToAssignResource({
    history,
    resourceRequest,
    isResourcingTab: !isProjectManagerView,
    scale,
    displayUnit
  });

  const {
    onResourceRequestDuplicate,
    onResourceRequestSplit
  } = useSecondaryResourceRequestActionHandlers({
    resourceRequest,
    isResourceActualModeEnabled
  });

  const {
    open: showReleaseResourcesDialog,
    openDialog: openReleaseResourcesDialog,
    closeDialog: _closeReleaseResourcesDialog
  } = useDialogState(false);

  const closeReleaseResourcesDialog = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

      _closeReleaseResourcesDialog();
    },
    [_closeReleaseResourcesDialog]
  );

  const {
    open: showRemoveAllocationDialog,
    openDialog: openRemoveAllocationDialog,
    closeDialog: closeRemoveAllocationDialog
  } = useDialogState(false);

  const {
    open: showRemoveRequestDialog,
    openDialog: openRemoveRequestDialog,
    closeDialog: closeRemoveRequestDialog
  } = useDialogState(false);

  const onDeleteRequestClick = useCallback(() => {
    closeRemoveRequestDialog();
    deleteResourceRequestFromProject({
      projectUri,
      resourceRequestsQueryVariables: resourceRequestsQueryVariables
        ? {
            ...resourceRequestsQueryVariables,
            projectUri
          }
        : null,
      request: resourceRequest
    });
  }, [
    closeRemoveRequestDialog,
    deleteResourceRequestFromProject,
    projectUri,
    resourceRequest,
    resourceRequestsQueryVariables
  ]);

  const onRemoveAllAllocations = useCallback(() => {
    closeRemoveAllocationDialog();
    deleteResourceAllocationsForResourceRequest({
      projectUri,
      resourceRequest
    });
  }, [
    closeRemoveAllocationDialog,
    deleteResourceAllocationsForResourceRequest,
    projectUri,
    resourceRequest
  ]);

  const actions = useResourceRequestAllocationActions({
    isProjectResourcingTab,
    canEditProject,
    canEditTeam,
    resourceRequest,
    onResourceRequestSubmit,
    onResourceRequestSplit,
    onResourceRequestAccept,
    onResourceRequestAllocationReject,
    onResourceRequestUnsubmit,
    onResourceRequestDuplicate,
    openRemoveRequestDialog,
    openReleaseResourcesDialog,
    openRemoveAllocationDialog,
    onResourceRequestFulfilled,
    onAssignResourceAction,
    onResourceRequestReject,
    onResourceRequestToBeHired,
    onResourceRequestMerge,
    onResourceRequestProposeTopMatch,
    mergeDialogState,
    isResourceRequestMergeEnabled
  });

  return (
    <>
      <RequestActions actions={actions} />
      {showRemoveRequestDialog && (
        <ResourceRequestDeleteActions
          showRemoveRequestDialog={showRemoveRequestDialog}
          closeRemoveRequestDialog={closeRemoveRequestDialog}
          onDeleteRequestClick={onDeleteRequestClick}
        />
      )}
      {showReleaseResourcesDialog && (
        <ReleaseResourceRequestDialog
          onResourceRequestSubmit={onResourceRequestSubmit}
          onReleaseResourceAllocationsForRequest={
            onReleaseResourceAllocationsForRequest
          }
          resourceRequest={resourceRequest}
          open={showReleaseResourcesDialog}
          onCancelClick={closeReleaseResourcesDialog}
          canEditTask={canEditTask}
        />
      )}
      {showRemoveAllocationDialog && (
        <ResourceAllocationDeleteActions
          showRemoveAllocationDialog={showRemoveAllocationDialog}
          closeRemoveAllocationDialog={closeRemoveAllocationDialog}
          onDeleteAllocationClick={onRemoveAllAllocations}
        />
      )}
      {isResourceRequestMergeEnabled &&
        resourceRequest.requestStatus === ResourceRequestStatus.Tentative &&
        mergeDialogState.existingAllocationState.hasExistingAllocation && (
          <MergeResourceRequestDialog
            open={mergeDialogState.open}
            onClose={mergeDialogState.closeDialog}
            resourceRequest={resourceRequest}
            onMarkResourceRequestAsCompleteAndMerge={
              onMarkResourceRequestAsCompleteAndMerge
            }
            isMergeLoading={isMergeLoading}
          />
        )}
    </>
  );
};

ResourceRequestAllocationActions.propTypes = {
  project: PropTypes.object.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  isProjectManagerView: PropTypes.bool.isRequired,
  isProjectResourcingTab: PropTypes.bool,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  chartDisplayDateRange: PropTypes.object,
  allocationChartRef: PropTypes.object,
  isResourceActualModeEnabled: PropTypes.bool,
  isResourceRequestMergeEnabled: PropTypes.bool,
  mergeDialogState: PropTypes.object,
  scale: PropTypes.string,
  displayUnit: PropTypes.string,
  cacheUpdateParams: PropTypes.object
};

export default ResourceRequestAllocationActions;
