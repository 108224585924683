const createScheduleRulesEntry = ({ startDate, endDate, setHours }) => ({
  dateRange: {
    startDate,
    endDate,
    __typename: 'DateRange'
  },
  do: {
    load: 100,
    setHours,
    excludeWeekdays: []
  },
  __typename: 'ScheduleRule'
});

export const getScheduleRulesFromAvailabilityPeriods = ({
  availabilityPeriods,
  availabilityFactor = 1
}) => {
  const scheduleRules = [];

  if (availabilityPeriods.length === 0) return scheduleRules;

  let isFirstWorkingEntryMatched = false;
  let startDateOfSchedulePeriod = null;
  let scheduleHoursForSchedulePeriod = null;

  for (let i = 0; i < availabilityPeriods.length; i++) {
    const { date, hours: periodHours } = availabilityPeriods[i];
    const hours = periodHours * availabilityFactor;

    if (!isFirstWorkingEntryMatched) {
      startDateOfSchedulePeriod = date;
      scheduleHoursForSchedulePeriod = hours;
      isFirstWorkingEntryMatched = true;
      continue;
    }

    if (hours !== scheduleHoursForSchedulePeriod) {
      const previousEntry = availabilityPeriods[i - 1];

      scheduleRules.push(
        createScheduleRulesEntry({
          startDate: startDateOfSchedulePeriod.toISO(),
          endDate: previousEntry.date.toISO(),
          setHours: scheduleHoursForSchedulePeriod
        })
      );

      startDateOfSchedulePeriod = date;
      scheduleHoursForSchedulePeriod = hours;
    }
  }

  const entry = availabilityPeriods[availabilityPeriods.length - 1];

  scheduleRules.push(
    createScheduleRulesEntry({
      startDate: startDateOfSchedulePeriod.toISO(),
      endDate: entry.date.toISO(),
      setHours: scheduleHoursForSchedulePeriod
    })
  );

  return scheduleRules;
};

export const getScheduleRulesFromAllocationPeriods = sortedAllocationPeriods =>
  getScheduleRulesFromAvailabilityPeriods({
    availabilityPeriods: sortedAllocationPeriods,
    availabilityFactor: 1
  });
