import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { useMeContext } from '~/modules/me';
import AddResourceEstimateDialogHeader from './AddResourceEstimateDialogHeader';
import AddResourceEstimateDialogContent from './AddResourceEstimateDialogContent';
import AddResourceEstimateDialogContent2 from './AddResourceEstimateDialogContent2';
import AddResourceEstimateDialogFooter from './AddResourceEstimateDialogFooter';
import AddResourceEstimateDialogFooter2 from './AddResourceEstimateDialogFooter2';
import useAddResourceEstimateDialogFormState from './useAddResourceEstimateDialogFormState';
import useGetTotalResourceEstimateHoursForTask from './useGetTotalResourceEstimateHoursForTask';

const AddResourceEstimateDialog = ({
  task,
  isOpen,
  onClose,
  resourceEstimate
}) => {
  const [role, setRole] = useState(task.assignedRole || null);
  const [resource, setResource] = useState(null);

  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();
  const canEditCompletedResourceAllocation = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  const {
    loading,
    totalResourceEstimateHours
  } = useGetTotalResourceEstimateHoursForTask({
    taskId: task.id,
    skip: Boolean(resourceEstimate)
  });

  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  const {
    initialValues,
    validationSchema
  } = useAddResourceEstimateDialogFormState({
    task,
    totalResourceEstimateHours,
    resourceEstimate,
    canEditCompletedResourceAllocation
  });

  return (
    <Dialog maxWidth="xs" fullWidth open={isOpen} onClose={onClose}>
      <AddResourceEstimateDialogHeader
        task={task}
        isEditMode={Boolean(resourceEstimate)}
      />
      {isRmpTaskAllocationPhase2Enabled ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          enableReinitialize
        >
          <>
            <AddResourceEstimateDialogContent2 loading={loading} task={task} />
            <AddResourceEstimateDialogFooter2
              projectId={task.project.id}
              resourceEstimates={resourceEstimates}
              taskId={task.id}
              onClose={onClose}
              setFieldValue={setFieldValue}
            />
          </>
        </Formik>
      ) : (
        <>
          <AddResourceEstimateDialogContent
            projectSlug={task.projectReference.slug}
            role={role}
            setRole={setRole}
            resource={resource}
            setResource={setResource}
            taskId={task.id}
          />
          <AddResourceEstimateDialogFooter
            taskId={task.id}
            role={role}
            resource={resource}
            onClose={onClose}
          />
        </>
      )}
    </Dialog>
  );
};

AddResourceEstimateDialog.propTypes = {
  task: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  resourceEstimate: PropTypes.object
};

export default AddResourceEstimateDialog;
