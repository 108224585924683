import { ResourceRequestStatus, ResourceAllocationStatus } from '~/types';

export const getStatusBasedClassName = (status, prefix = '') =>
  status === ResourceRequestStatus.Complete ||
  status === ResourceRequestStatus.MergedArchived
    ? `${prefix}Complete`
    : status === ResourceRequestStatus.Tentative
    ? `${prefix}Tentative`
    : status === ResourceRequestStatus.Tobehired
    ? `${prefix}ToBeHired`
    : status === ResourceRequestStatus.Rejected
    ? `${prefix}Rejected`
    : status === ResourceRequestStatus.Allocationrejected
    ? `${prefix}AllocationRejected`
    : status === ResourceRequestStatus.Submitted
    ? `${prefix}Submitted`
    : `${prefix}Draft`;

export const getAllocationStatusBasedClassName = (status, prefix = '') =>
  status === ResourceAllocationStatus.Draft
    ? `${prefix}Draft`
    : status === ResourceAllocationStatus.Proposed
    ? `${prefix}Proposed`
    : status === ResourceAllocationStatus.Rejected
    ? `${prefix}Rejected`
    : `${prefix}Committed`;

export const getAssignedClassName = (status, prefix = '') =>
  status === ResourceRequestStatus.Allocationrejected ||
  status === ResourceRequestStatus.Rejected
    ? `${prefix}Rejected`
    : status === ResourceRequestStatus.Tobehired
    ? `${prefix}ToBeHired`
    : status === ResourceRequestStatus.Complete
    ? `${prefix}Complete`
    : status === ResourceRequestStatus.Tentative
    ? `${prefix}Proposed`
    : status === ResourceRequestStatus.Submitted
    ? `${prefix}Submitted`
    : `${prefix}Request`;
