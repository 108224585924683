import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { App } from '~/modules/App';

/* eslint-disable */
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_PERF === 'true') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  whyDidYouRender(React);
}

if (process.env !== 'production') {
  if (module.hot) {
    module.hot.accept();
  }
}
/* eslint-enable */

ReactDOM.render(<App />, document.getElementById('root'));
