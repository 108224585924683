import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';

import AddIcon from '@material-ui/icons/AddSharp';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: 12,
    width: 'auto',
    height: theme.spacing(7),
    minWidth: theme.spacing(7),
    borderRadius: theme.spacing(7 / 2),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  extended: {
    '& $textLabel': {
      marginLeft: theme.spacing(1),
      opacity: 1
    }
  },

  textLabel: {
    margin: 0,
    width: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    opacity: 0,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  }
}));

const overflowRegex = /(auto|scroll)/;
const getScrollParent = node => {
  let scrollNode = node;

  while (scrollNode) {
    if (scrollNode.parentElement) {
      const parentStyles = getComputedStyle(scrollNode.parentElement);
      const regexTest = overflowRegex.test(
        parentStyles.overflow + parentStyles.overflowX + parentStyles.overflowY
      );

      return regexTest ? scrollNode.parentElement : false;
    }
    scrollNode = scrollNode.parentElement;
  }

  return false;
};

export const AddFabButton = ({
  className,
  visible = true,
  label,
  shortLabel,
  onClick,
  dataQeId,
  color = 'primary'
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [variant, setVariant] = useState('extended');
  const ref = useRef();
  const textLabelRef = useRef();

  useLayoutEffect(() => {
    if (textLabelRef.current) textLabelRef.current.style.width = '0';
    const requestID = requestAnimationFrame(() => {
      if (textLabelRef.current && variant === 'extended') {
        textLabelRef.current.style.width = `${textLabelRef.current.scrollWidth}px`;
      }
    });

    return () => {
      cancelAnimationFrame(requestID);
    };
  }, [variant, visible]);

  useEffect(() => {
    const scrollParent = getScrollParent(ref.current);
    let lastScroll = scrollParent.scrollTop;
    let scrollPivot = lastScroll;
    let down = true;

    const handleScroll = event => {
      const currentScroll = scrollParent.scrollTop;
      const scrollDiff = currentScroll - lastScroll;

      if ((down && scrollDiff < 0) || (!down && scrollDiff > 0)) {
        scrollPivot = lastScroll;
        down = scrollDiff > 0;
      }

      const pivotDiff = Math.abs(currentScroll - scrollPivot);

      if (pivotDiff > 32) {
        setVariant(lastVariant => {
          if (down && lastVariant !== 'round') return 'round';

          if (!down && lastVariant !== 'extended') return 'extended';

          return lastVariant;
        });
      }
      lastScroll = currentScroll;
    };

    scrollParent.addEventListener('scroll', handleScroll, true);

    return () => scrollParent.removeEventListener('scroll', handleScroll);
  }, [visible]);

  const zoomTimeOut = useMemo(
    () => ({
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    }),
    [
      theme.transitions.duration.enteringScreen,
      theme.transitions.duration.leavingScreen
    ]
  );

  const fabClasses = useMemo(
    () => ({
      root: classes.root,
      extended: classes.extended
    }),
    [classes.root, classes.extended]
  );

  return (
    <Zoom in={visible} timeout={zoomTimeOut} unmountOnExit>
      <Fab
        ref={ref}
        color={color}
        aria-label={label}
        data-qe-id={dataQeId}
        variant={variant}
        onClick={onClick}
        className={className}
        classes={fabClasses}
      >
        <AddIcon />
        <span ref={textLabelRef} className={classes.textLabel}>
          {!shortLabel ? label : shortLabel}
        </span>
      </Fab>
    </Zoom>
  );
};

AddFabButton.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  shortLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  dataQeId: PropTypes.string,
  color: PropTypes.string
};

export default AddFabButton;
