import { useCallback, useState } from 'react';

export const getDrawerHasUnsavedChangesProps = ({
  resourceAssignmentFormik,
  editableGates
}) => {
  const unsavedChangesWarningMessageId =
    resourceAssignmentFormik.dirty && editableGates
      ? 'projectRequest.unsavedChangesPrompt.unsavedRequirementAndChecklistWarning'
      : resourceAssignmentFormik.dirty
      ? 'projectRequest.unsavedChangesPrompt.unsavedResourceRequirementWarning'
      : 'projectRequest.unsavedChangesPrompt.unsavedChecklistItemsWarning';

  return {
    isDirty: resourceAssignmentFormik.dirty || editableGates,
    unsavedChangesWarningMessageId
  };
};

const useUnsavedChangesDialogProps = ({
  editableGates,
  onClose,
  resourceAssignmentFormik
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [warningMessageId, setWarningMessageId] = useState('');

  const onDrawerCloseWithWarning = useCallback(() => {
    const {
      isDirty,
      unsavedChangesWarningMessageId
    } = getDrawerHasUnsavedChangesProps({
      resourceAssignmentFormik,
      editableGates
    });

    if (!isDirty) {
      setOpenDialog(false);
      onClose();
    } else {
      setOpenDialog(true);
      setWarningMessageId(unsavedChangesWarningMessageId);
    }
  }, [resourceAssignmentFormik, editableGates, onClose]);

  const onCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  return {
    openDialog,
    onCloseDialog,
    onDrawerCloseWithWarning,
    warningMessageId
  };
};

export default useUnsavedChangesDialogProps;
