import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { AllowedExpensesAndEstimates } from '~/modules/projects/project/AllowedExpensesAndEstimates';
import { allowedExpensesAndEstimatesColumns } from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';

const useColumnStyles = makeStyles(theme => ({
  expenseType: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    width: 'auto',
    minWidth: 'auto',
    whiteSpace: 'break-spaces'
  }
}));

const useProviderClasses = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    padding: theme.spacing(0, 1)
  },
  tableHeaderCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: 'unset',
    padding: theme.spacing(0.5),
    height: 'auto',
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
    borderCollapse: 'separate',
    height: 'auto'
  },
  tableFooterCell: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: 'unset',
    padding: theme.spacing(0.5),
    borderCollapse: 'separate',
    height: 'auto',
    backgroundColor: theme.palette.background.paper
  }
}));

const visibleColumns = [
  allowedExpensesAndEstimatesColumns.EXPENSE_TYPE,
  allowedExpensesAndEstimatesColumns.MARK_UP,
  allowedExpensesAndEstimatesColumns.ESTIMATED_BILLING
];

export const ExpenseBillPlanReadOnlyContent = ({ totalEstimatedAmount }) => {
  const columnClasses = useColumnStyles();
  const providerClasses = useProviderClasses();
  const totals = useMemo(
    () =>
      totalEstimatedAmount ? { estimatedBilling: totalEstimatedAmount } : null,
    [totalEstimatedAmount]
  );

  return (
    <AllowedExpensesAndEstimates
      totals={totals}
      visibleColumns={visibleColumns}
      editable={false}
      columnClasses={columnClasses}
      providerClasses={providerClasses}
      showBillableExpenseOnly
    />
  );
};

ExpenseBillPlanReadOnlyContent.propTypes = {
  totalEstimatedAmount: PropTypes.object
};
