import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const REMOVE_RESOURCE_USER_TASK_ASSIGNMENT = gql`
  mutation removeResourceUserTaskAssignment(
    $input: RemoveResourceUserTaskAssignmentInput!
  ) {
    removeResourceUserTaskAssignment(input: $input) {
      userId
      taskId
    }
  }
`;

export const useRemoveResourceUserTaskAssignment = ({
  refetchQueries
} = {}) => {
  const [removeResourceUserTaskAssignment, { data, loading }] = useMutation(
    REMOVE_RESOURCE_USER_TASK_ASSIGNMENT
  );

  const removeResourceUserTaskAssignmentCallback = useCallback(
    ({ userId, projectId, taskId }) =>
      removeResourceUserTaskAssignment({
        variables: {
          input: {
            userId,
            projectId,
            taskId
          }
        },
        refetchQueries,
        awaitRefetchQueries: true
      }),
    [refetchQueries, removeResourceUserTaskAssignment]
  );

  return {
    removeResourceUserTaskAssignment: removeResourceUserTaskAssignmentCallback,
    data,
    loading
  };
};

export default useRemoveResourceUserTaskAssignment;
