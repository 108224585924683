import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Receipt from '@material-ui/icons/ReceiptSharp';
import { NoDataItem } from '~/modules/common/components';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import CertificateReadOnly from '~/modules/resource-management/resourceUserDetails/Certificates/CertificateReadOnly';

const useStyles = makeStyles(() => ({
  title: {
    position: 'relative',
    top: '-8px',
    padding: '0 8px'
  }
}));

export const CertificateSection = ({ certificates }) => {
  const classes = useStyles();

  const title = useMemo(
    () => (
      <>
        <Receipt />
        <span className={classes.title}>
          <FormattedMessage id="resourceDrawer.licenseAndCertificates" />
        </span>
      </>
    ),
    [classes.title]
  );

  return (
    <InfoSection editable={false} title={title}>
      {certificates.length > 0 ? (
        certificates.map(certificate => (
          <CertificateReadOnly
            editable={false}
            key={certificate.uri}
            certificate={certificate}
          />
        ))
      ) : (
        <NoDataItem>
          <FormattedMessage id="resourceDrawer.noCertificates" />
        </NoDataItem>
      )}
    </InfoSection>
  );
};

CertificateSection.propTypes = {
  certificates: PropTypes.array.isRequired
};

export default CertificateSection;
