import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { UNSUBMIT_RESOURCE_REQUEST } from './withMarkResourceRequestAsDraft';

const useMarkResourceRequestAsDraft = ({ resourceRequest }) => {
  const [markResourceRequestAsDraft] = useMutation(UNSUBMIT_RESOURCE_REQUEST);

  const onMarkResourceRequestAsDraft = useCallback(
    () =>
      markResourceRequestAsDraft(
        mapToServiceRequest({
          request: resourceRequest,
          responseType: 'markResourceRequestAsDraft2',
          responseTypeName: 'MarkResourceRequestAsDraftResult',
          requestStatus: ResourceRequestStatus.Draft
        })
      ),
    [markResourceRequestAsDraft, resourceRequest]
  );

  return {
    onMarkResourceRequestAsDraft
  };
};

export default useMarkResourceRequestAsDraft;
