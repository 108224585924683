import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const PUT_VENDOR = gql`
  mutation putVendor($input: PutVendorInput!) {
    putVendor(input: $input) {
      id
      name
      slug
      closedDate
      isActive
    }
  }
`;

export const usePutVendor = ({ refetchQueries }) => {
  const [putVendor] = useMutation(PUT_VENDOR);

  return {
    putVendor: input => {
      return putVendor({
        variables: {
          input
        },
        refetchQueries: refetchQueries || [
          'getQuickFilterSummaryCount',
          'getVendorList'
        ]
      });
    }
  };
};

export default usePutVendor;
