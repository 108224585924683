import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { ReleaseResourceAllocationType } from '~/types';
import useGetUsersAssignedImpactedTaskSummary from '../../hooks/useGetUsersAssignedImpactedTaskSummary';
import {
  ReleaseResourceRequestDialogContent,
  ReleaseResourceRequestDialogTitle,
  ReleaseResourceRequestDialogActions
} from './components';
import {
  useReleaseTaskOwners,
  useBulkReleaseTaskOwnership,
  useTaskOwnedByResources,
  getImpactedTasksByReleasingResources
} from './hooks';
import useReleaseResourceRequestDialogState from './enhancers/useReleaseResourceRequestDialogState';
import { useFormOnChangeHandlers } from './enhancers';

const useActionStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'block'
  }
}));

const useContenStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

export const ReleaseResourceRequestDialog = ({
  onCancelClick,
  onReleaseResourceAllocationsForRequest,
  open,
  resourceRequest,
  onReleaseSuccess,
  canEditTask,
  onResourceRequestSubmit
}) => {
  const { values, setFieldValue } = useReleaseResourceRequestDialogState({
    resourceRequest,
    onResourceRequestSubmit
  });
  const {
    allocations,
    releaseDate,
    requestStartDate,
    requestEndDate,
    role,
    comment,
    releaseType,
    releaseConfirmed,
    projectId
  } = values;
  const resourceIds = allocations.map(allocation => allocation.user.uri);
  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const {
    usersAssignedImpactedTaskSummary,
    isLoading
  } = useGetUsersAssignedImpactedTaskSummary({
    projectId,
    userIds: resourceIds,
    asOfDate:
      releaseType === ReleaseResourceAllocationType.Asof ? releaseDate : null,
    skip: !isPsaRmpTaskAllocation1Enabled
  });

  const {
    resources,
    isLoading: isLoadingImpactedTasksSummary
  } = useTaskOwnedByResources({
    projectId,
    resourceIds,
    skip: isPsaRmpTaskAllocation1Enabled
  });

  const { releaseTaskOwners } = useReleaseTaskOwners();
  const { bulkReleaseTaskOwnership } = useBulkReleaseTaskOwnership();

  const {
    impactedTasks,
    selectedUsersForReleasing
  } = getImpactedTasksByReleasingResources({
    resources: isLoadingImpactedTasksSummary ? [] : resources,
    allocations,
    releaseDate,
    releaseType
  });

  const onReleaseClick = useCallback(async () => {
    if (allocations.length === 0 && onResourceRequestSubmit) {
      await onResourceRequestSubmit();
    } else {
      await onReleaseResourceAllocationsForRequest({
        resourceAllocations: allocations.filter(a => a.isSelected),
        releaseDate,
        releaseType,
        comment
      });
      if (impactedTasks.length > 0 && !isPsaRmpTaskAllocation1Enabled)
        await releaseTaskOwners(impactedTasks);
      else if (isPsaRmpTaskAllocation1Enabled) {
        await bulkReleaseTaskOwnership({
          entries: usersAssignedImpactedTaskSummary,
          projectId
        });
      }
      if (onReleaseSuccess) onReleaseSuccess();
    }
    onCancelClick();
  }, [
    allocations,
    onResourceRequestSubmit,
    onCancelClick,
    onReleaseResourceAllocationsForRequest,
    releaseDate,
    releaseType,
    comment,
    impactedTasks,
    isPsaRmpTaskAllocation1Enabled,
    releaseTaskOwners,
    onReleaseSuccess,
    bulkReleaseTaskOwnership,
    usersAssignedImpactedTaskSummary,
    projectId
  ]);

  const noneSelected = useMemo(
    () => allocations.filter(a => a.isSelected).length === 0,
    [allocations]
  );
  const {
    onSelectAllClick,
    onReleaseConfirmChange,
    onReleaseTypeChange,
    onCommentsChange,
    onRowSelectionChange,
    onReleaseDateChange
  } = useFormOnChangeHandlers({ setFieldValue, allocations });
  const contentClasses = useContenStyles();
  const actionClasses = useActionStyles();

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      aria-labelledby="release-resource-dialog-title"
      aria-describedby="release-resource-dialog-content"
    >
      <DialogTitle id="release-resource-dialog-title">
        <ReleaseResourceRequestDialogTitle
          role={role}
          onClose={onCancelClick}
        />
      </DialogTitle>
      <DialogContent
        id="release-resource-dialog-content"
        classes={contentClasses}
      >
        <ReleaseResourceRequestDialogContent
          allocations={allocations}
          onSelectAllClick={onSelectAllClick}
          onRowSelectionChange={onRowSelectionChange}
        />
      </DialogContent>
      <DialogActions classes={actionClasses}>
        <ReleaseResourceRequestDialogActions
          onCancelClick={onCancelClick}
          onReleaseClick={onReleaseClick}
          noneSelected={noneSelected}
          releaseType={releaseType}
          releaseDate={releaseDate}
          requestStartDate={requestStartDate}
          requestEndDate={requestEndDate}
          onCommentsChange={onCommentsChange}
          releaseConfirmed={releaseConfirmed}
          onReleaseDateChange={onReleaseDateChange}
          onReleaseTypeChange={onReleaseTypeChange}
          onReleaseConfirmChange={onReleaseConfirmChange}
          comment={comment}
          impactedTasks={impactedTasks}
          isLoadingImpactedTasksSummary={
            isLoadingImpactedTasksSummary || isLoading
          }
          usersAssignedImpactedTaskSummary={usersAssignedImpactedTaskSummary}
          selectedUsersForReleasing={selectedUsersForReleasing}
          canEditTask={canEditTask}
          allocations={allocations}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      </DialogActions>
    </Dialog>
  );
};

ReleaseResourceRequestDialog.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onReleaseResourceAllocationsForRequest: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  canEditTask: PropTypes.bool.isRequired,
  onResourceRequestSubmit: PropTypes.func,
  onReleaseSuccess: PropTypes.func,
  resourceRequest: PropTypes.object
};

export default ReleaseResourceRequestDialog;
