import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useMeContext } from '~/modules/me/useMeContext';
import { ResourceAssignmentContent } from './components/ResourceAssignmentContent';

const useStyles = makeStyles(theme => ({
  summaryTitle: {
    margin: theme.spacing(3, 0, 1, 0),
    fontWeight: '500'
  },
  noViewRolePermission: {
    fontSize: theme.typography.caption.fontSize,
    opacity: '0.7'
  }
}));

export const ResourceAssignments = ({ resourceAssignmentFormik }) => {
  const classes = useStyles();
  const { permissionsMap } = useMeContext();
  const canViewRole = Boolean(
    permissionsMap['urn:replicon:project-role-action:view-project-role']
  );

  return (
    <>
      <Typography className={classes.summaryTitle}>
        <FormattedMessage id="projectRequest.resourceRequirement.title" />
      </Typography>
      {canViewRole ? (
        <ResourceAssignmentContent
          resourceAssignmentFormik={resourceAssignmentFormik}
        />
      ) : (
        <Typography className={classes.noViewRolePermission} variant="body2">
          <FormattedMessage id="projectRequest.resourceRequirement.noViewRolePermission" />
        </Typography>
      )}
    </>
  );
};
ResourceAssignments.propTypes = {
  resourceAssignmentFormik: PropTypes.object
};
export default ResourceAssignments;
