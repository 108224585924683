import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useGetEnabledTagFieldsQuery } from '~/modules/tags/graphql';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import {
  useListSelection,
  ListTablePropTypes,
  ListTableNavigationContext,
  PAGINATION_TYPE
} from '~/modules/common/components/ListTable';
import { useHasPermission } from '~/modules/common/permissions';
import {
  usePaginatedFilteredProjectsQuery,
  DEFAULT_PAGE_SIZE
} from '../usePaginatedFilteredProjectsQuery';
import BulkActionsForProjects from '../BulkActionsForProjects';
import { useFilteredProjectTotalsQuery } from './useFilteredProjectTotalsQuery';
import ProjectListTableBase from './ProjectListTableBase';

const useHeaderClasses = makeStyles(theme => ({
  bottomHeader: {
    top: theme.spacing(12) + 41
  }
}));

export const ProjectListTable = ({
  enabledFields,
  isMobile,
  onRowSelectionChange,
  onSortChange,
  sort
}) => {
  const canEditProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-project'
  });
  const { currentPage, setCurrentPage } = useProjectsContext();
  const projectListNavigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: DEFAULT_PAGE_SIZE,
      disableInfiniteScroll: !isMobile
    }),
    [setCurrentPage, currentPage, isMobile]
  );

  const tagFieldUris = enabledFields.filter(c =>
    /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(c)
  );
  const tagFieldData = useGetEnabledTagFieldsQuery(enabledFields);
  const {
    loading: projectsLoading,
    projects,
    loadMore,
    refetch: refetchProjects
  } = usePaginatedFilteredProjectsQuery({
    currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    enabledFields,
    tagIds: tagFieldUris,
    sort
  });

  const headerClasses = useHeaderClasses();
  const {
    loading: totalsLoading,
    totals,
    refetch: refetchTotals
  } = useFilteredProjectTotalsQuery();
  const { selected, onSelectionChange } = useListSelection();

  const loading = projectsLoading || totalsLoading;

  const selectedProjects = canEditProject
    ? (projects || []).filter(
        p => selected.records && selected.records.includes(p.id)
      )
    : [];

  useEffect(() => {
    if (!loading) {
      onRowSelectionChange(selectedProjects);
    }
  }, [loading, selectedProjects, onRowSelectionChange]);

  const showBulkActions =
    !loading && canEditProject && selected?.records?.length > 0;

  return (
    <ListTableNavigationContext.Provider value={projectListNavigationContext}>
      <ProjectListTableBase
        headerClasses={headerClasses}
        projects={projects}
        totals={totals}
        hasMoreProjects
        loadMoreProjects={loadMore}
        projectsLoading={loading}
        enabledFields={enabledFields}
        onSortChange={onSortChange}
        sort={sort}
        tagFieldData={tagFieldData.data}
        selected={selected}
        onRowSelectionChange={canEditProject ? onSelectionChange : null}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        paginationType={PAGINATION_TYPE.NAVIGATION}
      />
      {showBulkActions ? (
        <BulkActionsForProjects
          selected={selected}
          projects={selectedProjects}
          onSelectionChange={onSelectionChange}
          refetchTotals={refetchTotals}
          refetchProjects={refetchProjects}
        />
      ) : null}
    </ListTableNavigationContext.Provider>
  );
};

ProjectListTable.propTypes = {
  isMobile: PropTypes.bool,
  enabledFields: PropTypes.arrayOf(PropTypes.string),
  onSortChange: PropTypes.func,
  sort: ListTablePropTypes.sort,
  onRowSelectionChange: PropTypes.func
};

export default ProjectListTable;
