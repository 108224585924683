import { useIntl } from 'react-intl';
import { ProgressTypes } from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';
import { isNumeric } from '~/modules/common/numbers';

const getValues = ({ type, actual, estimated, intl }) => {
  if (type === ProgressTypes.COST) {
    return {
      actualValue: actual?.amount,
      estimatedValue: estimated?.amount,
      symbol: actual?.currency?.symbol || estimated?.currency?.symbol,
      units: ''
    };
  }

  if (type === ProgressTypes.HOURS) {
    return {
      actualValue: actual,
      estimatedValue: estimated,
      symbol: '',
      units: ` ${intl.formatMessage({
        id: 'portfolio.budgetProgressCard.hoursUnit'
      })}`
    };
  }

  return null;
};

const useProgressDetailsData = ({ type, actual, estimated }) => {
  const intl = useIntl();

  const { actualValue, estimatedValue, symbol, units } = getValues({
    type,
    actual,
    estimated,
    intl
  });

  const actualNumericValue = actualValue || 0;
  const estimatedNumericValue = estimatedValue || 0;

  const relativeValue = Math.abs(estimatedNumericValue - actualNumericValue);
  const isOverage = actualNumericValue > estimatedNumericValue;
  const percentage = isNumeric(estimatedValue)
    ? (relativeValue / estimatedNumericValue) * 100
    : null;

  return {
    actualValue,
    estimatedValue,
    isOverage,
    percentage,
    relativeValue,
    symbol,
    units,
    valuesPrefix:
      actualNumericValue === estimatedNumericValue ? '' : isOverage ? '+' : '-'
  };
};

export default useProgressDetailsData;
