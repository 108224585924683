import Menu from '@material-ui/core/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { withRoutes } from '~/modules/common/routes';
import { useDialogState } from '~/modules/common/hooks';
import NotificationSettingsDialog from '~/modules/settings';
import { useUnreadNotifications } from '~/modules/notifications/hooks/useUnreadNotifications';
import { useMarkNotificationAsRead } from '~/modules/notifications/hooks/useMarkNotificationAsRead';
import { useMarkAllNotificationsAsRead } from '~/modules/notifications/hooks/useMarkAllNotificationsAsRead';
import Notifications from './Notifications';
import NotificationsIcon from './NotificationsIcon';
import NotificationActions from './NotificationActions';
import { useUnreadNotificationsSubscription } from './useUnreadNotificationsSubscription';

const useStyles = makeStyles(theme => ({
  notificationButton: {
    color: 'inherit'
  },
  paper: {
    maxHeight: '400px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '60%',
      width: '80%'
    }
  },
  notificationActions: {
    padding: 0
  },
  actionButtons: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.common.white
  },
  title: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
    zIndex: theme.zIndex.modal
  },
  actionButton: {
    flex: '1 1 0'
  },
  primary: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  secondary: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  notificationItem: {
    marginTop: 0,
    marginBottom: 0
  },
  notifications: {
    paddingTop: 0,
    paddingBottom: 0
  },
  icon: {
    paddingLeft: theme.spacing(0.5),
    minWidth: theme.spacing(6)
  },
  text: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const useNotificationStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  }
}));

export const NotificationsMenu = ({ routes }) => {
  const onMarkNotificationAsRead = useMarkNotificationAsRead();
  const onMarkAllNotificationsAsRead = useMarkAllNotificationsAsRead();

  const {
    notifications,
    unreadCount,
    subscribeToMore
  } = useUnreadNotifications();

  useUnreadNotificationsSubscription({ subscribeToMore });

  const intl = useIntl();
  const classes = useStyles();
  const notificationClasses = useNotificationStyles();
  const menuClasses = useMemo(
    () => ({
      paper: classNames({ [classes.paper]: notifications.length > 0 }),
      list: classes.notifications
    }),
    [classes.notifications, classes.paper, notifications.length]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleOpen = useCallback(
    event => {
      !isOpen && setAnchorEl(event.target);
      setIsOpen(!isOpen);
    },
    [isOpen, setIsOpen]
  );

  const { open, openDialog, closeDialog } = useDialogState(false);

  const notificationsCount = parseInt(unreadCount, 10);

  return (
    <>
      <ListItem
        id="notifications-menu-icon"
        button
        aria-owns={isOpen ? 'notifications-menu' : undefined}
        aria-haspopup="true"
        aria-label={intl.formatMessage({ id: 'notificationsMenu.title' })}
        onClick={toggleOpen}
        classes={notificationClasses}
        dense={false}
      >
        <ListItemIcon className={classes.icon}>
          <NotificationsIcon notificationsCount={notificationsCount} />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'routes.notifications' })}
          classes={{ primary: classes.text }}
        />
      </ListItem>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        placement="top-start"
        open={isOpen}
        onClick={toggleOpen}
        classes={menuClasses}
      >
        <Notifications
          classes={classes}
          notifications={notifications}
          onMarkNotificationAsRead={onMarkNotificationAsRead}
        />
        <NotificationActions
          classes={classes}
          intl={intl}
          routes={routes}
          toggleOpen={toggleOpen}
          onSettingsClick={openDialog}
          showMarkAllAsRead={notificationsCount > 0}
          onMarkAllNotificationAsRead={onMarkAllNotificationsAsRead}
        />
      </Menu>
      <NotificationSettingsDialog open={open} closeDialog={closeDialog} />
    </>
  );
};

NotificationsMenu.propTypes = {
  routes: PropTypes.object
};

export default withRoutes(NotificationsMenu);
