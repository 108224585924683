import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get, compact } from 'lodash';
import { useIntl } from 'react-intl';
import { Money2 } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  actuals: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: theme.spacing(16.5),
    paddingLeft: theme.spacing(0.5),
    '& input': {
      fontSize: 'small'
    },
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  estimates: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    paddingRight: theme.spacing(0.5),
    '& input': {
      fontSize: 'medium'
    }
  },
  estimatesWithActuals: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: theme.spacing(16.5),
    paddingRight: theme.spacing(0.5),
    '& input': {
      fontSize: 'small'
    }
  },
  currency: {
    fontSize: theme.typography.body2.fontSize,
    '& div': {
      paddingRight: 'unset !important'
    }
  },
  row: {
    display: 'flex'
  }
}));

const keys = {
  actuals: 'actuals',
  estimated: 'estimated'
};

const getAmountPath = (field, path) => `${field}.${path}`;

export const ExpenseAmountFooter = ({ field, record }) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    values: { showActuals, currency }
  } = useFormikContext();

  const currencyClasses = useMemo(() => ({ currency: classes.currency }), [
    classes.currency
  ]);

  const getAmount = useCallback(
    (path, defaultValue = 0) =>
      get(record, getAmountPath(field, path), defaultValue),
    [field, record]
  );

  const dataPoints = useMemo(
    () =>
      compact([
        {
          key: keys.estimated,
          className: showActuals
            ? classes.estimatesWithActuals
            : classes.estimates
        },
        showActuals && {
          key: keys.actuals,
          className: classes.actuals
        }
      ]),
    [
      classes.actuals,
      classes.estimates,
      classes.estimatesWithActuals,
      showActuals
    ]
  );

  return (
    <div className={classes.row}>
      {dataPoints.map(({ key: dataPointKey, className }) => (
        <React.Fragment key={dataPointKey}>
          <Money2
            classes={currencyClasses}
            className={className}
            variant="standard"
            amount={getAmount(dataPointKey)}
            currency={currency}
            currencyEditable={false}
            editable={false}
            ariaLabel={intl.formatMessage({
              id: `expenseBillPlan.${dataPointKey}`
            })}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

ExpenseAmountFooter.propTypes = {
  field: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
};

export default ExpenseAmountFooter;
