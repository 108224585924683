import React, { useMemo, useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core';
import { MonetizationOnSharp as MonetizationOn } from '@material-ui/icons';

import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me';
import MicroFrontend from '../MicroFrontend';
import StickyHeader from '../../components/StickyHeader/StickyHeader';

const useStyles = makeStyles({
  root: {
    display: 'contents'
  },
  cpoRoot: {
    marginTop: '0px !important'
  }
});

export const CPOMount = () => {
  const me = useMeContext();
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const [showHeader, setShowHeader] = useState(true);
  const frontendProps = useMemo(
    () => ({
      apiUrl: `${me.cpoUrl}/graphql`,
      parentTheme: theme,
      routeBasename: 'smart-budget',
      setShowHeader,
      showHeader,
      className: classes.cpoRoot
    }),
    [me.cpoUrl, theme, showHeader]
  );

  return (
    <>
      {showHeader && (
        <StickyHeader
          title={intl.formatMessage({ id: 'routes.smartBudget' })}
        />
      )}
      <MicroFrontend
        name="CostPriceOptimizer"
        host={me.cpoUrl}
        assetManifestResource="public/asset-manifest.json"
        props={frontendProps}
        className={classes.root}
      />
    </>
  );
};

const cpoRoute = ({ me, intl }) => {
  const { cpoUrl } = me;

  if (!cpoUrl) {
    return null;
  }

  const hasPermission = me.permissions.find(
    p =>
      p.permissionActionUri ===
      'urn:replicon-webui:smart-budget-action:view-smart-budget'
  );

  return {
    path: '/smart-budget',
    icon: MonetizationOn,
    isSideBarRoute: true,
    component: CPOMount,
    hidden: true,
    title: () => intl.formatMessage({ id: 'routes.smartBudget' }),
    showDivider: false,
    hasPermission: Boolean(hasPermission),
    exact: false
  };
};

export default cpoRoute;
