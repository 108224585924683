import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_PORTFOLIO_PROJECT_STATUS_BUDGET_SUMMARY = gql`
  query portfolioProjectStatusBudgetSummary($portfolioId: String!) {
    portfolioProjectStatusBudgetSummary(portfolioId: $portfolioId) {
      initiate
      planning
      execution
      closeout
      archived
    }
  }
`;

export const usePortfolioProjectStatusBudgetSummary = ({ id }) => {
  const { data, loading } = useQuery(
    GET_PORTFOLIO_PROJECT_STATUS_BUDGET_SUMMARY,
    {
      variables: {
        portfolioId: id
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const budgetSummaryAmounts =
    !loading && data ? data.portfolioProjectStatusBudgetSummary : {};

  return {
    loading,
    budgetSummaryAmounts
  };
};
