import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import ConfirmProjectMoveDialogTable from './ConfirmProjectMoveDialogTable';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  confirmationMessage: {
    padding: theme.spacing(2),
    width: '100%'
  },
  actions: {
    flexWrap: 'wrap'
  }
}));

const ConfirmProjectMoveDialog = ({
  portfolioName,
  open,
  onClose,
  onMove,
  projects,
  submitting
}) => {
  const classes = useStyles();

  const title =
    projects.length === 1 ? (
      <FormattedMessage id="addProject.projectsHasPortfolio" />
    ) : (
      <FormattedMessage
        id="addProject.xProjectsHasPortfolio"
        values={{ value: projects.length }}
      />
    );

  const handleSubmit = useCallback(() => {
    onMove();
    onClose();
  }, [onMove, onClose]);

  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <ConfirmProjectMoveDialogTable projects={projects} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Typography className={classes.confirmationMessage}>
          <FormattedMessage
            id="addProject.moveToPortfolioConfirmation"
            values={{ portfolio: <b>{portfolioName}</b> }}
          />
        </Typography>
        <Button onClick={onClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          disabled={submitting}
          isLoading={false}
          onClick={handleSubmit}
          color="secondary"
        >
          <FormattedMessage id="addProject.moveProjects" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmProjectMoveDialog.propTypes = {
  portfolioName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onMove: PropTypes.func,
  projects: PropTypes.array,
  submitting: PropTypes.bool
};

export default ConfirmProjectMoveDialog;
