import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  name: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'break-spaces'
  }
}));

export const SupervisorFormatter = ({ record, field }) => {
  const classes = useStyles();

  return record[field] ? (
    <User user={record[field].supervisor} classes={classes} />
  ) : (
    <NoValue />
  );
};

SupervisorFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default SupervisorFormatter;
