import { Checkbox, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isNullOrUndefined } from '~/modules/common/util';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-1)
  }
}));

export const RowSelectorFormatter = ({
  record,
  column: {
    availableToBillStates: {
      isRowSelected,
      onSelectClick,
      areHundredBillsSelected
    }
  }
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const isSelected = useMemo(() => isRowSelected(record), [
    isRowSelected,
    record
  ]);

  return (
    <Checkbox
      checked={isSelected}
      disabled={
        (!isSelected && areHundredBillsSelected()) ||
        isNullOrUndefined(record.startDate) ||
        isNullOrUndefined(record.endDate)
      }
      onChange={onSelectClick(record)}
      color="primary"
      size="small"
      classes={classes}
      inputProps={useMemo(
        () => ({
          'aria-label': formatMessage({
            id: 'availableToBillList.singleRowSelected'
          })
        }),
        [formatMessage]
      )}
    />
  );
};

RowSelectorFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};

export default RowSelectorFormatter;
