import { useMemo } from 'react';
import classNames from 'classnames';
import { useColumns } from '~/modules/common/components/ListTable';
import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import { ExpenseAmountFooter } from '../renderers';
import { allowedExpensesAndEstimatesColumns } from './enum';
import { useColumnStyles } from './useStyles';

export const buildFooters = ({ chartDates, visibleColumns, classes }) => {
  const estimatesColumns = chartDates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.key]: {
        id: curr.key,
        visible: visibleColumns.includes(
          allowedExpensesAndEstimatesColumns.ESTIMATES
        ),
        value: '',
        align: 'center',
        className: classes.estimates
      }
    }),
    {}
  );

  const hasMarkUpColumn = visibleColumns.includes(
    allowedExpensesAndEstimatesColumns.MARK_UP
  );

  const hasDeleteRowColumn = visibleColumns.includes(
    allowedExpensesAndEstimatesColumns.DELETE_ROW
  );

  return {
    expenseType: {
      id: 'expenseType',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.EXPENSE_TYPE
      ),
      value: '',
      align: 'left',
      className: classNames(classes.expenseType, classes.extraZIndex)
    },
    markUp: {
      id: 'markUp',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.MARK_UP
      ),
      value: '',
      align: 'right',
      className: classNames(classes.markUp, classes.extraZIndex)
    },
    estimatedBilling: {
      id: 'estimatedBilling',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.ESTIMATED_BILLING
      ),
      value: '',
      align: 'right'
    },
    billableType: {
      id: 'billableType',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.BILLABLE_TYPE
      ),
      value: '',
      align: 'left',
      className: classNames(
        classes.billableType,
        !hasMarkUpColumn && classes.noMarkUp,
        classes.extraZIndex
      )
    },
    ...estimatesColumns,
    totalEstimates: {
      id: 'totalEstimates',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.TOTAL_ESTIMATES
      ),
      value: '',
      align: 'center',
      className: classNames(
        classes.totalEstimates,
        !hasDeleteRowColumn && classes.noDeleteRow,
        classes.extraZIndex
      )
    },
    estimate: {
      id: 'estimate',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.ESTIMATE
      ),
      value: '',
      className: classNames(classes.estimate, classes.extraZIndex)
    },
    deleteRow: {
      id: 'deleteRow',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.DELETE_ROW
      ),
      value: '',
      className: classNames(classes.deleteRow, classes.extraZIndex)
    }
  };
};

export const useListFooters = ({
  chartDates,
  visibleColumns,
  columnClasses: classesOverride
}) => {
  const classes = useColumnStyles({ classes: classesOverride });
  const estimatesEditors = useMemo(
    () =>
      chartDates.reduce(
        (retVal, curr) => ({
          ...retVal,
          [curr.key]: ExpenseAmountFooter
        }),
        {}
      ),
    [chartDates]
  );

  return useColumns({
    columns: buildFooters({
      chartDates,
      visibleColumns,
      classes
    }),
    renders: {
      ...estimatesEditors,
      totalEstimates: ExpenseAmountFooter,
      estimatedBilling: MoneyFormatter
    }
  });
};
