import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { RoleIcon } from '~/modules/common/components/Icons';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import RoleScheduleReadOnlyCard from '~/modules/resource-management/resourceUserDetails/Role/RoleScheduleReadOnlyCard';

const useStyles = makeStyles(() => ({
  title: {
    position: 'relative',
    top: '-8px',
    padding: '0 8px'
  }
}));

export const RoleSection = ({ userDetails }) => {
  const classes = useStyles();

  const title = useMemo(
    () => (
      <>
        <RoleIcon />
        <span className={classes.title}>
          <FormattedMessage id="resourceDrawer.roles" />
        </span>
      </>
    ),
    [classes.title]
  );

  return (
    <InfoSection editable={false} title={title}>
      <RoleScheduleReadOnlyCard
        userDetails={userDetails}
        showCardLayout={false}
        editable={false}
      />
    </InfoSection>
  );
};

RoleSection.propTypes = {
  userDetails: PropTypes.object.isRequired
};

export default RoleSection;
