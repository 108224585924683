import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { fullProgramFragment } from '~/modules/programs/fragments/fullProgram.fragment';
import { GetProgramBySlugDocument } from '~/types';

export const ADD_PROGRAM = gql`
  mutation AddProgram($addProgramInput: AddProgramInput!) {
    addProgram2(addProgramInput: $addProgramInput) {
      program {
        uri
        displayText
        projectsStatusCountSummary {
          initiate
          planning
          execution
          closed
          total
        }
        permittedActionUris
        ...FullProgram
      }
      error {
        reason
      }
    }
  }
  ${fullProgramFragment}
`;

export const updateCache = (proxy, { data }) => {
  if (data) {
    const program = get(data, 'addProgram2.program', null);

    if (program) {
      proxy.writeQuery({
        query: GetProgramBySlugDocument,
        variables: { programSlug: program.slug },
        data: {
          program: {
            ...program
          }
        }
      });
    }
  }
};

export const useAddProgramMutation = () =>
  useMutation(ADD_PROGRAM, {
    update: updateCache
  });

export default useAddProgramMutation;
