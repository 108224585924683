import React from 'react';
import { retryLoading } from '~/util';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { RedirectToNotAuthorized } from '~/modules/common/notAuthorized';

const UserProfile = React.lazy(() =>
  retryLoading(() => import('./UserProfile'))
);

export const userProfileRoute = ({ intl, me }) => ({
  path: '/profile',
  icon: () => null,
  hidden: true,
  title: () => intl.formatMessage({ id: 'routes.userProfile' }),
  component: me.userAccessRoles.includes(USER_ACCESS_ROLE.PROJECT_RESOURCE)
    ? UserProfile
    : RedirectToNotAuthorized,
  hasPermission: true
});
