import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useProjectResourceAllocationsByIds } from '~/modules/resourcing/common/hooks';
import { useMeContext } from '~/modules/me';
import { ReleaseAllocationsDialogContent } from './ReleaseAllocationsDialogContent';
import ReleaseAllocationsDialogLoadingContent from './ReleaseAllocationsDialogLoadingContent';
import { ReleaseResourceRequestDialogTitle } from './components';

export const ReleaseAllocationsDialog = ({
  allocatedUser,
  canEditTask,
  onCancelClick,
  onReleaseResourceAllocation,
  onReleaseSuccess,
  open,
  projectUri,
  selectedAllocationId
}) => {
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = useMeContext();

  const allocationIds = allocatedUser.roles.reduce(
    (ids, role) => [
      ...ids,
      ...role.resourceAllocationReference.map(ref => ref.id)
    ],
    []
  );
  const {
    resourceAllocations,
    loadingAllocations
  } = useProjectResourceAllocationsByIds({
    allocationIds
  });

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="release-allocations-dialog-title"
      aria-describedby="release-allocations-dialog-content"
    >
      <DialogTitle id="release-allocations-dialog-title">
        <ReleaseResourceRequestDialogTitle
          role={allocatedUser.user}
          onClose={onCancelClick}
          headerId={
            isPsaRmpTaskAllocation1Enabled
              ? 'releaseResourceRequestDialog.releaseResource'
              : 'releaseResourceRequestDialog.releaseAllocationsTitle'
          }
        />
      </DialogTitle>
      {loadingAllocations ? (
        <ReleaseAllocationsDialogLoadingContent onCancelClick={onCancelClick} />
      ) : (
        <ReleaseAllocationsDialogContent
          allocatedUser={allocatedUser}
          allocationIds={allocationIds}
          allocations={resourceAllocations}
          onReleaseSuccess={onReleaseSuccess}
          onReleaseResourceAllocations={onReleaseResourceAllocation}
          projectUri={projectUri}
          onCancelClick={onCancelClick}
          canEditTask={canEditTask}
          selectedAllocationId={selectedAllocationId}
        />
      )}
    </Dialog>
  );
};

ReleaseAllocationsDialog.propTypes = {
  allocatedUser: PropTypes.object,
  canEditTask: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onReleaseResourceAllocation: PropTypes.func,
  onReleaseSuccess: PropTypes.func,
  open: PropTypes.bool.isRequired,
  projectUri: PropTypes.string,
  selectedAllocationId: PropTypes.string
};

export default ReleaseAllocationsDialog;
