import React from 'react';
import PropTypes from 'prop-types';
import NoResourceUserProjectAllocationContent from '../NoResourceUserProjectAllocationContent';
import { TaskAllocationEditorSkeleton } from '../common/components';
import { useTaskEstimateRowContext } from '../../TaskEstimateRowContext';
import ProjectManagerRoleEditorForm from './components/ProjectManagerRoleEditorForm';

const ProjectManagerRoleEditorContent = ({
  onClose,
  otherTaskAllocationsSummary,
  resourceAllocation,
  isLoading
}) => {
  const {
    task: {
      project: { slug: projectSlug }
    }
  } = useTaskEstimateRowContext();

  return isLoading ? (
    <TaskAllocationEditorSkeleton onClose={onClose} />
  ) : !resourceAllocation ? (
    <NoResourceUserProjectAllocationContent
      projectSlug={projectSlug}
      onClose={onClose}
    />
  ) : (
    <ProjectManagerRoleEditorForm
      onClose={onClose}
      otherTaskAllocationsSummary={otherTaskAllocationsSummary}
      resourceAllocation={resourceAllocation}
    />
  );
};

ProjectManagerRoleEditorContent.propTypes = {
  resourceAllocation: PropTypes.object,
  onClose: PropTypes.func,
  otherTaskAllocationsSummary: PropTypes.object,
  isLoading: PropTypes.bool
};

export default ProjectManagerRoleEditorContent;
