import { Button, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';
import { ProjectRequestDrawerActions } from './ProjectRequestDrawerActions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
    position: 'sticky',
    boxShadow: theme.shadows[2],
    background: theme.palette.common.white,
    zIndex: 11,
    padding: theme.spacing(1, 2, 1, 2),
    margin: 0,
    minHeight: '-webkit-max-content'
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  userInfo: {
    padding: theme.spacing(1, 2),
    minHeight: '-webkit-max-content',
    marginTop: theme.spacing(2)
  },
  action: {
    margin: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1, 0, 0)
    }
  }
}));

export const ProjectRequestDrawerFooter = ({
  onClose,
  setEditable,
  editable,
  projectRequestName,
  gatesFormik,
  portfolioId,
  portfolioName
}) => {
  const {
    values: { gates }
  } = gatesFormik;
  const classes = useStyles();
  const {
    saving,
    handleSubmit,
    handleReset,
    values: { id, status },
    isSubmitting
  } = useFormikContext();
  const isNew = !id;

  const handleCancelInEditMode = useCallback(() => {
    handleReset();
    setEditable(false);
  }, [handleReset, setEditable]);

  return (
    <div className={classes.container}>
      {editable ? (
        <>
          <LoadingButton
            className={classes.action}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            data-qe-id="AddRequest"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            {isNew ? (
              <FormattedMessage id="projectRequest.addRequest" />
            ) : (
              <FormattedMessage id="projectRequest.save" />
            )}
          </LoadingButton>
          <Button
            className={classes.action}
            variant="contained"
            onClick={isNew ? onClose : handleCancelInEditMode}
            color="default"
            data-qe-id="CancelRequest"
          >
            <FormattedMessage id="addDialog.cancel" />
          </Button>
        </>
      ) : null}
      <div className={classes.spacer} />
      {isNew ? null : (
        <ProjectRequestDrawerActions
          saving={saving}
          editable={editable}
          projectRequestName={projectRequestName}
          portfolioId={portfolioId}
          portfolioName={portfolioName}
          projectRequestId={id}
          onDrawerClose={onClose}
          projectRequestStatus={status}
          projectRequestGates={gates}
        />
      )}
    </div>
  );
};

ProjectRequestDrawerFooter.propTypes = {
  onClose: PropTypes.func,
  setEditable: PropTypes.func,
  editable: PropTypes.bool,
  projectRequestName: PropTypes.string,
  gatesFormik: PropTypes.object,
  portfolioId: PropTypes.string,
  portfolioName: PropTypes.string
};

export default ProjectRequestDrawerFooter;
