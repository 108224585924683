import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { SearchBox } from '~/modules/common/components';
import { useResourceAssignmentDialogContext } from './ResourceAssignmentDialogContext';

const facets = [];

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0
    }
  }
}));

export const ResourceAssignmentDialogSearchBar = () => {
  const classes = useStyles();
  const {
    searchCriteria,
    setSearchCriteria,
    setCurrentPage
  } = useResourceAssignmentDialogContext();

  const onSearchCriteriaChange = useCallback(
    newSearchCriteria => {
      setSearchCriteria(newSearchCriteria);
      setCurrentPage(1);
    },
    [setSearchCriteria, setCurrentPage]
  );

  return (
    <div className={classes.root}>
      <SearchBox
        dataQeId="ResourceAssignmentSearchBar"
        searchCriteria={searchCriteria}
        setSearchCriteria={onSearchCriteriaChange}
        facets={facets}
      />
    </div>
  );
};

export default ResourceAssignmentDialogSearchBar;
