import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  footer: {
    ...theme.typography.caption,
    left: 0,
    right: 0,
    color: theme.palette.text.secondary,
    display: 'flex',
    padding: theme.spacing(3, 2, 6, 2),
    position: 'sticky',
    flexGrow: 1,
    alignItems: 'flex-end',
    flexShrink: 0,
    justifyContent: 'center',
    zIndex: -1
  }
}));

const Footer = () => {
  const classes = useStyles();

  const values = { year: new Date().getFullYear() };

  return (
    <footer className={classes.footer}>
      <FormattedMessage id="footer.repliconCopyright" values={values} />
    </footer>
  );
};

export default Footer;
