import { useQuery } from '@apollo/client';
import { UNREAD_NOTIFICATIONS_QUERY } from '../graphql/unreadNotificationsQuery';

export const useUnreadNotifications = () => {
  const { data, subscribeToMore } = useQuery(UNREAD_NOTIFICATIONS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const notifications = (data && data.me && data.me.unreadNotifications) || [];
  const unreadCount = data && data.me && data.me.unreadNotificationsCount;

  return {
    notifications,
    unreadCount,
    subscribeToMore
  };
};
