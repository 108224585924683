import { isNullOrUndefined } from '~/modules/common/util';
import { GET_PROJECT_REQUEST_DETAILS } from './useProjectRequest';

export const updateScoreInCache = ({ id, score, featureFlags }) => cache => {
  try {
    const { projectRequest } = cache.readQuery({
      query: GET_PROJECT_REQUEST_DETAILS,
      variables: {
        id
      }
    });

    const updatedProjectRequest = {
      ...projectRequest,
      score: {
        __typename: 'ProjectRequestScore',
        ...projectRequest.score,
        ...score,
        effectiveTotal: isNullOrUndefined(score.overrideTotal)
          ? score.total
          : score.overrideTotal
      }
    };

    cache.writeQuery({
      query: GET_PROJECT_REQUEST_DETAILS,
      variables: {
        id
      },
      data: {
        projectRequest: updatedProjectRequest
      }
    });
  } catch (e) {} // eslint-disable-line
};
