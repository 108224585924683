import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { User } from '~/modules/common/components';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useCanEditAllPrograms } from './useCanEditAllPrograms';
import { useProgramManagers } from './hooks';

const renderTags = values =>
  values.map(option => (
    <User
      dataQeId={option.displayText}
      user={option}
      disablePadding
      key={option.displayText}
    />
  ));

const renderOption = option => <User user={option} disablePadding />;

export const ProgramManagerDropdown = ({
  programId,
  value,
  onChange,
  dataQeId
}) => {
  const { formatMessage } = useIntl();

  const canEditAllPrograms = useCanEditAllPrograms();

  const { programManagers, loading } = useProgramManagers({ programId });

  const handleOnChange = useCallback(
    managers =>
      onChange(
        managers && managers.length ? managers[managers.length - 1] : null
      ),
    [onChange]
  );

  const selectedValue = useMemo(() => (value ? [value] : []), [value]);

  return (
    <SimpleAutocomplete
      inputLabel={formatMessage({ id: 'programBasicInfoCard.programManager' })}
      dataQeId={dataQeId}
      options={programManagers}
      loading={loading}
      disabled={!canEditAllPrograms}
      renderTags={renderTags}
      value={selectedValue}
      onChange={handleOnChange}
      multiple
      fullWidth
      noOptionsText={formatMessage({ id: 'program.noProgramManager' })}
      optionPropText="displayText"
      renderOption={renderOption}
    />
  );
};

ProgramManagerDropdown.propTypes = {
  programId: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  dataQeId: PropTypes.string
};

export default ProgramManagerDropdown;
