import React from 'react';
import {
  makeStyles,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  autoGenerateReference: {
    whiteSpace: 'pre-line',
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export const AutoGenerateReferenceRequiredContent = ({ onCancelClick }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="availableToBillList.invoiceNumber" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className={classes.autoGenerateReference}
          dataQeId="AutoGenerateReferenceRequired"
        >
          <FormattedMessage id="availableToBillList.autoGenerateReferenceRequired" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancelClick}
          aria-label={formatMessage({
            id: 'button.close'
          })}
          data-qe-id="Close"
        >
          <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
    </>
  );
};

AutoGenerateReferenceRequiredContent.propTypes = {
  onCancelClick: PropTypes.func
};

export default AutoGenerateReferenceRequiredContent;
