import { Typography } from '@material-ui/core';
import isEqual from 'lodash.isequal';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar/UserAvatar';
import DateTime from '~/modules/common/components/DateTime';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  dateTimeStyle: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary
  },
  titleStyle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  },
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize
  },
  nameTitleAndDate: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0, 0, 0, 1),
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  }
}));

export const RevisionEntryTitle = ({
  revisionReferenceKeys,
  actualUser,
  effectiveUser,
  timestamp,
  isInitialRevision
}) => {
  const classes = useStyles();
  const isSubstituteUser = !isEqual(actualUser, effectiveUser);
  const getMessageObject = () => {
    if (isInitialRevision) {
      if (isSubstituteUser) {
        return {
          id: `${revisionReferenceKeys.substituteUserAdded}`,
          value: {
            actualUser: actualUser.displayText,
            effectiveUser: effectiveUser.displayText
          }
        };
      }

      return {
        id: `${revisionReferenceKeys.added}`,
        value: { displayText: actualUser.displayText }
      };
    }
    if (isSubstituteUser) {
      return {
        id: `${revisionReferenceKeys.substituteUserEdited}`,
        value: {
          actualUser: actualUser.displayText,
          effectiveUser: effectiveUser.displayText
        }
      };
    }

    return {
      id: `${revisionReferenceKeys.edited}`,
      value: { displayText: actualUser.displayText }
    };
  };
  const { id, value } = getMessageObject();

  return (
    <div className={classes.root}>
      <UserAvatar
        user={effectiveUser}
        size="large"
        className={classes.avatar}
      />
      <div className={classes.nameTitleAndDate}>
        <Typography className={classes.titleStyle}>
          <FormattedMessage id={id} values={value} />
        </Typography>

        <DateTime className={classes.dateTimeStyle} value={timestamp} />
      </div>
    </div>
  );
};

RevisionEntryTitle.propTypes = {
  revisionReferenceKeys: PropTypes.object.isRequired,
  actualUser: PropTypes.object.isRequired,
  effectiveUser: PropTypes.object.isRequired,
  isInitialRevision: PropTypes.bool.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default RevisionEntryTitle;
