import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { TIMESHEET_ACCESS_TYPE } from '~/modules/common/enums';
import {
  AllUsersIcon,
  CostCenterIcon,
  DepartmentIcon,
  DivisionIcon,
  EmployeeTypeIcon,
  LocationIcon,
  ServiceCenterIcon
} from '../../Icons';

export const useStyles = makeStyles(theme => ({
  iconContainer: {
    paddingLeft: theme.spacing(1)
  },
  text: {
    paddingLeft: theme.spacing(0.5),
    ...theme.typography.body2
  },
  requiredMissing: {
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontStyle: 'italic',
    fontWeight: 'normal',
    textTransform: 'none'
  },
  label: { paddingLeft: theme.spacing(0.5) },
  labelIcon: {
    fontSize: theme.spacing(2),
    margin: theme.spacing(-0.5, 0),
    color: theme.palette.text.secondary
  }
}));

const groupIconComponent = {
  [TIMESHEET_ACCESS_TYPE.SERVICE_CENTERS]: ServiceCenterIcon,
  [TIMESHEET_ACCESS_TYPE.LOCATIONS]: LocationIcon,
  [TIMESHEET_ACCESS_TYPE.COST_CENTERS]: CostCenterIcon,
  [TIMESHEET_ACCESS_TYPE.DEPARTMENT_GROUP]: DepartmentIcon,
  [TIMESHEET_ACCESS_TYPE.EMPLOYEE_TYPE_GROUP]: EmployeeTypeIcon,
  [TIMESHEET_ACCESS_TYPE.DIVISIONS]: DivisionIcon,
  [TIMESHEET_ACCESS_TYPE.ALL_USERS]: AllUsersIcon
};

export const TaskDrawerGroupsReadOnly = ({ assignments }) => {
  const classes = useStyles();

  return Object.values(assignments).map((element, index) => {
    const ComponentIcon = groupIconComponent[element.group.id];

    return (
      <span
        key={element.id}
        className={classNames({
          [classes.requiredMissing]: !element.displayText
        })}
      >
        <span
          className={classNames({
            [classes.iconContainer]: index !== 0
          })}
        >
          <ComponentIcon className={classes.labelIcon} />
        </span>
        <span className={classes.text}>
          {element.displayText ? (
            element.displayText
          ) : (
            <FormattedMessage
              id="resourceRequestDrawerDetails.noGroup"
              values={element.labelValues}
            />
          )}
        </span>
      </span>
    );
  });
};

const GroupItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayText: PropTypes.string.isRequired
  })
});

TaskDrawerGroupsReadOnly.propTypes = {
  assignments: PropTypes.arrayOf(GroupItemPropTypes).isRequired
};

export default TaskDrawerGroupsReadOnly;
