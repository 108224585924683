import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  Link,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  icon: {
    paddingLeft: theme.spacing(0.5),
    minWidth: theme.spacing(6),
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    '& > svg': {
      opacity: 0.3
    },
    '&:hover': {
      '& > svg': {
        opacity: 1
      }
    }
  },
  text: {
    flexGrow: 1,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export const ExternalLinksMenuItem = ({ url, icon, text, dataQeId }) => {
  const classes = useStyles();
  const { setValue: setCurrentView } = useSessionStorage('current-view', null);
  const location = useLocation();
  const onClick = useCallback(() => {
    setCurrentView(location);
  });
  const primaryTypographyProps = useMemo(() => ({ className: classes.text }), [
    classes.text
  ]);

  return (
    <ListItem
      data-qe-id={dataQeId}
      className={classes.root}
      button
      component={Link}
      href={url}
      underline="none"
      target="_self"
      rel="noopener noreferrer"
      onClick={onClick}
      dense={false}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={primaryTypographyProps}
      />
    </ListItem>
  );
};

ExternalLinksMenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dataQeId: PropTypes.string
};

export default ExternalLinksMenuItem;
