import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyCardContentLoading } from '~/modules/common/components/DetailsPage/Card';
import {
  SideCard,
  SideCardSectionHeader
} from '~/modules/common/components/SideCard';
import { useClientPermissions } from '../../hooks';
import ClientInfoBasicSection from './ClientInfoBasicSection';
import ClientInfoContactSection from './ClientInfoContactSection';
import ClientInfoAddressSection from './ClientInfoAddressSection';
import ClientInfoAdditionalSection from './ClientInfoAdditionalSection';
import ClientInfoClientRepSection from './ClientInfoClientRepSection';

const basicInfoTitle = <FormattedMessage id="client.basicInfo" />;
const contactTitle = <FormattedMessage id="client.contact" />;
const addressTitle = <FormattedMessage id="client.address" />;
const billingAddressTitle = <FormattedMessage id="client.billingAddress" />;
const clientRepresentativesTitle = (
  <FormattedMessage id="client.clientRepresentatives" />
);
const additionalInfoTitle = <FormattedMessage id="client.additionalInfo" />;

export const ClientInfoDetails = ({
  openBasicDialog,
  openAdditionalInfoDialog,
  openContactDialog,
  openAddressDialog,
  openClientRepDialog,
  edit,
  details,
  contactInfo,
  billingSameAsAddress,
  billingContactInfo,
  showBillingSection,
  showClientRepSection,
  clientRepresentatives,
  showAdditionalInfo,
  isLoading,
  featureFlags
}) => {
  const {
    canViewBillingAddress,
    canEditBillingAddress,
    canEditCustomFields
  } = useClientPermissions();

  return (
    <SideCard>
      {isLoading ? (
        <ReadOnlyCardContentLoading />
      ) : (
        <>
          {edit && (
            <SideCardSectionHeader
              title={basicInfoTitle}
              onEditClick={openBasicDialog}
              dataQeId="EditBasicSection"
              showEdit={edit}
              ariaLabelKey="button.basicInfoEditButton"
            />
          )}
          <ClientInfoBasicSection details={details} isClientEditable={edit} />
          <SideCardSectionHeader
            title={contactTitle}
            onEditClick={openContactDialog}
            dataQeId="EditContactSection"
            showEdit={edit}
            ariaLabelKey="button.contactsEditButton"
          />
          <ClientInfoContactSection details={details} />
          <SideCardSectionHeader
            title={addressTitle}
            onEditClick={openAddressDialog}
            dataQeId="EditAddressSection"
            showEdit={edit}
            ariaLabelKey="button.addressEditButton"
          />
          <ClientInfoAddressSection addressDetails={contactInfo} />
          {canViewBillingAddress && !billingSameAsAddress && (
            <>
              <SideCardSectionHeader
                dataQeId="EditBillingAddress"
                title={billingAddressTitle}
                onEditClick={openAddressDialog}
                showEdit={edit && canEditBillingAddress}
                ariaLabelKey="button.addressEditButton"
              />
              <ClientInfoAddressSection addressDetails={billingContactInfo} />
            </>
          )}
          {showClientRepSection && (
            <>
              <SideCardSectionHeader
                title={clientRepresentativesTitle}
                dataQeId="EditClientRepSection"
                onEditClick={openClientRepDialog}
                showEdit={edit}
                ariaLabelKey="button.clientRepresentativeEditButton"
              />
              <ClientInfoClientRepSection
                clientRepresentatives={clientRepresentatives}
              />
            </>
          )}
          {showAdditionalInfo && (
            <>
              <SideCardSectionHeader
                title={additionalInfoTitle}
                onEditClick={openAdditionalInfoDialog}
                dataQeId="EditAdditionalSection"
                showEdit={edit && canEditCustomFields}
                ariaLabelKey="button.additionalInfoEditButton"
              />
              <ClientInfoAdditionalSection details={details} />
            </>
          )}
        </>
      )}
    </SideCard>
  );
};

ClientInfoDetails.propTypes = {
  openBasicDialog: PropTypes.func,
  openAdditionalInfoDialog: PropTypes.func,
  openContactDialog: PropTypes.func,
  openAddressDialog: PropTypes.func,
  openClientRepDialog: PropTypes.func,
  edit: PropTypes.bool,
  details: PropTypes.object,
  contactInfo: PropTypes.object,
  billingSameAsAddress: PropTypes.bool,
  billingContactInfo: PropTypes.object,
  showBillingSection: PropTypes.bool,
  showClientRepSection: PropTypes.bool,
  clientRepresentatives: PropTypes.array,
  showAdditionalInfo: PropTypes.bool,
  isLoading: PropTypes.bool,
  featureFlags: PropTypes.object
};

export default ClientInfoDetails;
