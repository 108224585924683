import React from 'react';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

export const createBillColumn = ({ record }) => {
  const { client } = record;

  if (!client) return null;

  return (
    <Button data-qe-id="CreateBillButton" size="small">
      <FormattedMessage id="avaialbleToBillList.createBill" />
    </Button>
  );
};

createBillColumn.propTypes = {
  record: PropTypes.object.isRequired
};

export default createBillColumn;
