import { useMemo } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const useInitialState = ({ initialName }) => {
  const { baseCurrency } = useMeContext();

  return useMemo(
    () => ({
      name: initialName,
      description: '',
      costRate: mapMoneyValue(baseCurrency),
      billingRate: mapMoneyValue(baseCurrency)
    }),
    [initialName, baseCurrency]
  );
};

export const mapMoneyValue = baseCurrency => ({
  amount: 0,
  currency: {
    id: baseCurrency.id,
    displayText: baseCurrency.displayText,
    symbol: baseCurrency.displayText
  }
});
