import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const hasError = error => error !== null && error !== undefined;

const useStyles = makeStyles({
  taskName: {
    fontWeight: 400,
    overflowWrap: 'break-word'
  },
  taskDialog: {
    color: theme.palette.text.secondary
  }
});

export const DeleteConfirmationDialog = ({
  intl,
  open,
  onCancel,
  taskName,
  deleteTask,
  deleteTaskError
}) => {
  const classes = useStyles();

  const dialogTitle = intl.formatMessage({
    id: 'taskDrawerEdit.removeTaskTitle'
  });

  const dialogLine1 = intl.formatMessage({
    id: 'taskDrawerEdit.removeTaskConfirmation.messageLine1'
  });

  const dialogLine2 = intl.formatMessage({
    id: 'taskDrawerEdit.removeTaskConfirmation.messageLine2'
  });

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Typography variant="h5" display="inline">
          {dialogTitle}
        </Typography>
        <Typography variant="h5" display="inline" className={classes.taskName}>
          {taskName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {hasError(deleteTaskError) ? (
          <Typography variant="body1" className={classes.taskDialog}>
            {deleteTaskError}
          </Typography>
        ) : (
          <>
            <Typography variant="body1">{dialogLine1}</Typography>
            <br />
            <Typography variant="body1" className={classes.taskDialog}>
              {dialogLine2}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          <FormattedMessage id="taskDrawerEdit.cancel" />
        </Button>
        {!deleteTaskError && (
          <Button color="secondary" onClick={deleteTask}>
            <FormattedMessage id="taskDrawerEdit.removeTask" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmationDialog.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  taskName: PropTypes.string,
  deleteTask: PropTypes.func,
  deleteTaskError: PropTypes.string
};

export default injectIntl(DeleteConfirmationDialog);
