import url from 'url';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import NoneMenuItem from '~/modules/common/components/NoneMenuItem';
import NotificationLink from './NotificationLink';

export const Notifications = ({
  notifications,
  onMarkNotificationAsRead,
  classes
}) => {
  const intl = useIntl();

  return (
    <>
      <Typography variant="h6" noWrap className={classes.title}>
        {intl.formatMessage({ id: 'notifications.title' })}
      </Typography>
      {notifications.length > 0 ? (
        notifications.map(notification => (
          <NotificationLink
            key={notification.id}
            id={notification.id}
            message={notification.message}
            path={
              notification.targetUrl
                ? url.parse(notification.targetUrl).pathname
                : null
            }
            type={notification.type}
            onMarkNotificationAsRead={onMarkNotificationAsRead}
            classes={classes}
          />
        ))
      ) : (
        <NoneMenuItem>
          {intl.formatMessage({
            id: 'notifications.noNotifications'
          })}
        </NoneMenuItem>
      )}
    </>
  );
};

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      path: PropTypes.string
    })
  ),
  onMarkNotificationAsRead: PropTypes.func
};

export default Notifications;
