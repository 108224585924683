import React from 'react';
import { useMeContext } from '~/modules/me';
import DrawerRouter from './DrawerRouter';
import DrawerRoute from './DrawerRoute';
import { useDrawerRoutes } from './useDrawerRoutes';

export const defaultRouteKey = 'id';

export const extractKeyFromPath = path => {
  const index = path.indexOf(':');

  if (index <= 0) {
    return defaultRouteKey;
  }

  const key = path.substring(index + 1);

  return key || defaultRouteKey;
};

export const DrawerRouting = () => {
  const { routes } = useDrawerRoutes();
  const { isRolledUpTaskEstimateCalculationMethodEnabled } = useMeContext();
  const taskDrawerPath = '#/task/:taskId';

  return (
    <DrawerRouter>
      {routes.map(route => (
        <DrawerRoute
          key={route.path}
          routeKey={extractKeyFromPath(route.path)}
          path={route.path}
          component={route.component}
          drawerWidth={
            isRolledUpTaskEstimateCalculationMethodEnabled &&
            route.path === taskDrawerPath
              ? 775
              : route.drawerWidth
          }
          disableBackdropClick={route.disableBackdropClick}
          disableEscapeKeyDown={route.disableEscapeKeyDown}
          useMuiDrawerOverriddenProps={route.useMuiDrawerOverriddenProps}
        />
      ))}
    </DrawerRouter>
  );
};

export default DrawerRouting;
