import { Typography, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  SideCardSectionDetail,
  SideCardSectionHeader
} from '~/modules/common/components/SideCard';
import { MoneyValue, NoValue } from '~/modules/common/components';

const resourceLabels = {
  portfolioCurrency: <FormattedMessage id="portfolio.portfolioCurrency" />,
  title: <FormattedMessage id="portfolio.budget.title" />,
  none: <FormattedMessage id="portfolio.budget.none" />,
  total: <FormattedMessage id="portfolio.budget.total" />,
  capital: <FormattedMessage id="portfolio.budget.capital" />,
  operational: <FormattedMessage id="portfolio.budget.operational" />
};

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2)
    }
  },
  noBudget: {
    padding: theme.spacing(1, 0, 0, 0)
  }
}));

export const BudgetInfo = ({
  budget = {},
  portfolioCurrency,
  edit,
  onEditClick
}) => {
  const classes = useStyles();

  const { total, capital, operational } = budget;
  const isCapexOpexMode = Boolean(capital || operational);

  const details = useMemo(() => {
    return {
      total: total && <MoneyValue money={total} />,
      capital: capital ? <MoneyValue money={capital} /> : <NoValue />,
      operational: operational ? (
        <MoneyValue money={operational} />
      ) : (
        <NoValue />
      )
    };
  }, [total, capital, operational]);

  return (
    <div className={classes.root}>
      <SideCardSectionHeader
        title={resourceLabels.title}
        showEdit={edit}
        onEditClick={onEditClick}
        dataQeId="EditBudgetInfo"
        ariaLabelKey="button.budgetInfoEditButton"
      />
      {portfolioCurrency && (
        <SideCardSectionDetail
          detail={portfolioCurrency.displayText}
          label={resourceLabels.portfolioCurrency}
        />
      )}
      {!(total || capital || operational) ? (
        <Typography
          variant="body2"
          color="textSecondary"
          noWrap
          className={classes.noBudget}
        >
          {resourceLabels.none}
        </Typography>
      ) : (
        <>
          {isCapexOpexMode && (
            <>
              <SideCardSectionDetail
                detail={details.capital}
                label={resourceLabels.capital}
              />
              <SideCardSectionDetail
                detail={details.operational}
                label={resourceLabels.operational}
              />
            </>
          )}
          <SideCardSectionDetail
            detail={details.total}
            label={resourceLabels.total}
          />
        </>
      )}
    </div>
  );
};

BudgetInfo.propTypes = {
  budget: PropTypes.object,
  edit: PropTypes.bool,
  onEditClick: PropTypes.func,
  portfolioCurrency: PropTypes.object
};

export default BudgetInfo;
