import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useAcceptTopMatches } from '~/modules/resourcing/common/hooks';
import { UserAvatar } from '~/modules/common/components/Avatars';
import AssignResourceButton from '~/modules/resourcing/common/components/AssignResourceButton';
import { DelaStarIcon } from '~/modules/common/components';
import useStyles from './useStyles';
import useTooltipStyles from './useTooltipStyles';

export const ResourceRequestMatchItem = ({ resourceRequest }) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { formatMessage } = useIntl();
  const {
    id,
    projectUri,
    quantity,
    requestStatus,
    resourceMatches
  } = resourceRequest;

  const [resourceMatch] = resourceMatches || [];

  const { acceptTopMatches } = useAcceptTopMatches();

  const handleAccept = useCallback(
    event => {
      acceptTopMatches({ projectUri, resourceRequestUris: [id] });
    },
    [acceptTopMatches, projectUri, id]
  );

  return resourceMatch ? (
    <div className={classes.root}>
      <ButtonBase className={classes.resourceMatchButton}>
        <DelaStarIcon className={classes.delaStarIcon} />
        <UserAvatar user={resourceMatch} size="small" />
        <div className={classes.resourceMatchTextColumn}>
          <div className={classes.resourceMatchDisplayText}>
            {resourceMatch.displayText}
          </div>
          <div className={classes.resourceMatchAlternativeText}>
            <FormattedMessage id="resourceAllocationItem.findAlternative" />
          </div>
        </div>
      </ButtonBase>
      <ButtonBase onClick={handleAccept}>
        <Tooltip
          classes={tooltipClasses}
          placement="bottom-start"
          title={formatMessage({
            id: 'resourceAllocationItem.acceptResource'
          })}
        >
          <CheckCircle className={classes.checkCircleIcon} />
        </Tooltip>
      </ButtonBase>
    </div>
  ) : (
    <div className={classes.allocation}>
      <AssignResourceButton
        quantity={quantity}
        resourceRequestStatus={requestStatus}
      />
    </div>
  );
};

ResourceRequestMatchItem.propTypes = {
  resourceRequest: PropTypes.object.isRequired
};

export default ResourceRequestMatchItem;
