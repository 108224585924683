/* eslint-disable react/jsx-max-depth */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import WorkflowBadge from '~/modules/projects/project/WorkflowBadge';
import DateRange from '~/modules/common/components/DateRange';
import User from '~/modules/common/components/User';
import Client from '~/modules/common/components/Client/Client';
import Portfolio from '~/modules/common/components/Portfolio/Portfolio';

const useStyles = makeStyles(theme => ({
  root: { width: '100%', overflow: 'hidden', padding: theme.spacing(1) },
  nameContainer: {
    overflow: 'hidden'
  },
  name: {
    fontWeight: theme.typography.fontWeightBold
  },
  entities: {
    paddingTop: theme.spacing(1)
  }
}));

const useEntityStyles = makeStyles({
  container: {
    padding: 0
  }
});

const MobileProjectListItemContent = ({
  name,
  client,
  portfolio,
  status,
  startDate,
  endDate,
  projectManagerReference
}) => {
  const classes = useStyles();
  const entityClasses = useEntityStyles();

  return (
    <div className={classes.root}>
      <Grid container wrap="nowrap" justifyContent="space-between">
        <Grid className={classes.nameContainer} item>
          <Typography className={classes.name} component="h6">
            {name}
          </Typography>
          <DateRange start={startDate} end={endDate} />
        </Grid>
        <Grid item>
          <WorkflowBadge
            workflowId={status.uri}
            customStatusLabel={status.displayText}
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.entities}
        container
        spacing={1}
        alignItems="center"
      >
        {projectManagerReference && (
          <Grid item>
            <User classes={entityClasses} user={projectManagerReference} />
          </Grid>
        )}
        {portfolio && (
          <Grid item>
            <Portfolio classes={entityClasses} portfolio={portfolio} />
          </Grid>
        )}
        {client && (
          <Grid item>
            <Client classes={entityClasses} client={client} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

MobileProjectListItemContent.propTypes = {
  name: PropTypes.string.isRequired,
  client: PropTypes.object,
  portfolio: PropTypes.object,
  status: PropTypes.object.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  projectManagerReference: PropTypes.object
};

export default MobileProjectListItemContent;
