import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from 'graphql-tag';

const updateClosedOnDateMutation = gql`
  mutation updateClosedOnDate($taskId: String!, $date: Date!) {
    updateClosedOnDate: updateClosedOnDate2(
      input: { taskId: $taskId, date: $date }
    ) {
      task {
        id
        closedOnDate: closedOnDate2
      }
    }
  }
`;

export const useUpdateClosedOnDate = task => {
  const [updateClosedOnDateReq] = useMutation(updateClosedOnDateMutation);

  return {
    updateClosedOnDate: useCallback(
      date => {
        updateClosedOnDateReq({
          variables: {
            taskId: task.id,
            date
          }
        });
      },
      [updateClosedOnDateReq]
    )
  };
};

export default useUpdateClosedOnDate;
