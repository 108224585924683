import {
  useProjectsStatusCountSummaryQuery,
  useHasFeatureFlag
} from '~/modules/common/hooks';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { useProjectFilterWithExcludingArchiveProjects } from '~/modules/common/components/ProjectsQuickFilterSummary/useProjectFilterWithExcludingArchiveProjects.js';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import { getStatusCountSummaryBasedProps } from './useStatusCountSummary';
import { getQuickFilterSummaryTabs } from './getQuickFilterSummaryTabs';

export const usePortfolioProjectsQuickFilterSummary = ({
  searchCriteria,
  summaryFilterState
}) => {
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria
  });

  const filterWithExcludingArchiveProjects = useProjectFilterWithExcludingArchiveProjects(
    { projectFilter }
  );

  const {
    loading: isAllProjectsSummaryLoading,
    projectsStatusCountSummary: allProjectsStatusCountSummary = {}
  } = useProjectsStatusCountSummaryQuery({
    projectFilter: filterWithExcludingArchiveProjects
  });

  const {
    loading: isSubPortfolioProjectsSummaryLoading,
    projectsStatusCountSummary: subPortfoliosProjectsStatusCountSummary = {}
  } = useProjectsStatusCountSummaryQuery({
    projectFilter: {
      ...filterWithExcludingArchiveProjects,
      includeSubPortfolios: true
    }
  });

  const {
    loading: isArchivedCountLoading,
    projectsStatusCountSummary: archivedProjectsCountSummary = {}
  } = useProjectsStatusCountSummaryQuery({
    projectFilter
  });

  const {
    statusOptions,
    totalProjects,
    subPortfoliosProjects,
    archivedStatus
  } = getStatusCountSummaryBasedProps({
    allProjectsStatusCountSummary,
    subPortfoliosProjectsStatusCountSummary,
    archivedProjectsCount: archivedProjectsCountSummary.archived || 0,
    filterByAccess: summaryFilterState.quickFilter.filterByAccess,
    customStatusMapping,
    isCustomStatusNameEnabled
  });

  const {
    allItems,
    executionPhases,
    alternativeItems
  } = getQuickFilterSummaryTabs({
    totalProjects,
    statusOptions,
    subPortfoliosProjects,
    archivedStatus
  });

  const isLoading =
    isAllProjectsSummaryLoading ||
    isArchivedCountLoading ||
    isSubPortfolioProjectsSummaryLoading;

  return {
    allItems,
    executionPhases,
    alternativeItems,
    isLoading
  };
};
