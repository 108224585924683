import { Grid, IconButton, Link, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoValue, NumberInput } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { useUpdateScoreOverrideTotal } from './hooks/useUpdateScoreOverrideTotal';

const useStyles = makeStyles(theme => ({
  editButton: {
    padding: 0,
    margin: theme.spacing(0, 2, 0, 2)
  },
  useRecommendedLink: {
    margin: theme.spacing(0, 0, 0, 0.5),
    cursor: 'pointer'
  },
  overrideTotalSection: {
    display: 'flex',
    alignItems: 'center'
  },
  totalLabelStyle: {
    alignItems: 'center',
    display: 'flex'
  },
  editFieldStyle: {
    width: 120,
    marginRight: 16
  },
  suggestedScoreText: {
    ...theme.typography.caption
  }
}));

export const ScoreTotalSection = () => {
  const {
    setFieldValue,
    values: { score, id }
  } = useFormikContext();
  const { total, overrideTotal } = score;
  const classes = useStyles();
  const { canEditProjectRequest } = useProjectRequestObjectPermissions();
  const { updateScoreOverrideTotal } = useUpdateScoreOverrideTotal({
    id
  });
  const [hasOverrideTotal, setHasOverrideTotal] = useState(
    !isNullOrUndefined(overrideTotal)
  );

  const onValueChangeHandler = useCallback(
    e => {
      setFieldValue(`score.overrideTotal`, e.target.value);
    },
    [setFieldValue]
  );

  const handleRecommendedScoreChange = useCallback(() => {
    setHasOverrideTotal(false);
    setFieldValue(`score.overrideTotal`, null);
    updateScoreOverrideTotal({
      overrideTotal: null
    });
  }, [setFieldValue, updateScoreOverrideTotal]);

  const handleHasOverrideTotalChange = useCallback(() => {
    setHasOverrideTotal(!hasOverrideTotal);
  }, [hasOverrideTotal]);

  const onBlurHandler = useCallback(() => {
    updateScoreOverrideTotal({
      overrideTotal: overrideTotal === '' ? null : overrideTotal
    });
  }, [overrideTotal, updateScoreOverrideTotal]);

  return (
    <>
      <Grid className={classes.totalLabelStyle} item lg={2} md={2} xs={12}>
        <span>
          <FormattedMessage id="projectRequest.totalScore" />
        </span>
      </Grid>
      <Grid item lg={10} md={10} xs={12}>
        {!hasOverrideTotal ? (
          <>
            <span data-qe-id="ScoreTotalInSection">
              {total === null ? <NoValue /> : total.toFixed(2)}
            </span>
            {canEditProjectRequest && (
              <IconButton
                data-qe-id="EditScore"
                className={classes.editButton}
                onClick={handleHasOverrideTotalChange}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        ) : (
          <div className={classes.overrideTotalSection}>
            <NumberInput
              data-qe-id="OverriderScoreInput"
              className={classes.editFieldStyle}
              value={overrideTotal}
              onBlur={onBlurHandler}
              onValueChange={onValueChangeHandler}
              variant="filled"
              allowNegative
              size="small"
              fixedDecimalScale={false}
              disabled={!canEditProjectRequest}
            />
            <div className={classes.suggestedScoreText}>
              <FormattedMessage
                id="projectRequest.suggestedScore"
                values={{
                  total: total === null ? <NoValue /> : total.toFixed(2)
                }}
              />

              {canEditProjectRequest && (
                <Link
                  component="button"
                  data-qe-id="UseRecommendedScore"
                  onClick={handleRecommendedScoreChange}
                  className={classes.useRecommendedLink}
                >
                  <FormattedMessage id="projectRequest.useThis" />
                </Link>
              )}
            </div>
          </div>
        )}
      </Grid>
    </>
  );
};

export default ScoreTotalSection;
