import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import createCustomLuxonUtils from './CustomLuxonUtils';

const getLocale = me => me.locale.language.cultureCode;

const withMuiPickers = BaseComponent => ({ me, ...props }) => {
  const locale = getLocale(me);

  return (
    <MuiPickersUtilsProvider
      utils={createCustomLuxonUtils(me.workWeekStartDay?.uri)}
      locale={locale}
    >
      <BaseComponent {...props} />
    </MuiPickersUtilsProvider>
  );
};

export default withMuiPickers;
