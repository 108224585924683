import { makeStyles } from '@material-ui/core';
import React from 'react';
import AddProjectRequestForm from '../AddProjectRequestDialog/AddProjectRequestForm';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 1, 0, 1),
    margin: 0,
    width: '100%',
    borderTop: `1px solid ${theme.palette.table.border}`
  }
}));

export const ProjectRequestDrawerContent = () => {
  const classes = useStyles();

  return <AddProjectRequestForm classes={classes} />;
};

export default ProjectRequestDrawerContent;
