import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PolarisLogoAnimation from './PolarisLogoAnimation';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    animation: `$fadeInAnimation 1.8s ease-in 1 forwards`
  },
  '@keyframes fadeInAnimation': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  container: {
    flexGrow: 0,
    flexShrink: 1,
    maxWidth: theme.spacing(30),
    width: '100%',
    maxHeight: '100%',
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    boxSizing: 'border-box'
  },
  logo: {
    position: 'relative',
    width: '100%',
    verticalAlign: 'bottom'
  },
  message: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'center',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    fontWeight: 600
  }
}));

const RootLoader = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container)}>
        <PolarisLogoAnimation className={classes.logo} />
        <Typography variant="h4" className={classes.message}>
          Polaris
        </Typography>
      </div>
    </div>
  );
};

export default RootLoader;
