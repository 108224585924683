import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import { projectTag } from '~/modules/common/components/SearchBox/Facets/ProjectFacet/tag';
import { programTag } from '~/modules/common/components/SearchBox/Facets/ProgramFacet/tag';
import { dateTag } from '~/modules/common/components/SearchBox/Facets/DateRangeFacet/tag';
import {
  checkForCriteria,
  getForCriteria,
  getDateForCriteria
} from '~/modules/common/util';

const allTags = [clientTag, dateTag];

export const useFilterBasedOnSearchCriteria = ({
  searchCriteria: { keywords, criterions } = {}
}) => {
  if (
    !(keywords && keywords.length > 0) ||
    allTags.some(tag => checkForCriteria(criterions, tag))
  )
    return {
      availableToBillFilter: {
        text: keywords && keywords.length > 0 ? keywords.join(' ') : null,
        clients: getForCriteria(criterions, clientTag),
        projects: getForCriteria(criterions, projectTag),
        programs: getForCriteria(criterions, programTag),
        dateRange: getDateForCriteria(criterions, dateTag)
      }
    };

  return {};
};
