import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  EmailSharp as EmailIcon,
  CallSharp as PhoneIcon
} from '@material-ui/icons';
import {
  SideCardSectionDetail,
  SideCardSectionIconDetail
} from '~/modules/common/components/SideCard';
import {
  ContactIcon,
  FaxIcon,
  WebsiteIcon
} from '~/modules/common/components/Icons';
import { NoDataItem } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  link: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export const noContactText = (
  <NoDataItem>
    <FormattedMessage id="client.noContact" />
  </NoDataItem>
);

const icons = {
  name: <ContactIcon width={18} />,
  email: <EmailIcon />,
  phone: <PhoneIcon />,
  fax: <FaxIcon />,
  website: <WebsiteIcon />
};

export const ClientInfoContactSection = ({ details }) => {
  const classes = useStyles();

  const contactName = get(details, 'contactName');
  const contactInfo = get(details, 'contactInfo', {});

  const { email, faxNumber, phoneNumber, website } = contactInfo;

  const hasContactInfo =
    contactName || email || faxNumber || phoneNumber || website;

  const emailLink = email && (
    <span className={classes.link}>
      <Link href={`mailto:${email}`} target="_blank">
        {email}
      </Link>
    </span>
  );

  const websitePrefix = website && website.startsWith('http') ? '' : '//';

  const websiteLink = website && (
    <span className={classes.link}>
      <Link
        href={`${websitePrefix}${website}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {website}
      </Link>
    </span>
  );

  return (
    <div className={classes.root}>
      {hasContactInfo ? (
        <>
          {contactName && (
            <SideCardSectionIconDetail icon={icons.name} detail={contactName} />
          )}
          {emailLink && (
            <SideCardSectionIconDetail icon={icons.email} detail={emailLink} />
          )}
          {phoneNumber && (
            <SideCardSectionIconDetail
              icon={icons.phone}
              detail={phoneNumber}
            />
          )}
          {faxNumber && (
            <SideCardSectionIconDetail icon={icons.fax} detail={faxNumber} />
          )}
          {websiteLink && (
            <SideCardSectionIconDetail
              icon={icons.website}
              detail={websiteLink}
            />
          )}
        </>
      ) : (
        <SideCardSectionDetail detail={noContactText} />
      )}
    </div>
  );
};

ClientInfoContactSection.propTypes = {
  details: PropTypes.object
};

export default ClientInfoContactSection;
