import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Popover
} from '@material-ui/core';
import { MoreVertSharp, AddSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useMenuState } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.paper
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonGroup: {
    width: '85%',
    display: 'flex',
    justifyContent: 'flex-start',
    '& button': {
      marginRight: '5%'
    }
  },
  removeButton: {
    padding: theme.spacing(2),
    '& > span': {
      color: '#91054d',
      textTransform: 'capitalize'
    }
  }
}));

const popoverProps = {
  anchor: {
    vertical: 'top',
    horizontal: 'left'
  },
  transform: {
    vertical: 'bottom',
    horizontal: 'right'
  }
};

export const MobileDrawerToolbar = ({
  disabled,
  handleSubmit,
  stopEditing,
  openDialog,
  openAddTaskDialog,
  isSubmitting,
  taskIndex
}) => {
  const classes = useStyles();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isOpen = Boolean(anchorEl);

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolBar}>
        <div className={classes.buttonGroup}>
          <Button
            data-qe-id="TaskDrawerSaveButton"
            onClick={handleSubmit}
            disabled={disabled}
            color="primary"
            variant="contained"
          >
            <FormattedMessage id="taskDrawerEdit.save" />
          </Button>
          <Button
            onClick={stopEditing}
            disabled={isSubmitting}
            variant="contained"
          >
            <FormattedMessage id="taskDrawerEdit.cancel" />
          </Button>
          {taskIndex < 10 && (
            <Button
              data-qe-id="TaskDrawerAddSubTaskButton"
              onClick={openAddTaskDialog}
            >
              <AddSharp />
              <FormattedMessage id="taskDrawerEdit.addSubTask" />
            </Button>
          )}
        </div>
        <IconButton onClick={onMenuClick} edge="end">
          <MoreVertSharp />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={popoverProps.anchor}
          transformOrigin={popoverProps.transform}
          open={isOpen}
          onClose={onMenuClose}
        >
          <Button
            data-qe-id="TaskDrawerRemoveButton"
            onClick={openDialog}
            disabled={isSubmitting}
            className={classes.removeButton}
          >
            <FormattedMessage id="taskDrawerEdit.remove" />
          </Button>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

MobileDrawerToolbar.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  stopEditing: PropTypes.func,
  openDialog: PropTypes.func,
  openAddTaskDialog: PropTypes.func,
  isSubmitting: PropTypes.bool,
  taskIndex: PropTypes.number.isRequired
};

export default MobileDrawerToolbar;
