import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  makeStyles
} from '@material-ui/core';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  description: {
    whiteSpace: 'break-spaces'
  }
}));
const DeactivatePortfolioConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  isRemoving
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="entity-confirmation-dialog-title">
        <FormattedMessage id="portfolio.deleteDialog.deactivatePortfolio" />
      </DialogTitle>
      <DialogContent id="entity-confirmation-dialog-description">
        <DialogContentText>
          <Typography variant="body2" className={classes.description}>
            <FormattedMessage id="portfolio.deleteDialog.requestsDeactivateDescription" />
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="resourceRequestActions.cancel" />
        </Button>
        <LoadingButton
          onClick={onConfirm}
          color="secondary"
          isLoading={isRemoving}
          autoFocus
        >
          <FormattedMessage id="portfolio.deleteDialog.deactivatePortfolio" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeactivatePortfolioConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isRemoving: PropTypes.bool
};

export default DeactivatePortfolioConfirmationDialog;
