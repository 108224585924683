import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));

const useSkeletonStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(0.75)
  }
}));

const ProgressDetailsLoading = () => {
  const classes = useStyles();
  const skeletonClasses = useSkeletonStyles();

  return (
    <div className={classes.container}>
      <Skeleton
        classes={skeletonClasses}
        variant="rect"
        width={64}
        height={16}
      />
      <Skeleton
        classes={skeletonClasses}
        variant="rect"
        width={172}
        height={48}
      />
      <Skeleton
        classes={skeletonClasses}
        variant="rect"
        width={210}
        height={32}
      />
    </div>
  );
};

export default ProgressDetailsLoading;
