import { InputAdornment, Popover, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Hours } from '~/modules/common/components';
import { ESTIMATED_HOURS_MAX } from '~/modules/common/components/TaskDrawer/EditTask/constants';
import useOnChangeHandlers from '../../../hooks/useOnChangeHandlers';

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.spacing(57.5),
    minHeight: theme.spacing(9),
    padding: theme.spacing(1.75, 1.5, 1)
  }
}));

const useTaskResourceEstimatesStyles = makeStyles(theme => ({
  input: {
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}));

const endAdornment = (
  <InputAdornment position="end">
    <FormattedMessage id="taskAllocationEditor.hoursSuffix" />
  </InputAdornment>
);

const PlaceholderEstimatedHoursEditor = ({
  anchorEl,
  setAnchorEl,
  resourceEstimate,
  rowIndex
}) => {
  const popoverClasses = usePopoverStyles();
  const taskResourceEstimatesClasses = useTaskResourceEstimatesStyles();
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();

  const { initialEstimatedHours } = resourceEstimate;

  const [estimatedHours, setEstimatedHours] = useState(
    initialEstimatedHours || null
  );

  const { onEstimateChange } = useOnChangeHandlers({
    resourceEstimate
  });

  const handleOnBlur = useCallback(() => {
    if (initialEstimatedHours === estimatedHours) return;

    onEstimateChange(estimatedHours);
    setFieldValue(
      `resourceEstimates[${rowIndex}].initialEstimatedHours`,
      estimatedHours
    );
  }, [
    initialEstimatedHours,
    estimatedHours,
    onEstimateChange,
    setFieldValue,
    rowIndex
  ]);

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleOnBlur(event);
      }
    },
    [handleOnBlur]
  );

  const onChange = useCallback(
    event =>
      setEstimatedHours(Math.min(ESTIMATED_HOURS_MAX, event.target.value)),
    [setEstimatedHours]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Popover
      id="task-estimate-placeholder-editor"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={popoverClasses}
    >
      <Hours
        dataQeId="TaskEstimateHours"
        align="left"
        ariaLabel={formatMessage({
          id: 'taskResourceEstimates.hoursInputField'
        })}
        autoFocus
        classes={taskResourceEstimatesClasses}
        endAdornment={endAdornment}
        fullWidth={false}
        isEditible
        label={formatMessage({
          id: 'taskResourceEstimates.estimate'
        })}
        onBlur={handleOnBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={estimatedHours}
        variant="outlined"
      />
    </Popover>
  );
};

PlaceholderEstimatedHoursEditor.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  resourceEstimate: PropTypes.object,
  rowIndex: PropTypes.number
};

export default PlaceholderEstimatedHoursEditor;
