import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, ListSubheader, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';
import {
  useTooltipStyles,
  useTableSortLabelHoverStyles,
  useIconButtonStyles,
  useAvailabilityTitleStyles
} from './useStyles';
import UserAvailabilityDropdownSkeleton from './UserAvailabilityDropdownSkeleton';

export const UserAvailabilityDropdownItem = ({
  params,
  classes,
  isAvailabilityEnabled,
  sort,
  isLoading,
  onSortChange,
  hasViewAvailabilityPermission,
  toggleAvailabilityColumnVisibility
}) => {
  const { formatMessage } = useIntl();
  const availabilityClasses = useAvailabilityTitleStyles();
  const popperClasses = useTooltipStyles();
  const iconButtonClasses = useIconButtonStyles();
  const tableSortLabelHoverClasses = useTableSortLabelHoverStyles();

  return params.group ? (
    <li key={params.key}>
      <ListSubheader component="div" className={classes.groupLabel}>
        <span className={classes.resourceTitle}>
          <TableSortLabel
            show
            field="displayText"
            active={sort.field === 'displayText'}
            direction={sort.direction.toLowerCase()}
            onClick={onSortChange}
            className={classes.nameSortLabel}
          >
            {params.group}
          </TableSortLabel>
        </span>
        {hasViewAvailabilityPermission && params.key === 0 && (
          <span
            className={classNames(availabilityClasses.root, {
              [availabilityClasses.isDisabled]: !isAvailabilityEnabled
            })}
          >
            <TableSortLabel
              show={isAvailabilityEnabled}
              field="availabilityPercentage"
              active={sort.field === 'availabilityPercentage'}
              direction={sort.direction.toLowerCase()}
              onClick={onSortChange}
              className={classes.availabilitySortLabel}
              classes={tableSortLabelHoverClasses}
              align="right"
            >
              <FormattedMessage id="addResource.availability" />
            </TableSortLabel>

            <IconButton
              label="Availability-icon"
              aria-label="availability-preview"
              onClick={toggleAvailabilityColumnVisibility}
              classes={iconButtonClasses}
            >
              {isAvailabilityEnabled ? (
                <Tooltip
                  title={formatMessage({ id: 'addResource.visibilityOn' })}
                  placement="bottom-start"
                  classes={popperClasses}
                >
                  <Visibility className={availabilityClasses.isEnabled} />
                </Tooltip>
              ) : (
                <Tooltip
                  title={formatMessage({ id: 'addResource.visibilityOff' })}
                  placement="bottom-start"
                  classes={popperClasses}
                >
                  <VisibilityOff className={availabilityClasses.isDisabled} />
                </Tooltip>
              )}
            </IconButton>
          </span>
        )}
      </ListSubheader>
      {isLoading ? (
        <UserAvailabilityDropdownSkeleton
          isAvailabilityEnabled={isAvailabilityEnabled}
          classes={classes}
        />
      ) : (
        <ul className={classes.groupUl}>{params.children}</ul>
      )}
    </li>
  ) : (
    <li key={params.key}>
      <ul className={classes.groupUl}>{params.children}</ul>
    </li>
  );
};

UserAvailabilityDropdownItem.propTypes = {
  params: PropTypes.object,
  classes: PropTypes.object,
  isAvailabilityEnabled: PropTypes.bool,
  sort: PropTypes.object.isRequired,
  onSortChange: PropTypes.func.isRequired,
  hasViewAvailabilityPermission: PropTypes.bool,
  toggleAvailabilityColumnVisibility: PropTypes.func,
  isLoading: PropTypes.bool
};

export default UserAvailabilityDropdownItem;
