import { useHasPermission, hasPermission } from '~/modules/common/permissions';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { isMobileApp } from '~/util';

export const isCostManagerEnabled = ({
  permissionsMap,
  featureFlags: { isPsaPrpCostManagerEnabled = false }
}) => {
  if (!isPsaPrpCostManagerEnabled || isMobileApp()) return false;
  const permissionFunc = hasPermission(permissionsMap);

  return (
    permissionFunc({
      actionUri: 'urn:replicon:timesheet-action:view-timesheet',
      dataAccessLevelUri:
        'urn:replicon:timesheet-data-access-level:cost-manager-data-access-scope'
    }) ||
    permissionFunc({
      actionUri: 'urn:replicon:expense-action:view-expense-sheet',
      dataAccessLevelUri:
        'urn:replicon:expense-data-access-level:cost-manager-data-access-scope'
    }) ||
    permissionFunc({
      actionUri:
        'urn:replicon:time-data-workbench-action:view-time-data-workbench'
    })
  );
};

export const useCostManagerPermissions = () => {
  const isSupportedDevice = isMobileApp() !== true;
  const isEnabled =
    useHasFeatureFlag({
      featureFlag: 'isPsaPrpCostManagerEnabled'
    }) && isSupportedDevice;
  const hasAccessToTimesheets =
    useHasPermission({
      actionUri: 'urn:replicon:timesheet-action:view-timesheet',
      dataAccessLevelUri:
        'urn:replicon:timesheet-data-access-level:cost-manager-data-access-scope'
    }) && isEnabled;
  const hasAccessToExpenses =
    useHasPermission({
      actionUri: 'urn:replicon:expense-action:view-expense-sheet',
      dataAccessLevelUri:
        'urn:replicon:expense-data-access-level:cost-manager-data-access-scope'
    }) && isEnabled;
  const hasAccessToTimeWorkbench =
    useHasPermission({
      actionUri:
        'urn:replicon:time-data-workbench-action:view-time-data-workbench'
    }) && isEnabled;
  const isTimeAdmin = hasAccessToTimesheets || hasAccessToTimeWorkbench;
  const title =
    isTimeAdmin && hasAccessToExpenses
      ? 'timeAndExpenseAdmin.title'
      : isTimeAdmin
      ? 'timeAndExpenseAdmin.timeAdmin'
      : 'timeAndExpenseAdmin.expenseAdmin';

  return {
    isCostManager:
      hasAccessToTimesheets || hasAccessToExpenses || hasAccessToTimeWorkbench,
    hasAccessToTimesheets,
    hasAccessToExpenses,
    hasAccessToTimeWorkbench,
    titleId: title
  };
};
