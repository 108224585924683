import React from 'react';
import PropTypes from 'prop-types';
import { deepPure } from '~/util';
import { RoleName } from '~/modules/common/components/entities/role';
import { CreatableOption } from '~/modules/common/components/SearchAutocomplete';

const getRoleFromOption = option => ({
  displayText: option.displayText,
  symbol: option.currentRate?.currency?.displayText,
  amount: option.currentRate?.amount
});

export const RoleDropdownItem = ({ option, onCreate }) => {
  const { isCreatable } = option;

  const role = getRoleFromOption(option);

  return isCreatable ? (
    <CreatableOption
      option={option}
      onCreate={onCreate}
      optionPropText="displayText"
      primaryLabelTextId="addRoleDialog.creatableRole"
    />
  ) : (
    <RoleName
      data-qe-id={`${option.displayText}_MenuItem`}
      role={role}
      showRate={false}
    />
  );
};

RoleDropdownItem.propTypes = {
  option: PropTypes.object,
  onCreate: PropTypes.func
};

export default deepPure(RoleDropdownItem);
