import PropTypes from 'prop-types';
import React from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Typography,
  Button
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    bottom: theme.spacing(0),
    zIndex: 11,
    left: 0,
    backgroundColor: '#494949',
    padding:
      theme.direction === 'rtl'
        ? theme.spacing(0, 2, 0, 2)
        : theme.spacing(0, 2, 0, 0)
  },
  container: {
    backgroundColor: '#494949',
    height: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-between'
  },
  removeIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    color: '#fff',
    height: theme.spacing(4.5)
  },
  selectedItem: {
    color: '#fff'
  },
  removeButton: {
    marginRight: theme.spacing(1.5),
    height: theme.spacing(4.5)
  }
}));

export const RemoveActionForResource = ({
  openDialog,
  selectedResourcesCount,
  onClearClick
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        className={classes.container}
        alignItems="center"
      >
        <Grid item className={classes.removeIcon}>
          <IconButton onClick={onClearClick} className={classes.iconButton}>
            <ClearIcon />
          </IconButton>
          <Typography variant="subtitle1" className={classes.selectedItem}>
            <FormattedMessage
              id="vendorListTable.selectedItem"
              values={selectedResourcesCount}
            />
          </Typography>
        </Grid>
        <Button
          variant="contained"
          className={classes.removeButton}
          onClick={openDialog}
        >
          <FormattedMessage id="vendorListTable.remove" />
        </Button>
      </Grid>
    </div>
  );
};

RemoveActionForResource.propTypes = {
  openDialog: PropTypes.func,
  selectedResourcesCount: PropTypes.object,
  onClearClick: PropTypes.func
};

export default RemoveActionForResource;
