import React from 'react';
import PropTypes from 'prop-types';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import { usePeriodTimelineBlocks } from '~/modules/resourcing/common/chart/hooks';
import { useTaskAllocationDragIndicators } from '~/modules/resourcing/common/hooks';
import GenericTimelineBlocks from './components/GenericTimelineBlocks';

export const TaskAllocationTimelineBlocks = ({
  taskAllocation,
  chartDisplayDateRange,
  chartDisplayPeriods,
  handleAllocationPeriodClick,
  onAllocationChange,
  resourceAvailabilitySummary,
  scale,
  isEditable,
  getCustomDisplayPeriodProps
}) => {
  const { displayUnit } = useDisplayUnitContext();

  const { overlayPeriods, allocationPeriods } = usePeriodTimelineBlocks({
    allocation: taskAllocation,
    chartDisplayDateRange,
    chartDisplayPeriods,
    scale,
    getCustomDisplayPeriodProps
  });

  const dragIndicatorHandlers = useTaskAllocationDragIndicators({
    taskAllocation,
    chartStartDate: chartDisplayDateRange.startDate,
    scale,
    onAllocationChange
  });

  return (
    <GenericTimelineBlocks
      allocation={taskAllocation}
      handleAllocationPeriodClick={handleAllocationPeriodClick}
      resourceAvailabilitySummary={resourceAvailabilitySummary}
      scale={scale}
      isEditable={isEditable}
      isReadOnly={!isEditable}
      displayUnit={displayUnit}
      overlayPeriods={overlayPeriods}
      allocationPeriods={allocationPeriods}
      dragIndicatorHandlers={dragIndicatorHandlers}
      hideCost
    />
  );
};

TaskAllocationTimelineBlocks.propTypes = {
  scale: PropTypes.string.isRequired,
  taskAllocation: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  chartDisplayDateRange: PropTypes.object,
  onAllocationChange: PropTypes.func.isRequired,
  chartDisplayPeriods: PropTypes.array,
  resourceAvailabilitySummary: PropTypes.object,
  isEditable: PropTypes.bool,
  getCustomDisplayPeriodProps: PropTypes.func
};

export default TaskAllocationTimelineBlocks;
