import { makeStyles } from '@material-ui/core';
import statusFactStyles from '../statusFactStyles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[50]
  },
  estimateAtCompletion: {
    backgroundColor: theme.palette.grey[50],
    paddingLeft: theme.spacing(3.5),
    paddingBottom: theme.spacing(2)
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  boldItem: {
    fontWeight: 500
  },
  facts: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 1, 0),
    flexWrap: 'wrap'
  },
  fact: {
    ...statusFactStyles(theme)
  },
  columnHeader: {
    marginLeft: theme.spacing(1)
  },
  arrow: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    margin: theme.spacing(-0.5, 0.5)
  },
  table: {
    display: 'grid',
    alignItems: 'baseline',
    gridRowGap: 0,
    justifyItems: 'start',
    gridColumnGap: 0,
    justifyContent: 'left',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto auto'
  },
  row: {
    display: 'contents'
  },
  title: {
    padding: theme.spacing(0.75, 1.5),
    whiteSpace: 'nowrap',
    justifySelf: 'start'
  },
  value: {
    padding: theme.spacing(0.75, 0),
    whiteSpace: 'nowrap',
    justifySelf: 'start'
  },
  header: {
    color: theme.palette.text.secondary
  },
  status: {
    paddingLeft: theme.spacing(0)
  },
  hours: {
    textAlign: 'right',
    justifySelf: 'end',
    paddingRight: 0
  },
  dates: {
    paddingRight: 0
  },
  label: {
    color: theme.palette.text.secondary
  },
  placeHolder: {
    paddingLeft: 0
  },
  plan: {},
  subtext: {
    fontSize: theme.typography.caption.fontSize
  },
  estimatedPart: {},
  diffNegative: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
    border: `solid 1px ${theme.palette.error.light}`,
    borderRadius: '0.5em',
    paddingLeft: '2px',
    paddingRight: '2px',
    fontSize: theme.typography.caption.fontSize,
    width: 'fit-content',
    marginLeft: '0.5em'
  },
  onPlan: {
    color: theme.palette.text.secondary
  },
  overPlan: {
    color: theme.palette.error.dark,
    fontWeight: theme.typography.fontWeightBold
  },
  dateRangeOnPlan: {
    '& :last-child': {
      color: theme.palette.text.secondary
    }
  },
  dateRangeOverPlan: {
    '& :last-child': {
      color: theme.palette.error.dark,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  projectedMark: {
    color: theme.palette.text.secondary
  }
}));

export default useStyles;
