import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

const deleteTaskMutation = gql`
  mutation deleteTask($taskId: String!) {
    deleteTask: deleteTask2(input: { taskId: $taskId }) {
      result
    }
  }
`;

export const useDeleteTask = ({ task, onClose }) => {
  const [deleteTaskError, setDeleteTaskError] = useState(null);

  const [deleteTaskRequest] = useMutation(deleteTaskMutation);

  const deleteTask = useCallback(async () => {
    const {
      data: {
        deleteTask: { result: error }
      }
    } = await deleteTaskRequest({
      variables: {
        taskId: task.id
      },
      refetchQueries: ['projectTasksQuery'],
      awaitRefetchQueries: true
    });

    if (error) {
      setDeleteTaskError(error);
    } else {
      onClose();
    }

    return error;
  }, [deleteTaskRequest, onClose, task.id]);

  return {
    deleteTask,
    deleteTaskError
  };
};

export default useDeleteTask;
