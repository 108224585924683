import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Decimal, NoValue } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';

const useStyles = makeStyles(theme => ({
  hours: {
    lineHeight: 1,
    fontSize: theme.typography.body2.fontSize
  },
  cost: {
    fontSize: theme.typography.caption.fontSize
  },
  noValue: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0, -2)
  }
}));

export const FormattedHourCostItem = ({
  hours,
  cost = {},
  hoursSuffix,
  hasCostPermissions = false,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div>
      {isNullOrUndefined(hours) ? (
        <Typography className={classNames(classes.hours, classes.noValue)}>
          <FormattedMessage id="taskDrawer.na" />
        </Typography>
      ) : (
        <Decimal value={hours} className={classes.hours} suffix={hoursSuffix} />
      )}
      {hasCostPermissions && (
        <>
          <br />
          {cost?.amount ? (
            <div className={classes.currency}>
              <span>{`${cost.currencySymbol} `}</span>
              <Decimal value={cost.amount} className={classes.cost} />
            </div>
          ) : (
            <NoValue key="NoValue" className={classes.cost} />
          )}
        </>
      )}
    </div>
  );
};

FormattedHourCostItem.propTypes = {
  hours: PropTypes.number,
  cost: PropTypes.object,
  hoursSuffix: PropTypes.string,
  hasCostPermissions: PropTypes.bool,
  classes: PropTypes.object
};

export default FormattedHourCostItem;
