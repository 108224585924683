import { useCallback } from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { roundToDecimals } from '~/modules/resourcing/common/util';
import {
  getInitialEstimatedHoursForTaskAllocation,
  getProjectAvailableHours,
  startIsAfterEnd
} from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor/common/taskAllocationEditorUtil';

export const onDateChange = async ({
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  initialEstimatedHours,
  setValues,
  getTotalResourceAvailableHoursForDateRange,
  userUri
}) => {
  if (!startDate || !endDate) {
    setValues({
      startDate,
      endDate,
      projectAvailableHours: 0,
      allocatedHours: null,
      resourceAvailabilityHours: 0
    });
  } else {
    const {
      loading,
      availableDuration
    } = await getTotalResourceAvailableHoursForDateRange({
      resourceUserId: userUri,
      startDate,
      endDate
    });

    const resourceAvailabilityHours =
      !loading && roundToDecimals(availableDuration);

    const projectAvailableHours = resourceAllocationScheduleRules
      ? getProjectAvailableHours({
          start: mapRepliconDateToUtcObject(startDate),
          end: mapRepliconDateToUtcObject(endDate),
          resourceAllocationScheduleRules,
          otherTaskAllocationsSummaryScheduleRules
        })
      : 0;

    const allocatedHours = getInitialEstimatedHoursForTaskAllocation({
      initialEstimatedHours,
      projectAvailableHours
    });

    setValues({
      startDate,
      endDate,
      projectAvailableHours,
      allocatedHours,
      resourceAvailabilityHours
    });
  }
};

const useFormChangeHandlers = ({
  values,
  setValues,
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  initialEstimatedHours,
  userUri,
  getTotalResourceAvailableHoursForDateRange
}) => ({
  onStartDateChange: useCallback(
    async val => {
      await onDateChange({
        startDate: val,
        endDate:
          endDate && startIsAfterEnd({ start: val, end: endDate })
            ? val
            : endDate,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        initialEstimatedHours,
        setValues,
        userUri,
        getTotalResourceAvailableHoursForDateRange
      });
    },
    [
      endDate,
      getTotalResourceAvailableHoursForDateRange,
      initialEstimatedHours,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      setValues,
      userUri
    ]
  ),
  onEndDateChange: useCallback(
    async val => {
      await onDateChange({
        startDate:
          startDate && startIsAfterEnd({ start: startDate, end: val })
            ? val
            : startDate,
        endDate: val,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        initialEstimatedHours,
        setValues,
        userUri,
        getTotalResourceAvailableHoursForDateRange
      });
    },
    [
      getTotalResourceAvailableHoursForDateRange,
      initialEstimatedHours,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      setValues,
      startDate,
      userUri
    ]
  ),
  onTaskAllocationHoursChange: useCallback(
    event => {
      setValues({ ...values, allocatedHours: event.target.value || 0 });
    },
    [setValues, values]
  )
});

export default useFormChangeHandlers;
