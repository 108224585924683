import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { TOBEHIRED_RESOURCE_REQUEST } from './withMarkResourceRequestAsToBeHired';

export const useMarkResourceRequestAsToBeHired = ({ resourceRequest }) => {
  const [markResourceRequestAsToBeHired] = useMutation(
    TOBEHIRED_RESOURCE_REQUEST
  );

  const onMarkResourceRequestAsToBeHired = useCallback(
    () =>
      markResourceRequestAsToBeHired(
        mapToServiceRequest({
          request: resourceRequest,
          responseType: 'markResourceRequestAsToBeHired2',
          responseTypeName: 'MarkResourceRequestAsToBeHiredResult',
          requestStatus: ResourceRequestStatus.Tobehired
        })
      ),
    [markResourceRequestAsToBeHired, resourceRequest]
  );

  return {
    onMarkResourceRequestAsToBeHired
  };
};

export default useMarkResourceRequestAsToBeHired;
