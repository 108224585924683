import React, { useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { toRepliconDate } from '~/modules/common/dates/convert';
import {
  SimpleAutocomplete,
  AutocompleteInput
} from '~/modules/common/components/SearchAutocomplete';
import UserWithAvailability from '~/modules/common/components/User/UserWithAvailability';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { MoreResult, User } from '~/modules/common/components';
import { getDefaultCustomPopper } from '~/modules/common/components/CustomPopper';
import { useMeContext } from '~/modules/me/useMeContext';
import { debounce, scope } from '~/modules/common/debounce';
import { USER_DAL, USER_PERMISSION } from '~/modules/common/enums';
import { hasPermission } from '../../permissions';
import useStyles, { useInputStyles } from './useStyles';
import UserAvailabilityDropdownItem from './UserAvailabilityDropdownItem';
import useResourceUsers from './useResourceUsers';
import { useColumnSettings } from './hooks/useColumnSettings';

const getOptionLabel = option => option.displayText;
const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

const userScope = scope();

export const UserAvailabilityDropdown = ({
  value,
  onChange,
  placeholder = '',
  userRole = null,
  dataQeId,
  dateRange,
  optionTypeText,
  disableClearable = false,
  disableOnBlur = false,
  inputClasses: inputClassesOverride,
  variant,
  hiddenLabel = true,
  userClassesOverride,
  inputRef,
  onBlur: onBlurCallback
}) => {
  const [selectedValue, setSelectedValue] = useState(value ? [value] : []);
  const [userSearchText, setUserSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const me = useMeContext();
  const { permissionsMap, resourceCostMode } = me;
  const intl = useIntl();
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled, isPsaRmpBugFixes2024 }
  } = me;

  const isProjectRoleEnabled = Boolean(
    permissionsMap['urn:replicon:user-action:view-project-role']
  );

  const hasPermissionWithMap = hasPermission(permissionsMap);

  const hasViewAvailabilityPermission =
    hasPermissionWithMap({
      actionUri: USER_PERMISSION.VIEW_AVAILABILITY,
      dataAccessLevelUri: USER_DAL.RESOURCE_MANAGER
    }) ||
    hasPermissionWithMap({
      actionUri: USER_PERMISSION.VIEW_AVAILABILITY,
      dataAccessLevelUri: USER_DAL.PROJECT_MANAGER
    });

  const {
    isAvailabilityColumnEnabled,
    sort,
    toggleAvailabilityColumnVisibility,
    onSortChange
  } = useColumnSettings({
    initialValue: hasViewAvailabilityPermission
  });

  const inputClasses = useInputStyles({
    current: ref?.current,
    inputClasses: inputClassesOverride
  });
  const classes = useStyles();

  const handleOnChange = useCallback(
    (users, evt, type) => {
      if (type === 'remove-option' || type === 'clear') {
        setSelectedValue([]);

        return onChange();
      }

      const user = users && users.length ? users[users.length - 1] : null;

      if (disableOnBlur) setSelectedValue([user]);

      return onChange(user);
    },
    [disableOnBlur, onChange]
  );

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <UserWithAvailability
          user={option}
          disablePadding
          isAvailabilityEnabled={isAvailabilityColumnEnabled}
          isProjectRoleEnabled={isProjectRoleEnabled}
        />
      ),
    [isAvailabilityColumnEnabled, isProjectRoleEnabled]
  );

  const onBlur = useCallback(() => {
    !disableOnBlur &&
      selectedValue.length === 0 &&
      value &&
      setSelectedValue([value]);
    onBlurCallback && onBlurCallback();
  }, [disableOnBlur, onBlurCallback, selectedValue.length, value]);

  const renderInput = useCallback(
    ({
      InputLabelProps,
      InputProps,
      disabled,
      fullWidth,
      id,
      inputProps,
      size
    }) => (
      <AutocompleteInput
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        onBlur={onBlur}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        label={
          value || isPsaRmpTaskAllocation1Enabled
            ? intl.formatMessage({ id: 'addResource.resource' })
            : ''
        }
        hiddenLabel={hiddenLabel}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={inputProps}
        size={size}
        variant={variant || 'standard'}
        placeholder={value ? '' : placeholder}
        data-qe-id={dataQeId}
        inputRef={inputRef}
      />
    ),
    [
      onBlur,
      value,
      intl,
      hiddenLabel,
      variant,
      placeholder,
      dataQeId,
      inputRef,
      isPsaRmpTaskAllocation1Enabled
    ]
  );

  const renderTags = useCallback(
    (values, _) =>
      values.map(option => (
        <User
          classes={userClassesOverride}
          user={option}
          disablePadding
          key={option.displayText}
        />
      )),
    [userClassesOverride]
  );

  const {
    isLoading,
    resourceUsers,
    hasMoreRows,
    showAllUsers
  } = useResourceUsers({
    isAvailabilityEnabled: isAvailabilityColumnEnabled,
    userRole,
    isProjectRoleEnabled,
    userSearchText,
    availabilityPercentageInRange: {
      dateRange: {
        startDate: toRepliconDate(dateRange.startDate),
        endDate: toRepliconDate(dateRange.endDate)
      },
      number: 0
    },
    sort,
    skip: !open,
    resourceCostMode
  });

  const groupBy =
    !userRole || !isProjectRoleEnabled
      ? () => intl.formatMessage({ id: 'addResource.resource' })
      : resource =>
          resource.isRoleExists
            ? `${userRole.displayText} ${intl.formatMessage({
                id: 'addResource.resources'
              })}`
            : showAllUsers &&
              intl.formatMessage({ id: 'addResource.otherResources' });

  const handleInputChange = useCallback(
    (_, v) => {
      debounce(
        userScope,
        async () => {
          setUserSearchText(v.trim());
        },
        250
      );
    },
    [setUserSearchText]
  );

  const renderGroup = useCallback(
    params => (
      <UserAvailabilityDropdownItem
        isLoading={isLoading}
        params={params}
        classes={classes}
        isAvailabilityEnabled={isAvailabilityColumnEnabled}
        sort={sort}
        onSortChange={onSortChange}
        hasViewAvailabilityPermission={hasViewAvailabilityPermission}
        toggleAvailabilityColumnVisibility={toggleAvailabilityColumnVisibility}
      />
    ),
    [
      isLoading,
      classes,
      onSortChange,
      isAvailabilityColumnEnabled,
      sort,
      hasViewAvailabilityPermission,
      toggleAvailabilityColumnVisibility
    ]
  );

  const onClose = useCallback(() => {
    if (selectedValue.length === 0 && value) {
      setSelectedValue([value]);
    }
    setOpen(false);
  }, [selectedValue, value]);

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const onOpen = () => setOpen(true);

  const loadingOptions = useMemo(
    () => [
      {
        displayText: '',
        isRoleExists: Boolean(userRole)
      }
    ],
    [userRole]
  );

  return (
    <SimpleAutocomplete
      ref={ref}
      loading={isPsaRmpBugFixes2024 && isLoading}
      options={isLoading ? loadingOptions : resourceUsers}
      size="small"
      fullWidth
      value={selectedValue}
      variant="standard"
      onChange={handleOnChange}
      groupBy={groupBy}
      open={open}
      renderInput={renderInput}
      PopperComponent={getDefaultCustomPopper('auto', 500)}
      renderGroup={renderGroup}
      getOptionSelected={getOptionSelected}
      disableClearable={disableClearable}
      multiple
      renderTags={renderTags}
      classes={inputClasses}
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      optionTypeText={optionTypeText}
      getOptionDisabled={getOptionDisabled}
      hasMore={hasMoreRows}
    />
  );
};

UserAvailabilityDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  dataQeId: PropTypes.string,
  placeholder: PropTypes.string,
  dateRange: PropTypes.object.isRequired,
  userRole: PropTypes.object,
  optionTypeText: PropTypes.string,
  disableClearable: PropTypes.bool,
  disableOnBlur: PropTypes.bool,
  variant: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  inputClasses: PropTypes.string,
  userClassesOverride: PropTypes.object,
  inputRef: PropTypes.object,
  onBlur: PropTypes.func
};

export default UserAvailabilityDropdown;
