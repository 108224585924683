import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const RESOURCE_MATCHES_QUERY = gql`
  query ResourceMatches(
    $input: PRAA_ResourceMatchesInput!
    $isPsaPraaResourcingUIEnabled: Boolean = false
  ) {
    resourceMatches(input: $input) @include(if: $isPsaPraaResourcingUIEnabled) {
      id
      resourceMatches {
        id
        displayText
        score
        role
      }
    }
  }
`;

export const useResourceMatches = ({ page, pageSize, resourceRequestUris }) => {
  const isPsaPraaResourcingUIEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPraaResourcingUIEnabled'
  });

  const { loading, data } = useQuery(RESOURCE_MATCHES_QUERY, {
    variables: {
      input: {
        page,
        pageSize,
        resourceRequestUris
      },
      isPsaPraaResourcingUIEnabled
    },
    fetchPolicy: 'cache-first'
  });

  return {
    loading,
    resourceMatches: data?.resourceMatches || []
  };
};

export default useResourceMatches;
