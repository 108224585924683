import { useCallback } from 'react';
import { PORTFOLIO_BUDGET_OPTIONS } from '~/modules/portfolios/enums';

export const useBudgetFormOnChangeHandlers = ({ setFieldValue }) => {
  const onBudgetAmountChange = useCallback(
    event => {
      setFieldValue(`${event.target.name}.amount`, event.target.value);
    },
    [setFieldValue]
  );

  const onBudgetCurrencyChange = useCallback(
    value => {
      setFieldValue('budgetCurrency', value);
    },
    [setFieldValue]
  );

  const onOptionChange = useCallback(
    event => {
      setFieldValue(
        'isCapexOpexMode',
        event.target.value !== PORTFOLIO_BUDGET_OPTIONS.TOTAL
      );
    },
    [setFieldValue]
  );

  return {
    onBudgetAmountChange,
    onBudgetCurrencyChange,
    onOptionChange
  };
};

export default useBudgetFormOnChangeHandlers;
