import { gql } from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

export const RESOURCE_USER_AVAILABLE_HOURS_FOR_DATE_RANGE_QUERY = gql`
  query GetResourceUserAvailableHoursForDateRange(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
  ) {
    resourceUsers2(page: $page, pagesize: $pagesize, filter: $filter) {
      id
      timeOffDuration
      holidayDuration
      allocatedDuration
      scheduleDuration
    }
  }
`;

const getQueryVariables = ({ resourceUserId, startDate, endDate }) => ({
  page: 1,
  pagesize: 1,
  filter: {
    userIds: [resourceUserId],
    availabilityPercentageInRange: {
      dateRange: {
        startDate,
        endDate
      },
      number: 0
    }
  }
});

const getAvailableDuration = ({ loading, data }) => {
  const resourceUserDurations = data?.resourceUsers2?.[0];

  return loading
    ? 0
    : resourceUserDurations?.scheduleDuration -
        resourceUserDurations?.allocatedDuration -
        resourceUserDurations?.holidayDuration -
        resourceUserDurations?.timeOffDuration;
};

export const useTotalResourceAvailableDurationForDateRange = ({
  resourceUserId,
  startDate,
  endDate,
  skip
}) => {
  const { loading, error, data } = useQuery(
    RESOURCE_USER_AVAILABLE_HOURS_FOR_DATE_RANGE_QUERY,
    {
      variables: getQueryVariables({ resourceUserId, startDate, endDate }),
      skip,
      fetchPolicy: 'network-only'
    }
  );

  return {
    loading,
    availableDuration: getAvailableDuration({ loading, data }),
    error
  };
};

export const useTotalResourceAvailableDurationForDateRangeCallback = () => {
  const apolloClient = useApolloClient();
  const getTotalResourceAvailableHoursForDateRange = useCallback(
    async ({ resourceUserId, startDate, endDate }) => {
      const { data, loading } = await apolloClient.query({
        query: RESOURCE_USER_AVAILABLE_HOURS_FOR_DATE_RANGE_QUERY,
        variables: getQueryVariables({ resourceUserId, startDate, endDate })
      });

      return {
        availableDuration: getAvailableDuration({ loading, data }),
        loading
      };
    },
    [apolloClient]
  );

  return {
    getTotalResourceAvailableHoursForDateRange
  };
};
export default useTotalResourceAvailableDurationForDateRange;
