/* eslint-disable max-lines, no-template-curly-in-string */
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import { ADDRESS_TYPE, CONTACT_TYPE } from '~/modules/common/enums';
import { flattenMessages } from './util';

const messages = {
  yup: {
    mixed: {
      required: '${path} is a required field'
    }
  },
  appTitle: 'Polaris',
  abbreviatedNumberSuffix: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  addButton: {
    add: 'Add'
  },
  addResource: {
    allocateResource: 'Allocate Resources',
    resource: 'Resource',
    otherResources: 'Other Resources',
    selectResource: 'Select Resource',
    availability: 'Availability',
    visibilityOff:
      'Turning ON availability will impact the loading performance',
    visibilityOn: 'Hide',
    addResources: 'Add Resources',
    resources: 'Resources',
    noResources: 'No Resources',
    loading: 'Loading...',
    availabilityOnLoadingMessage:
      'Turning off availability you will get faster result...',
    alertMessage: 'This resource has already been allocated to this project'
  },
  button: {
    showMore: 'Show More...',
    showMoreShort: 'Show More',
    showLess: 'Show Less',
    submit: 'Submit',
    accept: 'Accept',
    add: 'Add',
    doneEditing: 'Done Editing',
    done: 'Done',
    ok: 'Ok',
    edit: 'Edit',
    cancel: 'Cancel',
    close: 'Close',
    clearData: 'Clear Data',
    clearTaskData: 'Clear Task Data',
    editSubmittedData: 'Edit Submitted Data',
    update: 'Update',
    saveReport: 'Save Report',
    addTask: 'Add Task',
    shortLabel: 'Task',
    save: 'Save',
    delete: 'Delete',
    remove: 'Remove',
    viewMyProfile: 'View My Profile',
    resetToDefault: 'Reset to Defaults',
    saveTaskSummary: 'Save Task Summary',
    saveChanges: 'Save Changes',
    back: 'Back',
    closeButton: 'Close Button',
    editRole: 'Edit Role',
    basicInfoEditButton: 'Basic Info Edit',
    budgetInfoEditButton: 'Budget Info Edit',
    subPortfoliosEditbutton: 'Sub-Portfolios Edit',
    additionalInfoEditButton: 'Additional Info Edit',
    clientsInfoEditButton: 'Clients Info Edit',
    groupsInfoEditButton: 'Groups Info Edit',
    billingInfoEditButton: 'Billing Info Edit',
    contactsEditButton: 'Contacts Edit',
    addressEditButton: 'Address Edit',
    clientRepresentativeEditButton: 'Client Representative Edit',
    editButton: '{editButtonLabel} edit',
    selectAll: 'Select All'
  },
  changeAllocationUserAsOfDateDialog: {
    changing: 'Changing',
    resourceChangeConfirmMessage:
      'Are you sure you want to change this resource?',
    changeAsOf: 'Change as of',
    changeForEntireAllocation: 'Change for entire allocation',
    changeResourceOptions: 'Change Resource Options',
    changeResource: 'Change Resource',
    allocationInProgress: 'Allocation In Progress: ',
    allocationInPast: 'Allocation In Past: ',
    changeResourceText: 'Change Resource: ',
    cancel: 'Cancel',
    inProgress: '{inProgressCount} In Progress',
    notStarted: '{notStartedCount} Not Started',
    inProgressAndNotStarted:
      '{inProgressCount} In Progress & {notStartedCount} Not Started',
    entireAllocationTasksPrompt:
      '{currentUser} is the Task Owner of {tasksCount} Tasks',
    entireAllocationConfirmation: 'I would like to {changeTask}.',
    changeTask: 'change the Task Owner from {currentUser} to {futureUser}',
    noTaskEditPermission:
      'Task owner will remain unchanged, as you are not assigned Task edit permissions',
    noTaskViewEditPermission:
      "You don't have permission to view or edit tasks, tasks will not be updated to show your resource plan changes."
  },
  dateField: {
    invalidDateFormat: 'Invalid Date Format'
  },
  dayOfWeek: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  },
  dimensionDropdown: {
    selectDimension: 'Select Dimension',
    byRole: 'By Role',
    byLocation: 'By Location',
    byDivision: 'By Division',
    byServiceCenter: 'By Service Center',
    byCostCenter: 'By Cost Center',
    byDepartment: 'By Department',
    byEmployeeType: 'By Employee Type'
  },
  resourceRequestSkillField: {
    uncategorized: 'Uncategorized',
    skills: 'Skills'
  },
  resourceRequestPreferredResources: {
    preferredResources: 'Preferred Resources',
    preferredResourcesMatched: 'Preferred Resource for this resource request',
    preferredResourcesWithCount: '{count} Preferred Resources',
    showPreferredResource: 'SHOW PREFERRED RESOURCES',
    seeAllResources: 'SEE ALL RESOURCES'
  },
  resourcesAcceptedDialog: {
    title: 'Resources Accepted',
    description:
      'Resources have been successfully accepted and their time has been added to their time already allocated to the project.',
    resourcesHaveBeenAccepted: 'Resources have been successfully accepted.'
  },
  resourceRequestRequestedCostField: {
    requestedCostRate: 'Requested Cost Rate',
    totalRequestedCost: 'Total Requested Cost'
  },
  resourceRequestTagField: {
    tags: 'Tags'
  },
  resourceRequestCostRateField: {
    costRate: 'Cost',
    requestedCostRateAppend: 'Requested Cost Rate: {cost} ',
    totalRequestedCostAppend: 'Total Requested Cost: {cost} '
  },
  resourceRequestToolbar: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
    overview: 'Overview',
    chart: 'CHART',
    grid: 'GRID',
    actuals: 'Actuals',
    by: 'By: {period}',
    whatIsNew: "what's new"
  },
  requestChartRowTotalTooltip: {
    totalHours: 'Requested Hours',
    totalCost: 'Requested Cost',
    totalRequestedHours: 'Total Requested Hours',
    costRate: 'Cost Rate',
    totalRequestedCost: 'Total Requested Cost',
    noRoleMessage: 'Please select a role to see cost estimates.',
    totalRequested: 'Total Requested',
    totalAllocated: 'Total Allocated',
    difference: 'Difference',
    grandTotal: 'Grand Total',
    actualsToDate: 'Actuals to Date'
  },
  calendar: {
    hour: 'hour',
    day: 'Day',
    month: 'Month',
    week: 'Week',
    year: 'Year',
    timeOff: 'Time off',
    actuals: 'Actuals',
    allocation: 'Allocation',
    periodDropdown: 'Period Dropdown',
    today: 'Current Month',
    todayIcon: 'Current Month Button',
    days: 'Days',
    hours: 'Hours'
  },
  clientRepresentative: {
    label: 'Client Representative',
    noclientRepresentative: 'No Client Representative'
  },
  clientCard: {
    title: 'Client',
    details: 'Details'
  },
  coManagers: {
    label: 'Co-Managers'
  },
  costType: {
    label: 'Cost Type'
  },
  earnedRevenue: {
    title: 'Earned Revenue',
    contractType: 'Contract Type',
    none: 'None'
  },
  flagToggle: {
    flagged: 'Flagged',
    notFlagged: 'Not Flagged'
  },
  formCustomField: {
    notSupported: 'Not Supported',
    none: 'none'
  },
  noDates: {
    primary: 'No dates'
  },
  projectLeader: {
    label: 'Project Manager'
  },
  projectAddDialog: {
    title: 'Add Project'
  },
  projectAddDialogActions: {
    addButton: 'Add Project',
    cancelButton: 'Cancel'
  },
  projectAddFab: {
    label: 'Add Project',
    shortLabel: 'Project'
  },
  mobileTaskItemBody: {
    work: 'Work:',
    work2: 'Hours Worked',
    remaining: 'Remaining:',
    estimatedEnd: 'Estimated End:',
    estimatedHours: 'Estimated Hours:',
    estimatedHoursAtCompletion: 'Estimated Hours at Completion',
    estimatedCompletionDate: 'Estimated Completion Date',
    unknown: 'unknown'
  },
  projectCard: {
    details: 'DETAILS'
  },
  projectStatusCard: {
    changeStatus: 'Change Status',
    status: 'Status'
  },
  projectDetails: {
    title: 'Project Details',
    name: 'Name',
    code: 'Code',
    startDate: 'Start Date',
    endDate: 'End Date',
    client: 'Client',
    program: 'Program',
    task: 'Task',
    description: 'Description',
    noValue: 'None',
    more: 'More',
    ok: 'OK'
  },
  portfolio: {
    addPortfolioLabel: 'Add Portfolio',
    addSubPortfolioLabel: 'Add Sub-Portfolio',
    addSubPortfolioTooltip: 'Add Sub-Portfolio',
    addSubPortfolioTitle: 'Add Sub-Portfolio to: {parentName}',
    addPortfolioLabelShort: 'Portfolio',
    portfolioManager: 'Portfolio Manager',
    noPortfolioManager: 'No Portfolio Manager',
    name: 'Name',
    basicInfo: 'Basic Info',
    code: 'Code',
    description: 'Description',
    manager: 'Manager',
    status: 'Status',
    validationMessages: {
      duplicateRootPortfolio: 'There is already a portfolio with this name.',
      duplicateSubPortfolio:
        'There is already a portfolio with this name under {parentName}',
      nameRequired: 'Please enter a name',
      nameExceedsMaxLength: 'Portfolio name cannot exceed 255 characters',
      codeExceedsMaxLength: 'Portfolio code cannot exceed 50 characters'
    },
    tabs: {
      list: 'List',
      summary: 'Summary',
      projects: 'Projects',
      requests: 'Requests'
    },
    maxSubPortfolios: 'No sub-portfolios allowed at this level',
    noMatchingPortfolio: 'No matching portfolios found.',
    clickToAddPortfolio: 'To add a portfolio, press the {value} button below.',
    statusLabel: {
      active: 'Active',
      inActive: 'Inactive'
    },
    subPortfolios: 'Sub-Portfolios',
    subPortfolio: 'Sub-Portfolio',
    showMore: 'Show More...',
    removePortfolio: 'Remove Portfolio',
    deleteDialog: {
      removePortfolio: 'Remove Portfolio',
      deactivatePortfolio: 'Deactivate Portfolio',
      deleteDescription:
        'Are you sure you want to remove this Portfolio?\n\nAny sub-portfolios will be removed as well.',
      deactivateDescription:
        'This portfolio contains projects, which prevents it from being removed. Its status will be changed to inactive instead.\n\nAre you sure you want to deactivate this portfolio?',
      requestsDeactivateDescription:
        'This portfolio contains projects or requests, which prevents it from being removed. Its status will be changed to inactive instead.\n\nAre you sure you want to deactivate this portfolio?'
    },
    budget: {
      title: 'Budget',
      none: 'No Budget Set',
      capital: 'CapEx Budget',
      operational: 'OpEx Budget',
      total: 'Total Budget',
      generalBudgetLabel: 'Set a general budget',
      capexOpexLabel: 'Set separate CapEx and OpEx budgets',
      exceedMaxAllowedValue: 'Amount value can not exceed {max}',
      currencyChangeWarning:
        'Recalculating portfolio metrics with a new currency will take a while.'
    },
    portfolioCurrency: 'Portfolio Currency',
    portfolioBudget: 'Portfolio Budget',
    plannedBudget: 'Total',
    plannedBudgetCapex: 'Portfolio CapEx',
    plannedBudgetOpex: 'Portfolio OpEx',
    budgetColumns: {
      budgetGroup: 'Budget',
      portfolioTotal: 'Portfolio',
      rolledUpProjectBudgets: 'Total Project',
      totalProjectBudget: 'Total Project Budget',
      rolledUpProjectBudgetsPercentage: '% Allocated to Projects'
    },
    hourColumns: {
      hoursGroup: 'Project Hours',
      rolledUpProjectBudgetHours: 'Total Project Budget',
      rolledUpTotalActualHours: 'Actual',
      rolledUpTotalActualHoursPercentage: '% Project Budget Used',
      rolledUpTotalEstimateAtCompletionHours: 'Estimate at Completion',
      rolledUpTotalEstimateAtCompletionHoursPercentage:
        '% Budget Used at Completion'
    },
    costColumns: {
      costGroup: 'Project Cost',
      rolledUpTotalActualCost: 'Actual',
      rolledUpTotalActualCostPercentage: '% Project Budget Used',
      actualCost: 'Actual Cost',
      estimateAtCompletion: 'Estimate at Completion',
      percentageBudgetUsedAtCompletion: '% Budget Used at Completion'
    },
    total: 'Total',
    quickFilterSummary: {
      all: 'All Portfolios',
      my: 'My Portfolios',
      inactive: 'Inactive'
    },
    projectBudgetStatusChart: {
      chartTitle: 'Budget Allocation',
      title: 'Portfolio Budget',
      labelTitle: {
        initiate:
          '{count, plural, =1 {1 Project in Initiate} other {# Projects in Initiate}}',
        planning:
          '{count, plural, =1 {1 Project in Planning} other {# Projects in Planning}}',
        execution:
          '{count, plural, =1 {1 Project in Execution} other {# Projects in Execution}}',
        closeout:
          '{count, plural, =1 {1 Project in Closeout} other {# Projects in Closeout}}',
        archived:
          '{count, plural, =1 {1 Project in Archived} other {# Projects in Archived}}',
        remaining: 'Remaining Budget',
        overallocated: 'Overallocated Budget'
      },
      customStatusLabelTitle: {
        initiate:
          '{count, plural, =1 {1 Project in} other {# Projects in}} {status}',
        planning:
          '{count, plural, =1 {1 Project in} other {# Projects in}} {status}',
        execution:
          '{count, plural, =1 {1 Project in} other {# Projects in}} {status}',
        closeout:
          '{count, plural, =1 {1 Project in} other {# Projects in}} {status}'
      },
      na: 'NA'
    },
    budgetProgressCard: {
      chartTitle: 'Budget Progress',
      cost: 'Cost',
      hours: 'Hours',
      hoursUnit: 'h',
      noProjects: 'No Projects to Display',
      addProjects: 'Please add projects to your portfolio',
      totalProjectBudget: 'Total Project Budget',
      actualCost: 'Actual Cost',
      estimatedAtCompletion: 'Estimated at Completion'
    },
    budgetBurnedCard: {
      actualCost: 'Actual Cost',
      actualHours: 'Actual Hours',
      actualToDate: 'Actual to Date',
      budgetCostMissing: 'Budget Cost not set',
      budgetCostMissingAlert:
        'Set the Budget Cost on this portfolio’s projects to track budget burned',
      budgetHoursMissing: 'Budget Hours not set',
      budgetHoursMissingAlert:
        'Set the Budget Hours on this portfolio’s projects to track budget burned.',
      chartTitle: 'Budget Burned',
      cost: 'Cost',
      eacMissing: 'EAC missing',
      eacMissingAlert:
        'Check the projects in this portfolio for more information.',
      estimatedAtCompletion: 'Estimated at Completion',
      hours: 'Hours',
      totalProjectBudget: 'Total Project Budget'
    },
    projectsOverBudgetProgressCard: {
      chartTitle: 'Projects Over Budget',
      overCostLabel: 'Over Cost Budget',
      overHoursLabel: 'Over Hours Budget',
      pastEndDateLabel: 'Past End Date',
      overCostBudget:
        '{displayText} {count, plural, one {Project} other {Projects}} Over Cost Budget',
      underCostBudget:
        '{displayText} {count, plural, one {Project} other {Projects}} Under/On Cost Budget',
      overHoursBudget:
        '{displayText} {count, plural, one {Project} other {Projects}} Over Hours Budget',
      underHoursBudget:
        '{displayText} {count, plural, one {Project} other {Projects}} Under/On Hours Budget',
      pastEndDate:
        '{displayText} {count, plural, one {Project} other {Projects}} Past End Date',
      underEndDate:
        '{displayText} {count, plural, one {Project} other {Projects}} Early/On Time'
    },
    projectRequestStatuses: 'Request Statuses',
    projectRequestStatusChart: {
      chartTitle: 'Request Costs by Status',
      title: 'Requests',
      labelTitle: {
        new: '{count} New',
        inReview: '{count} Under Review',
        approved: '{count} Approved',
        rejected: '{count} Rejected'
      }
    },
    message:
      'This portfolio cannot be changed to Active because a parent portfolio is Inactive. A sub-portfolio cannot be Active with an Inactive parent.'
  },
  projectRequest: {
    moreActions: 'More actions',
    label: 'Add Request',
    save: 'Save',
    shortLabel: 'Request',
    name: 'Name',
    description: 'Description',
    requestedBy: 'Requested By',
    estimatedCost: 'Estimated Cost',
    noEstimatedCost:
      'The cost will be automatically calculated once you add resources.',
    noEstimatedCostWithEditPermission:
      'The cost will be automatically calculated once you add resources, or click the icon to enter a cost',
    useCalculatedEstimatedCost: 'Use automatically calculated value',
    helpIconText:
      'The Estimated Cost is automatically calculated based on the cost rate of the required resources and the duration of the work. Use the automatically calculated value or click on the icon next to it to enter your own.',
    helpIconTextReadOnly:
      'The Estimated Cost is automatically calculated based on the cost rate of the required resources and the duration of the work.',

    suggestedCost: 'Suggested Cost: {estimatedCost}',
    createdBy: 'Created By',
    status: 'Status',
    validations: {
      nameRequired: 'Please enter a name',
      descriptionRequired: 'Please enter a description',
      nameExceedsMaxLength: 'Request Name cannot exceed 255 characters',
      requestedByExceedsMaxLength:
        'Requested By name cannot exceed 255 characters',
      gateDisplayTextRequired: 'Please enter a label',
      portfolioIsRequired: 'Please select a portfolio',
      resourceAssignment: {
        quantityExceedsMax: 'Quantity cannot be more than {max}',
        roleIsRequired: 'Please select a resource'
      }
    },
    tabs: {
      list: 'List'
    },
    summaryTabs: {
      allRequests: 'All Requests',
      thisPortfolio: 'This Portfolio',
      subPortfolios: '+ Sub-Portfolios'
    },
    noMatchingProjectRequest: 'No matching requests found.',
    clickToAddProjectRequest: 'To add a request, press the{value}button below.',
    createProject: 'Create Project',
    createProjectHeading: 'Create Project from Request',
    addRequest: 'Add Request',
    edit: 'Edit',
    close: 'close',
    removeRequest: 'Remove Request',
    requestHistory: 'Request History',
    requestHistoryTitle: 'Request History: {projectRequestName}',
    requestHistoryError:
      'We were not able to retrieve the audit history at this time. Please try again later.',
    deleteDialog: {
      removeRequest: 'Remove Request',
      description: 'Are you sure you want to remove this request?'
    },
    documents: 'Documents',
    documentsCount: {
      singular: '{count} document',
      plural: '{count} documents'
    },
    reviewChecklist: 'Review Checklist',
    score: 'Score',
    schedule: 'Schedule',
    planning: 'Planning',
    starting: 'Starting',
    period: 'Period',
    for: 'for',
    periodResolution: {
      WEEK: '{count, plural, =1 {Week} other {Weeks}}',
      MONTH: '{count, plural, =1 {Month} other {Months}}',
      QUARTER: '{count, plural, =1 {Quarter} other {Quarters}}',
      YEAR: '{count, plural, =1 {Year} other {Years}}'
    },
    selectStartDate: 'Select a start date',
    value: 'Value',
    investment: 'Investment',
    risk: 'Risk',
    valueHelp: 'The amount of value the work would deliver.',
    investmentHelp: 'The investment required to complete the work.',
    riskHelp:
      "The risk that the expected value won't fully materialize, or it will require a larger investment than expected.",
    totalHelp:
      'A rough estimate of the ROI the work would provide, adjusted for risk. Helpful for comparing and prioritizing requests.',
    totalScore: 'Total Score',
    scoreHelpMessage:
      'Use the value {link} from the above ratings, or click the icon next to the score to enter your own.',
    scoreHelpLinkMessage: 'automatically calculated',
    useThis: 'Use This',
    suggestedScore: 'Suggested Score: {total}',
    scoreDetails: {
      total: 'Total Score',
      value: 'Value',
      investment: 'Investment',
      risk: 'Risk'
    },
    planningDetails: {
      estimatedStartDate: 'Estimated Start Date',
      estimatedDuration: 'Estimated Duration',
      resourcesRequired: 'Resources Required',
      estimatedCost: 'Estimated Cost'
    },
    periodDuration: {
      WEEK: '{count, plural, =1 { week} other { weeks}}',
      MONTH: '{count, plural, =1 { month} other { months}}',
      QUARTER: '{count, plural, =1 { quarter} other { quarters}}',
      YEAR: '{count, plural, =1 { year} other { years}}'
    },
    approvalWarning: {
      title: 'Approve Request',
      message:
        'The Review Checklist on this request has not been completed. Are you sure you want to approve it?'
    },
    checklistItem: 'Checklist Item',
    noChecklistItemsWithEditPermission:
      'Click the icon on the right to add checklist items.',
    noChecklistItems: 'There are no checklist items on this request.',
    defaultCheckListItemsToolTip:
      'This checklist item is required and cannot be removed.',
    portfolio: 'Portfolio',
    existingRequests: 'Existing Requests',
    addNewProjectRequest: 'Add New Request',
    addExistingProjectRequestDialog: {
      projectRequest: 'Request',
      portfolio: 'Portfolio',
      addXProjectRequests: 'Add {value} Requests',
      submitError: 'An unexpected error has occurred. Please try again later.',
      projectRequestHasPortfolio: '1 request already has a portfolio',
      xProjectRequestHasPortfolio:
        ' {value} request(s) already have a portfolio',
      moveToPortfolioConfirmation:
        'Are you sure you want to move these requests to {portfolio}?',
      moveProjectRequests: 'Move Requests',
      tooManyRequestsSelected: 'Too many requests selected',
      tooManyRequestsSelectedMessage:
        'You can only move {number} requests at a time. Please deselect some requests and try again.'
    },
    resourceRequirement: {
      title: 'Resource Requirements',
      noResources: 'No resources added yet',
      addResource: 'Resource',
      selectAResource: 'Select a resource',
      resourceCount: 'Resource Count',
      roleDropdown: 'Role Dropdown',
      delete: 'Delete',
      reachedMaxAssignmentLimit: 'Only 50 rows can be added on each request',
      noViewRolePermission:
        'To view or add resource requirements, ask your administrator to enable the Project Roles permission in your Portfolio Management permission set.'
    },
    resourcesCount: '{count, plural, =1 {# resource} other {# resources}}',
    costDescription: '{currency} {cost}',
    unsavedChangesPrompt: {
      title: 'Unsaved Changes',
      unsavedResourceRequirementWarning:
        'There are unsaved changes to the Resource Requirements. Please save them before closing the request.',
      unsavedChecklistItemsWarning:
        'There are unsaved changes to the Review Checklist. Please save them before closing the request.',
      unsavedRequirementAndChecklistWarning:
        'There are unsaved changes to the Resource Requirements and Review Checklist. Please save them before closing the request.'
    }
  },
  projectRequestHistory: {
    newest: 'Newest First ',
    oldest: 'Oldest First ',
    edited: '{displayText} edited the request',
    added: '{displayText} added the request',
    substituteUserEdited:
      '{actualUser} (on behalf of {effectiveUser}) edited the request',
    substituteUserAdded:
      '{actualUser} (on behalf of {effectiveUser}) added the request',
    fieldTitles: {
      name: 'Changed Name',
      description: 'Changed Description',
      requestedBy: 'Changed Requested By',
      status: 'Changed Status',
      portfolio: 'Changed Portfolio'
    }
  },
  projectRequestStatus: {
    statusLabel: {
      new: 'New',
      inReview: 'Under Review',
      approved: 'Approved',
      rejected: 'Rejected',
      inReviewWithProgress: 'Under Review {checkedGatesCount}/{totalGatesCount}'
    },
    drawerButtons: {
      reviewRequest: 'Review Request',
      approveRequest: 'Approve Request',
      rejectRequest: 'Reject Request'
    }
  },
  program: {
    noProgram: 'No Program',
    removeProgram: 'Remove Program',
    noProgramManager: 'No Program Manager'
  },
  projectRevenue: {
    empty: 'No revenue contract has been set for this project'
  },
  projectBilling: {
    empty: 'Project is non-billable.',
    timeAndMaterialsTitle: 'Rates',
    timeAndMaterialsName: 'Name',
    timeAndMaterialsRate: 'Rate'
  },
  projectAddForm: {
    name: 'Name',
    startDate: 'Planned Start Date',
    endDate: 'Planned End Date',
    budgetHours: 'Budget Hours',
    budgetCost: 'Budget Cost',
    totalContractValue: 'Total Contract Value',
    contractType: 'Contract Type'
  },
  projectTasksPage: {
    name: 'Name',
    code: 'Code',
    description: 'Description',
    task: 'Task',
    selectATaskOwner: 'Select a Task Owner',
    taskOwner: 'Task Owner',
    none: 'None',
    noTaskOwner: 'No Task Owner',
    taskOwnerFor: 'Task Owner for: {task}',
    assignedTo: 'Assigned To',
    assignedRole: 'Role',
    taskRole: 'Task Role',
    assignTo: '+ owner',
    assignRole: '+ Role',
    notAssigned: '&#8212;',
    unassigned: 'Unassigned',
    na: 'NA',
    estimateMissing: 'Estimate Missing',
    estimatedHours: 'Estimated Hours',
    estimatedCost: 'Estimated Cost',
    taskExists: 'A task with this name already exists.',
    hours: 'Hours',
    cost: 'Cost',
    milestone: 'Milestone',
    estimatedCompletionDate: 'Estimated Completion Date',
    moveTaskMaxLevelError: 'Projects cannot have more than 10 levels of tasks.',
    addTask: 'Add Task to: ',
    addSubTask: 'Add Subtask to: ',
    estimatedHoursAtCompletion: 'Estimated Hours at Completion',
    actualHours: 'Actual Hours',
    progress: 'Progress',
    progressValue: '{actual} / {estimate} hours',
    progressValueWithoutEstimate: '{actual} hours',
    noMatchingTasksFound: 'No matching tasks found.',
    clickToAddTask: 'To add a task, press the {value} button below.',
    taskAdded: 'Task {taskName} added',
    role: 'Role',
    timeAndExpenseEntryAllowed: 'Time & Expense Entry Allowed',
    timeEntryAllowed: 'Time Entry Allowed',
    isTimeEntryAllowed: 'Time Entry Allowed',
    noOwner: 'No Owner',
    noRole: 'No Role',
    footerMessage: 'Showing the first {count} tasks. Search to refine results.',
    otherProjectResources: 'Other Project Resources',
    noResourcesAllocated:
      'No resources have been allocated to this project for {role}',
    taskName: 'Task Name',
    taskStatus: 'Task Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    workRemaining: 'Work Remaining',
    estimatedAtCompletion: 'Estimated at Completion',
    originalEstimate: 'Original Estimate',
    variation: 'Variation',
    tasksStatus: {
      INPROGRESS: 'In Progress',
      NOTSTARTED: 'Not Started',
      COMPLETED: 'Completed'
    },
    hrs: ' hrs',
    h: ' h',
    aboveHours: 'Above Hours',
    exceededHours: 'Exceeded Hours',
    belowHours: 'Below Hours',
    hoursMatched: 'Hours Matched',
    estimatedDateOfCompletion: 'Estimated Date of Completion',
    projectName: 'Project Name',
    projectStatus: 'Project Status',
    projectBudget: 'Project Budget',
    estimatedAtTaskCompletion: 'Estimated at Task Completion',
    endDateMissing: 'End date missing',
    startDateMissing: 'Start date missing',
    datesAreMissing: 'Dates are missing',
    actualHoursEstimatedAtCompletion: 'Actual Hours / Estimated At Completion',
    actualHoursEstimatedAtTaskCompletion:
      'Actual Hours / Estimated At Task Completion',
    budgetMissing: 'Budget Missing',
    allResourcesCount:
      ' {resourceEstimatesCount, plural, =0 {} one {# Resource} other {# Resources}}',
    partialResourcesCount:
      '{resourceEstimatesWithUsersCount} / {resourceEstimatesCount, plural, =0 {} one {# Resource} other {# Resources}}',
    plannedStartDate: 'Planned Start Date',
    plannedEndDate: 'Planned End Date'
  },
  moveTaskErrorDialog: {
    title: 'Cannot Move Task'
  },
  qualityIcon: {
    great: 'Great',
    good: 'Good',
    neutral: 'Neutral',
    bad: 'Bad',
    horrible: 'Horrible'
  },
  projectListItem: {
    resourceRequest: 'Resource Request',
    noResourceRequests: 'No resource requests.'
  },
  resourceAllocationStatus: {
    pending: 'Pending',
    proposed: 'Proposed',
    committed: 'Committed',
    rejected: 'Rejected'
  },
  resourceRequestChart: {
    projectStart: 'Project Start',
    projectEnd: 'Project End'
  },
  resourceRequestChartHeader: {
    role: 'Role',
    resource: 'Resource',
    requested: 'Requested',
    allocated: 'Allocated',
    totalHours: 'Total Hours',
    totalCost: 'Total Cost',
    total: 'Total',
    resourceRequests: 'Resource Requests',
    resources: 'Resources'
  },
  resourceRequestChartFooter: {
    totalRequested: 'Total Requested',
    totalAllocated: 'Total Allocated',
    grandTotal: 'Grand Total'
  },
  resourceRequestChartBanner: {
    submitRequests: "We've created this request for you. Would you like to?",
    delaIdentifyTeam: 'Dela has identify your team. Would you like to?',
    delaProposeQuestion: 'Dela has created your team. Would you like to?',
    submitAll: 'submit all',
    proposeAll: 'propose all',
    acceptAll: 'accept all'
  },
  resourceRequestsNotification: {
    createdResourceRequests:
      "We've created some resource requests for you. Click here to view it",
    viewResourceRequests: 'view resource requests'
  },
  resourceRequestBlockLabel: {
    hours: 'hours',
    percentage: 'percentage'
  },
  resourceRequestButtons: {
    allocateResource: 'Allocate Resource',
    requestResources: 'Request Resources'
  },
  resourceAllocationChangeSnackBar: {
    mergedAllocationAndRole: 'Merged the allocations for {username}',
    updatedAllocationAndRole:
      'Updated the allocation for {username} and {role}',
    directAllocation: '{username} has been allocated to {role}',
    acceptResource: 'Accepted resource {username}',
    acceptMultipleResources: 'Accepted {count} resources',
    noRole: 'No Role',
    resourceChanged: 'Resource changed to {username}',
    resourceChangedAsOf: 'Resource changed to {username} as of {date}',
    releaseResource: 'Released {username} from {role}',
    releaseResourceAsOf: 'Released {username} from {role} as of {date}',
    releaseResourceMultipleRoles: 'Released {username} from {count} roles',
    releaseResourceMultipleRolesAsOf:
      'Released {username} from {count} roles as of {date}'
  },
  releaseResourceAllocationSnackBar: {
    noRole: 'No Role',
    releaseResource: 'Released {username} from {projectname} for {role}',
    releaseResourceAsOf:
      'Released {username} from {projectname} for {role} as of {date}',
    releaseResourceMultipleRoles:
      'Released {username} from {projectname} for {count} roles',
    releaseResourceMultipleRolesAsOf:
      'Released {username} from {projectname} for {count} roles as of {date}'
  },
  addallocationSnackBar: {
    addallocation: '{username} has been allocated to {projectname} for {role}',
    noRole: 'No Role'
  },
  allocateResourcesContent: {
    startDate: 'Start Date',
    endDate: 'End Date',
    loading: 'Loading',
    totalAllocatedHours: 'Total Allocated Hours',
    minValueForAmount: 'Amount must be greater than {value}',
    allocate: 'Allocate',
    cancel: 'Cancel'
  },
  rolesDropdown: {
    noRole: 'No Role',
    costRate: 'Cost Rate',
    selectRole: 'Select a Role',
    plusSelectRole: '+ Select Role'
  },
  tasksDropdown: {
    selectTask: 'Select a Task'
  },
  resourceAllocationItem: {
    allocateResource: '+ Allocate Resource',
    noAllocateResource: 'No Resource Allocated',
    noAllocateResources: 'No Resources Allocated',
    noMatchFound: 'No Match found',
    toBeHired: 'To Be Hired',
    findAlternative: 'Find Alternative',
    proposeResource: 'Click to Propose Resource',
    acceptResource: 'Click to Accept Resource',
    resourceRequested: '{quantity} Requested'
  },
  resourceMatchPopover: {
    delaTopSuggestions: "Dela's Top 3 Suggestions",
    noRole: 'No Role',
    findAlternative: 'Find Alternative',
    resources: 'Resources',
    match: 'Match',
    proposeResource: 'Propose Resource',
    propose: 'Propose',
    score: '{score}%'
  },
  resourceUserPeriodSummaryContent: {
    scheduledHours: 'Scheduled Hours',
    hours: 'Hours',
    total: 'Total'
  },
  userDropdown: {
    user: 'User',
    selectAResource: 'Select a resource',
    removeResourceAssignment: 'Remove Resource Assignment',
    noUser: 'No User',
    noResource: 'No Resource'
  },
  userAvatar: {
    altLabel: 'Avatar of {user}'
  },
  resourceAllocationEditableUserItem: {
    removeResourceAllocation: 'Remove Resource Allocation',
    addResourceAllocation: 'Add Resource Allocation',
    removeResourceAllocationContent:
      'Are you sure you want to remove this resource?',
    removeResourceAllocatinAction: 'REMOVE RESOURCE',
    editResourceAllocation: 'Edit Resource Allocation'
  },
  resourceAssignmentDialog: {
    paginationLabel: '{from}-{to} of many',
    paginationLabelPastLimit: '{from}+ of many',
    paginationLabelWithCount: '{from}-{to} of {count}',
    refreshData: 'Refresh Data',
    updateSmartMatch: 'Update SmartMatch',
    retry: 'Retry',
    selectedAllocationsBar: {
      of: 'of',
      requested: 'Requested',
      allocated: 'Allocated',
      resourceSelected: 'Resource Selected',
      total: 'Total'
    },
    smartMatchFailed:
      'Something went wrong calculating SmartMatch scores for this request. Please {supportLink} for assistance.',
    supportLinkText: 'contact Replicon Support',
    scoreCalculatingMessage:
      'SmartMatch Scores are being calculated for this Resource Request…',
    costOptimizationCalculatingMessage: 'Calculating SmartMatch Scores...',
    scoreCalculatedMessage:
      'SmartMatch Scores have been calculated. Refresh data to see the results.',
    refreshScoreCalculatedMessage:
      'To show revised resource recommendations for this request.',
    costOptimizationFailed:
      'Something went wrong calculating Cost Optimized scores for this request. Please {supportLink} for assistance.',
    additionalActions: 'Additional Actions',
    availabilityFrom: 'Availability {availability}% from',
    noRole: 'No Role',
    showMoreDetails: 'Show Request Details',
    counter: '{count} of {total} @ {load} %',
    noMatchingUsers: 'No matching users found.',
    resource: 'Resource',
    role: 'Role',
    loading: 'Loading',
    availability: 'Availability',
    smartMatchExcludingCost: {
      primary: 'SmartMatch (Without cost)',
      subheader: '(Without cost)'
    },
    smartMatchIncludingCost: {
      primary: 'SmartMatch',
      subheader: ' '
    },
    smartMatch: {
      primary: 'SmartMatch',
      subheader: ' '
    },
    costRate: 'Cost Rate',
    match: 'SmartMatch',
    acceptResource: 'Accept Resources',
    error: 'An unexpected error has occurred. Please try again later.',
    toBeHired: 'To Be Hired',
    cancelToBeHired: 'Cancel To Be Hired',
    unSubmitRequest: 'Unsubmit Request',
    rejectRequest: 'Reject Request',
    recallRequest: 'Recall Request',
    removeRequest: 'Remove Request',
    submitRequest: 'Submit Request',
    cancelRejection: 'Cancel Rejection',
    proposeResources: 'Propose Resources',
    pending: 'Pending',
    propose: 'Propose',
    rejected: 'Rejected',
    completed: 'Accept',
    resourceRejected: 'Reject',
    recall: 'Recall',
    requestMarkedToBeHired: 'Request marked as to be hired',
    requestMarkedRejected: 'Request marked as rejected',
    allocateResource: 'Allocate Resource',
    showUserDetails: 'Show User Details',
    showMore: 'More',
    showLess: 'Less',
    off: 'Off',
    availabilitySlider: {
      availabilityFrom: 'Availability {value} From'
    },
    paginationLimitMessage: {
      title: 'Sorry, we cannot serve more than {recordLimit} results',
      message: 'Try using search to refine your results.',
      apology: 'We apologize for any inconvenience.',
      previousPage: 'Previous page'
    },
    smartMatchSlider: {
      smartMatchOff: 'SmartMatch: Off',
      smartMatch: 'SmartMatch: {value}'
    },
    smartMatchLoadMoreBanner: {
      lowScores: 'Resources with low SmartMatch scores were not included.',
      moreInfo: 'More Information',
      seeAll: 'To see all Resources',
      switch: 'Switch to SmartMatch Without Cost'
    },
    completeResourceRequest: 'Complete Resource Request',
    completeResourceRequestDescription:
      'The resources for this request have been accepted and are now part of the project team.',
    proposedResourceRequest: 'Proposed Resources',
    proposedResourceRequestDescription:
      'The following resources have been proposed for this request.',
    rejectedResourceRequest: 'Resource Request Rejected',
    rejectedResourceRequestDescription:
      'This resource request has been rejected. Resubmit it to assign resources.',
    rejectedResource: 'Resources Rejected',
    rejectedResourceDescription:
      'The resources proposed for this request were rejected.',
    draftResourceRequest: 'Draft Resource Request',
    draftResourceRequestDescription:
      'Submit this resource request to begin assigning resources',
    toBeHiredResourceRequest: 'To Be Hired Resource Request',
    toBeHiredResourceRequestDescription:
      'This resource request has been marked as To Be Hired. Return it to Pending when you are ready to allocate resources.',
    noUserAllocated: 'No User Allocated',
    reject: 'Reject',
    allocateResources: 'Allocate Resources',
    completeAllocation: 'Complete Allocation',
    editResources: 'Edit Resources',
    acceptResources: 'Accept',
    releaseResources: 'Release Resources',
    hours: 'Hours',
    cost: 'Cost',
    includeCostInSmartMatch:
      'Include Requested Cost in SmartMatch calculations',
    notEnoughAvailableHours: 'Not Enough Available Hours',
    cancel: 'Cancel',
    createAllocation: 'Create Allocation',
    whatWouldYouLikeToDo: 'What would you like to do?',
    allocateFullAmount: 'Allocate Full Amount',
    allocateWhatFits: 'Allocate What Fits',
    extendTheAllocation: 'Extend the Allocation',
    redistributeAllocation: 'Redistribute Allocation',
    allocateAllValueLeavingSomeWithScale:
      'Allocate all {hours} hours, leaving some {scale} overallocated',
    allocateWhatFitsValueWithoutOverAllocationScale:
      'Allocate {hours} hours, which fit in the {scale} without overallocation',
    allocateExtendWithoutOverAllocationScale:
      'Allocate all {hours} hours, without overallocation by extending the allocation to {endDate}',
    redistributeAllocationSecondary:
      'Allocate all {hours} hours by redistributing hours between {scale}',
    allocated: 'Allocated',
    requested: 'Requested',
    actual: 'Actual',
    variation: 'Variation',
    difference: 'Difference',
    hoursSuffix: 'h',
    displayText: 'Resource',
    currentLocation: 'Location',
    currentDivision: 'Division',
    currentDepartment: 'Department',
    currentCostCenter: 'Cost Center',
    currentServiceCenter: 'Service Center',
    currentEmployeeType: 'Employee Type',
    resourcePools: 'Resource Pools',
    supervisorAssignment: 'Supervisor',
    score: 'SmartMatch',
    startDate: 'Start Date',
    endDate: 'End Date',
    roles: 'Role',
    employeeId: 'Employee ID',
    tags: 'Tags',
    pleaseSelectRequest:
      'Please select a Resource Request from {otherRequests} or return to the {path}',
    pleaseSelectProjectResourceRequest:
      'Please select a Resource Request from {otherRequests} or return to the project {path}',
    otherRequests: 'Other Requests',
    resourceRequests: 'Resource Requests'
  },
  locationsDropdown: {
    location: 'Location',
    noLocation: 'No Location'
  },
  portfoliosDropdown: {
    portfolio: 'Portfolio',
    noPortfolio: 'No Portfolio',
    loading: 'Loading',
    portfolios: 'Portfolios'
  },
  resourcePoolsDropdown: {
    resourcePools: 'Resource Pool(s)',
    noResourcePools: 'No Resource Pools'
  },
  settingsActionLink: {
    settings: 'Settings'
  },
  routes: {
    admin: 'Admin',
    myWork: 'My Work',
    userProfile: 'User Profile',
    projects: 'Projects',
    project: 'Project',
    resourceAllocation: 'Resource Allocation',
    projectResource: 'Project Resource',
    pageNotFound: 'Page Not Found',
    unhandledError: 'Unhandled Error',
    smartBeats: 'SmartBeats',
    tasks: 'Tasks',
    resourcing: 'Resourcing',
    integrations: 'Integrations',
    resourcePool: 'Resource Pool',
    clients: 'Clients',
    portfolios: 'Portfolios',
    programs: 'Programs',
    billing: 'Billing',
    notifications: 'Notifications',
    notAuthorized: 'Not Authorized',
    projectResourcing: 'Project Resourcing',
    resourceManagement: 'Resource Management',
    smartBudget: 'SmartBudget',
    settings: 'Settings',
    offline: 'Offline',
    businessIntelligence: 'Business Intelligence',
    financials: 'Financials',
    projectRequests: 'Requests',
    myTeam: 'My Team',
    payroll: 'Payroll',
    quickAllocation: 'Quick Allocation',
    zeroTime: 'ZeroTime',
    vendors: 'Vendors'
  },
  widgets: {
    'add-a-widget': 'Add a Widget'
  },
  notSupportedPage: {
    alt: 'Not Supported',
    description: 'Sorry for the inconvenience.',
    title: 'Unfortunately this view does not support small screens'
  },
  notFoundPage: {
    description:
      'The link you followed is probably broken, or the page has been removed.',
    pageNotFound: '404 Page Not Found',
    title: 'Sorry, page not found.'
  },
  unhandledErrorPage: {
    description: 'To report the issue, contact {email}',
    eventId: 'Error trace ID: {eventId}',
    title: 'Unknown Error'
  },
  commonError: {
    backtoHome: 'Back to Home',
    formErrorAlertMessage:
      'An unexpected error has occurred. We apologize for the inconvenience. If this problem persists, please contact {support}.',
    support: 'support'
  },
  unauthorizedPage: {
    description:
      'It appears you are not logged in. You will be redirected to login in a moment.',
    title: 'Unauthorized'
  },
  projectBasicInfoCard: {
    basicInfo: 'Basic Info',
    title: 'Basic Details',
    name: 'Name',
    code: 'Code',
    dates: 'Dates',
    startDate: 'Planned Start Date',
    endDate: 'Planned End Date',
    description: 'Description',
    client: 'Client',
    coManagers: 'Co-Managers',
    program: 'Program',
    portfolio: 'Portfolio',
    project: 'Project',
    groups: 'Groups',
    contactInfo: {
      contacts: 'Contacts',
      noContacts: 'No Contacts',
      fields: {
        name: 'Name',
        email: 'Email',
        phoneNumber: 'Phone',
        faxNumber: 'Fax',
        website: 'Website',
        contactType: 'Contact Type'
      },
      validation: {
        fieldExceededMaxLength:
          'Cannot exceed maximum length of {max} characters.',
        invalidEmail: 'Please enter a valid e-mail address.',
        invalidUrl: 'The URL entered is not valid.'
      },
      actions: {
        addContact: '+ Add Contact',
        viewDetails: 'View Details',
        deleteConfirmationDialog: {
          removeContact: 'Remove Contact',
          description: 'Are you sure you want to remove this contact?'
        }
      },
      contactTypeOptions: {
        [CONTACT_TYPE.PRIMARY]: 'Primary',
        [CONTACT_TYPE.BILLING]: 'Billing',
        [CONTACT_TYPE.OTHERS]: 'Others'
      }
    },
    addressInfo: {
      addresses: 'Addresses',
      noAddresses: 'No Addresses',
      fields: {
        address: 'Address',
        city: 'City',
        stateProvince: 'State / Province',
        zipPostalCode: 'Zip / Postal Code',
        country: 'Country',
        addressType: 'Address Type'
      },
      validation: {
        fieldExceededMaxLength:
          'Cannot exceed maximum length of {max} characters.'
      },
      actions: {
        addAddress: '+ Add Address',
        viewDetails: 'View Details',
        deleteConfirmationDialog: {
          removeAddress: 'Remove Address',
          description: 'Are you sure you want to remove this address?'
        }
      },
      addressTypeOptions: {
        [ADDRESS_TYPE.PRIMARY]: 'Primary',
        [ADDRESS_TYPE.BILLING]: 'Billing',
        [ADDRESS_TYPE.OTHERS]: 'Others'
      }
    },
    additionalInfo: 'Additional Info',
    additionalInformation: 'Additional Information',
    allowEntryWithoutTasks: 'Allow Entry without Tasks',
    unknownError:
      'There was a problem saving your project, please try again later.',
    estimated: 'Estimated',
    hours: 'Hours',
    slackChannel: 'Slack Channel',
    projectManager: 'Project Manager',
    projectType: 'Project Type',
    clients: 'Clients',
    clientRepresentative: 'Client Representative',
    noGroups: 'No Groups'
  },
  billingRateCard: {
    manageTeamRates: 'Manage Team Rates',
    title: {
      main: 'Billing Rates',
      projectRate: 'Project Rates',
      userRate: 'User Rates',
      billingRate: 'Billing Rates'
    },
    subTitle: {
      singular: '{count} Rate',
      plural: '{count} Rates'
    },
    showMore: 'Show More',
    columns: {
      name: 'Name',
      effectiveDate: 'Effective Date',
      rate: 'Hourly Rate'
    },
    table: {
      initialRate: 'Initial Rate'
    },
    noItem: 'No {rateTitle} defined for this project yet.',
    addRate: '+ Add Rate',
    rateWithvalue: '{displayText} - Hourly: {rateValue}',
    noBillingRate: 'No Billing Rate',
    validation: {
      billingRateRequired: 'Please select a name for the rate.',
      effectiveDateRequired: 'Please select an effective date.',
      duplicateEffectiveDate: 'Must have unique effective date for a rate.',
      duplicateBillingRate: 'A rate with specified name is already defined.'
    },
    addRateIcon: 'Add Rate',
    removeRateIcon: 'Remove Rate',
    addRateButton: 'Add Rate Button',
    removeRateButton: 'Remove Rate Button',
    removeInfo:
      'This billing rate is assigned to {users}. If you delete this billing rate, it will be un-assigned to all users.\n\nAre you sure you want to remove?',
    cancel: 'Cancel',
    remove: 'Remove',
    billingRateCardDialog: 'Billing Rate Card'
  },
  rateCard: {
    showMore: 'Show More...',
    cardTitle: 'Rate Card',
    deleteAllConfirmationDialog: {
      header: {
        projectRate: 'Remove Project Rate',
        roleRates: 'Remove Role Rate',
        userRates: 'Remove Resource Rate'
      },
      removeRate: 'Remove Rate',
      message: {
        projectRate: 'Are you sure you want to remove all Project Rates ?',
        roleRates: 'Are you sure you want to remove all Role Rates ?',
        userRates: 'Are you sure you want to remove all Resource Rates ?'
      }
    },
    title: {
      projectRate: 'Project Rate',
      roleRates: 'Role Rate',
      userRates: 'Resource Rate'
    },
    subTitle: {
      roleRates: '{count} Roles',
      userRates: '{count} Resources'
    },
    rateType: {
      title: 'Rate Type',
      projectRate: 'Rate Type: Project',
      roleRates: 'Rate Type: Role',
      userRates: 'Rate Type: Resource'
    },
    noData: {
      client: {
        projectRate: 'No Project rate defined for this client yet',
        roleRates: 'No Role rate defined for this client yet',
        userRates: 'No Resource rate defined for this client yet'
      },
      project: {
        projectRate: 'No Project rate defined for this project yet',
        roleRates: 'No Role rate defined for this project yet',
        userRates: 'No Resource rate defined for this project yet'
      }
    },
    remove: {
      roleRates: 'Remove All Role Rates',
      userRates: 'Remove All Resource Rates',
      projectRate: 'Remove Project Rate'
    },
    unknownServiceError:
      'There was a problem in loading this card, please try again later.',
    noRates: {
      client: 'No Project, Role, or Resource rate defined for this client yet.',
      project:
        'No Project, Role, or Resource rate defined for this project yet.',
      rate: 'No Rate defined'
    },
    addRate: '+ Add Rate',
    removeRate: 'Remove Rate',
    effectiveDate: 'Effective Date',
    billingRate: 'Billing Rate',
    role: 'Role',
    resource: 'Resource',
    addResourceRate: 'Add Resource Rate',
    addRoleRate: 'Add Role Rate',
    addProjectRate: 'Add Project Rate',
    projectRate: 'Project Rate',
    roleRate: 'Role Rate',
    resourceRate: 'Resource Rate',
    copyFromClient: 'Copy from Client',
    addRateType: 'Add Rate Type',
    addRateCard: 'Add Rate Card',
    addMilestone: 'Add Milestone',
    copyingFromClient: 'Copying from client...',
    copyFromClientConfirmationDialog: {
      copyFromClient: 'Copy From Client',
      ok: 'Ok',
      cancel: 'Cancel',
      thisWillCopy: 'This will copy:',
      projectRates: 'Project Rates',
      roleRates: 'Role Rates',
      resourceRates: 'Resource Rates',
      confirmationMessage: 'Are you sure you want to copy?'
    },
    validations: {
      roleIsRequired: 'Please select a role for the rate.',
      duplicateRole: 'A rate with specified Role is already defined.',
      userIsRequired: 'Please select a user for the rate.',
      duplicateUser: 'A rate with specified User is already defined.',
      emptyEffectiveDate: 'Effective date is required on all rate entries.',
      duplicateEffectiveDate:
        'Rate entries should have unique effective dates for a rate.'
    },
    rateCardDialog: 'Rate Card Dialog',
    resourceAssignments: {
      allocatedResources: 'Allocated Resources',
      timesheetAccess: 'Timesheet Access',
      noOptions: 'No options',
      select: 'Select a Resource',
      resourceNotFoundMessage:
        "If you're looking for a resource not listed, {allocate} them or give them {timesheetAccess}.",
      allocateMessage: 'allocate',
      timesheetAccessMessage: 'timesheet access'
    }
  },
  advancedRateCard: {
    noRates: 'No rates have been defined yet.',
    definedBy: 'Defined by: ',
    payCode: 'Pay Code',
    payCodeDeleted: 'Pay Code deleted',
    role: 'Role',
    roleDeleted: 'Role deleted',
    resource: 'Resource',
    resourceDeleted: 'Resource deleted',
    selectDimensionValue: 'Select a {displayText}',
    separator: ', ',
    definedByDimensions: 'Defined by: {dimensions}',
    ratesSet: '{count} Rates Set',
    rateSet: '{count} Rate Set',
    addRate: 'Add Rate',
    addEntry: 'Add Entry',
    removeEntry: 'Remove Entry',
    rateCardSetup: 'Rate Card Setup',
    rateCardSummary: 'Rate Card Summary',
    defineRatesBy: 'Define Rates By: ',
    removeAllRates: 'Remove All Rates',
    add: 'Add',
    delete: 'Delete',
    defaultRate: 'Default',
    billingRate: 'Billing Rate',
    rateAmount: 'Rate Amount',
    rate: 'Rate',
    addRateToRow: 'Add Rate To Row',
    removeRateFromRow: 'Remove Rate From Row',
    addDimensionValue: '+ {displayText}',
    initialRate: 'Initial rate',
    deleteAllConfirmationDialog: {
      removeRate: 'Remove All Rates',
      message: 'Are you sure you want to remove all Rates ?'
    },
    validations: {
      required: '{field} is a required field',
      duplicate: 'Specified {field} is already defined'
    },
    effectiveDate: 'Effective Date',
    deleteDefaultRate: {
      no: 'Default Row can not be deleted.'
    },
    removeRateRowMessage:
      'Are you sure you want to remove this row?\n\nThis will impact the Billing Data.',
    removeRateRow: 'Remove Rate Row',
    advancedRateCardDialog: 'Advanced Rate Card',
    viewSummary: 'View Summary'
  },
  billingCard: {
    cardTitle: 'Billing',
    title: {
      schedule: 'Schedule',
      structure: 'Structure',
      defaults: 'Defaults'
    },
    subTitle: {
      billFrequency: 'Bill Frequency',
      paymentTerm: 'Payment Term',
      consolidateBillsBy: 'Consolidate Bills By',
      groupColumnsBy: 'Group Columns By',
      billLineItemsBy: 'Bill Line Items By',
      invoiceCurrency: 'Billing Currency',
      taxProfile: 'Tax Profile',
      poNumber: 'PO Number',
      invoiceTemplate: 'Invoice Template',
      description: 'Description (Customer)',
      internalNotes: 'Notes (Internal)',
      showComments: 'Show Comments on the Invoice'
    },
    subTitleValue: {
      billFrequency: '{billFrequency} on the {billFrequencyPeriod}',
      net: 'Net',
      noTaxProfile: 'No Tax Profile',
      noInvoiceTemplate: 'No Invoice Template'
    },
    validations: {},
    billingCardDialog: 'Billing Card'
  },
  billingSettingOverrideDialog: {
    title: 'Override Changes',
    description:
      'You have made some changes on Billing Default. This requires overriding Client Level Billing Defaults.',
    confirmationMessage:
      'Are you sure you want to override Client Level Billing Defaults?',
    cancel: 'Cancel',
    overrideChanges: 'Override Changes'
  },
  rateTable: {
    resource: 'Resource',
    role: 'Role',
    billingRate: 'Billing Rate',
    costRate: 'Cost Rate',
    initialRate: 'Initial rate',
    asOfDate: 'as of {formattedDate}',
    add: 'Add',
    remove: 'Remove',
    date: 'Date',
    effectiveDate: 'Effective Date',
    addRateToRow: 'Add Rate To Row',
    removeRateFromRow: 'Remove Rate From Row',
    rateAmount: 'Rate Amount',
    removeRateRow: 'Remove Rate Row'
  },
  userCard: {
    details: 'Details'
  },
  resourceBasicInfoCard: {
    skillsInfoCardDialog: 'Skills Info Card',
    basicInformation: 'Basic Information',
    groups: 'Groups',
    email: 'Email',
    employeeId: 'Employee ID',
    supervisor: 'Supervisor',
    language: 'Language',
    timeZone: 'Time Zone',
    department: 'Department',
    startDate: 'Start Date',
    noLanguage: 'No Language',
    noEmail: 'No Email',
    noTimeZone: 'No Time Zone',
    noEmployeeId: 'No Employee ID',
    asOf: 'As of',
    skills: 'Skills',
    save: 'Save',
    cancel: 'Cancel',
    firstname: 'First Name',
    lastname: 'Last Name',
    endDate: 'End Date',
    edit: 'Edit',
    costCenter: 'Cost Center',
    division: 'Division',
    employeeType: 'Employee Type',
    serviceCenter: 'Service Center',
    location: 'Location',
    noCostCenter: 'No Cost Center',
    noDepartment: 'No Department',
    noEmployeeType: 'No Employee Type',
    noDivision: 'No Division',
    noServiceCenter: 'No Service Center',
    noLocation: 'No Location',
    noResourcePools: 'No Resource Pools',
    resourcePools: 'Resource Pools',
    resourcePoolDialog: {
      placeHolderText: 'Type to search by Pool Name',
      assignResourcePools: '+ Assign Resource Pools'
    },
    showMore: 'Show More',
    validation: {
      firstNameRequired: 'Please enter a first name',
      lastNameRequired: 'Please enter a last name',
      firstNameExceedsMaxLength: 'First name cannot exceed 50 characters',
      lastNameExceedsMaxLength: 'Last name cannot exceed 50 characters',
      validEmail: 'Please enter a valid e-mail address'
    }
  },
  skillInfoCard: {
    certificate: 'Certificate',
    noSkillsAssignedMessage: 'No skills assigned to the user.',
    assignSkillMessage:
      'You can add skills by selecting from the dropdown above.',
    addCertificate: '+ Add Certificate',
    addNewCertificate: '+ Add New Certificate',
    unrated: 'Unrated',
    proficiencyLevel: 'Proficiency Level',
    unCategorized: 'Uncategorized',
    skills: 'Skills',
    selectSkills: 'Select Skills',
    addSkillRequirement: '+ Add Skill Requirement',
    noSkillsAssigned: 'No Skills Assigned',
    firstUsed: 'First Used',
    lastUsed: 'Last Used',
    level: 'Level',
    firstUsedDescription: 'First Used: {firstUsed}',
    lastUsedDescription: 'Last Used: {lastUsed}',
    deleteSkillDialog: {
      removeSkill: 'Remove Skill',
      description: 'Are you sure you want to remove this skill?',
      removeCategory: 'Remove Category',
      removeCategoryDescription:
        'Are you sure you want to remove the skills in this category?'
    },
    rating: {
      singular: '{ratingValue} star',
      plural: '{ratingValue} stars'
    }
  },
  skillsDropdown: {
    creatableSkill: 'Skill {label}',
    noMatchingSkillsFound: 'No matching skills found.',
    skill: 'Skill',
    addSkill: 'Add Skill',
    categoryExceedsMaxLength: 'Category cannot exceed 50 characters',
    nameExceedsMaxLength: 'Name cannot exceed 50 characters',
    descriptionExceedsMaxLength: 'Description cannot exceed 255 characters',
    pleaseSelectCategory: 'Please select a Category',
    category: 'Category',
    duplicateSkill:
      'Skill name should be unique. A skill with that name already exists for the category.',
    duplicateSkillCategory:
      'Skill Category name should be unique. A Skill Category already exists with the specified name.'
  },
  roleInfoCard: {
    role: 'Roles',
    primary: 'Primary',
    asOfDate: 'As of',
    history: 'History',
    additional: 'Additional',
    noRoleAssigned: 'No Role Assigned',
    additionalRole: 'Additional Role',
    rolesDuplicate: 'Duplicate roles cannot exist',
    roleEmpty: 'Role cannot be empty',
    roleLabel: 'Role'
  },
  rolesForm: {
    primaryRole: 'Primary Role',
    effectiveDate: 'Effective Date',
    additionalRole: 'Additional Role',
    additionalRoles: 'Additional Roles',
    initialRoles: 'Initial Roles',
    newRoleAsOf: '+ New Roles As Of'
  },
  resourceCertificatesAndLicensesCard: {
    addTitle: 'Add License / Certificate',
    title: 'License and Certificates',
    editTitle: 'Edit License / Certificate',
    description: 'Issued By {issuer} {issuedOn} {expiresOn}',
    alreadyExpiredDescription: 'Issued By {issuer}{issuedOn} (Expired)',
    credentialIdDescription: 'Credential ID : {credentialId} {credentialUrl}',
    expiresOn: '(Expires on {expiryDate})',
    seeCredential: '(See credential)',
    name: 'Name',
    issuingOrganization: 'Issuing Organization',
    issueDate: 'Issue Date',
    expiryDate: 'Expiry Date',
    credentialId: 'Credential ID',
    credentialUrl: 'Credential URL',
    doesntExpire: 'Does not expire',
    validation: {
      fieldExceededMaxLength:
        'Cannot exceed maximum length of {max} characters.',
      nameRequired: 'Please enter a name',
      invalidUrl: 'The URL entered is not valid.',
      organizationRequired: 'Please enter a Issuing organization name',
      validIssueDate: 'Please enter a Issue date',
      validExpirationDate: 'Please enter a Expiration date'
    },
    deleteCertificateDialog: {
      removeCertificate: 'Remove License / Certificate',
      description:
        'Are you sure you want to remove this license / certificate?',
      removeCertificateButton: 'Remove'
    },
    noCertificates: 'No certificates are available',
    license: 'License',
    certificate: 'Certificate',
    addLicenseOrCertificate: '{license} / {certificate}',
    issuedByOn: ' on {issueDate}',
    issuedBy: 'Issued By {issuer}',
    issuedOn: 'Issued on {issueDate}',
    issuedByOrOnWithExpiresOn: ' (Expires on {expiryDate})',
    expired: 'Expired',
    issuedByOrOnWithExpired: ' (Expired)',
    issuedByOrOnWithDoesNotExpire: ' (Does not expire)'
  },
  resourcePoolSearchBox: {
    role: 'Role',
    primaryRole: 'Primary Role',
    skill: 'Skill',
    expand: 'Expand',
    resourcePool: 'Resource Pool',
    noResourcePool: 'No Resource Pool',
    noResourcePools: 'No Resource Pools'
  },
  resourcePoolCard: {
    title: 'Resource Pools',
    userAddedToPools:
      '{user} added to {count, plural, =1 {# resource pool} other {# resource pools}}',
    userAddedToSpecificPool: '{user} added to {displayText}',
    removedFromPools:
      '{user} removed from {count, plural, =1 {# resource pool} other {# resource pools}}',
    removedFromSpecificPool: '{user} removed from {displayText}'
  },
  projectFinancialsCard: {
    title: 'Financials',
    'urn:replicon:cost-type:capital': 'CapEx',
    'urn:replicon:cost-type:operational': 'OpEx',
    exceedingMaxValue: 'Exceeds max allowed value: ',
    budgetHours: 'Budget Hours',
    totalEstimatedContract: 'Total Contract Value',
    budgetedCost: 'Budget Cost',
    estimatedHours: 'Estimated Hours',
    estimatedCost: 'Estimated Cost',
    estimatedBilling: 'Estimated Billing',
    budget: 'Budget',
    estimates: 'Project Estimates',
    projectCurrency: 'Project Currency',
    projectCurrencyWithValue: 'Project Currency: {value}',
    projectCurrencyWarning:
      'Recalculating project metrics with a new currency will take a while.',
    budgetCurrencyWarning:
      'Recalculating totals with new currency. Totals may be out of sync temporarily.',
    costType: 'Cost Type',
    resourceBudgeted: 'Resource Cost',
    expenseBudgeted: 'Expense Cost',
    cost: 'Cost',
    plusSign: '+',
    equalSign: '=',
    costDescription: '{currency} {cost}',
    financialsCardDialog: 'Financials Card'
  },
  projectTimeAndExpenseCard: {
    title: 'Time & Expenses',
    expenses: 'Expenses',
    timeEntryAllowed: 'Allow time entry against project',
    timeAndExpenseEntryType: 'Project Time & Expense Entry',
    expenseCodes: 'Expense Codes',
    addExpenseCode: 'Add Expense Code',
    allowedExpenseTypes: 'Allowed Expense Types',
    expenseTypes: '{count} Expense Types',
    viewSummary: 'View Summary',
    totals: 'Totals',
    budgetExpenseCost: 'Expense Budget',
    actualAmount: 'Actual Amount',
    estimatedAmount: 'Estimated Amount',
    costDescription: '{currency} {cost}',
    timeAndExpenseCardDialog: 'Time And Expense Card',
    noExpenseType:
      'No Expense Types added. Add expense types to allow them to \nbe entered against this project and estimates for them.'
  },
  projectBillingCard: {
    title: 'Billing',
    addClause: 'Add Clause',
    removeContractClause: 'Remove Contract Clause'
  },
  carryOverSummaryDialog: {
    title: 'Recognizable Revenue',
    subTitle: '{projectDisplayText}',
    rowType: {
      total: '{month} Total',
      current: '{month}',
      carryOver: '{month}: Carry Over',
      deferred: '{month}: Deferred',
      noDate: 'Carry Over'
    }
  },
  revenueRun: {
    calculate: 'Calculate',
    inProgress: 'Calculation in process',
    createRevenueContractScriptRecalculationBatchInProgress:
      'Identifying & Syncing Projects',
    revenueRunBatchInProgress: 'Calculating Revenue',
    noCalculationMessage: 'No calculation has been made for this book.',
    noCalculationHelpText: 'To calculate, press the calculate button.',
    lastUpdated: 'Last Updated: {date}',
    lastRecalculatedInfo: 'Data is {days} days old -',
    error: 'Error',
    revenueEntriesErrorCount: '{count} project(s) ',
    revenueEntriesErrorMessageWithoutRefresh:
      'need attention. Revenue (Pending Revenue + Recognized Revenue to Date + Recognized Revenue) should be equal to the Total Contract Value.',
    revenueEntriesErrorMessageWithRefresh:
      'need attention. Revenue (Pending Revenue + Recognized Revenue to Date + Recognized Revenue) should be equal to the Total Contract Value. To view Projects -',
    nonBaseCurrencyFailureMessage:
      'The Currency of Revenue Entries for some projects is not in sync with Base Currency. Please Unassign and Reassign the Policies to the Projects before you proceed further.',
    refresh: 'Refresh',
    closed: 'Closed: {date}',
    reopened: 'Reopened: {date}',
    exportData: 'Export Data',
    revrecHistory: 'Revenue Recognition History: {displayText}',
    revrecPolicyInEffect: 'Revenue Recognition Policy In effect',
    noMatchingHistory: 'No Revenue History Found',
    deferredRevenue: 'Deferred Revenue',
    revenuePeriod: 'Month',
    recognizedRevenue: 'Recognized Revenue',
    initialRevenue: 'Initial Revenue',
    actualHours: 'Actual Hours',
    estimatedHours: 'Estimated Hours to Complete',
    cumulativePoC: 'Cumulative PoC',
    percenatageOfCompletion: 'PoC',
    cumulativeRevenue: 'Cumulative Revenue',
    recognizableRevenue: 'Recognizable Revenue',
    pendingRevenue: 'Pending Revenue',
    forecastRevenue: 'Revenue Forecast',
    actualCost: 'Actual Cost',
    estimatedCost: 'Estimated Cost',
    closeButton: 'Close Button',
    closeIcon: 'Close'
  },
  billingRevenueDrilldown: {
    showMore: 'Show More',
    noDataMessage: 'No data found',
    client: 'Client',
    project: 'Project',
    program: 'Program',
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    costCenter: 'Cost Center',
    department: 'Department',
    employeeType: 'Employee Type',
    total: 'Total'
  },
  revenueDrilldown: {
    title: 'Revenue Recognition',
    client: 'Client',
    revenueForecast: 'Revenue Forecast',
    recognizableRevenue: 'Recognizable Revenue',
    deferredRevenue: 'Deferred Revenue',
    recognizedRevenue: 'Recognized Revenue',
    total: 'Total'
  },
  billingDrilldown: {
    title: 'Billing',
    client: 'Client',
    estimatedBilling: 'Estimated Billing',
    availableToBill: 'Available to Bill',
    unBilled: 'Unbilled',
    billed: 'Billed',
    total: 'Total'
  },
  revenueEntryTable: {
    revenue: 'Revenue',
    groups: 'Groups',
    recognizableRevenue: 'Recognizable Revenue',
    total: 'TOTAL',
    project: 'Project',
    projectStatus: 'Project Status',
    program: 'Program',
    forecastRevenue: 'Revenue Forecast',
    month: 'Month',
    revenueRecognitionPolicy: 'Revenue Recognition Policy in effect',
    revenueRecognizedTillDate: 'Revenue Recognized To Date',
    initialRecognizedRevenue: 'Revenue Recognized To Date',
    initialForecastedRevenue: 'Revenue Forecasted To Date',
    recognizedRevenue: 'Recognized Revenue',
    status: 'Status',
    open: 'Open',
    close: 'Close',
    closed: 'Closed',
    all: 'All',
    client: 'Client',
    contractAmount: 'Contract Amount',
    deferredRevenue: 'Deferred Revenue',
    comment: 'Comment',
    revenuePending: 'Revenue Pending',
    recognizedRevenueYTD: 'YTD',
    lastCalculated: 'Last Calculated',
    noMatchingEntries:
      'No revenue entries for the selection have been created.',
    noEntries: 'No entries found.',
    totalContractAmount: 'Total Contract Amount',
    projectCode: 'Project Code',
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    costCenter: 'Cost Center',
    department: 'Department',
    employeeType: 'Employee Type',
    projectManager: 'Project Manager',
    pendingRevenue: 'Pending Revenue'
  },
  projectRevenueRecognitionCard: {
    projectCurrency: 'Project Currency',
    baseCurrency: 'Base Currency',
    noChartMessage: 'Click the refresh icon to generate chart data.',
    toCloseMonthMessage:
      'Recognized Revenue will be generated once the month is closed.',
    title: 'Revenue Recognition',
    empty: 'No Policy Selected.',
    selectPolicy: 'Select a Policy',
    noPolicy: 'None',
    asOfCloseDateTitle: 'As of {date} Close:',
    totalForecast: 'Total Forecast',
    recognizedRevenue: 'Recognized Revenue',
    revenueForecast: 'Revenue Forecast',
    revenueVariance: 'Revenue Variance',
    viewHistory: 'View History',
    warningMessage:
      "You've recognized {currency} {recognizedAmount} as of the last closed month ({date}).\nChanges made to this Revenue Recognition policy will only effect data from {openMonth} onwards.",
    warningMessageWithTCV:
      "You've recognized {currency} {recognizedAmount} as of the last closed month ({date}).\nChanges made to this Revenue Recognition policy will only effect data from {openMonth} onwards.\nThese changes may affect the Total Contract Value (Base Currency), please update manually if required.",
    baseCurrencyWarning:
      'These changes may affect the Total Contract Value (Base Currency), please update manually if required.',
    tcvProjectCurrency: 'TCV (Project Currency)',
    tcvBaseCurrency: 'TCV (Base Currency)',
    contractDate: 'Contract Date',
    projectContractValue: 'Project Contract Value',
    baseContractValue: 'Base Contract Value',
    revenueRecognitionCardDialog: 'Revenue Recognition Card'
  },
  projectRevenueCard: {
    title: 'Revenue',
    error: 'Error',
    recalculateRevenue: 'Recalculate Revenue',
    recalculatingRevenue: 'Recalculating Revenue...'
  },
  profitMarginCard: {
    profit: 'Profit',
    margin: 'Margin'
  },
  groupsCard: {
    title: 'Groups'
  },
  projectManagerCard: {
    projectManagers: 'Project Managers',
    skipProjectManagerApproval: 'Skip Project Manager Approval'
  },
  projectTasksCard: {
    title: 'Tasks',
    addButton: 'Add Task'
  },
  programCard: {
    details: 'Details'
  },
  projectPageTabs: {
    resource: 'Resource',
    info: 'Project Info',
    summary: 'Summary',
    overview: 'Overview',
    billingInvoicing: 'Billing & Invoicing',
    resourcingPlan: 'Resourcing Plan',
    tasks: 'Tasks',
    time: 'Time',
    timeAndExpense: 'Time & Expense',
    resourcing: 'Resourcing',
    taskSummary: 'Task Summary',
    linkedProjects: 'Linked Projects'
  },
  projectResourcing: {
    noRows: 'There are no outstanding resource requests for this project.',
    resourcePlan: 'Resource Plan',
    timesheetAccess: 'Timesheet Access',
    banner1: 'Timesheet access only provides access to record time worked',
    banner2:
      'To plan when resources will be allocated to this project, use the',
    remaining: 'Remaining',
    requested: 'Requested',
    showMoreRow: {
      resourceRequestsMessage:
        'More Resource Requests are defined for this project. Search to refine results.',
      resourcesMessage:
        'More Resources are assigned to this project. Search to refine results.',
      resourceRequestsAllocationMessage:
        'More resource requests are available. Search to refine results.'
    }
  },
  timesheetAccess: {
    noBillingRatesAssigned: 'No billing rates assigned',
    allUsers: 'All Users',
    accessMember: 'Access Member',
    taskAssignments: 'Task Assignments',
    taskTimesheetAccess: 'Task Timesheet Access',
    taskTimesheetAccessHeaderText: 'Task Timesheet Access: ',
    teamBillingRates: 'Team Billing Rates',
    billingRates: 'Billing Rates',
    resource: 'Resource',
    resources: 'Resources',
    tasks: 'tasks',
    noTimesheetAccess: 'No timesheet access has been granted for this project.',
    grantAccess: 'To grant timesheet access, press the',
    buttonBelow: 'button below.',
    noTaskAccess: 'No task access',
    noTaskAssignments: 'No task assignments',
    userRate: `{displaytext}'s Rate`,
    removeAllUsersTaskCount: 'All Users is assigned to {value} task(s).',
    removeMemberTaskCount: 'This {memberType} is assigned to {value} task(s).',
    removeTimesheetAccess: 'Remove Timesheet Access',
    removeMessage:
      'Are you sure you want to remove timesheet access from {value}?',
    addTimesheetAccess: 'Add Timesheet Access',
    addAccess: 'Add Access ({value})',
    cancel: 'Cancel',
    noTasks: 'No Tasks',
    save: 'Save',
    setTaskAssignments: 'Set Task Assignments',
    setTimesheetAccess: 'Set Timesheet Access',
    saveBillingRates: 'Save Billing Rates',
    noAvailableMembersToAdd: 'No available {memberPluralName} to add.',
    accessToTask: 'Access to {value} tasks',
    accessToBillingRate: 'Access to {value} Rates',
    taskCount: '{value} tasks',
    billingRatesCount: '{value} Rates',
    billingRateCount: '{value} Rate',
    billingRateAccess: 'No Billing Rate ',
    noMatchingBillingRate: 'No matching billing rates found'
  },
  taskAssignmentDialog: {
    title: 'Task Assignments: ',
    assignment: 'Assignment',
    hoursSuffix: ' h',
    moreTasksAvailable: 'More tasks available. Search to refine results.',
    noRole: 'No Role',
    task: 'Task',
    select: 'Select',
    openDrawer: 'Open Drawer'
  },
  projectPage: {
    cost: 'Cost',
    hours: 'Hours',
    archiveProject: 'Archive Project',
    resourceCost: 'Resource Cost',
    resourceHours: 'Resource Hours',
    budget: 'Budget',
    budgetHours: 'Budget Hours',
    budgetCosts: 'Budget Costs',
    removeProject: 'Remove Project',
    progress: 'Project Progress',
    overview: 'Project Overview',
    projectCost: 'Project Cost',
    profitability: 'Project Profitability',
    duplicateProject: 'Duplicate Project',
    unavailableForMigratedProjects: 'Unavailable for Migrated Projects',
    rescheduleProject: 'Reschedule Project',
    unavailableForMigratedProjectsInfo:
      'Duplicate Project Disabled\nMigrated projects don’t have\nbill plans and rate cards which are\nrequired in for projects in Polaris'
  },
  feedListItem: {
    by: 'by ',
    on: ' on'
  },
  setUpdatesDialog: {
    title: 'Accept Tasks',
    confirmationBody: 'You are about to accept {count} tasks.',
    confirmationCaption: 'Are you sure you want to accept these tasks?',
    cancelButton: 'Cancel',
    setUpdatesButton: 'Accept'
  },
  userBeatPage: {
    dateSelectorLabel: 'Select User Beat Date',
    notStartedTasksTitle: 'Not Started Tasks',
    completedTasksTitle: 'Completed Tasks',
    startTask: 'Start',
    startTaskLabel: 'Start Task',
    restartTask: 'Restart',
    restartTaskLabel: 'Restart Task',
    stopTaskLabel: 'Stop Task',
    completeTaskLabel: 'Complete Task',
    stopWorkingOnTask: 'Stop Working on Task',
    markAsDone: 'Mark as Done',
    markAsNotDone: 'Mark as Not Done',
    clearData: 'Clear Data',
    done: 'Done',
    notDone: 'Not Done',
    dateSummary: 'Hours Worked: {hoursWorked}, Tasks: {taskCount}',
    addTask: 'Add Task',
    editTasks: 'Edit',
    completedTaskDescription: 'Completed: {dateCompleted}, {hoursWorked} hours',
    notStartedTaskDescription: '{dateRange}, {hoursWorked}',
    noTasksStarted: 'No tasks have been started.',
    noTasksAvailable: 'No tasks available to start.',
    noTasksCompleted: 'No tasks have been completed.',
    allTaskDataCleared: 'All task data cleared',
    submittedSuccessfully: 'All task data submitted successfully',
    submitError: 'There was an error submitting the task',
    taskDataCleard: 'Task row data cleared',
    submitTask: 'Submit Task',
    unsubmitTask: 'Edit Submitted Task',
    notStartedTitle: 'Not Started ({notStartedCount})',
    completedTitle: 'Completed ({completedCount})',
    notStarted: 'Not Started',
    completed: 'Completed',
    approved: 'Approved',
    submitSelected: 'Submit Selected',
    gotoToday: 'Go to Today',
    timesheetStatusNotOpen:
      'Timesheet for the selected date must be open to edit',
    currentTimesheetStatusNotOpen: 'Current timesheet must be open to edit'
  },
  userBeatTabHeaders: {
    activeTasks: 'Active Tasks',
    availableTasks: 'Available',
    completedTasks: 'Completed'
  },
  tasks: {
    totalWork: 'Total Work',
    work: 'Work',
    todaysWork: "Today's Work",
    workRemaining: 'Work Remaining',
    estimatedWork: 'Estimated Work',
    estimatedCompletionDate: 'Estimated Completion Date',
    estimatedCompletionDateShort: 'Est Completion Date',
    comment: 'Comment',
    plannedDates: 'Planned Dates',
    completionDate: 'Completion Date',
    quality: 'Quality',
    flagged: 'Flagged',
    notFlagged: 'Not Flagged',
    done: 'Done',
    includeClosedTasks: 'Show Closed Tasks'
  },
  qualityFacetDetails: {
    title: 'Quality'
  },
  dateRangeFacetDetails: {
    title: 'Dates',
    startDateAriaLabel: 'Start Date',
    endDateAriaLabel: 'End Date',
    clearDateAriaLabel: 'Clear date',
    today: 'Today'
  },
  editAllocationUserDialog: {
    changing: 'Changing',
    changeAsOf: 'Change as of',
    changeForEntireAllocation: 'Change for entire allocation',
    changeResourceOptions: 'Change Resource Options',
    changeResource: 'Change Resource',
    cancel: 'Cancel',
    changeAllocationPrompt: 'How would you like to change the allocation?',
    roles: 'Roles',
    allocation: 'Allocation',
    noRole: 'No Role'
  },
  score: {
    none: 'None',
    matchScore: 'SmartMatch Score: ',
    error: 'Unable to retrieve score',
    pleaseWait: 'Please Wait',
    notFinishedCalculating:
      'Resource Request SmartMatch score has not finished calculating.',
    errorCalculating: 'Error Calculating SmartMatch Scores',
    somethingWrong:
      'Something went wrong calculating SmartMatch scores for this request. Please contact Replicon Support for assistance.',
    notComputed: 'SmartMatch Score: -',
    wasNotComputed: 'Required qualifications not met.',
    role: 'Role',
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    costCenter: 'Cost Center',
    department: 'Department',
    skill: 'Skill',
    tag: 'Tag',
    cost: 'Cost',
    employeeType: 'Employee Type',
    resourcePool: 'Resource Pool'
  },
  tabbedDialogHeader: {
    close: 'Close'
  },
  taskBeatDrawerFooterMobile: {
    update: 'Accept'
  },
  taskBeatTable: {
    noOverdueTasks: 'No Overdue Tasks',
    noApprovedTasks: 'No Approved Tasks',
    noTaskEntries: 'No Task Entries',
    noSmartBeats: 'No matching SmartBeats found.',
    errorMessage:
      'Sorry, but we could not load the requested data. Try adjusting the filters for a smaller set of data.',
    loading: 'Loading...',
    taskMovedToAcceptedTab: '{name} moved to Accepted tab.',
    tasksMovedToAcceptedTab: '{count} tasks moved to Accepted tab.',
    beatMovedToAcceptedTab: 'Beat moved to Accepted tab.',
    selectTask: 'Select {name} task',
    expandTask: 'Expand {name} task',
    noTask: 'No Task'
  },
  centeredError: {
    errorOccurred: 'An error has occurred'
  },
  taskBeatTableHeader: {
    date: 'Date',
    user: 'User',
    work: 'Work',
    hoursWorked: 'Hours Worked',
    previousWork: 'Previous Work',
    workRemaining: 'Work Remaining',
    estimatedHoursAtCompletion: 'Estimated Hours At Completion',
    estimatedCompletionDate: 'Estimated Completion Date',
    comment: 'Comment',
    task: 'Task',
    project: 'Project',
    actions: 'Actions',
    done: 'Done',
    selectAll: 'Select All Tasks'
  },
  taskEntries: {
    emptyMessage: 'No task entries have been entered.'
  },
  taskBeatDrawerHeaderMobile: {
    date: 'Date',
    work: 'Work'
  },
  taskBeatManagerSectionMobile: {
    title: 'Set Status to:',
    remaining: 'Remaining',
    estimatedDate: 'Estimated Date',
    comment: 'Comment',
    quality: 'Quality',
    flagged: 'Flagged',
    estimatedHoursAtCompletion: 'Estimated At Completion'
  },
  taskBeatUserSectionMobile: {
    submitted: 'Submitted'
  },
  mobileSelectedToolbar: {
    selected: 'Selected',
    accept: 'Accept'
  },
  taskBeatChip: {
    h: 'h',
    day: 'day',
    days: 'days'
  },
  taskBeatRow: {
    setStatusTo: 'Set Status to:',
    update: 'Update',
    edit: 'Edit'
  },
  projectList: {
    project: 'Project',
    projectCode: 'Project Code',
    projectLeader: 'Project Manager',
    projectStatus: 'Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    actualTaskStartDate: 'Actual Task Start Date',
    estimatedTaskCompletionDate: 'Estimated Task Completion Date',
    actualTaskCompletionDate: 'Actual Task Completion Date',
    program: 'Program',
    portfolio: 'Portfolio',
    client: 'Client',
    emptyList: `None of your projects are currently managed by Replicon Professional Services Automation.
      To manage a project, set the Project Type to Managed.`,
    noMatchingProjects: 'No matching projects found.',
    clickToAddProject: 'To add a project, press the{value}button below.',
    hours: 'Hours',
    cost: 'Cost',
    billing: 'Billing',
    revenue: 'Revenue',
    budgetHoursUsed: '% Budget Used',
    budgetRemaining: 'Budget Remaining',
    budgetVsScope: 'Budget vs Scope',
    budget: 'Budget',
    actualExpenseCost: 'Actual Expenses',
    actualResourceCost: 'Actual Resource Cost',
    actualCostInProjectCurrency: 'Actual Cost (Project Currency)',
    budgetCostInPortfolioCurrency: 'Budget Cost (Portfolio Currency)',
    totalActualCostInPortfolioCurrency: 'Actual Cost (Portfolio Currency)',
    actualCost: 'Actual Cost',
    actual: 'Actual',
    projectCurrency: 'Project Currency',
    portfolioCurrency: 'Portfolio Currency',
    estimatedAtCompletion: 'Estimated at Completion',
    estimatedAtTaskCompletion: 'Estimated at Task Completion',
    total: 'Total',
    managed: 'Managed',
    unmanaged: 'Unmanaged',
    list: 'List',
    date: 'Dates',
    timeline: 'Timeline',
    budgetHours: 'Budget Hours',
    actualHours: 'Actual Hours',
    scopeCompleted: '% Scope Completed',
    workRemaining: 'Work Remaining',
    taskHours: 'Task Hours',
    overview: 'Overview',
    allProjects: 'All Projects',
    myProjects: 'My Projects',
    thisPortfolio: 'This Portfolio',
    subPortfolios: '+ Sub-Portfolios',
    totalContractValue: 'Total Contract Value',
    billableRevenue: 'Billable Revenue',
    billableRevenueProjectCurrency: 'Billable Revenue (Project Currency)',
    revenueRecognitionPolicy: 'Revenue Recognition Policy',
    groups: 'Groups',
    valueUnavailableTooltip: {
      title: 'Value unavailable',
      content:
        'This value is currently unavailable due to some pending calculations.'
    },
    coManagers: 'Co-Managers',
    noProjectManager: 'No Project Manager',
    more: 'more'
  },
  resourceManagementList: {
    name: 'Name',
    department: 'Department',
    employeeId: 'Employee ID',
    startDate: 'Start Date',
    endDate: 'End Date',
    location: 'Location',
    resourceType: 'Employee Type',
    status: 'Status',
    enabled: 'Enabled',
    disabled: 'Disabled',
    details: 'Resource Details',
    supervisor: 'Supervisor',
    noResources: 'No matching resources found.',
    norole: 'No matching roles found.',
    nolocation: 'No matching locations found.',
    nodivision: 'No matching divisions found.',
    nocostCenter: 'No matching cost centers found.',
    noserviceCenter: 'No matching service centers found.',
    noemployeeType: 'No matching employee types found.',
    nodepartment: 'No matching departments found.',
    costCenter: 'Cost Center',
    serviceCenter: 'Service Center',
    division: 'Division',
    employeeType: 'Employee Type',
    Status: 'Status',
    asOf: 'As of',
    role: 'Role',
    adjustFilterMessage:
      'Try adjusting your search filters to find matching resources.',
    resourcePools: 'Resource Pools',
    countResourcePools: '{count} Resource Pools'
  },
  projectDrawer: {
    eacFromProjectBudgetMessage: `There is no Estimated at Task Completion value available from the project's tasks.\n\n As a result, the Project Budget Cost has been used.`,
    eacFromProjectEstimtesMessage: `There is no Estimated at Task Completion value available from the project's tasks.\n\n As a result, the Project Estimated Cost has been used.`,
    hoursEacFromProjectBudgetMessage: `There is no Estimated at Task Completion value available from the project's tasks.\n\n As a result, the Project Budget Hours has been used.`,
    hoursEacFromProjectEstimatesMessage: `There is no Estimated at Task Completion value available from the project's tasks.\n\n As a result, the Project Estimated Hours has been used.`,
    expenseRemainingFromProjectBudgetMessage: `The remaining expenses have been calculated by subtracting the total actual expenses from the Expense Cost Budget.`,
    expenseRemainingForClosedProjectMessage: `The project is closed so no further expenses can be entered.`,
    workRemainingForClosedProjectMessage: `The project is closed so no further time can be entered.`,
    noStartDate: 'No Start Date',
    plannedDates: 'Planned Dates',
    projectBudget: 'Project Budget',
    projectName: 'Project Name',
    projectStatusDetails: 'Project Status Details',
    projectSummary: 'Project Summary',
    status: 'Status'
  },
  taskDrawer: {
    'Basic Information': 'Basic Information',
    'Estimated At Completion': 'Est. at Completion',
    'Estimated Completion Date': 'Estimated Completion Date',
    'Estimated End': 'Estimated End',
    'Estimated Hours At Completion': 'Estimated Hours At Completion',
    'Hours Worked': 'Hours Worked',
    'No timesheet data available': 'No timesheet data available',
    'Start - End Date': 'Start - End Date',
    'Task Beat Total': 'Task Beat Total',
    'Task Status': 'Task Status',
    'Total Work Done': 'Total Work',
    'Work Done': 'Work Done',
    'Work Remaining': 'Work Remaining',
    'more fields': 'more fields',
    Comment: 'Comment',
    Date: 'Date',
    Description: 'Description',
    Estimate: 'Estimate',
    Project: 'Project',
    Remaining: 'Remaining',
    Status: 'Status',
    Summary: 'Summary',
    User: 'User',
    Work: 'Work',
    actualCost: 'Total Cost To Date',
    actualCostToDate: 'Actual Cost to Date',
    actualHoursToDate: 'Actual Hours to Date',
    actuals: 'Actuals',
    closed: 'Closed',
    completed: 'Completed',
    costRemaining: 'Cost Remaining',
    costType: 'Cost Type',
    costVariation: 'Cost Variation',
    dateVariation: 'Date Variation',
    early: '{days} Day(s) Early',
    estimated: 'estimated',
    estimatedAtCompletion: 'Estimated At Completion',
    estimatedAtTaskCompletion: 'Estimated at Task Completion',
    estimatedCostAtCompletion: 'Estimated Cost at Completion',
    estimatedExpenses: 'Estimated Expenses',
    estimatedHoursAtCompletion: 'Estimated Hours at Completion',
    expensesRemaining: 'Expenses Remaining',
    hours: 'hours',
    hoursRemaining: 'Hours Remaining',
    hoursVariation: 'Hours Variation',
    inProgress: 'In Progress',
    late: '{days} Day(s) Late',
    less: 'Less',
    month: 'Month',
    more: 'More',
    na: 'NA',
    notStarted: 'Not Started',
    onTime: 'On Time',
    open: 'Open',
    originalEstimate: 'Original Estimate',
    to: 'to',
    pendingTaskBeats: {
      title: '1 Pending SmartBeat',
      xTitle: '{value} Pending SmartBeats',
      managerSubText: 'Accept to include them in the task status.',
      reviewBeats: 'Review SmartBeats',
      userSubText: 'The status will include them once they are accepted.'
    },
    plannedDates: 'Planned Dates',
    showMore: 'Show More',
    taskHours: 'Task Hours',
    taskSummary: 'Task Summary',
    tasks: 'Tasks',
    totalExpenses: 'Total Expenses',
    variation: 'Variation',
    workRemaining: 'Work Remaining',
    rescheduleButton: 'reschedule'
  },
  resourceUsersDropdown: {
    noResources: 'No resources',
    noProjectResources: 'No resources have been added to this project.',
    noMatchedResources: 'No matching resources found.',
    loading: 'Loading…',
    tabId: 'Tabs',
    noOption: 'No Option',
    projectResources: 'Project Resources',
    allResources: 'All Resources'
  },
  taskDrawerEdit: {
    addSubTask: 'Subtask',
    editButtonAria: 'Edit Task',
    name: 'Name',
    code: 'Code',
    closedOn: 'Closed on',
    closingOn: 'Closing on',
    effectiveClosedOn: 'Effectively closed - Parent task closed on',
    effectiveClosingOn: 'Effectively closed - Parent task closing on',
    completionOn: 'Completion on',
    daysLeft: '({count} days left)',
    startDate: 'Planned Start Date',
    endDate: 'Planned End Date',
    description: 'Description',
    isTimeEntryAllowed: 'Time Entry Allowed',
    milestone: 'Milestone',
    timeAndExpenseEntryType: 'Time & Expense Entry',
    initialEstimatedHours: 'Estimated Hours',
    save: 'Save',
    cancel: 'Cancel',
    isRequired: ' is required.',
    minDate: '{path} must be greater than or equal to {date}',
    maxDate: '{path} must be less than or equal to {date}',
    remove: 'Remove',
    removeTask: 'Remove Task',
    removeTaskTitle: 'Remove Task: ',
    removeTaskConfirmation: {
      messageLine1: 'This task and any of its subtasks will be removed.',
      messageLine2: 'Are you sure you want to remove this task?'
    },
    taskOwner: 'Task Owner',
    validations: {
      startDateRequired: 'Please enter start date',
      endDateRequired: 'Please enter end date'
    },
    role: 'Role',
    taskRole: 'Task Role',
    timeAndExpenseEntryAllowed: 'Time & Expense Entry Allowed',
    timeEntryAllowed: 'Time Entry Allowed',
    costType: 'Cost Type',
    codeExceedsMaxLength: 'Task Code cannot exceed {max} characters',
    groupTimesheetAccess: 'Group Timesheet Access'
  },
  taskResourceAssignments: {
    addResource: '+ Resource',
    allocatedResources: 'Allocated Resources',
    allocation: 'Allocation',
    allUsers: 'All Users',
    costCenters: 'Cost Centers',
    deltaTooltip:
      "Task estimate doesn't match hours.\nAdjust assigned hours or task estimate",
    departments: 'Departments',
    divisions: 'Divisions',
    employeeTypes: 'Employee Types',
    enterDates: 'Enter Dates',
    enterEstimatedHours: 'Enter estimated hours',
    estimatedCost: 'Estimated Cost',
    estimated: 'Estimated',
    groupTimesheetAccess: 'Group Timesheet Access',
    hours: 'Hours',
    hoursSuffix: 'h',
    inheritLabel: 'Inherit parent task resources',
    inheritMessage: '{count} Resource(s) will be assigned from the parent task',
    loading: 'Loading',
    locations: 'Locations',
    more: 'More',
    noOptions: 'No options',
    noResourceAssignments: 'No resources have been assigned to this task',
    removeResource: 'Remove Resource',
    removeResourceBody:
      '{resourceName} may have some Assigned Hours, Actual Hours or Work Remaining Hours on this Task(s).\nPlease make sure Assigned Hours are distributed to the Remaining and/or New Resources and Work Remaining is “0“ before removing the resource.\nUnassigned Hours can lead to inaccurate Work Remaining & Estimated Hours at Completion.\nAre you sure you want to remove the resource?',
    cancel: 'Cancel',
    resource: 'Resource',
    resources: 'Resources',
    role: 'Role',
    search: 'Search...',
    selectAResource: 'Select a Resource',
    selectARole: 'Select a Role',
    serviceCenters: 'Service Centers',
    taskEstimatedHours: 'Task Estimated Hours',
    timesheetAccess: 'Timesheet Access',
    title: 'Resource Assignment',
    total: 'Total',
    totalEstimatedCost: 'Total Estimated Cost',
    totalEstimatedHours: 'Total Estimated Hours',
    diffHoursToolTip:
      'Total Resource Estimated Hours is different than the Task Estimated Hours.',
    diffCostsToolTip:
      'Total Resource Estimated Cost is different than the Task Estimated Cost.',
    emptyMessage: 'No Resources have been assigned to this task'
  },
  addResourceEstimateDialog: {
    title: 'Add Resource:',
    role: 'Role',
    resource: 'Resource',
    estimatedHours: 'Estimated Hours',
    allocateResourceTimeCheckBox: 'Allocate Resource Time to Task',
    startDate: 'Start Date',
    endDate: 'End Date',
    addResource: 'Add Resource',
    addPlaceholder: 'Add Placeholder',
    validations: {
      startDateRequired: 'Please enter a Start Date',
      endDateRequired: 'Please enter an End Date',
      initialEstimatedHoursRequired: 'Enter hours to allocate resource time'
    },
    messages: {
      noEnoughAvailableHoursWarning:
        'This resource does not have enough available hours for the estimated hours in the selected dates.'
    }
  },
  editResourceEstimateDialog: {
    title: 'Update Placeholder: ',
    setResource: 'Set Resource',
    savePlaceholder: 'Save Placeholder'
  },
  rescheduleTaskDialog: {
    rescheduleTaskTitle: 'Reschedule Task:',
    rescheduleTask: 'Reschedule Task',
    existingStartDate: 'Existing Start Date',
    existingEndDate: 'Existing End Date',
    newStartDate: 'New Start Date',
    newEndDate: 'New End Date',
    duration: '{calendarDays} Days, {workDays} Work Days',
    keepTaskWorkDays: 'Keep Task Work Days',
    keepTaskLength: 'Keep Task Length',
    custom: 'Custom',
    keepTaskWorkDaysDescription:
      'Calculate the end date so the task includes the same number of work days',
    keepTaskLengthDescription:
      'Calculate the end date to keep the same number of days long',
    customDescription: 'Select the date you want the task to end on',
    removeTaskAllocationConfirmation: 'Remove the Task Allocation',
    removeTaskAllocationConfirmationDescription:
      'Keep the resources, but remove the time allocated to the task',
    daysLater:
      'This task will be rescheduled {count, plural, =1 {# day} other {# days}} later.',
    earlierDays:
      'This task will be rescheduled {count, plural, =1 {# day} other {# days}} earlier.',
    adjustTaskDates: 'Adjusting task dates',
    onSubmitLoadingMessage: 'Rescheduling Task...',
    noDateSet: 'No Date Set',
    openEndedTask: 'Open ended task',
    openEndedTaskTooltip:
      'Tasks without a start or end date set are open ended.',
    removeTaskAllocationAriaLabel: 'Remove Task Allocation'
  },
  taskResourceEstimates: {
    actual: 'Actual',
    addAllocation: '+ Allocation',
    addEstimate: '+ Estimate',
    allocation: 'Allocation',
    dateRange: '{startDate} - {endDate}',
    difference: 'Difference',
    estimate: 'Estimate',
    estimatedCost: 'Estimated Cost:',
    estimatedAtCompletion: 'Estimated at Completion',
    hours: '{value}h',
    hoursSuffix: 'h',
    hoursAdornment: 'hours',
    hoursInput: '{value} hours',
    hoursInputField: 'Hours Input Field',
    noAllocationSet: 'No Allocation Set',
    noEstimateSet: 'No Estimate Set',
    noResourceAssignments: 'No resources have been assigned to this task',
    notApplicable: 'NA',
    noResourceSelected: 'No Resource Selected',
    noRole: 'No Role',
    originalEstimate: 'Original Estimate',
    remaining: 'Remaining',
    remove: 'Remove',
    resource: 'Resource',
    resources: 'Resources',
    resourceCount: {
      count:
        '{count, plural, =0 {No Resources} one {# Resource} other {{count} Resources}}',
      plural: '{value} Resources'
    },
    role: 'Role',
    workRemaining: 'Work Remaining',
    changeResource: 'Change Resource',
    overAllocated: 'Overallocated'
  },
  taskNoResourcePopover: {
    message:
      'A resource needs to be selected in order to allocate their time to a task'
  },
  taskAssignments: {
    noRole: 'No Role',
    noTasksAssigned: 'No Tasks Assigned',
    rowOverAllocation:
      "There is not enough project allocation for all of this task's allocation.",
    resourceRowOverDistributed:
      'There is not enough project allocation for all of the task allocations'
  },
  taskAllocationChangeSnackbar: {
    limitedTimeToAllocationMessage: 'Limited to time allocated to the project.'
  },
  taskAllocationEditor: {
    aboveTaskEstimate: 'above task estimate',
    aboveEstimatedAtCompletion: 'above estimated at completion',
    allocatedToOtherTask: 'Allocated To Other Tasks',
    availableAllocation: 'Available Allocation',
    availableProject: 'Available {project}',
    belowTaskEstimate: 'below task estimate',
    belowEstimatedAtCompletion: 'below estimated at completion',
    details: 'Details',
    diffHours: '{value}h',
    endDate: 'End Date',
    hours: '{hours} h',
    hoursSuffix: 'h',
    noAllocationPrompt: 'Allocate this resource to the project to begin',
    noProjectAllocation: 'No Project Allocation',
    overAllocated: 'Overallocated',
    project: '(Project)',
    projectAllocation: 'Project Allocation',
    projectAllocationRemaining: 'Project Allocation Remaining',
    projectAllocationDateRange: 'Project Allocation: {startDate} - {endDate}',
    projectAllocationHours: '{allocationHours}h (Available {availableHours}h)',
    pleaseSelectStartDate: 'Please select a Start Date.',
    pleaseSelectEndDate: 'Please select a End Date.',
    remaining: 'Remaining',
    removeAllocatedTime: 'Remove Allocated Time',
    selectDatesPrompt: 'Select dates to see availability',
    setAllocation: 'Set Allocation',
    startDate: 'Start Date',
    taskAllocation: 'Task Allocation',
    thisTaskAllocation: 'This Task Allocation',
    resourceAvailability: 'Resource Availability',
    remainingProject: ' Remaining (Project)',
    totalAvailability: 'Total Availability'
  },
  removeTaskEstimateDialog: {
    allocationRemoveWarning: 'The time allocated to this task will be removed.',
    cancel: 'Cancel',
    message: 'Are you sure you want to remove this task assignment?',
    pastTimeWillRemainMessage: 'Time that has already entered will remain.',
    removeEstimate: 'Remove Assignment',
    resourceHasTimeAllocation:
      '{resourceName} has time allocated to this task.',
    title: 'Remove Assignment from:'
  },
  changeTaskResourceDialog: {
    title: 'Change Task Resource: ',
    cancel: 'Cancel',
    role: 'Role: ',
    noRole: 'No Role',
    estimate: 'Estimate',
    taskAllocation: 'Task Allocation',
    changeTaskResource: 'Change Task Resource',
    noAllocation: 'No Allocation Set',
    taskAllocationDateRange: '{startDate} - {endDate}.',
    resourceChange: 'The resource will be changed to {resourceDisplayText}.',
    noResourceAllocation:
      '{resourceDisplayText} can not be allocated to this task because they do not have any time allocated to this project.',
    noResourceAllocationHoursMessage:
      'The {allocationHours} hours of task allocation will be removed.',
    removeTaskAllocation: 'Remove the Task Allocation',
    dontAllocateMessage:
      'Change to the new resource, but do not allocate their time to the task.',
    confirmation: 'Are you sure you want to change this resource?',
    allocationChangeMessage:
      'The {allocationHours} hours of Task Allocation will be changed to the new resource.',
    hoursSuffix: 'h',
    existingResource: 'Existing Resource',
    newResource: 'New Resource',
    overAllocationOptions: {
      projectManagerOverAllocationAlertTitle:
        '{resourceDisplayText} does not have enough available time allocated to this project for ',
      projectManagerAllocateFullAmount:
        'Allocate all {hours} hours, using more time than is allocated to this project.',
      projectManagerAllocateWhatFits:
        'Allocate the {hours} hours available for this resource on the project.',
      resourceManagerOverAllocationAlertTitle:
        '{resourceDisplayText} does not have enough available time for ',
      resourceManagerOverAllocateFullAmount:
        'Allocate all {hours} hours, leaving the user overallocated.',
      resourceManagerAllocateWhatFits:
        'Allocate the {hours} hours available for this resource.',
      allocateFullAmount: 'Allocate Full Amount',
      allocateWhatFits: 'Allocate What Fits'
    }
  },
  removeTaskAllocationDialog: {
    cancel: 'Cancel',
    caption: 'This will only remove the time allocated to the task.',
    captionNote: 'Note: ',
    message: 'Are you sure you want to remove the time allocated to this task?',
    removeAllocatedTime: 'Remove Allocated Time',
    removeAssignmentLink: 'Remove the Assignment',
    removeAssignmentMessage: 'to completely remove this resource.',
    title: 'Remove Time Allocated to:'
  },
  checkMenu: {
    title: 'Select'
  },
  moreMenu: {
    title: 'Additional Options'
  },
  mobileMenu: {
    menu: 'Menu'
  },
  desktopMenu: {
    expand: 'Expand Menu',
    collapse: 'Collapse Menu',
    pin: 'Pin Menu'
  },
  billingTransactionTypeFacet: {
    title: 'Type'
  },
  clientFacet: {
    searchDropdownAriaLabel: 'Client dropdown'
  },
  revenueRecognitionPolicyFacet: {
    noRevenueRecognitionPolicy: 'None'
  },
  search: {
    title: 'Search',
    detailsAriaLabel: 'Search Details',
    placeholder: 'Search...',
    hasWords: 'Has words',
    user: 'User',
    resource: 'Resource',
    client: 'Client',
    clientManager: 'Client Manager',
    project: 'Project',
    portfolio: 'Portfolio',
    billingItemType: 'Type',
    revenueRecognitionPolicy: 'Revenue Recognition Policy',
    projectStatusPolicy: 'Project Status',
    program: 'Program',
    projectManager: 'Project Manager',
    projectCoManager: 'Project Co-Manager',
    location: 'Location',
    division: 'Division',
    costCenter: 'Cost Center',
    serviceCenter: 'Service Center',
    department: 'Department',
    resourcePool: 'Resource Pool(s)',
    employeeType: 'Employee Type',
    programManager: 'Program Manager',
    role: 'Role',
    tag: 'Tag',
    hasMore: 'More matches available. Search to refine results.',
    status: 'Status',
    syncStatus: 'Sync Status',
    sliderDescription: '{metric} Score: {val}',
    anyMetricScore: 'Any {metric} Score',
    value: 'Value',
    risk: 'Risk',
    investment: 'Investment',
    totalScore: 'Total Score',
    totalMinimum: 'Minimum',
    totalMaximum: 'Maximum',
    projectLink: 'Link Type'
  },
  syncStatus: {
    none: 'None',
    noSyncStatus: 'No Sync Status'
  },
  project: {
    projectManager: 'Project Manager',
    noProjectManager: 'No Project Manager',
    projectCoManager: 'Project Co-Manager',
    noProjectCoManager: 'No Project Co-Manager'
  },
  skill: {
    skill: 'Skill',
    noSkill: 'No Skill'
  },
  billingItemType: { search: 'BillingItem Type' },
  projectFacetDetail: {
    search: 'Projects',
    noProject: 'No Projects'
  },
  monthFacetDetails: {
    title: 'Month',
    clearMonthAriaLabel: 'Clear Month'
  },
  monthRangeFacetDetails: {
    title: 'Months',
    clearMonthAriaLabel: 'Clear Month',
    startMonth: 'Start Month',
    endMonth: 'End Month'
  },
  profileMenu: {
    title: 'Profile Menu'
  },
  notificationsMenu: {
    title: 'Notifications Menu',
    seeAll: 'See All',
    settings: 'Settings',
    markAllAsRead: 'Mark all as read'
  },
  notificationSettingsDialog: {
    title: 'Notification Settings',
    cancel: 'Cancel',
    save: 'Save'
  },
  notifications: {
    title: 'Notifications',
    noNotifications: 'No unread notifications'
  },
  taskStatus: {
    planned: 'Planned',
    actual: 'Actual',
    hours: 'Hours',
    cost: 'Cost',
    dates: 'Dates',
    additionalDays: '+{days} days',
    estimatedAtCompletion: 'Estimated at Completion',
    estimatedHoursAtCompletion: 'Estimated Hours at Completion',
    taskStatusDetails: 'Task Status Details',
    estimatedHours: 'Estimated Hours',
    plannedDates: 'Planned Dates',
    actualHours: 'Actual Hours',
    actualDates: 'Actual Dates'
  },
  taskStatusBadge: {
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    completed: 'Completed',
    parentTask: 'Parent Task'
  },
  importExportMenu: {
    title: 'Import Export Menu',
    options: {
      csvImport: 'CSV',
      csvExport: 'CSV',
      import: 'Import',
      export: 'Export',
      salesforceConnector: 'Salesforce Connector',
      enabled: 'Enabled',
      addUser: 'Add User',
      notEnabled: 'Not Enabled'
    },
    exportDialog: {
      loadingMessage: 'Your download will start automatically',
      csvExport: 'Generating CSV File...',
      failed: 'Unable to export, please try again'
    }
  },
  notificationAnnotations: {
    'project-manager.modification.assigned':
      'Notify when you are assigned as the project manager',
    'resource-request.modification.submitted':
      'Notify when a resource request is submitted',
    'resource-request.modification.rejected':
      'Notify when a resource request is rejected by a resource manager',
    'resource-request.modification.to-be-hired':
      'Notify when a resource manager indicates that a resource needs to be hired to fill a resource request',
    'resource-allocation.modification.proposed':
      'Notify when resources are proposed for a resource request by a resource manager',
    'resource-allocation.modification.rejected':
      'Notify when proposed resources are rejected by the project manager',
    'resource-allocation.modification.accepted':
      'Notify when proposed resources are accepted by the project manager Or when you are allocated to a project.',
    'resource-allocation.modification.accepted.urn:replicon:user-access-role:resource-manager':
      'Notify when proposed resources are accepted by the project manager',
    'resource-allocation.modification.accepted.urn:replicon:user-access-role:project-resource':
      'Notify when you are allocated to a project',
    'resource-allocation.modification.released':
      'Notify when resources are released by the project manager',
    'task-assignment.modification.assigned':
      'Notified when you are assigned to a task',
    'task.modification.status-changed':
      'Notified when assigned task status change',
    'project.modification.project-stage-changed':
      'Notify when a project you manage changes its status',
    disabled: 'Please contact your administrator to enable this notification.'
  },
  hasOptions: {
    flag: 'Flagged',
    comment: 'Has Comment',
    noDate: 'No Date'
  },
  qualityOptions: {
    great: 'Quality Great',
    good: 'Quality Good',
    neutral: 'Quality Neutral',
    bad: 'Quality Bad',
    horrible: 'Quality Horrible'
  },
  unsavedChangesPrompt: {
    title: 'Unsaved Changes',
    text: 'Leaving this page will discard your changes.',
    rescheduleText:
      'Would you like to save your changes before rescheduling this project?'
  },
  addResourceRequestButton: {
    label: 'Add Resource Request',
    shortLabel: 'Resource Request'
  },
  resourcing: {
    noResourceRequests: 'No resource requests',
    removeResourcingRequest: 'Remove Resourcing Request',
    noProjects: 'No Matching Resource Requests',
    noResourceAllocations: 'No resource allocations.'
  },
  resourceAllocationUserItem: {
    loadingValue: 'Loading {load}%',
    allocated: 'Allocated',
    hours: 'hours'
  },
  clearDataDialog: {
    title: 'Clear Data',
    content: 'Are your sure you want to clear all the data entered today?'
  },
  clearTaskEntryDataDialog: {
    multiRowMessage:
      'Are you sure you want to clear the information entered for this task?'
  },
  readOnlyTaskEntryDialog: {
    content:
      'This information is not editable here due to multiple timesheet rows populating it. Please go to your timesheet to edit this information.'
  },
  readOnlyResourceRequestRow: {
    showLess: 'Show Less',
    showMore: 'Show More'
  },
  resourceRequestItem: {
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    department: 'Department',
    employeeType: 'Employee Type',
    costCenter: 'Cost Center',
    comment: 'Comment',
    hideRequestDetails: 'Hide Request Details',
    showMoreDetails: 'Show Request Details',
    viewMoreDetails: 'View details for ',
    requested: 'Requested',
    requestedCostRate: 'Requested Cost Rate',
    totalRequestedCost: 'Total Requested Cost',
    costPerHour: '{cost}/hr: ',
    cost: '{cost}: ',
    multipleRequests: 'Multiple Requests',
    multipleResourceRequests: 'Multiple Resource Requests',
    resourceRequests: 'Resource Requests',
    viewMultipleResourceRequestDescription:
      'The following Resource Requests have contributed to this resource allocation'
  },
  resourceRequestDrawerTitle: {
    resourceRequest: ' Resource Request'
  },
  resourceRequestResourcePoolField: {
    resourcePool: '{count, plural, =1 {Resource Pool} other {Resource Pools}}'
  },
  resourceRequestDrawerDetails: {
    qualifications: 'Qualifications',
    importance: 'Importance',
    qualificationImportance: 'Qualification Importance',
    setImportance: 'Set Importance',
    noQualifications:
      'No qualifications have been set on this resource request.',
    role: 'Role',
    costRate: 'Cost Rate',
    startDate: 'Start Date',
    endDate: 'End Date',
    extendEndDate: 'Extend End Date?',
    overLoadParam: 'Over {load}% ',
    noOfResources: '# of Resources',
    loading: 'Loading',
    comment: 'Comment',
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    department: 'Department',
    employeeType: 'Employee Type',
    costCenter: 'Cost Center',
    noGroup: 'No {groupName}',
    anyGroup: 'Any {groupName}',
    anyrole: 'Any Role',
    anyskills: 'No Skill Requirements Set',
    anytags: 'No Tag Requirements Set',
    anyresourcePools: 'No Pool Requirements Set',
    anyrequestedCostRate: 'No Requested Cost Rate Set',
    anypreferredResources: 'No Preferred Resources Set',
    totalHours: 'Requested Hours',
    totalCost: 'Requested Cost',
    resourcePools: 'Resource Pools',
    allocatedTotalHours: 'Allocated Total Hours',
    allocatedTotalCost: 'Allocated Total Cost',
    totalRequestedHours: 'Total Requested Hours',
    requestedCostRate: 'Requested Cost Rate',
    totalRequestedCost: 'Total Requested Cost',
    totalAllocatedHours: 'Total Allocated Hours',
    totalAllocatedCost: 'Total Allocated Cost',
    from: 'From',
    to: 'To',
    hours: 'Hours',
    quantityError: 'Quantity is required',
    minValueForQuantity: 'Quantity must be greater than or equal to {value}',
    maxValueForQuantity: 'Quantity must be less than or equal to {value}',
    minValueForTotalHours: 'Requested Hours must be greater than {value}',
    loadError: 'Loading is required',
    minValueForLoad: 'Loading must be greater than {value}',
    maxValueForLoad: 'Loading must be less than or equal to {value}',
    requested: 'Requested: ',
    allocated: 'Allocated: ',
    reqHours: ' hours',
    allocatedDates: 'Allocated Dates',
    dateRangeError: 'The selected dates do not contain any work days',
    allocatedHours: 'Allocated Hours',
    allocatedCost: 'Allocated Cost',
    allocatedResources: 'Allocated Resources',
    noResourcesAllocated: 'No Resource Allocated',
    days: ' days',
    smartMatch: 'SmartMatch: ',
    groups: 'Group Requirements',
    skills: 'Skill Requirements',
    skillRating: 'Skill Rating',
    tags: 'Tag Requirements',
    noSkills: 'No skill requirements set',
    noGroups: 'No group requirements set',
    noTags: 'No tag requirements set',
    noTagsExpanded: 'No tag requirements set.',
    noPreferredResources: 'No Preferred Resources set',
    addPreferredResource: '+ Add Preferred Resource',
    preferredResources: 'Preferred Resources',
    skillsSet: {
      singular: '{count} Skill set',
      plural: '{count} Skills set'
    },
    preferredResourcesSet: {
      singular: '{value}',
      count:
        '{count, plural, =0 {No Resources Selected} one {# Resource Selected} other {# Resources Selected}}',
      plural: '{value} Resources Selected'
    },
    tagsSet: {
      singular: '{count} Tag set',
      plural: '{count} Tags set'
    },
    resourcePoolsSet: {
      singular: '{value}',
      count:
        '{count, plural, =0 {No Pools Selected} one {# Pool Selected} other {# Pools Selected}}',
      plural: '{value} Pools Selected'
    },
    weights: {
      NiceToHave: 'Nice to Have',
      Useful: 'Useful',
      Normal: 'Normal',
      Important: 'Important',
      Required: 'Required'
    },
    roleRequired: 'Please select a Role.',
    roleCostRequired: 'Please select a Cost Rate',
    skillsRequired: 'Please select some Skills.',
    groupRequired: 'Please select a {groupName}.',
    resourcePoolRequired: 'Please select a Resource Pool',
    preferredResourceRequired: 'Please select a Preferred Resource',
    setAsMyDefault: 'Set as my Default',
    requestedAmount: 'Requested Amount',
    allocatedResource: 'Allocated Resource'
  },
  taskAllocationActions: {
    removeTaskAssignment: 'Remove Task Assignment',
    removeAllocatedTime: 'Remove Allocated Time',
    assignTask: 'Assign Task'
  },
  taskBeatTabs: {
    accepted: 'Accepted',
    pending: 'Pending',
    pendingCount: 'Pending ({count})'
  },
  resourceRequestQuantityField: {
    quantity: 'Quantity'
  },
  resourceLoadingField: {
    loading: 'Loading',
    percentAria: 'Resource Percentage',
    minValueForLoad: 'Loading must be greater than {load}',
    adjustedLoading: '*Average loading, detailed adjustments have been made.',
    adjustedLoadingMessage:
      'Changing the base loading will override those changes.'
  },
  resourceAllocationActions: {
    proposeResources: 'Propose Resources',
    allocateResource: 'Allocate Resource',
    rejectRequest: 'Reject Request',
    recall: 'Recall',
    removeAllResources: 'Remove All Resources',
    toBeHired: 'To Be Hired',
    removeAllResourceAllocations: 'Remove All Resource Allocations',
    removeAllResourceAllocationContent:
      'Are you sure you want to remove all resources from this resource request?',
    removeAllResourcesAction: 'REMOVE ALL RESOURCES',
    proposeAllResources: 'Propose all Resources',
    rejectAllRequests: 'Reject all Requests',
    recallAllResources: 'Recall all Resources',
    markAllToBeHired: 'Mark all as To Be Hired',
    cancelRequestRejection: 'Cancel Request Rejection',
    cancelRejection: 'Cancel Rejection',
    ok: 'Ok',
    ariaDialog: 'Resource Allocation Dialog',
    completeAllPendingAllocation: 'Complete All Pending Allocation',
    complete: 'Complete Allocation'
  },
  resourceRequestActions: {
    more: 'more',
    save: 'Save',
    cancel: 'Cancel',
    removeResourceRequest: 'Remove Resource Request',
    removeResourceRequestMessage:
      'Are you sure you want to remove this resource request?',
    submitRequest: 'Submit Request',
    splitRequest: 'Split Request',
    removeRequest: 'Remove Request',
    unsubmitRequest: 'Unsubmit Request',
    duplicateRequest: 'Duplicate Request',
    acceptResources: 'Accept Resources',
    rejectResources: 'Reject Resources',
    rejectRequest: 'Reject Request',
    fulfillRequest: 'Fulfill Request',
    submitAllRequests: 'Submit All Requests',
    removeAllRequests: 'Remove All Requests',
    removeAllResourceRequests: 'Remove All Resource Requests',
    unsubmitAllRequests: 'Unsubmit All Requests',
    removeAllResourceRequestsMessage:
      'Are you sure you want to remove all resource request from this project?',
    releaseResource: 'Release Resource',
    releaseResources: 'Release Resources',
    acceptAllResources: 'Accept All Resources',
    rejectAllResources: 'Reject All Resources',
    reject: 'Reject',
    saveDraft: 'Save Draft',
    ok: 'Ok',
    expand: 'Expand',
    requestSubmitted: 'Request Submitted.',
    requestSaved: 'Request Saved.',
    resourcesProposed: 'Resources Proposed.',
    requestRejected: 'Request Rejected.',
    requestRecalled: 'Request Recalled.',
    requestToBeHired: 'Request marked as To Be Hired.',
    cancelRequestRejection: 'Request Rejection Cancelled.',
    cancelRequestToBeHired: 'Request no longer To Be Hired.',
    requestSplit: 'Request Split.',
    resourcesAccepted: 'Resources Accepted.',
    resourcesRejected: 'Resources Rejected.',
    requestUnSubmitted: 'Request Unsubmitted.',
    requestRemoved: 'Request Removed.',
    requestDuplicated: 'Request Duplicated.',
    completeAllocation: 'Complete Allocation',
    completeAllocationMessage: 'Resource allocation completed.',
    cancelAllToBeHired: 'Cancel all To Be Hired',
    viewResourceRequest: 'View Resource Request',
    proposeTopMatch: 'Propose Top Match',
    viewProject: 'View Project'
  },
  resourceRequestTitle: {
    anyRole: 'Any Role',
    role: 'Role'
  },
  resourceRequestDrawerReadonlyDetails: {
    role: 'Role',
    costRate: 'Cost Rate',
    noOfResources: '# of Resources',
    loading: 'Loading',
    dates: 'Dates',
    totalHours: 'Requested Hours',
    totalCost: 'Requested Cost',
    comment: 'Comment',
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    costCenter: 'Cost Center',
    department: 'Department',
    employeeType: 'Employee Type',
    skills: 'Skills',
    requestedCostRate: 'Requested Cost Rate',
    totalRequestedHours: 'Total Requested Hours',
    totalRequestedCost: 'Total Requested Cost'
  },
  resourceRequestItemStatusField: {
    draft: 'Draft',
    pending: 'Pending',
    proposed: 'Proposed',
    rejected: 'Request Rejected',
    complete: 'Complete',
    toBeHired: 'To Be Hired',
    allocationRejected: 'Resource Rejected',
    reqRejected: 'Req Rejected',
    resRejected: 'Res Rejected',
    reject: 'Rejected'
  },
  resourceRequestReadonlyItem: {
    noRole: 'No Role',
    loading: 'Loading',
    quantity: 'Quantity',
    comment: 'Comment',
    showUserDetails: 'Show User Details',
    averageLoading: '*Average loading, detailed adjustments have been made'
  },
  readOnlyResourceAllocationItem: {
    noResourcesAllocated: 'No Resources Allocated'
  },
  taskBurnDownChart: {
    hoursWorked: 'Total Work:',
    plannedCompletionDate: 'Planned Completion Date',
    hoursRemaining: 'Work Remaining:',
    completionLabel: 'Estimated At Completion:',
    workDoneAndRemaining: 'Total Work & Remaining vs Date',
    hours: 'Hours',
    date: 'Date',
    legendHoursWorked: 'Total Work',
    legendHoursRemaining: 'Work Remaining',
    legendOriginalEstimate: 'Original Estimate'
  },
  resourceAllocationChartHeaderRow: {
    projects: 'Projects',
    roles: 'Roles',
    requested: 'Requested',
    allocated: 'Allocated',
    totalHours: 'Total Hours',
    totalCost: 'Total Cost'
  },
  projectItem: {
    resourcePlan: 'Resource Plan'
  },
  rowTotalTooltip: {
    allocatedHours: 'Allocated Hours: ',
    plannedHours: 'Requested Hours: ',
    allocatedCost: 'Allocated Cost: ',
    plannedCost: 'Requested Cost: '
  },
  resourceAllocationProjectChart: {
    noMatchingProjects: 'No matching projects.',
    noResourceRequests: 'No Resource Requests'
  },
  resourceAllocationProjectRow: {
    showMore: 'Show More',
    showLess: 'Show Less',
    noRole: 'No Role',
    otherRequests: 'Other Requests'
  },
  resourceAllocationUserChartDialog: {
    resource: 'Resource',
    columnOne: 'Column 1',
    columnTwo: 'Column 2',
    columnThree: 'Column 3',
    columnFour: 'Column 4',
    smartMatch: 'SmartMatch',
    smartMatchExcludingCost: 'SmartMatch (Without Cost)',
    groups: 'Groups',
    customFields: 'Custom Fields',
    tags: 'Tags'
  },
  resourceAllocationUserChartHeaderRow: {
    resources: 'Resources'
  },
  resourceRequestChartHeaderRow: {
    resourceRequest: 'Resource Requests'
  },
  resourceAllocationUserRow: {
    showResourceDetails: 'Show Resource Details'
  },
  resourceAllocationUserChart: {
    noResources: 'No Resources',
    allocation: 'Allocation',
    overAllocated: 'Over Allocated',
    notWorking: 'Not Working',
    showLess: 'Show Less',
    timeOff: 'Time Off',
    holidays: 'Holidays'
  },
  mergeResourceRequestDialog: {
    mergeResourceTitle:
      '{count, plural, =1 {This resource has} other {Some resources have}} already been allocated',
    mergeResourceDescription:
      '{count, plural, one {When you accept this resource,} other {When you accept these resources,}} the new hours will be added to the existing allocation in this project',
    acceptAndMerge: 'Accept & Merge'
  },
  resourcingPageTabs: {
    resourcingPool: 'Resource Pool'
  },
  projectReport: {
    add: 'Add',
    title: 'Task Summary',
    reportDate: 'Task Summary Date',
    hoursWorked: 'Hours Worked',
    hours: 'Hours',
    cost: 'Cost',
    estimatedHoursAtCompletion: 'Estimated Hours At Completion',
    estimatedCostAtCompletion: 'Estimated Cost at Completion',
    estimatedEndDate: 'Estimated End Date',
    score: 'Score',
    comment: 'Comment',
    noReports: {
      title: 'Keep Track of Your Project',
      message:
        'Task summaries let you submit updates on how your project is progressing.',
      message2:
        'Keep stakeholders up to date on the health of this project by reporting on: estimated hours, estimated cost, and estimated completion date.',
      report: 'Task Summary'
    },
    warning:
      'You have an unsaved Project Report.\n\nAre you sure you want to leave this page?',
    error: 'An error occurred submitting the report, please try again.'
  },
  ganttScale: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year'
  },
  periodHoursFormatter: {
    loadingHours: 'Detailed adjustments have been made.',
    loadingHoursDetailed:
      'Changing the period’s total hours will override those adjustments.'
  },
  periodPercentageFormatter: {
    loadingPercentage: 'Average Loading, detailed adjustments have been made.',
    loadingPercentageDetailed:
      'Changing the period’s loading will override those adjustments.'
  },
  timeAndExpenseTab: {
    noDataForPeriod:
      'There is no time and expense data entered for the selected period.',
    noTimeDataForPeriod:
      'There is no time data entered for the selected period.',
    error:
      'Data for the provided date range is too large, please narrow your search criteria or date range.',
    csvFileName: '-time-and-expense.csv',
    csvAriaLabel: 'Download CSV',
    settings: 'Settings',
    rows: 'Set Rows',
    groupings: 'Set Column Grouping',
    columns: 'Set Columns Data',
    updateView: 'Update View',
    setting: {
      rows: 'Dimensions',
      columns: 'Values',
      save: 'Save',
      cancel: 'Cancel'
    },
    total: 'Total',
    rowTotals: 'Row Totals'
  },
  timeAndExpenseSettings: {
    client: 'Client',
    program: 'Program',
    task: 'Task',
    projectname: 'Project',
    username: 'User',
    taskname: 'Task',
    clientname: 'Client',
    entrydate: 'Entry Date',
    billingitemtype: 'Entry Type',
    billable: 'Billable',
    invoicestatus: 'Invoice Status',
    billingratename: 'Billing Rate',
    billingcontractclause: 'Billing Contract Clause',
    entrydate__day: 'Entry Date: Daily',
    entrydate__week: 'Entry Date: Weekly',
    entrydate__month: 'Entry Date: Monthly',
    entrydate__quarter: 'Entry Date: Quarterly',
    entrydate__year: 'Entry Date: Yearly',
    billablehours: 'Hours',
    nonbillablehours: 'Non-Billable Hours',
    resourcecostamount: 'Actual Resource Cost',
    costamount: 'Cost',
    expenseamount: 'Expense',
    billableamount: 'Billing'
  },
  timeAndExpenseSettingsFF: {
    expenseamount: 'Actual Expenses',
    resourcecostamount: 'Actual Resource Cost',
    costamount: 'Actual Cost'
  },
  createTaskForm: {
    nameRequired: 'Please enter a name',
    nameExceedsMaxLength: 'Name cannot exceed {max} characters',
    codeExceedsMaxLength: 'Task Code cannot exceed {max} characters',
    estimatedHoursExceedsMax: 'Estimated Hours cannot be more than {max}',
    roleOrResourceRequired: 'Please select a role or resource'
  },
  dateButton: {
    next: 'Next',
    previous: 'Previous'
  },
  projectBasicInfo: {
    pmApprovalRequired: 'Project Manager Approval Required',
    nameRequired: 'Please enter a name',
    codeRequired: 'Please enter a code',
    projectNameExceedsMaxLength: 'Project name cannot exceed 255 characters',
    projectCodeExceedsMaxLength: 'Project code cannot exceed {max} characters',
    projectDescriptionExceedsMaxLength:
      'Description cannot exceed 3000 characters',
    descriptionExceedsMaxLength: 'Description cannot exceed 255 characters',
    codeExceedsMaxLength: 'Code cannot exceed 50 characters',
    code: 'Code',
    startDate: 'Start Date',
    endDate: 'End Date',
    description: 'Description',
    slackChannelValidationError:
      'Channel Name can contain lowercase letters, numbers, hyphens and underscores. It can’t be longer than 80 characters',
    openSlackChannel: 'Open Slack Channel'
  },
  quickAllocation: {
    addToProject: 'Add to Project',
    showMore: 'Show More',
    addProject: 'Add Project',
    createNewProject: 'Create New Project',
    addRole: 'Add  Role',
    showLess: 'Show Less,',
    more: 'more',
    noRole: 'No Role',
    resource: 'Resource',
    of: 'of',
    noResources: 'No Resources',
    projects: 'Projects',
    project: 'Project',
    projectRole: 'Project Role',
    noProjectAllocated: 'No projects have been allocated',
    demand: 'Demand',
    timeOff: 'Time Off',
    actualsDialog: {
      scheduledHours: '{scheduledHours} Scheduled Hours',
      allocated: 'Allocated:',
      actual: 'Actual:',
      variation: 'Variation:',
      difference: 'Difference:'
    }
  },
  gantt: {
    new_task: 'New task',
    icon_save: 'Save',
    icon_cancel: 'Cancel',
    icon_details: 'Details',
    icon_edit: 'Edit',
    icon_delete: 'Delete',
    confirm_deleting: 'Task will be deleted permanently, are you sure?',
    section_description: 'Description',
    section_time: 'Time period',
    section_type: 'Type',
    column_wbs: 'WBS',
    column_text: 'Task name',
    column_start_date: 'Start time',
    column_duration: 'Duration',
    link: 'Link',
    confirm_link_deleting: 'will be deleted',
    link_start: ' (start)',
    link_end: ' (end)',
    type_task: 'Task',
    type_project: 'Project',
    type_milestone: 'Milestone',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Week',
    months: 'Months',
    years: 'Years',
    message_ok: 'OK',
    message_cancel: 'Cancel',
    section_constraint: 'Constraint',
    constraint_type: 'Constraint type',
    constraint_date: 'Constraint date',
    asap: 'As Soon As Possible',
    alap: 'As Late As Possible',
    snet: 'Start No Earlier Than',
    snlt: 'Start No Later Than',
    fnet: 'Finish No Earlier Than',
    fnlt: 'Finish No Later Than',
    mso: 'Must Start On',
    mfo: 'Must Finish On',
    resources_filter_placeholder: 'type to filter',
    resources_filter_label: 'hide empty',
    showMore: 'Show More...',
    loading: 'Loading...'
  },
  templateDropdown: {
    projectTemplate: 'Project Template',
    noTemplate: 'No Template',
    loading: 'Loading'
  },
  addProject: {
    addProject: 'Add Project',
    code: 'Code',
    cancel: 'Cancel',
    projectTemplate: 'Project Template',
    name: 'Name',
    startDate: 'Planned Start Date',
    endDate: 'Planned End Date',
    client: 'Client',
    creatableClient: 'Client {label}',
    projectManagerReference: 'Project Manager',
    budgetHours: 'Budget Hours',
    totalEstimatedContract: 'Total Contract Value',
    budgetedCost: 'Budget Cost',
    projectManagementType: 'Project Type',
    enterProjectNameErrorMsg: 'Please enter a name',
    enterProjectCodeErrorMsg: 'Please enter code',
    customFieldRequired: '{name} is required',
    customFieldNumericMinValue: '{name} cannot be less than {value}',
    customFieldNumericMaxValue: '{name} cannot be more than {value}',
    customFieldTextLength: '{name} cannot exceed {value} characters',
    minDate: '{name} must be greater than or equal to {value}',
    maxDate: '{name} must be less than or equal to {value}',
    billingType: 'Billing Type',
    billingContract: 'Billing Contract',
    basicBilling: 'Basic Billing',
    'urn:replicon:billing-type:fixed-bid': 'Fixed Bid',
    'urn:replicon:billing-type:time-and-material': 'Time and Material',
    'urn:replicon:billing-type:non-billable': 'Non-Billable',
    clientIsRequired: 'Please select a client',
    portfolioIsRequired: 'Please select a portfolio',
    projectTemplateIsRequired: 'Please select a template',
    programIsRequired: 'Please select a program',
    addNewProject: 'Add New Project',
    existingProjects: 'Existing Projects',
    addXProjects: 'Add {value} Projects',
    submitError: 'An unexpected error has occurred. Please try again later.',
    projectsHasProgram: '1 project already has a program',
    projectsHasPortfolio: '1 project already has a portfolio',
    xProjectsHasProgram: '{value} project(s) already have a program',
    xProjectsHasPortfolio: '{value} project(s) already have a portfolio',
    moveToProgramConfirmation:
      'Are you sure you want to move these projects to {program}?',
    moveToPortfolioConfirmation:
      'Are you sure you want to move these projects to {portfolio}?',
    moveProjects: 'Move Projects',
    addNewProjectToProgram: 'Add Project to: {program}',
    addNewProjectToClient: 'Add Project to: {client}',
    program: 'Program',
    allowEntryWithoutTasks: 'Allow Entry without Tasks'
  },
  resourceAllocationChartTabs: {
    pending: 'Pending {count}',
    proposed: 'Proposed {count}',
    toBeHired: 'To Be Hired {count}',
    all: 'All'
  },
  statusFacetDetails: {
    title: 'Status'
  },
  projectsTabs: {
    managed: 'Managed Projects',
    nonManaged: 'UnManaged Projects'
  },
  fileWebAddressDialog: {
    uploadFile: 'Upload File',
    webAddress: 'Web Address (URL)',
    dragFileHere: 'Drag file here',
    or: 'or',
    chooseFileToUpload: 'Choose File To Upload',
    maximumFileSize: 'Maximum file size 5MB',
    exceededMaximumFileSizeError: 'File exceeded the maximum file size of 5MB',
    invalidFileTypeError:
      'Invalid file format (Formats allowed: .txt, .jpg, .png, .pdf, .tiff, .html, .doc, .docx, .bmp, .gif, .aac, .avi, .bmp, .eml, .heic, .heif, .jpeg, .m4a, .m4v, .mkv, .mov, .mp3, .mp4, .mpe, .mpeg, .mpg, .odp, .ods, .odt, .oga, .ogg, .ogm, .ogv, .ppt, .pptx, .rtf, .svg, .tif, .weba, .webm, .webp, .xls, .xlsx)',
    invalidAvatarFileTypeError:
      'Invalid file format (Formats allowed:  .bmp, .heic, .heif, .jpeg, .jpg, .png, .tif, .tiff, .webp)',
    pasteAUrlHere: 'Paste a URL here',
    name: 'Name',
    invalidUrl: 'The URL entered is not valid.',
    uploading: 'Uploading'
  },
  projectTypes: {
    managed: 'Managed',
    unmanaged: 'Unmanaged',
    title: 'Project Type'
  },
  mobileResourcePlanning: {
    noPlanning: 'No resource have been allocated to this project',
    totalHours: 'Total Hours',
    totalCost: 'Total Cost',
    requestingResource: 'Requesting Resource',
    cancel: 'Cancel',
    addRequest: 'Add Request',
    hours: 'Hours',
    cost: 'Cost',
    resourceRequests: 'Resource Requests',
    noResourceAllocation: 'No Resource Allocation',
    resources: 'Resources',
    allocatedHours: 'Allocated Hours',
    requestedHours: 'Requested Hours',
    allocatedCost: 'Allocated Cost',
    requestedCost: 'Requested Cost',
    totalAllocatedHours: 'Total Allocated Hours',
    totalRequestedHours: 'Total Requested Hours',
    totalAllocatedCost: 'Total Allocated Cost',
    totalRequestedCost: 'Total Requested Cost',
    anyRole: 'Any Role',
    noRole: 'No Role Selected'
  },
  settingsPage: {
    tabs: {
      notifications: 'Notifications'
    },
    notifications: {
      notificationSubscriptions: 'Notification Subscriptions',
      event: 'Event',
      web: 'Web',
      email: 'Email',
      mobilePush: 'Mobile Push'
    },
    notificationEventNames: {
      'resource-request.modification.submitted': 'Resource Request Submitted',
      'resource-request.modification.rejected': 'Resource Request Rejected',
      'resource-request.modification.to-be-hired': 'Resource to be Hired',
      'resource-allocation.modification.proposed': 'Resource Proposed',
      'resource-allocation.modification.rejected': 'Resource Rejected',
      'resource-allocation.modification.accepted': 'Resource Accepted',
      'resource-allocation.modification.released': 'Resource Released',
      'task-assignment.modification.assigned': 'Task Assigned',
      'task.modification.status-changed': 'Task Status Changed',
      'project.modification.project-stage-changed': 'Project Status Changed'
    }
  },
  roleFormatter: {
    selectARole: 'Select a Role',
    noRole: 'No Role'
  },
  locationFormatter: {
    location: 'Location'
  },
  costCenterFormatter: {
    costCenter: 'Cost Center'
  },
  divisionFormatter: {
    division: 'Division'
  },
  serviceCenterFormatter: {
    serviceCenter: 'Service Center'
  },
  departmentFormatter: {
    department: 'Department'
  },
  employeeTypeFormatter: {
    employeeType: 'Employee Type'
  },
  taskStatusCard: {
    taskBeat: 'SmartBeats',
    timesheet: 'Timesheet'
  },
  groupFormatter: {
    noGroup: 'No {group}',
    selectAGroup: 'Select a {group}'
  },
  groupField: {
    none: 'None'
  },
  groupsDropdown: {
    noGroup: 'No {group}',
    loading: 'Loading...'
  },
  serviceCenterDropdown: {
    serviceCenter: 'Service Center',
    noServiceCenter: 'No Service Center'
  },
  divisionDropdown: {
    division: 'Division',
    noDivision: 'No Divison'
  },
  costCenterDropdown: {
    costCenter: 'Cost Center',
    noCostCenter: 'No Cost Center'
  },
  departmentDropdown: {
    department: 'Department',
    noDepartment: 'No Department'
  },
  employeeTypeDropdown: {
    employeeType: 'Employee Type',
    noEmployeeType: 'No Employee Type'
  },
  resourceRequestCard: {
    skillRequirement: '{count} Skill Requirement',
    skillRequirements: '{count} Skill Requirements',
    skills: 'Skills',
    tags: 'Tags',
    comment: 'Comment',
    tagRequirement: '{count} Tag Requirement',
    tagRequirements: '{count} Tag Requirements',
    resourcePools: 'Resource Pools',
    resourcePoolRequirements:
      '{count, plural, one {# Resource Pool} other {# Resource Pools}}',
    preferredResources: 'Preferred Resources',
    preferredResourceRequirements: '{count} Preferred Resources',
    displayText: '{displayText}'
  },
  simpleDropdown: {
    none: 'None'
  },
  billingEntryAmountType: {
    bill: 'Bill',
    creditMemo: 'Credit Memo',
    payment: 'Payment'
  },
  addDialog: {
    addBill: 'Add Bill',
    addPayment: 'Add Payment',
    addRefund: 'Add Refund',
    addCredit: 'Add Credit',
    addCreditMemo: 'Add Credit Memo',
    balance: 'Balance:',
    deleteBill: 'Delete Bill',
    deletePayment: 'Delete Payment',
    deleteCredit: 'Delete Credit',
    viewBill: 'View Bill',
    viewPayment: 'View Payment',
    viewCreditPayment: 'View Credit Memo',
    removeBill: 'Remove Bill',
    removeBillInfo: 'Are you sure you want to remove this bill?',
    removePayment: 'Remove Payment',
    removePaymentInfo: 'Are you sure you want to remove this payment?',
    removeCreditMemo: 'Remove Credit Memo',
    removeCreditMemoInfo: 'Are you sure you want to remove this credit memo?',
    save: 'Save',
    cancel: 'Cancel',
    ok: 'Ok'
  },
  addBillingMenuButton: {
    add: 'Add',
    addBill: 'ADD BILL',
    addPayment: 'ADD PAYMENT',
    addCredit: 'ADD CREDIT',
    bill: 'Bill',
    creditMemo: 'Credit Memo',
    payment: 'Payment',
    addBillButton: 'Add Bill',
    addCreditMemoButton: 'Add CreditMemo',
    addBillPaymentButton: 'Add Payment'
  },

  billingToolbar: {
    search: {
      searchText: 'Search Filter',
      expand: 'Search Filter options',
      label: 'Billing transactions search bar'
    }
  },

  addLineItemDialogToolbar: {
    search: {
      searchText: 'Search Filter',
      expand: 'Search Filter Options',
      label: 'Add line items dialog search bar'
    }
  },

  billingTransactionRows: {
    bills: {
      actionColumn: 'Print or Email Invoice'
    }
  },

  addProjectLineItemButton: {
    project: 'Project',
    noProject: 'No Project'
  },
  customLineItem: {
    project: 'Project',
    amount: 'Amount',
    unit: 'Unit',
    description: 'Description',
    label: 'Label',
    date: 'Date',
    rate: 'Rate'
  },
  addBillingButton: {
    addBillButton: 'Add Bill Button',
    addCreditMemoButton: 'Add CreditMemo Button',
    addBillPaymentButton: 'Add Payment Button'
  },
  billDetails: {
    bill: 'Bill',
    currencyAriaLabel: 'Bill Currency',
    clientAriaLabel: 'Client',
    editBill: 'Edit Bill',
    drawerTitle: 'Bill: {displayId}',
    client: 'Client',
    billAmount: 'Bill Amount',
    taxProfile: 'Tax Profile',
    syncStatus: 'Sync Status',
    invoiceTemplate: 'Invoice Template',
    id: 'ID',
    referenceNumber: 'Reference #',
    description: 'Description',
    issueDate: 'Issue Date',
    paymentTerm: 'Payment Term',
    dueDate: 'Due Date',
    project: 'Project',
    poNumber: 'PO Number',
    Period: 'Period',
    startDate: 'Start Date',
    endDate: 'End Date',
    billCurrency: 'Currency',
    notesForCustomer: 'Notes for Customer',
    internalNotes: 'Internal Notes (Not visible to client)',
    showComments: 'Show Comments on the Invoice',
    billingAddress: 'Billing Address',
    net: 'Net',
    lineItems: 'Line Items',
    lineItem: 'Line Item',
    amount: 'Amount',
    creditMemos: 'Credit Memos',
    emailInvoice: 'Email Invoice',
    revertToDraft: 'Revert to Draft',
    void: 'Void Bill',
    noAssociatedCreditMemos: 'No credit memos have been applied to this bill.',
    payment: 'Payment',
    payments: 'Payments',
    printInvoice: 'Print Invoice',
    noAssociatedPayments: 'No payments have been applied to this bill.',
    refund: 'Refund',
    refunds: 'Refunds',
    noAssociatedRefunds: 'No refunds have been applied to this bill.',
    total: 'Total',
    subTotal: 'Subtotal',
    voidReason: 'Void Reason:',
    validations: {
      issueDateRequired: 'Please enter an Issue Date',
      issueDateMaxLimit: 'Issue Date must be on or before Due Date',
      periodValue: 'Period must have a valid start and end dates',
      dueDateRequired: 'Please enter a Due Date',
      dueDateMinLimit: 'Due Date must be on or after Issue Date',
      billAmountExceded: 'Bill Amount exceeds max allowed value',
      descriptionExceedsMaxLength: 'Description cannot exceed 255 characters',
      internalNotesExceedsMaxLength:
        'Notes (Internal) cannot exceed 255 characters',
      poNumberExceedsMaxLength: 'PO Number cannot exceed 255 characters',
      referenceNumberIsRequired: 'Please enter Reference #',
      referenceNumberExceedsMaxLength:
        'Reference # cannot exceed 255 characters',
      clientIsRequired: 'Please select a client',
      minimumBillAmount: 'Please enter an amount',
      duplicateDisplayIdError:
        'Reference# should be unique. A Transaction already exists with the specified Reference#.',
      duplicateReferenceNumberErrorForEditableField:
        'Reference# should be unique. A Transaction already exists with the specified Reference#.',
      duplicateReferenceNumberErrorForNonEditableField:
        'Reference# should be unique. A Transaction already exists with the specified Reference#. Please cancel and create again.'
    },
    removeBill: 'Remove Bill',
    lastLineItem: 'This is the last line item on this bill.',
    deleteBill: 'Delete Bill',
    permissionWarning:
      'You have permission to see bills associated with your projects.',
    permissionRemovedWarning:
      'Once this line item is removed you will not be able to access this bill.',
    deleteDialog: {
      removeBill: 'Remove Bill',
      description: 'Are you sure you want to remove this bill?'
    },
    syncDialog: {
      syncBill: 'Sync Bill',
      details: 'Syncing this bill will change the status to issued.',
      description: 'Are you sure?',
      cancel: 'Cancel',
      actionButton: 'Sync Bill'
    },
    printDialog: {
      printBill: 'Print Bill',
      details: 'Printing this bill will change the status to issued.',
      description: 'Are you sure?',
      cancel: 'Cancel',
      actionButton: 'Print Bill'
    },
    emailDialog: {
      emailBill: 'Email Bill',
      details: 'Emailing this bill will change the status to issued.',
      description: 'Are you sure?',
      cancel: 'Cancel',
      actionButton: 'Email Bill'
    },
    creditMemo: 'Credit Memo',
    balance: 'Balance',
    noLineItem: 'No line item available.',
    removeLineItem: 'Remove Line Item',
    removeCredit: 'Remove Credit',
    removePayment: 'Remove Payment',
    removeRefund: 'Remove Refund',
    removeLineItemMessage:
      'Are you sure you want to remove line item of {amount} for {project}?',
    removeLineItemMessageForNoProject:
      'Are you sure you want to remove line item of {amount}?',
    removeCreditMessage:
      'Are you sure you want to remove the credit of {amount}?',
    removePaymentMessage:
      'Are you sure you want to remove the payment of {amount}?',
    removeRefundMessage:
      'Are you sure you want to remove the refund of {amount}?',
    availableBillSummary: {
      message: '{amount} available to bill from {projectCount} projects'
    },
    availableBillSummaryMessage: {
      message: '{amount} available to bill from {projectCount} projects'
    },
    currencyChangeConfirmationDialog: {
      header: 'Change Currency',
      actionButton: 'Change Currency',
      confirmationMessage:
        'Are you sure you want to change the currency of this bill from {sourceCurrency} to {destinationCurrecy}?',
      lineItemsConfirmation:
        'All line items will be recreated in {currency}. Any edits made to them will be lost.'
    },
    paymentCurrency: 'Payment Currency',
    creditMemoCurrency: 'Credit Memo Currency',
    one: '1',
    ok: 'Ok',
    revertDescription: 'Are you sure you want to revert?',
    allocatedAmount: 'Allocated Amount:',
    draftSubStatus: 'Draft Sub Status'
  },
  creditMemoDetails: {
    creditMemo: 'Credit Memo',
    editCreditMemo: 'Edit Credit Memo',
    creditInfo: {
      currencyAriaLabel: 'Credit Currency',
      clientAriaLabel: 'Client Dropdown'
    },
    associatedBills: {
      amountAriaLabel: 'Allocated Amount in CreditMemo Currency'
    },
    drawerTitle: 'Credit Memo: {displayId}',
    client: 'Client',
    referenceNumber: 'Reference #',
    description: 'Description',
    creditDate: 'Credit Date',
    amount: 'Amount',
    currency: 'Currency',
    validations: {
      creditDateRequired: 'Please enter a Credit Date',
      descriptionExceedsMaxLength: 'Description cannot exceed 255 characters',
      referenceNumberIsRequired: 'Please enter Reference #',
      referenceNumberExceedsMaxLength:
        'Reference # cannot exceed 255 characters',
      clientIsRequired: 'Please select a client',
      allocatedAmountError: 'Must be less than or equal to balance',
      allocatedBillAmountError: 'Must be greater than Zero',
      totalAllocatedAmountExceedsBalanceError:
        'Total allocation must be less than or equal to total credit balance'
    },
    removeCreditMemo: 'Remove Credit Memo',
    lastLineItem: 'This is the last line item on this credit memo.',
    permissionWarning:
      'You have permission to see bills associated with your projects.',
    permissionRemovedWarning:
      'Once this line item is removed you will not be able to access this credit memo.',
    deleteCreditMemo: 'Delete Credit Memo',
    deleteDialog: {
      removeCreditMemo: 'Remove Credit Memo',
      description: 'Are you sure you want to remove this credit memo?'
    },
    credits: 'Credits',
    outstandingBills: 'Outstanding Bills',
    allocatedAmount: 'Allocated Amount:',
    noOutstandingBills: 'This client does not have any outstanding bills.',
    selectClient: 'Please select a client.',
    duplicateReferenceNo:
      'Reference# should be unique. A Transaction already exists with the specified Reference#.',
    removeLineItem: 'Remove Credit',
    removeLineItemMessageForNoProject:
      'Are you sure you want to remove credit of {amount}?',
    removeLineItemMessage:
      'Are you sure you want to remove credit of {amount} for {project}?',
    noLineItem: 'No credit available.'
  },
  associatedBillCredits: {
    referenceNumber: 'Reference #',
    date: 'Date',
    amount: 'Amount',
    total: 'Total',
    showMoreBills: 'Show More Bills'
  },
  associatedBills: {
    allocatedBillAmountAriaLabel: 'Allocated Amount in Bill Currency',
    buttonAriaLabel: 'Edit Button'
  },
  billPaymentDetails: {
    payment: 'Payment',
    editPayment: 'Edit Payment',
    editRefund: 'Edit Refund',
    paymentInfo: {
      clientAriaLabel: 'Client Dropdown',
      currencyAriaLabel: 'Payment Currency'
    },
    associatedBills: {
      paymentAmountAriaLabel: 'Allocated Amount in Payment Currency'
    },
    drawerTitle: 'Payment: {displayId}',
    refundDrawerTitle: 'Refund: {displayId}',
    client: 'Client',
    referenceNumber: 'Reference #',
    description: 'Description',
    paymentDate: 'Payment Date',
    paymentMethod: 'Payment Method',
    amount: 'Amount Received',
    validations: {
      paymentDateRequired: 'Please enter a Payment Date',
      paymentAmountRequired: 'Please enter a Payment Amount',
      descriptionExceedsMaxLength: 'Description cannot exceed 255 characters',
      referenceNumberIsRequired: 'Please enter Reference #',
      referenceNumberExceedsMaxLength:
        'Reference # cannot exceed 255 characters',
      allocatedAmountError: 'Must be less than or equal to balance',
      allocatedBillAmountError: 'Must be greater than Zero',
      refundAllocatedBillAmountError: 'Must be less than Zero',
      totalAllocatedAmountExceedsBalanceError:
        'Total allocation must be less than or equal to total payment amount'
    },
    deletePayment: 'Delete Payment',
    deleteDialog: {
      removePayment: 'Remove Payment',
      description: 'Are you sure you want to remove this payment?',
      removeRefund: 'Remove Refund',
      refundDescription: 'Are you sure you want to remove this refund?'
    },
    deleteRefund: 'Delete Refund',
    outstandingBills: 'Outstanding Bills',
    allocatedAmount: 'Allocated Amount:',
    selectClient: 'Please select a client.',
    noOutstandingBills: 'This client does not have any outstanding bills.',
    duplicateReferenceNo:
      'Reference# should be unique. A Transaction already exists with the specified Reference#.'
  },
  lineItemsTable: {
    project: 'Project',
    amount: 'Amount',
    total: 'Total',
    subTotal: 'Subtotal',
    lineItem: {
      deleteLineItemAriaLabel: 'Delete Item',
      amountAriaLabel: 'Amount'
    },
    task: 'Task',
    resource: 'Resource',
    billingType: 'Billing Type',
    type: 'Type',
    billingContractScriptName: 'Contract Clause',
    units: 'Units',
    rate: 'Rate',
    description: 'Description',
    timesheetPeriod: 'Timesheet Period',
    payCode: 'Paycode',
    role: 'Role',
    expenseCode: 'Expense Code'
  },
  billLineItemOptions: {
    USER: 'Resource',
    PROJECT: 'Project',
    TASK: 'Task',
    ITEMTYPE: 'Billing Type',
    RATE: 'Rate',
    QUANTITY: 'Quantity',
    UNIT: 'Units',
    BILLING_CONTRACT_CLAUSE: 'Contract Clause',
    TIMESHEET_PERIOD: 'Timesheet Period',
    PAYCODE: 'Paycode',
    ROLE: 'Role',
    EXPENSE_CODE: 'Expense Code'
  },
  taxLineItems: {
    total: 'Total'
  },
  addLineItemDialog: {
    title: 'Add Line Item',
    noUser: 'No User',
    noTask: 'No Task',
    showLineItemsBy: 'Show Line Items By:',
    noMatchingItem: 'No matching Available to Bill data found.',
    addLineItems: 'Add Line Items',
    createBill: 'Create Bill',
    createAndViewBill: 'Create & View Bill',
    less: 'Less',
    more: 'More',
    availableToBill: 'Available To Bill',
    custom: 'Custom',
    billingItemGroup: { groupBy: 'Group By' },
    dialogBox: 'Add Line Item Dialog',
    noExpenseCode: 'No Expense Code',
    internalServiceError:
      'Too many billing line items. Try using search to refine your results.',
    unknownError: 'Unknown Error, please try again.'
  },
  billingItemTable: {
    project: 'Project',
    amount: 'Amount',
    originalAmount: 'Amount Original Currency',
    originalCurrency: 'Original Currency',
    billCurrency: 'Bill Currency†',
    billCurrencyNote: 'Estimated using your current currency conversion rates.',
    rate: 'Rate',
    units: 'Units',
    month: 'Month',
    task: 'Task',
    expenseCode: 'Expense Code',
    resource: 'Resource',
    billingRate: 'Billing Rate',
    itemType: 'Billing Type',
    type: 'Type',
    contractClause: 'Contract Clause',
    billingItem: {
      select: 'Select Row',
      selectAll: 'Select All Rows'
    },
    timesheetPeriod: 'Timesheet Period',
    payCode: 'Paycode',
    role: 'Role'
  },
  itemTypeLabels: {
    fixedBid: 'Fixed Bid',
    timesheet: 'Timesheet',
    expense: 'Expense',
    noData: 'No Type'
  },
  outstandingBillsTable: {
    referenceNumber: 'Reference #',
    dueDate: 'Due Date',
    period: 'Period',
    balance: 'Balance',
    amount: 'Amount',
    total: 'Total',
    billCurrency: 'Bill Currency',
    one: '1'
  },
  billingOverviewPeriodDropdown: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year'
  },
  revenueRecognition: {
    title: 'Revenue Recognition',
    schedule: 'Schedule',
    total: 'Total',
    calculate: 'Calculate',
    open: 'Open',
    closed: 'Closed',
    close: 'Close',
    reopen: 'Reopen',
    remove: 'Remove',
    cancel: 'Cancel',
    entries: 'Entries',
    monthClosure: {
      closing: 'Closing {month}',
      confirmationMessage:
        'Are you sure you want to close the book for this month?',
      cancel: 'CANCEL',
      closeTheMonth: 'CLOSE THE MONTH',
      refresh: 'Refresh',
      lastRecalculatedInfo: 'Data is {days} days old -',
      close: 'Close',
      calculating: 'Calculating',
      closingNotAllowed:
        'You can not close this month, because the\npast months are still open.\n\nTo be able to close, you have to close the past\nmonths first.',
      reopenNotAllowed:
        'You can not reopen this month, because the\nfuture months are closed.\n\nTo be able to reopen, you have to reopen the\nfuture months first.'
    },
    monthReopen: {
      reopening: 'Reopen Closed Month',
      reopenTheMonth: 'REOPEN',
      warning:
        'Reopening this month will clear off the revenue data for the subsequent months.',
      confirmationMessage: 'Are you sure you want to Reopen?',
      reopeningDialog: 'Reopen Closed Month Dialog'
    },
    selectMonthMessage:
      'Define the month you want to start Recognizing Revenue from.\n\nRevenue Recognition will be calculated from this month forward.',
    selectMonth: 'Select Month',
    startingMonth: 'Starting Month',
    initialPeriodMessage:
      'This is the first month you are using Polaris for Revenue Recognition.\nIf you want to change this, can remove this month and pick a new starting month.',
    initialPeriodDetails:
      'To reconcile with revenue recognized before this month you can set {value} values for each project to what was recognized before using Polaris.',
    initialPeriodListDetails:
      'Past this month, the {value} will be calculated and populated by Polaris.',
    removeStartMonth: 'Remove',
    startOverHeading: 'Starting Month: Remove and start over',
    startOverMessage:
      'To change the starting month you will need to remove the current starting month and all of its existing Revenue Recognition data.',
    startOverConfirmationMessage:
      'Are you sure you want to remove and start over?',
    recognizedRevenueToDate: 'Revenue Recognized To Date',
    removeAndStartoverDialog: 'Remove and start over'
  },
  revenueAmountEditor: {
    deferredRevenue: 'Deferred Revenue'
  },
  commentEditor: {
    comment: 'Comment'
  },
  chevronRight: {
    label: 'Right Angle'
  },
  referenceNumber: {
    label: 'Reference #',
    title: 'Default Reference #',
    defaultInfo: 'When creating a {displayText}, the default reference # is:',
    prefix: 'Prefix',
    nextNumber: 'Next Number',
    noDefaultReference: 'No default reference #',
    autoGenerateReference: 'Auto-generate reference #',
    allowEditReference: 'Allow users to edit reference #',
    requiredNextNumber: 'Required Next Number.',
    duplicateReferenceNumber: 'Must be a unique reference #.'
  },
  billingOverviewTable: {
    total: 'Total',
    billPlan: 'Bill Plan',
    billable: 'Billable',
    estimatedBilling: 'Estimated Billing',
    availableToBill: 'Available to Bill',
    hours: 'Hours',
    billableHours: 'Billable Hours',
    nonBillableHours: 'Non-Billable Hours',
    flat: 'Flat',
    recurring: 'Recurring',
    time: 'Time',
    fixedBidMilestone: 'Fixed Bid Milestone',
    expenses: 'Expenses',
    totalEstimatedBilling: 'Total Estimated Billing',
    totalAvailableToBill: 'Total Available to Bill',
    billing: 'Billing',
    billed: 'Billed',
    adjustment: 'Adjustment',
    unbilled: 'Unbilled',
    creditMemos: 'Credit Memos',
    payments: 'Payments',
    outstanding: 'Outstanding'
  },
  billingInvoicingTab: {
    title: 'Billing & Invoicing',
    overview: 'Overview',
    availableToBill: 'Available To Bill',
    availableToBillWithTotal: 'Available To Bill ({formattedAmount})',
    transactions: 'Transactions',
    accountReceivable: 'Account Receivable'
  },
  accountReceivable: {
    displaying: 'Displaying {number} of {totalNumber}',
    viewAll: 'View All',
    thirtyDays: '1 - 30 DAYS',
    sixtyDays: '31 - 60 DAYS',
    ninetyDays: '61 - 90 DAYS',
    oneTwentyDays: '91 - 120 DAYS',
    greaterThanoneTwenty: '121+ DAYS',
    chipLabel: '+ {days} Days'
  },
  availableToBillList: {
    selectedItem: '{count} Selected',
    singleRowSelected: 'Single Row Selected',
    allRowsSelected: 'All Rows Selected',
    clearIcon: 'Clear Icon',
    createBill: 'Create {count, plural, =1 { Bill} other { Bills}}',
    createBills: 'CREATE {count, plural, =1 { BILL} other { BILLS}}',
    confirmationMessageForBulkBill:
      'You are about to create {count} {count, plural, =1 { bill} other { bills}} based on client billing default settings.\n\nAre you sure you want to bulk create {count} {count, plural, =1 { bill} other { bills}}?',
    noAvailableToBillData: 'No data available to bill.',
    undoInfoMessage: 'Cancel does not undo bills already created.',
    billsCreatedSuccessfully:
      '{count} {count, plural, =1 { Bill} other { Bills}} successfully created.',
    viewBills: 'View {count, plural, =1 { Bill} other { Bills}}',
    invoiceNumber: 'Invoice Number',
    autoGenerateReferenceRequired:
      'For Bulk Bill creation, select Auto Generate Reference # on Administration - Default Reference Numbers.'
  },
  mobileAvailableToBillHeaderRow: {
    availableToBill: 'Available To Bill'
  },
  billingTransactionList: {
    sync: 'Issue and Sync',
    print: 'Issue and Print',
    email: 'Issue and Email',
    syncIfIssued: 'Sync',
    printIfIssued: 'Print',
    emailIfIssued: 'Email',
    referenceNumber: 'Reference #',
    client: 'Client',
    date: 'Date',
    cancel: 'Cancel',
    dueDate: 'Due Date',
    period: 'Period',
    type: 'Type',
    bill: 'Bill',
    emailInvoice: 'Email Invoice',
    issueAndSync: 'Issue and Sync',
    issueAndPrint: 'Issue and Print',
    issueAndEmail: 'Issue and Email',
    payment: 'Payment',
    credit: 'Credit',
    balance: 'Balance',
    originalAmount: 'Original Amount',
    status: 'Status',
    description: 'Description',
    total: 'Total',
    amount: 'Amount',
    addPayment: 'Add Payment',
    addRefund: 'Add Refund',
    printInvoice: 'Print Invoice',
    pastDue: 'Past Due',
    allBills: 'All',
    draft: 'Draft',
    issued: 'Issued',
    outstanding: 'Outstanding',
    overdueBills: 'Overdue Bills',
    unallocated: 'Unallocated',
    void: 'Voided',
    selectedItemStatus: '{n} Selected',
    syncInvoice: 'Sync Invoice',
    'Queued for Synced': 'Queued for Sync',
    'Failed Sync': 'Failed Sync',
    'Not Sync': 'Not Synced',
    Synced: 'Synced',
    syncDialogDescription:
      "If any selections are already synced or are a\npayment/credit memo, they can't be synced.\n\nAre you sure you want to sync?",
    issueSyncDialogDescription:
      "If any selections are already synced or are a\npayment/credit memo, they can't be synced.\n\nAny bills in draft status will be changed to issued.\n\nAre you sure you want to sync?",
    syncDialogConfirmButton: 'Sync',
    allRowsSelected: 'All Rows Selected',
    singleRowSelected: 'Single Row Selected',
    statusLabel: {
      unbilled: 'Unbilled',
      paid: 'Paid',
      unPaid: 'Unpaid',
      partiallyPaid: 'Partially Paid',
      partiallyRefunded: 'Partially Refunded',
      allocated: 'Allocated',
      unallocated: 'Unallocated',
      partiallyAllocated: 'Partially Allocated',
      closed: 'Closed',
      open: 'Open',
      partiallyClosed: 'Partially Closed',
      void: 'Void',
      draft: 'Draft',
      issuedPaid: 'Issued: Paid',
      issuedUnpaid: 'Issued: Unpaid',
      issuedPartiallyPaid: 'Issued: Partially Paid'
    },
    typeLabel: {
      bill: 'Bill',
      payment: 'Payment',
      creditMemo: 'Credit Memo',
      referenceNumber: 'Ref#',
      refund: 'Payment - Refund',
      availableToBill: {
        timesheet: 'Available to Bill — Time',
        expense: 'Available to Bill — Expense',
        fixedBid: 'Available to Bill — Fixed Bid',
        periodicBillingEntry: 'Available to Bill — Recurring'
      }
    },
    availableToBillType: {
      timesheet: 'Time',
      expense: 'Expense',
      fixedBid: 'Fixed Bid',
      periodicBillingEntry: 'Recurring'
    },
    noData: {
      title: {
        client: 'No billing records created for this client.',
        project: 'No billing records created for this project.',
        program: 'No billing records created for this program.',
        billingWithoutFilter: ' No billing records created.',
        billingWithFilter: 'No matching billing records found.',
        accountReceivable: 'No matching billing records found.'
      },
      description: {
        project:
          'To add a bill or credit memo,  press the {plusIcon} button below.',
        client:
          'To add a bill, payment or credit memo,  press the {plusIcon} button below.',
        billing:
          'To add a bill, payment or credit memo,  press the {plusIcon} button below.'
      }
    }
  },
  voidBillDialog: {
    voidBill: 'Void Bill',
    description: 'Description',
    cancel: 'Cancel',
    placeholder: 'Void bill reason',
    details: 'This will disassociate any payments/credit memos.'
  },
  sendBillDialog: {
    sendBill: 'Send Bill: ',
    send: 'Send',
    cancel: 'Cancel',
    from: 'From',
    to: 'To',
    cc: 'Cc',
    subject: 'Subject',
    message: 'Message',
    validation: {
      emailRequired: 'Please enter an email',
      subjectRequired: 'Please enter a message subject',
      bodyRequired: 'Please enter a message body',
      validEmail: 'Please enter a valid email'
    },
    billDetails:
      'Dear {contactName} \n\nYour {transactionDate} invoice is ready.\n\nYou can view it here: {billUrl} \n\nThank you'
  },
  avaialbleToBillList: {
    client: 'Client',
    date: 'Date',
    amount: 'Amount',
    createBill: 'Create Bill',
    description: 'Description',
    total: 'Total'
  },
  billingList: {
    id: 'ID',
    description: 'Description',
    issueDate: 'Issue Date',
    creditDate: 'Credit Date',
    paymentDate: 'Payment Date',
    createdOn: 'Created On',
    type: 'Type',
    client: 'Client',
    paymentAmount: 'Payment Amount',
    creditAmount: 'Credit Amount',
    billAmount: 'Bill Amount',
    status: 'Status'
  },
  billingListTable: {
    client: {
      noClient: 'None'
    }
  },
  timeAndExpenseAdmin: {
    title: 'Time and Expense Admin',
    timeAdmin: 'Time Admin',
    expenseAdmin: 'Expense Admin',
    timesheet: {
      title: 'Timesheets'
    },
    expense: {
      title: 'Expenses'
    },
    timeWorkbench: {
      title: 'Time WorkBench'
    }
  },
  adminTabs: {
    expenses: 'Expenses',
    timesheets: 'Timesheets',
    timeWorkbench: 'Time WorkBench'
  },
  financialsOverviewTab: {
    title: 'Overview',
    keyPerformanceIndicators: 'Key Performance Indicators',
    kpi: 'KPI',
    yAxisLabel: 'Amount ',
    billingRevenueRecognition: 'Billing & Revenue Recognition',
    billing: 'Billing',
    revenue: 'Revenue Recognition',
    billableRevenue: 'Billable Revenue',
    estimatedBilling: 'Estimated Billing',
    revenueRecognition: 'Revenue Recognition',
    forecastedRevenue: 'Forecasted Revenue',
    recognizedRevenue: 'Recognized Revenue',
    revenueForecast: 'Revenue Forecast'
  },
  accountReceivableCard: {
    title: 'Account Receivable',
    total: 'Total',
    unAllocatedPayments: 'Unallocated Payments',
    thirtyDays: '1 - 30 Days',
    sixtyDays: '31 - 60 Days',
    ninetyDays: '61 - 90 Days',
    oneTwentyDays: '91 - 120 Days',
    greaterThanOneTwentyDays: '120+ Days'
  },
  sessionExpiry: {
    logout: 'Logout',
    continue: 'Keep Working',
    title: 'Session Expiry Warning',
    details:
      'Your session will expire in {minutes} minutes, and {seconds} seconds.',
    description:
      'As a security precaution, sessions are ended after {hours} hour, {minutes} minutes of inactivity.'
  },
  attachments: {
    fileName: 'Filename',
    fileType: 'Type',
    fileSize: 'Size',
    fileSizeDescription: 'Size: {fileSize}',
    uploadedOnDescription: 'Uploaded On {date}',
    description: '{fileSize} - Uploaded On {date}',
    uploadedOn: 'Uploaded On',
    uploadedBy: 'Uploaded By',
    uploadedByDescription: 'Uploaded By {user}',
    action: 'Action',
    addFileOrUrl: 'File / Web Address',
    documents: 'Documents',
    noAttachments: 'No files added.',
    noFilesSelected: 'No files have been selected',
    attachmentDropZoneMessage:
      'Drag files here or click to choose a file to upload.',
    attachmentsCardDialog: 'Attachments Card',
    delaAnalyzing: 'Dela! Is Analyzing your uploaded Document',
    delaTimeToFinish: 'Dela! is going to take some time to finish'
  },
  resourcePoolSupervisorsDialog: {
    currentSupervisor: 'Current Supervisor',
    effectiveDate: 'Effective Date',
    noneAssignedSupervisors: 'No assigned supervisors to show'
  },
  clientList: {
    client: 'Client',
    name: 'Name',
    code: 'Code',
    clientManager: 'Client Manager',
    status: 'Status',
    projectCount: '# of Projects',
    hours: 'Hours',
    cost: 'Cost',
    billing: 'Billing',
    revenue: 'Revenue',
    profit: 'Profit',
    totalProjectBudgets: 'Total Project Budgets',
    estimatedResourceHours: 'Estimated Resource Hours',
    estimatedResourceCost: 'Estimated Resource Cost',
    actual: 'Actual',
    actualCost: 'Actual Cost',
    actualExpenseCost: 'Actual Expenses',
    actualResourceCost: 'Actual Resource Cost',
    estimatedAtCompletion: 'Estimated at Completion',
    estimatedCompletionDate: 'Estimated Completion Date',
    noClients:
      'You have no clients. To add a client, click the add button below.',
    total: 'Total',
    addClient: 'Add Client',
    noMatchingClients: 'No matching clients found.',
    showMore: 'Show More',
    noDataForPeriod: 'No client data for {period}',
    totalProjectContractValue: 'Total Project Contract Value',
    billableRevenue: 'Billable Revenue',
    taxProfile: 'Tax Profile'
  },
  clientAddFab: {
    label: 'Add Client',
    shortLabel: 'Client'
  },
  statusChip: {
    active: 'Active',
    inactive: 'Inactive'
  },
  programList: {
    program: 'Program',
    status: 'Status',
    programLeader: 'Program Manager',
    projectsCount: '# of Projects',
    hours: 'Hours',
    cost: 'Cost',
    billing: 'Billing',
    totalProjectBudgets: 'Total Project Budgets',
    estimatedResourceHours: 'Estimated Resource Hours',
    estimatedResourceCost: 'Estimated Resource Cost',
    actual: 'Actual',
    actualCost: 'Actual Cost',
    actualResourceCost: 'Actual Resource Cost',
    actualExpenseCost: 'Actual Expenses',
    estimatedAtCompletion: 'Estimated at Completion',
    estimatedCompletionDate: 'Estimated Completion Date',
    emptyList:
      'None of your programs are currently managed by Replicon Professional Services Automation',
    noPrograms:
      'You have no programs. To add a program, click the add button below.',
    noMatchingPrograms: 'No matching programs found.',
    name: 'Name',
    total: 'Total',
    totalProjectContractValue: 'Total Project Contract Value',
    billableRevenue: 'Billable Revenue'
  },
  notificationList: {
    message: 'Message',
    type: 'Type',
    noNotifications: 'No Notifications.'
  },
  notificationTypes: {
    'resource-request.modification.submitted': 'Resource Request Submitted',
    'resource-request.modification.to-be-hired': 'Resource to be Hired',
    'resource-request.modification.rejected': 'Resource Request Rejected',
    'resource-allocation.modification.proposed': 'Resource Proposed',
    'resource-allocation.modification.accepted': 'Resource Accepted',
    'resource-allocation.modification.rejected': 'Resource Rejected',
    'task-assignment.modification.assigned': 'Task Assigned',
    'task.modification.status-changed': 'Task Status Changed',
    'project.modification.project-stage-changed': 'Project Status Changed',
    'resource-allocation.modification.released': 'Resource Released'
  },
  programPageTabs: {
    summary: 'Summary',
    info: 'Info',
    projects: 'projects',
    time: 'Time',
    timeAndExpense: 'Time & Expense',
    billingInvoicing: 'Billing & Invoicing'
  },
  programHeaderInfo: {
    programActiveStatus: 'Active',
    programInactiveStatus: 'Inactive'
  },
  programBasicInfoCard: {
    name: 'Name',
    startDate: 'Planned Start Date',
    endDate: 'Planned End Date',
    programManager: 'Program Manager',
    program: 'Program',
    duplicateNameValidation:
      'Program Name should be unique. A Program already exists with specified program name.',
    budgetCost: 'Budget Cost',
    dates: 'Dates',
    basicInfo: 'Basic Info'
  },
  invoiceTemplate: {
    label: 'Invoice Template',
    noInvoiceTemplate: 'No Invoice Template'
  },
  client: {
    billing: 'Billing',
    taxProfile: 'Tax Profile',
    noTaxProfile: 'No Tax Profile',
    invoiceTemplate: 'Invoice Template',
    noInvoiceTemplate: 'No Invoice Template',
    noClient: 'No Client',
    noClientRepresentatives: 'No Representatives',
    noComanagers: 'No Co-Manager(s)',
    address: 'Address',
    contactName: 'Contact Name',
    contactEmail: 'Contact Email',
    clientManager: 'Client Manager',
    comanagers: 'Co-Manager(s)',
    invoiceCurrency: 'Billing Currency',
    email: 'Email',
    phone: 'Phone',
    fax: 'Fax',
    website: 'Website',
    active: 'Active',
    inactive: 'Inactive',
    info: 'Info',
    clientRepresentatives: 'Client Representatives',
    additionalInfo: 'Additional Info',
    basicInfo: 'Basic Info',
    name: 'Name',
    code: 'Code',
    description: 'Description',
    city: 'City',
    stateProvince: 'State / Province',
    zipPostalCode: 'Zip / Postal Code',
    country: 'Country',
    contact: 'Contact',
    billingAddress: 'Billing Address',
    projects: 'Projects',
    noClientManager: 'No Client Manager',
    noAdditionalInfo: 'No Additional Info',
    noContact: 'No Contact',
    noAddress: 'No Address',
    duplicateClient:
      'Client name should be unique. A client already exists with the specified name.',
    fieldExceededMaxLength: 'Cannot exceed maximum length of {max} characters.',
    invalidEmail: 'Please enter a valid e-mail address.',
    invalidUrl: 'Please enter a valid URL.',
    nameRequired: 'Please enter a Client Name',
    codeRequired: 'Please enter a Client Code',
    time: 'Time',
    timeAndExpense: 'Time & Expense',
    billingAndInvoicing: 'Billing & Invoicing',
    sameAsAddress: 'Same as Address',
    overview: 'Overview',
    removeClient: 'Remove Client',
    summary: 'Summary',
    expandDetails: 'Expand Details',
    collapseDetails: 'Collapse Details',
    taxTypes: '{n} Tax Types',
    numberOfTaxType: '{numberOfTaxTypes} Tax Types - ',
    yes: 'Yes',
    no: 'No',
    asOfDate: 'As of {date}',
    paymentTerm: 'Payment Term',
    showDetails: 'Show Details',
    hideDetails: 'Hide Details',
    taxTypesHeading: 'Tax Types',
    cancelDetails: 'Cancel'
  },
  removeClientDialog: {
    title: 'Remove Client',
    content: 'Are you sure you want to remove this client?',
    action: 'Remove Client',
    cancel: 'Cancel'
  },
  disableClientDialog: {
    title: 'Deactivate Client',
    content:
      'This client has projects created for it, preventing it from being removed. Its status will be changed to inactive instead.',
    secondaryContent: 'Are you sure you want to deactivate this client?',
    action: 'Deactivate Client',
    cancel: 'Cancel'
  },
  stopTaskDialog: {
    messageBody1: 'You have already entered information for this task.',
    messageBody2: 'Are you sure you want to stop working on this task?',
    cancel: 'Cancel',
    stopWorking: 'Stop Working'
  },
  searchBox: {
    search: 'Search',
    applySearch: 'Apply Search',
    searchBy: 'Search by "{phrase}"',
    clear: 'clear',
    close: 'Close',
    resetMetric: 'Reset {metric} Score'
  },
  qualityDropDown: {
    quality: 'Quality'
  },
  taskDropDown: {
    goToToday: 'Go to Today'
  },
  warning: {
    warning: 'Warning'
  },
  menu: {
    logout: 'Logout',
    appSettings: 'App Settings',
    myProfile: 'My Profile'
  },
  workflow: {
    setStatus: 'Set Status',
    currentStatus: 'Current Status',
    projectLifcycles: 'Project Lifecycles',
    newItem: 'New Item',
    checklist: {
      emptyName: 'Please enter a name',
      duplicateName: 'Name should be unique'
    }
  },
  rateCardCopyOptions: {
    'urn:replicon:rate-table-entry-copy-option:copy-from-project':
      'Copy Rate Card from Project',
    'urn:replicon:rate-table-entry-copy-option:copy-from-client':
      'Copy Rate Card from Client'
  },
  billingOptions: {
    [TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE]:
      'Billable & Non-Billable',
    [TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE]: 'Non-Billable',
    [TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE]: 'Billable Only',
    [TIME_AND_EXPENSE_ENTRY_TYPE.NO]: 'No'
  },
  timeAndExpenseEntryOptions: {
    yes: 'Yes',
    [TIME_AND_EXPENSE_ENTRY_TYPE.NO]: 'No',
    [TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE]: 'Billable',
    [TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE]: 'Non-Billable',
    [TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE]:
      'Billable & Non-Billable'
  },
  expenseEntryOptions: {
    billable: 'Billable',
    nonBillable: 'Non-Billable',
    billableAndNonBillable: 'Billable & Non-Billable'
  },
  projectWorkflow: {
    'urn:replicon-psa:project-stage:initiate': 'Initiate',
    'urn:replicon-psa:project-stage:planning': 'Planning',
    'urn:replicon-psa:project-stage:execution': 'Execution',
    'urn:replicon-psa:project-stage:close-out': 'Closeout',
    'urn:replicon-psa:project-stage:archived': 'Archived'
  },
  userBeatTimesheetWarning: {
    'time-entry-cannot-edit-on-not-open-timesheet-validation-failure':
      'Unable to add task hours to your timesheet as it was already submitted.',
    'required-comment-is-blank':
      'Unable to add task hours to your timesheet as you did not provide a comment.',
    'exceed-scheduled-work-hours':
      'Unable to add task hours to your timesheet as they would exceed your scheduled work hours.',
    defaultMessage:
      'Unable to add task hours to your timesheet. Please try again later, or enter the hours manually.'
  },
  programBasicInfo: {
    nameRequired: 'Please enter a name',
    programNameExceedsMaxLength: 'Program name cannot exceed 255 characters',
    save: 'save'
  },
  requestAllocationDetails: {
    waitingForResourceAllocations: 'Waiting for resource allocations...'
  },
  resourceAllocationSummaryTooltip: {
    across: 'across',
    projects: 'Projects',
    project: 'project',
    allocated: 'Allocated'
  },
  resourceActualSummaryDialog: {
    hours: '{value}h',
    percentage: '{value}%'
  },
  taskEntryDrawer: {
    previousWork: 'Previous Work',
    work: 'Work',
    workRemaining: 'Work Remaining',
    estimatedAtCompletion: 'Estimated at Completion',
    estimatedCompletionDate: 'Estimated Completion Date',
    comment: 'Comment',
    done: 'Done',
    originalEstimate: 'Original Estimate',
    assignedHours: 'Assigned Hours',
    hours: 'hours',
    hour: 'hour'
  },
  taskEntryDrawerActions: {
    stopTask: 'Stop Task',
    restart: 'Restart Task',
    close: 'Close',
    addHours: 'Add Hours',
    removeHours: 'Remove Hours',
    ok: 'Ok',
    save: 'Save',
    cancel: 'Cancel'
  },
  addProgram: {
    addProgram: 'Add Program',
    shortLabel: 'Program',
    cancel: 'Cancel',
    add: 'Add',
    name: 'Name',
    enterProgramNameErrorMsg: 'Please enter a name',
    programNameExceedsMaxLength: 'Program name cannot exceed 255 characters',
    duplicateNameValidation:
      'Program Name should be unique. A Program already exists with specified program name.'
  },
  projectArchiveConfirmationDialog: {
    title: 'Archive Project',
    confirmMessage: 'Are you sure you want to archive this project?'
  },
  removeProgramDialog: {
    title: 'Remove Program',
    content: 'Are you sure you want to remove this program?',
    action: 'Remove Program',
    cancel: 'Cancel'
  },
  disableProgramDialog: {
    title: 'Deactivate Program',
    content:
      'This program has projects created for it, preventing it from being removed.  Its status will be changed to inactive instead.',
    secondaryContent: 'Are you sure you want to deactivate this program?',
    action: 'Deactivate Program',
    cancel: 'Cancel'
  },
  removeProjectDialog: {
    title: 'Remove Project',
    content: 'Are you sure you want to remove this project?',
    action: 'Remove Project',
    cancel: 'Cancel'
  },
  disableProjectDialog: {
    title: 'Archive Project',
    content:
      'This project has dependencies, preventing it from being removed.  It will be archived instead.',
    secondaryContent: 'Are you sure you want to archive this project?',
    action: 'Archive Project',
    cancel: 'Cancel'
  },
  avatarUploadDialog: {
    title: 'Upload an Avatar',
    removeAvatar: 'Remove Avatar',
    removeAvatarDescription: 'Are you sure you want to remove the avatar?'
  },
  loadingIndicators: {
    loading: 'Loading'
  },
  releaseResourceRequestDialog: {
    allocationDate: 'Allocation Date',
    asOf: 'As Of',
    cancel: 'Cancel',
    close: 'Close',
    comment: 'Comment',
    confirmation: 'I confirm, this action will remove resources permanently.',
    impactedTask:
      '{count} {type} {taskLabel} will no longer have a task owner.',
    impactedTaskSummaryAssignedTasks:
      'Assigned to <b>{taskCount} {taskCount, plural, =1 { Task} other { Tasks}}</b>',
    impactedTaskSummaryAllocationRemaining:
      'Has <b>{allocatedHours} {allocatedHours, plural, =1 { hour} other { hours}}</b> of allocation remaining',
    impactedTaskSummaryWithAsOfDate:
      '<b>After {releaseDate} {userDisplayText}</b>',
    impactedTaskSummaryWithOutAsOfDate: '<b>{userDisplayText}</b> is currently',
    inProgress: 'In Progress',
    noEditTaskPermissionMessage:
      'You do not have permission to edit tasks on this project',
    noRole: 'No Role',
    notStarted: 'Not Started',
    notStartedAndInProgressTasks:
      '{notStarted} Not Started and {inProgress} In Progress {taskLabel} will no longer have a task owner.',
    projectAllocationDates: 'Project Allocation Dates',
    projectTitleSubheader: 'Project: <span>{project}</span>',
    release: 'Release',
    releaseAllocationsTitle: 'Release Resource: <span>{role}</span>',
    releaseEntirely: 'Release Entirely',
    releaseResource: 'Release Resource',
    releaseResourcesTitle: 'Release {role} Resources',
    releaseResourcesTitleNew: 'Release Resources: <span>{role}</span>',
    releaseResourceTitle: 'Release {role} Resource',
    resource: 'Resource',
    resourceCanNotRelease:
      '{user} cannot be released because they are in use as a task owner.',
    role: 'Role {count}',
    task: 'task',
    tasks: 'tasks',
    todaysDate: "Today's Date",
    user: 'User {count}',
    userHeader: 'User'
  },
  viewResourceRequestsSummaryDialog: {
    title: '{user} has multiple Resource requests.',
    description: 'Select the request to view details',
    loadSuffix: '%',
    loadPrefix: '@',
    hoursSuffix: ' h',
    showMore: 'Show More'
  },
  recallResourceRequestDialog: {
    cancel: 'Cancel',
    recall: 'Recall Request',
    recallResourcesTitle: 'Remove Proposed Resource',
    recallMessage:
      'You are about to remove all the time allocated to this request for this resource.',
    confirmation:
      'Do you want to recall the resource request and propose a new resource?'
  },
  helpZone: {
    title: 'Help',
    description: 'Learn more about using Replicon'
  },
  customerZone: {
    title: 'Customer Community',
    description: 'Ask questions and send feedback'
  },
  policyLinks: {
    privacy: 'Privacy Policy',
    license: 'License Agreement'
  },
  country: {
    none: 'None'
  },
  periodDropdown: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
    entireProject: 'Entire Project'
  },
  projectStatusFilterButton: {
    unarchivedProjects: 'Unarchived Projects',
    allProjects: 'All Projects',
    showProjects: 'Show Projects'
  },
  projectStatusFilterDialog: {
    saveSettings: 'Save Settings',
    dialogTitle: 'Project Display Settings',
    cancel: 'Cancel',
    selectAProjectStatus: 'Please select a project status',
    unarchivedProjects: 'unarchived projects',
    allProjects: 'all projects',
    showProjects: 'Show {project} the Resource is assigned to.',
    showUnarchivedProjects:
      'Show all the {project} the Resource is assigned to.',
    showCustomProjects: 'Only show projects with the following statuses:',
    selectProjectStatus: 'Please select a project status.'
  },
  resourceManagementTabs: {
    timeline: 'Timeline',
    list: 'List',
    overview: 'OverView'
  },
  sendProjectReport: {
    send: 'Send',
    cancel: 'Cancel',
    title: 'Send Report',
    failedRecipient: 'Unable to send email, please try again later.',
    invalidRecipient: 'User does not have a valid email address.'
  },
  resourceRoles: {
    primaryRole: 'Primary Role:',
    additionalRoles: 'Additional Roles',
    noRole: 'No Role'
  },
  notAuthorized: {
    canNotViewPage: 'You are not authorized to view this page.'
  },
  resourcingLegends: {
    request: 'Requested',
    requested: 'Requested',
    allocated: 'Allocated',
    overAllocated: 'Over Allocated',
    toBeHired: 'To Be Hired',
    rejected: 'Rejected',
    requestRejected: 'Request Rejected',
    resourceRejected: 'Resource Rejected',
    pending: 'Pending',
    proposed: 'Proposed',
    complete: 'Complete Allocation',
    unavailable: 'Unavailable',
    timeOff: 'Time Off',
    holidays: 'Holidays',
    notWorking: 'Not Working',
    currentAllocation: 'This Allocation',
    pendingAllocation: 'Pending Allocation',
    acceptedAllocation: 'Accepted Allocation',
    rejectedAllocation: 'Rejected Allocation',
    proposedAllocation: 'Proposed Allocation',
    allocation: 'Allocation'
  },
  chart: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    allocatedHours: 'Allocated Hours',
    allocatedCost: 'Allocated Cost',
    actualcost: 'Actual Cost',
    hoursWorked: 'Hours worked',
    budget: 'Budget',
    actual: 'Actual',
    project: 'Project',
    projects: 'Projects',
    tasks: 'Tasks',
    resources: 'Resources',
    quarterAbbreviated: 'Q',
    cost: 'Cost ({value})'
  },
  hoursAndCostLabel: {
    hours: 'Hours',
    cost: 'Cost'
  },
  projectBudgetChart: {
    budgetCost: 'Budget Cost',
    budgetHours: 'Budget Hours',
    projectEstimate: 'Project Estimate',
    projectCostEac: 'Project Cost EAC',
    projectActuals: 'Project Actuals',
    taskCostEac: 'Task Cost EAC',
    taskHoursEac: 'Task Hours EAC',
    taskActuals: 'Task Actuals',
    allocatedCost: 'Allocated Cost',
    allocatedHours: 'Allocated Hours',
    allocatedActuals: 'Allocated Actuals',
    estimate: 'Estimate',
    estimateAtCompletion: 'Est. At Completion',
    estimatedCost: 'Estimated Cost',
    estimatedCostAtCompletion: 'Est. Cost at Completion',
    resourceCost: 'Resource Cost',
    allocated: 'Allocated',
    hoursWorked: 'Hours Worked'
  },
  actualsSummaryPeriodPopup: {
    otherRoles: 'Other Roles',
    noRole: 'No Role',
    actuals: 'Actuals',
    difference: 'Difference',
    allocated: 'Allocated',
    total: 'Total',
    scheduledHours: '{scheduledHours} Scheduled Hours',
    roleDisplayText: '{roleDisplayText}',
    hours: '{value}h',
    totalActuals: 'Actual:',
    totalAllocated: 'Allocated:',
    totalDifference: 'Difference:'
  },
  resourceAllocationChartContent: {
    noRows: 'No Resources have been allocated to this project.'
  },
  programPage: {
    projects: 'Projects',
    projectsTotal: 'Projects Total'
  },
  projectStatus: {
    initiate: 'Initiate',
    planning: 'Planning',
    execution: 'Execution',
    closeout: 'Closeout',
    archived: 'Archived'
  },
  columnSettings: {
    tableSettings: 'Table Settings',
    showTableSettings: 'Show Table Settings'
  },
  clientPageCharts: {
    resourceCost: 'Resource Cost',
    resourceHours: 'Resource Hours',
    projects: 'Projects',
    projectsTotal: 'Projects Total',
    overview: 'Client Overview',
    trendDescription: '({prefix}{delta} from last month)',
    costPercentageTrend: '{value}%',
    billable: 'Billable'
  },
  dashboard: {
    welcomeText: 'Welcome, {name}',
    tasks: 'Tasks',
    userBeat: {
      activeTitle: 'Active ({activeCount})',
      notStartedTitle: 'Not Started ({notStartedCount})',
      errorInTaskCount: 'Problem displaying task count, try again later',
      taskCount: '{taskCount} Tasks',
      notStarted: 'Not Started',
      showMore: 'Show More...',
      searchProjects: 'Search Projects',
      close: 'Close',
      tasksName: 'Tasks Name',
      workEstimatedAtCompletion: 'Work / Estimated at Completion',
      noTasks: 'No Tasks'
    },
    allocatedProjects: 'Allocated Projects',
    showMore: 'Show More...',
    hoursWorked: 'Hours Worked',
    hoursAllocated: 'Hours Allocated',
    noAllocatedProjects: 'No allocated projects.',
    timesheet: {
      title: 'Timesheet',
      viewAllTimesheets: 'View All Timesheets',
      currentTimesheet: 'Current Timesheet',
      due: 'Due',
      overdueTitle: 'Overdue Timesheet',
      rejectedTitle: 'Rejected Timesheet',
      noTimesheetAvailable: 'No timesheets available'
    },
    timeOff: {
      title: 'Time Off',
      balances: 'Balances',
      viewAllBalances: 'View All Balances',
      bookTimeOff: 'Book Time Off',
      noTimeOffBalances: 'No time off balances.',
      noTimeOffBookings: 'No time off bookings.',
      hours: 'Hours',
      days: 'Days',
      daysTaken: 'Days Taken',
      hoursTaken: 'Hours Taken'
    },
    expenses: {
      title: 'Expenses',
      addExpense: 'Add Expense',
      notSubmitted: 'Not Submitted',
      rejected: 'Rejected',
      waitingForApproval: 'Waiting for Approval',
      awaitingReimbursement: 'Awaiting Reimbursement',
      approved: 'Approved',
      noExpenseAvailable: 'No expenses available.'
    },
    approvals: {
      title: 'Approvals',
      viewApprovals: 'View Approvals',
      timesheetsWaiting: 'Timesheets Waiting',
      bookingsWaiting: 'Time Off Bookings Waiting',
      expensesWaiting: 'Expenses Waiting',
      sellbacksWaiting: 'Time Off Sell Backs Waiting',
      noApprovals: 'There is nothing waiting for your approval.'
    },
    targetBillableHours: 'Target Billable Hours',
    noData: 'Data not available.',
    skillsAssigned: '{count} skills assigned',
    noSkillsAssigned: 'No skills assigned',
    certificatesAssigned: '{count} licenses/certificates',
    noCertificatesAssigned: 'No licenses/certificates'
  },
  approvalStatus: {
    open: 'Not Submitted',
    waiting: 'Waiting for Approval',
    approved: 'Approved',
    rejected: 'Rejected',
    submitting: 'Submitting...'
  },
  billPlan: {
    addPlan: 'BILLING TYPE',
    deletePlan: 'Remove {scriptType} Billing Type',
    removeBillingType: 'Remove Billing Type',
    cancel: 'Cancel',
    deleteScriptConformationMessage:
      'Are you sure you want to remove the {scriptType} billing type?',
    addBillPlanType: 'ADD BILL PLAN TYPE',
    title: 'Bill Plan',
    totalEstimatedAmount: 'Estimated Billing',
    noPermissionToViewExpenseCodes:
      'You do not have access to view/edit expense codes, therefore cannot add/edit expense codes.',
    noPermissionToEditBillPlan:
      'You do not have access to view/edit expense codes, therefore cannot add/edit expense codes to this bill plan.',
    estimatedAmount: 'Estimated: ',
    billingType: 'Billing Type',
    noBillPlan: 'No bill plan defined',
    recalculateBillableData: 'Recalculate Billable Data',
    recalculatingBillableData: 'Recalculating billable data...',
    unknownServiceError:
      'There was a problem in loading this card, please try again later.',
    recurring: 'Recurring',
    time: 'Time',
    flat: 'Flat',
    fixedBidMilestone: 'Fixed Bid Milestone',
    noTask: 'No Milestone Task',
    expenses: 'Expenses',
    milestone: 'Milestone',
    markup: 'Markup',
    date: 'Date',
    period: 'Period',
    frequency: 'Frequency',
    amount: 'Amount',
    percentage: 'Percentage',
    addRecurring: 'Add Recurring',
    addFlat: 'Add Flat',
    addTime: 'Add Time',
    addMilestone: 'Add Milestone',
    addExpenses: 'Add Expenses',
    contractAmount: 'Contract Amount',
    weeklyFrequency: '{frequency} on {dayOfWeek}',
    monthlyFrequency: '{frequency} on the {dayOfMonth}',
    frequencyOnFormattedDate: '{frequency} on {formattedDate}',
    frequencyFromFormattedDate: '{frequency} from {formattedDate}',
    on: 'on',
    onThe: 'on the',
    from: 'from',
    taskIsNotAMilestone: 'Task is not a milestone',
    NO_MORE_MILESTONE: 'Task is not a milestone',
    REMOVED_MILESTONE: 'Task has been removed',
    taskRemoved: 'Task Removed',
    validations: {
      requireTask: 'Please select a task.',
      duplicateTask: 'A milestone with specified task is already defined.'
    },
    totalPercentage: 'Total Percentage: ',
    totalAmount: 'Total Amount: ',
    dayOfWeek: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday'
    },
    billPlanType: {
      recurring: 'Recurring',
      flat: 'Flat',
      time: 'Time',
      fixedBidMilestone: 'Fixed Bid Milestone',
      expenses: 'Expenses'
    },
    billPlanFrequency: {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      BIWEEKLY: 'Bi-Weekly',
      SEMIMONTHLY: 'Semi-Monthly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly',
      Daily: 'Daily',
      Weekly: 'Weekly',
      'Bi-Weekly': 'Bi-Weekly',
      'Semi-Monthly': 'Semi-Monthly',
      Monthly: 'Monthly',
      Quarterly: 'Quarterly',
      Yearly: 'Yearly'
    },
    dayOfMonth: {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
      5: '5th',
      6: '6th',
      7: '7th',
      8: '8th',
      9: '9th',
      10: '10th',
      11: '11th',
      12: '12th',
      13: '13th',
      14: '14th',
      15: '15th',
      16: '16th',
      17: '17th',
      18: '18th',
      19: '19th',
      20: '20th',
      21: '21st',
      22: '22nd',
      23: '23rd',
      24: '24th',
      25: '25th',
      26: '26th',
      27: '27th',
      28: '28th',
      29: '29th',
      30: '30th',
      31: 'End of Month'
    },
    semiMonthlyDays: {
      1: '1st & 16th',
      2: '2nd & 17th',
      3: '3rd & 18th',
      4: '4th & 19th',
      5: '5th & 20th',
      6: '6th & 21st',
      7: '7th & 22nd',
      8: '8th & 23rd',
      9: '9th & 24th',
      10: '10th & 25th',
      11: '11th & 26th',
      12: '12th & 27th',
      13: '13th & 28th',
      14: '14th & 29th',
      15: '15th & Last'
    }
  },
  programPageCharts: {
    overview: 'Program Overview',
    resourceCost: 'Resource Cost',
    resourceHours: 'Resource Hours'
  },
  dashboardOverviewChart: {
    budgetDescription: 'of {currency} {budget}',
    costDescription: '{currency} {cost}',
    budgetLegend: 'Budget',
    stages: 'Stages',
    billPlan: 'Bill Plan not setup',
    billPlanAlert:
      'Setup the Bill Plan on this project to define how Billable Revenue is created',
    na: 'NA'
  },
  dashboardProgressChart: {
    budgetBurned: 'Budget Hours not set',
    budgetBurnedAlert:
      'Set the Budget Hours on this project to track Budget Burned.',
    scopeCompleted: 'Work Remaining missing',
    scopeCompletedAlert:
      'Check the tasks on this project for more information.',
    budgetRemaining: 'Budget Remaining',
    workRemaining: 'Work Remaining',
    remaining: 'Remaining',
    budget: 'Budget',
    work: 'Work',
    na: 'NA',
    vs: 'vs',
    hrs: ' hrs',
    dayx: '{count, plural, =1 { Day} other { Days}}',
    predicted: 'Predicted',
    planned: 'Planned',
    noPredictedDate: 'No Predicted Date',
    noPredictedDateTooltip:
      'Not enough existing data to calculate predicted end date',
    noPlannedDate: 'No Planned Date',
    noPlannedDateTooltip: 'Planned end date has not been defined',
    costBurned: 'Budget Cost not set',
    costBurnedAlert:
      'Set the Budget Cost on this project to track Budget Burned.',
    costScopeCompleted: 'Cost Remaining missing',
    hours: 'Hours',
    cost: 'Cost',
    costRemaining: 'Cost Remaining'
  },
  resourceDrawer: {
    supervisor: 'Supervisor',
    roles: 'Roles',
    groups: 'Groups',
    skills: 'Skills',
    tags: 'Tags',
    additionalInfo: 'Additional Info',
    profileLinks: 'Profile Links',
    noAdditionalInfo: 'No Additional Info',
    noProfileLinks: 'No Profile Links',
    licenseAndCertificates: 'License and Certificates',
    noCertificates: 'No certificates are available',
    documents: 'Documents',
    projectHistory: 'Project History',
    noProjectsAllocated: 'No Projects Allocated',
    projectHistorySection: {
      project: 'Project',
      showMore: 'Show More',
      allocationDates: 'Allocation Dates',
      projectManager: 'Project Manager',
      hoursWorked: 'Hours Worked',
      role: 'Role'
    },
    resourcePoolSection: {
      placeHolderText: 'Type to search by Pool Name',
      userAssigned: 'User Assigned',
      headerToolTip:
        'Directly assigned resource pools are the resource pools this user has been individually assigned to'
    },
    resourcePools: 'Resource Pools',
    noResourcePoolsAssigned: 'No Resource Pools',
    accessDenied: {
      title: 'Access Denied',
      details: "You do not have access to see this user's details.",
      caption: 'If you need access, please contact your system administrator.'
    }
  },
  resourcePlanGroupDropdown: {
    project: 'Project',
    role: 'Role',
    allRequests: 'All Requests',
    groupBy: 'Group by: {groupType}',
    groupBySelect: 'Group by select'
  },
  resourceManagement: {
    noAdditionalInfo: 'No Additional Info',
    additionalInfo: 'Additional Info',
    settingsButton: 'Resource table setting button',
    resourcePools: 'Resource Pools',
    noResourcePools: 'No Resource Pools',
    list: {
      name: 'Name',
      department: 'Department',
      employeeId: 'Employee ID',
      startDate: 'Start Date',
      endDate: 'End Date',
      role: 'Role',
      supervisor: 'Supervisor',
      costCenter: 'Cost Center',
      division: 'Division',
      employeeType: 'Employee Type',
      location: 'Location',
      serviceCenter: 'Service Center',
      resourcePools: 'Resource Pools',
      status: 'Status'
    }
  },
  tags: {
    tagsCardDialog: 'Tags Card',
    title: 'Tags',
    key: 'Key',
    value: 'Value',
    noTags: 'No tags defined.',
    noTagsEdit: 'No tags have been defined. Click "Add" to get started.',
    noOptions: 'No tags created. Enter a value to create the first tag.',
    noValues: 'Enter some text to define the first value for this tag.',
    noMatchingKeys: 'No tags matching the search criteria.',
    noMatchingValues: 'No values matching the search criteria.',
    createTag: 'Create tag "{tagName}".',
    createTagValue: 'Create value "{tagName}"',
    noTagError: 'You must choose or create a tag.',
    noValueError: 'You must choose or create a value',
    noTagErrorNoCreate: 'You must choose a tag.',
    noValueErrorNoCreate: 'You must choose a value',
    tagSearch: 'Search for a tag',
    duplicateKeyError: 'This key has been used by a custom field name.',
    exceedsMaxCharactersError:
      'Cannot exceed maximum length of {count} characters.'
  },
  projectOverview: {
    activeTasks: 'Active Tasks',
    activeTasksTotal: '{count} Active Tasks',
    allocated: '{Count} Allocated',
    budget: 'Budget',
    awaitingApproval: 'Resources Awaiting Approval',
    compDate: ' Completion Date',
    complete: 'Complete',
    cost: 'Over Cost',
    draft: 'Draft',
    draftRequest: '{requestType} Request To Submit',
    draftRequests: '{requestType} Requests To Submit',
    drafted: '{Count} Drafted',
    endDate: 'End Date',
    estHours: ' Estimated Hours',
    hoursBudget: 'Hours Budget',
    hoursEstimate: 'Hours Estimate',
    keyPerformanceIndicators: 'Key Performance Indicators',
    kpi: 'KPI',
    maxLimitError: 'Please refine your search.',
    noActiveTasks: 'No Active Tasks',
    noResourceRequests: 'No Resource Requests to Display',
    noTasks: 'No Active or Pending Tasks to Display',
    onTrack: 'On Track',
    over: '{Count} Over',
    overEstimate: 'Over {suffix}',
    overEstimatedDate: 'Over the Estimated Completion Date',
    overEstimatedHours: 'Over the Estimated Hours at Completion',
    overEstimation: 'Over Estimation',
    overEstimationOrEndDate: 'Over Estimation or End Date',
    overHours: 'Over',
    past: 'Past',
    pending: 'Pending',
    pendingTask: 'SmartBeats Need Your Approval',
    pendingTaskBeats: 'Pending SmartBeats',
    pendingTaskBeatsToAccept: '{prefix} to accept',
    projects: 'Projects',
    proposed: 'Proposed',
    proposedRequests: '{Count} Proposed',
    refineSearchError: 'Try using search to refine your results.',
    rejected: 'Rejected',
    rejectedText: 'Rejected',
    requestAwaitingApproval: '{requestType} Request Awaiting Approval',
    requestRejected: '{requestType} Request',
    requestedResources: 'Requested Resources',
    requestsAwaitingApproval: '{requestType} Requests Awaiting Approval',
    requestsRejected: '{requestType} Requests',
    requiredAttention: '{count} require attention',
    resource: 'Resource',
    resourceFulfillment: 'Resource Fulfillment',
    resources: 'Resources',
    seeAll: 'See all',
    showMore: 'Show More...',
    stages: 'Stages',
    taskBreakDown: 'Task BreakDown',
    toBeHired: 'To Be Hired',
    tooManyResourcesForSummary:
      'We cannot calculate a resource summary for more than 1000 projects at a time.',
    tooManyVisibleProjects: 'Too many visible projects',
    underEstimation: 'Under Estimation',
    unknownServiceError:
      'There was a problem in loading this card, please try again later.',
    viewInList: 'View in List'
  },
  status: {
    active: 'Active',
    inactive: 'Inactive'
  },
  myWorkPageTabs: {
    overview: 'Overview',
    userBeat: 'User Beat',
    myTasks: 'My SmartBeats',
    approvals: 'Approvals',
    timesheet: 'Timesheet',
    timeOff: 'Time Off',
    expenses: 'Expenses'
  },
  overbudget: {
    completionDateOverdue: 'Completion Date Overdue',
    costOverbudget: 'Cost Over Budget',
    hoursOverbudget: 'Hours Over Budget',
    overbudget: 'Either Over Budget'
  },
  resourcingTabs: {
    overview: 'Overview',
    requests: 'Requests',
    resourceRequests: 'Resource Requests',
    resourceAllocation: 'Resource Allocation',
    quickAllocation: 'Quick Allocation'
  },
  resourcingOverviewPeriod: {
    weekly: 'Weekly',
    monthly: 'Monthly'
  },
  resourcingOverviewChart: {
    title: 'Resource Allocation Summary',
    legends: {
      availableResource: 'Available Resource',
      allocated: 'Allocated',
      overAllocated: 'Over Allocated',
      timeoff: 'Time Off',
      holidays: 'Holidays',
      pendingRequests: 'Pending Requests',
      toBeHired: 'To Be Hired',
      totalResources: 'Total Resources',
      fittingAllocation: 'Fitting Allocation'
    },
    hours: 'Hours',
    percentage: 'Percentage',
    resourcesFTE: 'Resources (FTE)',
    tooltips: {
      fittingAllocation:
        "Fitting Allocation is the portion of the project allocation which fits into the resources' working time.",
      overAllocated:
        "Over Allocated is the portion of the project allocation which does not fit into the resources' working time."
    },
    dialogSections: {
      resources: 'Resources',
      projectAllocation: 'Project Allocation',
      resourceRequests: 'Resource Requests',
      availability: 'Availability',
      working: 'Working',
      fte: 'FTE'
    },
    footnote: 'Over allocattion can happen to user & date level issues'
  },
  resourcingOverview: {
    skills: 'Skills',
    noSkills: 'No skills defined',
    skill: 'Skill',
    tags: 'Tags',
    noTags: 'No tags defined',
    tag: 'Tag',
    resourcePool: 'Resource Pool',
    resourcePools: 'Resource Pools'
  },
  resourcingOverviewReminders: {
    title: 'Resource Requests',
    pending: 'Pending',
    pendingResourceRequests: ' Resource Requests',
    proposed: 'Proposed',
    proposedResourcesNotAccepted: ' Resources not accepted',
    resourcesRejected: 'Resources Rejected',
    noReminders: 'No Reminder',
    reminders: 'Resourcing Reminders'
  },
  clientsPageTabs: {
    overview: 'Overview',
    list: 'List'
  },
  programsPageTabs: {
    overview: 'Overview',
    list: 'List'
  },
  clientsOverview: {
    keyPerformanceIndicators: 'Key Performance Indicators',
    kpi: 'KPI',
    profitabilityLegends: {
      profit: 'Profit',
      loss: 'Loss'
    },
    clientProfitability: 'Client Profitability',
    projects: {
      overHours: 'Over',
      estHours: ' Estimated Hours',
      status: 'Status',
      showMore: 'Show More',
      noProjectsData: 'No Project Data to Display',
      overBudget: 'Over Budget or End Date',
      onTrack: 'On Track',
      projectsInExecution: '{count} Projects in Execution',
      executionCustomStatus:
        '{count, plural, =1 {1 Project in} other {# Projects in}} {status}',
      overHoursBudget: 'Hours Budget',
      overCostBudget: 'Over Cost Budget',
      overEndDate: 'End Date',
      eitherHoursOrCostOverbudget: 'Budget',
      overEstimate: 'Over {suffix}'
    }
  },
  programsOverview: {
    keyPerformanceIndicators: 'Key Performance Indicators',
    programProfitability: 'Program Profitability',
    kpi: 'KPI'
  },
  expansionPanelActionButton: {
    showDetails: 'Show Details',
    hideDetails: 'Hide Details'
  },
  footer: {
    repliconCopyright: 'Replicon Inc, © 1996 - {year}'
  },
  externalLinks: {
    reports: 'Reports',
    administration: 'Administration',
    team: 'Team Approvals'
  },
  groups: {
    costCenter: 'Cost Center',
    department: 'Department',
    division: 'Division',
    employeeType: 'Employee Type',
    location: 'Location',
    serviceCenter: 'Service Center',
    costCenters: 'Cost Centers',
    departments: 'Departments',
    divisions: 'Divisions',
    employeeTypes: 'Employee Types',
    locations: 'Locations',
    serviceCenters: 'Service Centers',
    resourcePools: 'Resource Pools'
  },
  substituteUsersDialog: {
    title: 'Substitute For a User',
    noMatchingUsers: 'No matching substitute users.'
  },
  scriptParamEditor: {
    addScriptParams: '+ {script}',
    removeScript: 'Remove {scriptType}',
    validations: {
      required: '{field} is a required field',
      duplicate: 'Specified {field} is already defined',
      maxAmount: 'Must be less than or equal to {maxAmount}',
      aboveZeroAmount: 'Must be above zero',
      startDate: 'Start Date must be <= End Date',
      endDate: 'End Date must be >= Start Date'
    },
    scriptParamsEditableCardDialog: '{text} Card'
  },
  expenseBillPlan: {
    readOnly: {
      title: 'Expenses'
    },
    edit: {
      title: 'Allowed Expenses & Estimates'
    },
    expensesCardDialog: 'expenses Card',
    expenseBillPlanCardDialog: 'expense Bill Plan Card',
    noPermissionToEditBillPlan:
      'You do not have access to view/edit expense codes, therefore cannot add/edit expense codes to this bill plan.',
    estimatedAmount: 'Estimated: ',
    add: '+ {script}',
    remove: 'Remove {scriptType}',
    addExpenseType: '+ Add Expense Type',
    allowedExpensesAndEstimates: 'Allowed Expenses & Estimates',
    totalEstimatesAndActuals: 'Total Estimates/Actuals',
    allowedBillable: 'Allow Billable',
    showActuals: 'Show Actuals',
    noExpenseType:
      'No Expense Types added. Add expense types to allow them to \nbe entered against this project and estimates for them.',
    nonBillable: 'Non-Billable',
    billable: 'Billable',
    estimate: 'Estimate',
    estimates: 'Estimates',
    estimatesAndActuals: 'Estimates/Actuals',
    totalEstimates: 'Total Estimates',
    type: 'Type',
    billableType: 'Billable Type',
    total: 'Total',
    estimatedBillable: 'Estimated Billable',
    actualBillable: 'Actual Billable',
    nonBillableEstimated: 'Non-Billable Estimated',
    nonBillableActual: 'Non-Billable Actual',
    estimatedBillableAmount: 'Estimated Billable',
    actualBillableAmount: 'Actual Billable',
    estimatedNonBillableAmount: 'Non-Billable Estimated',
    actualNonBillableAmount: 'Non-Billable Actual',
    estimated: 'Total Estimated',
    actuals: 'Total Actual',
    removeDialog: {
      header: 'Remove Expenses Billing Type',
      message: 'Are you sure you want to remove the Expenses billing type?',
      actionButton: 'Remove Billing Type'
    }
  },
  expenses: {
    title: 'Expenses'
  },
  allowedExpensesAndEstimates: {
    columns: {
      expenseType: 'Type',
      markUp: 'Mark Up',
      billableType: 'Billable Type',
      estimatedBilling: 'Estimated Billing',
      totalEstimates: 'Total Estimates',
      totalEstimatesAndActuals: 'Total Estimates/Actuals',
      estimate: 'Estimate'
    },
    estimates: 'Estimates',
    estimatesAndActuals: 'Estimates/Actuals',
    showActuals: 'Show Actuals',
    title: 'Allowed Expenses & Estimates',
    addExpenseType: '+ Add Expense Type',
    remove: 'Remove Billing Type',
    billExpenses: 'Bill Expenses',
    approvalStatus: {
      any: 'Any',
      onlyApproved: 'Only Approved'
    }
  },
  profitabilityLegends: {
    profit: 'Profit',
    loss: 'Loss'
  },
  profitabilityDrilldown: {
    client: 'Client',
    program: 'Program',
    revenue: 'Revenue',
    profit: 'Profit',
    cost: 'Cost',
    noClientDataForPeriod: 'No client data for {period}',
    noProgramDataForPeriod: 'No program data for {period}',
    showMore: 'Show More',
    total: 'Total'
  },
  clientsSummary: {
    allClients: 'All Clients',
    myClients: 'My Clients',
    inactive: 'Inactive'
  },
  programsSummary: {
    allPrograms: 'All Programs',
    myPrograms: 'My Programs',
    inactive: 'Inactive'
  },
  resourceAvailabilityCard: {
    availability: 'Availability',
    allocation: 'Allocation',
    allocated: 'Allocated',
    overAllocated: 'Over Allocated',
    holiday: 'Holiday',
    timeOff: 'Time Off',
    noLocation: 'No Location',
    noDivision: 'No Division',
    noCostCenter: 'No Cost Center',
    noServiceCenter: 'No Service Center',
    noDepartment: 'No Department',
    noEmployeeType: 'No Employee Type',
    noResourcePools: 'No Resource Pools',
    resourceAvailability: 'Resource Availability',
    role: 'Role',
    fte: 'FTE',
    hours: 'Hours',
    resources: 'Resources',
    totalResources: 'Total Resources',
    location: 'Location',
    division: 'Division',
    serviceCenter: 'Service Center',
    costCenter: 'Cost Center',
    fittingAllocation: 'Fitting Allocation',
    employeeType: 'Employee Type',
    department: 'Department',
    total: 'Total',
    countTooltip: '{count} Resources as of {period}'
  },
  revenueRunSchedule: {
    status: 'Status',
    recognizableRevenue: 'Recognizable Revenue',
    deferredRevenue: 'Deferred Revenue',
    open: 'Open',
    closed: 'Closed',
    recognizedRevenue: 'Recognized Revenue',
    revenueForecast: 'Revenue Forecast',
    revenueVariance: 'Revenue Variance',
    recalculateRevenueForecast: 'Recalculate Revenue Forecast',
    asOfDate: 'As of {date}',
    calculating: 'Calculating...',
    carryOver: 'Carry Over'
  },
  kpiCharts: {
    cost: 'Cost',
    hours: 'Hours',
    margin: 'Margin',
    profit: 'Profit',
    budget: 'Budget Burned',
    scope: 'Scope Completed',
    variation: 'Budget vs Scope',
    billableRevenue: 'Billable Revenue',
    estimated: 'Estimated: ',
    forecast: 'Forecast: ',
    recognizedRevenue: 'Recognized Revenue',
    profitMargin: 'Profit Margin',
    revenuePerResource: 'Revenue Per Resource',
    resources: 'Resources: ',
    predictedEndDate: 'Predicted End Date',
    projectRiskScore: 'Project Risk Score',
    projectRiskScoreInfo:
      'Risk Score is calculated as a weighted average of Predicted End Date and Confidence.',
    projectRiskScoreInfoMore:
      'Risk Score is capped between 0-100, with 0 meaning No Risk and 100 meaning High Risk.'
  },
  duplicateProject: {
    name: 'Name',
    code: 'Code',
    startDate: 'Planned Start Date',
    endDate: 'Planned End Date',
    title: 'Duplicate Project',
    copyTasks: 'Duplicate Tasks',
    toThisProject: 'To This New Project',
    copyResourceRequests: 'Duplicate Resource Requests',
    copyBillPlan: 'Duplicate Bill Plan',
    creatingProject: 'Creating Project…',
    duplicationComplete: 'Duplication Complete',
    duplicationMessage:
      'Click Close to continue duplication in the background. Your new project should be available in a few minutes.',
    goToNewProject: 'Go To New Project',
    close: 'close',
    projectNameExceedsMaxLength: 'Project name cannot exceed 255 characters',
    projectCodeExceedsMaxLength: 'Project code cannot exceed {max} characters',
    enterProjectNameErrorMsg: 'Please enter a name',
    enterClientErrorMsg: 'Please select a client',
    projectToDuplicate: 'Project to Duplicate',
    duplicationInProgress: 'Project Duplication In Progress',
    duplicationInProgressDescription:
      'Please wait while we finish duplicating Resource Requests to this Project.',
    error:
      'An error occurred while duplicating, the new project was not created.',
    projectDuplicatedWithErrors: 'Project Duplicated With Errors',
    duplicateResourceRequestsError:
      'Resource Requests could not be duplicated.',
    duplicateTasksError: 'Tasks could not be duplicated.',
    duplicateBillPlanError: 'Bill Plan could not be duplicated.'
  },
  addRoleDialog: {
    addRole: 'Add Role',
    creatableRole: 'Role {label}',
    role: 'Role',
    name: 'Name',
    description: 'Description',
    costRate: 'Cost Rate',
    billingRate: 'Billing Rate',
    nameExceedsMaxLength: 'Name cannot exceed 50 characters',
    descriptionExceedsMaxLength: 'Description cannot exceed 255 characters',
    duplicateRole: 'Role name should be unique.',
    enterNameErrorMsg: 'Please enter a name',
    noSkills: 'No skill requirements set for this role.'
  },
  profitabilityChart: { profit: 'Profit' },
  offlinePage: {
    description: 'It appears your device is currently offline.',
    title: 'Offline'
  },
  NONE: 'NONE',
  projectsCardHelp: {
    overBudget: 'Over Budget',
    overBudgetAlert:
      'Alerts you to any projects which have Actuals or Estimated at Completion higher than their Budget, either for hours or cost.',
    overEndDate: 'Over End Date',
    overEndDateAlert:
      'Alerts you to any projects which have an Estimated Task Completion Date that is past the End Date set on the project.',
    overHoursBudget: 'Over Hours Budget',
    overHoursBudgetAlert:
      'Alerts you to any projects which have Actual Hours or Estimated Hours at Completion higher than their Budget Hours.'
  },
  projectProgressSummaryChartHelp: {
    budgetBurned: 'Budget Burned',
    budgetBurnedAlert:
      'The percent of the Project Budget Hours used by the Actual Hours recorded on this project.',
    scopeCompleted: 'Scope Completed',
    scopeCompletedAlert:
      "How much of this project's work have been completed. Based on the Hours Remaining reported on the tasks.",
    variation: 'Budget vs Scope',
    variationAlert:
      'A comparison of the remaining Project Budget Hours, and the Task Hours Remaining.',
    costBudgetBurnedAlert:
      'The percent of the Project Budget Cost used by the Actual Cost recorded on this project.',
    costScopeCompletedAlert:
      "How much of this project's total work and projected expenses have been incurred, as a cost amount.",
    costVariationAlert:
      'A comparison of the remaining Project Budget Cost, and the Project Cost Remaining.',
    projectRisk: 'Predicted End Date',
    projectRiskAlert: 'Predicted end date based on Machine Learning.',
    projectRiskScore: 'Project Risk Score',
    projectRiskScoreAlert: 'Project risk score based on Machine Learning.'
  },
  projectHoursSummaryChartHelp: {
    budget: 'Budget',
    budgetAlert: 'The budget hours set on this project.',
    project: 'Project',
    projectAlert:
      'Compares the estimate hours set on this project with the total hours worked anywhere on the project.',
    tasks: 'Tasks',
    tasksAlert:
      'Compares the total estimated hours at completion of all the tasks with the hours worked on all the tasks.',
    resources: 'Resources',
    resourcesAlert:
      'Compares the allocated hours from the project’s resource plan with the hours worked by those resources.'
  },
  projectCostsSummaryChartHelp: {
    budget: 'Budget Cost',
    budgetAlert: 'The budget cost set on this project.',
    project: 'Project',
    projectAlert:
      'Compares the estimated cost set on this project with the total cost incurred anywhere on the project.',
    tasks: 'Tasks',
    tasksAlert:
      'Compares the total estimated cost at completion of all the tasks with the cost incurred on all the tasks.',
    resources: 'Resources',
    resourcesAlert:
      'Compares the allocated cost from the project’s resource plan with the cost incurred by those resources.'
  },
  resourceHoursHelp: {
    hours: 'Hours Worked',
    projectHoursAlert: "The hours work by this project's resources by month.",
    clientHoursAlert:
      "The hours worked by resources on this client's projects, by month.",
    programHoursAlert:
      "The hours worked by resources on this program's projects, by month.",
    allocated: 'Allocated Hours',
    projectAllocatedAlert:
      'The hours resources were allocated to work on this project by month.',
    clientAllocatedAlert:
      "The hours resources were allocated to work on this client's projects, by month.",
    programAllocatedAlert:
      "The hours resources were allocated to work on this program's projects, by month."
  },
  resourceCostHelp: {
    actual: 'Actual Cost',
    projectActualAlert:
      "The cost of the hours worked by this project's resources by month.",
    clientActualAlert:
      "The cost of the hours worked by resources on this client's projects, by month.",
    programActualAlert:
      "The cost of the hours worked by resources on this program's projects, by month.",
    allocated: 'Allocated Cost',
    projectAllocatedAlert:
      'The estimated cost of the hours resources were allocated to work on this project by month.',
    clientAllocatedAlert:
      "The estimated cost of the hours resources were allocated to work on this client's projects, by month.",
    programAllocatedAlert:
      "The estimated cost of the hours resources were allocated to work on this program's projects, by month."
  },
  tasksCardHelp: {
    overEndDate: 'Over End Date',
    pendingTaskBeats: 'Pending  SmartBeats',
    overHoursEstimate: 'Over Hours Estimate',
    overHoursEstimateAlert:
      'Alerts you to any tasks which have Actual Hours or Estimated Hours at Completion higher than their Estimated Hours.',
    overEndDateAlert:
      'Alerts you to any tasks which have an Estimated Completion Date that is past the End Date set on the tasks.'
  },
  resourcesCardHelp: {
    proposedRequests: 'Proposed Requests',
    proposedRequestsAlert:
      'Resource Requests which have resources proposed for them. Accept or reject the proposed resources for your projects.',
    draftRequests: 'Draft Requests',
    draftRequestsAlert:
      'Resource Requests which have not been submitted yet. Submit to request the resources.'
  },
  downloadUserBeatsApp: {
    card: {
      subTitle: 'Desktop App',
      description: 'Entering beats has never been faster'
    },
    button: {
      defaultMessage: 'DESKTOP APP',
      cardMessage: 'Download Now',
      close: 'Close'
    }
  },
  rescheduleProject: {
    title: 'Reschedule Project',
    cancel: 'Cancel',
    newStartDate: 'New Start Date',
    RescheduleBillPlanDisabledInfo:
      'Migrated projects don’t have bill plans and can not be rescheduled',
    originalStartDate: 'Original Start Date',
    rescheduleTasks: 'Reschedule Tasks',
    rescheduleResourceRequests: 'Reschedule Resource Requests',
    daysLater: 'Moving dates {value} {day} later.',
    earlierDays: 'Moving dates {value} {day} earlier.',
    rescheduleBillPlan: 'Reschedule Bill Plan',
    rescheduling: 'Rescheduling Project',
    allocatedResourcesInfo:
      'Resource Requests cannot be automatically rescheduled because resources have already been allocated.\nThey will have to be rescheduled manually.',
    noTaskDefined: 'No Tasks have been defined.',
    reschedulingComplete: 'Rescheduling Complete',
    movingProject: 'Moving Project...',
    movingResourceRequests: 'Moving Resource Requests...',
    movingTasks: 'Moving Tasks...',
    movingBillingPlan: 'Moving Billing Plan...',
    reschedulingFailed: 'Rescheduling Failed',
    close: 'Close',
    backgroundMessage:
      'Click Close to continue rescheduling in the background. Your project should be rescheduled in a few minutes.',
    day: 'day',
    days: 'days',
    hasBillingInfo:
      'Bill Plan cannot be automatically rescheduled because billing has already started.\nThey will have to be rescheduled manually.',
    originalStartDateValidation: 'Please enter an original start date.',
    newStartDateValidation: 'Please enter a new start date.',
    projectStartDateGreaterThanEndDate:
      'Setting an Original Start Date of {originalStartDate} would result in a Start Date after the project End Date of {endDate}.',
    tip: {
      header: 'Tip: ',
      message: 'use {value} to adjust the dates of all your project data.'
    },
    datesUpdating:
      'This project is being rescheduled. Please wait while the dates are updated.',
    resourceRequestsError: 'Resource Requests could not be rescheduled.',
    tasksError: 'Tasks could not be rescheduled.',
    billPlanError: 'Bill Plan could not be rescheduled.',
    rescheduleProjectError: 'Project Rescheduled with Errors',
    completedTasksCount: '{value} Complete{separator}',
    inProgressTasksCount: '{value} In Progress{separator}',
    notStartedTasksCount: '{value} Not Started{separator}',
    tasksToReschedule: 'Tasks to Reschedule',
    tasksToRescheduleType: {
      allTasks: 'All Tasks',
      inProgress: 'Not Started & In Progress Tasks',
      notStarted: 'Not Started Tasks'
    },
    adjustWorkDays: 'Adjust dates for work days',
    adjustWorkDaysHelpTitle:
      'Dates will be adjusted to ensure that the number of work days and hours remains the same, Instead of simply shifting dates.',
    adjustWorkDaysHelpDescription:
      'This is done by shifting the project Start Date to the New Start Date. Then all the other dates are moved relative to the New Start Date by making sure the number of work days between them is the same as it was with the Original Start Date.',
    noResourceRequests: 'No Resource Requests have been created.',
    acceptedResourcesCount: '{value} with Accepted Resources, ',
    proposedResourcesCount: '{value} with Proposed Resources',
    resourceRequestsToReschedule: 'Resource Requests to Reschedule',
    releaseAndRescheduleAllRequests:
      'Release All Resources & Reschedule All Resource Request',
    rescheduleWithoutAcceptedResourceRequests:
      'Reschedule Resource Requests without Accepted Resources',
    rescheduleWithoutProposedOrAcceptedResourceRequests:
      'Reschedule Resource Requests without Proposed or Accepted Resources',
    permissionErrorMessage: {
      tasks:
        'You do not have permission to change the Tasks for this project.\nThey will not be rescheduled, and will need to be updated manually.',
      billPlan:
        'You do not have permission to change the Bill Plan for this project.\nIt will not be rescheduled, and will need to be updated manually.'
    },
    reschedule: 'Rescheduling…'
  },
  businessIntelligence: {
    api: 'Replicon API for BI Services',
    title: 'Business Intelligence',
    powerBi: 'Microsoft Power BI',
    tableau: 'Tableau',
    bigQuery: 'Google BigQuery',
    apiServiceDescription:
      'This option is typically used by customers who have their own proprietary data warehouse solution, so they require a custom connection.',
    customerSupport: 'Customer support',
    analyticsConnection:
      'If you want to build your own analytics connection, you can access the ',
    forMoreInformation: ' for more information.',
    customerSuccessManager: 'Please contact your Customer Success Manager or ',
    bigQueryDescription:
      'If you use Google BigQuery for data warehousing, we have a data connector that can extract your data from Replicon and populate your data warehouse.',
    bigQuerySubDescription:
      'If you use other business intelligence tools besides Tableau and Power BI, you can use the BigQuery connection to access your Replicon data for use with those tools.',
    learnMore: 'Learn More',
    directly: ' directly. ',
    offerTwoConnection: 'We offer two connection options for ',
    templateDashBoard: ' Template dashboards',
    templateDashBoardDesc:
      ' use your organization’s data as the source and starting point for sample charts.',
    repliconConnectorFile: 'Replicon connector file',
    downloadConnector:
      'Download connector file and save it to the designated location',
    createThisDirectory: 'Create this directory on your machine: ',
    openClosePowerBI: 'If Power BI is open, close it.',
    downloadThe: 'Download the ',
    copyToTheDirectory: ' and copy it to the directory you just created.',
    downloadAndLaunch: 'Download and launch the sample data file',
    downloadAndSampleData: 'Download the sample data zip file: ',
    polarisData: 'Polaris sample data file',
    unzipPpm:
      'Unzip the file, and launch the PolarisPPMTemplate.pbit template file.',
    unzipTheFile:
      'Unzip the file, and launch the PolarisPSITemplate.pbit template file.',
    authenticateToGain: 'Authenticate to gain access to your Replicon data',
    click: 'Click ',
    signIn: 'Sign In.',
    useCompanyName:
      'Use your company name, user name, and password to log in to your Replicon instance.',
    signInMessage: "You'll see a message stating you’re signed in.",
    connect: 'Connect.',
    promoSectionTitle:
      'We integrate with the leading Business Intelligence software.',
    promoSectionDescription:
      'Your Replicon data is a rich source of information that can help you identify trends and issues that affect your business. We provide easy connections to Business Intelligence software and an open API for BI Services. Use your data, with your tools, on your terms.',
    tryNow: 'Try It Now',
    refreshAfterConnecting:
      'The data will now refresh. This could take a while, depending on the size of your database.',
    refreshIsComplete:
      'Once the refresh is complete, you’ll see your Replicon data populating the analytics charts.',
    refreshAndSave: 'Refresh and save dashboards',
    clickRefresh: 'Refresh.',
    refreshTitle:
      'If you want to see changes you’ve made to your data in Replicon reflected in the charts, click ',
    saveAsDescription:
      'This version will retain the Replicon credentials used in the template file, so you can refresh and view updates with it.',
    chooseNameAndLocation:
      ', and choose a name and location for a .pbix version of the workbook.',
    saveAs: 'File → Save As',
    moreInformation: 'More Information…',
    sampleDashboard: 'Sample Dashboard',
    repliconConnectorTitle: 'Replicon’s connector for Microsoft Power BI',
    repliconConnectorSubTitle:
      ' allows you to create charts from scratch. Try this option if you don’t need the examples from the sample dashboards.',
    setPowerBi: 'Set Power BI security settings to allow connection',
    launchPowerBI: 'Launch Power BI.',
    goTo: 'Go to ',
    optionsAndSettings: 'File → Options and Settings → Options.',
    thenSelect: 'Then select ',
    security: 'Security',
    andInThe: ', and in the ',
    dataExtensions: 'Data Extensions ',
    sectionEnableThe: 'section enable the ',
    notRecommend:
      '(Not Recommended) Allow any extension to load without validation or warning',
    ok: 'OK.',
    optionAndClick: 'option, and click ',
    securityDescription:
      'This lower security setting is required to open dashboard files, since they are not certified connections. You can switch this setting back later, if desired.',
    closePowerBI: 'Close Power BI.',
    downloadConnectorFile:
      'Download connector file and save it to the designated location',
    createDirectory: 'Create this directory on your machine: ',
    launchConnector: 'Launch the connector',
    relaunchPowerBI: 'Re-launch Power BI.',
    getData: 'Get Data',
    enter: 'Enter ',
    replicon: 'Replicon',
    inTheSearchField: ' in the search field.',
    fileNameDescription:
      'The file name should display in the search results on the right-hand side of the dialog.',
    selectFileName: 'Select the file name, and click Connect.',
    betaConnectorMessage:
      'You’ll see a message indicating this is a beta connector.',
    continue: 'Continue.',
    allow: 'Allow',
    dialogThatDisplay: ' in the dialog that displays.',
    a: 'A ',
    navigator: 'Navigator',
    dataSetDisplay:
      ' page displays. The names of the data sets available to you display in the left column.',
    importRepliconData: 'Import your Replicon data into Power BI',
    doubleClickOnDataSet:
      'Double-click a data set to view the available data tables. Enable the check boxes beside each table you want to work with. Then click ',
    load: 'Load.',
    connectorSetting: 'Connection Settings',
    howToAccessSelectedTables:
      ' dialog displays, asking you to choose how to access the tables you selected.',
    recommenChoosingImportOption:
      'We recommend choosing the Import option, which will move all data at once, rather than by queries. This option is sufficient for most customers.',
    dataImportedIntoPowerBI:
      'Your Replicon data will be imported into Power BI.',
    sampleDashboardZipFile: 'Polaris sample dashoard zip file.',
    unzipFileAndLaunch: 'Unzip the file, and launch the ',
    templateFile: ' template file.',
    authenticationFields:
      'A dialog will open, displaying authentication fields for your Replicon account.',
    afterAMoment: 'After a moment, a ',
    processingRequest: 'Processing Request',
    dialogDisplays: ' dialog displays.',
    connectorLoadsData:
      'You will have to wait a moment, or longer, depending on the size of your database, as the connector loads your data.',
    promptedToAuthenticateAgain:
      'You will be prompted to authenticate again. And, once again, you’ll need to wait for data to load.',
    authenticateTwice:
      'You need to authenticate twice because your data is divided into two data sets, TimeEntry and TimeAllocation, and you must grant access to each.',
    displayDemoChart:
      'When your Replicon data has loaded, the demo charts will display, using your data.',
    saveDataChartsCopy: 'To save a copy of your data charts, ',
    saveWorkbook: ' and save the workbook.',
    openOriginalTemplateFile:
      'When you open this copy or the original template file, the charts will use the data available when you last saved the file.',
    seeUpdatedData: 'To see updated data, when changes are made Replicon.',
    data: 'Data ',
    refreshAllExtracts: 'Refresh All Extracts.',
    whileTheDataRefreshes: ', then wait while the data refreshes.',
    webConnectorForTableau: 'Replicon’s Web Data Connector for Tableau ',
    chartFromScratch:
      'allows you to create charts from scratch. Try this option if you don’t need the examples from the sample dashboards.',
    launchTableau: 'Launch Tableau',
    fromThe: 'From the ',
    sideMenu: ' side menu, under ',
    toAServer: 'To a Server',
    clickWebConnector: ', click ',
    webDataConnector: 'Web Data Connector',
    topOfTheDialog:
      ' in either of the fields that display – the text will appear in the field at the top of the dialog in either case. Click ',
    loginFields: 'A dialog showing Replicon’s login fields displays.',
    connectorLoadsYourData:
      'You may have to wait a moment as the connector loads your data.',
    tableauWorkbook:
      'A Tableau workbook will open, with the available Replicon data tables displayed on the left-hand side of the page.',
    refresh: 'Refresh',
    launchTableauTitle: 'Launch Tableau.',
    fromTheConnect: 'Connect',
    clickWebDataConnector: 'Web Data Connector.',
    topOfTheDialogEnter: 'Enter.',
    settingExampleOptions: 'Settings example - Options',
    settingExampleSecurity: 'Settings example - Security',
    getDataExample: 'Get data example',
    repliconSearchExample: 'Replicon search example',
    connectorSelectExample: 'Connector select example',
    thirdPartyWarningExample: 'Third party warning example',
    signInExample: 'Sign in example',
    loginCompanyExample: 'Login example - Company',
    loginDataAccessExample: 'Login example - Data Access',
    connectExample: 'Connect example',
    navigatorExample: 'Navigator example',
    navigatorTablesExample: 'Navigator tables example',
    connectionSettingsExample: 'Connection Settings example',
    refreshExample: 'Refresh example',
    processingRequestExample: 'Processing request example',
    refreshAllExtractsExample: 'Refresh all extracts example',
    webDataConnectorMenuExample: 'Web data connector menu example',
    webDataConnectorUrlExample: 'Web data connector url example',
    dataTablesExample: 'Data tables example',
    help: 'Help'
  },
  userBeatsBotAdCard: {
    title: 'Polaris chat bots',
    nowAvailable: 'Now Available!'
  },
  selectionBar: {
    selected: '{count} Selected',
    remove: 'Remove',
    status: 'Status'
  },
  selectionStatusBar: {
    clearIcon: 'Clear Icon'
  },
  bulkRemoveDialog: {
    project: 'project',
    projects: 'projects',
    removeProjects: {
      title: 'Remove Projects',
      message: 'Are you sure you want to remove {value} {object}?'
    },
    archiveProjects: {
      title: 'Archive Projects',
      message: 'Are you sure you want to archive {value} {object}?'
    },
    removeAndArchiveProjects: {
      title: 'Remove & Archive Projects',
      message:
        'Are you sure you want to archive {archiveValue} {archiveObject}, and remove {removeValue} {removeObject}?'
    },
    info: {
      archive:
        '{value} {object} has hours or other data logged against it, preventing it from being removed.  It will be archived instead.',
      xArchive:
        '{value} {object} have hours or other data logged against them, preventing them from being removed.  They will be archived instead.',
      cancel: 'Cancel does not undo changes already saved.'
    },
    error: {
      permission: 'You do not have permission to remove {value} {object}.',
      unknown: '{title} failed with error'
    },
    actions: {
      cancel: 'Cancel',
      ok: 'Ok'
    },
    success: {
      removed: '{value} {object} successfully removed.',
      archived: '{value} {object} successfully archived.'
    }
  },
  bulkProjectEditStatus: {
    ok: 'Ok',
    cancel: 'Cancel',
    project: 'Project',
    projects: 'Projects',
    cancelActionInfo: 'Cancel does not undo changes already saved.',
    status: 'Status',
    inProgress: 'In Progress',
    complete: 'Complete',
    unknown: '{title} failed with error',
    editStatus: 'Edit Status',
    updateStatus: 'Update Status',
    noPermission: 'You do not have permission to edit {value} {object}.',
    changeStatusMessage: 'Change selected projects to the new status'
  },
  myTeamPageTabs: {
    timeOff: 'Time Off',
    expenses: 'Expenses',
    timesheets: 'Timesheets',
    timeWorkbench: 'Time Workbench',
    crewTime: 'Crew Time',
    timePunches: 'Time Punches'
  },
  searchAutocomplete: {
    moreAvailable: 'More {option} available. Search to refine results.'
  },
  moreOptions: {
    tags: 'Tags',
    roles: 'Roles',
    skills: 'Skills',
    users: 'Users',
    clients: 'Clients',
    projects: 'Projects',
    programs: 'Programs',
    location: 'Location',
    division: 'Division',
    department: 'Department',
    costCenter: 'Cost Center',
    employeeType: 'Employee Type',
    serviceCenter: 'Service Center',
    resourcePools: 'Resource Pools',
    projectManagers: 'Project Managers',
    projectCoManagers: 'Project Co-Managers',
    clientRepresentatives: 'Client Representatives',
    resources: 'Resources'
  },
  issueActionButton: {
    loadingButton: 'Loading Button',
    dropdown: 'Dropdown'
  },
  referenceNumberField: {
    settingLabel: 'Setting Label'
  },
  billPlanPreRequisites: {
    or: 'or',
    noMileStoneTasks:
      'No milestone tasks created. Create milestone tasks to add this billing type.',
    rateCardSetupRequired: 'Rate card setup required.',
    noViewTaskPermissionMessage:
      'You do not have access to View/Edit Tasks, therefore can not Add/Edit Milestones to this Bill Plan.',
    singleMilestoneTask: 'Milestone Task Added.',
    mileStoneTasksAdded: 'Milestone Tasks Added.',
    roleRatesAdded: 'Role Rates Added.',
    noEditTaskPermissionMessage:
      'Can not Create a New Milestone or Edit the Existing One.',
    noCreateTaskPermissionMessage:
      'No milestone tasks created. You do not have permission to create milestone tasks.',
    singleRateAdded: 'Rate Added',
    multipleRatesAdded: 'Rates Added',
    noViewEditRatePermission:
      'You do not have permission to edit the rate card for this billing type.',
    noBillingRatesDefinedMessage:
      "No Billing Rates defined in this project's Rate Card.",
    billingRateRequiredMessage:
      '- Billing Rates are required to calculate the amount charged for hours worked.',
    noEditRatePermission: '- You do not have edit permission for rates.',
    noViewEditRateCardPermission:
      'You cannot view or create this billing type.',
    rates: {
      resource: 'Resource',
      role: 'Role',
      project: 'Project'
    }
  },
  pageOutOfBounds: {
    firstPageButtonTitle: 'First Page',
    firstPageMessage: 'Do you want to go to the first page?',
    firstOrLastPageMessage:
      'Do you want to go to the first page, or go back to the last page?',
    backToLastRecordButtonTitle: 'Back to Last Record',
    noAvailableRecords: 'You have reached the end of the available records.'
  },
  listTableNavigation: {
    moreProjects: '{from}-{to} of many',
    pageOutOfBound: '- of {to}',
    fromToAndCount: '{from}-{to} of {count}',
    pagination: 'Pagination'
  },
  tableHeaderNavigation: {
    moreProjects: '{from}-{to} of many',
    pageOutOfBound: '- of {to}',
    fromToAndCount: '{from}-{to} of {count}'
  },
  vendorListTable: {
    closedDate: 'Closed Date',
    vendorName: 'Vendor',
    vendors: 'Vendors',
    list: 'List',
    noVendorsMsgCanView: 'No vendors have been added',
    noVendorsMsgCanEdit:
      'No vendors have been added, add one by clicking the "+ Vendor" button in the bottom right.',
    noFilteredVendorsMsg: 'No matching vendors found.',
    noVendorsSubTitle: 'No vendors have been added',
    addVendorMsg: 'Add a vendor to get started!',
    addVendor: 'Add Vendor',
    errorMsg: 'An unexpected error has occurred. Please try again later.',
    alt: 'No vendors',
    selectedItem: '{count} Selected',
    remove: 'Remove',
    confirmationTitleForRemoveResource: 'Remove Resource',
    confirmationMessageForDisableResources:
      'Are you sure you want to remove the resource?'
  },
  vendorResourcesListTable: {
    noResourcesMsg: 'No resources have been assigned',
    name: 'Name',
    employeeId: 'Employee Id',
    startDate: 'Start Date',
    endDate: 'End Date',
    role: 'Role',
    supervisor: 'Supervisor',
    department: 'Department'
  },
  vendorResourceAssignmentDialog: {
    title: 'Assign Resources:',
    errorMsg:
      'There was an unexpected error assigning the resource. Please try again later.',
    close: 'Close',
    assignResource: 'Assign Resource',
    noResourcesMessage: 'No matching resources found.',
    adjustFilterMessage:
      'Try adjusting your search filters to find matching resources.'
  },
  vendorAddFab: {
    label: 'Add Vendor',
    shortLabel: 'Vendor'
  },
  resourceAssignmentAddFab: {
    label: 'Assign Resources'
  },
  vendorTabs: {
    list: 'List',
    summary: 'Summary',
    resources: 'Resources'
  },
  vendorSummary: {
    active: 'Active',
    inactive: 'Inactive'
  },
  vendor: {
    name: 'Name',
    closedDate: 'Closed Date',
    menu: 'Menu',
    basicInfo: 'Basic Info',
    removeVendor: 'Remove Vendor',
    deleteDialog: {
      removeVendor: 'Remove Vendor',
      description: 'Are you sure you want to remove this vendor?'
    },
    removeDialog: {
      title: 'Remove Vendor',
      content: 'Are you sure you want to remove this vendor?',
      action: 'Remove Vendor',
      cancel: 'Cancel'
    },
    disableDialog: {
      title: 'Deactivate Vendor',
      content: 'Vendor is assigned to one or more resources.',
      secondaryContent:
        ' Vendor cannot be removed, do you want to make it inactive?',
      action: 'Deactivate Vendor',
      cancel: 'Cancel'
    },
    validationMessages: {
      nameRequired: 'Please enter a name',
      nameExceedsMaxLength: 'Vendor name cannot exceed 255 characters.',
      duplicateVendor: 'There is already a vendor with this name.'
    }
  },
  zeroTimeLink: {
    zeroTime: 'ZeroTime™'
  },
  projectLink: {
    CHANGEREQUEST: 'Change Request',
    CHANGEREQUESTFOR: 'Change Request For',
    DUPLICATES: 'Duplicates',
    ISDUPLICATEDBY: 'Is Duplicated By',
    HASPHASE: 'Has Phase',
    PHASEOF: 'Phase Of',
    RELATESTO: 'Relates To',
    SELF: 'This Project',
    title: 'Linked Projects',
    linkType: 'Link Type',
    projectToLink: 'Project to Link',
    addLink: '+ Add Link',
    to: 'To',
    addFab: { label: 'Link Project' },
    noMatchingLinks: 'There are no projects linked to this project.',
    clickToAddLink: 'To add a link, press the{value}button below.',
    linkXProjects:
      '{count, plural, =0 {Link Projects} one {Link # Project} other {Link # Projects}}',
    includeProject: 'Include this project in the list.',
    removeLink: {
      title: 'Removing Link from {project}',
      content:
        'Confirming this dialog will remove the {linkType} link from {project}\n\nThis will not delete the project. You can add the link again later if you need to.\n\nAre you sure you want to remove this link?',
      cancel: 'Cancel',
      remove: 'Remove'
    }
  }
};

export default flattenMessages(messages);
