/* eslint-disable react/jsx-max-depth */
import {
  Button,
  Chip,
  Divider,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core';
import ExpandLessSharp from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreSharp from '@material-ui/icons/ExpandMoreSharp';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import { PropTypes } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import { NoValue, User } from '~/modules/common/components';
import ErrorCard from '~/modules/tasks/modecs/ErrorCard';
import Date from '~/modules/common/components/Date';
import { TASK_STATUS } from '~/modules/common/enums';
import DateRange from '~/modules/common/components/DateRange';
import Decimal from '~/modules/common/components/Decimal';
import { RoleName } from '~/modules/common/components/entities/role';
import { resolveValueOrDefault } from '~/modules/common/components/FormCustomField';
import FormSelectField from '~/modules/common/components/FormSelectField';
import ReadOnlyContainer from '~/modules/common/components/ReadOnlyContainer';
import ExtensionFieldValueText from '~/modules/extensionFields/components/ExtensionFieldValueText';
import { useMeContext } from '~/modules/me';
import { useTaskCustomFieldDefinitions } from '~/modules/tasks/modecs/useTaskCustomFieldDefinitions';
import { useDialogState } from '~/modules/common/hooks';
import RescheduleTaskDialog from '~/modules/tasks/components/RescheduleTaskDialog';
import { useSetTaskTimeEntryDateRange } from '~/modules/tasks/hooks';
import { ClosedOnDatePicker, ReadOnlyCustomFieldValue } from './components';
import { useExpandedState, useOnOpenCloseSelection } from './hooks';
import TaskDrawerHeaderInfoBarExpanded from './TaskDrawerHeaderInfoBarExpanded';
import RescheduleButton from './RescheduleButton';

const useStyles = makeStyles({
  label: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize
  },
  wrapper: ({ isRolledUpTaskEstimateCalculationMethodEnabled }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingBottom: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.spacing(1)
      : theme.spacing(2)
  }),
  flexRow: ({ isRolledUpTaskEstimateCalculationMethodEnabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: isRolledUpTaskEstimateCalculationMethodEnabled
      ? 'inherit'
      : 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1)
  }),
  rowItem: {
    display: 'flex',
    paddingRight: theme.spacing(2)
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 0,
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(1.5)
  },
  subheading: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  boldItem: {
    fontWeight: theme.typography.fontWeightMedium
  },
  textItem: {
    paddingLeft: '0.3em'
  },
  lineItem: {
    paddingLeft: theme.spacing(1)
  },
  smallIconButton: {
    padding: '0'
  },
  expandButton: ({ isRolledUpTaskEstimateCalculationMethodEnabled }) => ({
    color: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.palette.text.main
      : theme.palette.text.secondary,
    marginTop: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.spacing(2.25)
      : 0,
    textTransform: isRolledUpTaskEstimateCalculationMethodEnabled
      ? 'none'
      : undefined
  }),
  expanded: {
    backgroundColor: theme.palette.background.default
  },
  details: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginBottom: 'auto'
  },
  chip: {
    backgroundColor: theme.palette.text.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5)
  },
  statusChip: ({ status }) => ({
    backgroundColor: theme.palette.taskStatus[status].color,
    fontWeight: 600
  }),
  field: ({ isRolledUpTaskEstimateCalculationMethodEnabled, status }) => ({
    color: theme.palette.common.white,
    backgroundColor: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.palette.taskStatus[status].color
      : theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    padding: isRolledUpTaskEstimateCalculationMethodEnabled
      ? 0
      : theme.spacing(0, 0, 0, 1),
    fontWeight: isRolledUpTaskEstimateCalculationMethodEnabled ? 600 : 400
  }),
  inputBase: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    height: theme.spacing(4),
    '&.MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.white
    },
    justifyContent: 'center',
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(3.75),
      backgroundColor: 'transparent'
    }
  },
  select: {
    paddingLeft: '5px',
    paddingTop: theme.spacing(1)
  },
  select2: {
    padding: theme.spacing(0.25, 1.5, 0, 1.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
    color: theme.palette.text.main
  },
  icon: ({ isRolledUpTaskEstimateCalculationMethodEnabled }) => ({
    color: isRolledUpTaskEstimateCalculationMethodEnabled
      ? theme.palette.text.main
      : theme.palette.common.white,
    right: theme.spacing(0.5)
  }),
  noDateIcon: {
    color: theme.palette.error.dark
  },
  noValue: { fontSize: theme.typography.body2.fontSize },
  roleName: {
    fontSize: theme.typography.body2.fontSize
  },
  description: {
    whiteSpace: 'pre-wrap',
    hyphens: 'auto',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  },
  range: ({ hasStartAndEndDate }) => ({
    marginTop: hasStartAndEndDate ? theme.spacing(1) : theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    display: hasStartAndEndDate ? 'inline' : 'flex',
    alignItems: hasStartAndEndDate ? undefined : 'center',
    color: 'inherit'
  }),
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    display: 'inline'
  },
  spacer: {
    color: 'inherit',
    fontWeight: 400,
    lineHeight: 'inherit'
  },
  container: {
    overflow: 'hidden',
    marginTop: theme.spacing(1.5)
  },
  name: {
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    paddingLeft: theme.spacing(1) - 1
  },
  rescheduleColumnItem: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(7),
    marginLeft: theme.spacing(1.5),
    alignItems: 'flex-start'
  }
});

const InputBase = classes => (
  <Input className={classes.inputBase} disableUnderline />
);

export const TaskDrawerHeaderInfoBar = ({
  task,
  expandedInitialState = false,
  handleRefetch
}) => {
  const {
    openCloseSelection,
    openCloseExpand,
    onOpenCloseExpand
  } = useOnOpenCloseSelection(task);
  const {
    open: isRescheduleTaskDialogOpen,
    openDialog: openRescheduleTaskDialog,
    closeDialog: closeRescheduleTaskDialog
  } = useDialogState(false);
  const { expanded, setExpanded } = useExpandedState(expandedInitialState);
  const { setTaskTimeEntryDateRange, loading } = useSetTaskTimeEntryDateRange();
  const intl = useIntl();
  const me = useMeContext();

  const {
    loading: customFieldDefsLoading,
    error: customFieldDefsError,
    customFieldDefinitions
  } = useTaskCustomFieldDefinitions();

  const {
    featureFlags: {
      isPsaPrpPsaPpmMergerEnabled,
      isPsaRmpTaskAllocation1Enabled
    },
    isRolledUpTaskEstimateCalculationMethodEnabled,
    isExpenseProductEnabled,
    hasViewProjectBillingOptions
  } = me;

  const {
    startDate,
    endDate,
    taskStatus,
    assignedUser,
    assignedRole,
    isClosedByTaskInheritance,
    isClosed,
    extensionFieldValues,
    initialEstimatedHours,
    timeAndExpenseEntryType,
    isTimeEntryAllowed,
    costTypeUri,
    description,
    project,
    rolledUpSummary
  } = task;

  const onRescheduleTaskSubmit = useCallback(
    async ({
      startDate: newStartDate,
      endDate: newEnddate,
      rescheduleTaskModificationOption
    }) => {
      await setTaskTimeEntryDateRange({
        taskId: task.id,
        startDate: newStartDate,
        endDate: newEnddate,
        rescheduleTaskModificationOption
      });
      closeRescheduleTaskDialog();
      handleRefetch();
    },
    [setTaskTimeEntryDateRange, handleRefetch, task, closeRescheduleTaskDialog]
  );

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting: task?.project?.projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const { hasCostType } = templateSettings || {};

  const hasStartAndEndDate = startDate && endDate;
  const actualHours = rolledUpSummary?.actualHours;
  const rolledUpTaskStatus =
    taskStatus === TASK_STATUS.COMPLETED
      ? TASK_STATUS.COMPLETED
      : actualHours
      ? TASK_STATUS.INPROGRESS
      : TASK_STATUS.NOTSTARTED;

  const classes = useStyles({
    status: isRolledUpTaskEstimateCalculationMethodEnabled
      ? rolledUpTaskStatus
      : taskStatus,
    hasStartAndEndDate,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  const canViewProject = Boolean(
    project?.permittedActionUris?.includes(
      'urn:replicon:project-action:view-project'
    )
  );

  const canEditTask = Boolean(
    project?.permittedActionUris?.includes(
      'urn:replicon:project-action:edit-tasks'
    )
  );

  const canViewCostType = isPsaPrpPsaPpmMergerEnabled
    ? hasCostType &&
      Boolean(
        project?.permittedActionUris?.includes(
          'urn:replicon:project-action:view-cost-type'
        )
      )
    : Boolean(
        project?.permittedActionUris?.includes(
          'urn:replicon:project-action:view-cost-type'
        )
      );

  const selectClasses = useMemo(
    () => ({
      icon: classes.icon,
      select: isRolledUpTaskEstimateCalculationMethodEnabled
        ? classes.select2
        : classes.select
    }),
    [
      isRolledUpTaskEstimateCalculationMethodEnabled,
      classes.icon,
      classes.select,
      classes.select2
    ]
  );

  const dateRangeClasses = useMemo(
    () => ({
      range: classes.range,
      date: classes.date,
      spacer: classes.spacer
    }),
    [classes]
  );

  const noValueClasses = useMemo(
    () => ({
      root: classes.noValue
    }),
    [classes.noValue]
  );

  const taskOwner = useMemo(
    () =>
      canViewProject
        ? assignedUser
        : { id: me.id, uri: me.uri, displayText: me.displayText },
    [canViewProject, assignedUser, me.id, me.uri, me.displayText]
  );

  const options = isRolledUpTaskEstimateCalculationMethodEnabled
    ? [
        {
          value: 2,
          displayText: intl.formatMessage({
            id: `taskDrawer.${actualHours ? 'inProgress' : 'notStarted'}`
          })
        },
        {
          value: 1,
          displayText: intl.formatMessage({ id: 'taskDrawer.completed' })
        }
      ]
    : [
        {
          value: 1,
          displayText: intl.formatMessage({ id: 'taskDrawer.closed' })
        },
        { value: 2, displayText: intl.formatMessage({ id: 'taskDrawer.open' }) }
      ];

  const taskState = isClosed || isClosedByTaskInheritance ? 1 : 2;

  const visibleCustomFieldDefinitions = customFieldDefinitions.filter(
    def => def.isVisible
  );

  const customFields = useMemo(
    () =>
      visibleCustomFieldDefinitions.reduce(
        (fields, definition) => ({
          ...fields,
          [definition.uri]: resolveValueOrDefault(task.customFields, definition)
        }),
        {}
      ),
    [visibleCustomFieldDefinitions, task]
  );

  const canViewEstimate = Boolean(
    project?.permittedActionUris?.includes(
      'urn:replicon:project-action:view-project-estimates'
    )
  );

  const hasViewProjectBillingOption = isPsaPrpPsaPpmMergerEnabled
    ? templateSettings?.hasBilling && hasViewProjectBillingOptions
    : hasViewProjectBillingOptions;

  const selectedTimeAndExpenseEntryType = useMemo(() => {
    if (!hasViewProjectBillingOption && isTimeEntryAllowed)
      return intl.formatMessage({ id: 'timeAndExpenseEntryOptions.yes' });

    if (timeAndExpenseEntryType && timeAndExpenseEntryType.uri)
      return intl.formatMessage({
        id: `timeAndExpenseEntryOptions.${timeAndExpenseEntryType.uri}`
      });

    return '';
  }, [
    hasViewProjectBillingOption,
    isTimeEntryAllowed,
    intl,
    timeAndExpenseEntryType
  ]);

  const timeEntryLabel = intl.formatMessage({
    id: isExpenseProductEnabled
      ? 'taskDrawerEdit.timeAndExpenseEntryAllowed'
      : 'taskDrawerEdit.timeEntryAllowed'
  });

  const hasExtensionFieldValues =
    extensionFieldValues && extensionFieldValues.length > 0;

  const showRescheduleButton = isPsaRmpTaskAllocation1Enabled && canEditTask;

  return customFieldDefsLoading ? (
    <Skeleton data-qe-id="TaskDrawer_Loading" variant="rect" height={48} />
  ) : customFieldDefsError ? (
    <ErrorCard error={customFieldDefsError} />
  ) : (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.flexRow}>
          {!isRolledUpTaskEstimateCalculationMethodEnabled && (
            <div className={classes.rowItem}>
              {canEditTask &&
                (isClosedByTaskInheritance && !isClosed ? (
                  <Chip
                    className={classes.chip}
                    label={options[0].displayText}
                  />
                ) : (
                  <FormSelectField
                    className={classes.field}
                    classes={selectClasses}
                    value={taskState}
                    onChange={openCloseSelection}
                    onOpen={onOpenCloseExpand}
                    onClose={onOpenCloseExpand}
                    input={InputBase(classes)}
                    IconComponent={
                      openCloseExpand ? ExpandLessSharp : ExpandMoreSharp
                    }
                    disabled={!canEditTask}
                  >
                    {options.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.displayText}
                      </MenuItem>
                    ))}
                  </FormSelectField>
                ))}
              {!canEditTask && (
                <Chip
                  color="primary"
                  label={intl.formatMessage({
                    id: `taskDrawer.${
                      isClosed || isClosedByTaskInheritance ? 'closed' : 'open'
                    }`
                  })}
                />
              )}
            </div>
          )}
          {isRolledUpTaskEstimateCalculationMethodEnabled && (
            <div className={classes.columnItem}>
              <span className={classes.subheading}>
                {intl.formatMessage({
                  id: 'taskDrawer.Status'
                })}
              </span>
              {canEditTask ? (
                <FormSelectField
                  className={classes.field}
                  classes={selectClasses}
                  value={taskState}
                  onChange={openCloseSelection}
                  onOpen={onOpenCloseExpand}
                  onClose={onOpenCloseExpand}
                  input={InputBase(classes)}
                  IconComponent={
                    openCloseExpand ? ExpandLessSharp : ExpandMoreSharp
                  }
                  disabled={!canEditTask}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.displayText}
                    </MenuItem>
                  ))}
                </FormSelectField>
              ) : (
                <Chip
                  className={classes.statusChip}
                  label={intl.formatMessage({
                    id: `projectTasksPage.tasksStatus.${rolledUpTaskStatus}`
                  })}
                />
              )}
            </div>
          )}
          {!isRolledUpTaskEstimateCalculationMethodEnabled && (
            <div
              className={classNames(classes.rowItem, {
                [classes.rescheduleColumnItem]: showRescheduleButton
              })}
            >
              <DateRange start={startDate} end={endDate} />
              {showRescheduleButton && (
                <RescheduleButton
                  onClick={openRescheduleTaskDialog}
                  isRolledUpTaskEstimateCalculationMethodEnabled={
                    isRolledUpTaskEstimateCalculationMethodEnabled
                  }
                />
              )}
            </div>
          )}
          {isRolledUpTaskEstimateCalculationMethodEnabled && (
            <div className={classes.columnItem}>
              <span className={classes.subheading}>
                {intl.formatMessage({
                  id: 'taskDrawer.plannedDates'
                })}
                {showRescheduleButton && (
                  <RescheduleButton
                    onClick={openRescheduleTaskDialog}
                    isRolledUpTaskEstimateCalculationMethodEnabled={
                      isRolledUpTaskEstimateCalculationMethodEnabled
                    }
                  />
                )}
              </span>
              <DateRange
                classes={dateRangeClasses}
                start={startDate}
                end={endDate}
                noDateComponent={
                  <ReportProblemOutlinedIcon className={classes.noDateIcon} />
                }
              />
            </div>
          )}
          {!isRolledUpTaskEstimateCalculationMethodEnabled && (
            <div className={classes.rowItem}>
              {taskOwner && <User user={taskOwner} />}
            </div>
          )}
          {isRolledUpTaskEstimateCalculationMethodEnabled && (
            <div className={classes.rowItem}>
              {taskOwner && (
                <User
                  classes={{ container: classes.container, name: classes.name }}
                  user={taskOwner}
                  size="small"
                />
              )}
            </div>
          )}
          <div className={classes.rowItem}>
            <Button
              data-qe-id="TaskDrawer_MoreFields_Icon"
              size="small"
              onClick={() => setExpanded(!expanded)}
              className={classes.expandButton}
            >
              {expanded ? (
                <ExpandLessSharp className={classes.boldItem} />
              ) : (
                <ExpandMoreSharp className={classes.boldItem} />
              )}
              {expanded
                ? intl.formatMessage({
                    id: 'taskDrawer.less'
                  })
                : intl.formatMessage({
                    id: 'taskDrawer.more'
                  })}
            </Button>
          </div>
        </div>
      </div>
      {(isClosed || isClosedByTaskInheritance) && (
        <ClosedOnDatePicker task={task} canEditTask={canEditTask} />
      )}
      {isPsaRmpTaskAllocation1Enabled && expanded && (
        <TaskDrawerHeaderInfoBarExpanded
          task={task}
          expandedInitialState={expandedInitialState}
          visibleCustomFieldDefinitions={visibleCustomFieldDefinitions}
          customFields={customFields}
          hasCostType={hasCostType}
          templateSettings={templateSettings}
        />
      )}
      {!isPsaRmpTaskAllocation1Enabled && expanded && (
        <div className={classes.expanded}>
          <Divider />
          <Grid container spacing={1} className={classes.details}>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={intl.formatMessage({
                  id: 'taskDrawerEdit.startDate'
                })}
              >
                <Date value={startDate} />
              </ReadOnlyContainer>
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={intl.formatMessage({
                  id: 'taskDrawerEdit.endDate'
                })}
              >
                <Date value={endDate} />
              </ReadOnlyContainer>
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={intl.formatMessage({
                  id: isPsaRmpTaskAllocation1Enabled
                    ? 'taskDrawerEdit.taskRole'
                    : 'taskDrawerEdit.role'
                })}
              >
                {assignedRole ? (
                  <RoleName
                    role={assignedRole}
                    showTooltip={false}
                    showRate={false}
                    className={classes.roleName}
                  />
                ) : (
                  <NoValue classes={noValueClasses} />
                )}
              </ReadOnlyContainer>
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyContainer
                label={intl.formatMessage({
                  id: 'taskDrawerEdit.taskOwner'
                })}
              >
                {taskOwner ? (
                  <User user={taskOwner} />
                ) : (
                  <NoValue classes={noValueClasses} />
                )}
              </ReadOnlyContainer>
            </Grid>
            {timeAndExpenseEntryType && (
              <Grid item xs={6}>
                <ReadOnlyContainer label={timeEntryLabel}>
                  <Typography variant="body2">
                    {selectedTimeAndExpenseEntryType}
                  </Typography>
                </ReadOnlyContainer>
              </Grid>
            )}
            {canViewCostType && (
              <Grid item xs={6}>
                <ReadOnlyContainer
                  label={intl.formatMessage({ id: 'taskDrawer.costType' })}
                >
                  <Typography variant="body2" data-qe-id="costType">
                    {costTypeUri ? (
                      intl.formatMessage({
                        id: `projectFinancialsCard.${costTypeUri}`
                      })
                    ) : (
                      <NoValue />
                    )}
                  </Typography>
                </ReadOnlyContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyContainer
                label={intl.formatMessage({ id: 'taskDrawerEdit.description' })}
              >
                <Typography variant="body2" className={classes.description}>
                  {description || <NoValue />}
                </Typography>
              </ReadOnlyContainer>
            </Grid>
            {visibleCustomFieldDefinitions.map(definition => (
              <Grid key={definition.uri} item xs={12} sm={6}>
                <ReadOnlyContainer label={definition.displayText}>
                  <ReadOnlyCustomFieldValue
                    definition={definition}
                    value={customFields[definition.uri]}
                  />
                </ReadOnlyContainer>
              </Grid>
            ))}
            {hasExtensionFieldValues &&
              extensionFieldValues.map(e => (
                <Grid item xs={12} sm={6} key={e.definition.id}>
                  <ReadOnlyContainer label={e.definition.displayText}>
                    <Typography variant="body2" className={classes.description}>
                      <ExtensionFieldValueText extensionFieldValue={e} />
                    </Typography>
                  </ReadOnlyContainer>
                </Grid>
              ))}
            {canViewEstimate && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ReadOnlyContainer
                    label={intl.formatMessage({
                      id: 'taskDrawerEdit.initialEstimatedHours'
                    })}
                  >
                    <Typography variant="body2">
                      <Decimal value={initialEstimatedHours} />
                    </Typography>
                  </ReadOnlyContainer>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      )}
      {isPsaRmpTaskAllocation1Enabled && isRescheduleTaskDialogOpen && (
        <RescheduleTaskDialog
          onSubmit={onRescheduleTaskSubmit}
          onCancel={closeRescheduleTaskDialog}
          isSetTaskTimeEntryDateRangeLoading={loading}
          task={task}
        />
      )}
    </div>
  );
};

TaskDrawerHeaderInfoBar.propTypes = {
  task: PropTypes.object.isRequired,
  expandedInitialState: PropTypes.bool
};

export default TaskDrawerHeaderInfoBar;
