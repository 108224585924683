import { useEffect } from 'react';
import { IANAZone, Settings } from 'luxon';
import get from 'lodash.get';
import { useMeContext } from '~/modules/me/useMeContext';

export const useConfigureTimeZoneEffect = () => {
  const me = useMeContext();
  const ianaName = get(me, 'locale.timeZone.ianaName');

  useEffect(() => {
    if (IANAZone.isValidZone(ianaName)) {
      Settings.defaultZoneName = ianaName;
    }
  }, [ianaName]);
};
