const reducer = (state, action) => {
  switch (action.type) {
    case 'RESOURCE-REQUEST-DRAWER-BUTTON-ACTION':
      return {
        ...state,
        resourceRequestButtonActionMessage:
          action.resourceRequestButtonActionMessage
      };
    case 'RECALCULATE-GANTT-CHART-DATA':
      return {
        ...state,
        recalculatingGanttChartData: action.recalculatingGanttChartData
      };
    default:
      return state;
  }
};

export default reducer;
