import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withCountries } from '~/modules/common/enhancers';
import EditClientDialog from './EditClientDialog';
import EditClientAddressDialogForm from './EditClientAddressDialogForm';
import { contactInfoValidationSchema } from './validationSchemas';
import { mapContactInfoInitialValues } from './mapContactInfoInitialValues';

const title = <FormattedMessage id="client.address" />;

export const EditClientAddressDialog = ({
  open,
  onClose,
  details,
  countries,
  billingSameAsAddress
}) => {
  const { id, contactInfo, billingContactInfo } = details;

  const initialValues = useMemo(
    () => ({
      id,
      contactInfo: mapContactInfoInitialValues(contactInfo),
      billingContactInfo: mapContactInfoInitialValues(billingContactInfo)
    }),
    [billingContactInfo, contactInfo, id]
  );

  const mapValuesOnSubmit = useCallback(
    values => ({
      ...values,
      contactInfo: {
        ...values.contactInfo,
        countryUri: values.contactInfo.countryUri
          ? values.contactInfo.countryUri
          : null
      },
      billingContactInfo: {
        ...values.billingContactInfo,
        countryUri: values.billingContactInfo.countryUri
          ? values.billingContactInfo.countryUri
          : null
      }
    }),
    []
  );

  return (
    <EditClientDialog
      open={open}
      onClose={onClose}
      title={title}
      initialValues={initialValues}
      validationSchema={contactInfoValidationSchema}
      mapValuesOnSubmit={mapValuesOnSubmit}
    >
      <EditClientAddressDialogForm
        countries={countries}
        billingSameAsAddress={billingSameAsAddress}
      />
    </EditClientDialog>
  );
};

EditClientAddressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  details: PropTypes.object,
  countries: PropTypes.array,
  billingSameAsAddress: PropTypes.bool
};

export default withCountries(EditClientAddressDialog);
