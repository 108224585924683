import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import { MarkupFormatter } from '~/modules/projects/project/AllowedExpensesAndEstimates/renderers';
import { ExpenseType as ExpenseTypeFormatter } from '../ExpenseType';
import FrequencyFormatter from './FrequencyFormatter';
import PercentageFormatter from './PercentageFormatter';
import TaskNameFormatter from './TaskNameFormatter';

export const formatters = {
  'urn:replicon:script-key:parameter:frequency': FrequencyFormatter,
  'urn:replicon:script-key:parameter:percentage': PercentageFormatter,
  'urn:replicon:script-key:parameter:markup': MarkupFormatter,
  'urn:replicon:script-key:parameter:task': TaskNameFormatter,
  'urn:replicon:script-key:parameter:milestone-amount': MoneyFormatter,
  'urn:replicon:script-key:parameter:expense-type': ExpenseTypeFormatter
};
