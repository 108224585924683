import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useClientOnlySearchState } from '~/modules/common/components/SearchBox';
import { PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS } from '~/modules/project-request/enums';
import PortfolioProjectRequestsContext from './PortfolioProjectRequestsContext';

export default function PortfolioProjectRequestsContextProvider({ children }) {
  const [searchCriteria, setSearchCriteria] = useClientOnlySearchState();

  const [quickFilter, setQuickFilter] = useState({
    filterByAccess: PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS,
    filterByStatus: null
  });

  const handleSetQuickFilter = useCallback(
    (newAllKey, newKey) => {
      setQuickFilter({ filterByAccess: newAllKey, filterByStatus: newKey });
    },
    [setQuickFilter]
  );

  const summaryFilterState = {
    quickFilter,
    setKeys: handleSetQuickFilter
  };

  return (
    <PortfolioProjectRequestsContext.Provider
      value={useMemo(
        () => ({
          searchCriteria,
          setSearchCriteria,
          summaryFilterState
        }),
        [searchCriteria, setSearchCriteria, summaryFilterState]
      )}
    >
      {children}
    </PortfolioProjectRequestsContext.Provider>
  );
}

PortfolioProjectRequestsContextProvider.propTypes = {
  children: PropTypes.node
};
