import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { PortfolioFullPath } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
    fontSize: theme.typography.body2.fontSize
  }
}));

const useFullPathStyles = makeStyles(() => ({
  fullpath: {
    flexWrap: 'wrap'
  }
}));

export const PortfolioFieldFormatter = ({ fieldValue }) => {
  const classes = useStyles();
  const fullPathClasses = useFullPathStyles();

  return (
    fieldValue?.displayText && (
      <div className={classes.root}>
        <PortfolioFullPath portfolio={fieldValue} classes={fullPathClasses} />
      </div>
    )
  );
};

PortfolioFieldFormatter.propTypes = {
  fieldValue: PropTypes.object
};
export default PortfolioFieldFormatter;
