import React, { useState } from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useOnSubmitHandler } from './hooks';

export const ChangeTaskResourceDialogFooter = ({
  onClose,
  onChangeTaskResourceSuccess,
  resourceEstimate,
  task
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    values: { allocationMode, newResourceUser }
  } = useFormikContext();

  const { onSubmit } = useOnSubmitHandler({
    newResourceUserId: newResourceUser?.id,
    onClose,
    onChangeTaskResourceSuccess,
    existingResourceUserId: resourceEstimate.resourceUser.id,
    setIsSaving,
    taskId: task.id,
    projectId: task.projectReference.id,
    allocationMode
  });

  return (
    <DialogActions>
      <Button onClick={onClose}>
        <FormattedMessage id="changeTaskResourceDialog.cancel" />
      </Button>
      <LoadingButton
        color="primary"
        onClick={onSubmit}
        isLoading={isSaving}
        disabled={Boolean(!newResourceUser)}
      >
        <FormattedMessage id="changeTaskResourceDialog.changeTaskResource" />
      </LoadingButton>
    </DialogActions>
  );
};

ChangeTaskResourceDialogFooter.propTypes = {
  onClose: PropTypes.func,
  onChangeTaskResourceSuccess: PropTypes.func,
  resourceEstimate: PropTypes.object,
  task: PropTypes.object
};

export default ChangeTaskResourceDialogFooter;
