import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { setLocale } from 'yup';
import { Settings } from 'luxon';

import moment from 'moment';
import { LinearQueryProgress } from '~/modules/common';
import { rebuildStructure } from './messages';
import useLocaleMessages from './useLocaleMessages';

const getLocale = me => me.locale.language.languageCode;
const getCustomTerms = me => me.locale.customTerms;

const withIntlProvider = BaseComponent => ({ me, ...props }) => {
  const locale = getLocale(me);

  moment.locale(locale);
  const customTerms = getCustomTerms(me);
  const messages = useLocaleMessages(locale, customTerms);

  useEffect(() => {
    if (messages) {
      setLocale(rebuildStructure(messages, 'yup'));
      [Settings.defaultLocale] = locale.toLowerCase().split('-');
    }
  }, [locale, messages]);

  if (!messages) {
    return <LinearQueryProgress />;
  }

  return (
    <IntlProvider locale={getLocale(me)} messages={messages}>
      <BaseComponent {...props} me={me} />
    </IntlProvider>
  );
};

export default withIntlProvider;
