import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import SupervisorsInfoReadOnly from '~/modules/resource-management/resourceUserDetails/SupervisorsInfo/SupervisorsInfoReadOnly';

const title = <FormattedMessage id="resourceDrawer.supervisor" />;

export const SupervisorSection = ({ userDetails }) => (
  <InfoSection title={title} editable={false}>
    <SupervisorsInfoReadOnly userDetails={userDetails} />
  </InfoSection>
);

SupervisorSection.propTypes = {
  userDetails: PropTypes.object.isRequired
};

export default SupervisorSection;
