import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useColumns } from '~/modules/common/components/ListTable';
import { Money as MoneyFormatter } from '~/modules/common/components/ListTable/renderers';
import { compareMonthAndYear } from '~/modules/common/dates/compare';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import {
  DeleteRow,
  ExpenseType,
  MarkupEditor,
  MarkupFormatter,
  BillableTypeEditor,
  BillableTypeFormatter,
  ExpenseAmountEditor,
  ExpenseAmountFormatter
} from '../renderers';
import { allowedExpensesAndEstimatesColumns } from './enum';
import { getProjectPeriodKeys } from './utils';
import { useColumnStyles } from './useStyles';

const isMonthYearInRange = (start, end) => date =>
  (!start || compareMonthAndYear(start, date) <= 0) &&
  (!end || compareMonthAndYear(date, end) <= 0);

export const buildColumns = ({
  chartDates,
  endDate,
  projectPeriodKeys,
  startDate,
  totalEstimatesColumnLabel,
  visibleColumns,
  classes
}) => {
  const isMonthYearInProjectMonthRange = isMonthYearInRange(startDate, endDate);

  const hasMarkUpColumn = visibleColumns.includes(
    allowedExpensesAndEstimatesColumns.MARK_UP
  );

  const hasDeleteRowColumn = visibleColumns.includes(
    allowedExpensesAndEstimatesColumns.DELETE_ROW
  );

  const estimatesColumns = chartDates.reduce(
    (retVal, curr) => ({
      ...retVal,
      [curr.key]: {
        id: curr.key,
        visible: visibleColumns.includes(
          allowedExpensesAndEstimatesColumns.ESTIMATES
        ),
        value: curr.label,
        align: 'center',
        disabled: !isMonthYearInProjectMonthRange(curr.start),
        projectPeriodKeys,
        className: classes.estimates,
        headerClassName: classes.estimates
      }
    }),
    {}
  );

  return {
    expenseType: {
      id: 'expenseType',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.EXPENSE_TYPE
      ),
      value: (
        <FormattedMessage id="allowedExpensesAndEstimates.columns.expenseType" />
      ),
      align: 'left',
      className: classes.expenseType,
      headerClassName: classNames(classes.expenseType, classes.extraZIndex)
    },
    markUp: {
      id: 'markUp',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.MARK_UP
      ),
      value: (
        <FormattedMessage id="allowedExpensesAndEstimates.columns.markUp" />
      ),
      align: 'right',
      className: classes.markUp,
      headerClassName: classNames(classes.markUp, classes.extraZIndex)
    },
    estimatedBilling: {
      id: 'estimatedBilling',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.ESTIMATED_BILLING
      ),
      value: (
        <FormattedMessage id="allowedExpensesAndEstimates.columns.estimatedBilling" />
      ),
      align: 'right'
    },
    billableType: {
      id: 'billableType',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.BILLABLE_TYPE
      ),
      value: (
        <FormattedMessage id="allowedExpensesAndEstimates.columns.billableType" />
      ),
      align: 'left',
      className: classNames(
        classes.billableType,
        !hasMarkUpColumn && classes.noMarkUp
      ),
      headerClassName: classNames(
        classes.billableType,
        !hasMarkUpColumn && classes.noMarkUp,
        classes.extraZIndex
      )
    },
    ...estimatesColumns,
    totalEstimates: {
      id: 'totalEstimates',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.TOTAL_ESTIMATES
      ),
      value: totalEstimatesColumnLabel || (
        <FormattedMessage id="allowedExpensesAndEstimates.columns.totalEstimates" />
      ),
      align: 'center',
      disabled: !startDate || !endDate,
      projectPeriodKeys,
      className: classNames(
        classes.totalEstimates,
        !hasDeleteRowColumn && classes.noDeleteRow
      ),
      headerClassName: classNames(
        classes.totalEstimates,
        !hasDeleteRowColumn && classes.noDeleteRow,
        classes.extraZIndex
      )
    },
    estimate: {
      id: 'estimate',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.ESTIMATE
      ),
      value: (
        <FormattedMessage id="allowedExpensesAndEstimates.columns.estimate" />
      ),
      align: 'right',
      className: classes.estimate,
      headerClassName: classNames(classes.estimate, classes.extraZIndex)
    },
    deleteRow: {
      id: 'deleteRow',
      visible: visibleColumns.includes(
        allowedExpensesAndEstimatesColumns.DELETE_ROW
      ),
      value: '',
      align: 'center',
      className: classes.deleteRow,
      headerClassName: classNames(classes.deleteRow, classes.extraZIndex)
    }
  };
};

export const useListColumns = ({
  billPlanEditable,
  chartDates,
  editable,
  projectStartDate,
  projectEndDate,
  totalEstimatesColumnLabel,
  visibleColumns,
  columnClasses: classesOverride
}) => {
  const classes = useColumnStyles({ classes: classesOverride });
  const start = projectStartDate && mapIsoStringtoUtcObject(projectStartDate);
  const end = projectEndDate && mapIsoStringtoUtcObject(projectEndDate);

  const projectPeriodKeys = useMemo(() => getProjectPeriodKeys(start, end), [
    end,
    start
  ]);

  const estimatesEditors = useMemo(
    () =>
      chartDates.reduce(
        (retVal, curr) => ({
          ...retVal,
          [curr.key]: editable ? ExpenseAmountEditor : ExpenseAmountFormatter
        }),
        {}
      ),
    [chartDates, editable]
  );

  return useColumns({
    columns: buildColumns({
      chartDates,
      endDate: end,
      projectPeriodKeys,
      startDate: start,
      totalEstimatesColumnLabel,
      visibleColumns,
      classes
    }),
    renders: {
      expenseType: ExpenseType,
      markUp: editable && billPlanEditable ? MarkupEditor : MarkupFormatter,
      billableType:
        editable && billPlanEditable
          ? BillableTypeEditor
          : BillableTypeFormatter,
      estimatedBilling: editable ? null : MoneyFormatter,
      ...estimatesEditors,
      totalEstimates: editable ? ExpenseAmountEditor : ExpenseAmountFormatter,
      deleteRow: editable ? DeleteRow : null,
      estimate: editable ? null : MoneyFormatter
    }
  });
};
