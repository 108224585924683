import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from '~/modules/common/util';
import useAccordionStyles from '../useAccordionStyles';
import ScoreItem from './ScoreItem';
import ScoreTotalSection from './ScoreTotalSection';
import ScoreHelp from './ScoreHelp';

const scoreMetricKeys = ['value', 'investment', 'risk'];
const expandIcon = <ExpandMoreIcon />;

const useStyles = makeStyles(theme => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  accordionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  }
}));

export const ScoresAccordion = () => {
  const {
    accordionClasses,
    summaryClasses,
    titleClass,
    summaryContainerClass
  } = useAccordionStyles();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const onChange = useCallback(() => setExpanded(!expanded), [expanded]);
  const {
    values: { score }
  } = useFormikContext();
  const { total, overrideTotal } = score;
  const effectiveTotalScore = isNullOrUndefined(overrideTotal)
    ? total
    : overrideTotal;

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      classes={accordionClasses}
    >
      <div className={expanded ? classes.accordionHeader : null}>
        <AccordionSummary
          data-qe-id="ScoreAccordion"
          expandIcon={expandIcon}
          classes={summaryClasses}
        >
          <div className={summaryContainerClass}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={titleClass}
            >
              <FormattedMessage id="projectRequest.score" />
            </Typography>
            {!expanded && effectiveTotalScore !== null && (
              <span>{effectiveTotalScore.toFixed(2)}</span>
            )}
          </div>
        </AccordionSummary>
        {expanded && <ScoreHelp />}
      </div>
      <AccordionDetails className={classes.details}>
        <Grid container className={classes.container}>
          {scoreMetricKeys.map(key => (
            <ScoreItem key={key} scoreMetricKey={key} />
          ))}

          <ScoreTotalSection />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

ScoresAccordion.propTypes = {};

export default ScoresAccordion;
