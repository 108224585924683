import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 128
  }
});

const NoProject = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="subtitle1">
        <FormattedMessage id="portfolio.budgetProgressCard.noProjects" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="portfolio.budgetProgressCard.addProjects" />
      </Typography>
    </div>
  );
};

export default NoProject;
