import { gql } from 'graphql-tag';

const mergedResourceRequestsFragment = gql`
  fragment MergedResourceRequestsFragment on ResourceRequest {
    id
    role {
      id
      displayText
      currentRate {
        amount
        currency {
          id
          displayText
        }
      }
    }
    resourcePools {
      id
      displayText
    }
    location {
      id
      displayText
    }
    division {
      id
      displayText
    }
    serviceCenter {
      id
      displayText
    }
    costCenter {
      id
      displayText
    }
    employeeType {
      id
      displayText
    }
    department {
      id
      displayText
    }
    quantity
    comment
    startDate
    endDate
    load
    totalHours
    skills {
      id
      displayText
      category {
        id
        displayText
      }
      skillLevel {
        id
        rating
      }
    }
    tags {
      id
      tag {
        id
        name
        values {
          value
          id
        }
      }
      value {
        id
        value
      }
    }
    preferredResources {
      id
      displayText
    }
  }
`;

export default mergedResourceRequestsFragment;
