import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY = gql`
  query GetTaskResourceUserAllocationsSummary(
    $userId: String!
    $filter: TaskResourceUserAllocationsSummaryForUserFilter
  ) {
    getTaskResourceUserAllocationsSummaryForUser(
      userId: $userId
      filter: $filter
    ) {
      userId
      startDate
      endDate
      totalHours
      scheduleRules {
        dateRange {
          startDate
          endDate
        }
        do
      }
    }
  }
`;
export const useGetTaskResourceUserAllocationsSummaryForUser = ({
  userId,
  projectId,
  taskAllocationId,
  skip
}) => {
  const { data, loading } = useQuery(
    TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
    {
      variables: {
        userId,
        filter: {
          projectId,
          excludeTaskAllocationId: taskAllocationId
        }
      },
      fetchPolicy: 'cache-and-network',
      skip
    }
  );

  return {
    loading,
    taskResourceUserAllocationsSummary:
      data?.getTaskResourceUserAllocationsSummaryForUser || {}
  };
};

export default useGetTaskResourceUserAllocationsSummaryForUser;
