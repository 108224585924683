import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const EXPENSE_CARD_SETTINGS = gql`
  query expenseCardSettings($projectId: String!) {
    expenseCardSettings(projectId: $projectId) {
      allowBillable
      showActuals
    }
  }
`;

export const useExpenseCardSettings = ({ projectId }) => {
  const isPsaPrpExpenseApprovalInExpenseBillPlanEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpExpenseApprovalInExpenseBillPlanEnabled'
  });

  const { data, loading } = useQuery(EXPENSE_CARD_SETTINGS, {
    variables: { projectId },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: isPsaPrpExpenseApprovalInExpenseBillPlanEnabled
      ? 'cache-first'
      : undefined
  });

  const expenseCardSettings = get(data, 'expenseCardSettings', {});

  return {
    loading,
    expenseCardSettings
  };
};

export default useExpenseCardSettings;
