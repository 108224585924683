export const useFormOnChangeHandlers = ({
  setFieldValue,
  values,
  currencies
}) => ({
  onPaymentTermChange: value =>
    setFieldValue('defaultInvoicePaymentTerm', value),
  onBillingCurrencyChange: event => {
    const selectedId = event.target.value;
    const selectedCurrency = currencies.find(c => c.id === selectedId);

    setFieldValue('invoiceCurrency', selectedCurrency);
  },
  onTaxProfileChange: (event, value) => setFieldValue('taxProfile', value),
  onInvoiceTemplateChange: (event, value) =>
    setFieldValue('invoiceTemplate', value),
  onDescriptionChange: event => {
    setFieldValue('description', event.target.value);
  },
  onInternalNotesChange: event => {
    setFieldValue('internalNotes', event.target.value);
  },
  onShowCommentsOnInvoiceChange: event => {
    setFieldValue('showComments', !values.showComments);
  }
});
