import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Prompt } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { usePageTitle } from '~/modules/common/title';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { OfflineDescription, OfflineTitle } from './components';
import { useOfflineContext } from './OfflineContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
  container: {
    textAlign: 'center'
  }
}));

const pageTitle = <FormattedMessage id="offlinePage.title" />;

export const OfflinePage = ({ location }) => {
  usePageTitle([{ messageId: 'offlinePage.title' }]);
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const isOffline = useOfflineContext();
  const prevLocation = location.state ? location.state.source.pathname : '/';

  // In native mobile app we support offline features
  // Hence we allow user to navigate back into native app when this page is rendered
  // Upon revisit when device is online we reload the webview on native app ie even when device is online 'offline' route will be rendered
  // Since this is misleading , below check is in place. This will ensure user will be taken to the root of the app in online scenarios
  if (!isOffline) {
    return <Redirect to={{ pathname: prevLocation }} />;
  }

  const allowNavigation = () => {
    return !isOffline || isMobile;
  };

  return (
    <>
      {/* this will not prompt the user, the message argument can also be a function determining
      if we should allow navigation away from this page without showing a prompt to the user */}
      <Prompt message={allowNavigation} />
      <StickyHeader showMenuButton={isMobile} title={pageTitle} />
      <div className={classes.root}>
        <div className={classes.container}>
          <OfflineTitle />
          <OfflineDescription />
        </div>
      </div>
    </>
  );
};

OfflinePage.propTypes = {
  location: PropTypes.object
};

export default OfflinePage;
