import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  makeStyles
} from '@material-ui/core';

const useTextStyles = makeStyles(() => ({
  primary: {
    fontWeight: '500'
  }
}));

const OverAllocationOptionItem = ({
  value,
  inputProps,
  primaryText,
  secondaryText,
  onSelect
}) => {
  const textClasses = useTextStyles();

  const handleClick = useCallback(() => {
    if (onSelect) onSelect(value);
  }, [onSelect, value]);

  return (
    <ListItem
      button
      key={value}
      dense={false}
      onClick={handleClick}
      role="presentation"
    >
      <ListItemIcon>
        <Radio
          color="primary"
          value={value}
          disableRipple
          inputProps={inputProps}
        />
      </ListItemIcon>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        classes={textClasses}
      />
    </ListItem>
  );
};

OverAllocationOptionItem.propTypes = {
  inputProps: PropTypes.object,
  value: PropTypes.string.isRequired,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  onSelect: PropTypes.func
};

export default OverAllocationOptionItem;
