import { SubscriptionClient } from '@replicon/subscriptions-transport-serverless-ws';
import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloLink,
  split,
  ApolloProvider,
  gql
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import DebounceLink from 'apollo-link-debounce';
import get from 'lodash.get';
import React from 'react';
import { isProductionEnv, getVersion } from '~/config';
import {
  clientResolvers as taskResolvers,
  clientTypeDefs as taskTypeDefs
} from '~/modules/tasks/graphql';
import httpLink from './httpLink';
import { WebSocketLink } from './WebSocketLink';
import InFlightRequestLink from './inFlightRequestLink';
import typePolicies from './typePolicies';

const cache = new InMemoryCache({
  dataIdFromObject: object =>
    object.id
      ? `${object.__typename}:${object.id}`
      : defaultDataIdFromObject(object),
  typePolicies
});

let wsEndpoint = null;

const { hostname } = window.location;

if (isProductionEnv()) {
  const subdomain = /^([^.]*)\.*/.exec(hostname)[1];
  const domain = /^[^.]*\.(.*)/.exec(hostname)[1];

  wsEndpoint = `wss://${subdomain}-ws.${domain}:${window.location.port || 443}`;
} else {
  wsEndpoint = `wss://${hostname}:5000`;
}

const wsClient = new SubscriptionClient(wsEndpoint, {
  reconnect: true,
  reconnectionAttempts: 5
});

const wsLink = new WebSocketLink(wsClient);

export const loggingErrorMiddleware = logger => ({
  graphQLErrors,
  networkError
}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      logger(
        // eslint-disable-line
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    if (
      networkError.statusCode === 403 &&
      get(networkError, 'result.error.redirect')
    ) {
      window.location.href = get(networkError, 'result.error.redirect');
    } else {
      logger(`[Network error]: ${networkError}`); // eslint-disable-line
    }
  }
};

const client = new ApolloClient({
  name: 'psa-ui',
  version: getVersion(),
  link: ApolloLink.from([
    // eslint-disable-next-line no-console
    onError(loggingErrorMiddleware(console.log)),
    new DebounceLink(500),
    split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);

        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      ApolloLink.from([new InFlightRequestLink(), httpLink])
    )
  ]),
  cache,
  resolvers: {
    ...taskResolvers
  },
  typeDefs: gql`
    ${taskTypeDefs}
  `
});

export default BaseComponent => props => (
  <ApolloProvider client={client}>
    <BaseComponent {...props} />
  </ApolloProvider>
);
