import { useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { extractFirstValidationError } from '~/modules/common/graphql/errors';

export const UPDATE_CLIENT_REPRESENTATIVES_MUTATION = gql`
  mutation UpdateClientRepresentatives(
    $input: UpdateClientRepresentativesInput!
  ) {
    updateClientRepresentatives2(input: $input) {
      client {
        id
        clientRepresentatives {
          id
          displayText
        }
      }
    }
  }
`;

export const useUpdateClientRepresentativesOnSubmit = closeDialog => {
  const [updateClientRepresentatives] = useMutation(
    UPDATE_CLIENT_REPRESENTATIVES_MUTATION
  );

  const onSubmit = useCallback(
    async (values, { resetForm, setSubmitting, setFieldError }) => {
      const { id, clientRepresentatives } = values;

      try {
        await updateClientRepresentatives({
          variables: {
            input: {
              id,
              clientRepresentativeIds: clientRepresentatives.map(cr => cr.id)
            }
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateClientRepresentatives2: {
              __typename: 'UpdateClientRepresentativesResponse',
              client: {
                __typename: 'Client',
                id,
                clientRepresentatives
              }
            }
          }
        });

        closeDialog();
        resetForm();
      } catch (error) {
        const validationErrors = extractFirstValidationError(error);

        setFieldError('clientRepresentatives', validationErrors);
      } finally {
        setSubmitting(false);
      }
    },
    [closeDialog, updateClientRepresentatives]
  );

  return { onSubmit };
};
