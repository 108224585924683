import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import { Tooltip, makeStyles } from '@material-ui/core';
import { hasValue } from '~/modules/customFields/utils';
import { GetUserDefinedField } from '~/modules/resource-management/resourceUserDetails/AdditionalInfo/AdditionalInfoSection';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import { CustomFieldValueText } from '~/modules/customFields/components';

const title = <FormattedMessage id="resourceDrawer.additionalInfo" />;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0, 0, 0),
    display: 'flex'
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    flexBasis: '50%',
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  value: {
    flexBasis: '50%',
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  toolTip: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export const AdditionalInfoSection = ({ userDetails }) => {
  const customFieldValues = get(userDetails, 'customFieldValues') || [];

  const hasCustomFieldValues =
    customFieldValues &&
    customFieldValues.length > 0 &&
    customFieldValues.some(hasValue);

  const cssClasses = useStyles();

  return hasCustomFieldValues ? (
    <InfoSection editable={false} title={title}>
      {customFieldValues.map(field => (
        <Tooltip
          title={GetTooltipContent(field, cssClasses)}
          key={field.customField.displayText}
        >
          {GetUserDefinedField(field, cssClasses)}
        </Tooltip>
      ))}
    </InfoSection>
  ) : null;
};

export const GetTooltipContent = (field, cssClasses) => (
  <div key={field.customField.id} className={cssClasses.toolTip}>
    <div> {field.customField.displayText}</div>
    <CustomFieldValueText customFieldValue={field} />
  </div>
);

AdditionalInfoSection.propTypes = {
  userDetails: PropTypes.object.isRequired
};

export default AdditionalInfoSection;
