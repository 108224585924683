import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import {
  RemoveTaskAllocationConfirmationDialog,
  RemoveTaskAssignmentConfirmationDialog
} from '~/modules/projects/resourcing-plan/components';
import { TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY } from '~/modules/common/components/TaskDrawer/common/hooks/useGetTaskResourceUserAllocationsSummaryForUser';

export const ReleaseTaskDialogsContainer = ({
  projectId,
  task,
  user,
  closeReleaseTaskDialogsContainer,
  handleRemoveTaskAssignment
}) => {
  const {
    open: taskAssignmentDialogOpen,
    openDialog: openTaskAssignmentDialog,
    closeDialog: closeTaskAssignmentDialog
  } = useDialogState(false);

  const {
    open: taskAllocationReleaseDialogOpen,
    closeDialog: closeTaskAllocationReleaseDialog
  } = useDialogState(true);

  const onRemoveTaskAssignmentSuccess = useCallback(
    ({ taskId }) => {
      handleRemoveTaskAssignment({ taskId });
      closeTaskAssignmentDialog();
      closeReleaseTaskDialogsContainer();
    },
    [
      handleRemoveTaskAssignment,
      closeTaskAssignmentDialog,
      closeReleaseTaskDialogsContainer
    ]
  );

  const refetchQueries = useMemo(
    () => [
      {
        query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
        variables: {
          userId: user.id,
          filter: {
            projectId
          }
        }
      }
    ],
    [projectId, user.id]
  );

  return (
    <>
      {taskAllocationReleaseDialogOpen && (
        <RemoveTaskAllocationConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={taskAllocationReleaseDialogOpen}
          onClose={closeTaskAllocationReleaseDialog}
          openRemoveTaskAssignmentDialog={openTaskAssignmentDialog}
          onRemoveTaskAllocationSuccess={closeTaskAllocationReleaseDialog}
        />
      )}
      {taskAssignmentDialogOpen && (
        <RemoveTaskAssignmentConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={taskAssignmentDialogOpen}
          onClose={closeTaskAssignmentDialog}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};

ReleaseTaskDialogsContainer.propTypes = {
  user: PropTypes.object,
  task: PropTypes.object,
  projectId: PropTypes.string,
  closeReleaseTaskDialogsContainer: PropTypes.func,
  handleRemoveTaskAssignment: PropTypes.func
};

export default ReleaseTaskDialogsContainer;
