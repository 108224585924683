import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const getSessionTimeoutRemainingQuery = gql`
  query GetSessionTimeoutRemaining($touchSession: Boolean) {
    sessionTimeoutRemaining(touchSession: $touchSession) {
      days
      hours
      minutes
      seconds
      milliseconds
    }
  }
`;

export const useSessionTimeoutRemainingQuery = () => {
  const { data, loading, error, refetch } = useQuery(
    getSessionTimeoutRemainingQuery,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  return {
    loading,
    error,
    sessionTimeoutRemaining:
      loading || error ? null : data.sessionTimeoutRemaining,
    touchSession: () => refetch({ touchSession: true })
  };
};

export default useSessionTimeoutRemainingQuery;
