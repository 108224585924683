import React, { useMemo, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import deepEqual from 'fast-deep-equal';
import ReadOnlyCard from '~/modules/common/components/EditableCard/ReadOnlyCard';
import { ReadOnlyContent } from '~/modules/common/components/EditableCard';
import { useDialogState } from '~/modules/common/hooks';
import { BatchStatus } from '~/modules/common/components';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import {
  useExpenseActualsAndEstimates,
  useInitialValues,
  useOnSubmit,
  useExpenseCardSettings,
  useBulkPutEstimatedExpenses,
  usePutExpenseCardSettings,
  useUpdateProjectTimeAndExpense
} from '../AllowedExpensesAndEstimates/hooks';
import {
  useProjectBillPlans,
  usePutProjectBillPlans,
  useRecalculateProjectsBillingContractClauses,
  useBillPlanState,
  usePutProjectBillingContract,
  useDefaultBillingContract
} from '../BillPlanV2/hooks';
import { ExpenseReadOnlyContent } from './ExpenseReadOnlyContent';
import { ExpenseReadOnlyDialog } from './ExpenseReadOnlyDialog';
import { ExpenseEditableDialog } from './ExpenseEditableDialog';

const useDeepCompareMemoize = value => {
  const ref = useRef();

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const Expense = ({
  editable,
  endDate: projectEndDate,
  expenseBudgetedCost,
  expenseCodes,
  hasBillingContractAssignment,
  isBillingContractType,
  onSetBillPlanChanged,
  projectCurrency,
  projectId,
  projectPermissions,
  startDate: projectStartDate
}) => {
  const {
    canEditExpenseCodes,
    canViewBillingContracts,
    canRecalculateBillingData
  } = projectPermissions;
  const {
    open: isReadOnlyDialogOpen,
    openDialog: openReadOnlyDialog,
    closeDialog: closeReadOnlyDialog
  } = useDialogState();

  const {
    open: isEditableDialogOpen,
    openDialog: openEditableDialog,
    closeDialog: closeEditableDialog
  } = useDialogState();

  const { bulkPutEstimatedExpenses } = useBulkPutEstimatedExpenses();
  const { putExpenseCardSettings } = usePutExpenseCardSettings({ projectId });
  const updateProjectExpenses = useUpdateProjectTimeAndExpense({
    projectUri: projectId,
    includeExpenseCodes: !isBillingContractType
  });

  const { formatMessage } = useIntl();
  const {
    expenseCardSettings,
    loading: expenseCardSettingsLoading
  } = useExpenseCardSettings({ projectId });

  const showActuals = Boolean(expenseCardSettings?.showActuals);

  const {
    billPlans: { billPlans },
    loading: billPlanLoading,
    refetchBillPlans
  } = useProjectBillPlans(projectId, !isBillingContractType);

  const { defaultBillingContract } = useDefaultBillingContract();

  const { batchState, setBatchState } = useBillPlanState();

  const { putBillPlans } = usePutProjectBillPlans({
    projectUri: projectId,
    billPlans
  });

  const putProjectBillingContract = usePutProjectBillingContract({
    projectId,
    contractId: defaultBillingContract.contractId
  });

  const {
    recalculateProjectsBillingContractClauses
  } = useRecalculateProjectsBillingContractClauses(projectId);

  const onSave = useCallback(
    async updatedBillPlan => {
      if (!hasBillingContractAssignment && defaultBillingContract.contractId)
        await putProjectBillingContract();

      await putBillPlans({ updatedBillPlan, setBatchState });
      onSetBillPlanChanged();
    },
    [
      putBillPlans,
      setBatchState,
      hasBillingContractAssignment,
      putProjectBillingContract,
      defaultBillingContract.contractId,
      onSetBillPlanChanged
    ]
  );

  const recalculate = useCallback(() => {
    if (canRecalculateBillingData) {
      recalculateProjectsBillingContractClauses(setBatchState);
      onSetBillPlanChanged();
    }
  }, [
    canRecalculateBillingData,
    recalculateProjectsBillingContractClauses,
    setBatchState,
    onSetBillPlanChanged
  ]);

  const scriptDetails = useMemo(
    () =>
      (billPlans || []).find(({ slug }) => slug === 'expenses') || {
        scripts: [],
        isAddMode: false
      },
    [billPlans]
  );

  const {
    loading: expenseActualsAndEstimatesLoading,
    estimatedExpensesSeries,
    expenseActualsSeries,
    estimatedExpensesSummary,
    expenseActualsSummary,
    refetchEstimates
  } = useExpenseActualsAndEstimates({
    currencyId: projectCurrency.id,
    endDate: projectEndDate,
    projectId,
    startDate: projectStartDate,
    skipActuals: false,
    skipEstimates: false
  });

  const initialValues = useInitialValues(
    useDeepCompareMemoize({
      estimatedExpensesSeries,
      estimatedExpensesSummary,
      expenseActualsSeries,
      expenseActualsSummary,
      expenseCodes,
      projectCurrency,
      scriptDetails,
      showActuals
    })
  );

  const onSubmit = useOnSubmit({
    bulkPutEstimatedExpenses,
    projectPermissions,
    isBillingContractType,
    onBillPlanSave: onSave,
    planId: scriptDetails.id,
    projectEndDate,
    projectId,
    putExpenseCardSettings,
    recalculate,
    savedShowActuals: showActuals,
    updateProjectExpenses
  });

  const onBatchComplete = useCallback(() => {
    refetchEstimates();
    refetchBillPlans();
  }, [refetchBillPlans, refetchEstimates]);

  const isLoading =
    expenseActualsAndEstimatesLoading ||
    billPlanLoading ||
    expenseCardSettingsLoading;

  if (batchState.batchInProgress)
    return (
      <BatchStatus
        batchState={batchState}
        setBatchState={setBatchState}
        onBatchComplete={onBatchComplete}
      >
        <CardLoading title={formatMessage({ id: 'expenses.title' })} />
      </BatchStatus>
    );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <>
        <ReadOnlyCard
          title={formatMessage({ id: 'expenses.title' })}
          onEdit={openEditableDialog}
          key="expense"
          editable={editable && canEditExpenseCodes}
          isLoading={isLoading}
        >
          <ReadOnlyContent>
            <ExpenseReadOnlyContent
              expenseBudgetedCost={expenseBudgetedCost}
              onViewSummaryClick={openReadOnlyDialog}
            />
          </ReadOnlyContent>
        </ReadOnlyCard>
        {isReadOnlyDialogOpen && (
          <ExpenseReadOnlyDialog
            onClose={closeReadOnlyDialog}
            open={isReadOnlyDialogOpen}
            projectEndDate={projectEndDate}
            projectStartDate={projectStartDate}
            canViewBillingContracts={canViewBillingContracts}
          />
        )}
        {isEditableDialogOpen && (
          <ExpenseEditableDialog
            onClose={closeEditableDialog}
            open={isEditableDialogOpen}
            projectEndDate={projectEndDate}
            projectPermissions={projectPermissions}
            projectStartDate={projectStartDate}
          />
        )}
      </>
    </Formik>
  );
};

Expense.propTypes = {
  editable: PropTypes.bool,
  endDate: PropTypes.string,
  expenseBudgetedCost: PropTypes.object,
  expenseCodes: PropTypes.array,
  hasBillingContractAssignment: PropTypes.bool,
  isBillingContractType: PropTypes.bool,
  onSetBillPlanChanged: PropTypes.func,
  projectCurrency: PropTypes.object,
  projectId: PropTypes.string,
  projectPermissions: PropTypes.object,
  startDate: PropTypes.string
};
