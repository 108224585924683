import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withRoutes } from '~/modules/common/routes';

// eslint-disable-next-line react/prop-types
const buildRoute = ({ path, exact, component }) => (
  <Route
    key={path || 'notFound'}
    path={path}
    exact={exact}
    component={component}
  />
);

export const RoutesSwitch = ({ routes }) => (
  <Switch>{Object.values(routes).map(buildRoute)}</Switch>
);

RoutesSwitch.propTypes = {
  routes: PropTypes.object.isRequired
};

export default withRoutes(RoutesSwitch);
