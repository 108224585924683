import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  headerCell: {
    padding: theme.spacing(0, 4, 0, 1),
    fontWeight: theme.typography.fontWeightBold
  },
  rowSelector: {
    width: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  }
});

export const columnHeaders = ({
  classes,
  isVendorResourcesListTable,
  isEditable
}) => [
  {
    id: 'header',
    columns: [
      ...(isVendorResourcesListTable && isEditable
        ? [
            {
              column: 'rowSelector',
              align: 'left',
              sortable: false,
              className: classes.rowSelector
            }
          ]
        : []),
      {
        column: 'name',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.name" />,
        includesNavigationComponent: true,
        sortable: false,
        className: classes.headerCell
      },
      {
        column: 'employeeId',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.employeeId" />,
        className: classes.headerCell
      },
      {
        column: 'startDate',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.startDate" />,
        className: classes.headerCell
      },
      {
        column: 'endDate',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.endDate" />,
        className: classes.headerCell
      },
      {
        column: 'roleSchedules',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.role" />,
        className: classes.headerCell,
        visible: isVendorResourcesListTable
      },
      {
        column: 'supervisorAssignment',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.supervisor" />,
        className: classes.headerCell
      },
      {
        column: 'currentDepartment',
        align: 'left',
        value: <FormattedMessage id="vendorResourcesListTable.department" />,
        className: classes.headerCell
      }
    ]
  }
];

export const useListHeaders = ({
  isVendorResourcesListTable,
  isEditable = false
}) => {
  const classes = useStyles();

  return columnHeaders({ classes, isVendorResourcesListTable, isEditable });
};
