import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LegendTypes,
  ProgressTypes
} from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';
import { usePortfolioProjectContext } from '~/modules/portfolios/portfolio/components/ProjectList/PortfolioProjectsContext';
import { overbudgetTag } from '~/modules/projects/components/ProjectListPage/Toolbar/OverbudgetFacet';

const overQueryMap = {
  [ProgressTypes.COST]: 'costOverbudget',
  [ProgressTypes.HOURS]: 'hoursOverbudget',
  [ProgressTypes.DATE]: 'completionDateOverdue'
};

const useClickHandler = ({ type, setSearchCriteria, summaryFilterState }) => {
  const history = useHistory();
  const overbudgetKey = overQueryMap[type];
  const { resetSummaryFilter } = summaryFilterState || {};

  return useCallback(() => {
    resetSummaryFilter && resetSummaryFilter();
    setSearchCriteria({
      keywords: [],
      criterions: {
        [overbudgetTag]: [{ key: overbudgetKey, value: overbudgetKey }]
      }
    });
    history.push({
      pathname: `${history.location.pathname}/projects`
    });
  }, [history, overbudgetKey, resetSummaryFilter, setSearchCriteria]);
};

const useDonutChartClickHandler = ({ type }) => {
  const {
    setSearchCriteria,
    summaryFilterState
  } = usePortfolioProjectContext();
  const overdueHandler = useClickHandler({
    type,
    setSearchCriteria,
    summaryFilterState
  });

  const onDataClick = useCallback(
    (_, e) => {
      if (e && e.datum && e.datum.id) {
        const eventId = e.datum.id;

        if (eventId === LegendTypes.OVER) return overdueHandler();
      }

      return null;
    },
    [overdueHandler]
  );

  return useMemo(
    () => ({
      events: [
        {
          target: 'data',
          eventHandlers: {
            onClick: onDataClick
          }
        }
      ]
    }),
    [onDataClick]
  );
};

export default useDonutChartClickHandler;
