import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, withStyles } from '@material-ui/core';

const styles = theme => ({
  content: {
    padding: theme.spacing(2)
  }
});

const FormContainer = ({ classes, children }) => (
  <div>
    <Divider />
    <div className={classes.content}>{children}</div>
  </div>
);

FormContainer.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any
};

export default withStyles(styles)(FormContainer);
