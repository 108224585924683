import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { deepPure } from '~/util';
import { useDialogState } from '~/modules/common/hooks';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import UserAllocationSummaryBlock from '../UserAllocationSummaryBlock';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1
  }
}));

export const TimeOffSummaryBlock = ({
  scale,
  chartStartDate,
  timeoffSummaryPeriod,
  timeOffs,
  chartDisplayDateRange,
  showTooltip = true,
  clickable = false,
  classes: classesOverride,
  relativeDynamicPositionLeft
}) => {
  const classes = useStyles({ classes: classesOverride });
  const theme = useTheme();

  const {
    totalTimeOffHours,
    totalAvailableHours,
    timeOffType
  } = timeoffSummaryPeriod;

  const dynamicStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.resourceAllocation.notWorking
    }),
    [theme.palette.resourceAllocation.notWorking]
  );

  const clippedChartDisplayDateRange = useMemo(
    () => ({
      start:
        timeoffSummaryPeriod.startDate > chartDisplayDateRange.startDate
          ? timeoffSummaryPeriod.startDate
          : chartDisplayDateRange.startDate,
      end:
        timeoffSummaryPeriod.endDate < chartDisplayDateRange.endDate
          ? timeoffSummaryPeriod.endDate
          : chartDisplayDateRange.endDate
    }),
    [
      timeoffSummaryPeriod.startDate,
      timeoffSummaryPeriod.endDate,
      chartDisplayDateRange.endDate,
      chartDisplayDateRange.startDate
    ]
  );

  const period = useMemo(
    () => ({
      startDate: timeoffSummaryPeriod.startDate,
      endDate: timeoffSummaryPeriod.endDate
    }),
    [timeoffSummaryPeriod.endDate, timeoffSummaryPeriod.startDate]
  );

  const {
    open: showTimeoffTooltip,
    openDialog: openTimeoffTooltipDialog,
    closeDialog: closeTimeoffTooltipDialog
  } = useDialogState(false);

  const handleTimeoffToolTipClick = useCallback(() => {
    showTimeoffTooltip
      ? closeTimeoffTooltipDialog()
      : openTimeoffTooltipDialog();
  }, [closeTimeoffTooltipDialog, openTimeoffTooltipDialog, showTimeoffTooltip]);

  const showTooltipConditonal = clickable
    ? showTooltip && showTimeoffTooltip
    : showTooltip;

  const filteredTimeoffs = useMemo(
    () =>
      (timeOffs || []).filter(
        timeOff =>
          mapRepliconDateToUtcObject(timeOff.dateRange.startDate) >=
            mapIsoStringtoUtcObject(timeoffSummaryPeriod.startDate) &&
          mapRepliconDateToUtcObject(timeOff.dateRange.startDate) <=
            mapIsoStringtoUtcObject(timeoffSummaryPeriod.endDate)
      ),
    [timeOffs, timeoffSummaryPeriod]
  );

  const allocationBlock = (
    <UserAllocationSummaryBlock
      scale={scale}
      classes={classes}
      clippedChartDisplayDateRange={clippedChartDisplayDateRange}
      period={period}
      chartStartDate={chartStartDate}
      name={timeOffType.displayText}
      totalHours={totalTimeOffHours}
      totalAvailableHours={totalAvailableHours}
      loadRatio={totalTimeOffHours / totalAvailableHours}
      dynamicStyle={dynamicStyle}
      hideTotalAllocationLabel
      showLabel={false}
      showTooltip={showTooltipConditonal}
      clickable={clickable}
      timeOffs={filteredTimeoffs}
      relativeDynamicPositionLeft={relativeDynamicPositionLeft}
    />
  );

  if (clickable) {
    return (
      <div
        onClick={handleTimeoffToolTipClick}
        onMouseDown={handleTimeoffToolTipClick}
        onMouseLeave={closeTimeoffTooltipDialog}
        onKeyDown={handleTimeoffToolTipClick}
        role="presentation"
      >
        {allocationBlock}
      </div>
    );
  }

  return allocationBlock;
};

TimeOffSummaryBlock.propTypes = {
  timeoffSummaryPeriod: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  chartStartDate: PropTypes.object.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  showTooltip: PropTypes.bool,
  clickable: PropTypes.bool,
  relativeDynamicPositionLeft: PropTypes.number
};

export default deepPure(TimeOffSummaryBlock);
