import { useColumns } from '~/modules/common/components/ListTable';
import formatters from './formatters';
import { buildColumns } from './columns';

export const useListColumns = ({
  classes,
  editable,
  availableToBillStates,
  isPsaPrpManualBulkBillCreationEnabled
}) =>
  useColumns({
    columns: buildColumns(
      classes,
      editable,
      availableToBillStates,
      isPsaPrpManualBulkBillCreationEnabled
    ),
    renders: formatters
  });
