import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { useTaskSummaryLabels } from '~/modules/common/components/TaskStatusSection/useTaskSummaryLabels';
import { TaskSummaryHeader } from './Table';

const useStyles = makeStyles(theme => ({
  table: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    display: 'grid',
    alignItems: 'baseline',
    gridRowGap: 0,
    justifyItems: 'start',
    justifyContent: 'left',
    gridColumnGap: 0,
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto auto auto auto'
  },
  labelCell: {
    ...theme.typography.body2,
    display: 'flex',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 1, 1, 2),
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0)
  },
  costCell: {
    ...theme.typography.body2,
    justifySelf: 'end',
    paddingRight: 0,
    paddingLeft: theme.spacing(5),
    justifyContent: 'center'
  },
  row: {
    display: 'contents'
  },
  datesCell: {
    ...theme.typography.body2,
    paddingLeft: theme.spacing(1),
    justifyContent: 'start'
  },
  cell: {
    display: 'grid',
    whiteSpace: 'nowrap',
    width: theme.spacing(15),
    justifyContent: 'end'
  },
  paddingTop: {
    paddingTop: theme.spacing(3)
  },
  tableColumnsNoCost: {
    gridTemplateColumns: 'auto auto auto'
  },
  cellWidthNoCost: {
    width: theme.spacing(22)
  }
}));

export const TaskStatusSkeleton = ({
  task,
  isPsaPpmEstimatedCostAtCompletionEnabled
}) => {
  const classes = useStyles();

  const labels = useTaskSummaryLabels({ isProjectVariant: false });

  const showCost =
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    task?.project?.permittedActionUris?.includes(
      'urn:replicon:project-action:view-cost-data'
    );

  const variationLabel = 'taskDrawer.variation';

  const rowHeaders = [
    labels.actuals,
    labels.workRemaining,
    labels.estimatedAtCompletion,
    labels.estimateBudget,
    variationLabel
  ];

  return (
    <>
      <div
        className={classNames(classes.table, {
          [classes.tableColumnsNoCost]: !showCost
        })}
        role="table"
      >
        <TaskSummaryHeader showCost={showCost} />

        {rowHeaders.map(label => (
          <div className={classes.row} role="row" key={label}>
            <div
              className={classNames(classes.labelCell, {
                [classes.paddingTop]:
                  label === labels.estimatedAtCompletion ||
                  label === variationLabel
              })}
              role="rowheader"
            >
              <FormattedMessage id={label} />
            </div>
            <div
              className={classNames(classes.cell, {
                [classes.cellWidthNoCost]: !showCost
              })}
              role="cell"
            >
              <Skeleton variant="text" width={50} />
            </div>

            {showCost && (
              <div
                className={classNames(classes.cell, classes.costCell)}
                role="cell"
              >
                <Skeleton variant="text" width={70} />
              </div>
            )}
            <div
              className={classNames(classes.cell, classes.datesCell, {
                [classes.cellWidthNoCost]: !showCost
              })}
              role="cell"
            >
              {label !== labels.workRemaining && (
                <Skeleton variant="text" width={80} />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

TaskStatusSkeleton.propTypes = {
  task: PropTypes.object,
  isPsaPpmEstimatedCostAtCompletionEnabled: PropTypes.bool
};

export default TaskStatusSkeleton;
