import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const projectCanBeDeletedQuery = gql`
  query GetProjectCanBeDeleted($projectId: String!) {
    project(projectId: $projectId) {
      id
      canBeDeleted
    }
  }
`;

const useCanProjectBeDeleted = ({ projectId }) => {
  const { loading, error, data } = useQuery(projectCanBeDeletedQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectId
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    canBeDeleted: data && data.project ? data.project.canBeDeleted : true,
    error
  };
};

export default useCanProjectBeDeleted;
