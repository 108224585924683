import { ApolloLink, Observable } from '@apollo/client';

let inFlightCount = 0;
let notifyInFlightCount;
const setInFlightCount = c => {
  inFlightCount = c;
  if (notifyInFlightCount) {
    notifyInFlightCount(c);
  }
};

export const inFlightRequestObservable = new Observable(obs => {
  notifyInFlightCount = obs.next.bind(obs);
});

class InFlightRequestLink extends ApolloLink {
  request(operation, forward) {
    setInFlightCount(inFlightCount + 1);

    const forwarded = forward(operation);

    forwarded.subscribe({
      error: result => {
        setInFlightCount(inFlightCount - 1);
      },
      complete: result => {
        setInFlightCount(inFlightCount - 1);
      }
    });

    return forwarded;
  }
}

export default InFlightRequestLink;
