import React, { useCallback } from 'react';
import { DialogContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  ProjectTeamMemberDropdown,
  RoleDropdown
} from '~/modules/tasks/components';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(2)
  }
}));

const AddResourceEstimateDialogContent = ({
  projectSlug,
  role,
  setRole,
  resource,
  setResource,
  taskId
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const addResourceLabel = formatMessage({
    id: 'addResourceEstimateDialog.resource'
  });

  const addRoleLabel = formatMessage({
    id: 'addResourceEstimateDialog.role'
  });

  const onResourceChange = useCallback(
    newResource => {
      setResource(newResource);
      if (
        !role &&
        newResource?.projectRoles?.[0]?.projectRole?.uri !== 'null'
      ) {
        setRole({
          id: newResource?.projectRoles?.[0]?.projectRole?.uri,
          displayText: newResource?.projectRoles?.[0]?.projectRole?.name
        });
      }
    },
    [setResource, setRole, role]
  );

  const onAddRoleDialogCancelButtonClick = useCallback(() => {
    setRole(null);
  }, [setRole]);

  return (
    <DialogContent>
      <RoleDropdown
        value={role}
        onChange={setRole}
        variant="outlined"
        placeholder={addRoleLabel}
        projectSlug={projectSlug}
        className={classes.spacing}
        label={addRoleLabel}
        onAddRoleDialogCancelButtonClick={onAddRoleDialogCancelButtonClick}
      />

      <ProjectTeamMemberDropdown
        assignedRole={role}
        onResourceChange={onResourceChange}
        projectSlug={projectSlug}
        value={resource}
        selectedResources={[resource]}
        placeholder={addResourceLabel}
        ariaLabel={addResourceLabel}
        variant="outlined"
        label={addResourceLabel}
        taskId={taskId}
      />
    </DialogContent>
  );
};

AddResourceEstimateDialogContent.propTypes = {
  projectSlug: PropTypes.string.isRequired,
  role: PropTypes.object,
  setRole: PropTypes.func.isRequired,
  resource: PropTypes.object,
  setResource: PropTypes.func.isRequired,
  taskId: PropTypes.string
};

export default AddResourceEstimateDialogContent;
