import React from 'react';
import { retryLoading } from '~/util';
import { ResourceManagementIcon } from '~/modules/common/components/Icons';

const ResourceManagement = React.lazy(() =>
  retryLoading(() => import('./ResourceManagement'))
);

const route = ({ intl, me }) => ({
  path: '/resource-pool',
  icon: ResourceManagementIcon,
  isSideBarRoute: true,
  component: ResourceManagement,
  title: () => intl.formatMessage({ id: 'routes.resourceManagement' }),
  hasPermission: Boolean(
    me.permissions.find(
      p =>
        (p.permissionActionUri === 'urn:replicon:user-action:view-user' &&
          p.dataAccessLevelUris.includes(
            'urn:replicon:user-data-access-level:resource-manager'
          )) ||
        (p.permissionActionUri === 'urn:replicon:user-action:view-user' &&
          p.dataAccessLevelUris.includes(
            'urn:replicon:user-data-access-level:resource-pool-manager'
          ))
    )
  )
});

export default route;
