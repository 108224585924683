import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useLocation } from 'react-router-dom';
import MuiDrawer from '@material-ui/core/Drawer';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

export const extractIdFromHash = ({ location: { hash = '' } }, path) => {
  const index = path.indexOf(':');
  const paramIndex = hash.indexOf('?');

  if (index <= 0) {
    return null;
  }

  return hash.startsWith(path.substring(0, index))
    ? paramIndex > index
      ? hash.substring(index, paramIndex)
      : hash.substring(index)
    : null;
};

export const extractQueryParamsFromHash = (
  { location: { hash = '' } },
  path
) => {
  const index = path.indexOf(':');
  const paramIndex = hash.indexOf('?');

  if (paramIndex <= 0) {
    return null;
  }

  return hash.startsWith(path.substring(0, index))
    ? new URLSearchParams(hash.substring(paramIndex))
    : null;
};

export const doesHashMatchPath = (history, path) => {
  if (!history || !history.location || !history.location.hash || !path) {
    return false;
  }

  return extractIdFromHash(history, path) !== null;
};

export const preventClickAwayOnClose = ({
  history,
  path,
  location: { search },
  reason,
  disableBackdropClick,
  disableEscapeKeyDown
}) => {
  if (
    (reason === 'backdropClick' && disableBackdropClick) ||
    (reason === 'escapeKeyDown' && disableEscapeKeyDown)
  )
    return;
  // Prevent issues if a user clicks away from a drawer and
  // onClose is called later.
  const hasHashRoute = doesHashMatchPath(history, path);

  if (!hasHashRoute) return;

  history.replace(`${history.location.pathname}${search}`);
};

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    padding: 0,
    width: 580,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 700
    }
  },
  drawerPaper400: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    padding: 0,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 400
    }
  },
  drawerPaper775: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    padding: 0,
    width: 775,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 775
    }
  }
}));

export const VirtualDrawer = ({
  routeKey,
  component: Drawer,
  drawerWidth,
  history,
  path,
  disableBackdropClick,
  disableEscapeKeyDown,
  useMuiDrawerOverriddenProps,
  ...rest
}) => {
  const classes = useStyles();
  const routeValue = extractIdFromHash(history, path);
  const location = useLocation();
  const open = Boolean(routeValue);

  const onClose = useCallback(
    (e, reason) => {
      preventClickAwayOnClose({
        history,
        path,
        location,
        reason,
        disableBackdropClick,
        disableEscapeKeyDown
      });
    },
    [history, location, path, disableBackdropClick, disableEscapeKeyDown]
  );

  const drawerProps = {
    routeValue,
    open,
    onClose,
    history,
    path,
    queryParams: extractQueryParamsFromHash(history, path),
    [routeKey]: routeValue,
    ...rest
  };

  const { modalProps } = useMuiDrawerOverriddenProps();

  return (
    <MuiDrawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      classes={useMemo(
        () => ({
          paper:
            classes[drawerWidth ? `drawerPaper${drawerWidth}` : 'drawerPaper']
        }),
        [classes, drawerWidth]
      )}
      ModalProps={modalProps}
    >
      <ErrorBoundary>{open && <Drawer {...drawerProps} />}</ErrorBoundary>
    </MuiDrawer>
  );
};

VirtualDrawer.propTypes = {
  routeKey: PropTypes.string,
  history: PropTypes.object.isRequired,
  component: PropTypes.elementType.isRequired,
  drawerWidth: PropTypes.number,
  path: PropTypes.string.isRequired,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  useMuiDrawerOverriddenProps: PropTypes.func
};

export default VirtualDrawer;
