import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RevenuePeriodFormatter } from '~/modules/billing-invoicing/revenueRecognition/components/common/columnFormatters';
import useStyles from './useStyles';

export const MonthFormatter = ({ field, record }) => {
  const { isActive } = record;
  const classes = useStyles({ isActive });
  const { startDate } = record[field];

  return (
    <div className={classes.root}>
      {startDate ? (
        <RevenuePeriodFormatter field={field} record={record} />
      ) : (
        <FormattedMessage id="revenueRun.initialRevenue" />
      )}
    </div>
  );
};

MonthFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default MonthFormatter;
