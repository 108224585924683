import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { NoDataItem } from '~/modules/common/components';
import useStyles from './useStyles';
import ResourcePoolTableHeader from './ResourcePoolTableHeader';
import {
  useAssignedResourcePoolCount,
  useAssignedResourcePools
} from './hooks';

const PAGE_SIZE = 10;

export const ResourcePoolTable = ({
  userDetails,
  searchText,
  currentPage,
  onNextPage,
  onPreviousPage
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const searchFilter = {
    searchTextParam: searchText,
    includeOnlyEnabled: false
  };

  const {
    loading: loadingCount,
    assignedResourcePoolCount
  } = useAssignedResourcePoolCount({
    userSlug: userDetails.slug,
    searchFilter
  });

  const { resourcePools, loading } = useAssignedResourcePools({
    page: currentPage,
    userSlug: userDetails.slug,
    searchFilter,
    pageSize: PAGE_SIZE
  });

  return (
    <>
      {!loading && resourcePools.length === 0 ? (
        <NoDataItem>
          <FormattedMessage id="resourceDrawer.noResourcePoolsAssigned" />
        </NoDataItem>
      ) : loading && resourcePools.length === 0 ? (
        <Grid item className={classes.section}>
          <Skeleton width="100%" height={50} />
        </Grid>
      ) : (
        <Table
          className={classes.table}
          size="small"
          aria-label={intl.formatMessage({
            id: 'resourceDrawer.resourcePools'
          })}
          padding="none"
        >
          <TableHead>
            <ResourcePoolTableHeader
              assignedResourcePoolCount={assignedResourcePoolCount}
              currentPage={currentPage}
              loadingRows={loadingCount}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              pageSize={PAGE_SIZE}
            />
          </TableHead>
          <TableBody>
            {resourcePools.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" className={classes.cell}>
                  <Typography variant="h6" className={classes.cell}>
                    {row.displayText}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

ResourcePoolTable.propTypes = {
  userDetails: PropTypes.object.isRequired,
  currentPage: PropTypes.number,
  searchText: PropTypes.string,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func
};

export default ResourcePoolTable;
