export const allowedExpensesAndEstimatesColumns = {
  EXPENSE_TYPE: 'EXPENSE_TYPE',
  MARK_UP: 'MARK_UP',
  ESTIMATED_BILLING: 'ESTIMATED_BILLING',
  BILLABLE_TYPE: 'BILLABLE_TYPE',
  ESTIMATES: 'ESTIMATES',
  TOTAL_ESTIMATES: 'TOTAL_ESTIMATES',
  ESTIMATE: 'estimate',
  DELETE_ROW: 'DELETE_ROW'
};

export const allowedExpensesAndEstimatesColumnsValues = Object.values(
  allowedExpensesAndEstimatesColumns
);
