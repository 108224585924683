import React from 'react';
import PropTypes from 'prop-types';
import { mapToDefaultRoleRates } from '~/modules/rateCard/enhancers';
import { useMeContext } from '~/modules/me';
import GenericRateCard from '~/modules/rateCard';
import useRoles from '~/modules/common/hooks/useRoles';
import { usePutClientRates } from '~/modules/rateCard/hooks/usePutClientRates';
import useClientRates from './hooks/useClientRates';

const RateCard = props => {
  const { clientUri } = props;

  const me = useMeContext();
  const { defaultOptions: roles, isLoading: isRolesLoading } = useRoles({
    fetchPolicy: 'no-cache',
    includeNoneOption: false,
    includeBillingScheduleEntries: true
  });

  const { isClientRatesLoading, ...clientRatesProps } = useClientRates({
    clientUri
  });
  const putClientRates = usePutClientRates();

  const { defaultRoleRates } = mapToDefaultRoleRates(roles);
  const isLoading = isRolesLoading || isClientRatesLoading;

  return (
    <GenericRateCard
      me={me}
      roles={roles}
      isLoading={isLoading}
      {...defaultRoleRates}
      parentUri={clientUri}
      putRatesHandler={putClientRates}
      {...clientRatesProps}
      {...props}
    />
  );
};

RateCard.propTypes = {
  clientUri: PropTypes.string
};

export default RateCard;
