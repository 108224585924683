import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_USER_SLUG_QUERY = gql`
  query GetResourceUserSlug(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
  ) {
    resourceUsers: resourceUsers2(
      page: $page
      pagesize: $pagesize
      filter: $filter
    ) {
      id
      slug
    }
  }
`;

export const useResourceUserSlug = ({
  page,
  pageSize,
  userUris,
  skip = false
}) => {
  const { loading, data } = useQuery(RESOURCE_USER_SLUG_QUERY, {
    variables: {
      page,
      pagesize: pageSize,
      filter: {
        userIds: userUris
      }
    },
    skip,
    fetchPolicy: 'cache-and-network'
  });

  return {
    loading,
    resourceUsers: data?.resourceUsers || []
  };
};

export default useResourceUserSlug;
