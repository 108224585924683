import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { InnerRecognizableRevenueFormatter } from '~/modules/billing-invoicing/revenueRecognition/components/common/columnFormatters/RecognizableRevenueFormatter';
import useStyles from './useStyles';

export const RecognizableRevenueFormatter = ({ field, record }) => {
  const { isActive, revenueEntryId } = record;
  const classes = useStyles({ isActive });
  const newRecord = useMemo(() => ({ ...record, id: revenueEntryId }), [
    record,
    revenueEntryId
  ]);

  return (
    <div className={classes.root}>
      <InnerRecognizableRevenueFormatter field={field} record={newRecord} />
    </div>
  );
};

RecognizableRevenueFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default RecognizableRevenueFormatter;
