import { useMemo } from 'react';
import { DateTime } from 'luxon';

const useTaskStatusFacts = task => {
  const estimatedEndDelta = useMemo(
    () =>
      task.estimatedCompletionDate && task.endDate
        ? DateTime.fromObject(task.estimatedCompletionDate)
            .diff(DateTime.fromObject(task.endDate), 'days')
            .toObject().days
        : 0,
    [task]
  );

  const actualEndDelta = useMemo(
    () =>
      task.endDate && task.isClosed && task.effectiveClosedOnDate
        ? Math.ceil(
            DateTime.fromISO(task.effectiveClosedOnDate)
              .diff(DateTime.fromObject(task.endDate), 'days')
              .toObject().days
          )
        : 0,
    [task]
  );

  return {
    planned: {
      hours: task.initialEstimatedHours,
      date: {
        start: task.startDate,
        end: task.endDate
      }
    },
    estimated: {
      hours: task.estimatedCompletionHours,
      delta: task.estimatedCompletionHours - task.initialEstimatedHours,
      date: {
        delta: estimatedEndDelta,
        end: task.estimatedCompletionDate || task.endDate
      }
    },
    actual: {
      hours: task.totalActualHours,
      delta: task.totalActualHours - task.initialEstimatedHours,
      date: {
        start: task.effectiveStartDate,
        delta: actualEndDelta,
        end: task.isClosed ? task.effectiveClosedOnDate : null
      }
    }
  };
};

export default useTaskStatusFacts;
