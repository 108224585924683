import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogTitle, makeStyles } from '@material-ui/core';
import { TaskIcon, TaskParentIcon } from '~/modules/common/components';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    width: '1em',
    height: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const useTitleStyles = makeStyles(() => ({
  root: {
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

const RemoveTaskAllocationDialogTitle = () => {
  const { task } = useTaskEstimateRowContext();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const IconComponent = task.hasChildren ? TaskParentIcon : TaskIcon;

  return (
    <DialogTitle
      classes={titleClasses}
      id="remove-task-allocation-dialog-title"
    >
      <span data-qe-id="removeTaskAllocationDialog.title">
        <FormattedMessage id="removeTaskAllocationDialog.title" />
      </span>

      <IconComponent className={classes.icon} />
      <span>{task.name}</span>
    </DialogTitle>
  );
};

export default RemoveTaskAllocationDialogTitle;
