import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getTotalHoursForPeriodFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { getDynamicPositionAttributes } from '~/modules/common/charts/timeline/calculations';
import { isNumeric } from '~/modules/common/numbers';
import { ResourceCostModeType } from '~/types';
import { useMeContext } from '~/modules/me/useMeContext';
import { ALLOCATION_PERIOD_OVERLAP_TYPE } from '~/modules/resourcing/common/enums';
import { getScheduleRulesInChartDisplayRange } from './useAllocationTimelineBlocks2';

export const getAllocationPeriodOverlapType = ({
  periodStart,
  periodEnd,
  allocationStartDate,
  allocationEndDate,
  hasAllocationStart,
  hasAllocationEnd
}) =>
  (hasAllocationStart && !periodStart.equals(allocationStartDate)) ||
  (hasAllocationEnd && !periodEnd.equals(allocationEndDate))
    ? ALLOCATION_PERIOD_OVERLAP_TYPE.PARTIAL
    : ALLOCATION_PERIOD_OVERLAP_TYPE.FULL;

const usePeriodTimelineBlocks = ({
  chartDisplayPeriods,
  chartDisplayDateRange,
  allocation,
  scale,
  getCustomDisplayPeriodProps
}) => {
  const me = useMeContext();
  const {
    resourceCostMode,
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = me;

  const isRoleCostMode = resourceCostMode === ResourceCostModeType.Rolecost;

  const {
    startDate: chartStartDate,
    endDate: chartEndDate
  } = chartDisplayDateRange;

  const { scheduleRules, user, startDate, endDate } = allocation;

  const allocationStartDate = mapIsoStringtoUtcObject(startDate);
  const allocationEndDate = mapIsoStringtoUtcObject(endDate);

  const noOverlap =
    allocationEndDate < chartStartDate || allocationStartDate > chartEndDate;

  const noChartOverlap = isPsaRmpTaskAllocation1Enabled
    ? !scheduleRules || noOverlap
    : noOverlap;

  const scheduleRulesInRange = noChartOverlap
    ? []
    : getScheduleRulesInChartDisplayRange({
        rangeStart: chartStartDate,
        rangeEnd: chartEndDate,
        scheduleRules
      });

  return chartDisplayPeriods.reduce(
    (acc, period) => {
      const { startDate: periodStart, endDate: periodEnd } = period;

      const allocatedHoursForRange = getTotalHoursForPeriodFromScheduleRules({
        start: periodStart,
        end: periodEnd,
        scheduleRules: scheduleRulesInRange,
        useVersion2: true
      });

      const scheduledHoursForRange =
        user?.scheduleDurationByPeriodMap?.[periodStart.toISO()]?.hours || 0;

      const overlayPeriod = {
        ...period,
        totalHours: allocatedHoursForRange,
        percentage: scheduledHoursForRange
          ? (allocatedHoursForRange / scheduledHoursForRange) * 100
          : undefined,
        totalScheduleHours: scheduledHoursForRange,
        allocationPeriodOverlapType: ALLOCATION_PERIOD_OVERLAP_TYPE.NONE,
        totalCost:
          user && isNumeric(allocatedHoursForRange)
            ? {
                amount:
                  allocatedHoursForRange *
                    (isRoleCostMode
                      ? user.primaryRoleCostRate?.amount ||
                        user.primaryRoleCostRate
                      : user.costRate) || 0,
                symbol: isRoleCostMode
                  ? user.primaryRoleCostCurrency?.displayText ||
                    user.primaryRoleCostRate?.currency?.displayText
                  : user.costCurrency && user.costCurrency.displayText
              }
            : undefined
      };

      const hasChartOverlap = !noChartOverlap;

      if (hasChartOverlap) {
        const noPeriodOverlap =
          allocationEndDate < periodStart || allocationStartDate > periodEnd;

        const hasPeriodOverlap = !noPeriodOverlap;

        if (hasPeriodOverlap) {
          const overlapStart = DateTime.max(allocationStartDate, periodStart);
          const overlapEnd = DateTime.min(allocationEndDate, periodEnd);
          const hasAllocationStart = overlapStart.equals(allocationStartDate);
          const hasAllocationEnd = overlapEnd.equals(allocationEndDate);
          const periodOverlapType = getAllocationPeriodOverlapType({
            periodStart,
            periodEnd,
            allocationStartDate,
            allocationEndDate,
            hasAllocationEnd,
            hasAllocationStart
          });

          acc.allocationPeriods.push({
            ...overlayPeriod,
            dynamicPosition: getDynamicPositionAttributes({
              chartStartDate,
              start: overlapStart,
              end: overlapEnd,
              scale,
              isPsaRmpUserSchedulePerfFixEnabled: true
            }).dynamicPosition,
            hasAllocationStart,
            hasAllocationEnd,
            allocationPeriodOverlapType: periodOverlapType,
            ...(getCustomDisplayPeriodProps
              ? getCustomDisplayPeriodProps({
                  periodStart
                })
              : {})
          });

          overlayPeriod.allocationPeriodOverlapType = periodOverlapType;
        }
      }

      acc.overlayPeriods.push(overlayPeriod);

      return acc;
    },
    {
      overlayPeriods: [],
      allocationPeriods: []
    }
  );
};

export default usePeriodTimelineBlocks;
