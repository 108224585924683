import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CHANGE_TASK_RESOURCE = gql`
  mutation changeTaskResourceUser($input: ChangeTaskResourceUserInput!) {
    changeTaskResourceUser(input: $input) {
      taskResourceEstimateId
    }
  }
`;

export const useChangeTaskResource = () => {
  const [changeTaskResourceMutation] = useMutation(CHANGE_TASK_RESOURCE);

  const changeTaskResource = useCallback(
    ({
      taskId,
      projectId,
      newResourceUserId,
      existingResourceUserId,
      allocationMode
    }) =>
      changeTaskResourceMutation({
        variables: {
          input: {
            existingResourceUserId,
            newResourceUserId,
            projectId,
            taskId,
            allocationMode
          }
        }
      }),
    [changeTaskResourceMutation]
  );

  return { changeTaskResource };
};

export default useChangeTaskResource;
