import { Divider, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { RevisionEntryField } from './RevisionEntryField';
import { RevisionEntryTitle } from './RevisionEntryTitle';

const useStyles = makeStyles(theme => ({
  dividerMargin: {
    margin: theme.spacing(2, 0)
  }
}));

export const RevisionEntry = ({
  dataQeId,
  revisionReferenceKeys,
  revisionData,
  fieldMetadata
}) => {
  const classes = useStyles();
  const { revisionValue, isInitialRevision } = revisionData;
  const validRevisionFieldKeys = Object.keys(fieldMetadata).filter(k =>
    Boolean(revisionValue[k])
  );
  const lastValidKey =
    validRevisionFieldKeys.length &&
    validRevisionFieldKeys[validRevisionFieldKeys.length - 1];

  return lastValidKey ? (
    <div className={classes.content} data-qe-id={dataQeId}>
      <RevisionEntryTitle
        revisionReferenceKeys={revisionReferenceKeys}
        actualUser={revisionData.actualUser}
        effectiveUser={revisionData.effectiveUser}
        isInitialRevision={isInitialRevision ?? false}
        timestamp={revisionData.timestamp}
      />
      {Object.entries(fieldMetadata).map(([key, metadataValue], index) => {
        const isLastKey = lastValidKey === key;

        return (
          revisionValue[key] && (
            <RevisionEntryField
              dataQeId={`${dataQeId}_Field${index}`}
              key={uuid()}
              revisionData={revisionData}
              metadataValue={metadataValue}
              showDivider={!isLastKey}
            />
          )
        );
      })}
      <Divider className={classes.dividerMargin} variant="fullWidth" />
    </div>
  ) : null;
};

RevisionEntry.propTypes = {
  dataQeId: PropTypes.string,
  revisionReferenceKeys: PropTypes.object.isRequired,
  revisionData: PropTypes.object.isRequired,
  fieldMetadata: PropTypes.object.isRequired
};

export default RevisionEntry;
