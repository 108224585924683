import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import { useMeContext } from '~/modules/me/useMeContext';
import GroupsInformationReadOnly from '~/modules/resource-management/resourceUserDetails/GroupsInformation/GroupsInformationReadOnly';

const title = <FormattedMessage id="resourceDrawer.groups" />;

export const GroupSection = ({ userDetails, permittedActions }) => {
  const me = useMeContext();

  return (
    <InfoSection editable={false} title={title}>
      <GroupsInformationReadOnly
        me={me}
        userDetails={userDetails}
        permittedActions={permittedActions}
      />
    </InfoSection>
  );
};

GroupSection.propTypes = {
  userDetails: PropTypes.object.isRequired,
  permittedActions: PropTypes.array.isRequired
};

export default GroupSection;
