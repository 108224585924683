import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DelaStarIcon } from '~/modules/common/components';
import { useBulkMarkAttachmentAsRead } from '~/modules/attachments/AttachmentsBlock/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[600],
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(1.75),
    borderRadius: theme.spacing(3)
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '65%'
  },
  text: {
    color: theme.palette.common.white
  },
  button: {
    marginTop: theme.spacing(1.75),
    padding: theme.spacing(0.75, 3, 0.75, 3),
    borderRadius: theme.spacing(2.5),
    color: theme.palette.common.white,
    backgroundImage: `linear-gradient(119deg, ${theme.palette.Dela.button.gradient.purple} 17.59%, ${theme.palette.Dela.button.gradient.blue} 77.78%)`,
    boxShadow: 'none'
  }
}));

const useDelaStarIconStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 1),
    fill: theme.palette.common.white,
    fontSize: theme.spacing(4)
  }
}));

export const DelaResourceRequestsNotification = ({ attachments }) => {
  const classes = useStyles();
  const delaStarIconClasses = useDelaStarIconStyles();
  const history = useHistory();

  const { bulkMarkAttachmentAsRead } = useBulkMarkAttachmentAsRead();

  const handler = useCallback(() => {
    bulkMarkAttachmentAsRead({
      binaryObjectUris: attachments.map(attachment => attachment.uploadUri)
    });
    history.push({
      pathname: `${history.location.pathname}/resourcingplan`
    });
  }, [bulkMarkAttachmentAsRead, attachments, history]);

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <DelaStarIcon classes={delaStarIconClasses} />
      </div>
      <div className={classes.column}>
        <div className={classes.row}>
          <Typography className={classes.text} variant="subtitle1">
            <FormattedMessage id="resourceRequestsNotification.createdResourceRequests" />
          </Typography>
        </div>
        <div className={classes.row}>
          <Button
            onClick={handler}
            className={classes.button}
            variant="contained"
          >
            <FormattedMessage id="resourceRequestsNotification.viewResourceRequests" />
          </Button>
        </div>
      </div>
    </div>
  );
};

DelaResourceRequestsNotification.propTypes = {
  attachments: PropTypes.array.isRequired
};

export default DelaResourceRequestsNotification;
