import React from 'react';
import { IntlProvider } from 'react-intl';

import { buildMessages } from './messages';

const locale = navigator.language || navigator.browserLanguage || 'en';

const withDefaultIntlProvider = BaseComponent => ({ ...props }) => (
  <IntlProvider locale={locale} messages={buildMessages(locale)}>
    <BaseComponent {...props} />
  </IntlProvider>
);

export default withDefaultIntlProvider;
