import { usePortfolioProjectsPermissions } from '~/modules/common/hooks/portfolio';
import { portfolioInfoRoute } from '../components/PortfolioInfo';
import { projectListRoute } from '../components/ProjectList';
import { projectRequestTabRoute } from '../components/ProjectRequestTab';
import usePortfolioProjectRequestsPermissions from './usePortfolioProjectRequestsPermissions';

const useTabRoutes = ({ portfolioManager, ancestors } = {}) => {
  const { canViewPortfolioProjects } = usePortfolioProjectsPermissions({
    portfolioManager,
    ancestors
  });

  const {
    canViewPortfolioProjectRequests
  } = usePortfolioProjectRequestsPermissions({
    portfolioManager,
    ancestors
  });

  return [
    portfolioInfoRoute(),
    canViewPortfolioProjectRequests && projectRequestTabRoute(),
    canViewPortfolioProjects && projectListRoute()
  ].filter(r => r);
};

export default useTabRoutes;
