import React from 'react';
import { PayrollIcon } from '~/modules/common/components/Icons';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';

const payrollRoute = ({ me, intl }) => {
  const { uiRootPath } = me;
  const hasPermission = me.userAccessRoles.find(
    role => role === USER_ACCESS_ROLE.PAYROLL_MANAGER
  );

  return {
    path: `${uiRootPath}payroll`,
    icon: <PayrollIcon />,
    isSideBarRoute: true,
    title: intl.formatMessage({ id: 'routes.payroll' }),
    hasPermission: Boolean(hasPermission),
    exact: false,
    isExternal: true,
    hidden: true
  };
};

export default payrollRoute;
