import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    whiteSpace: 'nowrap',
    width: theme.spacing(30),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  drawerPaperPermanent: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      delay: 500
    }),
    width: theme.spacing(8),
    boxShadow: `inset -10px 0px 5px -10px ${alpha(
      theme.palette.common.black,
      0.15
    )}`,
    '&:hover': {
      width: theme.spacing(30),
      boxShadow: `inset -10px 0px 5px -10px ${alpha(
        theme.palette.common.black,
        0
      )}, 0px 2px 4px -1px ${alpha(
        theme.palette.common.black,
        0.2
      )}, 0px 4px 5px 0px ${alpha(
        theme.palette.common.black,
        0.14
      )}, 0px 1px 10px 0px ${alpha(theme.palette.common.black, 0.12)}`
    }
  },
  drawerPaperPinned: {
    position: 'relative',
    width: theme.spacing(30),
    boxShadow: `inset -10px 0px 5px -10px ${alpha(
      theme.palette.common.black,
      0.15
    )}`,
    '&:hover': {
      width: theme.spacing(30),
      boxShadow: `inset -10px 0px 5px -10px ${alpha(
        theme.palette.common.black,
        0.15
      )}`
    }
  },
  logoContainer: {
    display: 'flex',
    flexBasis: theme.spacing(8),
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: ({ isSubstituteUser, isDocked }) =>
      isSubstituteUser && isDocked ? theme.spacing(4) : 0
  },
  menuContainer: {
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: [[theme.palette.grey[400], 'transparent']],
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400]
    }
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2)
    }
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;
