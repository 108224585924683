import { mapToProjectRates } from '~/modules/rateCard/enhancers';
import {
  mergeAndGroupRates,
  getRatesCount
} from '~/modules/projects/project/RateCard/enhancers/useAllProjectRates';
import { useGetClientRatesQuery } from '~/types';

type LoadMoreRowsInput = {
  rateTypeKey: string;
  fetchMore: Function;
  variables: {
    uri: string;
    roleRatePage: number;
    roleRatePageSize: number;
    resourceRatePage: number;
    resourceRatePageSize: number;
  };
  clientDetails: any;
};

const loadMoreRows = async ({
  rateTypeKey,
  fetchMore,
  variables,
  clientDetails
}: LoadMoreRowsInput): Promise<void> => {
  await fetchMore({
    variables: {
      ...variables,
      roleRatePage:
        rateTypeKey === 'roleRates'
          ? Math.ceil(
              getRatesCount(clientDetails.roleRates || []) /
                variables.roleRatePageSize
            ) + 1
          : -1,
      resourceRatePage:
        rateTypeKey === 'userRates'
          ? Math.ceil(
              getRatesCount(clientDetails.userRates || []) /
                variables.resourceRatePageSize
            ) + 1
          : -1
    },
    updateQuery: (
      previousResult: any,
      { fetchMoreResult: { client } }: { fetchMoreResult: { client: any } }
    ) => ({
      ...previousResult,
      client: {
        ...previousResult.client,
        [rateTypeKey]: mergeAndGroupRates(
          previousResult.client[rateTypeKey],
          client[rateTypeKey],
          rateTypeKey === 'roleRates' ? 'roleReference' : 'userReference'
        )
      }
    })
  });
};

type UseClientRatesInput = {
  clientUri: string;
  roleRatePage: number;
  roleRatePageSize: number;
  resourceRatePage: number;
  resourceRatePageSize: number;
};

type RateScheduleEntryMapping = {
  billingScheduleEntries: any[];
  costScheduleEntries: any[];
  effectiveBillingRate: any[];
};

type UseClientRatesOutput = {
  projectRate?: RateScheduleEntryMapping[];
  roleRates?: RateScheduleEntryMapping[];
  userRates?: RateScheduleEntryMapping[];
  hasMoreRoleRateRows?: boolean;
  hasMoreResourceRateRows?: boolean;
  roleRatesCount?: number;
  userRatesCount?: number;
  loadMoreRows?: Function;
  hasServiceError: boolean;
  isClientRatesLoading: boolean;
};

const useClientRates = ({
  clientUri,
  roleRatePage = 1,
  roleRatePageSize = 10,
  resourceRatePage = 1,
  resourceRatePageSize = 10
}: UseClientRatesInput): UseClientRatesOutput => {
  const variables = {
    uri: clientUri,
    roleRatePage,
    roleRatePageSize,
    resourceRatePage,
    resourceRatePageSize,
    effectiveDateRange: null
  };

  const { data, loading, error, fetchMore } = useGetClientRatesQuery({
    variables,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  if (!data) {
    return {
      hasServiceError: Boolean(error),
      isClientRatesLoading: loading
    };
  }

  const { client: clientDetails }: { client?: any } = data;

  const { projectRate, roleRates, userRates } = mapToProjectRates(
    clientDetails
  );

  return {
    projectRate,
    roleRates,
    userRates,
    hasMoreRoleRateRows:
      getRatesCount(clientDetails.roleRates) < clientDetails.roleRatesCount,
    hasMoreResourceRateRows:
      getRatesCount(clientDetails.userRates) < clientDetails.userRatesCount,
    roleRatesCount: clientDetails.roleRatesCount,
    userRatesCount: clientDetails.userRatesCount,
    loadMoreRows: async ({
      rateTypeKey
    }: {
      rateTypeKey: string;
    }): Promise<void> => {
      try {
        await loadMoreRows({
          rateTypeKey,
          fetchMore,
          variables,
          clientDetails
        });
        // eslint-disable-next-line no-empty
      } catch {}
    },
    hasServiceError: Boolean(error),
    isClientRatesLoading: loading
  };
};

export default useClientRates;
