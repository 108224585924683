import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useProjectTypeToggleProps } from '~/modules/common/components/ProjectTypeToggle/useProjectTypeToggleProps';
import { PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS } from '~/modules/common/enums';
import PortfolioProjectsContext from './PortfolioProjectsContext';

export default function PortfolioProjectsContextProvider({ children }) {
  const initialState = {
    filterByAccess: PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS,
    filterByStatus: null
  };
  const [quickFilter, setQuickFilter] = useState(initialState);

  const { projectType, onProjectTypeClick } = useProjectTypeToggleProps();

  const handleSetQuickFilter = useCallback(
    (filterByAccess, filterByStatus) => {
      setQuickFilter({
        filterByAccess,
        filterByStatus
      });
    },
    [setQuickFilter]
  );

  const resetSummaryFilter = useCallback(() => {
    setQuickFilter(initialState);
  }, [initialState]);

  const summaryFilterState = {
    quickFilter,
    setKeys: handleSetQuickFilter,
    resetSummaryFilter
  };

  const [searchCriteria, setSearchCriteria] = useState({
    keywords: [],
    criterions: {}
  });

  return (
    <PortfolioProjectsContext.Provider
      value={useMemo(
        () => ({
          projectType,
          onProjectTypeClick,
          searchCriteria,
          setSearchCriteria,
          summaryFilterState
        }),
        [onProjectTypeClick, projectType, searchCriteria, summaryFilterState]
      )}
    >
      {children}
    </PortfolioProjectsContext.Provider>
  );
}

PortfolioProjectsContextProvider.propTypes = {
  children: PropTypes.node
};
