import { useCallback } from 'react';
import { ResourcePlanGroupBy } from '~/types';
import useResourceAllocationChartTabStateContext, {
  RESOURCE_ALLOCATION_CHART_TAB_ENUM
} from './useResourceAllocationChartTabStateContext';
import useResourceAllocationToolbarStateContext from './useResourceAllocationToolbarStateContext';

const useRedirectToAssignResource = ({
  history,
  resourceRequest,
  isResourcingTab = true,
  scale,
  displayUnit
}) => {
  const {
    selectedChartTab = RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL
  } = useResourceAllocationChartTabStateContext();

  const { resourcePlanGroup } = useResourceAllocationToolbarStateContext();

  const { id, projectUri, role } = resourceRequest;

  const onAssignResourceClick = useCallback(() => {
    return isResourcingTab
      ? history.push(
          `/resourcing/assignment/${
            resourcePlanGroup === ResourcePlanGroupBy.Role
              ? role
                ? role.id
                : 'no_value'
              : projectUri
          }/${selectedChartTab.toLowerCase()}/${id}`,
          { scale, displayUnit }
        )
      : history.push(`/projectResourcing/assignment/${projectUri}/all/${id}`, {
          scale,
          displayUnit
        });
  }, [
    scale,
    displayUnit,
    isResourcingTab,
    history,
    resourcePlanGroup,
    role,
    projectUri,
    selectedChartTab,
    id
  ]);

  return onAssignResourceClick;
};

export default useRedirectToAssignResource;
