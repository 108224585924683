import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize
  },
  date: {
    cursor: 'pointer'
  },
  dateAdornedEnd: {
    paddingRight: 0
  },
  dateAdornmentRoot: {
    top: '50%',
    right: 0,
    color: theme.palette.text.secondary,
    position: 'absolute',
    pointerEvents: 'none'
  },
  formFields: {
    position: 'relative',
    marginBottom: theme.spacing(2)
  },
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2100
  },
  input: {
    fontSize: 'inherit'
  },
  removeButton: {
    flexGrow: 1,
    textAlign: 'right'
  },
  role: {
    padding: 0
  },
  readOnly: {
    marginLeft: theme.spacing(2)
  },
  divider: {
    width: '100%',
    height: theme.spacing(0.25),
    position: 'absolute',
    backgroundColor: theme.palette.divider
  },
  estimateCostlabel: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 0, 0, 6),
    fontSize: theme.typography.body2.fontSize
  },
  avatar: {
    marginTop: theme.spacing(0)
  }
}));

export default useStyles;
