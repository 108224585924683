import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useMeContext } from '~/modules/me';
import { PeriodSelector, PeriodDropdown } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { ResourcingDisplayUnitToggleButtonGroup } from '~/modules/resourcing/common/components';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import SettingsButton from '~/modules/common/components/ListTableToolBar/SettingsButton';
import ResourceAllocationUserChartDialog from './ResourceAllocationUserChartDialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.background.paper,
    flexGrow: 0,
    flexShrink: 0,
    position: 'sticky',
    left: 0,
    zIndex: 5,
    top: theme.spacing(12),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  toggleGroup: {
    marginLeft: theme.spacing(1)
  }
}));

const usePeriodDropdownStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 0, 0, 1)
  }
}));

export const ResourceAllocationUserChartToolbar = ({
  classes: classesOverride,
  scale,
  setScale,
  onPreviousClick,
  onNextClick,
  dateRange,
  LegendComponent,
  showDateRange,
  showDisplayUnitToggle = true,
  showYearsInPeriodDropdown = true,
  displayDialog = false
}) => {
  const { displayUnit, setDisplayUnit } = useDisplayUnitContext();
  const classes = useStyles({ classes: classesOverride });
  const periodClasses = usePeriodDropdownStyles();

  const { open, closeDialog, openDialog } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const me = useMeContext();

  return (
    <div className={classes.root}>
      <div className={classes.spacer}>
        <PeriodSelector
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          dateRange={dateRange}
          showDateRange={showDateRange}
        />
      </div>
      <div className={classes.spacer} />
      {LegendComponent}
      {showDisplayUnitToggle && (
        <div className={classes.toggleGroup}>
          <ResourcingDisplayUnitToggleButtonGroup
            displayUnit={displayUnit}
            setDisplayUnit={setDisplayUnit}
          />
        </div>
      )}
      <PeriodDropdown
        classes={periodClasses}
        value={scale}
        onChange={setScale}
        showYears={showYearsInPeriodDropdown}
      />
      {displayDialog && (
        <>
          <SettingsButton
            dataQeId="ResourceAllocationPage"
            onClick={openDialog}
          />
          <ResourceAllocationUserChartDialog
            open={open}
            onClose={closeDialog}
            me={me}
          />
        </>
      )}
    </div>
  );
};
ResourceAllocationUserChartToolbar.propTypes = {
  classes: PropTypes.object,
  scale: PropTypes.string.isRequired,
  setScale: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  showDisplayUnitToggle: PropTypes.bool,
  showDateRange: PropTypes.bool,
  LegendComponent: PropTypes.any,
  showYearsInPeriodDropdown: PropTypes.bool,
  displayDialog: PropTypes.bool
};

export default ResourceAllocationUserChartToolbar;
