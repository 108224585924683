import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  mapToServiceRequest,
  mapToServiceRequestWithTimeoff
} from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import {
  COMPLETE_RESOURCE_REQUEST,
  COMPLETE_RESOURCE_REQUEST_WITH_TIMEOFF
} from './withMarkResourceRequestAsComplete';

const useMarkResourceRequestAsComplete = ({
  resourceRequest,
  showTimeOff,
  chartDateRange,
  showHolidays
}) => {
  const [markResourceRequestAsComplete] = useMutation(
    COMPLETE_RESOURCE_REQUEST
  );
  const [markResourceRequestAsCompleteWithTimeoff] = useMutation(
    COMPLETE_RESOURCE_REQUEST_WITH_TIMEOFF
  );

  const requestParams = {
    request: resourceRequest,
    responseType: 'markResourceRequestAsComplete2',
    responseTypeName: 'MarkResourceRequestAsCompleteResult',
    requestStatus: ResourceRequestStatus.Complete
  };

  const onMarkResourceRequestAsComplete = useCallback(() => {
    if (!showTimeOff && !showHolidays) {
      markResourceRequestAsComplete(
        mapToServiceRequest({
          ...requestParams,
          chartDateRange,
          showTimeOff: Boolean(showTimeOff),
          showHolidays: Boolean(showHolidays)
        })
      );
    } else {
      markResourceRequestAsCompleteWithTimeoff(
        mapToServiceRequestWithTimeoff({
          ...requestParams,
          chartDateRange,
          showTimeOff,
          showHolidays
        })
      );
    }
  }, [
    chartDateRange,
    markResourceRequestAsComplete,
    markResourceRequestAsCompleteWithTimeoff,
    requestParams,
    showHolidays,
    showTimeOff
  ]);

  return {
    onMarkResourceRequestAsComplete
  };
};

export default useMarkResourceRequestAsComplete;
