import { useState } from 'react';

export default (initialState = false) => {
  const [expanded, setExpanded] = useState(initialState);

  return {
    expanded,
    setExpanded
  };
};
