import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';
import { ProgressContent } from './ProgressContent';
import { ConfirmationContent } from './ConfirmationContent';
import { AutoGenerateReferenceRequiredContent } from './AutoGenerateReferenceRequiredContent';

const useStyles = makeStyles(theme => ({
  successRoot: {
    backgroundColor: theme.palette.success.lightBackground
  },
  success: {
    padding: theme.spacing(1, 2, 1, 2),
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    width: '520px'
  }
}));

export const BulkBillProgressAndConfirmationDialog = ({
  open,
  selectedBillCount,
  billingBatchState,
  setBillingBatchState,
  batchCreationInProgress,
  createdBills,
  onBatchComplete,
  onCancelClick,
  onConfirmClick,
  isAutoGeneratedReferenceNumber
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const values = useMemo(
    () => ({
      count: billingBatchState?.overallProgress?.currentProgress
    }),
    [billingBatchState?.overallProgress?.currentProgress]
  );

  const createdBillCounts = useMemo(
    () => ({
      count: createdBills.length
    }),
    [createdBills.length]
  );

  return (
    <Dialog open={open} classes={useDialogStyles()}>
      {isAutoGeneratedReferenceNumber ? (
        <>
          <DialogTitle>
            <FormattedMessage
              id="availableToBillList.createBill"
              values={values.count ? values : selectedBillCount}
            />
          </DialogTitle>
          <DialogContent>
            {billingBatchState?.batchId ? (
              billingBatchState?.batchInProgress ? (
                <ProgressContent
                  batchId={billingBatchState?.batchId}
                  setBillingBatchState={setBillingBatchState}
                  onBatchComplete={onBatchComplete}
                />
              ) : (
                <Grid container className={classes.successRoot}>
                  <Typography
                    className={classes.success}
                    dataQeId="BulkBillCreatedSuccessMessage"
                  >
                    <FormattedMessage
                      id="availableToBillList.billsCreatedSuccessfully"
                      values={createdBillCounts}
                    />
                  </Typography>
                </Grid>
              )
            ) : (
              <ConfirmationContent selectedBillCount={selectedBillCount} />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onCancelClick}
              aria-label={formatMessage({
                id: billingBatchState?.hasProcessed
                  ? 'button.close'
                  : 'button.cancel'
              })}
            >
              <FormattedMessage
                id={
                  billingBatchState?.hasProcessed
                    ? 'button.close'
                    : 'button.cancel'
                }
              />
            </Button>
            {(!billingBatchState?.batchInProgress ||
              billingBatchState?.hasProcessed) && (
              <LoadingButton
                isLoading={batchCreationInProgress}
                color="primary"
                autoFocus
                onClick={onConfirmClick}
                dataQeId={
                  billingBatchState?.hasProcessed ? 'ViewBills' : 'CreateBills'
                }
              >
                <FormattedMessage
                  id={
                    billingBatchState?.hasProcessed
                      ? 'availableToBillList.viewBills'
                      : 'availableToBillList.createBills'
                  }
                  values={
                    billingBatchState?.hasProcessed ? values : selectedBillCount
                  }
                />
              </LoadingButton>
            )}
          </DialogActions>
        </>
      ) : (
        <AutoGenerateReferenceRequiredContent onCancelClick={onCancelClick} />
      )}
    </Dialog>
  );
};
BulkBillProgressAndConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  selectedBillCount: PropTypes.object,
  billingBatchState: PropTypes.object,
  setBillingBatchState: PropTypes.func,
  batchCreationInProgress: PropTypes.bool,
  createdBills: PropTypes.array,
  onBatchComplete: PropTypes.func,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  isAutoGeneratedReferenceNumber: PropTypes.bool
};

export default BulkBillProgressAndConfirmationDialog;
