import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { AllowedExpensesAndEstimatesListTable } from './AllowedExpensesAndEstimatesListTable';
import { TotalEstimatesLabel } from './renderers';
import { allowedExpensesAndEstimatesColumnsValues } from './hooks';

export const AllowedExpensesAndEstimates = ({
  billPlanEditable = false,
  showBillableExpenseOnly = false,
  chartDates,
  columnClasses,
  editable,
  projectEndDate,
  projectStartDate,
  providerClasses,
  totals,
  visibleColumns
}) => {
  const { values } = useFormikContext();
  const totalEstimatesColumnLabel = useMemo(
    () => (
      <TotalEstimatesLabel
        showActuals={values.showActuals}
        projectStartDate={projectStartDate}
        projectEndDate={projectEndDate}
      />
    ),
    [projectEndDate, projectStartDate, values.showActuals]
  );

  const records = useMemo(
    () =>
      values.allowedExpensesAndEstimates.filter(
        ({ scriptId }) => !showBillableExpenseOnly || scriptId
      ),
    [showBillableExpenseOnly, values.allowedExpensesAndEstimates]
  );

  return (
    <AllowedExpensesAndEstimatesListTable
      billPlanEditable={billPlanEditable}
      totals={totals}
      chartDates={chartDates}
      editable={editable}
      projectStartDate={projectStartDate}
      projectEndDate={projectEndDate}
      totalEstimatesColumnLabel={totalEstimatesColumnLabel}
      visibleColumns={visibleColumns}
      columnClasses={columnClasses}
      providerClasses={providerClasses}
      records={records}
    />
  );
};

AllowedExpensesAndEstimates.propTypes = {
  billPlanEditable: PropTypes.bool,
  showBillableExpenseOnly: PropTypes.bool,
  chartDates: PropTypes.array,
  columnClasses: PropTypes.object,
  editable: PropTypes.bool,
  projectEndDate: PropTypes.string,
  projectStartDate: PropTypes.string,
  providerClasses: PropTypes.object,
  totals: PropTypes.object,
  visibleColumns: PropTypes.arrayOf(
    PropTypes.oneOf(allowedExpensesAndEstimatesColumnsValues)
  ).isRequired
};

export default AllowedExpensesAndEstimates;
