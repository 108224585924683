import GroupColumn from '~/modules/common/components/Groups/GroupColumn';
import { Date } from '~/modules/common/components/ListTable/renderers';
import { UserFormatter } from './formatters/UserFormatter';
import { RowSelectorFormatter } from './formatters/RowSelectorFormatter';
import { TextFormatter } from './formatters/TextFormatter';
import { SupervisorFormatter } from './formatters/SupervisorFormatter';
import { RoleFormatter } from './formatters/RoleFormatter';

export const getFormatters = () => ({
  rowSelector: RowSelectorFormatter,
  name: UserFormatter,
  employeeId: TextFormatter,
  startDate: Date,
  endDate: Date,
  effectiveRoles: RoleFormatter,
  supervisorAssignment: SupervisorFormatter,
  currentDepartment: GroupColumn
});

export default getFormatters;
