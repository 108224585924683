import { useState, useCallback } from 'react';
import { useResourceAssignmentDialogContext } from '../ResourceAssignmentDialogContext';
import { usePutUserAssignmentForVendor } from './usePutUserAssignmentForVendor';

export const useAddUserToVendor = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState(null);
  const { vendor } = useResourceAssignmentDialogContext();

  const { submitting, assignUserToVendor } = usePutUserAssignmentForVendor({
    vendorUri: vendor.id,
    userUri: selectedUser?.uri,
    onError: setError
  });

  const onRowClick = useCallback(user => {
    if (user?.disabled) return;
    setSelectedUser(user);
  }, []);

  const handleSubmit = useCallback(() => {
    assignUserToVendor();
    setSelectedUser(null);
  }, [assignUserToVendor]);

  return {
    onRowClick,
    submitting,
    handleSubmit,
    error,
    assignUserToVendor,
    selectedUser
  };
};
