import React from 'react';
import { RoutesConsumer } from '~/modules/common/routes';

const withSideNavigationRouteFilter = BaseComponent => props => (
  <RoutesConsumer>
    {routes => (
      <BaseComponent
        {...props}
        routes={Object.keys(routes)
          .filter(key => routes[key].isSideBarRoute || false)
          .reduce((r, key) => ({ ...r, [key]: routes[key] }), {})}
      />
    )}
  </RoutesConsumer>
);

export default withSideNavigationRouteFilter;
