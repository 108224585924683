import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { EVENT_TYPE } from '~/modules/common/enums/EventTypes';
import { useOnRemoveTaskResourceEstimateSubmit } from '../../hooks/useOnChangeHandlers';
import { DEFAULT_PAGE_SIZE } from '../../hooks/useTaskResourceEstimates';

export const useStyles = makeStyles(theme => ({
  actionContainer: {
    margin: theme.spacing(1, 2, 1, 0)
  }
}));

const RemoveTaskEstimateConfirmationDialogFooter = ({
  projectId,
  taskId,
  resourceEstimateId,
  onClose,
  onRemoveResourceEstimateSuccess
}) => {
  const {
    loading,
    onRemoveTaskResourceEstimateSubmit
  } = useOnRemoveTaskResourceEstimateSubmit({ projectId, taskId });

  const classes = useStyles();

  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  const onRemoveEstimateSubmit = useCallback(async () => {
    const resourceEstimate = resourceEstimates.find(
      est => est.id === resourceEstimateId
    );
    const {
      omitRemovedTaskEstimateFunc
    } = await onRemoveTaskResourceEstimateSubmit(
      {
        taskResourceEstimateId: resourceEstimateId,
        resourceUserId: resourceEstimate.resourceUser?.id
      },
      [
        {
          eventName: EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
          payload: {
            taskId,
            updateResourceEstimatesCountBy: -1,
            updateResourceEstimatesWithUsersCountBy: resourceEstimate.resourceUser
              ? -1
              : 0
          }
        }
      ]
    );

    if (resourceEstimates.length === DEFAULT_PAGE_SIZE) {
      await onRemoveResourceEstimateSuccess({
        setFormikFieldValue: setFieldValue
      });
    } else {
      setFieldValue(
        'resourceEstimates',
        resourceEstimates.filter(omitRemovedTaskEstimateFunc)
      );
    }
    onClose();
  }, [
    resourceEstimates,
    onRemoveTaskResourceEstimateSubmit,
    resourceEstimateId,
    taskId,
    setFieldValue,
    onClose,
    onRemoveResourceEstimateSuccess
  ]);

  return (
    <DialogActions className={classes.actionContainer}>
      <Button onClick={onClose} autoFocus>
        <FormattedMessage id="removeTaskEstimateDialog.cancel" />
      </Button>
      <LoadingButton
        color="secondary"
        onClick={onRemoveEstimateSubmit}
        isLoading={loading}
      >
        <FormattedMessage id="removeTaskEstimateDialog.removeEstimate" />
      </LoadingButton>
    </DialogActions>
  );
};

RemoveTaskEstimateConfirmationDialogFooter.propTypes = {
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  resourceEstimateId: PropTypes.string,
  onClose: PropTypes.func,
  onRemoveResourceEstimateSuccess: PropTypes.func
};

export default RemoveTaskEstimateConfirmationDialogFooter;
