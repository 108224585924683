import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    flexGrow: 0,
    flexShrink: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize
  }
}));

export const SubstituteUserItem = ({ onClick, user }) => {
  const classes = useStyles();
  const handleClick = useCallback(() => onClick(user), [onClick, user]);

  return (
    <ListItem button onClick={handleClick}>
      <ListItemAvatar>
        <UserAvatar user={user} size="small" className={classes.avatar} />
      </ListItemAvatar>
      <ListItemText primary={user.displayText} />
    </ListItem>
  );
};

SubstituteUserItem.propTypes = {
  user: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SubstituteUserItem;
