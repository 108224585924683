import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Grid,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'formik';
import { isEmpty } from 'lodash';
import { useMeContext } from '~/modules/me/useMeContext';
import { GridItem } from '~/modules/common/components';
import { getError } from '~/util';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import ClientInfoContactAddress from '../ClientInfoAddressSection';
import { useClientPermissions } from '../../../hooks';
import CountrySelect from './CountrySelect';

const addressLabel = <FormattedMessage id="client.address" />;
const cityLabel = <FormattedMessage id="client.city" />;
const stateProvinceLabel = <FormattedMessage id="client.stateProvince" />;
const zipPostalCodeLabel = <FormattedMessage id="client.zipPostalCode" />;
const billingAddressLabel = <FormattedMessage id="client.billingAddress" />;
const sameAsAddressLabel = <FormattedMessage id="client.sameAsAddress" />;

const getInputProps = ({
  ariaLabelKey,
  formatMessage,
  isPsaPrpAccessibilityIssuesEnabled
}) => {
  return isPsaPrpAccessibilityIssuesEnabled
    ? { 'aria-label': formatMessage({ id: ariaLabelKey }) }
    : {};
};
const useStyles = makeStyles(theme => ({
  billingAddressHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    marginLeft: theme.spacing(3)
  }
}));

export const useHandleAddressChangeCallback = ({
  canUseBillingSameAsAddress,
  setFieldValue,
  handleChange
}) =>
  useCallback(
    event => {
      handleChange(event);
      if (canUseBillingSameAsAddress) {
        const { name, value } = event.target;

        const billingName = `billing${name[0].toUpperCase()}${name.slice(1)}`;

        setFieldValue(billingName, value);
      }
    },
    [handleChange, canUseBillingSameAsAddress, setFieldValue]
  );

export const useHandleBillingSameAsAddressClickCallback = ({
  setBillingSameAsAddress,
  billingSameAsAddress,
  setValues,
  values
}) =>
  useCallback(() => {
    setBillingSameAsAddress(!billingSameAsAddress);

    if (!billingSameAsAddress) {
      setValues({
        ...values,
        billingContactInfo: values.contactInfo
      });
    }
  }, [setBillingSameAsAddress, billingSameAsAddress, setValues, values]);

export const EditClientAddressDialogForm = ({
  formik: { handleChange, values, errors, setFieldValue, setValues },
  countries,
  billingSameAsAddress: initialBillingSameAsAddress
}) => {
  const { formatMessage } = useIntl();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const {
    canViewBillingAddress,
    canEditBillingAddress
  } = useClientPermissions();

  const classes = useStyles();

  const [billingSameAsAddress, setBillingSameAsAddress] = useState(
    initialBillingSameAsAddress
  );

  const handleAddressChange = useHandleAddressChangeCallback({
    canUseBillingSameAsAddress: billingSameAsAddress && canEditBillingAddress,
    setFieldValue,
    handleChange
  });

  const handleBillingSameAsAddressClick = useHandleBillingSameAsAddressClickCallback(
    {
      setBillingSameAsAddress,
      billingSameAsAddress,
      setValues,
      values
    }
  );

  const { contactInfo, billingContactInfo } = values;

  const {
    address,
    city,
    stateProvince,
    zipPostalCode,
    countryUri
  } = contactInfo;

  const {
    address: billingAddress,
    city: billingCity,
    stateProvince: billingStateProvince,
    zipPostalCode: billingZipPostalCode,
    countryUri: billingCountryUri
  } = billingContactInfo;

  const addressError = getError(errors, 'contactInfo.address');
  const cityError = getError(errors, 'contactInfo.city');
  const stateProvinceError = getError(errors, 'contactInfo.stateProvince');
  const zipPostalCodeError = getError(errors, 'contactInfo.zipPostalCode');

  const billingAddressError = getError(errors, 'billingContactInfo.address');
  const billingCityError = getError(errors, 'billingContactInfo.city');
  const billingStateProvinceError = getError(
    errors,
    'billingContactInfo.stateProvince'
  );
  const billingZipPostalCodeError = getError(
    errors,
    'billingContactInfo.zipPostalCode'
  );

  const addressDetails = useMemo(
    () => ({
      ...contactInfo,
      country: countries.find(c => c.id === contactInfo.countryUri)
    }),
    [contactInfo, countries]
  );

  const billingAddressDetails = useMemo(
    () => ({
      ...billingContactInfo,
      country: countries.find(c => c.id === billingContactInfo.countryUri)
    }),
    [billingContactInfo, countries]
  );

  const mappedErrors =
    !addressError &&
    !cityError &&
    !stateProvinceError &&
    !zipPostalCodeError &&
    !billingAddressError &&
    !billingCityError &&
    !billingStateProvinceError;

  const clientBillingAddressLabelControl = useMemo(
    () => (
      <Checkbox
        className={classes.checkbox}
        color="primary"
        checked={billingSameAsAddress}
        onClick={handleBillingSameAsAddressClick}
      />
    ),
    [billingSameAsAddress, classes.checkbox, handleBillingSameAsAddressClick]
  );

  return (
    <>
      {mappedErrors && !isEmpty(errors) && <FormErrorAlert />}
      <Grid container>
        <GridItem rightPadding>
          <TextField
            name="contactInfo.address"
            variant="filled"
            label={addressLabel}
            value={address}
            fullWidth
            onChange={handleAddressChange}
            autoFocus
            error={Boolean(addressError)}
            helperText={addressError}
            inputProps={getInputProps({
              ariaLabelKey: 'client.address',
              formatMessage,
              isPsaPrpAccessibilityIssuesEnabled
            })}
          />
        </GridItem>
        <GridItem>
          <TextField
            name="contactInfo.city"
            variant="filled"
            label={cityLabel}
            value={city}
            fullWidth
            onChange={handleAddressChange}
            error={Boolean(cityError)}
            helperText={cityError}
            inputProps={getInputProps({
              ariaLabelKey: 'client.city',
              formatMessage,
              isPsaPrpAccessibilityIssuesEnabled
            })}
          />
        </GridItem>
        <GridItem rightPadding>
          <TextField
            name="contactInfo.stateProvince"
            variant="filled"
            label={stateProvinceLabel}
            value={stateProvince}
            fullWidth
            onChange={handleAddressChange}
            error={Boolean(stateProvinceError)}
            helperText={stateProvinceError}
            inputProps={getInputProps({
              ariaLabelKey: 'client.stateProvince',
              formatMessage,
              isPsaPrpAccessibilityIssuesEnabled
            })}
          />
        </GridItem>
        <GridItem>
          <TextField
            name="contactInfo.zipPostalCode"
            variant="filled"
            label={zipPostalCodeLabel}
            value={zipPostalCode}
            fullWidth
            onChange={handleAddressChange}
            error={Boolean(zipPostalCodeError)}
            helperText={zipPostalCodeError}
            inputProps={getInputProps({
              ariaLabelKey: 'client.zipPostalCode',
              formatMessage,
              isPsaPrpAccessibilityIssuesEnabled
            })}
          />
        </GridItem>
        <GridItem fullWidth>
          <CountrySelect
            name="contactInfo.countryUri"
            value={countryUri}
            onChange={handleAddressChange}
            countries={countries}
            inputProps={getInputProps({
              ariaLabelKey: 'client.country',
              formatMessage,
              isPsaPrpAccessibilityIssuesEnabled
            })}
          />
        </GridItem>
      </Grid>
      {canViewBillingAddress && (
        <>
          <FormControl component="fieldset">
            <FormLabel
              className={classes.billingAddressHeader}
              component="legend"
            >
              {billingAddressLabel}
              {canEditBillingAddress && (
                <FormControlLabel
                  control={clientBillingAddressLabelControl}
                  label={sameAsAddressLabel}
                  labelPlacement="end"
                />
              )}
            </FormLabel>
          </FormControl>
          {!canEditBillingAddress || billingSameAsAddress ? (
            <ClientInfoContactAddress
              addressDetails={
                !canEditBillingAddress ? billingAddressDetails : addressDetails
              }
            />
          ) : (
            <Grid container>
              <GridItem rightPadding>
                <TextField
                  name="billingContactInfo.address"
                  variant="filled"
                  label={addressLabel}
                  value={billingAddress}
                  fullWidth
                  onChange={handleChange}
                  error={Boolean(billingAddressError)}
                  helperText={billingAddressError}
                  inputProps={getInputProps({
                    ariaLabelKey: 'client.address',
                    formatMessage,
                    isPsaPrpAccessibilityIssuesEnabled
                  })}
                />
              </GridItem>
              <GridItem>
                <TextField
                  name="billingContactInfo.city"
                  variant="filled"
                  label={cityLabel}
                  value={billingCity}
                  fullWidth
                  onChange={handleChange}
                  error={Boolean(billingCityError)}
                  helperText={billingCityError}
                  inputProps={getInputProps({
                    ariaLabelKey: 'client.city',
                    formatMessage,
                    isPsaPrpAccessibilityIssuesEnabled
                  })}
                />
              </GridItem>
              <GridItem rightPadding>
                <TextField
                  name="billingContactInfo.stateProvince"
                  variant="filled"
                  label={stateProvinceLabel}
                  value={billingStateProvince}
                  fullWidth
                  onChange={handleChange}
                  error={Boolean(billingStateProvinceError)}
                  helperText={billingStateProvinceError}
                  inputProps={getInputProps({
                    ariaLabelKey: 'client.stateProvince',
                    formatMessage,
                    isPsaPrpAccessibilityIssuesEnabled
                  })}
                />
              </GridItem>
              <GridItem>
                <TextField
                  name="billingContactInfo.zipPostalCode"
                  variant="filled"
                  label={zipPostalCodeLabel}
                  value={billingZipPostalCode}
                  fullWidth
                  onChange={handleChange}
                  error={Boolean(billingZipPostalCodeError)}
                  helperText={billingZipPostalCodeError}
                  inputProps={getInputProps({
                    ariaLabelKey: 'client.zipPostalCode',
                    formatMessage,
                    isPsaPrpAccessibilityIssuesEnabled
                  })}
                />
              </GridItem>
              <GridItem fullWidth>
                <CountrySelect
                  name="billingContactInfo.countryUri"
                  countries={countries}
                  value={billingCountryUri}
                  onChange={handleChange}
                  inputProps={getInputProps({
                    ariaLabelKey: 'client.country',
                    formatMessage,
                    isPsaPrpAccessibilityIssuesEnabled
                  })}
                />
              </GridItem>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

EditClientAddressDialogForm.propTypes = {
  formik: PropTypes.object,
  countries: PropTypes.array,
  billingSameAsAddress: PropTypes.bool
};

export default connect(EditClientAddressDialogForm);
