import { GET_PAGE_OF_PROJECT_REQUESTS } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks/usePageOfProjectRequests';
import { useSessionStorage } from '~/modules/common/hooks';
import { GET_PAGE_OF_PROJECT_REQUESTS_QUERY_VARIABLES_KEY } from '~/modules/project-request/ProjectRequestPage/hooks/constants';

export const useFetchPageOfProjectRequests = ({
  projectRequestId,
  apolloClient
}) => {
  const { storedValue: queryVariables } = useSessionStorage(
    GET_PAGE_OF_PROJECT_REQUESTS_QUERY_VARIABLES_KEY,
    null
  );

  return {
    fetchPageOfProjectRequests: () =>
      apolloClient.query({
        query: GET_PAGE_OF_PROJECT_REQUESTS,
        variables: {
          ...queryVariables,
          page: 1,
          pageSize: 1,
          filter: {
            projectRequests: [projectRequestId],
            portfolios: queryVariables.filter?.portfolios
          },
          sort: null
        },
        fetchPolicy: 'network-only'
      })
  };
};

export default useFetchPageOfProjectRequests;
