import React from 'react';
import { useRouteMatch, useLocation, matchPath } from 'react-router-dom';
import { makeStyles, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { ColumnSettingsDialog } from '~/modules/common/components/ListTable';
import { TabBar, TabContents } from '~/modules/common/components';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import {
  useIsDefaultEdit,
  useDialogState,
  useIsBreakpointDown,
  useHasFeatureFlag,
  useResetHorizontalScrollPositionEffect
} from '~/modules/common/hooks';
import { usePortfolioPermissions } from '~/modules/common/hooks/portfolio';
import { portfolioProjectsTabPath } from '~/modules/portfolios/portfolio/components/ProjectList/route';
import PortfolioProjectRequestsContextProvider from '~/modules/portfolios/portfolio/PortfolioProjectRequestsContextProvider';
import { PortfolioHeader } from './components';
import PortfolioProjectsContextProvider from './components/ProjectList/PortfolioProjectsContextProvider';
import {
  usePortfolio,
  useTabRoutes,
  usePortfolioProjectListTableSettings
} from './hooks';
import { PortfolioPageEditContextProvider } from './PortfolioPageEditContext';

const useStyles = makeStyles(theme => ({
  projectSettingsButton: {
    marginLeft: 'auto'
  }
}));

export const SettingsButton = props => (
  <IconButton {...props}>
    <SettingsIcon />
  </IconButton>
);

export const PortfolioPage = () => {
  const classes = useStyles();
  const isDefaultEdit = useIsDefaultEdit();
  const { canEditPortfolio } = usePortfolioPermissions();
  const {
    params: { slug }
  } = useRouteMatch();
  const { loading, error, portfolio } = usePortfolio({ slug });
  const edit = isDefaultEdit && canEditPortfolio;
  const portfolioTabRoutes = useTabRoutes(portfolio);
  const { pathname } = useLocation();

  const isPortfolioProjectsTabSelected = matchPath(
    pathname,
    portfolioProjectsTabPath
  );

  const { open, closeDialog, openDialog } = useDialogState();
  const isMobile = useIsBreakpointDown('sm');
  const isPsaPpmPortfolioListActualsColumnsEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPpmPortfolioListActualsColumnsEnabled'
  });

  const {
    tableKey,
    enabledProjectColumnGroups,
    enabledProjectColumnNames,
    selectedColumnNames,
    tableSettingsDataLoading
  } = usePortfolioProjectListTableSettings();

  useResetHorizontalScrollPositionEffect({ trigger: pathname });

  return (
    <PortfolioPageEditContextProvider
      edit={edit}
      loading={loading}
      error={error}
      portfolio={portfolio}
    >
      <PortfolioHeader />
      <StickyHeader level={1} bottomBorder>
        <TabBar
          tabRoutes={portfolioTabRoutes}
          variant="scrollable"
          scrollButtons="off"
          noBottomBorder
        />
        {isPsaPpmPortfolioListActualsColumnsEnabled &&
          isPortfolioProjectsTabSelected &&
          !isMobile && (
            <span className={classes.projectSettingsButton}>
              <SettingsButton
                data-qe-id="ProjectSettingsButton"
                onClick={openDialog}
              />
            </span>
          )}
      </StickyHeader>
      {!loading && (
        <PortfolioProjectRequestsContextProvider>
          <PortfolioProjectsContextProvider>
            <TabContents tabRoutes={portfolioTabRoutes} slug={slug} />
          </PortfolioProjectsContextProvider>
        </PortfolioProjectRequestsContextProvider>
      )}
      {isPsaPpmPortfolioListActualsColumnsEnabled &&
        !tableSettingsDataLoading &&
        selectedColumnNames && (
          <ColumnSettingsDialog
            tableKey={tableKey}
            groups={enabledProjectColumnGroups}
            selectedColumns={selectedColumnNames}
            settingsColumnJustify="flex-start"
            defaultColumns={enabledProjectColumnNames}
            open={open}
            onClose={closeDialog}
            tagsObjectBinding="PROJECT"
          />
        )}
    </PortfolioPageEditContextProvider>
  );
};

export default PortfolioPage;
