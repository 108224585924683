import { gql } from 'graphql-tag';
import { notificationFragment } from './notificationFragment';

export const MARK_ALL_NOFICATIONS_AS_READ_MUTATION = gql`
  mutation MarkAllNotificationsAsRead($userUri: String!) {
    markAllNotificationsAsRead(userUri: $userUri) {
      notifications {
        ...Notification
      }
    }
  }
  ${notificationFragment}
`;
