import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation, matchPath } from 'react-router-dom';
import {
  CircularProgress,
  IconButton,
  Menu,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircleSharp';
import HelpIcon from '@material-ui/icons/HelpSharp';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { useHasPermission } from '~/modules/common/permissions';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { CommunityIcon } from '~/modules/common/components/Icons';
import { TenantType } from '~/types';
import MyProfileMenuItem from '../MyProfileMenuItem';
import LogoutMenuItem from '../LogoutMenuItem';
import PolicyLinks from '../PolicyLinks';
import ActionLink from '../ActionLink';
import SettingsActionLink from '../SettingsActionLink';
import SubstituteUserMenuItem from '../SubstituteUserMenuItem';
import ZeroTimeLink from '../ZeroTimeLink';
import ProfileMenuList from './ProfileMenuList';
import useInFlightRequest from './useInFlightRequest';

const ButtonWithoutMenu = ({ classes }) => (
  <IconButton className={classes.accountButton} disabled>
    <AccountCircle />
  </IconButton>
);

ButtonWithoutMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

const origin = { vertical: 'top', horizontal: 'right' };

const useStyles = makeStyles(theme => ({
  listItem: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.dark
    },
    '&.Mui-selected,.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark
    }
  },
  menuText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    minWidth: theme.spacing(6)
  },
  avatarProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(2),
    zIndex: 1
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

const isSelected = location =>
  !!matchPath(location.pathname, '/profile') ||
  !!matchPath(location.pathname, '/settings');

export const ProfileMenu = ({
  me,
  openSubstituteUsersDialog,
  drawerHeight,
  menuContainerHeight
}) => {
  const intl = useIntl();
  const location = useLocation();
  const hasProfileAccess = me.userAccessRoles.includes(
    USER_ACCESS_ROLE.PROJECT_RESOURCE
  );
  const { isMobileApp = false } = window;
  const isLoading = useInFlightRequest();
  const classes = useStyles();
  const selected = isSelected(location);
  const isMobile = useIsBreakpointDown('sm');
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const hasSettingsAccess = useHasPermission({
    actionUri: 'urn:replicon:user-action:edit-preferences'
  });

  const {
    tenantType,
    permissions,
    zeroTimeUrl,
    substituteUserMetadata: isSubstituteUser
  } = me;

  const canLoginAsZeroTimeUser = Boolean(
    permissions.find(
      p =>
        p.permissionActionUri ===
        'urn:replicon:time-intelligence-user-action:allow-login'
    )
  );

  const toggleOpen = useCallback(
    event => {
      setIsOpen(!isOpen);
      setAnchorEl(event && event.target);
    },
    [isOpen, setIsOpen, setAnchorEl]
  );

  const { customerZoneUrl, helpUrl } = me;

  const listItemClasses = useMemo(
    () => ({
      root: classes.listItem
    }),
    [classes.listItem]
  );

  const listItemTextClasses = useMemo(
    () => ({
      primary: classes.menuText
    }),
    [classes.menuText]
  );

  const showZeroTimeLink =
    canLoginAsZeroTimeUser &&
    (!isSubstituteUser || tenantType === TenantType.Trial);

  return isMobile ? (
    <ProfileMenuList
      me={me}
      intl={intl}
      location={location}
      zeroTimeUrl={zeroTimeUrl}
      hasProfileAccess={hasProfileAccess}
      isMobileApp={isMobileApp}
      hasSettingsAccess={hasSettingsAccess}
      drawerHeight={drawerHeight}
      menuContainerHeight={menuContainerHeight}
      openSubstituteUsersDialog={openSubstituteUsersDialog}
      showZeroTimeLink={showZeroTimeLink}
    />
  ) : (
    <>
      <ListItem
        id="profile-menu-icon"
        classes={listItemClasses}
        button
        selected={selected}
        aria-owns={isOpen ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-label={intl.formatMessage({ id: 'profileMenu.title' })}
        onClick={toggleOpen}
        dense={false}
      >
        <ListItemIcon className={selected ? classes.selected : classes.icon}>
          <UserAvatar user={me} size="small" />
        </ListItemIcon>
        <Tooltip title={me.displayText}>
          <ListItemText
            primary={me.displayText}
            classes={listItemTextClasses}
          />
        </Tooltip>
        {isLoading && (
          <CircularProgress
            data-qe-id="inflight-request-spinner"
            size={32}
            thickness={2}
            className={classes.avatarProgress}
          />
        )}
      </ListItem>
      <List>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          anchorOrigin={origin}
          transformOrigin={origin}
          open={isOpen}
          onClose={toggleOpen}
        >
          {hasProfileAccess && (
            <MyProfileMenuItem onClose={toggleOpen} me={me} intl={intl} />
          )}
          {hasSettingsAccess && (
            <SettingsActionLink onClose={toggleOpen} me={me} intl={intl} />
          )}
          {showZeroTimeLink ? (
            <li>
              <Divider className={classes.divider} light />
              <ZeroTimeLink onClose={toggleOpen} zeroTimeUrl={zeroTimeUrl} />
              {helpUrl || customerZoneUrl ? (
                <Divider className={classes.divider} light />
              ) : null}
            </li>
          ) : null}
          <ActionLink
            link={customerZoneUrl}
            label={intl.formatMessage({ id: 'customerZone.title' })}
            Icon={CommunityIcon}
          />
          <ActionLink
            link={helpUrl}
            label={intl.formatMessage({ id: 'helpZone.title' })}
            Icon={HelpIcon}
          />
          <Divider className={classes.divider} light />
          <SubstituteUserMenuItem openDialog={openSubstituteUsersDialog} />
          {isMobileApp ? null : <LogoutMenuItem me={me} />}
          <PolicyLinks />
        </Menu>
      </List>
    </>
  );
};

ProfileMenu.propTypes = {
  me: PropTypes.object.isRequired,
  openSubstituteUsersDialog: PropTypes.func,
  drawerHeight: PropTypes.number,
  menuContainerHeight: PropTypes.number
};

export default ProfileMenu;
