import React from 'react';
import { PropTypes } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

import VirtualDrawer from './VirtualDrawer';

export const asArray = item => (Array.isArray(item) ? item : [item]);

const noop = () => ({});

export const DrawerRouter = ({ history, children }) =>
  asArray(
    children
  ).map(
    ({
      props: {
        routeKey,
        path,
        component,
        drawerWidth,
        disableBackdropClick,
        disableEscapeKeyDown,
        useMuiDrawerOverriddenProps = noop
      }
    }) => (
      <VirtualDrawer
        key={path}
        routeKey={routeKey}
        path={path}
        history={history}
        component={component}
        drawerWidth={drawerWidth}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        useMuiDrawerOverriddenProps={useMuiDrawerOverriddenProps}
      />
    )
  );

DrawerRouter.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  children: PropTypes.node
};

export default withRouter(DrawerRouter);
