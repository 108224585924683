import React from 'react';
import PropTypes from 'prop-types';
import { ResourceRequestStatus } from '~/types';
import ResourceRequestChartBanner from './ResourceRequestChartBanner';
import ResourceRequestAcceptAllButton from './ResourceRequestAcceptAllButton';

export const ResourceRequestAcceptAllChartBanner = ({
  projectUri,
  resourceRequests
}) => {
  const submittedResourceRequestUrisWithMatches = resourceRequests
    .filter(
      resourceRequest =>
        resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
        resourceRequest.resourceMatches?.length
    )
    .map(resourceRequest => resourceRequest.id);

  return submittedResourceRequestUrisWithMatches.length ? (
    <ResourceRequestChartBanner messageId="resourceRequestChartBanner.delaIdentifyTeam">
      <ResourceRequestAcceptAllButton
        projectUri={projectUri}
        resourceRequestUris={submittedResourceRequestUrisWithMatches}
      />
    </ResourceRequestChartBanner>
  ) : (
    <></>
  );
};

ResourceRequestAcceptAllChartBanner.propTypes = {
  projectUri: PropTypes.string.isRequired,
  resourceRequests: PropTypes.array.isRequired
};
export default ResourceRequestAcceptAllChartBanner;
