import { Typography } from '@material-ui/core';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { useClientPermissions } from '../../hooks';
import ClientInfoClientManager from './ClientInfoClientManager';
import ClientInfoComanagers from './ClientInfoComanagers';
import ClientInfoDetail from './ClientInfoDetail';

const nameLabel = <FormattedMessage id="client.name" />;
const codeLabel = <FormattedMessage id="client.code" />;
const descriptionLabel = <FormattedMessage id="client.description" />;

export const ClientInfoBasicSection = ({
  details,
  isClientEditable: editable
}) => {
  const { hasClientManagerRole } = useMeContext();
  const { canViewComanagers } = useClientPermissions();

  const name = get(details, 'name');
  const code = get(details, 'code');
  const comment = get(details, 'comment');
  const clientManager = get(details, 'clientManager');
  const comanagers = get(details, 'comanagers', []);

  const commentDetail = comment && (
    <Typography variant="caption">{comment}</Typography>
  );

  return (
    <>
      {editable && <ClientInfoDetail label={nameLabel} detail={name} />}
      {(editable || code) && (
        <ClientInfoDetail label={codeLabel} detail={code} />
      )}
      {Boolean(hasClientManagerRole) && (
        <ClientInfoClientManager clientManager={clientManager} />
      )}
      {Boolean(hasClientManagerRole) && canViewComanagers && (
        <ClientInfoComanagers comanagers={comanagers} />
      )}
      {(editable || commentDetail) && (
        <ClientInfoDetail label={descriptionLabel} detail={commentDetail} />
      )}
    </>
  );
};

ClientInfoBasicSection.propTypes = {
  details: PropTypes.object,
  isClientEditable: PropTypes.bool
};

export default ClientInfoBasicSection;
