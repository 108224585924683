import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { gql } from 'graphql-tag';

export const ATTACHMENT_INFO_QUERY = gql`
  query attachmentInfo($binaryObjectUri: String!) {
    getAttachmentInfo(binaryObjectUri: $binaryObjectUri) {
      id
      processed
      notifyUser
    }
  }
`;

const useGetAttachmentInfo = ({ binaryObjectUri }) => {
  const { data } = useQuery(ATTACHMENT_INFO_QUERY, {
    variables: { binaryObjectUri }
  });

  const attachmentInfo = get(data, 'getAttachmentInfo') || {};

  return {
    processed: attachmentInfo.processed,
    notifyUser: attachmentInfo.notifyUser
  };
};

export default useGetAttachmentInfo;
