/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { PropTypes } from 'prop-types';
import Drawer from '~/modules/common/components/Drawer';
import { useSkillLevels, useHasFeatureFlag } from '~/modules/common/hooks';
import ErrorDrawer from '~/modules/common/components/TaskDrawer/ErrorDrawer';
import LoadingDrawer from '~/modules/common/components/TaskDrawer/LoadingDrawer';
import { useResourceRequestDetails } from '~/modules/resourcing/common/hooks';
import ResourceRequestDrawerContent from './ResourceRequestDrawerContent/ResourceRequestDrawerContent';

export const ResourceRequestDrawer = ({ id, onClose }) => {
  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const {
    loading,
    projectRequestDetails,
    error: errorData
  } = useResourceRequestDetails(id, isPsaRmpTaskAllocation1Enabled);

  const { maxSkillLevel, isSkillLoading } = useSkillLevels();

  if (isSkillLoading || loading) {
    return <LoadingDrawer onClose={onClose} />;
  }

  if (errorData && errorData.error) {
    return <ErrorDrawer data={errorData} onClose={onClose} />;
  }

  return (
    <Drawer>
      <ResourceRequestDrawerContent
        {...projectRequestDetails}
        maxSkillLevel={maxSkillLevel}
        onClose={onClose}
      />
    </Drawer>
  );
};

ResourceRequestDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ResourceRequestDrawer;
