import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const UPDATE_SCORE_OVERRIDE_TOTAL = gql`
  mutation updateScoreOverrideTotal($input: UpdateScoreOverrideTotalInput!) {
    updateScoreOverrideTotal(input: $input) {
      projectRequestUri
    }
  }
`;

export const useUpdateScoreOverrideTotal = ({ id }) => {
  const [updateScoreOverrideTotal] = useMutation(UPDATE_SCORE_OVERRIDE_TOTAL);

  return {
    updateScoreOverrideTotal: async ({ overrideTotal }) => {
      await updateScoreOverrideTotal({
        variables: {
          input: {
            id,
            overrideTotal
          }
        },
        skip: !id
      });
    }
  };
};

export default useUpdateScoreOverrideTotal;
