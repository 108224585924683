import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import {
  DISPLAY_UNIT_ENUM,
  getAllocationStatusBasedClassName,
  ALLOCATION_PERIOD_OVERLAP_TYPE
} from '~/modules/resourcing/common/enums';
import { deepPure } from '~/util';

import ResourceRequestUserAllocationPeriodOverlay, {
  ResourceRequestUserAllocationPeriodOverlayReadOnly
} from './ResourceRequestUserAllocationPeriodOverlay';
import ResourceAllocationHoursSummaryBlock from './ResourceAllocationHoursSummaryBlock';
import ResourceAllocationPercentageSummaryBlock from './ResourceAllocationPercentageSummaryBlock';

export const useStyles = makeStyles(theme => ({
  timeItem: {
    userSelect: 'none',
    display: 'flex',
    height: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    '&:hover': {
      '& .dragIndicator': {
        opacity: 1
      }
    },
    position: 'relative'
  }
}));

const useTimelineStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(3),
    userSelect: 'none',
    backgroundColor: theme.palette.resourceRequest.pending.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.resourceRequest.pending.main, 0.1)
    },
    flexShrink: 0,
    borderRight: `1px solid ${theme.palette.grey[500]}`
  },
  containerEditable: {
    cursor: 'pointer'
  },
  containerCommitted: {
    backgroundColor: theme.palette.resourceRequest.complete.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.resourceRequest.complete.main, 0.1)
    }
  },
  containerProposed: {
    backgroundColor: theme.palette.resourceRequest.proposed.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.resourceRequest.proposed.main, 0.1)
    }
  },
  containerRejected: {
    backgroundColor: theme.palette.resourceRequest.resourceRejected.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(
        theme.palette.resourceRequest.resourceRejected.main,
        0.1
      )
    }
  },
  containerOverDistributed: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(theme.palette.error.main, 0.1)
    }
  },
  containerOverAllocated: {
    backgroundColor: theme.palette.allocationChartStatus.overAllocated.main,
    '&:hover': {
      boxShadow: theme.shadows[4],
      backgroundColor: darken(
        theme.palette.allocationChartStatus.overAllocated.main,
        0.1
      )
    }
  },
  noContainerBorder: {
    borderRight: `none`
  }
}));

const useOverlayStyles = makeStyles(theme => ({
  overlay: {
    cursor: 'pointer'
  }
}));

const useDragIndicatorStyles = makeStyles(theme => ({
  resizeHandle: {
    top: theme.spacing(0),
    position: 'absolute',
    zIndex: 3,
    cursor: 'ew-resize',
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  },
  resizeIcon: {
    top: theme.spacing(0.25)
  },
  resizeHandleStart: {
    left: theme.spacing(-0.5)
  },
  resizeHandleEnd: {
    left: `calc(100% - ${theme.spacing(2.5)}px)`
  }
}));

const useContainerStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 1
  },
  dragIndicators: {
    position: 'absolute',
    zIndex: 3
  }
}));

const getTimelineClassName = ({
  allocation,
  timelineClasses,
  hasAllocationEnd,
  allocationPeriodOverlapType,
  isReadOnly,
  isOverAllocated,
  isOverDistributed
}) =>
  classNames(
    timelineClasses.container,
    timelineClasses[
      getAllocationStatusBasedClassName(
        allocation.allocationStatus,
        'container'
      )
    ],
    {
      [timelineClasses.noContainerBorder]:
        hasAllocationEnd &&
        allocationPeriodOverlapType === ALLOCATION_PERIOD_OVERLAP_TYPE.PARTIAL,
      [timelineClasses.containerEditable]: !isReadOnly,
      [timelineClasses.containerOverAllocated]: isOverAllocated,
      [timelineClasses.containerOverDistributed]: isOverDistributed
    }
  );

export const GenericTimelineBlocks = ({
  allocation,
  handleAllocationPeriodClick,
  isEditable,
  resourceAvailabilitySummary,
  resourceRequest = {},
  scale,
  timeOffHolidaySummaryBlocksComponent,
  isReadOnly,
  displayUnit,
  overlayPeriods,
  allocationPeriods,
  dragIndicatorHandlers,
  hideCost
}) => {
  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection: resizeDirection,
    dates: dragIndicatorDates
  } = dragIndicatorHandlers;
  const classes = useStyles();
  const timelineClasses = useTimelineStyles();
  const dragIndicatorClasses = useDragIndicatorStyles();
  const containerClasses = useContainerStyles();
  const overlayClasses = useOverlayStyles();

  return (
    <div className={`${classes.timeItem} resourceRequestUserAllocation`}>
      {timeOffHolidaySummaryBlocksComponent}
      {isReadOnly
        ? displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE &&
          overlayPeriods
            .filter(
              period =>
                period.allocationPeriodOverlapType !==
                ALLOCATION_PERIOD_OVERLAP_TYPE.NONE
            )
            .map(overlayPeriod => (
              <ResourceRequestUserAllocationPeriodOverlayReadOnly
                key={overlayPeriod.key}
                overlayPeriod={overlayPeriod}
                allocation={allocation}
                resourceRequest={resourceRequest}
                requestPeriods={allocationPeriods}
              />
            ))
        : overlayPeriods.map(
            ({
              key,
              startDate,
              endDate,
              totalHours,
              totalScheduleHours,
              dynamicPosition,
              percentage,
              totalCost,
              allocationPeriodOverlapType
            }) => (
              <ResourceRequestUserAllocationPeriodOverlay
                key={key}
                startDate={startDate}
                endDate={endDate}
                totalHours={totalHours}
                requestPeriods={overlayPeriods}
                allocation={allocation}
                scale={scale}
                isEditable={isEditable}
                totalScheduleHours={totalScheduleHours}
                dynamicPosition={dynamicPosition}
                handleAllocationPeriodClick={handleAllocationPeriodClick}
                percentage={percentage}
                totalCost={totalCost}
                resourceRequest={resourceRequest}
                allocationPeriodOverlapType={allocationPeriodOverlapType}
                classes={overlayClasses}
                hideCost={hideCost}
              />
            )
          )}
      {(allocationPeriods || []).map(allocationPeriod => {
        const {
          key,
          hasAllocationEnd,
          allocationPeriodOverlapType,
          isOverAllocated,
          isOverDistributed
        } = allocationPeriod;
        const timelineClassName = getTimelineClassName({
          allocation,
          timelineClasses,
          hasAllocationEnd,
          allocationPeriodOverlapType,
          isReadOnly,
          isOverAllocated,
          isOverDistributed
        });

        return displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE ? (
          <ResourceAllocationPercentageSummaryBlock
            key={key}
            allocationPeriod={allocationPeriod}
            containerClasses={containerClasses}
            dragIndicatorDates={dragIndicatorDates}
            dragDelta={dragDelta}
            dragIndicatorClasses={dragIndicatorClasses}
            gestureBindEvents={gestureBindEvents}
            resizeDirection={resizeDirection}
            showResizeIndicator={isEditable}
            timelineClassName={timelineClassName}
          />
        ) : (
          <ResourceAllocationHoursSummaryBlock
            key={key}
            allocation={allocation}
            allocationPeriod={allocationPeriod}
            containerClasses={containerClasses}
            dragIndicatorDates={dragIndicatorDates}
            dragDelta={dragDelta}
            dragIndicatorClasses={dragIndicatorClasses}
            gestureBindEvents={gestureBindEvents}
            handleAllocationPeriodClick={handleAllocationPeriodClick}
            overlayPeriods={overlayPeriods}
            allocationPeriods={allocationPeriods}
            resizeDirection={resizeDirection}
            resourceAvailabilitySummary={resourceAvailabilitySummary}
            resourceRequest={resourceRequest}
            scale={scale}
            showResizeIndicator={isEditable}
            timelineClassName={timelineClassName}
            hideCost={hideCost}
          />
        );
      })}
    </div>
  );
};

GenericTimelineBlocks.propTypes = {
  scale: PropTypes.string.isRequired,
  allocation: PropTypes.object,
  handleAllocationPeriodClick: PropTypes.func,
  isEditable: PropTypes.bool,
  timeOffHolidaySummaryBlocksComponent: PropTypes.node,
  resourceRequest: PropTypes.object,
  resourceAvailabilitySummary: PropTypes.object,
  isReadOnly: PropTypes.bool,
  displayUnit: PropTypes.string,
  overlayPeriods: PropTypes.array,
  allocationPeriods: PropTypes.array,
  dragIndicatorHandlers: PropTypes.object,
  hideCost: PropTypes.bool
};

export default deepPure(GenericTimelineBlocks);
