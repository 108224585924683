import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { SortDirection } from '~/types';
import RevisionEntry from '~/modules/common/components/RevisionHistory/RevisionEntry';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { NoDataItem } from '~/modules/common/components';
import { useProjectRequestRevisionHistory } from './hooks';
import SortingButton from './SortingButton';
import PortfolioFieldFormatter from './PortfolioFieldFormatter';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm,
      minHeight: theme.breakpoints.values.sm
    }
  },
  projectRequestName: {
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    padding: theme.spacing(1, 3, 0, 3)
  },
  titleText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loadingDiv: {
    display: 'flex',
    justifyContent: 'center',
    height: '528px',
    alignItems: 'center'
  },
  closeIcon: {
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(-1.5)
  },
  sortText: {
    paddingRight: theme.spacing(1)
  },
  iconSize: {
    fontSize: '16px'
  },
  historyDialogButtons: {
    display: 'flex'
  }
}));

const getFieldMetadata = () => ({
  name: {
    previousValue: 'revisionValue.name.previous',
    currentValue: 'revisionValue.name.current',
    title: <FormattedMessage id="projectRequestHistory.fieldTitles.name" />
  },
  description: {
    previousValue: 'revisionValue.description.previous',
    currentValue: 'revisionValue.description.current',
    title: (
      <FormattedMessage id="projectRequestHistory.fieldTitles.description" />
    )
  },
  requestedBy: {
    previousValue: 'revisionValue.requestedBy.previous',
    currentValue: 'revisionValue.requestedBy.current',
    title: (
      <FormattedMessage id="projectRequestHistory.fieldTitles.requestedBy" />
    )
  },
  status: {
    previousValue: 'revisionValue.status.previous',
    currentValue: 'revisionValue.status.current',
    title: <FormattedMessage id="projectRequestHistory.fieldTitles.status" />
  },
  portfolio: {
    previousValue: 'revisionValue.portfolio.previous',
    currentValue: 'revisionValue.portfolio.current',
    title: (
      <FormattedMessage id="projectRequestHistory.fieldTitles.portfolio" />
    ),
    formatter: PortfolioFieldFormatter
  }
});

const revisionEntryResourceKeys = {
  added: 'projectRequestHistory.added',
  edited: 'projectRequestHistory.edited',
  substituteUserAdded: 'projectRequestHistory.substituteUserAdded',
  substituteUserEdited: 'projectRequestHistory.substituteUserEdited'
};

export const ProjectRequestHistoryDialog = ({
  open,
  onClose,
  projectRequestName,
  projectRequestId
}) => {
  const fullScreen = useIsBreakpointDown('sm');
  const classes = useStyles();
  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [
    classes.paper
  ]);
  const [sortOrder, setSortOrder] = useState(SortDirection.Desc);

  const { loading, revisionData, error } = useProjectRequestRevisionHistory({
    projectRequestUri: projectRequestId,
    sortOrder
  });

  const titleValues = useMemo(
    () => ({
      projectRequestName: { projectRequestName }
    }),
    [projectRequestName]
  );

  const onSortOrderChange = useCallback(() => {
    sortOrder === SortDirection.Desc
      ? setSortOrder(SortDirection.Asc)
      : setSortOrder(SortDirection.Desc);
  }, [setSortOrder, sortOrder]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      classes={dialogClasses}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        <div className={classes.titleText}>
          <Typography variant="h6" noWrap>
            <FormattedMessage
              id="projectRequest.requestHistoryTitle"
              values={titleValues.projectRequestName}
            />
          </Typography>

          <div className={classes.historyDialogButtons}>
            <SortingButton
              sortOrder={sortOrder}
              onSortOrderChange={onSortOrderChange}
            />

            <IconButton
              className={classes.closeIcon}
              color="inherit"
              onClick={onClose}
              data-qe-id="RequestHistoryDialog_Close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress size={24} />
          </div>
        ) : error || !(revisionData && revisionData.length) ? (
          <div className={classes.loadingDiv}>
            <NoDataItem fontStyle="normal">
              <FormattedMessage id="projectRequest.requestHistoryError" />
            </NoDataItem>
          </div>
        ) : (
          revisionData &&
          revisionData.map((entry, index) => {
            return (
              <RevisionEntry
                dataQeId={`RevisionEntry${index}`}
                key={uuid()}
                revisionReferenceKeys={revisionEntryResourceKeys}
                revisionData={entry}
                fieldMetadata={getFieldMetadata()}
              />
            );
          })
        )}
      </DialogContent>
    </Dialog>
  );
};
ProjectRequestHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectRequestName: PropTypes.string,
  projectRequestId: PropTypes.string
};

export default ProjectRequestHistoryDialog;
