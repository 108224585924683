import { gql } from 'graphql-tag';

export const INVOICE_DEFAULT_TEMPLATE_QUERY = gql`
  query getInvoiceDefaultTemplate {
    invoiceDefaultTemplate {
      id
      displayText
    }
  }
`;

export const useFetchInvoiceDefaultTemplate = ({
  apolloClient,
  isPsaPrpBillingDefaultForProjectEnabled
}) => {
  return {
    fetchInvoiceDefaultTemplate: async () => {
      const defaultInvoiceTemplate = await apolloClient.query({
        query: INVOICE_DEFAULT_TEMPLATE_QUERY,
        variables: {},
        fetchPolicy: 'network-only'
      });

      return isPsaPrpBillingDefaultForProjectEnabled
        ? defaultInvoiceTemplate?.data?.invoiceDefaultTemplate
        : defaultInvoiceTemplate?.data?.defaultInvoiceTemplate;
    }
  };
};
