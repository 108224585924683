import PropTypes from 'prop-types';
import React from 'react';
import List from '@material-ui/core/List';

import { Divider, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { withRoutes } from '~/modules/common/routes';
import { useMeContext } from '~/modules/me';
import { useIsBreakpointDown } from '~/modules/common/hooks';

import ExternalLinksMenuItem from '../ExternalLinksMenu/ExternalLinksMenuItem';
import MenuItem from '../MenuItem';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1)
  }
}));

export const FinancialsMenu = ({ routes }) => {
  const { uiRootPath } = useMeContext();
  const location = useLocation();
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();

  const {
    payroll: payrollRoute,
    costPriceOptimizer: cpoRoute,
    billing: billingInvoiceRoute
  } = routes;

  if (!payrollRoute && !cpoRoute && !billingInvoiceRoute) {
    return null;
  }

  return (
    <List disablePadding>
      {cpoRoute && !isMobile && (
        <MenuItem
          key={cpoRoute.path}
          path={cpoRoute.path}
          icon={<cpoRoute.icon />}
          title={<cpoRoute.title />}
          selected={location.pathname === cpoRoute.path}
          divider={cpoRoute.showDivider}
        />
      )}
      {billingInvoiceRoute && (
        <MenuItem
          key={billingInvoiceRoute.path}
          path={billingInvoiceRoute.path}
          icon={<billingInvoiceRoute.icon />}
          title={<billingInvoiceRoute.title />}
          selected={location.pathname === billingInvoiceRoute.path}
          divider={billingInvoiceRoute.showDivider}
          dataQeId={billingInvoiceRoute.dataQeId}
        />
      )}
      {payrollRoute && !isMobile && (
        <li>
          <ExternalLinksMenuItem
            url={`${uiRootPath}payroll`}
            icon={payrollRoute.icon}
            text={payrollRoute.title}
          />
        </li>
      )}
      <Divider role="" component="li" className={classes.divider} light />
    </List>
  );
};

FinancialsMenu.propTypes = {
  routes: PropTypes.object
};

export default withRoutes(FinancialsMenu);
