import { Drawer, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useProjectRevenueEntriesHistory } from './hooks';
import { DrawerContent } from './DrawerContent';

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    width: ({ isMobile }) => (isMobile ? '100%' : '45%')
  }
}));

const useStyles = makeStyles(theme => ({
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

export const ProjectHistoryDrawer = ({
  open,
  onClose,
  projectId,
  isMobile,
  ...rest
}) => {
  const drawerClasses = useDrawerStyles({ isMobile });
  const classes = useStyles();
  const {
    loading,
    hasMoreRows,
    loadMoreRows,
    project
  } = useProjectRevenueEntriesHistory({ projectId });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={drawerClasses}
      role="main"
    >
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      ) : (
        <DrawerContent
          hasMoreRows={hasMoreRows}
          loadMoreRows={loadMoreRows}
          projectDetails={project}
          onClose={onClose}
          {...rest}
        ></DrawerContent>
      )}
    </Drawer>
  );
};

ProjectHistoryDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool
};

export default ProjectHistoryDrawer;
