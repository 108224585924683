import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import MobileProjectListItemSkeleton from './MobileProjectList/MobileProjectListItemSkeleton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2)
  }
}));

const ProjectListRowSkeleton = () => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  return isMobile ? (
    <MobileProjectListItemSkeleton />
  ) : (
    <div className={classes.root}>
      <Skeleton variant="rect" height={32} />
    </div>
  );
};

export default ProjectListRowSkeleton;
