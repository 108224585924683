import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import checkedSpecificResourceFragment from '~/modules/resource-management/graphql/checkedSpecificResource.fragment';
import { useResourcePermissions } from './useResourcePermissions';

export const RESOURCE_DETAILS_QUERY = gql`
  query getResourceDetailsBySlug(
    $userSlug: String!
    $hasViewProjectRolePermission: Boolean!
    $hasViewCostCenterPermission: Boolean!
    $hasViewServiceCenterPermission: Boolean!
    $hasViewLocationPermission: Boolean!
    $hasViewDepartmentPermission: Boolean!
    $hasViewEmployeeTypePermission: Boolean!
    $hasViewDivisionPermission: Boolean!
    $hasViewCustomFieldsPermission: Boolean!
  ) {
    resource(userSlug: $userSlug) {
      ...CheckedSpecificResourceFragment
      certificates(includeExpired: true) {
        id
        uri
        name
        issueDate
        expiryDate
        issuer
        expires
        credentialId
        credentialUrl
      }
      tags {
        id
        tag {
          id
          name
        }
        value {
          id
          value
        }
      }
    }
  }
  ${checkedSpecificResourceFragment}
`;

const useResourceDetails = ({ userSlug }) => {
  const {
    hasViewProjectRolePermission,
    hasViewCostCenterPermission,
    hasViewServiceCenterPermission,
    hasViewLocationPermission,
    hasViewDepartmentPermission,
    hasViewEmployeeTypePermission,
    hasViewDivisionPermission,
    hasViewCustomFieldsPermission
  } = useResourcePermissions(userSlug);

  const { loading, error, data: { resource } = {} } = useQuery(
    RESOURCE_DETAILS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userSlug,
        hasViewProjectRolePermission,
        hasViewCostCenterPermission,
        hasViewServiceCenterPermission,
        hasViewLocationPermission,
        hasViewDepartmentPermission,
        hasViewEmployeeTypePermission,
        hasViewDivisionPermission,
        hasViewCustomFieldsPermission
      }
    }
  );

  return {
    loading,
    resourceDetails: resource,
    error
  };
};

export default useResourceDetails;
