import { IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import EditIcon from '@material-ui/icons/EditSharp';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { useMeContext } from '~/modules/me';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import ProjectRequestIcon from '../ProjectRequestIcon';
import { ProjectRequestStatusBadge } from '../ProjectRequestStatusBadge';
import { updateScoreInCache } from './hooks/updateScoreInCache';
import { useFetchPageOfProjectRequests } from './hooks/useFetchPageOfProjectRequests';
import { useFetchProjectRequestTotals } from './hooks/useFetchProjectRequestTotals';

const drawerHeaderStyles = makeStyles(theme => ({
  headerArea: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.black,
    padding: theme.spacing(1, 0, 1, 2),
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 11,
    borderBottom: `1px solid ${theme.palette.table.border}`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  heading: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingRight: theme.spacing(1)
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  grow: {
    flexGrow: 1,
    padding: theme.spacing(0.5)
  },
  headerTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...theme.typography.h6
  },
  root: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  icon: {
    color: theme.palette.text.secondary,
    width: 32,
    height: 32,
    marginRight: theme.spacing(1)
  }
}));

export const ProjectRequestDrawerHeader = ({
  onClose,
  editable,
  setEditable,
  gatesFormik
}) => {
  const apolloClient = useApolloClient();
  const { cache } = apolloClient;
  const {
    values: { gates }
  } = gatesFormik;
  const { featureFlags } = useMeContext();
  const { values } = useFormikContext();
  const { id, name, status, score } = values;
  const isNew = !id;
  const classes = drawerHeaderStyles();
  const { formatMessage } = useIntl();
  const { canEditProjectRequest } = useProjectRequestObjectPermissions();
  const { fetchPageOfProjectRequests } = useFetchPageOfProjectRequests({
    projectRequestId: id,
    apolloClient
  });
  const { fetchProjectRequestTotals } = useFetchProjectRequestTotals(
    apolloClient
  );
  const handleDrawerClose = useCallback(async () => {
    fetchPageOfProjectRequests();
    fetchProjectRequestTotals();

    onClose();
  }, [fetchPageOfProjectRequests, fetchProjectRequestTotals, onClose]);

  const handleEditMode = useCallback(() => {
    updateScoreInCache({ id, score, featureFlags })(cache);
    setEditable(true);
  }, [cache, featureFlags, id, score, setEditable]);

  return (
    <div className={classes.headerArea}>
      <div className={classes.heading}>
        <ProjectRequestIcon className={classes.icon} />
        <div className={classes.root}>
          <Typography variant="h1" className={classes.headerTitle}>
            {isNew ? <FormattedMessage id="projectRequest.addRequest" /> : name}
          </Typography>
        </div>
        <div className={classes.grow} />
        {!isNew ? (
          <ProjectRequestStatusBadge
            status={status}
            projectRequestGates={gates}
          />
        ) : null}
        {!editable && canEditProjectRequest && (
          <IconButton
            color="inherit"
            onClick={handleEditMode}
            date-qe-id="EditRequest"
            aria-label={formatMessage({
              id: 'projectRequest.edit'
            })}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          color="inherit"
          onClick={handleDrawerClose}
          date-qe-id="CloseRequest"
          aria-label={formatMessage({
            id: 'projectRequest.close'
          })}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

ProjectRequestDrawerHeader.propTypes = {
  onClose: PropTypes.func,
  editable: PropTypes.bool,
  setEditable: PropTypes.func,
  gatesFormik: PropTypes.object
};

export default ProjectRequestDrawerHeader;
