import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { darken, alpha } from '@material-ui/core/styles/colorManipulator';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 1
  },
  text: ({ paletteClass, theme }) => {
    return {
      color: paletteClass.dark,
      '&:hover': {
        backgroundColor: alpha(
          paletteClass.main,
          theme.palette.action.hoverOpacity
        ),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    };
  },
  contained: ({ paletteClass, theme }) => ({
    color: theme.palette.getContrastText(paletteClass.main),
    backgroundColor: paletteClass.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: darken(
        paletteClass.main,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: paletteClass.main
      }
    }
  })
}));

const RequestActionButton = ({
  label,
  paletteClass,
  variant,
  onClick,
  clickedButtonStatusType,
  statusType,
  isStatusUpdateLoading
}) => {
  const theme = useTheme();

  const classes = useStyles({
    paletteClass: paletteClass || theme.palette.error,
    theme
  });

  return (
    <LoadingButton
      variant={variant}
      onClick={onClick}
      isLoading={
        (statusType &&
          statusType === clickedButtonStatusType &&
          isStatusUpdateLoading.loading) ||
        false
      }
      classes={classes}
      autoFocus
    >
      {label}
    </LoadingButton>
  );
};

RequestActionButton.propTypes = {
  label: PropTypes.string,
  paletteClass: PropTypes.object,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  clickedButtonStatusType: PropTypes.string,
  statusType: PropTypes.string,
  isStatusUpdateLoading: PropTypes.object
};

export default RequestActionButton;
