/* eslint-disable react/jsx-max-depth */
import React, { memo, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import BasicInformationReadOnly from '~/modules/resource-management/resourceUserDetails/BasicInformation/BasicInformationReadOnly';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';

const useStyles = makeStyles(theme => ({
  avatar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center'
    }
  },
  heading: {
    width: '100%'
  },
  root: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  infoIconGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 1.5, 5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(0)
    }
  },
  info: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  basicInfoRoot: {
    flexDirection: 'row'
  },
  mobileBasicInfoRoot: {
    flexDirection: 'column',
    margin: theme.spacing(1, 0, 1, 2.5)
  },
  basicInfoGroup: {
    padding: theme.spacing(0, 1, 0, 0)
  },
  resourceTitle: {},
  userName: {
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap'
  },
  expandGroup: {
    paddingLeft: theme.spacing(0.5)
  },
  expandButton: {
    flexGrow: 0,
    flexShrink: 0,
    margin: 0,
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  email: {
    display: 'inline-flex',
    padding: theme.spacing(1.5, 0, 0, 1)
  },
  expandButtonOpen: {
    transform: 'rotate(180deg)'
  },
  mobileInfo: {}
}));

export const ResourceDrawerHeader = memo(
  ({
    resource,
    permittedActions,
    classes: classesOverride,
    toggleShowMore,
    showMore
  }) => {
    const intl = useIntl();
    const isMobile = useIsBreakpointDown('xs');

    const classes = useStyles({ classes: classesOverride });

    return (
      <div className={classes.root}>
        {isMobile ? (
          <div className={classes.avatar}>
            <UserAvatar user={resource} />
            <Typography variant="h6" className={classes.userName}>
              {resource.displayText}
            </Typography>
          </div>
        ) : (
          <>
            <UserAvatar user={resource} />
            <div className={classes.avatar}>
              <Typography variant="h6" className={classes.userName}>
                {resource.displayText}
              </Typography>
            </div>
          </>
        )}
        <div className={classes.resourceTitle}>
          <div className={classes.infoIconGroup}>
            <div className={classes.info}>
              <BasicInformationReadOnly
                userDetails={resource}
                showEmail={!!resource.email}
                permittedActions={permittedActions}
                classes={useMemo(
                  () => ({
                    root: isMobile
                      ? classes.mobileBasicInfoRoot
                      : classes.basicInfoRoot,
                    group: classes.basicInfoGroup
                  }),
                  [
                    isMobile,
                    classes.basicInfoGroup,
                    classes.basicInfoRoot,
                    classes.mobileBasicInfoRoot
                  ]
                )}
              />
            </div>
            <div className={classes.expandGroup}>
              <IconButton
                className={classNames(classes.expandButton, {
                  [classes.expandButtonOpen]: showMore
                })}
                onClick={toggleShowMore}
                aria-label={
                  !showMore
                    ? intl.formatMessage({
                        id: 'button.showMore'
                      })
                    : intl.formatMessage({
                        id: 'button.showLess'
                      })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
              {!showMore && (
                <FormattedMessage id="resourceAssignmentDialog.showMore" />
              )}
              {showMore && (
                <FormattedMessage id="resourceAssignmentDialog.showLess" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ResourceDrawerHeader.propTypes = {
  resource: PropTypes.object.isRequired,
  toggleShowMore: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  permittedActions: PropTypes.array.isRequired
};

export default ResourceDrawerHeader;
