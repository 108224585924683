import { useState, useMemo, useCallback } from 'react';
import { useAssignProjectsToPortfolio } from './useAssignProjectsToPortfolio';

export const useAddExistingProjectsFormProps = ({
  portfolioId,
  openDialog,
  onCancel
}) => {
  const [selectedProjects, setSelectedProjects] = useState({});

  const selectedCount = useMemo(
    () =>
      Object.values(selectedProjects).reduce((count, { selected }) => {
        if (selected) return count + 1;

        return count;
      }, 0),
    [selectedProjects]
  );

  const selectedCountValue = useMemo(
    () => ({
      value: selectedCount === 0 ? '' : selectedCount
    }),
    [selectedCount]
  );

  const selectedProjectIds = Object.entries(selectedProjects)
    .filter(([_, { selected }]) => selected)
    .map(([id]) => id);

  const [error, setError] = useState(null);

  const {
    submitting,
    assignProjectsToPortfolio
  } = useAssignProjectsToPortfolio({
    portfolioId,
    projectIds: selectedProjectIds,
    onComplete: onCancel,
    onError: setError
  });

  const selectedProjectsWithPortfolios = useMemo(
    () =>
      selectedProjectIds
        .filter(
          id =>
            selectedProjects[id].selected && selectedProjects[id].hasPortfolio
        )
        .map(id => selectedProjects[id]),
    [selectedProjectIds, selectedProjects]
  );

  const onRowClick = useCallback(
    ({ id, name, portfolio }) => {
      if (submitting) return;

      setSelectedProjects({
        ...selectedProjects,
        [id]: {
          selected: selectedProjects[id]
            ? !selectedProjects[id].selected
            : true,
          name,
          portfolio,
          hasPortfolio: Boolean(portfolio)
        }
      });
    },
    [selectedProjects, setSelectedProjects, submitting]
  );

  const handleSubmit = useCallback(() => {
    if (selectedProjectsWithPortfolios.length > 0) openDialog();
    else assignProjectsToPortfolio();
  }, [selectedProjectsWithPortfolios, openDialog, assignProjectsToPortfolio]);

  return {
    selectedProjects,
    selectedCount,
    onRowClick,
    submitting,
    handleSubmit,
    error,
    selectedCountValue,
    assignProjectsToPortfolio,
    selectedProjectsWithPortfolios
  };
};
