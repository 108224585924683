import get from 'lodash.get';

const mapNullValuesToEmptyStrings = info =>
  Object.keys(info).reduce(
    (mappedInfo, key) => ({
      ...mappedInfo,
      [key]: info[key] || ''
    }),
    {}
  );

export const mapContactInfoInitialValues = contactInfo => {
  const { country, __typename, ...restContactInfo } = contactInfo;

  return {
    ...mapNullValuesToEmptyStrings(restContactInfo),
    countryUri: get(country, 'id')
  };
};
