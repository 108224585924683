import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import Decimal from '~/modules/common/components/Decimal';
import DateRange from '~/modules/common/components/DateRange';
import { TASK_STATUS } from '~/modules/common/enums';
import TaskStatusBadge from '../TaskStatusBadge';
import useStyles from '../useStyles';
import { CompletedRow, InProgressRow } from '../Table';

export const DesktopTaskStatusSection = ({ task, facts }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={classNames(classes.table, classes.wrapper)}
      role="table"
      aria-label={intl.formatMessage({ id: 'taskStatus.taskStatusDetails' })}
    >
      <div className={classNames(classes.row, classes.header)} role="row">
        <div
          className={classNames(classes.cell, classes.label, classes.status)}
          role="cell"
        >
          <TaskStatusBadge
            status={task.taskStatus}
            progressStatus={task.status}
          />
        </div>
        <div
          data-qe-id="Status_TotalPlannedHours"
          className={classNames(classes.cell, classes.hours)}
          role="columnheader"
        >
          <FormattedMessage id="taskStatus.hours" />
        </div>
        <div className={classNames(classes.cell, classes.hoursEstimate)}></div>
        <div
          data-qe-id="Status_PlannedDate"
          className={classNames(classes.cell, classes.dates)}
          role="columnheader"
        >
          <FormattedMessage id="taskStatus.dates" />
        </div>
      </div>
      <div className={classNames(classes.row, classes.plan)} role="row">
        <div
          className={classNames(classes.cell, classes.label, classes.rowHeader)}
          role="rowheader"
        >
          <FormattedMessage id="taskStatus.planned" />
        </div>
        <div className={classNames(classes.cell, classes.hours)} role="cell">
          <Decimal className={classes.fact} value={facts.planned.hours} />
        </div>
        <div className={classNames(classes.cell, classes.hoursEstimate)}></div>
        <div className={classNames(classes.cell, classes.dates)} role="cell">
          <DateRange
            classes={{ date: classes.fact }}
            start={facts.planned.date.start}
            end={facts.planned.date.end}
          />
        </div>
      </div>
      {!task.isClosed && task.taskStatus === TASK_STATUS.INPROGRESS && (
        <InProgressRow intl={intl} classes={classes} facts={facts} />
      )}
      {task.isClosed && <CompletedRow classes={classes} facts={facts} />}
    </div>
  );
};

DesktopTaskStatusSection.propTypes = {
  task: PropTypes.object.isRequired,
  facts: PropTypes.object.isRequired
};

export default DesktopTaskStatusSection;
