import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const vendorCanBeDeletedQuery = gql`
  query GetVendorCanBeDeleted($id: String!) {
    vendor(id: $id) {
      id
      canBeDeleted
    }
  }
`;

const useCanVendorBeDeleted = ({ id }) => {
  const { loading, error, data } = useQuery(vendorCanBeDeletedQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    canBeDeleted: data && data.vendor ? data.vendor.canBeDeleted : true,
    error
  };
};

export default useCanVendorBeDeleted;
