import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LinearProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import { BATCH_QUERY } from '~/modules/common/components/BillingBatchStatus/BillingBatchStatus';
import { BatchState } from '~/types';

const useStyles = makeStyles(theme => ({
  progressRoot: {
    display: 'flex',
    width: '100%'
  },
  progressValue: {
    marginLeft: 'auto',
    fontWeight: theme.typography.fontWeightMedium
  },
  infoMessage: {
    fontWeight: theme.typography.fontWeightMedium
  },
  undoInfoMessageRoot: {
    paddingTop: theme.spacing(2)
  }
}));

export const ProgressContent = ({
  batchId,
  setBillingBatchState,
  onBatchComplete
}) => {
  const classes = useStyles();

  const { stopPolling, data: { getBillingBatchStatus = {} } = {} } = useQuery(
    BATCH_QUERY,
    {
      variables: {
        batchId
      },
      fetchPolicy: 'network-only',
      pollInterval: 500
    }
  );

  const { executionState, overallProgress } = getBillingBatchStatus;

  const hasProcessed =
    executionState === BatchState.Succeeded ||
    executionState === BatchState.Cancelled ||
    executionState === BatchState.Failed;

  const progressValue = useMemo(
    () =>
      !isNaN(overallProgress?.currentProgress) &&
      !isNaN(overallProgress?.maximumProgress)
        ? (100 * overallProgress?.currentProgress) /
          overallProgress?.maximumProgress
        : 0,
    [overallProgress?.currentProgress, overallProgress?.maximumProgress]
  );

  useEffect(() => {
    if (!hasProcessed) return;
    stopPolling();
    onBatchComplete(overallProgress);
  }, [
    overallProgress,
    hasProcessed,
    stopPolling,
    batchId,
    setBillingBatchState,
    onBatchComplete
  ]);

  return (
    <>
      {overallProgress?.maximumProgress && (
        <div className={classes.progressRoot}>
          <Typography
            variant="subtitle2"
            className={classes.progressValue}
          >{`${Math.round(progressValue)}%`}</Typography>
        </div>
      )}
      <LinearProgress variant="determinate" value={progressValue} />
      <div className={classes.undoInfoMessageRoot}>
        <Typography className={classes.infoMessage} variant="caption">
          <FormattedMessage id="availableToBillList.undoInfoMessage" />
        </Typography>
      </div>
    </>
  );
};

ProgressContent.propTypes = {
  batchId: PropTypes.string,
  setBillingBatchState: PropTypes.func,
  onBatchComplete: PropTypes.func
};

export default ProgressContent;
