import { useMutation, useApolloClient } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useMeContext } from '~/modules/me';
import { useProjectResourcePlanPermissions } from '~/modules/resourcing/hooks';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useSessionStorage } from '~/modules/common/hooks';
import {
  TOBEHIRED_RESOURCE_REQUEST,
  invokeMarkResourceRequestAsToBeHired
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsToBeHired';
import {
  ALLOCATION_REJECTED_RESOURCE_REQUEST,
  invokeMarkResourceRequestAsAllocationRejected
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsAllocationRejected';
import {
  invokeMarkResourceRequestAsComplete,
  COMPLETE_RESOURCE_REQUEST,
  COMPLETE_RESOURCE_REQUEST_WITH_TIMEOFF
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsComplete';
import {
  invokeMarkResourceRequestAsDraft,
  UNSUBMIT_RESOURCE_REQUEST
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsDraft';
import {
  invokeMarkResourceRequestAsRejected,
  REJECTED_RESOURCE_REQUEST
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsRejected';
import {
  invokeMarkResourceRequestAsSubmitted,
  SUBMIT_RESOURCE_REQUEST
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsSubmitted';
import {
  invokeMarkResourceRequestAsTentative,
  TENTATIVE_RESOURCE_REQUEST
} from '../enhancers/requestStatusHandlers/withMarkResourceRequestAsTentative';
import { invokeProposeTopMatch } from '../enhancers/requestStatusHandlers/withProposeTopMatch';
import {
  updateCache,
  RELEASE_RESOURCE_ALLOCATIONS
} from '../enhancers/requestStatusHandlers/useReleaseResourceAllocationForRequest';
import { PROPOSE_TOP_MATCH } from './useProposeTopMatch';
import { getHasExistingAllocation } from './useGetExistingAllocation';

export const useRequestStatusHandlers = ({
  resourceRequest,
  chartSelectedTab,
  requestAccessLevel,
  chartDisplayDateRange,
  allocationChartRef,
  mergeDialogState = null,
  onMarkResourceRequestAsCompleteAndMerge
}) => {
  const {
    permissionsMap,
    featureFlags: { isPsaPraaResourcingUIEnabled }
  } = useMeContext();
  const apolloClient = useApolloClient();

  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const {
    isViewAdvancedSettingsEnabled,
    isViewAvailabilitySettingsEnabled
  } = useProjectResourcePlanPermissions(permissionsMap);

  const [markResourceRequestAsToBeHired] = useMutation(
    TOBEHIRED_RESOURCE_REQUEST
  );

  const onResourceRequestToBeHired = useCallback(
    () =>
      invokeMarkResourceRequestAsToBeHired(markResourceRequestAsToBeHired)(
        resourceRequest,
        chartSelectedTab,
        requestAccessLevel
      ),
    [
      chartSelectedTab,
      markResourceRequestAsToBeHired,
      requestAccessLevel,
      resourceRequest
    ]
  );

  const showTimeOff =
    isViewAvailabilitySettingsEnabled && Boolean(chartDisplayDateRange);

  const showHolidays =
    isViewAdvancedSettingsEnabled && Boolean(chartDisplayDateRange);

  const formattedDateRange = useMemo(
    () =>
      chartDisplayDateRange
        ? {
            startDate: dateToMidnightUTCString(chartDisplayDateRange.startDate),
            endDate: dateToMidnightUTCString(chartDisplayDateRange.endDate)
          }
        : null,
    [chartDisplayDateRange]
  );

  const [releaseResourceAllocationsForRequest] = useMutation(
    RELEASE_RESOURCE_ALLOCATIONS
  );

  const onReleaseResourceAllocationsForRequest = useCallback(
    ({ resourceAllocations, releaseDate, releaseType, comment }) =>
      resourceRequest &&
      releaseResourceAllocationsForRequest({
        variables: {
          input: {
            releaseType,
            allocationIds: (resourceAllocations || []).map(alloc => alloc.id),
            releaseDate,
            comment,
            sendNotification: true
          },
          chartDateRange: formattedDateRange,
          showTimeOff: Boolean(showTimeOff),
          showHolidays: Boolean(showHolidays)
        },
        update: updateCache({
          projectId: resourceRequest.projectUri,
          resourceRequestId: resourceRequest.id,
          limit: 50,
          showTimeOff: Boolean(showTimeOff),
          chartDateRange: formattedDateRange,
          showHolidays: Boolean(showHolidays),
          resourceRequestsQueryVariables
        })
      }),
    [
      resourceRequest,
      releaseResourceAllocationsForRequest,
      formattedDateRange,
      showTimeOff,
      showHolidays,
      resourceRequestsQueryVariables
    ]
  );

  const [markResourceRequestAsSubmitted] = useMutation(SUBMIT_RESOURCE_REQUEST);

  const onResourceRequestSubmit = useCallback(
    () =>
      invokeMarkResourceRequestAsSubmitted(markResourceRequestAsSubmitted)(
        resourceRequest,
        chartSelectedTab,
        requestAccessLevel
      ),
    [
      chartSelectedTab,
      markResourceRequestAsSubmitted,
      requestAccessLevel,
      resourceRequest
    ]
  );

  const [markResourceRequestAsComplete] = useMutation(
    COMPLETE_RESOURCE_REQUEST
  );

  const [markResourceRequestAsCompleteWithTimeoff] = useMutation(
    COMPLETE_RESOURCE_REQUEST_WITH_TIMEOFF
  );

  const onResourceRequestAccept = useCallback(() => {
    const markResourceRequestCompleteParams = {
      request: resourceRequest,
      chartSelectedTab,
      requestAccessLevel,
      showTimeOff,
      chartDateRange: formattedDateRange,
      showHolidays,
      filter:
        resourceRequestsQueryVariables && resourceRequestsQueryVariables.filter,
      sort:
        resourceRequestsQueryVariables && resourceRequestsQueryVariables.sort,
      allocationChartRef
    };

    if (!showTimeOff && !showHolidays) {
      invokeMarkResourceRequestAsComplete(markResourceRequestAsComplete)(
        markResourceRequestCompleteParams
      );
    } else {
      invokeMarkResourceRequestAsComplete(
        markResourceRequestAsCompleteWithTimeoff
      )(markResourceRequestCompleteParams);
    }
  }, [
    resourceRequest,
    chartSelectedTab,
    requestAccessLevel,
    showTimeOff,
    formattedDateRange,
    showHolidays,
    resourceRequestsQueryVariables,
    markResourceRequestAsComplete,
    markResourceRequestAsCompleteWithTimeoff,
    allocationChartRef
  ]);

  const [markResourceRequestAsRejected] = useMutation(
    REJECTED_RESOURCE_REQUEST
  );

  const onResourceRequestReject = useCallback(
    () =>
      invokeMarkResourceRequestAsRejected(markResourceRequestAsRejected)(
        resourceRequest,
        chartSelectedTab,
        requestAccessLevel
      ),
    [
      chartSelectedTab,
      markResourceRequestAsRejected,
      requestAccessLevel,
      resourceRequest
    ]
  );

  const [markResourceRequestAsAllocationRejected] = useMutation(
    ALLOCATION_REJECTED_RESOURCE_REQUEST
  );

  const onResourceRequestAllocationReject = useCallback(
    () =>
      invokeMarkResourceRequestAsAllocationRejected(
        markResourceRequestAsAllocationRejected,
        chartSelectedTab
      )(resourceRequest, chartSelectedTab, requestAccessLevel),
    [
      chartSelectedTab,
      markResourceRequestAsAllocationRejected,
      requestAccessLevel,
      resourceRequest
    ]
  );

  const [markResourceRequestAsTentative] = useMutation(
    TENTATIVE_RESOURCE_REQUEST
  );

  const onResourceRequestFulfilled = useCallback(
    () =>
      invokeMarkResourceRequestAsTentative(markResourceRequestAsTentative)(
        resourceRequest,
        chartSelectedTab,
        requestAccessLevel
      ),
    [
      chartSelectedTab,
      markResourceRequestAsTentative,
      requestAccessLevel,
      resourceRequest
    ]
  );

  const [markResourceRequestAsDraft] = useMutation(UNSUBMIT_RESOURCE_REQUEST);

  const onResourceRequestUnsubmit = useCallback(
    () =>
      invokeMarkResourceRequestAsDraft(markResourceRequestAsDraft)(
        resourceRequest,
        chartSelectedTab,
        requestAccessLevel
      ),
    [
      chartSelectedTab,
      markResourceRequestAsDraft,
      requestAccessLevel,
      resourceRequest
    ]
  );

  const onResourceRequestMerge = useCallback(async () => {
    const { hasExistingAllocation, isLoading } = await getHasExistingAllocation(
      {
        apolloClient,
        projectUri: resourceRequest?.projectUri,
        userUris: (resourceRequest?.resourceAllocations || []).map(
          allocation => allocation.user.userUri
        )
      }
    );

    mergeDialogState?.setExistingAllocationState({
      hasExistingAllocation,
      hasExistingAllocationLoading: isLoading
    });

    if (hasExistingAllocation) {
      mergeDialogState?.openDialog();
    } else {
      onMarkResourceRequestAsCompleteAndMerge();
    }
  }, [
    apolloClient,
    mergeDialogState,
    onMarkResourceRequestAsCompleteAndMerge,
    resourceRequest?.projectUri,
    resourceRequest?.resourceAllocations
  ]);

  const [proposeTopMatch] = useMutation(PROPOSE_TOP_MATCH);

  const onResourceRequestProposeTopMatch = useCallback(
    () =>
      isPsaPraaResourcingUIEnabled &&
      invokeProposeTopMatch(proposeTopMatch)({
        resourceRequestUris: [resourceRequest.id]
      }),
    [
      isPsaPraaResourcingUIEnabled,
      proposeTopMatch,
      resourceRequest?.projectUri,
      resourceRequest.id
    ]
  );

  return {
    onResourceRequestToBeHired,
    onResourceRequestSubmit,
    onResourceRequestAccept,
    onResourceRequestReject,
    onResourceRequestAllocationReject,
    onResourceRequestFulfilled,
    onResourceRequestUnsubmit,
    onReleaseResourceAllocationsForRequest,
    onResourceRequestMerge,
    onResourceRequestProposeTopMatch
  };
};

export default useRequestStatusHandlers;
