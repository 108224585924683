import {
  ClickAwayListener,
  IconButton,
  Link,
  makeStyles
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpSharp';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useMenuState } from '~/modules/common/hooks';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { useMeContext } from '~/modules/me/useMeContext';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';

const getLink = intl => (
  <Link
    href="https://www.replicon.com/polaris/help/how-is-the-total-score-for-a-request-calculated"
    target="_blank"
    rel="noopener noreferrer"
  >
    {intl.formatMessage({ id: 'projectRequest.scoreHelpLinkMessage' })}
  </Link>
);

const getTooltipContent = intl => {
  return [
    {
      title: intl.formatMessage({ id: 'projectRequest.value' }),
      message: intl.formatMessage({ id: 'projectRequest.valueHelp' })
    },
    {
      title: intl.formatMessage({ id: 'projectRequest.investment' }),
      message: intl.formatMessage({ id: 'projectRequest.investmentHelp' })
    },
    {
      title: intl.formatMessage({ id: 'projectRequest.risk' }),
      message: intl.formatMessage({ id: 'projectRequest.riskHelp' })
    },
    {
      title: intl.formatMessage({ id: 'projectRequest.totalScore' }),
      message: intl.formatMessage({ id: 'projectRequest.totalHelp' })
    },
    {
      message: intl.formatMessage(
        {
          id: 'projectRequest.scoreHelpMessage'
        },
        { link: getLink(intl) }
      )
    }
  ];
};

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    maxWidth: 350,
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'start',
    alignItems: 'baseline'
  },
  title: {
    margin: 0,
    '$message + &': {
      marginTop: theme.spacing(1)
    }
  },
  message: {
    margin: 0,
    '& + &': {
      marginTop: theme.spacing(1)
    }
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    cursor: 'help',
    opacity: 0.3,
    verticalAlign: 'middle'
  },
  helpIconButton: {
    padding: 0
  }
}));

const usePopperStyles = makeStyles(theme => ({
  popper: {
    transform:
      theme.direction === 'rtl' ? 'translate3d(-55px,315px,0) !important' : null
  }
}));

export const ScoreHelp = () => {
  const classes = useStyles();
  const popperClasses = usePopperStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const intl = useIntl();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const menuOpen = Boolean(anchorEl);

  const content = getTooltipContent(intl);

  const { handleKeyDown } = useKeyBoardEventHandlers({ onClose: onMenuClose });

  return (
    <>
      <ClickAwayListener onClickAway={onMenuClose}>
        <IconButton
          className={classes.helpIconButton}
          onClick={onMenuClick}
          onKeyDown={isPsaPrpAccessibilityIssuesEnabled && handleKeyDown}
          aria-label="help"
          aria-owns={menuOpen ? 'help-action' : undefined}
          aria-haspopup="true"
        >
          <HelpIcon className={classes.helpIcon} />
        </IconButton>
      </ClickAwayListener>
      <ArrowPopup
        id="score-help-popup"
        anchorEl={anchorEl}
        placement="right-start"
        onClose={onMenuClose}
        preventOverflow
        classes={popperClasses}
      >
        <div className={classes.root}>
          {content.map(item => (
            <Fragment key={item.title || 'sc_link'}>
              {item.title ? (
                <h4 className={classes.title}>{item.title}</h4>
              ) : null}
              <p className={classes.message}>{item.message}</p>
            </Fragment>
          ))}
        </div>
      </ArrowPopup>
    </>
  );
};

ScoreHelp.propTypes = {};

export default ScoreHelp;
