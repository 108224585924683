import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';

import { STRING_MAX_LENGTH, exceededMaxLengthMessage } from './common';

const invalidEmailMessage = <FormattedMessage id="client.invalidEmail" />;
const invalidUrlMessage = <FormattedMessage id="client.invalidUrl" />;

export const urlFormatRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const contactInfoValidationSchema = object().shape({
  contactName: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
  contactInfo: object().shape({
    address: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    city: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    stateProvince: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    zipPostalCode: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    phoneNumber: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    faxNumber: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    email: string()
      .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
      .email(invalidEmailMessage),
    website: string().matches(urlFormatRegex, {
      message: invalidUrlMessage,
      excludeEmptyString: true
    })
  })
});
