import React from 'react';
import { retryLoading } from '~/util';

const ScheduleMonthDetails = React.lazy(() =>
  retryLoading(() => import('./ScheduleMonthDetails'))
);

export const canViewRevenueRun = ({ permissions }) =>
  Boolean(
    permissions.find(
      p =>
        p.permissionActionUri ===
        'urn:replicon:revenue-run-action:view-revenue-run'
    )
  );

const route = ({ intl, me }) => ({
  path: '/financials/revenue-recognition/schedule/:period',
  icon: () => null,
  hidden: true,
  title: () => '',
  component: ScheduleMonthDetails,
  hasPermission: canViewRevenueRun(me)
});

export default route;
