import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '~/modules/common/components';
import ClientInfoDetail from './ClientInfoDetail';
import User from './User';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(0.25, 0),
    overflow: 'hidden'
  }
}));

const label = <FormattedMessage id="client.comanagers" />;

export const ClientInfoComanagers = ({ comanagers }) => {
  const classes = useStyles();

  const detail =
    comanagers.length > 0 ? (
      <List dense disablePadding>
        {comanagers.map(user => (
          <ListItem
            className={classes.listItem}
            data-qe-id={user.displayText}
            key={user.id}
            dense
            disableGutters
          >
            <User user={user} />
          </ListItem>
        ))}
      </List>
    ) : (
      <NoValue />
    );

  return <ClientInfoDetail label={label} detail={detail} />;
};

ClientInfoComanagers.propTypes = {
  comanagers: PropTypes.array.isRequired
};

export default ClientInfoComanagers;
