import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import FieldLabel from './common/components/FieldLabel';

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(1)
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pathIcon: {
    margin: theme.spacing(0, 0.5),
    fontSize: theme.typography.body2.fontSize,
    opacity: 0.87
  },
  resourcePlanButton: {
    padding: theme.spacing(0.5, 0)
  }
}));

const NoResourceUserProjectAllocationContent = ({ onClose, projectSlug }) => {
  const classes = useStyles();

  const history = useHistory();
  const onResourcePlanClick = useCallback(() => {
    history.push({
      pathname: `/projects/${projectSlug}/resourcingplan`
    });
  }, [history, projectSlug]);

  const endIcon = useMemo(
    () => <ArrowForwardIcon fontSize="small" className={classes.pathIcon} />,
    [classes.pathIcon]
  );

  return (
    <>
      <FieldLabel
        messageId="taskAllocationEditor.taskAllocation"
        variant="subtitle1"
        className={classes.title}
      />
      <Alert severity="info" icon={false}>
        <FieldLabel
          messageId="taskAllocationEditor.noProjectAllocation"
          variant="body2"
          className={classes.heading}
        />
        <FieldLabel
          messageId="taskAllocationEditor.noAllocationPrompt"
          variant="body2"
        />
        <Button
          className={classes.resourcePlanButton}
          size="small"
          color="primary"
          variant="text"
          onClick={onResourcePlanClick}
          endIcon={endIcon}
        >
          <FormattedMessage id="projectPageTabs.resourcingPlan" />
        </Button>
      </Alert>
      <div className={classes.footer}>
        <Button
          variant="contained"
          onClick={onClose}
          size="small"
          className={classes.button}
        >
          <FormattedMessage id="button.ok" />
        </Button>
      </div>
    </>
  );
};

NoResourceUserProjectAllocationContent.propTypes = {
  onClose: PropTypes.func,
  projectSlug: PropTypes.string
};

export default NoResourceUserProjectAllocationContent;
