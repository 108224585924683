import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useTableSettings } from '~/modules/common/components/ListTable';
import { PROJECT_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectListPageType';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const TABLE_KEY = 'portfolio-projects-list';

const usePortfolioProjectListTableSettings = () => {
  const isPsaPpmPortfolioListActualsColumnsEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPpmPortfolioListActualsColumnsEnabled'
  });

  const {
    enabledProjectColumnNames,
    enabledProjectColumnGroups
  } = useEnabledProjectColumns({
    hideClient: true,
    includeGroupsInDefaultColumnNames: !isPsaPpmPortfolioListActualsColumnsEnabled,
    projectListPageType: PROJECT_LIST_PAGE_TYPE.PORTFOLIO
  });

  const { data: tableSettingsData, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: enabledProjectColumnNames,
    skip: !isPsaPpmPortfolioListActualsColumnsEnabled
  });
  const selectedColumnNames = tableSettingsData?.columns;

  return {
    tableKey: TABLE_KEY,
    enabledProjectColumnGroups,
    enabledProjectColumnNames,
    selectedColumnNames,
    tableSettingsDataLoading: loading
  };
};

export default usePortfolioProjectListTableSettings;
