import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

export const PORTFOLIO_PROJECTS_PROGRESS_SUMMARY = gql`
  query portfolioProjectsProgressSummary(
    $portfolioId: String!
    $includeRolledUpTotalActualHours: Boolean = false
    $includeRolledUpTotalEstimateAtCompletionCost: Boolean = false
  ) {
    portfolioProjectsProgressSummary(portfolioId: $portfolioId) {
      rolledUpProjectBudgets {
        amount
        currency {
          symbol
          displayText
        }
      }
      rolledUpTotalActualCost {
        amount
        currency {
          symbol
          displayText
        }
      }
      rolledUpProjectBudgetHours
      rolledUpTotalEstimateAtCompletionHours
      rolledUpTotalActualHours @include(if: $includeRolledUpTotalActualHours)
      rolledUpTotalEstimateAtCompletionCost
        @include(if: $includeRolledUpTotalEstimateAtCompletionCost) {
        amount
        currency {
          symbol
          displayText
        }
      }
    }
  }
`;

const usePortfolioProjectsProgressSummary = ({
  portfolioId,
  isPsaPpmEstimatedCostAtCompletionEnabled,
  canViewCostData
}) => {
  const { data, loading } = useQuery(PORTFOLIO_PROJECTS_PROGRESS_SUMMARY, {
    variables: {
      portfolioId,
      includeRolledUpTotalActualHours: isPsaPpmEstimatedCostAtCompletionEnabled,
      includeRolledUpTotalEstimateAtCompletionCost:
        canViewCostData && isPsaPpmEstimatedCostAtCompletionEnabled
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const {
    rolledUpProjectBudgets,
    rolledUpTotalActualCost,
    rolledUpProjectBudgetHours,
    rolledUpTotalEstimateAtCompletionHours,
    rolledUpTotalActualHours,
    rolledUpTotalEstimateAtCompletionCost
  } = get(data, 'portfolioProjectsProgressSummary', {});

  return {
    loading,
    projectsActualBudget: rolledUpTotalActualCost,
    projectsActualHours: rolledUpTotalActualHours,
    projectsBudget: rolledUpProjectBudgets,
    projectsBudgetHours: rolledUpProjectBudgetHours,
    projectsEstimateAtCompletionCost: rolledUpTotalEstimateAtCompletionCost,
    projectsEstimateAtCompletionHours: rolledUpTotalEstimateAtCompletionHours
  };
};

export default usePortfolioProjectsProgressSummary;
