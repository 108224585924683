import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import useTooltipConditions from '~/modules/common/components/TaskStatusSection/Hooks/useTooltipConditions';
import useCalculateVariationDetails from '../Table/useCalculateVariationDetails';
import { useTaskSummaryLabels } from '../useTaskSummaryLabels';
import CostAccordion from './CostAccordion';
import HoursAccordion from './HoursAccordion';
import DatesAccordion from './DatesAccordion';

const useStyles = makeStyles({
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.75, 1)
  }
});

const useSummaryRowStyle = makeStyles({
  data: ({ isBold, addMargin }) => ({
    fontWeight: isBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    marginRight: addMargin ? theme.spacing(3.25) : 0
  })
});

const useMobileTaskVariationRowStyle = makeStyles({
  content: ({ addMargin }) => ({
    marginRight: addMargin ? theme.spacing(3.25) : 0
  })
});

export const MobileRollUpStatusSection = ({
  source,
  hasMore,
  loadMore,
  loadingMore,
  estimatedCompletionDate
}) => {
  const classes = useStyles();

  const actualsClassOverride = useSummaryRowStyle({
    isBold: source?.rolledUpSummary?.actualHours > 0
  });

  const initialEstimatedClassOverride = useSummaryRowStyle({
    addMargin: source.children.length > 0
  });

  const {
    variationStatus,
    variationHours,
    variationCost,
    costVariationStatus,
    variationDays
  } = useCalculateVariationDetails({
    task: source
  });

  const projectPermittedActionUris = source.project?.permittedActionUris || [];

  const showCost = projectPermittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );
  const {
    actuals,
    workRemaining,
    estimatedAtCompletion,
    estimateBudget
  } = useTaskSummaryLabels({
    isProjectVariant: false
  });

  const { hoursOrCostLabels, dateLabels } = useMemo(
    () => ({
      hoursOrCostLabels: {
        actuals,
        workRemaining,
        estimatedAtCompletion,
        estimateBudget
      },
      dateLabels: {
        estimatedAtCompletion,
        estimateBudget
      }
    }),
    [actuals, estimateBudget, estimatedAtCompletion, workRemaining]
  );
  const hasChildTasks = source.children.length > 0;

  const tooltipConditions = useTooltipConditions({
    isProjectVariant: false,
    source,
    hasChildTasks,
    variationHours,
    variationCost
  });
  const {
    mobileTaskHoursVariationRowMarginCondition,
    mobileTaskCostVariationRowMarginCondition
  } = tooltipConditions;

  const mobileTaskHoursVariationRowClassOverride = useMobileTaskVariationRowStyle(
    {
      addMargin: mobileTaskHoursVariationRowMarginCondition
    }
  );

  const mobileTaskCostVariationRowClassOverride = useMobileTaskVariationRowStyle(
    {
      addMargin: mobileTaskCostVariationRowMarginCondition
    }
  );

  return (
    <>
      <div className={classes.title}>
        <FormattedMessage id="taskDrawer.taskSummary" />
      </div>
      <HoursAccordion
        task={source}
        loadMore={loadMore}
        hasMore={hasMore}
        loadingMore={loadingMore}
        variation={variationHours}
        variationStatus={variationStatus}
        actualsClassOverride={actualsClassOverride}
        initialEstimatedClassOverride={initialEstimatedClassOverride}
        labels={hoursOrCostLabels}
        mobileTaskVariationRowClassOverride={
          mobileTaskHoursVariationRowClassOverride
        }
      />
      {showCost && (
        <CostAccordion
          task={source}
          loadMore={loadMore}
          hasMore={hasMore}
          loadingMore={loadingMore}
          variation={variationCost}
          variationStatus={costVariationStatus}
          actualsClassOverride={actualsClassOverride}
          initialEstimatedClassOverride={initialEstimatedClassOverride}
          labels={hoursOrCostLabels}
          mobileTaskVariationRowClassOverride={
            mobileTaskCostVariationRowClassOverride
          }
          tooltipConditions={tooltipConditions}
        />
      )}
      <DatesAccordion
        task={source}
        estimatedCompletionDate={estimatedCompletionDate}
        endDate={source.endDate}
        variationDays={variationDays}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        labels={dateLabels}
        tooltipConditions={tooltipConditions}
      />
    </>
  );
};

MobileRollUpStatusSection.propTypes = {
  source: PropTypes.object.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  estimatedCompletionDate: PropTypes.string
};

export default MobileRollUpStatusSection;
