import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SideCardSectionDetail } from '~/modules/common/components/SideCard';
import { ClientInfoLoadingContext } from './ClientInfoLoadingContext';

export const ClientInfoDetail = ({ classes, label, detail, dataQeId }) => {
  const loading = useContext(ClientInfoLoadingContext);

  return (
    <SideCardSectionDetail
      classes={classes}
      label={label}
      detail={detail}
      loading={loading}
      dataQeId={dataQeId}
      ariaLabelKey="button.basicInfoEditButton"
    />
  );
};

ClientInfoDetail.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  detail: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dataQeId: PropTypes.string
};

export default ClientInfoDetail;
