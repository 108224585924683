import { useMemo } from 'react';
import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import { programTag } from '~/modules/common/components/SearchBox/Facets/ProgramFacet/tag';
import { portfolioTag } from '~/modules/common/components/SearchBox/Facets/PortfolioFacet/tag';
import { projectManagerTag } from '~/modules/common/components/SearchBox/Facets/ProjectManagerFacet/tag';
import { overbudgetTag } from '~/modules/projects/components/ProjectListPage/Toolbar/OverbudgetFacet';
import { tagsTag } from '~/modules/common/components/SearchBox/Facets/TagFacet/tag';
import { getTagForCriteria } from '~/modules/resourcing/common/enhancers/withFilterBasedOnSearchTerm';
import { costCenterTag } from '~/modules/common/components/SearchBox/Facets/CostCenterFacet/tag';
import { departmentTag } from '~/modules/common/components/SearchBox/Facets/DepartmentFacet/tag';
import { divisionTag } from '~/modules/common/components/SearchBox/Facets/DivisionFacet/tag';
import { employeeTypeTag } from '~/modules/common/components/SearchBox/Facets/EmployeeTypeFacet/tag';
import { locationTag } from '~/modules/common/components/SearchBox/Facets/LocationFacet/tag';
import { serviceCenterTag } from '~/modules/common/components/SearchBox/Facets/ServiceCenterFacet/tag';
import { projectCoManagerTag } from '~/modules/common/components/SearchBox/Facets/ProjectCoManagerFacet/tag';
import { revenueRecognitionPolicyTag } from '~/modules/common/components/SearchBox/Facets/RevenueRecognitionPolicyFacet/tag';
import { linkTypeTag } from '~/modules/common/components/SearchBox/Facets/ProjectLinkTypeFacet/tag';
import { baseProjectTag } from '~/modules/common/components/SearchBox/Facets/BaseProject/tag';
import NONE_OPTION_KEY from '~/modules/common/enums/NoneOptionKey';

import { checkForCriteria } from '~/modules/common/util';

const getForCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag)
    ? criterions[tag].map(v => {
        if (v.value === NONE_OPTION_KEY) return null;

        return v.value;
      })
    : null;

const getOverbudgetCriteria = (criterions, tag) =>
  checkForCriteria(criterions, tag)
    ? criterions[tag].reduce(
        (acc, { value }) => ({
          ...acc,
          ...mapSearchKeyToOverbugetFilter({ searchKey: value })
        }),
        {}
      )
    : null;

const getBaseProjectCriteria = (criterions, tag) => {
  if (checkForCriteria(criterions, tag)) {
    const result = criterions[tag][0];

    return result ? result.value : null;
  }

  return null;
};

const allTags = [
  clientTag,
  programTag,
  portfolioTag,
  projectManagerTag,
  projectCoManagerTag,
  overbudgetTag,
  tagsTag,
  departmentTag,
  divisionTag,
  employeeTypeTag,
  locationTag,
  serviceCenterTag,
  costCenterTag,
  revenueRecognitionPolicyTag,
  linkTypeTag,
  baseProjectTag
];

export const mapProjectSearchFilter = ({
  searchCriteria: { keywords, criterions } = {}
}) => {
  if (
    !(
      (keywords && keywords.length > 0) ||
      allTags.some(tag => checkForCriteria(criterions, tag))
    )
  ) {
    return {};
  }

  return {
    projectFilter: {
      text: keywords && keywords.length > 0 ? keywords.join(' ') : null,
      clients: getForCriteria(criterions, clientTag),
      programs: getForCriteria(criterions, programTag),
      portfolios: getForCriteria(criterions, portfolioTag),
      projectManagers: getForCriteria(criterions, projectManagerTag),
      projectCoManagers: getForCriteria(criterions, projectCoManagerTag),
      overbudget: getOverbudgetCriteria(criterions, overbudgetTag),
      tags: getTagForCriteria(criterions, tagsTag),
      locations: getForCriteria(criterions, locationTag),
      divisions: getForCriteria(criterions, divisionTag),
      serviceCenters: getForCriteria(criterions, serviceCenterTag),
      costCenters: getForCriteria(criterions, costCenterTag),
      departments: getForCriteria(criterions, departmentTag),
      employeeTypes: getForCriteria(criterions, employeeTypeTag),
      revenueRecognitionPolicies: getForCriteria(
        criterions,
        revenueRecognitionPolicyTag
      ),
      projectLinkTypes: getForCriteria(criterions, linkTypeTag),
      baseProject: getBaseProjectCriteria(criterions, baseProjectTag)
    }
  };
};

export const mapSearchKeyToOverbugetFilter = ({ searchKey }) => {
  if (searchKey === 'overbudget') {
    return { eitherHoursOrCostOverbudget: true };
  }

  return { [searchKey]: true };
};

export const useProjectFilter = ({ searchCriteria }) =>
  useMemo(() => mapProjectSearchFilter({ searchCriteria }), [searchCriteria]);
