import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AbbreviatedDecimalHideNull,
  Decimal,
  Percentage
} from '~/modules/common/components';
import { useTooltipTitleProps } from '~/modules/common/components/AbbreviatedDecimalHideNull';
import { isNumeric } from '~/modules/common/numbers';
import { ProgressTypes } from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';
import ProgressDetailsLoading from './ProgressDetailsLoading';
import { useProgressDetailsData } from './hooks';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  percentageValueContainer: {
    ...theme.typography.body1,
    fontSize: '1.25rem',
    fontWeight: 500,
    backgroundColor: ({ isValidNumeric, isOverage }) =>
      isValidNumeric
        ? isOverage
          ? theme.palette.error.light
          : theme.palette.success.light
        : theme.palette.grey[200],
    borderRadius: theme.spacing(0.75),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.75),
    padding: theme.spacing(1)
  },
  noValue: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  percentage: {
    backgroundColor: ({ isOverage }) =>
      isOverage ? theme.palette.error.main : theme.palette.success.main,
    borderRadius: theme.spacing(0.75),
    color: ({ isOverage }) =>
      isOverage ? theme.palette.common.white : theme.palette.common.black,
    padding: theme.spacing(0.25, 0.5)
  },
  relativeValue: {
    color: ({ isOverage }) =>
      isOverage ? theme.palette.error.dark : theme.palette.success.dark
  },
  valuesContainer: {
    ...theme.typography.body1,
    display: 'flex',
    marginTop: theme.spacing(0.25),
    whiteSpace: 'nowrap'
  },
  values: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: theme.spacing(1)
  },
  labels: {
    display: 'flex',
    flexDirection: 'column'
  },
  smallText: {
    fontSize: '0.725rem',
    lineHeight: 1.5
  }
}));

const titles = {
  [ProgressTypes.COST]: (
    <FormattedMessage id="portfolio.budgetProgressCard.cost" />
  ),
  [ProgressTypes.HOURS]: (
    <FormattedMessage id="portfolio.budgetProgressCard.hours" />
  )
};

const labels = {
  [ProgressTypes.COST]: {
    actual: 'portfolio.budgetProgressCard.actualCost',
    estimated: 'portfolio.budgetProgressCard.totalProjectBudget'
  },
  [ProgressTypes.HOURS]: {
    actual: 'portfolio.budgetProgressCard.estimatedAtCompletion',
    estimated: 'portfolio.budgetProgressCard.totalProjectBudget'
  }
};

export const getPrecision = value => (value >= 1 ? (value >= 10 ? 0 : 1) : 2);

const ProgressDetails = ({ loading, type, actual, estimated }) => {
  const {
    actualValue,
    estimatedValue,
    isOverage,
    percentage,
    relativeValue,
    symbol,
    units,
    valuesPrefix
  } = useProgressDetailsData({
    type,
    actual,
    estimated
  });

  const isValidNumeric = isNumeric(actualValue) && isNumeric(estimatedValue);

  const classes = useStyles({ isValidNumeric, isOverage });

  const { actual: actualValueLabel, estimated: estimatedValueLabel } = labels[
    type
  ];

  const tooltipPrecision = type === ProgressTypes.COST ? 2 : 4;

  const percentageTooltipProps = useTooltipTitleProps({
    value: percentage,
    prefix: valuesPrefix,
    suffix: '%'
  });

  const percentageTooltip = useMemo(
    () => <Decimal {...percentageTooltipProps} />,
    [percentageTooltipProps]
  );

  return (
    <div className={classes.container}>
      {loading ? (
        <ProgressDetailsLoading />
      ) : (
        <>
          <Typography variant="subtitle1">{titles[type]}</Typography>
          <div className={classes.percentageValueContainer}>
            {isValidNumeric ? (
              <>
                {isNumeric(percentage) ? (
                  <Percentage
                    className={classes.percentage}
                    prefix={valuesPrefix}
                    title={percentageTooltip}
                    value={percentage}
                    precision={getPrecision(percentage)}
                  />
                ) : null}
                <AbbreviatedDecimalHideNull
                  className={classes.relativeValue}
                  value={relativeValue}
                  prefix={`${valuesPrefix}${symbol}`}
                  suffix={units}
                  tooltipPrecision={tooltipPrecision}
                />
              </>
            ) : (
              <span className={classes.noValue}>-</span>
            )}
          </div>
          <div className={classes.valuesContainer}>
            <div className={clsx(classes.values, classes.smallText)}>
              <AbbreviatedDecimalHideNull
                fixedDecimalScale
                value={estimatedValue}
                prefix={symbol}
                suffix={units}
                tooltipPrecision={tooltipPrecision}
              />
              <AbbreviatedDecimalHideNull
                fixedDecimalScale
                value={actualValue}
                prefix={symbol}
                suffix={units}
                tooltipPrecision={tooltipPrecision}
              />
            </div>
            <div className={clsx(classes.labels, classes.smallText)}>
              <FormattedMessage id={estimatedValueLabel} tagName="span" />
              <FormattedMessage id={actualValueLabel} tagName="span" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ProgressDetails.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ProgressTypes)).isRequired,
  actual: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  estimated: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

export default ProgressDetails;
