import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const BULK_CREATE_BILL_FROM_BILLING_ITEMS_BATCH = gql`
  mutation bulkCreateBillFromBillingItemsBatch(
    $input: [CreateBulkInvoiceFromBillingItemsBatchInput!]!
  ) {
    bulkCreateBillFromBillingItemsBatch(input: $input) {
      batchUri
    }
  }
`;

export const useBulkCreateBillFromBillItemsBatch = ({
  setBillingBatchState
}) => {
  const [bulkCreateBillFromBillingItemsBatch, { loading }] = useMutation(
    BULK_CREATE_BILL_FROM_BILLING_ITEMS_BATCH
  );

  return {
    bulkCreateBillFromBillingItemsBatch: async input => {
      const { data } = await bulkCreateBillFromBillingItemsBatch({
        variables: {
          input
        },
        awaitRefetchQueries: true
      });

      if (data?.bulkCreateBillFromBillingItemsBatch?.batchUri) {
        setBillingBatchState({
          batchId: data?.bulkCreateBillFromBillingItemsBatch?.batchUri,
          batchInProgress: true
        });
      }
    },
    loading
  };
};

export default useBulkCreateBillFromBillItemsBatch;
