import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, alpha, Tooltip, Typography } from '@material-ui/core';
import { VictoryContainer, VictoryPie, VictoryTooltip } from 'victory';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import {
  LegendTypes,
  ProgressTypes
} from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';
import { useMeContext } from '~/modules/me';
import useDonutChartClickHandler from './hooks/useDonutChartClickHandler';
import DonutChartLoading from './DonutChartLoading';

const config = {
  size: 64,
  innerRadius: 20
};

const colorScale = [theme.palette.error.main, theme.palette.success.main];

const styles = {
  chart: {
    data: {
      cursor: ({ datum }) => (datum.id === LegendTypes.OVER ? 'pointer' : null)
    }
  },
  chartTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fill: theme.palette.text.secondary
  },
  tooltipStyle: {
    fontSize: '10px',
    fill: theme.palette.common.white
  },
  flyoutStyle: {
    strokeWidth: 0,
    fill: alpha(theme.palette.grey[700], 0.9)
  },
  flyoutPadding: {
    top: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
    left: theme.spacing(1),
    right: theme.spacing(1)
  }
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5)
  },
  chartContainer: {
    display: 'flex',
    position: 'relative'
  },
  chartTitle: {
    ...theme.typography.body1,
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  chartTitleText: {
    zIndex: 1
  },
  chartLabel: {
    ...theme.typography.body1,
    fontSize: '0.8125rem',
    lineHeight: 1,
    marginTop: theme.spacing(0.4),
    whiteSpace: 'nowrap'
  }
});

const labels = {
  [ProgressTypes.COST]: (
    <FormattedMessage id="portfolio.projectsOverBudgetProgressCard.overCostLabel" />
  ),
  [ProgressTypes.HOURS]: (
    <FormattedMessage id="portfolio.projectsOverBudgetProgressCard.overHoursLabel" />
  ),
  [ProgressTypes.DATE]: (
    <FormattedMessage id="portfolio.projectsOverBudgetProgressCard.pastEndDateLabel" />
  )
};

const labelProgressTypesMap = {
  [ProgressTypes.COST]: {
    over: 'portfolio.projectsOverBudgetProgressCard.overCostBudget',
    under: 'portfolio.projectsOverBudgetProgressCard.underCostBudget'
  },
  [ProgressTypes.HOURS]: {
    over: 'portfolio.projectsOverBudgetProgressCard.overHoursBudget',
    under: 'portfolio.projectsOverBudgetProgressCard.underHoursBudget'
  },
  [ProgressTypes.DATE]: {
    over: 'portfolio.projectsOverBudgetProgressCard.pastEndDate',
    under: 'portfolio.projectsOverBudgetProgressCard.underEndDate'
  }
};

const labelComponent = (
  <VictoryTooltip
    style={styles.tooltipStyle}
    pointerLength={6}
    flyoutStyle={styles.flyoutStyle}
    flyoutPadding={styles.flyoutPadding}
  />
);

const getPieData = ({ intl, overProjectsCount, totalProjectsCount, type }) => {
  const underProjectsCount = totalProjectsCount - overProjectsCount;

  return [
    {
      y: overProjectsCount,
      id: LegendTypes.OVER,
      label: intl.formatMessage(
        { id: labelProgressTypesMap[type].over },
        { count: overProjectsCount, displayText: overProjectsCount }
      )
    },
    {
      y: underProjectsCount,
      id: LegendTypes.UNDER,
      label: intl.formatMessage(
        { id: labelProgressTypesMap[type].under },
        { count: underProjectsCount, displayText: underProjectsCount }
      )
    }
  ];
};

const DonutChart = ({
  type,
  totalProjectsCount,
  overProjectsCount,
  loading
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    featureFlags: { isPsaPpmCostEacEnhancementsEnabled }
  } = useMeContext();

  const { events } = useDonutChartClickHandler({ type });

  return loading ? (
    <DonutChartLoading size={config.size} />
  ) : totalProjectsCount ? (
    <div className={classes.container}>
      <div className={classes.chartContainer}>
        <div className={classes.chartTitle}>
          <Tooltip
            title={intl.formatMessage(
              { id: labelProgressTypesMap[type].over },
              {
                count: totalProjectsCount,
                displayText: `${overProjectsCount} / ${totalProjectsCount}`
              }
            )}
          >
            <span className={classes.chartTitleText}>{overProjectsCount}</span>
          </Tooltip>
        </div>
        <VictoryContainer
          responsive={false}
          height={config.size}
          width={config.size}
        >
          <VictoryPie
            standalone={false}
            width={config.size}
            height={config.size}
            data={getPieData({
              intl,
              overProjectsCount,
              totalProjectsCount,
              type
            })}
            events={
              isPsaPpmCostEacEnhancementsEnabled || type !== ProgressTypes.COST
                ? events
                : null
            }
            radius={config.size / 2}
            innerRadius={config.innerRadius}
            colorScale={colorScale}
            labelComponent={labelComponent}
            style={
              isPsaPpmCostEacEnhancementsEnabled || type !== ProgressTypes.COST
                ? styles.chart
                : null
            }
          />
        </VictoryContainer>
      </div>
      <Typography className={classes.chartLabel}>{labels[type]}</Typography>
    </div>
  ) : null;
};

DonutChart.propTypes = {
  totalProjectsCount: PropTypes.number,
  overProjectsCount: PropTypes.number,
  type: PropTypes.oneOf(Object.values(ProgressTypes)).isRequired,
  loading: PropTypes.bool
};

export default DonutChart;
