import React from 'react';
import { DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'formik';
import DialogButtonActions from '~/modules/common/components/DialogButtonActions';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import AddProgramForm from './AddProgramForm';

const addProgramLabel = <FormattedMessage id="addProgram.addProgram" />;

const addProgramDialogRole = {
  role: 'presentation'
};

export const AddProgramDialogContent = ({ open, onClose, onCancel }) => {
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={addProgramDialogRole}
    >
      <DialogTitle data-qe-id="AddProgramTitle">{addProgramLabel}</DialogTitle>
      <DialogContent>
        <AddProgramForm />
      </DialogContent>
      <DialogButtonActions
        onClose={onClose}
        cancelButtonDataQeId="AddProgramCancel"
        addButtonDataQeId="AddProgramAdd"
        primaryButtonMessage={addProgramLabel}
        onCancel={onCancel}
      />
    </Dialog>
  );
};

AddProgramDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func
};

export default connect(AddProgramDialogContent);
