export const extractPathFromFullPath = fullPath =>
  fullPath.map(({ displayText }) => displayText).slice(0, -1);

export default {
  Task: {
    path: task => {
      const { fullPath, projectReference } = task;

      if (!fullPath || !projectReference) return [];

      return [
        projectReference.displayText,
        ...extractPathFromFullPath(fullPath)
      ];
    }
  }
};
