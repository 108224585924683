import React from 'react';
import { FormattedMessage } from 'react-intl';
import AllRowSelectorFormatter from './formatters/AllRowSelectorFormatter';

export const buildColumns = (
  classes,
  editable,
  availableToBillStates,
  isPsaPrpManualBulkBillCreationEnabled
) => ({
  ...(isPsaPrpManualBulkBillCreationEnabled && {
    rowSelector: {
      visible: editable,
      className: classes.rowSelector,
      availableToBillStates,
      value: (
        <AllRowSelectorFormatter
          availableToBillStates={availableToBillStates}
        />
      )
    }
  }),
  client: {
    visible: true,
    value: <FormattedMessage id="avaialbleToBillList.client" />,
    className: classes.client
  },
  date: {
    visible: true,
    value: <FormattedMessage id="avaialbleToBillList.date" />
  },
  amount: {
    visible: true,
    value: <FormattedMessage id="avaialbleToBillList.amount" />,
    align: 'right',
    className: classes.amount
  },
  createBill: {
    visible: editable,
    value: '',
    className: classes.createBill
  }
});
