import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ResourcePoolIcon } from '~/modules/common/components/Icons';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import useNavigateTablePages from '~/modules/resource-management/resourceUserDetails/ResourcePoolInformation/hooks/useNavigateTablePages';
import ResourcePoolTable from './ResourcePoolTable';

const useStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
    top: '-8px',
    padding: theme.spacing(0, 3, 0, 1)
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end'
  },
  root: {
    '& .MuiInputBase-input': {
      fontSize: theme.typography.fontSize
    }
  }
}));

export const getTitle = ({
  classes,
  searchText,
  handleSearchChange,
  formatMessage
}) => (
  <div className={classes.container}>
    <div>
      <ResourcePoolIcon />
      <span className={classes.title}>
        <FormattedMessage id="resourceDrawer.resourcePools" />
      </span>
    </div>
    <TextField
      placeholder={formatMessage({
        id: 'resourceDrawer.resourcePoolSection.placeHolderText'
      })}
      value={searchText}
      onChange={handleSearchChange}
      onBlur={handleSearchChange}
      fullWidth
      size="small"
      variant="outlined"
      className={classes.root}
    />
  </div>
);

export const ResourcePoolSection = ({ userDetails }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const {
    page,
    localSearchText,
    searchText,
    onNextPage,
    onPreviousPage,
    handleSearchChange
  } = useNavigateTablePages();

  return (
    <InfoSection
      title={getTitle({
        classes,
        searchText: localSearchText,
        handleSearchChange,
        formatMessage
      })}
      editable={false}
    >
      <ResourcePoolTable
        userDetails={userDetails}
        searchText={searchText}
        currentPage={page}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </InfoSection>
  );
};

ResourcePoolSection.propTypes = {
  userDetails: PropTypes.object.isRequired
};

export default ResourcePoolSection;
