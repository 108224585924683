import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { NavigationBreadcrumb } from '~/modules/common/components';
import { useProgramBySlug } from '~/modules/programs/program/hooks';

const buildPaths = [
  {
    to: '/programs',
    title: <FormattedMessage id="routes.programs" />
  }
];

export const ProgramNavigationBreadcrumb = () => {
  const {
    params: { slug }
  } = useRouteMatch();
  const { programName } = useProgramBySlug({ slug });

  return <NavigationBreadcrumb paths={buildPaths} title={programName} />;
};

export default ProgramNavigationBreadcrumb;
