import { usePutProjectRequestTimeline } from './usePutProjectRequestTimeline';

export const useOnChangeHandlers = ({
  setFieldValue,
  values,
  periodQuantityInputRef
}) => {
  const { putProjectRequestTimeline } = usePutProjectRequestTimeline({
    id: values.id
  });

  const onStartDateChangeHandler = e => {
    setFieldValue(`startDate`, e);
    putProjectRequestTimeline({
      ...values,
      startDate: e
    });
  };

  const onPeriodQuantityChangeHandler = e => {
    periodQuantityInputRef.current.focus();
    setFieldValue(`periodQuantity`, e);
  };

  const onPeriodQuantityBlurHandler = () => {
    putProjectRequestTimeline({
      ...values,
      periodQuantity: values.periodQuantity || 1
    });
    setFieldValue(`periodQuantity`, values.periodQuantity || 1);
  };

  const onPeriodResolutionChangeHandler = e => {
    setFieldValue(`periodResolution`, e.id);
    putProjectRequestTimeline({
      ...values,
      periodResolution: e.id
    });
  };

  return {
    onStartDateChangeHandler,
    onPeriodQuantityChangeHandler,
    onPeriodResolutionChangeHandler,
    onPeriodQuantityBlurHandler
  };
};

export default useOnChangeHandlers;
