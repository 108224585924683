import { gql } from 'graphql-tag';
import { ResourceAllocationStatus } from '~/types';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';

export const PROJECT_ALLOCATION_FOR_RESOURCE_USER = gql`
  query Eager_GetAllocations(
    $projectUri: String
    $allocationStatusList: [ResourceAllocationStatus]
    $filter: ResourceAllocationFilter
  ) {
    resourceAllocations(
      projectUri: $projectUri
      allocationStatusList: $allocationStatusList
      filter: $filter
    ) {
      resourceAllocations {
        id
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
      }
    }
  }
`;

const getProjectAllocationHours = async ({
  apolloClient,
  projectId,
  userId,
  startDate,
  endDate
}) => {
  const {
    data: {
      resourceAllocations: { resourceAllocations }
    }
  } = await apolloClient.query({
    query: PROJECT_ALLOCATION_FOR_RESOURCE_USER,
    variables: {
      filter: {
        users: [userId]
      },
      projectUri: projectId,
      allocationStatusList: [ResourceAllocationStatus.Committed]
    },
    fetchPolicy: 'network-only'
  });

  if (!resourceAllocations?.length) return null;

  return (
    getTotalHoursForDateRangeFromScheduleRules({
      scheduleRules: resourceAllocations[0].scheduleRules,
      start: startDate,
      end: endDate
    }) || 0
  );
};

export default getProjectAllocationHours;
