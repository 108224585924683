import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { FieldLabel } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor';
import { FormattedHourCostItem } from './FormattedHourCostItem';

export const useStyles = makeStyles(theme => ({
  container: { pointerEvents: 'none' },
  header: {
    color: theme.palette.text.secondary
  }
}));

export const EstimateItemMetric = ({
  formatMessageKey,
  cost,
  hours,
  classes: classesOverride,
  formattedHoursClasses
}) => {
  const classes = useStyles({ classes: classesOverride });

  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <FieldLabel
        variant="caption"
        className={classes.header}
        messageId={formatMessageKey}
      />
      <FormattedHourCostItem
        hours={hours}
        hoursSuffix={formatMessage({
          id: 'taskResourceEstimates.hoursSuffix'
        })}
        cost={cost}
        classes={formattedHoursClasses}
      />
    </div>
  );
};

EstimateItemMetric.propTypes = {
  formatMessageKey: PropTypes.string,
  cost: PropTypes.object,
  hours: PropTypes.number,
  classes: PropTypes.object,
  formattedHoursClasses: PropTypes.object
};

export default EstimateItemMetric;
