import { Checkbox, makeStyles } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SELECTED_STATUS } from '~/modules/billing-invoicing/common/components/BillingInvoicingTab/enum';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-1)
  }
}));

export const AllRowSelectorFormatter = ({
  availableToBillStates: {
    onSelectAllClick,
    selectAllStatus,
    hasAtleastOneSelectableAvailableToBillRow
  }
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Checkbox
      checked={selectAllStatus === SELECTED_STATUS.ALL}
      indeterminate={selectAllStatus === SELECTED_STATUS.PARTIAL}
      onChange={useCallback(() => {
        onSelectAllClick(selectAllStatus === SELECTED_STATUS.NONE);
      }, [onSelectAllClick, selectAllStatus])}
      color="primary"
      disabled={!hasAtleastOneSelectableAvailableToBillRow}
      size="small"
      classes={classes}
      inputProps={useMemo(
        () => ({
          'aria-label': formatMessage({
            id: 'availableToBillList.allRowsSelected'
          })
        }),
        [formatMessage]
      )}
    />
  );
};

AllRowSelectorFormatter.propTypes = {
  availableToBillStates: PropTypes.object
};

export default AllRowSelectorFormatter;
