import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { getStatusBasedClassName } from '~/modules/resourcing/common/enums';
import { ResourceRequestStatus } from '~/types';
import useStyles from './useStyles';

export const RequestStatusLabel = memo(
  ({ classes: classesOverride, value, short, dataQeId }) => {
    const classes = useStyles({ classes: classesOverride });

    return (
      <span
        data-qe-id={dataQeId}
        className={classNames(
          classes.root,
          classes[getStatusBasedClassName(value, 'root')]
        )}
      >
        {value === ResourceRequestStatus.Submitted && (
          <FormattedMessage id="resourceRequestItemStatusField.pending" />
        )}
        {value === ResourceRequestStatus.Tentative && (
          <FormattedMessage id="resourceRequestItemStatusField.proposed" />
        )}
        {value === ResourceRequestStatus.Tobehired && (
          <FormattedMessage id="resourceRequestItemStatusField.toBeHired" />
        )}
        {value === ResourceRequestStatus.Rejected && (
          <FormattedMessage
            id={
              short
                ? 'resourceRequestItemStatusField.reqRejected'
                : 'resourceRequestItemStatusField.rejected'
            }
          />
        )}
        {value === ResourceRequestStatus.Allocationrejected && (
          <FormattedMessage
            id={
              short
                ? 'resourceRequestItemStatusField.resRejected'
                : 'resourceRequestItemStatusField.allocationRejected'
            }
          />
        )}
        {(value === ResourceRequestStatus.Complete ||
          value === ResourceRequestStatus.MergedArchived) && (
          <FormattedMessage id="resourceRequestItemStatusField.complete" />
        )}
        {value === ResourceRequestStatus.Draft && (
          <FormattedMessage id="resourceRequestItemStatusField.draft" />
        )}
      </span>
    );
  }
);

RequestStatusLabel.propTypes = {
  dataQeId: PropTypes.string,
  short: PropTypes.bool,
  classes: PropTypes.object,
  value: PropTypes.string.isRequired
};

export default RequestStatusLabel;
