import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useClientManagers } from '../../../hooks';
import EditClientBasicInfoDialogForm from './EditClientBasicInfoDialogForm';
import EditClientDialogActions from './EditClientDialogActions';
import { basicInfoValidationSchema } from './validationSchemas';
import { useUpdateClientBasicInfoOnSubmit } from './useUpdateClientBasicInfoOnSubmit';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const clientBasicInfoDialogRole = {
  role: 'presentation'
};

export const EditClientBasicInfoDialog = ({ open, onClose, details }) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  const { id, name, code, comment, clientManager, comanagers } = details;

  const initialValues = useMemo(
    () => ({
      id,
      name,
      code: code || '',
      comment: comment || '',
      clientManager,
      comanagers
    }),
    [clientManager, code, comanagers, comment, id, name]
  );

  const { onSubmit, onReset, submitErrors } = useUpdateClientBasicInfoOnSubmit(
    onClose
  );

  const clientManagers = useClientManagers({
    clientId: id,
    currentClientManager: clientManager
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={basicInfoValidationSchema}
    >
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        fullScreen={isMobile}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionProps={clientBasicInfoDialogRole}
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <DescriptionIcon className={classes.icon} />
          <Typography variant="h6">{name}</Typography>
        </DialogTitle>
        <DialogContent>
          <EditClientBasicInfoDialogForm
            clientManagers={clientManagers}
            submitErrors={submitErrors}
          />
        </DialogContent>
        <EditClientDialogActions onClose={onClose} />
      </Dialog>
    </Formik>
  );
};

EditClientBasicInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  details: PropTypes.object
};

export default EditClientBasicInfoDialog;
