import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';
import get from 'lodash.get';
import deepEqual from 'fast-deep-equal';
import {
  specificProjectFragmentLegacy,
  specificResourceRequestWithAllocationsFragment,
  specificResourceRequestWithAllocationsScheduleFragment,
  resourceMatchesFragment
} from '~/modules/resourcing/common/fragments';
import { ResourceRequestAccessLevel } from '~/types';
import { useHasFeatureFlag, useSessionStorage } from '~/modules/common/hooks';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';

export const RESOURCE_REQUESTS_ALLOCATIONS_QUERY = gql`
  query resourceRequests(
    $cursor: String
    $roleUri: String
    $projectUri: String
    $requestStatusList: [ResourceRequestStatus]
    $requestAccessLevel: ResourceRequestAccessLevel
    $filter: ResourceRequestsFilter
    $limit: Int = 500
    $minimumLimit: Int
  ) {
    resourceRequests(
      limit: $limit
      cursor: $cursor
      roleUri: $roleUri
      projectUri: $projectUri
      requestStatusList: $requestStatusList
      requestAccessLevel: $requestAccessLevel
      filter: $filter
      minimumLimit: $minimumLimit
    ) {
      resourceRequests {
        project {
          ...SpecificProjectFragmentLegacy
        }
        ...SpecificResourceRequestWithAllocation
      }
      nextPageCursor
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
  ${specificProjectFragmentLegacy}
`;

export const RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_SCHEDULE = gql`
  query resourceRequests(
    $cursor: String
    $roleUri: String
    $projectUri: String
    $requestStatusList: [ResourceRequestStatus]
    $requestAccessLevel: ResourceRequestAccessLevel
    $filter: ResourceRequestsFilter
    $limit: Int = 500
    $minimumLimit: Int
    $chartDateRange: DateRangeInput
    $periodResolution: PeriodResolutionOption!
    $resourceMatchesPage: Int = 1
    $resourceMatchesPageSize: Int = 1
    $isPsaPraaResourcingUIEnabled: Boolean = false
  ) {
    resourceRequests(
      limit: $limit
      cursor: $cursor
      roleUri: $roleUri
      projectUri: $projectUri
      requestStatusList: $requestStatusList
      requestAccessLevel: $requestAccessLevel
      filter: $filter
      minimumLimit: $minimumLimit
    ) {
      resourceRequests {
        mergedResourceRequests {
          id
        }
        project {
          ...SpecificProjectFragmentLegacy
        }
        ...SpecificResourceRequestWithAllocationSchedule
        ...ResourceMatchesFragment
      }
      nextPageCursor
    }
  }
  ${specificResourceRequestWithAllocationsScheduleFragment}
  ${specificProjectFragmentLegacy}
  ${resourceMatchesFragment}
`;

const useResourceRequests = ({
  roleUri,
  projectUri,
  requestStatusList,
  requestAccessLevel = ResourceRequestAccessLevel.All,
  filter,
  chartDateRange,
  scale
}) => {
  const limit = 50;
  const minimumLimit = 10;

  const isPsaPraaResourcingUIEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPraaResourcingUIEnabled'
  });

  const variables = {
    cursor: null,
    roleUri,
    projectUri,
    requestStatusList,
    requestAccessLevel,
    filter,
    limit,
    minimumLimit,
    chartDateRange,
    periodResolution: mapScaleToPeriodResolution(scale),
    isPsaPraaResourcingUIEnabled
  };

  const { setValue, storedValue } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  useEffect(() => {
    if (!deepEqual(storedValue, variables)) {
      setValue(variables);
    }
  }, [setValue, variables, storedValue]);

  const [loadingMore, setLoadingMore] = useState(false);
  const { loading, error, data, fetchMore } = useQuery(
    RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_SCHEDULE,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables,
      errorPolicy: 'all'
    }
  );

  const resourceRequests = get(data, 'resourceRequests.resourceRequests') || [];
  const nextPageCursor = get(data, 'resourceRequests.nextPageCursor');

  const hasMoreRows = Boolean(nextPageCursor);

  const loadMoreRows = useCallback(async () => {
    if (loading || loadingMore || !hasMoreRows) return;

    setLoadingMore(true);
    try {
      await fetchMore({
        query: RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_SCHEDULE,
        variables: {
          ...variables,
          cursor: nextPageCursor
        }
      });
    } finally {
      setLoadingMore(false);
    }
  }, [loading, loadingMore, hasMoreRows, fetchMore, variables, nextPageCursor]);

  return {
    loadingRows: loading,
    resourceRequests: resourceRequests.filter(r =>
      requestStatusList.includes(r.requestStatus)
    ),
    error,
    loadMoreRows,
    hasMoreRows,
    loadingMoreRows: loadingMore
  };
};

export default useResourceRequests;
