import { useCallback, useMemo } from 'react';
import { compareDateObjects } from '~/modules/common/dates/compare';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import { makeSimpleEventChangeHandler } from '~/util';
import { ESTIMATED_COST_MAX, ESTIMATED_HOURS_MAX } from './constants';

export const useChangeHandlers = ({
  values,
  setFieldValue,
  setStatus,
  customFieldDefinitions,
  initialValues,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const onNameChange = useCallback(
    event => {
      setFieldValue('name', event.target.value);
      setStatus(undefined);
    },
    [setFieldValue, setStatus]
  );

  const onStartDateChange = useCallback(
    startDate => {
      setFieldValue('startDate', startDate);

      const { endDate } = values;
      const isStartDateAfterEndDate =
        compareDateObjects(startDate, endDate) === 1;

      if (isStartDateAfterEndDate) {
        setFieldValue('endDate', startDate);
      }
    },
    [setFieldValue, values]
  );

  const onEndDateChange = useCallback(
    endDate => {
      setFieldValue('endDate', endDate);

      const { startDate } = values;
      const isEndDateBeforeStartDate =
        compareDateObjects(endDate, startDate) === -1;

      if (isEndDateBeforeStartDate) {
        setFieldValue('startDate', endDate);
      }
    },
    [setFieldValue, values]
  );

  const onExtensionFieldsChange = useCallback(
    extensionFieldValues => {
      setFieldValue('extensionFieldValues', extensionFieldValues);
    },
    [setFieldValue]
  );

  const onTaskOwnerChange = useCallback(
    assignedOwner => {
      setFieldValue('assignedUser', assignedOwner);

      const { assignedRole } = values;

      const userRole = assignedOwner?.projectRoles?.[0]?.projectRole;

      if (userRole && !assignedRole) {
        setFieldValue('assignedRole', {
          id: userRole.uri,
          displayText: userRole.name
        });
      }
      setFieldValue('assignedUserRoleId', userRole ? userRole.uri : null);
    },
    [setFieldValue, values]
  );

  const onAssignedRoleChange = useCallback(
    (role, users) => {
      const { assignedRole, assignedUser } = values;

      const { id: assignedRoleId } = assignedRole || {};
      const { id: assigneeId } = assignedUser || {};
      const { id: roleId } = role || {};
      const findResource = projectRole =>
        projectRole.projectRole.uri === roleId;
      const findUser = user => (user.projectRoles || []).find(findResource);

      if (!assignedRoleId && !assigneeId) {
        const matchingResource = (users || []).find(findUser);

        if (matchingResource) setFieldValue('assignedUser', matchingResource);
      }
      setFieldValue('assignedRole', role);
    },
    [setFieldValue, values]
  );

  const onInitialEstimatedCostAmountChange = useCallback(
    e =>
      isPsaRmpTaskAllocation1Enabled
        ? setFieldValue(
            'initialEstimatedCost.amount',
            e.target?.value && Math.min(e.target?.value, ESTIMATED_COST_MAX)
          )
        : setFieldValue('initialEstimatedCost.amount', e.target?.value),
    [isPsaRmpTaskAllocation1Enabled, setFieldValue]
  );

  const onInitialEstimatedCostCurrencyChange = useCallback(
    e => setFieldValue('initialEstimatedCost.currency', e),
    [setFieldValue]
  );

  const onInitialEstimatedHoursChange = useCallback(
    e =>
      setFieldValue(
        'initialEstimatedHours',
        e.target?.value && Math.min(e.target?.value, ESTIMATED_HOURS_MAX)
      ),
    [setFieldValue]
  );

  const onCostTypeChange = useCallback(e => setFieldValue('costType', e), []);

  const onTimeAndExpenseEntryTypeChange = useCallback(
    e => {
      if (e.id === TIME_AND_EXPENSE_ENTRY_TYPE.NO) {
        setFieldValue('isTimeEntryAllowed', false);
      } else {
        setFieldValue('isTimeEntryAllowed', true);
      }
      setFieldValue('timeAndExpenseEntryType', e);
    },
    [setFieldValue]
  );

  const onIsMilestoneChange = useCallback(
    event => setFieldValue('isMilestone', event.target.checked),
    [setFieldValue]
  );

  const customFieldHandlers = useMemo(
    () =>
      customFieldDefinitions.map(definition => ({
        ...definition,
        onChange: value => {
          switch (definition.type.uri) {
            case 'urn:replicon:custom-field-type:date': {
              setFieldValue(definition.uri, value);
              break;
            }
            default: {
              setFieldValue(definition.uri, value.target.value);
            }
          }
        }
      })),
    [customFieldDefinitions, setFieldValue]
  );

  const onTimesheetAccessChange = useCallback(
    value => {
      const isUnassigned = orginalTimesheetAccess =>
        !value.some(
          timesheetAccess => orginalTimesheetAccess.id === timesheetAccess.id
        );

      const unassignedValues = initialValues.assignedTimesheetAccessUris.filter(
        isUnassigned
      );

      setFieldValue('unAssignedTimesheetAccessUris', unassignedValues);
      setFieldValue('assignedTimesheetAccessUris', value);
    },
    [setFieldValue, initialValues.assignedTimesheetAccessUris]
  );

  return {
    onNameChange,
    onCodeChange: makeSimpleEventChangeHandler('code')({ setFieldValue }),
    onStartDateChange,
    onEndDateChange,
    onDescriptionChange: makeSimpleEventChangeHandler('description')({
      setFieldValue
    }),
    onIsMilestoneChange,
    onInitialEstimatedHoursChange: isPsaRmpTaskAllocation1Enabled
      ? onInitialEstimatedHoursChange
      : makeSimpleEventChangeHandler('initialEstimatedHours')({
          setFieldValue
        }),
    onInitialEstimatedCostAmountChange,
    onInitialEstimatedCostCurrencyChange,
    onTimeAndExpenseEntryTypeChange,
    onTaskOwnerChange,
    onExtensionFieldsChange,
    onAssignedRoleChange,
    onCostTypeChange,
    onTimesheetAccessChange,
    customFieldHandlers
  };
};

export default useChangeHandlers;
