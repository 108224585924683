import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import { extractFirstValidationError } from '~/modules/common/graphql/errors';
import { useHasPermission } from '~/modules/common/permissions/useHasPermission';
import { addLocalRoleToCache } from '~/modules/common/hooks/useRoles';
import useAddRoleMutation from './useAddRoleMutation';

const mapValuesOnSubmit = ({ name, costRate, description, billingRate }) => ({
  name,
  description,
  costRate: costRate ? mapMoneyValue(costRate) : null,
  billingRate: billingRate ? mapMoneyValue(billingRate) : null
});

export const mapMoneyValue = money =>
  money
    ? {
        amount: money.amount || 0,
        currency: money.currency
          ? {
              id: money.currency.id
            }
          : null
      }
    : null;

export const useAddRoleOnSubmit = ({
  onClose,
  onChange,
  refetchActiveRoles
}) => {
  const intl = useIntl();
  const [addRole] = useAddRoleMutation();
  const hasViewPermission = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:view-project-role'
  });

  const client = useApolloClient();

  const onSubmit = useCallback(
    async (values, { resetForm, setSubmitting, setFieldError, ...rest }) => {
      setSubmitting(true);

      const payload = mapValuesOnSubmit(values);
      const refetchQueries = [];

      if (refetchActiveRoles) {
        refetchQueries.push('activeRoles');
      }
      try {
        const { data } = await addRole({
          variables: {
            addRoleInput: payload,
            includeSkills: false,
            isCurrentRateEnabled: hasViewPermission
          },
          refetchQueries,
          awaitRefetchQueries: true
        });

        const { addRole2: role = {} } = data;

        addLocalRoleToCache({
          role,
          client,
          includeSkills: false,
          isCurrentRateEnabled: hasViewPermission
        });
        onChange(role);

        setSubmitting(false);
        resetForm({});
        onClose();
      } catch (err) {
        setSubmitting(false);
        const validationErrors = extractFirstValidationError(err);

        if (validationErrors) {
          const duplicationNameError = validationErrors.find(
            v =>
              v.failureUri ===
              'urn:replicon:validation-failure:role-name-duplicated'
          );

          setFieldError(
            'name',
            duplicationNameError
              ? intl.formatMessage({
                  id: 'addRoleDialog.duplicateRole'
                })
              : null
          );
        }
      }
    },
    [
      refetchActiveRoles,
      hasViewPermission,
      onChange,
      onClose,
      addRole,
      client,
      intl
    ]
  );

  return { onSubmit };
};

export default useAddRoleOnSubmit;
