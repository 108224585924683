import { CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE } from '~/modules/tasks/components/ChangeTaskResourceDialog/enum';

export const getInitialValues = ({ hasTaskResourceUserAllocation }) => {
  return {
    allocationMode: hasTaskResourceUserAllocation
      ? CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_FULL_AMOUNT
      : CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.REMOVE_ALLOCATION,
    availableHours: null,
    newResourceUser: null
  };
};

export const useFormState = ({ hasTaskResourceUserAllocation }) => {
  return {
    initialValues: getInitialValues({ hasTaskResourceUserAllocation })
  };
};

export default useFormState;
