import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import RescheduleProjectDialog from '~/modules/projects/project/ProjectPageHeader/RescheduleProjectDialog';
import { useDialogState } from '~/modules/common/hooks';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import ProjectTimelineChartRowBarLabel from './ProjectTimelineChartRowBarLabel';
import { useProjectTimelineReschedule } from './useProjectTimelineReschedule';

const useStyles = makeStyles(theme => ({
  root: ({ workflowId }) => ({
    position: 'absolute',
    height: theme.spacing(3),
    backgroundColor:
      theme.palette.projectWorkflow[workflowId] || theme.palette.grey[500]
  }),
  containerEditable: {
    cursor: 'move',
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    }),
    '&:hover': {
      boxShadow: theme.shadows[4]
    }
  }
}));

export const ProjectTimelineChartRowBar = ({
  scale,
  project,
  chartStartDate,
  leftComponentWidth,
  chartDisplayDateRange,
  isRescheduleProjectJobRunningInBackground
}) => {
  const {
    startDate,
    endDate,
    actualHours,
    budgetHours,
    status: { uri: workflowId },
    permittedActionUris: projectPermissions
  } = project;
  const classes = useStyles({ workflowId });
  const {
    open: rescheduleProjectDialogOpen,
    openDialog: openRescheduleProjectDialog,
    closeDialog: rescheduleProjectDialogCancel
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const period = {
    startDate: startDate
      ? mapIsoStringtoUtcObject(startDate)
      : DateTime.fromObject({ day: 1, month: 1, year: 1900 }),
    endDate: endDate
      ? mapIsoStringtoUtcObject(endDate)
      : DateTime.fromObject({ day: 31, month: 12, year: 2099 })
  };

  const {
    bind,
    dates,
    newStyle,
    isInChartRange,
    onCloseHandler
  } = useProjectTimelineReschedule({
    scale,
    period,
    chartStartDate,
    chartDisplayDateRange,
    openRescheduleProjectDialog,
    rescheduleProjectDialogCancel
  });

  const canEditProject = projectPermissions.includes(
    'urn:replicon:project-action:edit-project'
  );

  const dragEnabled = !(
    !canEditProject || isRescheduleProjectJobRunningInBackground
  );

  return (
    <>
      {isInChartRange ? (
        <div
          className={classNames(classes.root, {
            [classes.containerEditable]: dragEnabled
          })}
          {...(dragEnabled ? bind() : {})}
          style={newStyle}
        >
          <ProjectTimelineChartRowBarLabel
            leftComponentWidth={leftComponentWidth}
            startDate={startDate ? dates.startDate : null}
            endDate={endDate ? dates.endDate : null}
            actualHours={actualHours}
            budgetHours={budgetHours}
            isRescheduleProjectJobRunningInBackground={
              isRescheduleProjectJobRunningInBackground
            }
          />
        </div>
      ) : null}
      {rescheduleProjectDialogOpen && (
        <RescheduleProjectDialog
          open={rescheduleProjectDialogOpen}
          onClose={onCloseHandler}
          project={project}
          newStartDate={startDate ? dates.startDate : null}
        />
      )}
    </>
  );
};

ProjectTimelineChartRowBar.propTypes = {
  end: PropTypes.object,
  scale: PropTypes.string,
  start: PropTypes.object,
  project: PropTypes.object,
  workflowId: PropTypes.string,
  actualHours: PropTypes.number,
  budgetHours: PropTypes.number,
  chartStartDate: PropTypes.object,
  leftComponentWidth: PropTypes.number,
  projectPermissions: PropTypes.array,
  chartDisplayDateRange: PropTypes.object.isRequired,
  isRescheduleProjectJobRunningInBackground: PropTypes.bool
};

export default ProjectTimelineChartRowBar;
