import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Date, NoValue } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { ReadOnlyCardContentLoading } from '~/modules/common/components/DetailsPage/Card';
import {
  SideCard,
  SideCardSectionDetail,
  SideCardSectionHeader
} from '~/modules/common/components/SideCard';
import { EditVendorBasicInfoDialog } from './EditVendorBasicInfoDialog';

const resourceLabels = {
  name: <FormattedMessage id="vendor.name" />,
  closedDate: <FormattedMessage id="vendor.closedDate" />,
  basicInfoTitle: <FormattedMessage id="vendor.basicInfo" />
};

export const VendorBasicInfo = ({ edit, loading, vendor }) => {
  const { name, closedDate } = vendor;

  const {
    open: basicDialogOpen,
    openDialog: openBasicDialog,
    closeDialog: closeBasicDialog
  } = useDialogState();

  const dateDetails = closedDate ? <Date value={closedDate} /> : <NoValue />;

  return (
    <SideCard>
      {loading ? (
        <ReadOnlyCardContentLoading />
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={6} md={12}>
            <SideCardSectionHeader
              title={resourceLabels.basicInfoTitle}
              onEditClick={openBasicDialog}
              showEdit={edit}
              dataQeId="EditBasicInfo"
              ariaLabelKey="button.basicInfoEditButton"
            />
            {name && (
              <SideCardSectionDetail
                detail={name}
                label={resourceLabels.name}
              />
            )}
            <SideCardSectionDetail
              detail={dateDetails}
              label={resourceLabels.closedDate}
            />
          </Grid>
        </Grid>
      )}
      {basicDialogOpen && (
        <EditVendorBasicInfoDialog
          open={basicDialogOpen}
          onClose={closeBasicDialog}
          vendor={vendor}
        />
      )}
    </SideCard>
  );
};

VendorBasicInfo.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  vendor: PropTypes.object
};

export default VendorBasicInfo;
