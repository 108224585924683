import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';

export const getUserOverDistributionDetails = ({
  chartDisplayPeriods,
  isPsaRmpTaskAllocation1Enabled,
  userTaskAllocationsSummaryScheduleRules,
  resourceAllocationScheduleRules
}) => {
  if (!isPsaRmpTaskAllocation1Enabled) return {};

  let isUserTaskAllocationsOverDistributed = false;
  const userOverDistributedPeriodsMap =
    userTaskAllocationsSummaryScheduleRules?.length &&
    resourceAllocationScheduleRules?.length
      ? chartDisplayPeriods.reduce((acc, period) => {
          const { startDate, endDate } = period;
          const periodResourceAllocationTotalHours = getTotalHoursForDateRangeFromScheduleRules(
            {
              start: startDate,
              end: endDate,
              scheduleRules: resourceAllocationScheduleRules
            }
          );
          const periodUserTaskAllocationTotalHours = getTotalHoursForDateRangeFromScheduleRules(
            {
              start: startDate,
              end: endDate,
              scheduleRules: userTaskAllocationsSummaryScheduleRules
            }
          );

          const periodAvailableTaskAllocationHours =
            periodResourceAllocationTotalHours -
            periodUserTaskAllocationTotalHours;

          if (periodAvailableTaskAllocationHours < 0) {
            isUserTaskAllocationsOverDistributed = true;
            acc[startDate.toISO()] = {
              periodStartDate: startDate,
              periodEndDate: endDate,
              periodUserTaskAllocationTotalHours,
              periodResourceAllocationTotalHours,
              periodAvailableTaskAllocationHours
            };
          }

          return acc;
        }, {})
      : {};

  return {
    userOverDistributedPeriodsMap,
    isUserTaskAllocationsOverDistributed
  };
};
