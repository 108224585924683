import { gql } from 'graphql-tag';
import { useHasFeatureFlag } from '~/modules/common/hooks';

export const CLIENTS_DETAIL_QUERY = gql`
  query ClientsDetails(
    $page: Int!
    $pageSize: Int!
    $clientFilter: ClientFilter2
    $sort: ClientSort,
    ${`$isPsaPrpBillingDefaultForProjectEnabled: Boolean = false`}
  ) {
    clients(
      page: $page
      pageSize: $pageSize
      sort: $sort
      clientFilter: $clientFilter
    ) {
      totalItems
      items {
        id
        displayText
        defaultInvoicePaymentTerm
        invoiceTemplate {
          id
          displayText
        }
        taxProfile @include(if: $isPsaPrpBillingDefaultForProjectEnabled) {
          id
          displayText
          description
          enabled
        }
        invoiceCurrency {
          id
          displayText
          symbol
        }
        billingContactInfo {
          address
          city
          country {
            id
            displayText
          }
          email
          faxNumber
          phoneNumber
          stateProvince
          website
          zipPostalCode
        }
        billingSettings {
          billLineItemsBy
          description
          internalNotes
          showComments
        }
      }
    }
  }
`;

export const useFetchClientsDefaultData = ({ apolloClient }) => {
  const isPsaPrpBillingDefaultForProjectEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpBillingDefaultForProjectEnabled'
  });

  return {
    fetchClientsDefaultData: async clientIds => {
      const clientDetails = await apolloClient.query({
        query: CLIENTS_DETAIL_QUERY,
        variables: {
          page: 1,
          pageSize: clientIds.length,
          clientFilter: { clients: clientIds },
          sort: null,
          isPsaPrpBillingDefaultForProjectEnabled
        },
        fetchPolicy: 'network-only'
      });

      return (clientDetails?.data?.clients?.items || []).reduce(
        (retVal, curr) => ({ ...retVal, [curr.id]: curr }),
        {}
      );
    }
  };
};
