import PropTypes from 'prop-types';
import React from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetResourceMatchRowData } from '../hooks';
import ResourceMatchListHeader from './ResourceMatchListHeader';
import ResourceMatchListRow from './ResourceMatchListRow';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  }
}));

export const ResourceMatchList = ({ resourceRequest }) => {
  const classes = useStyles();

  const userResourceMatchesMap = useGetResourceMatchRowData({
    resourceRequestUri: resourceRequest.id
  });

  return (
    <List data-qe-id="resourceMatchList" className={classes.root}>
      <ResourceMatchListHeader />
      {userResourceMatchesMap.map(userResourceMatch => (
        <ResourceMatchListRow
          key={userResourceMatch.resourceMatch.id}
          resourceMatch={userResourceMatch.resourceMatch}
          userSlug={userResourceMatch.userSlug}
        />
      ))}
    </List>
  );
};

ResourceMatchList.propTypes = {
  resourceRequest: PropTypes.object.isRequired
};

export default ResourceMatchList;
