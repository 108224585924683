import { DateTime } from 'luxon';
import { mapRepliconDateToUtcObject } from './convert';

export const getDaysBetweenDates = (date1, date2, includeEndDay = false) => {
  if (!date1 || !date2) {
    return null;
  }

  const dateObj1 = DateTime.fromObject({ ...date1, zone: 'local' }).endOf(
    'day'
  );
  const dateObj2 = DateTime.fromObject({ ...date2, zone: 'local' }).endOf(
    'day'
  );

  const { days } = dateObj1.diff(dateObj2, 'days');

  return includeEndDay ? days + 1 : days;
};

export const getWorkDaysBetweenDates = ({
  startDateInRepliconFormat,
  endDateInRepliconFormat,
  includeEndDay = true
}) => {
  if (!startDateInRepliconFormat || !endDateInRepliconFormat) return null;
  let startDate = mapRepliconDateToUtcObject(startDateInRepliconFormat);
  let endDate = mapRepliconDateToUtcObject(endDateInRepliconFormat);

  if (startDate > endDate) return null;
  const areDatesSame = () => endDate.diff(startDate, 'days').days === 0;
  const isDateOnaWeekend = date =>
    date.weekdayShort === 'Sat' || date.weekdayShort === 'Sun';

  if (areDatesSame()) {
    if (isDateOnaWeekend(startDate)) {
      return 0;
    }

    return includeEndDay ? 1 : 0;
  }

  const adjustStartDateToMonday = () => {
    if (startDate.weekdayShort === 'Sat') {
      startDate = startDate.plus({ days: 2 });
    }
    if (startDate.weekdayShort === 'Sun') {
      startDate = startDate.plus({ days: 1 });
    }
  };
  const adjustEndDateToFriday = () => {
    if (endDate.weekdayShort === 'Sat') {
      endDate = endDate.minus({ days: 1 });
    }
    if (endDate.weekdayShort === 'Sun') {
      endDate = endDate.minus({ days: 2 });
    }
  };
  const isDateRangeSpanningWeekend = () => endDate.weekday < startDate.weekday;

  adjustStartDateToMonday();
  adjustEndDateToFriday();

  const numberOfDays = endDate.diff(startDate, 'days').days;
  const numberOfWorkingDays =
    Math.floor(numberOfDays / 7) * 5 + (numberOfDays % 7);
  const numberOfWorkingDaysIncludingEndDay = includeEndDay
    ? numberOfWorkingDays + 1
    : numberOfWorkingDays;

  if (isDateRangeSpanningWeekend()) {
    return numberOfWorkingDaysIncludingEndDay - 2;
  }

  return numberOfWorkingDaysIncludingEndDay;
};

export const compareDateObject = (dateObj1, dateObj2) => {
  if (dateObj1.diff(dateObj2, 'days').days === 0) {
    return 0;
  }

  if (dateObj1 < dateObj2) {
    return -1;
  }

  return 1;
};

/**
 * returns 0 if the same, -1 if date1 is before date2 and 1 if date1 is after date2
 */
export const compareDateObjects = (date1, date2) => {
  if (!date1 || !date2) return null;

  const dateObj1 = DateTime.fromObject(date1);
  const dateObj2 = DateTime.fromObject(date2);

  return compareDateObject(dateObj1, dateObj2);
};

export const compareDates = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }

  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const compareServiceDates = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }

  return (
    date1.date === date2.date &&
    date1.month === date2.month &&
    date1.year === date2.year
  );
};

export const isToday = date => {
  const today = DateTime.local().toObject();

  return (
    date.year === today.year &&
    date.month === today.month &&
    date.day === today.day
  );
};

export const equalDateTimes = (dateA, dateB) => {
  if (!DateTime.isDateTime(dateA) && !DateTime.isDateTime(dateB)) {
    return true;
  }

  if (!DateTime.isDateTime(dateA) && DateTime.isDateTime(dateB)) {
    return false;
  }

  if (DateTime.isDateTime(dateA) && !DateTime.isDateTime(dateB)) {
    return false;
  }

  return dateA.equals(dateB);
};

export const sameYearISO = (isoA, isoB) =>
  isoA.substring(0, 4) === isoB.substring(0, 4);

export const sameYearAndMonthISO = (isoA, isoB) =>
  isoA.substring(0, 7) === isoB.substring(0, 7);

export const getEarliestDate = dates => {
  let earliest = dates[0];

  for (let i = 1; i < dates.length; i++) {
    if (dates[i] && (!earliest || compareDateObject(dates[i], earliest) === -1))
      earliest = dates[i];
  }

  return earliest;
};

export const getLatestDate = dates => {
  let latest = dates[0];

  for (let i = 1; i < dates.length; i++) {
    if (dates[i] && (!latest || compareDateObject(dates[i], latest) === 1))
      latest = dates[i];
  }

  return latest;
};

export const getEffectiveDateObject = dates =>
  dates.find((date, index) => {
    const today = DateTime.local().toObject();
    const nextDay = dates[index + 1];

    if (!nextDay) {
      return true;
    }

    return (
      compareDateObjects(date, today) === -1 &&
      compareDateObjects(nextDay, today) === 1
    );
  });

export const dateAfterMinRepliconDate = dateObject => {
  if (!dateObject) {
    return false;
  }

  const date = DateTime.fromObject(dateObject);
  const minRepliconDate = DateTime.fromObject({
    year: 1899,
    day: 31,
    month: 12
  });

  return date > minRepliconDate;
};

export const compareISOStrings = (d1, d2) => {
  if (!d1 || !d2) return false;

  const date1 = DateTime.fromISO(d1, { setZone: true });
  const date2 = DateTime.fromISO(d2, { setZone: true });

  if (date1 < date2) return -1;
  if (date1 > date2) return 1;

  return 0;
};

export const compareMonthAndYear = (d1, d2) => {
  if (!d1 || !d2) return false;

  return d1.year - d2.year || d1.month - d2.month;
};
