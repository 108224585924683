import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { ALLOCATION_REJECTED_RESOURCE_REQUEST } from './withMarkResourceRequestAsAllocationRejected';

const useMarkResourceRequestAsAllocationRejected = ({ resourceRequest }) => {
  const [markResourceRequestAsAllocationRejected] = useMutation(
    ALLOCATION_REJECTED_RESOURCE_REQUEST
  );

  const onMarkResourceRequestAsAllocationRejected = useCallback(
    () =>
      markResourceRequestAsAllocationRejected(
        mapToServiceRequest({
          request: resourceRequest,
          responseType: 'markResourceRequestAsAllocationRejected2',
          responseTypeName: 'MarkResourceRequestAsAllocationRejectedResult',
          requestStatus: ResourceRequestStatus.Allocationrejected
        })
      ),
    [markResourceRequestAsAllocationRejected, resourceRequest]
  );

  return {
    onMarkResourceRequestAsAllocationRejected
  };
};

export default useMarkResourceRequestAsAllocationRejected;
