import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flexStart',
    alignItems: 'stretch',
    alignContent: 'flex-start'
  }
});

export default useStyles;
