import React from 'react';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import AddClientProject from './AddClientProject';
import ClientProjects from './ClientProjects';
import ClientProjectsContextProvider from './ClientProjectsContextProvider';

export const ProjectListPage = ({ ...rest }) => (
  <ClientProjectsContextProvider>
    <ErrorBoundary>
      <ClientProjects {...rest} />
      <AddClientProject {...rest} />
    </ErrorBoundary>
  </ClientProjectsContextProvider>
);

export default ProjectListPage;
