import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  MoreMenu,
  MoreMenuOption,
  LoadingButton
} from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'end'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  menu: {
    padding: theme.spacing(0, 1)
  },
  menuOption: {
    color: theme.palette.secondary.main
  }
}));

const PopoverFooterLayout = ({
  onClose,
  showMoreMenu,
  onMenuClick,
  submitForm,
  isLoading,
  isDisabled
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {showMoreMenu && (
        <MoreMenu className={classes.menu}>
          <MoreMenuOption onClick={onMenuClick} className={classes.menuOption}>
            <Typography variant="body2">
              <FormattedMessage id="taskAllocationEditor.removeAllocatedTime" />
            </Typography>
          </MoreMenuOption>
        </MoreMenu>
      )}
      <Button variant="contained" onClick={onClose} size="small">
        <FormattedMessage id="button.cancel" />
      </Button>
      <LoadingButton
        color="primary"
        className={classes.button}
        variant="contained"
        onClick={submitForm}
        size="small"
        isLoading={isLoading}
        disabled={isDisabled}
      >
        <FormattedMessage id="taskAllocationEditor.setAllocation" />
      </LoadingButton>
    </div>
  );
};

PopoverFooterLayout.propTypes = {
  onClose: PropTypes.func.isRequired,
  showMoreMenu: PropTypes.bool,
  onMenuClick: PropTypes.func,
  submitForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
};

export default PopoverFooterLayout;
