import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';

import List from '@material-ui/core/List';

import { withRoutes } from '~/modules/common/routes';
import MenuItem from '../MenuItem';

export const MainMenu = ({ routes }) => {
  const location = useLocation();

  return (
    <List disablePadding>
      {Object.values(routes).map(
        route =>
          !route.hidden && (
            <MenuItem
              key={route.path}
              path={route.path}
              dataQeId={route.dataQeId}
              icon={route.icon && <route.icon />}
              title={route.title && <route.title />}
              selected={!!matchPath(location.pathname, route)}
              divider={route.showDivider}
            />
          )
      )}
    </List>
  );
};

MainMenu.propTypes = {
  routes: PropTypes.object
};

export default withRoutes(MainMenu);
