import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(6)
  }
}));

const toMenuItemProps = {
  pathname: '/profile',
  state: { showBackButton: false }
};

export const MyProfileMenuItem = forwardRef(({ onClose, me, intl }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem
      component={Link}
      ref={ref}
      to={toMenuItemProps}
      onClick={onClose}
      underline="none"
    >
      <ListItemIcon className={classes.icon}>
        <UserAvatar user={me} size="small" />
      </ListItemIcon>
      <ListItemText primary={intl.formatMessage({ id: 'menu.myProfile' })} />
    </MenuItem>
  );
});

MyProfileMenuItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
};

export default MyProfileMenuItem;
