import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Formik } from 'formik';
import { ChangeTaskResourceDialogTitle } from './ChangeTaskResourceDialogTitle';
import { ChangeTaskResourceDialogContent } from './ChangeTaskResourceDialogContent';
import { ChangeTaskResourceDialogFooter } from './ChangeTaskResourceDialogFooter';
import { useFormState } from './hooks';

const dialogTitleId = 'change-task-resource-dialog-title';

export const ChangeTaskResourceDialog = ({
  onClose,
  onChangeTaskResourceSuccess,
  open,
  resourceEstimate,
  task
}) => {
  const { initialValues } = useFormState({
    hasTaskResourceUserAllocation: Boolean(
      resourceEstimate?.taskResourceUserAllocation
    )
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby={dialogTitleId}
    >
      <ChangeTaskResourceDialogTitle
        taskName={task.displayText}
        isParentTask={task.hasChildren}
        dialogTitleId={dialogTitleId}
      />
      <Formik initialValues={initialValues}>
        <>
          <ChangeTaskResourceDialogContent
            resourceEstimate={resourceEstimate}
            task={task}
          />
          <ChangeTaskResourceDialogFooter
            onClose={onClose}
            onChangeTaskResourceSuccess={onChangeTaskResourceSuccess}
            resourceEstimate={resourceEstimate}
            task={task}
          />
        </>
      </Formik>
    </Dialog>
  );
};

ChangeTaskResourceDialog.propTypes = {
  task: PropTypes.object,
  onClose: PropTypes.func,
  onChangeTaskResourceSuccess: PropTypes.func,
  open: PropTypes.bool.isRequired,
  resourceEstimate: PropTypes.object
};

export default ChangeTaskResourceDialog;
