import { makeStyles, Tooltip } from '@material-ui/core';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsBudgetExceeded } from '~/modules/common/charts/dashboard/ProgressChart/hooks';
import { AbbreviatedDecimalHideNull } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';

const useStyles = makeStyles(theme => ({
  rowItems: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.625)
    }
  },
  columnItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%'
  },
  actualCostBudget: {
    alignItems: 'flex-end',
    textAlign: 'end'
  },
  eacCostBudget: {
    alignItems: 'flex-start',
    textAlign: 'start'
  },
  delimiter: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: 1.6,
    width: '2%'
  },
  message: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  help: {
    '&:hover': {
      cursor: 'help'
    }
  },
  warning: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(2)
  },
  details: {
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.text.primary
  },
  budgetExceeded: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.dark
  }
}));

export const ProgressItemMetricDetail = ({
  denominator,
  denominatorLabel,
  fixedDecimalScale,
  id,
  message,
  numerator,
  numeratorLabel,
  prefix,
  suffix,
  tooltipPrecision
}) => {
  const classes = useStyles();

  const messageTooltipContent = useMemo(
    () => <FormattedMessage id={message} />,
    [message]
  );

  const isBudgetExceeded = useIsBudgetExceeded({
    actuals: numerator,
    budget: denominator
  });

  return (
    <div className={classes.rowItems}>
      {!isNullOrUndefined(numerator) && !isNullOrUndefined(denominator) ? (
        <>
          <div
            className={classNames(
              classes.columnItems,
              classes.actualCostBudget
            )}
          >
            <AbbreviatedDecimalHideNull
              fixedDecimalScale={fixedDecimalScale}
              value={numerator}
              className={classNames(classes.details, {
                [classes.budgetExceeded]: isBudgetExceeded
              })}
              prefix={prefix}
              suffix={suffix}
              tooltipPrecision={tooltipPrecision}
            />
            <span className={classes.message}>
              <FormattedMessage id={numeratorLabel} />
            </span>
          </div>
          <span className={classes.delimiter}>/</span>
          <div
            className={classNames(classes.columnItems, classes.eacCostBudget)}
          >
            <AbbreviatedDecimalHideNull
              fixedDecimalScale={fixedDecimalScale}
              value={denominator}
              className={classes.details}
              prefix={prefix}
              suffix={suffix}
              tooltipPrecision={tooltipPrecision}
            />
            <span className={classes.message}>
              <FormattedMessage id={denominatorLabel} />
            </span>
          </div>
        </>
      ) : (
        <Tooltip title={messageTooltipContent}>
          <span className={classes.rowItems}>
            <WarningSharpIcon className={classes.warning} />
            <span className={classNames(classes.details, classes.help)}>
              <FormattedMessage id={id} />
            </span>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

ProgressItemMetricDetail.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  denominator: PropTypes.number,
  numerator: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  tooltipPrecision: PropTypes.number,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  numeratorLabel: PropTypes.string,
  denominatorLabel: PropTypes.string
};

export default ProgressItemMetricDetail;
