import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  makeStyles,
  Button,
  DialogActions,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useIsBreakpointDown, useDialogState } from '~/modules/common/hooks';
import { ListTablePropTypes } from '~/modules/common/components/ListTable';
import { PortfolioProjectRequestList } from '../PortfolioProjectRequestList';
import { useExistingProjectRequestSearchContext } from './ExistingProjectRequestSearchContext';
import ConfirmProjectRequestMoveDialog from './ConfirmProjectRequestMoveDialog/ConfirmProjectRequestMoveDialog';
import { useAddExistingProjectRequestsFormProps } from './hooks/useAddExistingProjectRequestsFormProps';
import TooManyProjectRequestsDialog from './TooManyProjectRequestsDialog/TooManyProjectRequestsDialog';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  }
}));

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    },
    '& th:first-child': {
      paddingLeft: theme.spacing(6)
    },
    width: '100%'
  },
  tableHeaderCell: {
    color: theme.palette.grey[600],
    top: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(8)
    },
    height: '40px'
  },
  tableCell: {
    height: 40,
    padding: theme.spacing(0, 2)
  },
  tableRow: {
    '&:hover': {
      cursor: ({ submitting }) => (submitting ? 'default' : 'pointer')
    }
  }
}));

const useHeaderStyles = makeStyles(theme => ({
  bottomHeader: {
    top: theme.spacing(5),
    height: theme.spacing(5)
  }
}));

const maxNumberOfRequestsAllowed = { number: 100 };

const AddExistingProjectRequestForm = ({
  portfolioId,
  portfolioName,
  excludePortfolio,
  onCancel,
  enabledColumns,
  isSortLoading,
  sortCriteria,
  onSortChange
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const { searchCriteria } = useExistingProjectRequestSearchContext();
  const classes = useStyles();
  const { open, openDialog, closeDialog } = useDialogState();
  const {
    open: openTooManyRequest,
    openDialog: openTooManyRequestDialog,
    closeDialog: closeTooManyRequestDialog
  } = useDialogState();
  const {
    selectedProjectRequests,
    selectedCount,
    onRowClick,
    submitting,
    handleSubmit,
    error,
    selectedCountValue,
    assignProjectRequestsToPortfolio,
    selectedProjectRequestsWithPortfolios
  } = useAddExistingProjectRequestsFormProps({
    portfolioId,
    openDialog,
    onCancel,
    openTooManyRequestDialog,
    maxNumberOfRequestsAllowed
  });

  const providerClasses = useListTableThemeProviderStyles({ submitting });
  const headerClasses = useHeaderStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <PortfolioProjectRequestList
          isMobile={isMobile}
          excludePortfolio={excludePortfolio}
          selectedColumnNames={enabledColumns}
          searchCriteria={searchCriteria}
          isSortLoading={isSortLoading}
          sortCriteria={sortCriteria}
          onSortChange={onSortChange}
          onRowClick={onRowClick}
          selectedProjectRequests={selectedProjectRequests}
          listClasses={providerClasses}
          listHeaderClasses={headerClasses}
          isExisting
        />
      </DialogContent>
      <DialogActions>
        {error && (
          <Typography color="error" variant="caption">
            <FormattedMessage id="projectRequest.addExistingProjectRequestDialog.submitError" />
          </Typography>
        )}
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          type="submit"
          disabled={selectedCount === 0 || submitting}
          color="primary"
          isLoading={submitting}
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="projectRequest.addExistingProjectRequestDialog.addXProjectRequests"
            values={selectedCountValue}
          />
        </LoadingButton>
      </DialogActions>
      <ConfirmProjectRequestMoveDialog
        open={open}
        onClose={closeDialog}
        onConfirm={assignProjectRequestsToPortfolio}
        projectRequests={selectedProjectRequestsWithPortfolios}
        portfolioName={portfolioName}
      />
      <TooManyProjectRequestsDialog
        open={openTooManyRequest}
        onClose={closeTooManyRequestDialog}
        maxNumberOfRequestsAllowed={maxNumberOfRequestsAllowed}
      />
    </>
  );
};

AddExistingProjectRequestForm.propTypes = {
  portfolioId: PropTypes.string,
  portfolioName: PropTypes.string,
  excludePortfolio: PropTypes.string,
  onCancel: PropTypes.func,
  enabledColumns: PropTypes.array,
  isSortLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortCriteria: ListTablePropTypes.sort
};

export default AddExistingProjectRequestForm;
