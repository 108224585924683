import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ClientInfoDetail from './ClientInfoDetail';
import User from './User';

const label = <FormattedMessage id="client.clientManager" />;

export const ClientInfoClientManager = ({ clientManager }) => {
  const detail = clientManager && <User user={clientManager} />;

  return <ClientInfoDetail label={label} detail={detail} />;
};

ClientInfoClientManager.propTypes = {
  clientManager: PropTypes.object
};

export default ClientInfoClientManager;
