import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';

export const GET_VENDOR_DATA = gql`
  query vendor($id: String!) {
    vendor(id: $id) {
      id
      name
      closedDate
      isActive
      slug
    }
  }
`;

export const useVendor = ({ slug }) => {
  const { data, error, loading } = useQuery(GET_VENDOR_DATA, {
    variables: {
      id: slug
    },
    fetchPolicy: 'network-only'
  });

  const vendor = get(data, 'vendor', {});

  return {
    loading,
    error,
    vendor
  };
};

export default useVendor;
