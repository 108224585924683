import React from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import AddIcon from '@material-ui/icons/AddSharp';
import { LoadingButton } from '~/modules/common/components';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { useOnChangeHandlers } from '../hooks/useOnChangeHandlers';
import ResourceAssignment from './ResourceAssignment';

const useStyles = makeStyles(theme => ({
  noResources: {
    fontSize: theme.typography.caption.fontSize,
    opacity: '0.7',
    lineHeight: 0.8
  },
  addButton: {
    color: theme.palette.primary.main,
    padding: 0,
    margin: theme.spacing(1, 0, 0, 0.25)
  },
  buttonContainer: {
    display: 'flex'
  },
  saveCancelButtonContainer: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  errorText: {
    margin: theme.spacing(1.25, 0, 0, 2)
  },
  save: {
    margin: theme.spacing(0, 1.5, 0, 0.25)
  }
}));

export const ResourceAssignmentContent = ({ resourceAssignmentFormik }) => {
  const classes = useStyles();

  const {
    values: { resourceAssignments },
    handleSubmit,
    resetForm,
    isSubmitting,
    dirty,
    errors
  } = resourceAssignmentFormik;

  const onChangeHandlers = useOnChangeHandlers(resourceAssignmentFormik);

  const reachedMaxAssignmentLimit = resourceAssignments.length === 50;

  const { canEditPlanningData } = useProjectRequestObjectPermissions();

  return (
    <>
      {resourceAssignments.length ? (
        resourceAssignments.map((resourceAssignment, entryIndex) => (
          <ResourceAssignment
            key={resourceAssignment.rowId}
            resourceAssignment={resourceAssignment}
            onChangeHandlers={onChangeHandlers}
            entryIndex={entryIndex}
            canEditPlanningData={canEditPlanningData}
            errors={errors}
            isSubmitting={isSubmitting}
            enableAutoFocus={dirty}
          />
        ))
      ) : (
        <Typography className={classes.noResources} variant="body2">
          <FormattedMessage id="projectRequest.resourceRequirement.noResources" />
        </Typography>
      )}
      {canEditPlanningData && (
        <div className={classes.buttonContainer}>
          <Button
            startIcon={<AddIcon />}
            className={classes.addButton}
            onClick={onChangeHandlers.onAddNewResourceAssignment}
            disabled={reachedMaxAssignmentLimit || isSubmitting}
            data-qe-id="ResourceAssignment_Add"
          >
            <FormattedMessage id="projectRequest.resourceRequirement.addResource" />
          </Button>
          {reachedMaxAssignmentLimit && (
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              <FormattedMessage id="projectRequest.resourceRequirement.reachedMaxAssignmentLimit" />
            </Typography>
          )}
        </div>
      )}
      {dirty && (
        <div className={classes.saveCancelButtonContainer}>
          <LoadingButton
            variant="contained"
            color="primary"
            className={classes.save}
            onClick={handleSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            data-qe-id="ResourceAssignment_Save"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
          <Button
            color="default"
            variant="contained"
            onClick={resetForm}
            disabled={isSubmitting}
            data-qe-id="ResourceAssignment_Cancel"
          >
            <FormattedMessage id="button.cancel" />
          </Button>
        </div>
      )}
    </>
  );
};
ResourceAssignmentContent.propTypes = {
  resourceAssignmentFormik: PropTypes.object
};
export default ResourceAssignmentContent;
