import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  value: {
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

const DimensionValueReadOnly = ({ value }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      tabIndex={0}
      className={classes.value}
      component="div"
    >
      {value}
    </Typography>
  );
};

DimensionValueReadOnly.propTypes = {
  value: PropTypes.string
};

export default DimensionValueReadOnly;
