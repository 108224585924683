import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FilledInput, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ProjectRequestPeriodResolution } from '~/types';
import { SimpleDropdown } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1
  }
}));

const useFilledInputStyles = makeStyles(theme => ({
  root: {
    borderTopLeftRadius: 0
  }
}));

const useDropDownStyles = makeStyles(theme => ({
  select: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: `${theme.spacing(1.625)}px !important`,
    lineHeight: 1.25
  }
}));

export const PeriodResolutionDropdown = ({
  periodResolution,
  onPeriodResolutionChangeHandler,
  disabled,
  periodQuantity,
  dataQeId
}) => {
  const classes = useStyles();
  const filledInputClasses = useFilledInputStyles();
  const dropDownClasses = useDropDownStyles();
  const intl = useIntl();

  const selectProps = useMemo(() => {
    return {
      input: <FilledInput classes={filledInputClasses} />,
      classes: dropDownClasses
    };
  }, [filledInputClasses, dropDownClasses]);

  const options = useMemo(
    () =>
      Object.values(ProjectRequestPeriodResolution).map(period => ({
        id: period,
        displayText: intl.formatMessage(
          { id: `projectRequest.periodResolution.${period}` },
          {
            count: periodQuantity
          }
        )
      })),
    [intl, periodQuantity]
  );

  const selectedValue = useMemo(
    () => options.find(x => x.id === periodResolution),
    [options, periodResolution]
  );

  return (
    <div className={classes.root}>
      <SimpleDropdown
        options={options}
        onChange={onPeriodResolutionChangeHandler}
        noneOption={false}
        value={selectedValue}
        disableSort
        variant="filled"
        selectProps={selectProps}
        disabled={disabled}
        dataQeId={dataQeId}
      />
    </div>
  );
};

PeriodResolutionDropdown.propTypes = {
  periodResolution: PropTypes.string.isRequired,
  onPeriodResolutionChangeHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  periodQuantity: PropTypes.number.isRequired,
  dataQeId: PropTypes.string
};
