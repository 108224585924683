import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { User as UserBase } from '~/modules/common/components';

const useStyles = makeStyles({
  container: {
    overflow: 'hidden'
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export const User = ({ user }) => {
  const classes = useStyles();

  return <UserBase classes={classes} user={user} disablePadding />;
};

User.propTypes = {
  user: PropTypes.object
};

export default User;
