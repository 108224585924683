import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SummaryFormattedNumber } from '~/modules/common/components';
import FieldLabel from './FieldLabel';

export const getVarianceMessageId = ({
  allocationVariance,
  taskResourceUserAllocationId
}) => {
  if (taskResourceUserAllocationId) {
    return allocationVariance > 0
      ? 'taskAllocationEditor.aboveEstimatedAtCompletion'
      : 'taskAllocationEditor.belowEstimatedAtCompletion';
  }

  return allocationVariance > 0
    ? 'taskAllocationEditor.aboveTaskEstimate'
    : 'taskAllocationEditor.belowTaskEstimate';
};

const useSummaryStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    fontWeight: 'bold'
  },
  diffPositive: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

const useStyles = makeStyles(theme => ({
  diff: {
    display: 'flex',
    marginTop: theme.spacing(1),
    alignItems: 'center'
  }
}));

export const getAllocationVariance = ({ estimatedHours, allocatedHours }) =>
  estimatedHours ? allocatedHours - estimatedHours : null;

const AllocationEstimateInfoComponent = ({
  taskResourceUserAllocationId,
  estimatedAtCompletionHours,
  initialEstimatedHours,
  allocatedHours
}) => {
  const summaryClasses = useSummaryStyles();
  const classes = useStyles();

  const variance = getAllocationVariance({
    estimatedHours: taskResourceUserAllocationId
      ? estimatedAtCompletionHours
      : initialEstimatedHours,
    allocatedHours
  });

  return (
    Boolean(variance) && (
      <div className={classes.diff}>
        <SummaryFormattedNumber
          classes={summaryClasses}
          value={variance}
          formattedMessageKey="taskAllocationEditor.diffHours"
          showAddIcon={variance > 0}
          isHigher={variance > 0}
        />
        <FieldLabel
          messageId={getVarianceMessageId({
            allocationVariance: variance,
            taskResourceUserAllocationId
          })}
          color="textSecondary"
          variant="body2"
        />
      </div>
    )
  );
};

AllocationEstimateInfoComponent.propTypes = {
  taskResourceUserAllocationId: PropTypes.string,
  estimatedAtCompletionHours: PropTypes.number,
  initialEstimatedHours: PropTypes.number,
  allocatedHours: PropTypes.number.isRequired
};

export default AllocationEstimateInfoComponent;
