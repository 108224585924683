import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { SearchBox } from '~/modules/common/components';
import useProjectFacets from '~/modules/projects/useFacets';
import { usePortfolioProjectContext } from '../PortfolioProjectsContext';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(2),
    flexGrow: 1
  }
}));

export const ProjectSearchBox = ({ classes }) => {
  const cls = useStyles({ classes });
  const { searchCriteria, setSearchCriteria } = usePortfolioProjectContext();
  const { facets, showCostOverBudgetOptions } = useProjectFacets({
    skipPortfolio: true
  });

  return (
    <div className={cls.root}>
      <SearchBox
        dataQeId="PortfolioProjectsTab_SearchBox"
        facets={facets}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        showCostOverBudgetOptions={showCostOverBudgetOptions}
      />
    </div>
  );
};

ProjectSearchBox.propTypes = {
  classes: PropTypes.object
};

export default ProjectSearchBox;
