import React from 'react';
import { PropTypes } from 'prop-types';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import { ListTablePropTypes } from '~/modules/common/components/ListTable';
import { PROJECT_REQUEST_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectRequestListPageType';
import { ProjectRequestListTable } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/ProjectRequestListTable';
import {
  useEnabledColumns,
  usePageOfProjectRequests,
  useProjectRequestTotals
} from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks';

export const PortfolioProjectRequestList = ({
  isMobile,
  excludePortfolio,
  selectedColumnNames,
  isSortLoading,
  onSortChange,
  sortCriteria,
  searchCriteria,
  summaryFilterState,
  onRowClick,
  selectedProjectRequests,
  listClasses,
  listHeaderClasses,
  isExisting,
  portfolioCurrency
}) => {
  const { enabledColumnGroups, getServiceDataColumns } = useEnabledColumns({
    classes: {},
    projectRequestListPageType: PROJECT_REQUEST_LIST_PAGE_TYPE.PORTFOLIO
  });
  const projectRequestListColumns = getServiceDataColumns({
    isMobile,
    selectedColumnNames,
    fetchPortfolioColumn: isExisting
  });
  const {
    loading,
    loadingMore,
    projectRequests,
    loadMore,
    hasMore
  } = usePageOfProjectRequests({
    sort: sortCriteria,
    columns: projectRequestListColumns,
    searchCriteria,
    summaryFilterState,
    excludePortfolio,
    portfolioCurrency
  });

  const { totals, isTotalsLoading } = useProjectRequestTotals({
    columns: projectRequestListColumns,
    searchCriteria,
    summaryFilterState,
    excludePortfolio,
    portfolioCurrency
  });

  const enhancedProjectRequests = isExisting
    ? projectRequests.map(req => ({
        ...req,
        checked:
          selectedProjectRequests &&
          selectedProjectRequests[req.id] &&
          selectedProjectRequests[req.id].isSelected,
        isExisting
      }))
    : projectRequests;

  return (
    <ErrorBoundary>
      <ProjectRequestListTable
        enabledFields={selectedColumnNames}
        enabledColumnGroups={enabledColumnGroups}
        isLoading={
          (loading && !loadingMore) || isTotalsLoading || isSortLoading
        }
        loadMore={loadMore}
        hasMore={hasMore}
        projectRequests={enhancedProjectRequests}
        totals={totals}
        sortCriteria={sortCriteria}
        onSortChange={onSortChange}
        listClasses={listClasses}
        listHeaderClasses={listHeaderClasses}
        onRowClick={onRowClick}
        isExisting={isExisting}
      />
    </ErrorBoundary>
  );
};
PortfolioProjectRequestList.propTypes = {
  isMobile: PropTypes.bool,
  excludePortfolio: PropTypes.string,
  selectedProjectRequests: PropTypes.object,
  selectedColumnNames: PropTypes.array,
  isSortLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortCriteria: ListTablePropTypes.sort,
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object,
  onRowClick: PropTypes.func,
  listClasses: PropTypes.object,
  listHeaderClasses: PropTypes.object,
  isExisting: PropTypes.bool,
  portfolioCurrency: PropTypes.object
};

export default PortfolioProjectRequestList;
