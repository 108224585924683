import { Divider, Grid, makeStyles, Box, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useMemo, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  FormSection,
  NumberTextField,
  InvoiceTemplateDropdown
} from '~/modules/common/components';
import { SearchableTaxProfileDropdown } from '~/modules/common/components/TaxProfileDropdown';
import { ShowCommentsOnInvoiceCheckBox } from '~/modules/billing-invoicing/common/components';
import {
  useGetCurrenciesQuery,
  useIsBreakpointDown,
  useHasFeatureFlag
} from '~/modules/common/hooks';
import { getError, hasError } from '~/util';
import { useTaxProfiles } from '../dialogs/billing/hooks';
import { CurrencySelect } from '../dialogs/billing/InvoiceCurrencySelect';
import { BillLineItemsByDropdown } from './components';
import { useFormOnChangeHandlers } from './hooks/useFormOnChangeHandlers';
import TaxProfileDetails from './TaxProfileDetails';

export const highlightTarget = event => event.target.select();

const useStyles = makeStyles(theme => ({
  legend: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 1, 0)
  },
  net: {
    padding: theme.spacing(2, 0.5, 0, 0)
  }
}));
const useDropDownStyles = makeStyles(theme => ({
  root: {
    '& label': {
      transform: 'translate(8px, 17px) scale(0.85)'
    },
    '& label[data-shrink="true"]': {
      transform: 'translate(8px, 7px) scale(0.65)'
    }
  },
  valueContainer: {
    alignItems: 'unset',
    height: 22,
    flexWrap: 'nowrap',
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  input: {
    height: '1.1875em',
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: 10,
    width: '100%'
  }
}));

const BillingCardEditable = ({
  billingCardResourceKeys,
  isSubmitting,
  errors
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const isPsaPrpCommentsOnInvoiceEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpCommentsOnInvoiceEnabled'
  });
  const label = resourceKey => <FormattedMessage id={resourceKey} />;
  const classes = useStyles();
  const dropDownClasses = useDropDownStyles();

  const { currencies } = useGetCurrenciesQuery();
  const [taxProfileSearchTerm, setTaxProfileSearchTerm] = useState('');
  const handleInputChange = useCallback(
    (_, v) => setTaxProfileSearchTerm(v),
    []
  );
  const { loading, taxProfiles } = useTaxProfiles({ taxProfileSearchTerm });
  const paymentTermAdornment = useMemo(
    () => (
      <span className={classes.net}>
        {label(billingCardResourceKeys.paymentTermNet)}
      </span>
    ),
    [billingCardResourceKeys.paymentTermNet, classes.net]
  );
  const inputRefCallback = useCallback(
    errorId => inputRef => {
      if (isSubmitting && hasError(errors, errorId) && inputRef)
        inputRef.focus();
    },
    [isSubmitting, errors]
  );

  const { values, setFieldValue } = useFormikContext();

  const {
    invoiceCurrency,
    defaultInvoicePaymentTerm,
    invoiceTemplate,
    taxProfile,
    billLineItemsBy,
    description,
    internalNotes,
    showComments
  } = values;
  const {
    onPaymentTermChange,
    onBillingCurrencyChange,
    onTaxProfileChange,
    onInvoiceTemplateChange,
    onDescriptionChange,
    onInternalNotesChange,
    onShowCommentsOnInvoiceChange
  } = useFormOnChangeHandlers({
    setFieldValue,
    values,
    currencies
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={label(billingCardResourceKeys.schedule)}
          />
        </Grid>
        <Grid item xs={12} sm={4} data-qe-id="PaymentTermDropdown">
          <NumberTextField
            id="paymentTerm"
            variant="filled"
            fullWidth
            label={label(billingCardResourceKeys.paymentTerm)}
            startAdornment={paymentTermAdornment}
            min={0}
            max={25000}
            step={1}
            onChange={onPaymentTermChange}
            value={defaultInvoicePaymentTerm}
          />
        </Grid>
        {isPsaPrpCommentsOnInvoiceEnabled ? (
          <Grid item xs={12} sm={6}>
            <ShowCommentsOnInvoiceCheckBox
              showCommentsLabel={billingCardResourceKeys.showComments}
              showComments={showComments}
              onShowCommentsOnInvoiceChange={onShowCommentsOnInvoiceChange}
              isReadOnly={false}
              dataQeId="EditableShowCommentsOnInvoice"
            />
          </Grid>
        ) : null}
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={label(billingCardResourceKeys.structure)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <BillLineItemsByDropdown
            billLineItemOptions={billLineItemsBy}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={label(billingCardResourceKeys.defaults)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencySelect
            currencies={currencies}
            value={invoiceCurrency}
            onChange={onBillingCurrencyChange}
          />
        </Grid>
        <>
          <Grid item xs={12} sm={4} data-qe-id="TaxProfileDropdown">
            <SearchableTaxProfileDropdown
              classes={dropDownClasses}
              loading={loading}
              options={taxProfiles}
              onChange={onTaxProfileChange}
              label={label(billingCardResourceKeys.taxProfile)}
              value={taxProfile}
              onInputChange={handleInputChange}
            />

            {taxProfile ? <TaxProfileDetails taxProfile={taxProfile} /> : null}
          </Grid>
        </>
        <Box width="100%" />
        <Grid item xs={12} sm={4} data-qe-id="InvoiceTemplateDropdown">
          <InvoiceTemplateDropdown
            value={invoiceTemplate}
            onChange={onInvoiceTemplateChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="bill.description"
            variant="filled"
            fullWidth
            inputRef={inputRefCallback('description')}
            label={label(billingCardResourceKeys.description)}
            value={description ?? ''}
            onChange={onDescriptionChange}
            error={hasError(errors, 'description')}
            helperText={getError(errors, 'description')}
            data-qe-id="Description"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="bill.internalNotes"
            variant="filled"
            multiline
            maxrows={isMobile ? 2 : 3}
            fullWidth
            inputRef={inputRefCallback('internalNotes')}
            label={label(billingCardResourceKeys.internalNotes)}
            value={internalNotes ?? ''}
            onChange={onInternalNotesChange}
            data-qe-id="InternalNotes"
          />
        </Grid>
      </Grid>
    </>
  );
};

BillingCardEditable.propTypes = {
  billingCardResourceKeys: PropTypes.any,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired
};

export default BillingCardEditable;
