import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  getReactMoneyFormatFromMe,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me/useMeContext';
import { OverviewItemTooltip } from '~/modules/common/charts/dashboard';
import { isNullOrUndefined } from '~/modules/common/util';
import useAccordionStyles from '../../useAccordionStyles';

const totalResourceCount = initialValues => ({
  count: Number.parseFloat(
    initialValues
      .reduce((acc, obj) => {
        return acc + obj.quantity;
      }, 0)
      .toFixed(2)
  )
});

export const AccordionHeader = ({
  estimatedCost,
  expanded,
  resourceAssignments
}) => {
  const { titleClass, summaryContainerClass } = useAccordionStyles();
  const { me, permissionsMap } = useMeContext();

  const moneySettings = getReactMoneyFormatFromMe(me);
  const numberSettings = getReactNumberFormatFromMe(me);

  const canViewRole = Boolean(
    permissionsMap['urn:replicon:project-role-action:view-project-role']
  );

  return (
    <div className={summaryContainerClass}>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={titleClass}
      >
        <FormattedMessage id="projectRequest.planning" />
      </Typography>
      {expanded ? null : (
        <Typography variant="body2">
          {canViewRole && resourceAssignments.length ? (
            <>
              <FormattedMessage
                id="projectRequest.resourcesCount"
                values={totalResourceCount(resourceAssignments)}
              />
              {!isNullOrUndefined(estimatedCost?.amount) && ', '}
            </>
          ) : null}
          {!isNullOrUndefined(estimatedCost?.amount) && (
            <OverviewItemTooltip
              currencySymbol={
                estimatedCost?.currency?.displayText ||
                estimatedCost?.currency?.symbol
              }
              value={estimatedCost.amount}
              moneySettings={moneySettings}
              numberSettings={numberSettings}
              intlKey="cost"
              id="projectRequest.costDescription"
            />
          )}
        </Typography>
      )}
    </div>
  );
};
AccordionHeader.propTypes = {
  estimatedCost: PropTypes.object,
  expanded: PropTypes.bool,
  resourceAssignments: PropTypes.array
};
export default AccordionHeader;
