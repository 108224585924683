import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { object } from 'yup';
import { useMeContext } from '~/modules/me/useMeContext';
import { usePutPortfolioBudgetMutation } from './usePutPortfolioBudgetMutation';

const MAX_VALUE = 9999999999;

export const buildValidationSchema = ({ intl }) => {
  return object().shape({
    total: object()
      .nullable()
      .test(
        'total-amount-max-value',
        message => {
          return intl.formatMessage(
            {
              id: 'portfolio.budget.exceedMaxAllowedValue'
            },
            { max: MAX_VALUE }
          );
        },
        function test(value) {
          const { isCapexOpexMode } = this.parent;

          if (!isCapexOpexMode && value?.amount >= MAX_VALUE) return false;

          return true;
        }
      ),
    capital: object()
      .nullable()
      .test(
        'capital-amount-max-value',
        message => {
          return intl.formatMessage(
            {
              id: 'portfolio.budget.exceedMaxAllowedValue'
            },
            { max: MAX_VALUE }
          );
        },
        function test(value) {
          const { isCapexOpexMode } = this.parent;

          if (isCapexOpexMode && value?.amount >= MAX_VALUE) return false;

          return true;
        }
      ),
    operational: object()
      .nullable()
      .test(
        'operational-amount-max-value',
        message => {
          return intl.formatMessage(
            {
              id: 'portfolio.budget.exceedMaxAllowedValue'
            },
            { max: MAX_VALUE }
          );
        },
        function test(value) {
          const { isCapexOpexMode } = this.parent;

          if (isCapexOpexMode && value?.amount >= MAX_VALUE) return false;

          return true;
        }
      )
  });
};

export const usePutPortfolioBudgetForm = ({
  budget = {},
  portfolioCurrency,
  portfolioId,
  onClose,
  canViewPlanningData
}) => {
  const intl = useIntl();
  const { putPortfolioBudget } = usePutPortfolioBudgetMutation({
    portfolioId,
    canViewPlanningData
  });
  const { baseCurrency } = useMeContext();
  const initialState = {
    total: budget && budget.total,
    capital: budget && budget.capital,
    operational: budget && budget.operational,
    budgetCurrency: portfolioCurrency || budget.total?.currency || baseCurrency,
    isCapexOpexMode: budget && Boolean(budget.capital || budget.operational)
  };
  const validationSchema = useMemo(() => buildValidationSchema({ intl }), [
    intl
  ]);
  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);
      const {
        total,
        operational,
        capital,
        budgetCurrency,
        isCapexOpexMode
      } = values;

      await putPortfolioBudget({
        budget: isCapexOpexMode
          ? {
              operational: operational?.amount,
              capital: capital?.amount
            }
          : { total: total?.amount || 0 },
        budgetCurrency: {
          id: budgetCurrency.id
        }
      });

      onClose();
    },
    [putPortfolioBudget, onClose]
  );

  return { initialState, onSubmit, validationSchema };
};

export default usePutPortfolioBudgetForm;
