import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DateFormatter from '../../AvailableToBillListTable/columns/formatters/date';

const useStyles = makeStyles(theme => ({
  name: {
    fontWeight: theme.typography.fontWeightBold
  },
  header: {
    padding: theme.spacing(1)
  },
  date: {
    color: theme.palette.text.secondary
  },
  chip: {
    marginRight: '0px'
  }
}));

export const MobileAvailableToBillHeaderRow = ({ record }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.header}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography className={classes.name} variant="body2" component="div">
          <FormattedMessage id="mobileAvailableToBillHeaderRow.availableToBill" />
        </Typography>
        <div className={classes.date}>
          {DateFormatter({
            record,
            variant: 'caption'
          })}
        </div>
      </Grid>
    </Grid>
  );
};

MobileAvailableToBillHeaderRow.propTypes = {
  record: PropTypes.object
};

export default MobileAvailableToBillHeaderRow;
