import React from 'react';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { retryLoading } from '~/util';
import { MyWorkIcon } from '~/modules/common/components/Icons';

const MyWorkPage = React.lazy(() =>
  retryLoading(() => import('./components/MyWorkPage'))
);

const myWorkRoute = ({ intl, me }) => ({
  path: '/my-work',
  icon: MyWorkIcon,
  isSideBarRoute: true,
  title: () => intl.formatMessage({ id: 'routes.myWork' }),
  component: MyWorkPage,
  hasPermission: me.userAccessRoles.includes(USER_ACCESS_ROLE.PROJECT_RESOURCE),
  showDivider: Boolean(
    !me.permissions.find(
      p => p.permissionActionUri === 'urn:replicon:project-action:view-project'
    ) &&
      !me.permissions.find(
        p => p.permissionActionUri === 'urn:replicon:project-action:view-tasks'
      ) &&
      !me.userAccessRoles.includes(USER_ACCESS_ROLE.PROJECT_MANAGER)
  )
});

export default myWorkRoute;
