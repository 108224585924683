import { getRoutePermissions } from '~/modules/common/permissions';
import PortfolioPage from './PortfolioPage';

export const portfolioRoute = ({ me }) => {
  const { canViewPortfolios } = getRoutePermissions(me);

  return {
    path: '/portfolios/:slug',
    icon: () => null,
    hidden: true,
    component: PortfolioPage,
    hasPermission: canViewPortfolios
  };
};
