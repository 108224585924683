import get from 'lodash.get';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {
  billPlanOverviewDetails,
  mapScaleToPeriodResolution,
  billPlanOverviewObject
} from '~/modules/billing-invoicing/common/components/BillingInvoicingTab/enhancers';

export const GET_CLIENT_BILLPLAN_OVERVIEW = gql`
  query getClientBillPlanOverview($slug: String!, $periodResolutionUri: String!, $dateRange: DateRangeInput2, $overviewMode: BillPlanOverviewMode!) {
    client(slug: $slug) {
      id
      ${billPlanOverviewDetails}
    }
  }
`;

export const useClientBillPlanOverview = ({
  dateRange,
  scale,
  client: { slug },
  overviewMode
}) => {
  const { data, loading } = useQuery(GET_CLIENT_BILLPLAN_OVERVIEW, {
    variables: {
      slug,
      dateRange: {
        startDate: dateRange.startDate.toISODate(),
        endDate: dateRange.endDate.toISODate()
      },
      periodResolutionUri: mapScaleToPeriodResolution(scale),
      overviewMode
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const result = get(data, 'client.billPlanOverview') || {};
  const dataset = billPlanOverviewObject(result);

  return { ...dataset, loading };
};

export default useClientBillPlanOverview;
