import { gql } from 'graphql-tag';

export const GET_PROJECTS_QUERY = gql`
  query getProjects(
    $page: Int!
    $pageSize: Int!
    $projectFilter: ProjectFilter2
  ) {
    projects(page: $page, pagesize: $pageSize, projectFilter: $projectFilter) {
      id
      startDate
      endDate
    }
  }
`;
