import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { isNumeric } from '~/modules/common/numbers';
import {
  getDateRangeFromStartDateChangeEvent,
  getDateRangeFromEndDateChangeEvent,
  shouldExcludeDay,
  buildUpdatedScheduleRules,
  getTotalHoursForDateRangeFromScheduleRules
} from '~/modules/resourcing/common/util';

export const handleExtendEndDate = ({
  scheduleRules,
  setScheduleRules,
  defaultScheduleRule,
  startDate,
  endDate,
  totalHours,
  quantity,
  setEndDate,
  setLoad,
  setIsAdjustedLoading
}) => {
  const {
    do: { setHours, excludeWeekdays }
  } = defaultScheduleRule;

  const overrideSchedules = [
    {
      dateRange: { startDate, endDate },
      do: { ...defaultScheduleRule.do, load: 100 }
    }
  ];

  const hoursWithActualLoad = getTotalHours({
    startDate,
    endDate,
    scheduleRules: overrideSchedules,
    quantity
  });

  const start = mapIsoStringtoUtcObject(startDate);
  let end = mapIsoStringtoUtcObject(endDate);

  const diffHours = Math.abs(totalHours - hoursWithActualLoad);
  let diffDays = Math.abs(diffHours / (quantity * setHours));

  const isIncrementDate = totalHours > hoursWithActualLoad;

  let isEndDateYetoBeMatched = diffDays > 0;

  while (isEndDateYetoBeMatched) {
    end = end.plus({ days: isIncrementDate ? 1 : -1 });

    if (!shouldExcludeDay(end, excludeWeekdays)) {
      --diffDays;
    }

    if (diffDays <= 0) isEndDateYetoBeMatched = false;
  }

  const newScheduleRules = buildUpdatedScheduleRules({
    start,
    end,
    scheduleRules,
    defaultScheduleRule
  });

  const newWorkHours = getTotalHours({
    startDate,
    endDate: newScheduleRules[newScheduleRules.length - 1].dateRange.endDate,
    scheduleRules: newScheduleRules,
    quantity
  });

  const newLoad = (totalHours * 100) / newWorkHours;

  const updatedScheduleRules = newScheduleRules.map(s => ({
    ...s,
    do: { ...s.do, load: newLoad }
  }));

  setScheduleRules(updatedScheduleRules);
  setLoad(newLoad);
  setIsAdjustedLoading(false);
  setEndDate(end.toISO());
};

const getTotalHours = ({ startDate, endDate, scheduleRules, quantity }) => {
  const periodHours = getTotalHoursForDateRangeFromScheduleRules({
    start: mapIsoStringtoUtcObject(startDate),
    end: mapIsoStringtoUtcObject(endDate),
    scheduleRules
  });

  return isNumeric(periodHours) ? periodHours * (quantity || 0) : null;
};

export const handleTotalHoursChange = ({
  newTotalHours,
  startDate,
  endDate,
  quantity,
  defaultScheduleRule,
  values,
  setValues
}) => {
  const overideSchedules = [
    {
      dateRange: { startDate, endDate },
      do: { ...defaultScheduleRule.do, load: 100 }
    }
  ];
  const totalHours = getTotalHours({
    startDate,
    endDate,
    scheduleRules: overideSchedules,
    quantity
  });

  const newLoad =
    newTotalHours > 0 && totalHours > 0
      ? (newTotalHours * 100.0) / totalHours
      : 0;

  const newScheduleRules = overideSchedules.map(s => ({
    ...s,
    do: { ...s.do, load: newLoad }
  }));

  setValues({
    ...values,
    load: newLoad,
    totalHours: newTotalHours,
    scheduleRules: newScheduleRules,
    isAdjustedLoading: false
  });
};

export const handleDateRangeChange = ({
  scheduleRules,
  startDate,
  endDate,
  load,
  values,
  setValues,
  quantity,
  defaultScheduleRule
}) => {
  const newScheduleRules = buildUpdatedScheduleRules({
    start: mapIsoStringtoUtcObject(startDate),
    end: mapIsoStringtoUtcObject(endDate),
    scheduleRules,
    defaultScheduleRule: {
      ...defaultScheduleRule,
      do: { ...defaultScheduleRule.do, load }
    }
  });

  const totalHours = getTotalHours({
    startDate,
    endDate,
    scheduleRules: newScheduleRules,
    quantity
  });

  setValues({
    ...values,
    isAdjustedLoading: false,
    scheduleRules: newScheduleRules,
    startDate,
    endDate,
    totalHours
  });
};

export const handleEndDateChange = ({
  scheduleRules,
  newEndDate,
  startDate,
  values,
  setValues,
  load,
  quantity,
  defaultScheduleRule
}) => {
  const newDateRange = getDateRangeFromEndDateChangeEvent(newEndDate, {
    start: startDate
  });

  handleDateRangeChange({
    scheduleRules,
    setValues,
    values,
    load,
    quantity,
    defaultScheduleRule,
    ...newDateRange
  });
};

export const handleStartDateChange = ({
  scheduleRules,
  newStartDate,
  endDate,
  values,
  setValues,
  load,
  quantity,
  defaultScheduleRule
}) => {
  const newDateRange = getDateRangeFromStartDateChangeEvent(newStartDate, {
    end: endDate
  });

  handleDateRangeChange({
    scheduleRules,
    load,
    quantity,
    defaultScheduleRule,
    values,
    setValues,
    ...newDateRange
  });
};

export const handleLoadChange = ({
  newLoad,
  quantity,
  startDate,
  endDate,
  defaultScheduleRule,
  values,
  setValues
}) => {
  const newScheduleRules = [
    {
      dateRange: {
        startDate,
        endDate
      },
      do: { ...defaultScheduleRule.do, load: newLoad }
    }
  ];

  const totalHours = getTotalHours({
    startDate,
    endDate,
    scheduleRules: newScheduleRules,
    quantity
  });

  setValues({
    ...values,
    isAdjustedLoading: false,
    scheduleRules: newScheduleRules,
    totalHours,
    load: newLoad
  });
};

export const handleQuantityChange = ({
  scheduleRules,
  newQuantity,
  startDate,
  endDate,
  load,
  values,
  setValues
}) => {
  const totalHours = getTotalHours({
    startDate,
    endDate,
    scheduleRules,
    quantity: newQuantity,
    load
  });

  setValues({
    ...values,
    totalHours,
    quantity: newQuantity,
    isAdjustedLoading: false
  });
};

export const handleTotalRequestedCostChange = ({
  newTotalRequestedCost,
  values,
  setValues
}) => {
  const { roleRate, totalHours } = values;

  setValues({
    ...values,
    roleRate:
      totalHours === 0
        ? roleRate || 0
        : (newTotalRequestedCost || 0) / totalHours
  });
};

export const handleRoleRateChange = ({
  newRoleRate,
  values,
  setValues,
  decimalScale
}) => {
  const { roleRate } = values;

  const precisionBuffer = 3;
  const precision = Math.pow(10, decimalScale + precisionBuffer);

  const roleRatePrecision = Math.round(roleRate * precision);
  const newRoleRatePrecision = Math.round(newRoleRate * precision);

  if (
    Math.abs(roleRatePrecision - newRoleRatePrecision) >=
    Math.pow(10, precisionBuffer)
  ) {
    setValues({
      ...values,
      roleRate: newRoleRate
    });
  }
};

export default () => ({
  handleQuantityChange,
  handleLoadChange,
  handleExtendEndDate,
  handleTotalHoursChange,
  handleStartDateChange,
  handleEndDateChange,
  handleTotalRequestedCostChange,
  handleRoleRateChange
});
