import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { DelaStarIcon } from '~/modules/common/components';
import useStyles from './useStyles';
import useDelaStarIconStyles from './useDelaStarIconStyles';

export const ResourceRequestChartBanner = ({ messageId, children }) => {
  const classes = useStyles();
  const delaStarIconClasses = useDelaStarIconStyles();

  return (
    <div className={classes.root}>
      <DelaStarIcon classes={delaStarIconClasses} />
      <Typography variant="subtitle1" color="primary">
        <FormattedMessage id={messageId} />
      </Typography>
      {children && <div className={classes.buttons}>{children}</div>}
    </div>
  );
};

ResourceRequestChartBanner.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.node
};
export default ResourceRequestChartBanner;
