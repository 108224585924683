import { useColumns } from '~/modules/common/components/ListTable';
import { MultiCurrencyMoney } from '~/modules/common/components/ListTable/renderers';
import { buildFooters } from './footers';

const moneyColumnFormatter = {
  amount: MultiCurrencyMoney
};

export const useListFooters = (
  classes,
  editable,
  isPsaPrpManualBulkBillCreationEnabled
) =>
  useColumns({
    columns: buildFooters(
      classes,
      editable,
      isPsaPrpManualBulkBillCreationEnabled
    ),
    renders: moneyColumnFormatter
  });
