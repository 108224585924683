import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './useStyles';
import SidePanelContext, { useSidePanelContext } from './SidePanelContext';

export const withSidePanelStateProvider = BaseComponent => props => {
  const [sidePanelContext, setSidePanelContext] = useState({});
  const value = useMemo(() => ({ sidePanelContext, setSidePanelContext }), [
    sidePanelContext,
    setSidePanelContext
  ]);

  return (
    <SidePanelContext.Provider value={value}>
      <BaseComponent {...props} />
    </SidePanelContext.Provider>
  );
};

export const SidePanel = () => {
  const classes = useStyles();
  const {
    sidePanelContext: { Component, ...context } = {}
  } = useSidePanelContext();
  const open = Boolean(Component);

  const cssClasses = useMemo(() => ({ paper: classes.drawerPaper }), [
    classes.drawerPaper
  ]);

  return (
    <Drawer
      className={classNames(classes.hideDrawer, {
        [classes.drawer]: open,
        [classes.drawerShift]: !open
      })}
      variant="persistent"
      anchor="right"
      open={open}
      classes={cssClasses}
    >
      {open ? <Component {...context} /> : <div />}
    </Drawer>
  );
};

export default SidePanel;
