import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import { STRING_MAX_LENGTH, exceededMaxLengthMessage } from './common';

const CODE_MAX_LENGTH = 50;

const nameRequiredMessage = <FormattedMessage id="client.nameRequired" />;
const codeExceededMaxLengthMessage = (
  <FormattedMessage
    id="client.fieldExceededMaxLength"
    values={{ max: CODE_MAX_LENGTH }}
  />
);

export const basicInfoValidationSchema = object().shape({
  name: string()
    .trim()
    .max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
    .required(nameRequiredMessage),
  code: string().max(CODE_MAX_LENGTH, codeExceededMaxLengthMessage),
  comment: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
});
