import React from 'react';
import PropTypes from 'prop-types';
import { ResourceRequestStatus } from '~/types';
import ResourceRequestChartBanner from './ResourceRequestChartBanner';
import ResourceRequestProposeAllButton from './ResourceRequestProposeAllButton';

export const ResourceRequestProposeAllChartBanner = ({ resourceRequests }) => {
  const submittedResourceRequestUrisWithMatches = resourceRequests
    .filter(
      resourceRequest =>
        resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
        resourceRequest.resourceMatches?.length
    )
    .map(resourceRequest => resourceRequest.id);

  return (
    <>
      {submittedResourceRequestUrisWithMatches.length > 0 && (
        <ResourceRequestChartBanner messageId="resourceRequestChartBanner.delaProposeQuestion">
          <ResourceRequestProposeAllButton
            resourceRequestUris={submittedResourceRequestUrisWithMatches}
          />
        </ResourceRequestChartBanner>
      )}
    </>
  );
};

ResourceRequestProposeAllChartBanner.propTypes = {
  resourceRequests: PropTypes.array.isRequired
};
export default ResourceRequestProposeAllChartBanner;
