import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { getTodayForUser } from '~/modules/common/dates/today';
import { useMeContext } from '~/modules/me/useMeContext';
import { useTaxRatesForTaxProfile } from '../dialogs/billing/hooks';
import ClientTaxRatesListTable from './ClientTaxRatesListTable';

const TaxProfileDetails = ({ taxProfile }) => {
  const me = useMeContext();

  const {
    loading: taxRatesLoading,
    taxRateRows,
    taxRateHeaders
  } = useTaxRatesForTaxProfile({
    taxProfileUri: taxProfile && taxProfile.id,
    asOfDate: getTodayForUser(me)
  });

  const noOfTaxTypes = { numberOfTaxTypes: taxRateRows.length };

  return (
    <>
      <Grid item xs={12} data-qe-id="TaxRatesTable">
        <Typography variant="caption">
          <FormattedMessage id="client.numberOfTaxType" values={noOfTaxTypes} />
        </Typography>
        {noOfTaxTypes.numberOfTaxTypes ? (
          <Typography variant="caption">
            <ClientTaxRatesListTable
              taxRatesLoading={taxRatesLoading}
              taxRateHeaders={taxRateHeaders}
              taxRateRows={taxRateRows}
            />
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

TaxProfileDetails.propTypes = {
  taxProfile: PropTypes.object
};

export default TaxProfileDetails;
