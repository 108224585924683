import React from 'react';
import { retryLoading } from '~/util';
import { getRoutePermissions } from '../common/permissions';
import { ProjectRequestIcon } from './ProjectRequestPage/components';

const ProjectRequestPage = React.lazy(() =>
  retryLoading(() => import('./ProjectRequestPage'))
);

export const projectRequestRoute = ({ intl, me }) => {
  const { canViewProjectRequests } = getRoutePermissions(me);

  return {
    path: '/project-request',
    icon: () => <ProjectRequestIcon />,
    isSideBarRoute: true,
    title: () => intl.formatMessage({ id: 'routes.projectRequests' }),
    showDivider: canViewProjectRequests,
    component: ProjectRequestPage,
    hidden: !canViewProjectRequests,
    hasPermission: canViewProjectRequests
  };
};
