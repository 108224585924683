import { useLoadMore } from '~/modules/common/hooks/useLoadMoreResults';
import { computeTableColumns } from '~/modules/common/components/ListTable';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { ProjectManagementType } from '~/types';
import useSetListSort from '~/modules/common/components/ListTable/useSetListSort';
import { useGetProjectsQuery } from '~/modules/projects';
import { useProjectsColumnSelection } from '~/modules/common/hooks/project';
import { usePortfolioProjectContext } from '../../../../PortfolioProjectsContext';
import { PORTFOLIO_PROJECTS_DIALOG_TABLE_KEY } from '../../../portfolioProjectsDialogTableSettings';
import renders from '../columnRenders';
import { GET_PROJECTS_TO_ADD_TO_PORTFOLIO_QUERY } from '../getProjectsToAddToPortfolioQuery';

const DEFAULT_PAGE_SIZE = 50;

const mapSortToSortExpression = ({ sort }) =>
  sort
    ? {
        direction: sort.direction,
        field: sort.field
      }
    : null;

export const useProjectListProps = ({
  portfolioId,
  selectedProjects,
  enabledColumnGroups,
  enabledColumns,
  sort,
  headerClasses,
  pageSize = DEFAULT_PAGE_SIZE
}) => {
  const tagFieldUris = enabledColumns.filter(c =>
    /^urn:replicon-tenant:[^:]+:object-extension-tag-definition:.*$/.exec(c)
  );

  const { projectType, searchCriteria } = usePortfolioProjectContext();

  const { headers, columns } = computeTableColumns({
    columnSpec: enabledColumnGroups,
    renders,
    defaultRender: renders.tag
  })({
    enabledFields: enabledColumns,
    columnClasses: {},
    headerClasses
  });

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    excludePortfolios: [portfolioId]
  });

  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey: PORTFOLIO_PROJECTS_DIALOG_TABLE_KEY,
    currentColumns: enabledColumns,
    currentSort: sort
  });

  const isManaged = projectType
    ? projectType === ProjectManagementType.Managed
    : null;

  const columnArguments = useProjectsColumnSelection({
    enabledFields: enabledColumns
  });

  const { loading, fetchMore, data, networkStatus } = useGetProjectsQuery(
    {
      variables: {
        page: 1,
        pageSize,
        projectFilter,
        isManaged,
        sort: mapSortToSortExpression({ sort }),
        searchCriteria,
        tagIds: tagFieldUris,
        ...columnArguments
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    },
    GET_PROJECTS_TO_ADD_TO_PORTFOLIO_QUERY
  );

  const projects = (data && data.projects) || [];
  const { loadMore, hasMore } = useLoadMore({
    variables: { pageSize: DEFAULT_PAGE_SIZE },
    fetchMore,
    fetchMoreResultPath: 'projects',
    loading,
    networkStatus,
    initialHasMore: projects.length % DEFAULT_PAGE_SIZE === 0
  });

  const enhancedProjects = projects.map(p => ({
    ...p,
    checked: selectedProjects[p.id] && selectedProjects[p.id].selected
  }));

  const isLoading = loading || sortLoading;

  return {
    isLoading,
    loadMore,
    onSortChange,
    headers,
    columns,
    projects: enhancedProjects,
    hasMore
  };
};
