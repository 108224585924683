import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const ACCEPT_TOP_MATCHES = gql`
  mutation acceptTopMatches(
    $projectUri: String!
    $resourceRequestUris: [String!]!
  ) {
    acceptTopMatches(
      projectUri: $projectUri
      resourceRequestUris: $resourceRequestUris
    )
  }
`;

export const useAcceptTopMatches = () => {
  const [acceptTopMatches] = useMutation(ACCEPT_TOP_MATCHES);

  return {
    acceptTopMatches: ({ projectUri, resourceRequestUris }) =>
      acceptTopMatches({
        variables: {
          projectUri,
          resourceRequestUris
        },
        refetchQueries: ['resourceRequestWithTimeoff']
      })
  };
};

export default useAcceptTopMatches;
