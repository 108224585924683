import { useState, useEffect } from 'react';

export const useOfflineState = () => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);
  const updateNetwork = () => {
    setIsOffline(!window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return { isOffline };
};

export default useOfflineState;
