import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { DeletePortfolioResult } from '~/types';

const DELETE_PORTFOLIO = gql`
  mutation deletePortfolioMutation($id: String!) {
    deletePortfolio(id: $id)
  }
`;

export const useDeletePortfolioHandler = ({
  portfolioId,
  canDeletePortfolio
}) => {
  const history = useHistory();
  const [deletePortfolio] = useMutation(DELETE_PORTFOLIO, {
    onCompleted: () => {
      canDeletePortfolio && history.push(`/portfolios`);
    }
  });

  return {
    deletePortfolio: async () => {
      await deletePortfolio({
        variables: {
          id: portfolioId
        },
        skip: !portfolioId,
        refetchQueries: canDeletePortfolio ? [] : ['portfolio'],
        update(cache, mutationResponse) {
          if (
            mutationResponse?.data?.deletePortfolio ===
            DeletePortfolioResult.Deleted
          ) {
            const cacheId = cache.identify({
              __typename: 'Portfolio',
              id: portfolioId
            });

            if (cacheId) {
              cache.evict({ id: cacheId });
              cache.gc();
            }
          }
        }
      });
    }
  };
};

export default useDeletePortfolioHandler;
