import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import { ProgressIcon } from '~/modules/common/components';
import { ChartHeader } from '~/modules/common/charts/dashboard';
import { ProgressTypes } from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';
import useProjectsCount from '../../hooks/useProjectsCount';
import NoProject from '../NoProject';
import DonutChart from './DonutChart';

const useCardStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    overflow: 'visible'
  }
}));

const useCardContentStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    '&:last-child': {
      paddingBottom: '16px'
    }
  }
}));

const title = (
  <FormattedMessage id="portfolio.projectsOverBudgetProgressCard.chartTitle" />
);
const avatar = <ProgressIcon />;

const ProjectsOverBudgetCard = ({ portfolioId, canViewCostData }) => {
  const cardClasses = useCardStyles();
  const cardContentClasses = useCardContentStyles();

  const {
    loading: totalCountLoading,
    count: totalProjectsCount
  } = useProjectsCount({ portfolioId });

  const totalCountLoadingOrNoProject =
    totalCountLoading || totalProjectsCount === 0;

  const {
    loading: overCostCountLoading,
    count: overCostProjectsCount
  } = useProjectsCount({
    type: ProgressTypes.COST,
    portfolioId,
    skip: !canViewCostData || totalCountLoadingOrNoProject
  });

  const {
    loading: overHoursCountLoading,
    count: overHoursProjectsCount
  } = useProjectsCount({
    type: ProgressTypes.HOURS,
    portfolioId,
    skip: totalCountLoadingOrNoProject
  });

  const {
    loading: pastDateCountLoading,
    count: pastDateProjectsCount
  } = useProjectsCount({
    type: ProgressTypes.DATE,
    portfolioId,
    skip: totalCountLoadingOrNoProject
  });

  const loading =
    totalCountLoading ||
    overCostCountLoading ||
    overHoursCountLoading ||
    pastDateCountLoading;

  return (
    <Card classes={cardClasses}>
      <ChartHeader title={title} avatar={avatar} />
      <CardContent classes={cardContentClasses}>
        {!totalCountLoading && totalProjectsCount === 0 ? (
          <NoProject />
        ) : (
          <>
            {canViewCostData ? (
              <DonutChart
                type={ProgressTypes.COST}
                totalProjectsCount={totalProjectsCount}
                overProjectsCount={overCostProjectsCount}
                loading={loading}
              />
            ) : null}
            <DonutChart
              type={ProgressTypes.HOURS}
              totalProjectsCount={totalProjectsCount}
              overProjectsCount={overHoursProjectsCount}
              loading={loading}
            />
            <DonutChart
              type={ProgressTypes.DATE}
              totalProjectsCount={totalProjectsCount}
              overProjectsCount={pastDateProjectsCount}
              loading={loading}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

ProjectsOverBudgetCard.propTypes = {
  portfolioId: PropTypes.string,
  canViewCostData: PropTypes.bool
};

export default ProjectsOverBudgetCard;
