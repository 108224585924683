import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  select: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const approvalStatus = {
  any: 'Any',
  approved: 'Approved'
};

const resourceKeyByOptionId = {
  [approvalStatus.any]: 'allowedExpensesAndEstimates.approvalStatus.any',
  [approvalStatus.approved]:
    'allowedExpensesAndEstimates.approvalStatus.onlyApproved'
};

const getOptions = ({ formatMessage }) =>
  [approvalStatus.any, approvalStatus.approved].map(id => ({
    id,
    displayText: formatMessage({ id: resourceKeyByOptionId[id] })
  }));

export const ApprovalStatusDropdown = ({ value: val, onChange, disabled }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const dropdownClasses = useMemo(
    () => ({
      container: classes.container,
      select: classes.select
    }),
    [classes.container, classes.select]
  );

  const value = useMemo(
    () => ({
      id: val,
      displayText: formatMessage({ id: resourceKeyByOptionId[val] })
    }),
    [formatMessage, val]
  );

  return (
    <SimpleDropdown
      variant="outlined"
      options={getOptions({ formatMessage })}
      onChange={onChange}
      value={value}
      classes={dropdownClasses}
      noneOption={false}
      labelWidth={200}
      disableSort
      disabled={disabled}
      label={formatMessage({ id: 'allowedExpensesAndEstimates.billExpenses' })}
    />
  );
};

ApprovalStatusDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default ApprovalStatusDropdown;
