import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS } from '~/modules/project-request/enums';
import { getSummaryItems } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestsSummary/useQuickFilterSummaryTabs';

const usePortfolioProjectRequestQuickFilterSummaryTabs = ({
  projectRequestsCountSummary = {},
  subPortfoliosProjectRequestCountSummary = {},
  filterByAccess = PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.THIS_PORTFOLIO
} = {}) => {
  const counts =
    filterByAccess ===
    PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.THIS_PORTFOLIO
      ? projectRequestsCountSummary
      : subPortfoliosProjectRequestCountSummary;

  return {
    allItems: [
      {
        key: PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.THIS_PORTFOLIO,
        label: (
          <FormattedMessage id="projectRequest.summaryTabs.thisPortfolio" />
        ),
        value: projectRequestsCountSummary.all,
        dataQeId: 'PortfolioProjectRequestsSummary_This'
      },
      {
        key: PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS,
        label: (
          <FormattedMessage id="projectRequest.summaryTabs.subPortfolios" />
        ),
        value: subPortfoliosProjectRequestCountSummary.all,
        dataQeId: 'PortfolioProjectRequestsSummary_SubPortfolio'
      }
    ],
    ...getSummaryItems({ projectRequestsCountSummary: counts })
  };
};

export default usePortfolioProjectRequestQuickFilterSummaryTabs;
