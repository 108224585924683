export const mapResourceAssignmentSearchFilter = (
  { keywords } = { keywords: [] }
) => {
  return {
    searchText: keywords && keywords.length > 0 ? keywords.join(' ') : null
  };
};

export const useResourceAssignmentSearchFilter = ({ searchCriteria }) => {
  const searchFilter = mapResourceAssignmentSearchFilter(searchCriteria);

  return searchFilter.searchText ? searchFilter : null;
};
