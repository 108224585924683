import { useMeContext } from '~/modules/me/useMeContext';

const useColumnVisibilityProps = ({
  canViewBillingContracts,
  canEditBillingContracts,
  hasAtleastOneBillableRow
}) => {
  const { hasViewProjectCostType } = useMeContext();
  const isPPMProduct = Boolean(hasViewProjectCostType);
  const shouldShowMarkUp = canViewBillingContracts && hasAtleastOneBillableRow;
  const shouldShowBillableType = canViewBillingContracts;
  const shouldShowDeleteRow = isPPMProduct || canEditBillingContracts;

  return { shouldShowMarkUp, shouldShowBillableType, shouldShowDeleteRow };
};

export default useColumnVisibilityProps;
