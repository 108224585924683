import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const taskBurndownDataQuery = gql`
  query taskBurndownData($taskId: String!, $page: Int!, $pageSize: Int!) {
    taskBurndownData(page: $page, pageSize: $pageSize, taskId: $taskId) {
      date
      hoursWorked
      totalHoursWorkedToDate
      estimatedRemainingHours
      estimatedHoursAtCompletion
      estimatedCompletionDate
    }
  }
`;

export const DEFAULT_PAGE_SIZE = 500;

export const useTaskBurndownDataQuery = ({ task }) => {
  const { data, loading } = useQuery(taskBurndownDataQuery, {
    variables: {
      taskId: task.id,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'network-only'
  });

  const taskBurndownData = get(data, 'taskBurndownData', []);

  return {
    taskBurndownData: (taskBurndownData || []).map(b => ({
      ...b,
      key: b.date.toString()
    })),
    loading
  };
};

export default useTaskBurndownDataQuery;
