import { useProjectsStatusCountSummaryQuery } from '~/modules/common/hooks';
import { ProgressTypes } from '../enums';

const overBudgetFilterMap = {
  [ProgressTypes.COST]: 'costOverbudget',
  [ProgressTypes.HOURS]: 'hoursOverbudget',
  [ProgressTypes.DATE]: 'completionDateOverdue'
};

const useProjectsCount = ({ type, portfolioId, skip }) => {
  const {
    loading,
    projectsStatusCountSummary
  } = useProjectsStatusCountSummaryQuery({
    projectFilter: {
      includeSubPortfolios: true,
      portfolios: [portfolioId],
      overbudget: {
        ...(overBudgetFilterMap[type]
          ? { [overBudgetFilterMap[type]]: true }
          : {})
      }
    },
    skip
  });

  return {
    loading,
    count: projectsStatusCountSummary?.total
  };
};

export default useProjectsCount;
