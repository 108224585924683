import { Grid, TextField } from '@material-ui/core';
import { connect } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ClientManagerSelect } from '~/modules/clients/components';
import { useClientPermissions } from '~/modules/clients/hooks';
import { GridItem } from '~/modules/common/components';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { useMeContext } from '~/modules/me/useMeContext';
import { getError } from '~/util';
import ClientCoManagerSelect from './ClientCoManagerSelect';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const useComanagerChange = setFieldValue =>
  useCallback(
    (users, _) => {
      setFieldValue('comanagers', users || []);
    },
    [setFieldValue]
  );

export const EditClientBasicInfoDialogForm = ({
  clientManagers,
  submitErrors,
  formik: { handleChange, values, errors, setFieldValue }
}) => {
  const { formatMessage } = useIntl();

  const label = useMemo(
    () => ({
      name: formatMessage({ id: 'client.name' }),
      code: formatMessage({ id: 'client.code' }),
      description: formatMessage({ id: 'client.description' })
    }),
    [formatMessage]
  );

  const {
    name,
    code,
    comment,
    clientManager,
    comanagers,
    id: clientId
  } = values;

  const { hasClientManagerRole } = useMeContext();
  const { canEditComanagers } = useClientPermissions();

  const handleClientManagerChange = useCallback(
    value => {
      setFieldValue('clientManager', value);
    },
    [setFieldValue]
  );

  const handleComanagerChange = useComanagerChange(setFieldValue);

  const nameError = getError(errors, 'name');
  const codeError = getError(errors, 'code');
  const commentError = getError(errors, 'comment');

  const formErrors = nameError || codeError || commentError;

  return (
    <>
      {!formErrors && (!isEmpty(errors) || !isEmpty(submitErrors)) && (
        <FormErrorAlert
          errorMessage={
            submitErrors &&
            submitErrors.graphQLErrors &&
            submitErrors.graphQLErrors.length > 0 &&
            submitErrors.graphQLErrors[0].message
          }
        />
      )}
      <Grid container>
        <GridItem rightPadding>
          <TextField
            name="name"
            required
            variant="filled"
            label={label.name}
            value={name}
            fullWidth
            inputProps={{
              ...getAriaLabel(label.name),
              'data-qe-id': 'ClientBasicInfo_NameInput'
            }}
            onChange={handleChange}
            autoFocus
            error={Boolean(nameError)}
            helperText={nameError}
            data-qe-id="ClientInfoName"
          />
        </GridItem>
        <GridItem>
          <TextField
            name="code"
            variant="filled"
            label={label.code}
            value={code}
            inputProps={{
              ...getAriaLabel(label.code),
              'data-qe-id': 'ClientBasicInfo_CodeInput'
            }}
            fullWidth
            onChange={handleChange}
            error={Boolean(codeError)}
            helperText={codeError}
            data-qe-id="ClientInfoCode"
          />
        </GridItem>
        {Boolean(hasClientManagerRole) && (
          <GridItem rightPadding>
            <ClientManagerSelect
              clientManagers={clientManagers}
              value={clientManager}
              onChange={handleClientManagerChange}
            />
          </GridItem>
        )}
        {Boolean(hasClientManagerRole) && canEditComanagers && (
          <GridItem>
            <ClientCoManagerSelect
              clientId={clientId}
              coManagers={comanagers}
              onChange={handleComanagerChange}
            />
          </GridItem>
        )}
        <GridItem fullWidth={canEditComanagers}>
          <TextField
            name="comment"
            variant="filled"
            fullWidth
            inputProps={{
              ...getAriaLabel(label.description),
              'data-qe-id': 'ClientBasicInfo_DescriptionInput'
            }}
            label={label.description}
            value={comment}
            onChange={handleChange}
            error={Boolean(commentError)}
            helperText={commentError}
          />
        </GridItem>
      </Grid>
    </>
  );
};

EditClientBasicInfoDialogForm.propTypes = {
  clientManagers: PropTypes.array,
  submitErrors: PropTypes.object,
  formik: PropTypes.object
};

export default connect(EditClientBasicInfoDialogForm);
