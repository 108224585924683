import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';

export const PROJECT_DROPDOWN_PAGE_SIZE = 100;

export const PROJECT_DROPDOWN_QUERY = gql`
  query ProjectDropdownSearch($filter: ProjectFilter2, $pageSize: Int) {
    projects(page: 1, pagesize: $pageSize, projectFilter: $filter) {
      id
      displayText
    }
  }
`;

const useProjectDropdownOptions = (clientId, noneLabel, filter = {}) => {
  const { query, resultKey } = {
    query: PROJECT_DROPDOWN_QUERY,
    resultKey: 'projects'
  };

  const projectFilter = {
    executionPhase: [
      PROJECT_EXECUTION_PHASE.INITIATE,
      PROJECT_EXECUTION_PHASE.PLANNING,
      PROJECT_EXECUTION_PHASE.EXECUTION
    ]
  };

  if (clientId) {
    projectFilter.clients = [clientId];
  }

  if (filter.text) {
    projectFilter.text = filter.text;
  }

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageSize: PROJECT_DROPDOWN_PAGE_SIZE,
      columns: ['project', 'displayText'],
      filter: projectFilter
    }
  });

  const projects = [{ displayText: noneLabel, id: 'none-option' }];

  return {
    options: loading ? [] : projects.concat(get(data, resultKey, [])),
    loading
  };
};

export default useProjectDropdownOptions;
