import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import EditAllocationUserDialog from '~/modules/resourcing/common/components/ChangeAllocationUserAsOfDateDialog/components/EditAllocationUserDialog';
import {
  HolidaySummaryBlocks,
  TimeOffSummaryBlocks
} from '~/modules/resourcing/common/components';
import {
  useHolidaySummaryPeriods,
  useTimeoffSummaryPeriods
} from '~/modules/resourcing/common/hooks';
import {
  getUserActualTotalHoursByPeriod,
  getUserAllocatedTotalHoursPeriod
} from '~/modules/resourcing/util';
import { RangeBoundaries } from '~/modules/common/charts/timeline/components';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { ResourceAllocationChartTimeline } from './ResourceAllocationChartTimeline';

const useStyles = makeStyles(theme => ({
  timeLinesContainer: {
    display: 'flex',
    flexBasis: theme.spacing(17.5),
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: theme.spacing(12.5)
  }
}));

const useRowStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  }
}));

const useTimeoffHolidaySummaryBlocksStyles = makeStyles(() => ({
  root: {
    height: '100%',
    top: '0px !important'
  },
  container: {
    height: '100%'
  }
}));

const getActualSummaryBlockPopupProps = ({
  roleDisplayText,
  userActualTotalHours,
  userAllocatedTotalHours
}) => ({
  userActualTotalHours,
  userAllocatedTotalHours,
  roleDisplayText
});

export const ResourceAllocationChartTimeLines = ({
  allocatedUser,
  loadingAllocations,
  loadingUserScheduleDetails,
  resourceAllocationsById,
  userScheduleDetails,
  userAllocationDialogOpen,
  newResourceUser,
  closeUserAllocationDialog,
  handleEditResourceAllocation,
  handleRemoveResourceAllocation,
  isEditSwitchEnabled,
  setSnackBarState,
  userActuals,
  isActualsLoading,
  chartDisplayPeriods
}) => {
  const classes = useStyles();
  const rowClasses = useRowStyles();
  const timeOffHolidaySummaryBlocksClasses = useTimeoffHolidaySummaryBlocksStyles();

  const { project } = useProjectContext();
  const { startDate, endDate } = project;

  const {
    chartDates,
    dateRange,
    scale,
    isResourceActualModeEnabled
  } = useResourceRequestToolbarContext();
  const [{ start: chartStartDate }] = chartDates;

  const resourceAllocations = Object.values(resourceAllocationsById);

  const userAllocatedTotalHours = isResourceActualModeEnabled
    ? getUserAllocatedTotalHoursPeriod({
        allocations: resourceAllocations,
        chartDisplayDateRange: dateRange,
        chartDisplayPeriods
      })
    : [];

  const userActualTotalHours = getUserActualTotalHoursByPeriod({
    actualSeriesByProjectRole: userActuals,
    scale
  });

  const loading =
    loadingUserScheduleDetails || loadingAllocations || isActualsLoading;

  const { timeoffs, holidays } = userScheduleDetails;

  const timeoffSummaryPeriods = useTimeoffSummaryPeriods({
    resource: { timeoffs }
  });

  const holidaySummaryPeriods = useHolidaySummaryPeriods({
    resource: { holidays }
  });

  return (
    <div className={classes.timeLinesContainer}>
      {!loading && (
        <>
          <RangeBoundaries
            chartStartDate={chartStartDate}
            scale={scale}
            start={startDate}
            end={endDate}
          />
          <TimeOffSummaryBlocks
            scale={scale}
            chartStartDate={dateRange.startDate}
            timeoffSummaryPeriods={timeoffSummaryPeriods}
            chartDisplayDateRange={dateRange}
            timeOffs={timeoffs}
            showTooltip
            clickable
            classes={timeOffHolidaySummaryBlocksClasses}
          />
          <HolidaySummaryBlocks
            scale={scale}
            chartStartDate={dateRange.startDate}
            holidaySummaryPeriods={holidaySummaryPeriods}
            chartDisplayDateRange={dateRange}
            holidays={holidays}
            showTooltip
            clickable
            classes={timeOffHolidaySummaryBlocksClasses}
          />
        </>
      )}
      {(allocatedUser.roles || []).map(
        ({ role, resourceAllocationReference }, index, array) => {
          const allocations = resourceAllocationReference
            .map(({ id }) => resourceAllocationsById[id])
            .filter(x => x);

          return (
            <div key={role?.id || `${allocatedUser.user.uri}-no-role`}>
              <ResourceAllocationChartTimeline
                classes={rowClasses}
                chartDisplayPeriods={chartDisplayPeriods}
                index={index}
                loading={loading}
                resourceAllocations={allocations}
                handleRemoveResourceAllocation={handleRemoveResourceAllocation}
                userAllocationDialogOpen={userAllocationDialogOpen}
                actualsData={userActuals.find(e => e.role?.id === role?.id)}
                allocatedUser={allocatedUser}
                newResourceUser={newResourceUser}
                userScheduleDetails={userScheduleDetails}
                closeUserAllocationDialog={closeUserAllocationDialog}
                isEditSwitchEnabled={isEditSwitchEnabled}
                actualSummaryBlockPopupProps={getActualSummaryBlockPopupProps({
                  roleDisplayText: role?.displayText,
                  userActualTotalHours,
                  userAllocatedTotalHours
                })}
                setSnackBarState={setSnackBarState}
              />
            </div>
          );
        }
      )}
      {userAllocationDialogOpen && (
        <EditAllocationUserDialog
          open={userAllocationDialogOpen}
          newResourceUser={newResourceUser}
          onClose={closeUserAllocationDialog}
          allocatedUser={allocatedUser}
          resourceAllocations={resourceAllocations}
          handleEditResourceAllocation={handleEditResourceAllocation}
        />
      )}
    </div>
  );
};

ResourceAllocationChartTimeLines.propTypes = {
  allocatedUser: PropTypes.object,
  userAllocationDialogOpen: PropTypes.bool.isRequired,
  newResourceUser: PropTypes.object,
  closeUserAllocationDialog: PropTypes.func,
  handleEditResourceAllocation: PropTypes.func,
  handleRemoveResourceAllocation: PropTypes.func,
  isEditSwitchEnabled: PropTypes.bool,
  loadingAllocations: PropTypes.bool,
  loadingUserScheduleDetails: PropTypes.bool,
  resourceAllocationsById: PropTypes.object,
  userScheduleDetails: PropTypes.object.isRequired,
  setSnackBarState: PropTypes.func,
  userActuals: PropTypes.array,
  isActualsLoading: PropTypes.bool,
  chartDisplayPeriods: PropTypes.array.isRequired
};
