import { PropTypes } from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ResourceCalendarCard from '~/modules/resource-management/resourceUserDetails/ResourceCalendar/ResourceCalendarCard';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  TagSection,
  AttachmentsSection,
  SupervisorSection,
  CertificateSection,
  GroupSection,
  RoleSection,
  SkillSection,
  ProjectHistorySection,
  AdditionalInfoSection,
  ResourcePoolSection
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  section: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2)
  }
}));

export const ResourceDrawerContent = ({
  resource: { certificates, tags, permittedActions, ...userDetails },
  classes: classesOverride,
  showMore,
  maxSkillLevel
}) => {
  const classes = useStyles({ classes: classesOverride });

  const isGroupSectionEnabled =
    permittedActions.includes('urn:replicon:user-action:view-location') ||
    permittedActions.includes('urn:replicon:user-action:view-division') ||
    permittedActions.includes('urn:replicon:user-action:view-service-center') ||
    permittedActions.includes('urn:replicon:user-action:view-cost-center') ||
    permittedActions.includes('urn:replicon:user-action:view-department') ||
    permittedActions.includes('urn:replicon:user-action:view-employee-type');

  const isViewCustomFields = permittedActions.includes(
    'urn:replicon:user-action:view-custom-fields'
  );

  const mdDown = useIsBreakpointDown('md');
  const calendarAspectRatio = mdDown ? '1.3' : '2';

  return (
    <div className={classes.root}>
      <Grid container className={classes.root}>
        {showMore && (
          <>
            <Grid item xs={12} sm={4} className={classes.section}>
              {permittedActions.includes(
                'urn:replicon:user-action:view-supervisor'
              ) && <SupervisorSection userDetails={userDetails} />}
              {isViewCustomFields && <TagSection tags={tags} />}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.section}>
              {isGroupSectionEnabled && (
                <GroupSection
                  userDetails={userDetails}
                  permittedActions={permittedActions}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.section}>
              {isViewCustomFields && (
                <AdditionalInfoSection userDetails={userDetails} />
              )}
            </Grid>
          </>
        )}
        {permittedActions.includes(
          'urn:replicon:user-action:view-resource-pool'
        ) && (
          <Grid item className={classes.section}>
            <ResourcePoolSection userDetails={userDetails} />
          </Grid>
        )}
        {permittedActions.includes(
          'urn:replicon:user-action:view-project-role'
        ) && (
          <Grid item xs={12} sm={6} className={classes.section}>
            <RoleSection userDetails={userDetails} />
          </Grid>
        )}
        {permittedActions.includes(
          'urn:replicon:user-action:view-skill-assignments'
        ) && (
          <>
            <Grid item xs={12} sm={6} className={classes.section}>
              <SkillSection
                userDetails={userDetails}
                maxSkillLevel={maxSkillLevel}
                certificates={certificates}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.section}>
              <CertificateSection certificates={certificates} />
            </Grid>
          </>
        )}
        {permittedActions.includes(
          'urn:replicon:user-action:view-availability'
        ) && (
          <Grid item className={classes.section}>
            <ResourceCalendarCard
              userDetails={userDetails}
              showCardLayout={false}
              calendarAspectRatio={calendarAspectRatio}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.section}>
          <ProjectHistorySection userUri={userDetails.uri} />
        </Grid>
        {permittedActions.includes(
          'urn:replicon:user-action:view-documents'
        ) && (
          <Grid item xs={12} className={classes.section}>
            <AttachmentsSection userUri={userDetails.uri} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

ResourceDrawerContent.propTypes = {
  resource: PropTypes.object.isRequired,
  classes: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  showMore: PropTypes.bool.isRequired
};

export default ResourceDrawerContent;
