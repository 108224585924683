import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { AvailableToBillListTableContext } from '~/modules/billing-invoicing/common/hooks';

export const AvailableToBillListTableContextProvider = ({
  children,
  refetchAvailableToBillRows,
  ...props
}) => {
  const value = useMemo(
    () => ({
      refetchAvailableToBillRows,
      ...props
    }),
    [props, refetchAvailableToBillRows]
  );

  return (
    <AvailableToBillListTableContext.Provider value={value}>
      {children}
    </AvailableToBillListTableContext.Provider>
  );
};

AvailableToBillListTableContextProvider.propTypes = {
  children: PropTypes.node,
  billingInvoicingRoutes: PropTypes.array,
  refetchAvailableToBillRows: PropTypes.func
};
