import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { useFixedRange } from '~/modules/common/charts/timeline/calculations';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import {
  AllowedExpensesAndEstimates,
  AllowedExpensesAndEstimatesHeader
} from '../AllowedExpensesAndEstimates';
import {
  allowedExpensesAndEstimatesColumnsValues,
  getTotalsForDataPoints
} from '../AllowedExpensesAndEstimates/hooks';

const editCardDialogRole = {
  role: 'presentation'
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: 'unset',
    paddingBottom: 'unset'
  }
}));

const useNoDataStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'pre'
  }
}));

const getPeriodCount = showActuals => breakPoint =>
  ({
    xs: 1,
    sm: 1,
    md: showActuals ? 3 : 4,
    lg: showActuals ? 3 : 5
  }[breakPoint] || 5);

export const ExpenseDialog = ({
  columnClasses,
  dialogAction,
  editable,
  onClose,
  open,
  projectEndDate,
  projectPermissions,
  projectStartDate,
  visibleColumns
}) => {
  const { values } = useFormikContext();
  const noDataClasses = useNoDataStyles();
  const classes = useStyles();

  const { chartDates, onPrevious, onNext, dateRange, setDate } = useFixedRange({
    anchor: 0,
    centerOffset: true,
    defaults: { scale: PERIOD_SCALE_ENUM.MONTHS },
    getPeriodCount: getPeriodCount(values.showActuals),
    isPast: false
  });

  const totals = useMemo(
    () =>
      getTotalsForDataPoints(
        values.allowedExpensesAndEstimates,
        chartDates,
        values.showActuals
      ),
    [chartDates, values.allowedExpensesAndEstimates, values.showActuals]
  );

  const maxWidth = useMemo(
    () => (values.allowedExpensesAndEstimates.length ? 'xl' : 'sm'),
    [values.allowedExpensesAndEstimates.length]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      TransitionProps={editCardDialogRole}
      disableEscapeKeyDown
      role="main"
    >
      <DialogTitle>
        <FormattedMessage id="allowedExpensesAndEstimates.title" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {values.allowedExpensesAndEstimates.length > 0 ? (
          <>
            <AllowedExpensesAndEstimatesHeader
              dateRange={dateRange}
              editable={editable}
              onNext={onNext}
              onPrevious={onPrevious}
              setDate={setDate}
            />
            <AllowedExpensesAndEstimates
              billPlanEditable={projectPermissions?.canEditBillingContracts}
              chartDates={chartDates}
              columnClasses={columnClasses}
              editable={editable}
              projectEndDate={projectEndDate}
              projectStartDate={projectStartDate}
              visibleColumns={visibleColumns}
              totals={totals}
            />
          </>
        ) : (
          <NoDataItem classes={noDataClasses}>
            <FormattedMessage id="projectTimeAndExpenseCard.noExpenseType" />
          </NoDataItem>
        )}
      </DialogContent>
      <DialogActions>{dialogAction}</DialogActions>
    </Dialog>
  );
};

ExpenseDialog.propTypes = {
  columnClasses: PropTypes.object,
  dialogAction: PropTypes.node.isRequired,
  editable: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  projectEndDate: PropTypes.string,
  projectPermissions: PropTypes.object,
  projectStartDate: PropTypes.string,
  visibleColumns: PropTypes.arrayOf(
    PropTypes.oneOf(allowedExpensesAndEstimatesColumnsValues)
  ).isRequired
};

export default ExpenseDialog;
