import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { portfolioFragment } from '../graphql/portfolioFragment';

export const GET_PORTFOLIO_DETAILS = gql`
  query portfolio($input: PortfolioInput!) {
    portfolio(input: $input) {
      ...Portfolio
    }
  }
  ${portfolioFragment}
`;

export const usePortfolio = ({ slug }) => {
  const { data, error, loading } = useQuery(GET_PORTFOLIO_DETAILS, {
    variables: {
      input: {
        slug
      }
    }
  });

  const portfolio = !loading && data ? data.portfolio : {};

  return {
    loading,
    error,
    portfolio
  };
};

export default usePortfolio;
