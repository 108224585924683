import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogTitle, makeStyles, Tabs, Tab } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useHasPermission } from '~/modules/common/permissions';
import { CopyProjectDialogDetails } from '~/modules/common/components/CopyProjectDialog/CopyProjectDialogDetails';
import { COPY_PROJECT_FLOW } from '~/modules/common/components/CopyProjectDialog/enums';
import AddProjectDialogForm from '~/modules/common/components/AddProjectDialogForm';
import {
  useAddProjectForm,
  useProjectCodeSettings
} from '~/modules/common/hooks/project';
import { DIALOG_FORM_TYPE } from '~/modules/common/enums/AddProjectDialogFormTypes';
import { useMeContext } from '~/modules/me/useMeContext';
import { GET_PORTFOLIO_DETAILS } from '../../../hooks/usePortfolio';
import {
  useCanCopyPortfolioProject,
  useOnCreatePortfolioProjectHistoryState
} from './hooks';
import AddExistingProjectForm from './AddExistingProjectForm';
import SearchFilterToolbar from './AddExistingProjectForm/ProjectList/SearchFilterToolbar';

const portfolioProjectDialogRole = {
  role: 'presentation'
};

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    }
  },
  paperMD: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.md,
      height: theme.spacing(70)
    }
  },
  title: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  titleText: {
    padding: theme.spacing(1, 2),
    ...theme.typography.h6
  },
  tabButton: ({ isPsaPrpAccessibilityIssuesEnabled }) => ({
    '&:hover': {
      ...(isPsaPrpAccessibilityIssuesEnabled
        ? { backgroundColor: theme.palette.grey[200] }
        : {})
    }
  })
}));

const addProject = <FormattedMessage id="addProject.addNewProject" />;
const copyProject = <FormattedMessage id="duplicateProject.title" />;
const existingProjects = <FormattedMessage id="addProject.existingProjects" />;

const AddPortfolioProjectDialog = ({
  portfolioId,
  portfolioName,
  projectType,
  open,
  onClose,
  customFieldDefinitions,
  enabledColumnGroups,
  enabledColumns,
  sort
}) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const classes = useStyles({ isPsaPrpAccessibilityIssuesEnabled });
  const fullScreen = useIsBreakpointDown('sm');
  const historyState = useOnCreatePortfolioProjectHistoryState();
  const {
    nextProjectCode,
    projectCodeSettingsLoading
  } = useProjectCodeSettings();

  const form = useAddProjectForm({
    type: DIALOG_FORM_TYPE.PORTFOLIO,
    portfolioId,
    portfolioName,
    projectType,
    historyState,
    customFieldDefinitions,
    projectCode: nextProjectCode,
    onClose,
    replaceHistoryOnClose: true,
    refetchQueries: [
      'getProjects',
      'Eager_GetProjectsStatusCountSummary',
      {
        query: GET_PORTFOLIO_DETAILS,
        variables: {
          input: {
            id: portfolioId
          }
        }
      }
    ]
  });

  const { handleReset } = form;

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
    setTab(0);
  }, [handleReset, onClose]);

  const [tab, setTab] = useState(0);
  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);

  const dialogClasses = useMemo(
    () => ({ paper: tab === 2 ? classes.paperMD : classes.paper }),
    [classes.paper, classes.paperMD, tab]
  );

  const canCopyProject = useCanCopyPortfolioProject();
  const canEditClient = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-client'
  });
  const canEditProgram = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-program'
  });

  const portfolio = useMemo(
    () => ({ id: portfolioId, displayText: portfolioName }),
    [portfolioId, portfolioName]
  );

  if (projectCodeSettingsLoading) return null;

  return (
    <>
      <Dialog
        classes={dialogClasses}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionProps={portfolioProjectDialogRole}
      >
        <DialogTitle className={classes.title}>
          <Tabs value={tab} indicatorColor="primary" onChange={handleTabChange}>
            <Tab label={addProject} className={classes.tabButton} />
            {canCopyProject && (
              <Tab label={copyProject} className={classes.tabButton} />
            )}
            <Tab label={existingProjects} className={classes.tabButton} />
          </Tabs>
          {tab === 2 && (
            <SearchFilterToolbar
              portfolioId={portfolioId}
              enabledColumns={enabledColumns}
            />
          )}
        </DialogTitle>
        {tab === 0 && (
          <AddProjectDialogForm
            type={DIALOG_FORM_TYPE.PORTFOLIO}
            onCancel={handleClose}
            {...form}
          />
        )}
        {canCopyProject && tab === 1 && (
          <CopyProjectDialogDetails
            portfolio={portfolio}
            onClose={handleClose}
            screenFlow={COPY_PROJECT_FLOW.ADD_PROJECT}
            projectType={projectType}
            canEditClient={canEditClient}
            canEditProgram={canEditProgram}
            canEditPortfolio={false}
            hidePortfolioDropDown
          />
        )}
        {tab === (canCopyProject ? 2 : 1) && (
          <AddExistingProjectForm
            portfolioId={portfolioId}
            portfolioName={portfolioName}
            onCancel={handleClose}
            enabledColumnGroups={enabledColumnGroups}
            enabledColumns={enabledColumns}
            sort={sort}
          />
        )}
      </Dialog>
    </>
  );
};

AddPortfolioProjectDialog.propTypes = {
  portfolioId: PropTypes.string,
  portfolioName: PropTypes.string,
  projectType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customFieldDefinitions: PropTypes.array,
  enabledColumnGroups: PropTypes.array,
  enabledColumns: PropTypes.array,
  sort: PropTypes.object
};

export default AddPortfolioProjectDialog;
