import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { ChartCard } from '~/modules/common/charts/dashboard';
import { notAuthorized, slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotAuthorized } from '~/modules/common/notAuthorized';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { usePortfolioPageEditContext } from '~/modules/portfolios/portfolio/PortfolioPageEditContext';
import ProjectRequestIcon from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestIcon';
import { CostIcon } from '~/modules/common/components/Icons';
import {
  usePortfolioObjectPermissions,
  usePortfolioProjectsPermissions
} from '~/modules/common/hooks/portfolio';
import { usePageTitle } from '~/modules/common/title';
import usePortfolioProjectRequestsPermissions from '../../hooks/usePortfolioProjectRequestsPermissions';
import PortfolioProjectRequestStatusChart from './PortfolioProjectRequestStatusChart';
import PortfolioProjectStatusBudgetChart from './PortfolioProjectStatusBudgetChart';
import BudgetProgressCard from './components/BudgetProgressCard';
import ProjectsOverBudgetCard from './components/ProjectsOverBudgetCard';
import PortfolioBasicInfo from './PortfolioBasicInfo';

const getChartTitle = canViewPlanningData => (
  <FormattedMessage
    id={
      canViewPlanningData
        ? 'portfolio.projectRequestStatusChart.chartTitle'
        : 'portfolio.projectRequestStatuses'
    }
  />
);
const budgetChartTitle = (
  <FormattedMessage id="portfolio.projectBudgetStatusChart.chartTitle" />
);

const useChartCardStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    minHeight: '-webkit-max-content'
  },
  portfolioBasicInfo: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexBasis: 200
    }
  },
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0, 2, 0)
    }
  },
  column1: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0)
    }
  },
  column2: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: theme.spacing(0)
    }
  }
}));

const icons = {
  projectRequest: <ProjectRequestIcon />,
  cost: <CostIcon />
};

export const PortfolioInfo = () => {
  const classes = useStyles();
  const chartClasses = useChartCardStyles();

  const { edit, loading, error, portfolio } = usePortfolioPageEditContext();
  const {
    featureFlags: { isPsaPpmPortfolioSummaryRequestChartEnabled }
  } = useMeContext();

  const { canViewCostData } = usePortfolioObjectPermissions(
    portfolio?.permittedActionUris
  );

  const {
    id,
    portfolioManager,
    ancestors,
    budget,
    portfolioCurrency
  } = portfolio;

  const {
    canViewPortfolioProjectRequests,
    canViewPortfolioProjectRequestsPlanningData
  } = usePortfolioProjectRequestsPermissions({
    portfolioManager,
    ancestors
  });

  const canViewPlanningData = canViewPortfolioProjectRequestsPlanningData;

  const { canViewPortfolioProjects } = usePortfolioProjectsPermissions({
    portfolioManager,
    ancestors
  });

  const requestStatusChartDataProps = useMemo(
    () => ({ portfolioId: id, portfolioCurrency, canViewPlanningData }),
    [canViewPlanningData, id, portfolioCurrency]
  );

  usePageTitle([
    { messageId: 'routes.portfolios' },
    { title: portfolio.name },
    { messageId: 'portfolio.tabs.summary' }
  ]);

  const projectStatusBudgetChartDataProps = useMemo(
    () => ({
      portfolioId: id,
      portfolioBudgetAmount: budget?.total?.amount,
      portfolioCurrency
    }),
    [id, budget, portfolioCurrency]
  );

  const projectProgressCardProps = useMemo(
    () => ({
      portfolioId: id,
      canViewPortfolioProjects,
      canViewCostData,
      portfolioCurrency
    }),
    [id, canViewPortfolioProjects, canViewCostData, portfolioCurrency]
  );

  if (error)
    return (
      (slugNotFound({ data: { error } }) && <RedirectToNotFound />) ||
      (notAuthorized({ data: { error } }) && <RedirectToNotAuthorized />)
    );
  const requestStatusChart = (
    <ChartCard
      chartTitle={getChartTitle(canViewPlanningData)}
      chartAvatar={icons.projectRequest}
      chartDataProps={requestStatusChartDataProps}
      ChartComponent={PortfolioProjectRequestStatusChart}
      classes={chartClasses}
    />
  );

  const budgetAllocationChart = (
    <ChartCard
      chartTitle={budgetChartTitle}
      chartAvatar={icons.cost}
      chartDataProps={projectStatusBudgetChartDataProps}
      ChartComponent={PortfolioProjectStatusBudgetChart}
      classes={chartClasses}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.portfolioBasicInfo}>
        <PortfolioBasicInfo
          portfolio={portfolio}
          loading={loading}
          edit={edit}
        />
      </div>
      {isPsaPpmPortfolioSummaryRequestChartEnabled && (
        <Grid container spacing={0} className={classes.container}>
          {canViewPortfolioProjects || canViewCostData ? (
            <Grid item xs={12} sm={6} xl={4} className={classes.column1}>
              <BudgetProgressCard {...projectProgressCardProps} />
              {canViewPortfolioProjects ? (
                <ProjectsOverBudgetCard {...projectProgressCardProps} />
              ) : null}
            </Grid>
          ) : null}
          <Hidden xlUp>
            <Grid item xs={12} sm={6} xl={4} className={classes.column2}>
              {canViewCostData &&
                canViewPortfolioProjects &&
                budgetAllocationChart}
              {canViewPortfolioProjectRequests && requestStatusChart}
            </Grid>
          </Hidden>
          <Hidden lgDown>
            <>
              {canViewCostData && canViewPortfolioProjects && (
                <Grid item xs={12} sm={6} xl={4} className={classes.column2}>
                  {budgetAllocationChart}
                </Grid>
              )}
              <Grid item xl={4}>
                {canViewPortfolioProjectRequests && requestStatusChart}
              </Grid>
            </>
          </Hidden>
        </Grid>
      )}
    </div>
  );
};
PortfolioInfo.propTypes = {};

export default PortfolioInfo;
