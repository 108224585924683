import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHasPermission } from '~/modules/common/permissions';
import { ResourceAllocationStatus } from '~/types';
import QuickAllocationActions2 from '../../../QuickAllocationActions/QuickAllocationsActions2';
import { useQuickAllocationContext } from '../../../../hooks';

const useStyles = makeStyles(theme => ({
  rowCell: {
    width: theme.spacing(28.75)
  },
  text: {
    ...theme.typography.caption,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: theme.spacing(1)
  },
  projectText: {
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: theme.spacing(25),
    marginTop: theme.spacing(0.5)
  },
  roleText: {
    padding: theme.spacing(1, 1, 0, 1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: theme.spacing(15),
    textAlign: 'right',
    fontSize: theme.typography.caption.fontSize
  },
  actionPlaceHolder: { width: theme.spacing(4) },
  rolesContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flexBasis: theme.spacing(17.5)
  },
  readOnlyProject: {
    width: theme.spacing(31.75),
    paddingLeft: theme.spacing(1)
  },
  loadingMenuItems: {
    marginTop: theme.spacing(1.75),
    marginLeft: theme.spacing(1)
  }
}));

const useActualsStyles = makeStyles(theme => ({
  roleRowActualsCell: {
    paddingBottom: theme.spacing(4.93),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

const useRowStyles = makeStyles(theme => ({
  roleRowCell: {
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

const QuickAllocationProjectGroupLeftComponent = ({
  allocatedProject,
  projectsSlugLoading,
  handleRemoveResourceAllocation,
  resourceAllocations,
  resourceUser,
  setSnackBarState
}) => {
  const classes = useStyles();
  const actualsClasses = useActualsStyles();
  const rowStyles = useRowStyles();
  const { isShowActualEnabled } = useQuickAllocationContext();

  const {
    resourceAllocationReferencesForProject
  } = allocatedProject.roles.reduce(
    (acc, { role, resourceAllocationReference }) => {
      if (resourceAllocationReference.length) {
        return {
          resourceAllocationReferencesForProject: [
            ...acc.resourceAllocationReferencesForProject,
            ...resourceAllocationReference.map(({ id }) => ({
              key: id,
              allocationId: id,
              role
            }))
          ],
          hasResourceAllocations: true
        };
      }

      return {
        ...acc,
        resourceAllocationReferencesForProject: [
          ...acc.resourceAllocationReferencesForProject,
          { key: role?.id || `${allocatedProject.project.id}-no-role`, role }
        ]
      };
    },
    {
      resourceAllocationReferencesForProject: [],
      hasResourceAllocations: false
    }
  );

  const isCompleteResourceAllocationPermissionEnabled = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });

  const committedResourceAllocations = resourceAllocations.filter(
    allocation =>
      allocation.allocationStatus === ResourceAllocationStatus.Committed
  );

  const showResourceActions =
    isCompleteResourceAllocationPermissionEnabled &&
    Boolean(committedResourceAllocations.length);

  return (
    <>
      {showResourceActions || Boolean(allocatedProject.project.slug) ? (
        <QuickAllocationActions2
          allocatedProject={allocatedProject}
          resourceAllocations={committedResourceAllocations}
          resourceUser={resourceUser}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          setSnackBarState={setSnackBarState}
          showResourceActions={showResourceActions}
        />
      ) : projectsSlugLoading ? (
        <div className={classes.loadingMenuItems}>
          <Skeleton variant="rect" width={10} height={12} />
        </div>
      ) : (
        <div className={classes.actionPlaceHolder} />
      )}
      <div
        className={classNames(
          showResourceActions ? classes.rowCell : classes.readOnlyProject
        )}
      >
        <Tooltip title={allocatedProject.project.displayText}>
          <div className={classNames(classes.text, classes.projectText)}>
            {allocatedProject.project.displayText}
          </div>
        </Tooltip>
      </div>
      <div className={classes.rolesContainer}>
        {(resourceAllocationReferencesForProject || []).map(
          ({ role, key, allocationId }, index, array) => (
            <div
              className={classNames(
                role?.id !== array[index + 1]?.role?.id
                  ? isShowActualEnabled && allocationId
                    ? actualsClasses.roleRowActualsCell
                    : rowStyles.roleRowCell
                  : null
              )}
              key={key}
            >
              <Tooltip title={role?.displayText || ''}>
                <div className={classes.roleText}>
                  {role?.displayText ? (
                    role.displayText
                  ) : (
                    <FormattedMessage id="quickAllocation.noRole" />
                  )}
                </div>
              </Tooltip>
            </div>
          )
        )}
      </div>
    </>
  );
};

QuickAllocationProjectGroupLeftComponent.propTypes = {
  allocatedProject: PropTypes.object,
  projectsSlugLoading: PropTypes.bool,
  handleRemoveResourceAllocation: PropTypes.func,
  resourceAllocations: PropTypes.array,
  resourceUser: PropTypes.object,
  setSnackBarState: PropTypes.func
};

export default QuickAllocationProjectGroupLeftComponent;
