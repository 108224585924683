import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  row: {
    display: 'contents',
    '&:last-child $cell': {
      borderBottom: 'none'
    }
  },
  cell: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    paddingLeft: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0)
    }
  },
  header: {
    '& $label, & $cell': {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      position: 'sticky',
      zIndex: 1
    },
    '& $label': {
      zIndex: 2
    }
  },
  toggleButton: {
    margin: theme.spacing(-0.5, -1),
    opacity: 0,
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  annotationIcon: {
    cursor: 'help',
    color: theme.palette.text.secondary,
    fontSize: 18,
    margin: theme.spacing(0, 0, 0, 1),
    verticalAlign: 'text-bottom',
    opacity: 0.8,
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    })
  },
  disabled: {
    color: theme.palette.text.disabled
  }
}));
