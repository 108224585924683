import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 435;

const useStyles = makeStyles(theme => ({
  hideDrawer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerShift: {
    width: 0,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

export default useStyles;
