import React, { useCallback } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { ErrorSharp as ErrorIcon } from '@material-ui/icons';
import { UserAvatar } from '~/modules/common/components/Avatars';
import { useProjectContext } from '~/modules/resourcing/common/contexts';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(0.5),
    minHeight: theme.spacing(5),
    alignItems: 'center'
  },
  rootHover: {
    '&:hover': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    }
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: theme.spacing(-0.5)
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  actionIcon: {
    alignSelf: 'center'
  },
  errorIcon: {
    fontSize: theme.spacing(2.25),
    color: theme.palette.error.main
  },
  avatar: {
    margin: theme.spacing(0, 1.25, 0, 0.75),
    pointerEvents: 'none'
  },
  avatarReadOnly: {
    marginLeft: theme.spacing(3)
  },
  resourceText: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold,
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  resourceTextOverDistributed: {
    lineHeight: 1
  },
  tasksSummary: {
    display: 'flex'
  }
}));

export const LeftItemContentModel1 = ({
  allocatedUser,
  isUserRowExpanded,
  ActionsComponent,
  actionsComponentProps,
  onUserRowExpansionPanelClick,
  isUserTaskAllocationsOverDistributed
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    projectObjectPermissions: { canViewTasks }
  } = useProjectContext();

  const onChange = useCallback(
    event => {
      if (
        canViewTasks &&
        (event.type === 'click' ||
          event.key === 'Enter' ||
          event.keyCode === 13)
      ) {
        onUserRowExpansionPanelClick(allocatedUser.user.id);
      }
    },
    [allocatedUser.user.id, canViewTasks, onUserRowExpansionPanelClick]
  );

  return (
    <>
      <div
        className={classNames(classes.root, {
          [classes.rootHover]: canViewTasks
        })}
        onClick={onChange}
        onKeyDown={onChange}
        role="button"
        tabIndex="0"
      >
        {canViewTasks && (
          <ExpandMoreIcon
            className={classNames(classes.expandIcon, {
              [classes.expandIconOpen]: isUserRowExpanded
            })}
          />
        )}
        <>
          <UserAvatar
            user={allocatedUser.user}
            className={classNames(classes.avatar, {
              [classes.avatarReadOnly]: !canViewTasks
            })}
            size="small"
          />
          <div>
            <Typography
              className={classNames(classes.resourceText, {
                [classes.resourceTextOverDistributed]:
                  isUserTaskAllocationsOverDistributed && !isUserRowExpanded
              })}
            >
              {allocatedUser.user.displayText}
            </Typography>
            {canViewTasks && !isUserRowExpanded && (
              <div className={classes.tasksSummary}>
                {isUserTaskAllocationsOverDistributed && (
                  <Tooltip
                    title={formatMessage({
                      id: 'taskAssignments.resourceRowOverDistributed'
                    })}
                    placement="right"
                  >
                    <ErrorIcon className={classes.errorIcon} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </>
      </div>
      <div className={classes.actionIcon}>
        <ActionsComponent {...actionsComponentProps} />
      </div>
    </>
  );
};

LeftItemContentModel1.propTypes = {
  allocatedUser: PropTypes.object,
  isUserRowExpanded: PropTypes.bool,
  ActionsComponent: PropTypes.elementType.isRequired,
  actionsComponentProps: PropTypes.object.isRequired,
  onUserRowExpansionPanelClick: PropTypes.func.isRequired,
  assignedTasksCount: PropTypes.number,
  isUserTaskAllocationsOverDistributed: PropTypes.bool
};

export default LeftItemContentModel1;
