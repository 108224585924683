import React from 'react';
import Badge from '@material-ui/core/Badge';
import { MyTeamIcon } from '~/modules/common/components/Icons';
import useApprovalsSummary from '~/modules/common/hooks/useApprovalsSummary';

export const MAX_NOTIFICATIONS = 9;

export const MyTeamBadgeIcon = () => {
  const { loading, approvalsSummary } = useApprovalsSummary();

  const approvals =
    approvalsSummary &&
    (approvalsSummary.awaitingTimesheets > 0 ||
      approvalsSummary.awaitingTimeOffs > 0 ||
      approvalsSummary.awaitingExpenses > 0 ||
      approvalsSummary.awaitingSellBacks > 0)
      ? {
          count:
            (approvalsSummary.awaitingTimesheets || 0) +
            (approvalsSummary.awaitingTimeOffs || 0) +
            (approvalsSummary.awaitingExpenses || 0) +
            (approvalsSummary.awaitingSellBacks || 0)
        }
      : null;

  if (!loading && approvals) {
    return (
      <Badge
        overlap="rectangular"
        badgeContent={
          approvals.count > MAX_NOTIFICATIONS
            ? `${MAX_NOTIFICATIONS}+`
            : approvals.count
        }
        color="secondary"
      >
        <MyTeamIcon />
      </Badge>
    );
  }

  return <MyTeamIcon />;
};

export default MyTeamBadgeIcon;
