import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { NoValue } from '~/modules/common/components';
import { OverviewItemTooltip } from '~/modules/common/charts/dashboard';
import { useMeContext } from '~/modules/me';
import {
  getReactMoneyFormatFromMe,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';

const useStyles = makeStyles(theme => ({
  amount: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  }
}));

export const ExpenseSummaryItem = ({ amount, dataQeId, labelId }) => {
  const me = useMeContext();
  const moneySettings = getReactMoneyFormatFromMe(me);
  const numberSettings = getReactNumberFormatFromMe(me);
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption">
        <FormattedMessage id={labelId} />
      </Typography>
      <Typography variant="body2" className={classes.amount}>
        {amount ? (
          <OverviewItemTooltip
            currencySymbol={
              amount.currency.displayText || amount.currency.symbol
            }
            value={amount.amount}
            moneySettings={moneySettings}
            numberSettings={numberSettings}
            intlKey="cost"
            id="projectTimeAndExpenseCard.costDescription"
            dataQeId={dataQeId}
          />
        ) : (
          <NoValue />
        )}
      </Typography>
    </>
  );
};

ExpenseSummaryItem.propTypes = {
  amount: PropTypes.object,
  dataQeId: PropTypes.string,
  labelId: PropTypes.string
};
