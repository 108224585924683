import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const PROJECT_DROPDOWN_QUERY = gql`
  query ProjectDropdownSearch(
    $page: Int!
    $pagesize: Int!
    $projectFilter: ProjectFilter2
  ) {
    projects(page: $page, pagesize: $pagesize, projectFilter: $projectFilter) {
      id
      displayText
    }
  }
`;

export const useProjectDropdownOptionsWithStatusFilter = ({
  searchTerm,
  excludeProjectStatus,
  skip = false
}) => {
  const { data, loading } = useQuery(PROJECT_DROPDOWN_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      page: 1,
      pagesize: PAGE_SIZE + 1,
      projectFilter: {
        text: searchTerm,
        excludeProjectStatus
      }
    },
    context: {
      debounceKey: 'project-search',
      debounceTimeout: 250
    },
    skip
  });

  const projects = loading ? [] : get(data, 'projects', []);

  return {
    projects,
    loading,
    hasMore: projects.length > PAGE_SIZE
  };
};

export default useProjectDropdownOptionsWithStatusFilter;
