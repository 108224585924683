import React from 'react';
import PropTypes from 'prop-types';
import { ProgressChart } from './ProgressChart';

export const ProgressCostChart = ({
  projectsBudgetCost,
  projectsEstimateAtCompletionCost,
  projectsActualCost,
  portfolioCurrency
}) => {
  return (
    <ProgressChart
      budgetAmount={projectsBudgetCost?.amount}
      eacAmount={projectsEstimateAtCompletionCost?.amount}
      actualAmount={projectsActualCost?.amount}
      prefix={portfolioCurrency.displayText}
      budgetMissing="portfolio.budgetBurnedCard.budgetCostMissing"
      budgetMissingAlert="portfolio.budgetBurnedCard.budgetCostMissingAlert"
      eacMissingAlert="portfolio.budgetBurnedCard.eacMissingAlert"
      eacMissing="portfolio.budgetBurnedCard.eacMissing"
      fixedDecimalScale
      actualAmountResourceKey="portfolio.budgetBurnedCard.actualCost"
      tooltipPrecision={2}
      dataQeId="BudgetBurnedCostChart"
    />
  );
};

ProgressCostChart.propTypes = {
  projectsBudgetCost: PropTypes.object,
  projectsEstimateAtCompletionCost: PropTypes.object,
  projectsActualCost: PropTypes.object,
  portfolioCurrency: PropTypes.object
};

export default ProgressCostChart;
