import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { clientTag } from '~/modules/common/components/SearchBox/Facets/ClientFacet/tag';
import { useLoadMore } from '~/modules/common/hooks/useLoadMoreResults';
import {
  useGetProjectsQuery,
  ProjectListTableBase,
  useGetProjectTotalsQuery
} from '~/modules/projects';
import { ProjectManagementType } from '~/types';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { usePageTitle } from '~/modules/common/title';
import { useProjectsColumnSelection } from '~/modules/common/hooks/project';
import { useIsBreakpointDown } from '~/modules/common/hooks';

import { PAGINATION_TYPE } from '~/modules/common/components/ListTable';
import { useGetEnabledTagFieldsQuery } from '~/modules/tags/graphql';
import { mobileColumnNames } from '~/modules/common/hooks/project/projectColumns';
import { CLIENT_ID_QUERY_BY_SLUG } from '../graphql';
import useClientPageEditContext from '../ClientInfo/useClientPageEditContext';
import { useClientProjectListTableSettings } from '../hooks';
import Toolbar from './Toolbar';
import { useClientProjectsContext } from './ClientProjectsContext';
import { useCanAddClientProject } from './AddClientProject/useCanAddClientProject';

export const DEFAULT_PAGE_SIZE = 20;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  bottomHeader: {
    top: theme.spacing(17)
  }
}));

export const ClientProjects = ({ slug }) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const {
    projectType,
    searchCriteria,
    summaryFilterState
  } = useClientProjectsContext();

  const headerClasses = useMemo(
    () => ({
      bottomHeader: classes.bottomHeader
    }),
    [classes.bottomHeader]
  );

  const { data: idQuery, loading: idLoading } = useQuery(
    CLIENT_ID_QUERY_BY_SLUG,
    {
      variables: { slug }
    }
  );

  const client = get(idQuery, 'client', {});

  usePageTitle(
    idLoading
      ? []
      : [
          { messageId: 'routes.clients' },
          { title: client.name },
          { messageId: 'client.projects' }
        ]
  );

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria: {
      ...searchCriteria,
      criterions: {
        ...(searchCriteria.criterions || {}),
        [clientTag]: [{ value: client.id }]
      }
    },
    quickFilter: summaryFilterState.quickFilter
  });
  const { selectedColumnNames } = useClientProjectListTableSettings();

  const columnArguments = useProjectsColumnSelection({
    enabledFields: isMobile ? mobileColumnNames : selectedColumnNames
  });

  const queryVariables = useMemo(
    () => ({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      projectFilter,
      isManaged: projectType === ProjectManagementType.Managed,
      ...columnArguments
    }),
    [projectFilter, projectType, columnArguments]
  );

  const tagFieldData = useGetEnabledTagFieldsQuery(selectedColumnNames);

  const { loading, fetchMore, data, networkStatus } = useGetProjectsQuery({
    variables: queryVariables,
    fetchPolicy: 'network-only'
  });

  const { data: totalsData, loading: totalsLoading } = useGetProjectTotalsQuery(
    {
      variables: {
        projectFilter,
        isManaged: projectType === ProjectManagementType.Managed
      },
      fetchPolicy: 'network-only'
    }
  );

  const projects = get(data, 'projects') || [];

  const { loadMore, hasMore } = useLoadMore({
    variables: { pageSize: DEFAULT_PAGE_SIZE },
    fetchMore,
    fetchMoreResultPath: 'projects',
    loading,
    networkStatus,
    initialHasMore: projects.length % DEFAULT_PAGE_SIZE === 0
  });

  const totals = get(totalsData, 'projectTotals');

  const { editable } = useClientPageEditContext();
  const canAddProject = useCanAddClientProject();
  const noProjectDataComponentProps = useMemo(
    () => ({
      showClickToAdd: editable && canAddProject
    }),
    [editable, canAddProject]
  );

  return (
    <>
      <Toolbar client={client} />
      <ProjectListTableBase
        headerClasses={headerClasses}
        projects={projects}
        totals={totals}
        hasMoreProjects={hasMore}
        loadMoreProjects={loadMore}
        projectsLoading={loading || totalsLoading}
        tagFieldData={tagFieldData.data}
        enabledFields={selectedColumnNames}
        disableSort
        noProjectDataComponentProps={noProjectDataComponentProps}
        paginationType={PAGINATION_TYPE.SCROLLABLE}
      />
    </>
  );
};

ClientProjects.propTypes = {
  slug: PropTypes.string.isRequired
};

export default ClientProjects;
