import { DialogContent, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  usePutBillFormState,
  usePutBillHandler
} from '~/modules/billing-invoicing/bill/hooks';
import { isNullOrUndefined } from '~/modules/common/util';
import {
  useClientDefaultData,
  useIsBillDataReflectedOnAvailableToBill,
  useInvoiceDefaultTemplate,
  useNextReferenceNumberText
} from '~/modules/billing-invoicing/common/hooks';
import { useMeContext } from '~/modules/me';
import { billingTransactionType } from '~/modules/billing-invoicing/common/enums';
import { mapRepliconDateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useBulkProjectOrClientBillingDefaultsForAvailableToBill } from '~/modules/billing-invoicing/common/components/AvailableToBillListTable/hooks/useBulkProjectOrClientBillingDefaultsForAvailableToBill';
import BillInfo from '../../bill/components/BillDetails/BillInfo';
import AddLineItemsDialogContent from './AddLineItemsDialogContent';
import AddLineItemsDialogFooter from './AddLineItemsDialogFooter';
import BillingItemGroup from './BillingItemGroup';
import { useLineItemsDialogContext } from './AddLineItemsDialogContext';
import {
  useFilterBasedOnSearchCriteria,
  useCreateInvoiceItemsFromBillItems,
  useAddLineItemsHooks,
  useCreateInvoiceFromBillItemsBatch,
  useCreateBillFromBillingItemsBatchResults
} from './hooks';
import getFacets from './facets';
import Toolbar from './Toolbar';
import { useStyles } from './useStyles';

export const AddLineItemsTab = ({
  billId,
  onClose,
  client,
  createBill,
  billCurrency,
  setLineItemAdding,
  projectUri,
  summarizeColumnOptions,
  setSummarizeColumOptions,
  billingBatchState,
  setBillingBatchState,
  projectName,
  refetchAvailableToBillRows,
  refetchAvailableToBillTotal,
  refetchFilteredTotals,
  startDate,
  endDate,
  billDetails
}) => {
  const me = useMeContext();
  const intl = useIntl();
  const { isPsaPrpBillingDefaultForProjectEnabled } = me.featureFlags;
  const classes = useStyles();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { invoiceDefaultTemplate } = useInvoiceDefaultTemplate();
  const { clientDefaultData } = useClientDefaultData({ client });
  const { nextReferenceNumberText } = useNextReferenceNumberText(
    billingTransactionType.BILL
  );
  const {
    invoiceCurrency,
    defaultInvoicePaymentTerm,
    invoiceTemplate,
    billingContactInfo,
    billingSettings,
    taxProfile
  } = clientDefaultData || {};
  const { putBill } = usePutBillHandler();
  const [selectedBillingItems, setSelectedBillingItems] = useState({});
  const [saving, setSaving] = useState(false);
  const [createAndView, setCreateAndView] = useState(false);
  const {
    projectOrClientBillingDefaults
  } = useBulkProjectOrClientBillingDefaultsForAvailableToBill([
    {
      clientUris: [client.id],
      projectUris: projectUri ? [projectUri] : undefined,
      billingPeriod:
        startDate && endDate
          ? {
              startDate: mapRepliconDateToMidnightUTCString(startDate),
              endDate: mapRepliconDateToMidnightUTCString(endDate)
            }
          : undefined
    }
  ]);

  const billingDefaults =
    (projectOrClientBillingDefaults && projectOrClientBillingDefaults[0]) || {};

  const {
    values,
    setFieldValue,
    errors,
    validateForm,
    isSubmitting,
    setFieldError
  } = usePutBillFormState({
    projectUri,
    projectName,
    me,
    client,
    clientCurrency: isPsaPrpBillingDefaultForProjectEnabled
      ? billingDefaults?.invoiceCurrency
      : invoiceCurrency,
    clientPaymentTerm: isPsaPrpBillingDefaultForProjectEnabled
      ? billingDefaults?.defaultInvoicePaymentTerm ?? defaultInvoicePaymentTerm
      : defaultInvoicePaymentTerm,
    clientInvoiceTemplate: isPsaPrpBillingDefaultForProjectEnabled
      ? billingDefaults?.invoiceTemplate
      : invoiceTemplate,
    clientTaxProfile: isPsaPrpBillingDefaultForProjectEnabled
      ? billingDefaults?.taxProfile
      : taxProfile,
    poNumber:
      isPsaPrpBillingDefaultForProjectEnabled &&
      !isNullOrUndefined(billingDefaults?.poNumber)
        ? billingDefaults?.poNumber
        : '',
    invoiceDefaultTemplate,
    putBill,
    setSaving,
    period: { startDate, endDate },
    billingAddress: isPsaPrpBillingDefaultForProjectEnabled
      ? billingDefaults?.billingContactInfo
      : billingContactInfo,
    onClose,
    summarizeColumnOptions,
    nextReferenceNumberText,
    billingSettings: isPsaPrpBillingDefaultForProjectEnabled
      ? {
          billLineItemsBy: billingDefaults?.billLineItemsBy,
          description: billingDefaults?.description,
          internalNotes: billingDefaults?.internalNotes,
          showComments: billingDefaults?.showComments
        }
      : billingSettings
  });
  const setColumns = useCallback(
    col => {
      setFieldValue('summarizeColumnOptions', col);
    },
    [setFieldValue]
  );

  const { searchCriteria } = useLineItemsDialogContext();
  const { billingItemSearch } = useFilterBasedOnSearchCriteria({
    searchCriteria
  });
  const searchFacets = useMemo(
    () => getFacets({ columns: values.summarizeColumnOptions, client }),
    [client, values.summarizeColumnOptions]
  );

  const {
    createInvoiceItemsFromBillingItemsBatch
  } = useCreateInvoiceItemsFromBillItems({ setBillingBatchState });
  const { fetchCalculationStatus } = useIsBillDataReflectedOnAvailableToBill();
  const { fetchBatchResults } = useCreateBillFromBillingItemsBatchResults();

  const addModeResourceKey = createBill
    ? 'addLineItemDialog.createBill'
    : 'addLineItemDialog.addLineItems';

  const {
    createInvoiceFromBillItemsBatch
  } = useCreateInvoiceFromBillItemsBatch({ setBillingBatchState });

  const {
    onAddLineItems,
    onBatchComplete,
    onBatchCompleteViewBill
  } = useAddLineItemsHooks({
    createBill,
    validateForm,
    setFieldError,
    setSaving,
    setCreateAndView,
    billId,
    setSummarizeColumOptions,
    createInvoiceItemsFromBillingItemsBatch,
    createInvoiceFromBillItemsBatch,
    fetchCalculationStatus,
    fetchBatchResults,
    columns: values.summarizeColumnOptions,
    values,
    billingItemSearch,
    selectedBillingItems,
    setLineItemAdding,
    onClose,
    refetchAvailableToBillRows,
    refetchAvailableToBillTotal,
    refetchFilteredTotals,
    history,
    params,
    intl,
    putBill,
    billDetails,
    isPsaPrpBillingDefaultForProjectEnabled
  });

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        {createAndView ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={24} className={classes.progress} />
          </div>
        ) : (
          <>
            {Boolean(createBill) && (
              <BillInfo
                hasClient
                isCollapsible
                containerClass={classes.container}
                fieldContainerClass={classes.fieldContainer}
                currencyEditable
                setFieldValue={setFieldValue}
                errors={errors}
                isSubmitting={isSubmitting}
                values={values}
              />
            )}
            <Toolbar
              searchFacets={searchFacets}
              columns={values.summarizeColumnOptions}
              createBill={createBill}
            />
            <div className={classes.groupBar}>
              <BillingItemGroup
                columns={values.summarizeColumnOptions}
                setColumns={setColumns}
              />
            </div>
            <div className={classes.resultContainer}>
              <AddLineItemsDialogContent
                classes={classes}
                billingItemSearch={billingItemSearch}
                client={client}
                columns={values.summarizeColumnOptions}
                setSelectedBillingItems={setSelectedBillingItems}
                billCurrency={!createBill ? billCurrency : values.billCurrency}
                projectUri={projectUri}
              />
            </div>
          </>
        )}
      </DialogContent>
      <AddLineItemsDialogFooter
        onAddLineItems={onAddLineItems}
        onBatchCompleteViewBill={onBatchCompleteViewBill}
        onClose={onClose}
        disableAdd={Boolean(
          saving || createAndView || !selectedBillingItems.any
        )}
        addButtonLabel={addModeResourceKey}
        saveInProgress={saving}
        createAndViewInProgress={createAndView}
        billingBatchState={billingBatchState}
        setBillingBatchState={setBillingBatchState}
        onBatchComplete={onBatchComplete}
        createBill={createBill}
      />
    </>
  );
};

AddLineItemsTab.propTypes = {
  billId: PropTypes.string,
  setLineItemAdding: PropTypes.func,
  billCurrency: PropTypes.object,
  onClose: PropTypes.func,
  client: PropTypes.object,
  createBill: PropTypes.bool,
  projectUri: PropTypes.string,
  summarizeColumnOptions: PropTypes.array,
  setSummarizeColumOptions: PropTypes.func,
  billingBatchState: PropTypes.object,
  setBillingBatchState: PropTypes.func,
  projectName: PropTypes.string,
  refetchAvailableToBillRows: PropTypes.func,
  refetchAvailableToBillTotal: PropTypes.func,
  refetchFilteredTotals: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  billDetails: PropTypes.object.isRequired
};

export default AddLineItemsTab;
