import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ColumnSettingsDialog } from '~/modules/common/components/ListTable';
import { ProjectRequestSearchBox } from '~/modules/project-request/ProjectRequestPage/components';
import SettingsButton from '~/modules/common/components/ListTableToolBar/SettingsButton';
import { useDialogState, useIsBreakpointUp } from '~/modules/common/hooks';
import { useEnabledColumns } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks';
import { useExistingProjectRequestSearchContext } from './ExistingProjectRequestSearchContext';

const PORTFOLIO_PROJECT_REQUEST_DIALOG_TABLE_KEY =
  'PORTFOLIO_PROJECT_REQUESTS_DIALOG';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    minHeight: '-webkit-fit-content'
  }
}));

const SearchFilterToolbar = ({ enabledColumns, defaultColumns }) => {
  const classes = useStyles();
  const showSettings = useIsBreakpointUp('md');
  const intl = useIntl();

  const {
    searchCriteria,
    setSearchCriteria
  } = useExistingProjectRequestSearchContext();

  const { enabledColumnGroups } = useEnabledColumns({
    classes: {}
  });

  const { open, openDialog, closeDialog } = useDialogState();

  return (
    <>
      <div className={classes.root}>
        {setSearchCriteria && (
          <ProjectRequestSearchBox
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
          />
        )}
        {showSettings ? (
          <SettingsButton
            onClick={openDialog}
            aria-label={intl.formatMessage({
              id: 'columnSettings.showTableSettings'
            })}
          />
        ) : null}
      </div>
      {showSettings && (
        <ColumnSettingsDialog
          tableKey={PORTFOLIO_PROJECT_REQUEST_DIALOG_TABLE_KEY}
          groups={enabledColumnGroups}
          settingsColumnJustify="flex-start"
          selectedColumns={enabledColumns}
          defaultColumns={defaultColumns}
          open={open}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

SearchFilterToolbar.propTypes = {
  enabledColumns: PropTypes.array,
  defaultColumns: PropTypes.array
};

export default SearchFilterToolbar;
