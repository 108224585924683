import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { SkillIcon } from '~/modules/common/components/Icons';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import SkillsReadOnly from '~/modules/resource-management/resourceUserDetails/Skills/SkillsReadOnly';

const useStyles = makeStyles(() => ({
  title: {
    position: 'relative',
    top: '-8px',
    padding: '0 8px'
  }
}));

export const SkillSection = ({ userDetails, certificates, maxSkillLevel }) => {
  const classes = useStyles();

  const title = useMemo(
    () => (
      <>
        <SkillIcon />
        <span className={classes.title}>
          <FormattedMessage id="resourceDrawer.skills" />
        </span>
      </>
    ),
    [classes.title]
  );

  return (
    <InfoSection editable={false} title={title}>
      <SkillsReadOnly
        skills={userDetails.resourceSkill}
        certificates={certificates}
        maxSkillLevel={maxSkillLevel}
      />
    </InfoSection>
  );
};

SkillSection.propTypes = {
  userDetails: PropTypes.object.isRequired,
  certificates: PropTypes.array.isRequired,
  maxSkillLevel: PropTypes.number
};

export default SkillSection;
