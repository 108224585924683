import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { useLoadMore2 as useLoadMore } from '~/modules/common/hooks';

export const GET_VENDOR_ASSIGNMENTS = gql`
  query vendorAssignments($id: String!, $page: Int!, $pageSize: Int!) {
    vendor(id: $id) {
      id
      userAssignments(page: $page, pageSize: $pageSize) {
        id
        uri
        displayText
        slug
        employeeId
        startDate
        endDate
        currentDepartment {
          id
          displayText
          ancestors {
            displayText
          }
        }
        effectiveRoles {
          isPrimary
          effectiveDate
          projectRole {
            uri
            name
          }
        }
        supervisorAssignment {
          supervisor {
            uri
            displayText
          }
          effectiveDate
        }
      }
    }
  }
`;

export const useVendorResources = ({ id, page = 1, pageSize = 20 }) => {
  const { data, fetchMore, error, loading } = useQuery(GET_VENDOR_ASSIGNMENTS, {
    variables: {
      id,
      page,
      pageSize
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const userAssignments = get(data, 'vendor.userAssignments', []);

  const { loadMore, hasMore, loadingMore } = useLoadMore({
    totalFetchedItems: userAssignments.length,
    fetchMore,
    loading,
    pageSize
  });

  return {
    loadMore,
    hasMore,
    loadingMore,
    loading,
    error,
    userAssignments
  };
};

export default useVendorResources;
