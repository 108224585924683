export const mapToServiceFilter = ({
  projectUri,
  client: { id: clientUri } = {},
  programUri,
  availableToBillFilter: {
    clients: clientUris,
    projects: projectUris,
    programs: programUris,
    dateRange: billingPeriod
  } = {},
  isPsaPrpIncludeNonZeroAmountOnlyInAvailableToBillEnabled
}) => {
  const filter = {
    billingPeriod
  };
  const clientFilter = { clientUris: clientUri ? [clientUri] : clientUris };
  const projectFilter = {
    projectUris: projectUri ? [projectUri] : projectUris
  };
  const programFilter = {
    programUris: programUri ? [programUri] : programUris
  };

  if (clientFilter.clientUris) filter.clientUris = clientFilter.clientUris;
  if (projectFilter.projectUris) filter.projectUris = projectFilter.projectUris;
  if (programFilter.programUris) filter.programUris = programFilter.programUris;
  if (isPsaPrpIncludeNonZeroAmountOnlyInAvailableToBillEnabled)
    filter.excludeZeroAmount = true;

  return filter;
};
