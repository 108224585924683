import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { DialogActions, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const NotificationActions = ({
  routes: { notifications: notificationsRoute },
  classes,
  toggleOpen,
  onSettingsClick,
  onMarkAllNotificationAsRead,
  showMarkAllAsRead
}) => (
  <div className={classes.actionButtons}>
    <Divider />
    <DialogActions className={classes.notificationActions}>
      <Button onClick={onSettingsClick} className={classes.actionButton}>
        <FormattedMessage id="notificationsMenu.settings" />
      </Button>
      {showMarkAllAsRead && (
        <Button
          data-qe-id="markAllAsRead"
          onClick={onMarkAllNotificationAsRead}
          className={classes.actionButton}
        >
          <FormattedMessage id="notificationsMenu.markAllAsRead" />
        </Button>
      )}
      <Button
        data-qe-id="seeAll"
        color="primary"
        component={React.forwardRef((props, ref) => (
          <Link to={notificationsRoute.path} {...props} ref={ref} />
        ))}
        onClick={toggleOpen}
        className={classes.actionButton}
      >
        <FormattedMessage id="notificationsMenu.seeAll" />
      </Button>
    </DialogActions>
  </div>
);

NotificationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.object,
  toggleOpen: PropTypes.func,
  onMarkAllNotificationAsRead: PropTypes.func,
  onSettingsClick: PropTypes.func,
  showMarkAllAsRead: PropTypes.bool.isRequired
};

export default NotificationActions;
