import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { moneyFragment } from '~/modules/common/fragments';

export const GET_PROJECT_TOTALS_QUERY = gql`
  query Eager_getProjectTotals(
    $projectFilter: ProjectFilter2
    $isManaged: Boolean
    $includeTotalBudgetCostAmountInPortfolioCurrency: Boolean = false
    $includeTotalActualCostAmountInPortfolioCurrency: Boolean = false
    $includeTotalEstimatedCostAtCompletionInPortfolioCurrency: Boolean = false
    $includeTotalEstimatedBudgetCostRemainingInPortfolioCurrency: Boolean = false
  ) {
    projectTotals(projectFilter: $projectFilter, isManaged: $isManaged) {
      totalEstimatedHours
      totalEstimatedHoursAtCompletion
      totalActualHours
      totalBudgetHours
      totalBudgetHoursUsed
      totalRolledUpEstimatedRemainingHours
      totalBudgetHoursRemaining
      totalScopeHoursCompleted
      totalEstimatedBudgetHoursRemaining
      totalTaskHours
      totalEstimatedContract {
        ...MoneyFragment
      }
      totalBudgetedCost {
        ...MoneyFragment
      }
      totalBudgetCostAmountInPortfolioCurrency
        @include(if: $includeTotalBudgetCostAmountInPortfolioCurrency)
      totalEstimatedCost {
        ...MoneyFragment
      }
      totalActualResourceCost {
        ...MoneyFragment
      }
      totalActualExpenseCost {
        ...MoneyFragment
      }
      totalActualCost {
        ...MoneyFragment
      }
      totalActualCostAmountInPortfolioCurrency
        @include(if: $includeTotalActualCostAmountInPortfolioCurrency)
      totalEstimatedBilling {
        ...MoneyFragment
      }
      totalActualBilling {
        ...MoneyFragment
      }
      totalActualRevenue {
        ...MoneyFragment
      }
      totalEstimatedCostAtCompletion {
        ...MoneyFragment
      }
      totalEstimatedCostAtCompletionInPortfolioCurrency
        @include(if: $includeTotalEstimatedCostAtCompletionInPortfolioCurrency)
      totalBudgetCostUsedPercentage
      totalEstimatedBudgetCostRemaining {
        ...MoneyFragment
      }
      totalEstimatedBudgetCostRemainingInPortfolioCurrency
        @include(
          if: $includeTotalEstimatedBudgetCostRemainingInPortfolioCurrency
        )
    }
  }
  ${moneyFragment}
`;

export const useGetProjectTotalsQuery = options =>
  useQuery(GET_PROJECT_TOTALS_QUERY, options);
