import { CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import NotificationPolicy from './NotificationPolicy';
import NotificationPolicyHeader from './NotificationPolicyHeader';

export const NotificationSettingsDialogContent = () => {
  const { setFieldValue, values, isSubmitting } = useFormikContext();
  const { notificationPolicies } = values;

  const updateNotificationPolicy = useCallback(
    policy =>
      setFieldValue(
        'notificationPolicies',
        notificationPolicies.map(np => (np.id === policy.id ? policy : np))
      ),
    [notificationPolicies, setFieldValue]
  );

  if (isSubmitting) return <CircularProgress size={24} />;

  return (
    <>
      <NotificationPolicyHeader />
      {notificationPolicies.map(policy => (
        <NotificationPolicy
          key={policy.id}
          policy={policy}
          updateNotificationPolicy={updateNotificationPolicy}
        />
      ))}
    </>
  );
};

NotificationSettingsDialogContent.propTypes = {};

export default NotificationSettingsDialogContent;
