import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { useProjectObjectPermissions } from '~/modules/common/hooks/project';
import { useHasPermission } from '~/modules/common/permissions';
import CopyingDialogContent from './CopyingDialogContent';
import CopyProjectDialogContent from './CopyProjectDialogContent';
import { useCopyTaskPermissions } from './hooks';
import { useForm } from './useForm';

export const CopyProjectDialogDetails = ({
  client,
  project = {},
  onClose,
  program,
  portfolio,
  screenFlow,
  projectType,
  targetProject,
  ...rest
}) => {
  const { canViewBillingContracts } = useProjectObjectPermissions({
    project
  });
  const canCopyBillPlan = Boolean(canViewBillingContracts);

  const canEditPortfolio = useHasPermission({
    actionUri: 'urn:replicon:project-action:edit-portfolio'
  });

  const canCopyTasks = useCopyTaskPermissions();

  const { form, jobState } = useForm({
    client,
    project,
    program,
    portfolio,
    screenFlow,
    projectType,
    canCopyTasks,
    targetProject,
    canCopyBillPlan
  });
  const { handleReset } = form;

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  return (
    <>
      {jobState.jobInProgress ? (
        <CopyingDialogContent jobState={jobState} onClose={handleClose} />
      ) : (
        <CopyProjectDialogContent
          onClose={handleClose}
          screenFlow={screenFlow}
          canCopyTasks={canCopyTasks}
          canEditPortfolio={canEditPortfolio}
          projectTemplateSetting={project?.projectTemplateSetting}
          {...rest}
          {...form}
        />
      )}
    </>
  );
};

CopyProjectDialogDetails.propTypes = {
  client: PropTypes.object,
  project: PropTypes.object,
  onClose: PropTypes.func,
  program: PropTypes.object,
  portfolio: PropTypes.object,
  screenFlow: PropTypes.string,
  projectType: PropTypes.string,
  targetProject: PropTypes.object
};

export default CopyProjectDialogDetails;
