import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useProposeTopMatch } from '~/modules/resourcing/common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2, 1, 1.5),
    borderRadius: theme.spacing(2.5),
    color: theme.palette.common.white,
    backgroundImage: `linear-gradient(119deg, ${theme.palette.Dela.button.gradient.purple} 17.59%, ${theme.palette.Dela.button.gradient.blue} 77.78%)`,
    boxShadow: 'none',
    zIndex: 5
  },
  text: {
    textTransform: 'uppercase'
  }
}));

export const ResourceRequestProposeAllButton = ({ resourceRequestUris }) => {
  const classes = useStyles();
  const { proposeTopMatch } = useProposeTopMatch();

  const handleSubmitAll = useCallback(
    event => {
      proposeTopMatch({ resourceRequestUris });
    },
    [resourceRequestUris]
  );

  return (
    <Button
      className={classes.root}
      onClick={handleSubmitAll}
      variant="contained"
    >
      <FormattedMessage
        className={classes.text}
        id="resourceRequestChartBanner.proposeAll"
      />
    </Button>
  );
};

ResourceRequestProposeAllButton.propTypes = {
  resourceRequestUris: PropTypes.array.isRequired
};

export default ResourceRequestProposeAllButton;
