import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import { retryLoading } from '~/util';
import TaskBurndownSectionSkeleton from './TaskBurndownSectionSkeleton';
import TaskBurndownList from './TaskBurndownList/TaskBurndownList';
import { useTaskBurndownDataQuery } from './useTaskBurndownDataQuery';

export const TaskBurndownChart = React.lazy(() =>
  retryLoading(() =>
    import('~/modules/common/components/TaskDrawer/Charts/TaskBurndownChart')
  )
);

const useStyles = makeStyles(theme => ({
  root: ({ isPsaPpmEstimatedCostAtCompletionEnabled }) => ({
    width: '100%',
    overflowY: 'visible',
    [theme.breakpoints.down('sm')]: {
      paddingTop: isPsaPpmEstimatedCostAtCompletionEnabled
        ? theme.spacing(3)
        : 0
    }
  }),
  loading: {
    paddingTop: theme.spacing(3)
  },
  header: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary
  },
  noData: { padding: theme.spacing(2) }
}));

export const TaskBurndownSection = ({ task, isDesktop }) => {
  const {
    featureFlags: { isPsaPpmEstimatedCostAtCompletionEnabled }
  } = useMeContext();
  const classes = useStyles({
    isPsaPpmEstimatedCostAtCompletionEnabled
  });
  const { taskBurndownData, loading } = useTaskBurndownDataQuery({ task });

  return (
    <div className={classes.root}>
      {!loading && taskBurndownData.length === 0 ? (
        <div className={classes.noData}>
          <NoDataItem>
            <FormattedMessage id="taskDrawer.No timesheet data available" />
          </NoDataItem>
        </div>
      ) : (
        !loading && (
          <>
            {isDesktop && (
              <TaskBurndownChart
                task={task}
                timesheetEntries={taskBurndownData}
              />
            )}
            <span className={classes.header}>
              <FormattedMessage id="taskDrawer.taskHours" />
            </span>
            <TaskBurndownList task={task} records={taskBurndownData} />
          </>
        )
      )}
      {loading && <TaskBurndownSectionSkeleton />}
    </div>
  );
};

TaskBurndownSection.propTypes = {
  task: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool
};

export default TaskBurndownSection;
