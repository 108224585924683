import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ListItemText } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { v4 } from 'uuid';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { ExpenseEntryType } from '~/types';

const useStyles = makeStyles(theme => ({
  root: { width: theme.spacing(20) },
  inputRoot: {
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    '&::before': {
      borderBottom: 'unset !important'
    },
    '& .MuiAutocomplete-input': {
      paddingLeft: '0 !important'
    },
    '& .MuiInputBase-input': {
      fontSize: theme.typography.body2.fontSize
    }
  },
  popper: {
    minWidth: theme.spacing(24)
  },
  option: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0, 2)
  }
}));

const useTextStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const getOptionLabel = o => o.displayText;
const getOptionSelected = (o, v) => o.id === v.id;

const resourceKeyByOptionId = {
  [ExpenseEntryType.Billable]: 'expenseEntryOptions.billable',
  [ExpenseEntryType.NonBillable]: 'expenseEntryOptions.nonBillable',
  [ExpenseEntryType.BillableAndNonBillable]:
    'expenseEntryOptions.billableAndNonBillable'
};

const getOptions = ({ formatMessage }) =>
  [
    ExpenseEntryType.Billable,
    ExpenseEntryType.NonBillable,
    ExpenseEntryType.BillableAndNonBillable
  ].map(id => ({
    id,
    displayText: formatMessage({ id: resourceKeyByOptionId[id] })
  }));

export const BillableType = ({ editable, field, index, record }) => {
  const { setFieldValue } = useFormikContext();
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const textClasses = useTextStyles();

  const options = getOptions({ formatMessage });

  const value = useMemo(() => {
    const valueId = record[field] || ExpenseEntryType.NonBillable;

    return {
      id: valueId,
      displayText: formatMessage({ id: resourceKeyByOptionId[valueId] })
    };
  }, [field, formatMessage, record]);

  const renderOption = useCallback(
    option => (
      <ListItemText
        primary={option.displayText}
        className={textClasses.primary}
      />
    ),
    [textClasses]
  );

  const fieldsWithEstimatedBillableAmount = useMemo(
    () => Object.keys(record).filter(k => record[k]?.estimatedBillableAmount),
    [record]
  );

  const fieldsWithEstimatedNonBillableAmount = useMemo(
    () =>
      Object.keys(record).filter(k => record[k]?.estimatedNonBillableAmount),
    [record]
  );

  const onChange = useCallback(
    newValue => {
      if (value.id === newValue.id) return;

      const updatedRecord = { ...record };

      if (newValue.id === ExpenseEntryType.NonBillable) {
        updatedRecord.markUp = 1;
        updatedRecord.scriptId = null;
        fieldsWithEstimatedBillableAmount.forEach(k => {
          updatedRecord[k].estimatedBillableAmount = 0;
        });
      } else if (newValue.id === ExpenseEntryType.Billable) {
        updatedRecord.scriptId = v4();
        fieldsWithEstimatedNonBillableAmount.forEach(k => {
          updatedRecord[k].estimatedNonBillableAmount = 0;
        });
      } else {
        updatedRecord.scriptId = v4();
      }

      updatedRecord.billableType = newValue.id;

      setFieldValue(`allowedExpensesAndEstimates[${index}]`, updatedRecord);
    },
    [
      fieldsWithEstimatedBillableAmount,
      fieldsWithEstimatedNonBillableAmount,
      index,
      record,
      setFieldValue,
      value.id
    ]
  );

  return (
    <SimpleAutocomplete
      dataQeId="ExpenseEntryOptions"
      clearOnBlur
      value={value}
      disabled={!editable}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      classes={classes}
      disableClearable
      ariaLabel={formatMessage({
        id: 'expenseBillPlan.billableType'
      })}
    />
  );
};

BillableType.propTypes = {
  editable: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired
};

export const BillableTypeEditor = props => <BillableType {...props} editable />;
export const BillableTypeFormatter = props => (
  <BillableType {...props} editable={false} />
);
