import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  description: {
    whiteSpace: 'pre-line',
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    width: '520px'
  }
}));

export const RemoveResourceConfirmationDialog = ({
  open,
  closeDialog,
  selectedResourcesCount,
  isSubmitting,
  onConfirmClick
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={useDialogStyles()}>
      <>
        <DialogTitle>
          <FormattedMessage id="vendorListTable.confirmationTitleForRemoveResource" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.description}>
            <FormattedMessage
              id="vendorListTable.confirmationMessageForDisableResources"
              values={selectedResourcesCount}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" disabled={isSubmitting}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            isLoading={isSubmitting}
            color="secondary"
            autoFocus
            onClick={onConfirmClick}
          >
            <FormattedMessage id="vendorListTable.remove" />
          </LoadingButton>
        </DialogActions>
      </>
    </Dialog>
  );
};

RemoveResourceConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  selectedResourcesCount: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onConfirmClick: PropTypes.func
};

export default RemoveResourceConfirmationDialog;
