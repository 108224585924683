import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useIntl } from 'react-intl';
import { AppSettingsIcon } from '~/modules/common/components/Icons';

const redirectToNativeMobileApp = () => {
  window.postMessage(
    JSON.stringify({ action: 'REDIRECT_TO_MOBILE_APP_INTENTION_ACTION' })
  );

  return false;
};

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(6),
    paddingLeft: theme.spacing(0.5)
  }
}));

export const AppSettingsMenuItem = ({ classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();

  return (
    <MenuItem onClick={redirectToNativeMobileApp} classes={classes}>
      <ListItemIcon className={classes.icon}>
        <AppSettingsIcon />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({
          id: 'menu.appSettings'
        })}
        classes={{ primary: classes.primary }}
      />
    </MenuItem>
  );
};

AppSettingsMenuItem.propTypes = {
  classes: PropTypes.object
};

export default AppSettingsMenuItem;
