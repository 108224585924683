import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { hasErrors } from '~/util';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import EditorPopoverFooterLayout from '../../common/components/PopoverFooterLayout';

const EditorPopoverFooter = ({ onClose, hasTaskResourceUserAllocation }) => {
  const {
    isSubmitting,
    submitForm,
    values: { projectAvailableHours, allocatedHours },
    errors
  } = useFormikContext();

  const {
    dialogProps: { openRemoveAllocationDialog }
  } = useTaskEstimateRowContext();

  const onRemoveAllocatedTimeClick = useCallback(() => {
    openRemoveAllocationDialog();
    onClose();
  }, [onClose, openRemoveAllocationDialog]);

  const remainingHours =
    roundToDecimals(projectAvailableHours) - roundToDecimals(allocatedHours);

  return (
    <EditorPopoverFooterLayout
      onClose={onClose}
      showMoreMenu={hasTaskResourceUserAllocation}
      onMenuClick={onRemoveAllocatedTimeClick}
      submitForm={submitForm}
      isLoading={isSubmitting}
      isDisabled={hasErrors(errors) || remainingHours < 0}
    />
  );
};

EditorPopoverFooter.propTypes = {
  onClose: PropTypes.func,
  hasTaskResourceUserAllocation: PropTypes.bool.isRequired
};

export default EditorPopoverFooter;
