import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { compact } from 'lodash';
import {
  parameterDefaultValueFormatterByType,
  parameterDefaultValueFormatterByKeyUri
} from '~/modules/projects/project/common/scriptEnhancers';
import { useMeContext } from '~/modules/me';
import { setPrerequisites } from '../helpers';
import {
  planParameterEnhancers,
  estimatedAmountParameter
} from './useBillPlanEnhancers';

export const getBillingContractClausesQuery = gql`
  query getProjectBillingContractClauses {
    billingContractClauses {
      id
      slug
      displayText
      script {
        id
        name
        description
      }
      parameters
    }
  }
`;

const normalizeScriptParams = ({ parameters, slug }, me) => {
  const hiddenColumns = compact([
    'urn:replicon:script-key:parameter:relative-date',
    'urn:replicon:script-key:parameter:frequency-formatted',
    !me?.featureFlags?.isPsaPrpExpenseApprovalInExpenseBillPlanEnabled &&
      slug === 'expenses' &&
      'urn:replicon:script-key:parameter:approval-status',
    'urn:replicon:billing-item-script-key:filtered-triggers:actual',
    'urn:replicon:billing-item-script-key:filtered-triggers:forecast',
    'urn:replicon:billing-item-script-key:filtered-triggers:invoiceable',
    'urn:replicon:billing-item-script-key:shiftable-date-script-key',
    'urn:replicon:script-key:billing-contract-clause-script-pre-requisites'
  ]);

  return parameters
    .filter(
      param =>
        Boolean(param.value && param.value.collection) &&
        !hiddenColumns.includes(param.keyUri)
    )
    .map(parameter => {
      const defaultValue = get(parameter, 'value.collection[1]');
      const valueType = get(parameter, 'value.collection[0].text');
      const valueFormatter =
        parameterDefaultValueFormatterByKeyUri[parameter.keyUri] ||
        parameterDefaultValueFormatterByType[valueType];

      return {
        id: parameter.keyUri,
        displayText: get(parameter, 'value.text'),
        type: valueType,
        options: get(parameter, 'value.collection[2].collection')
          ? parameter.value.collection[2].collection.map(x => x.text)
          : null,
        sortIndex: get(parameter, 'value.collection[3].number') || 0,
        defaultValue: valueFormatter
          ? valueFormatter(defaultValue, me)
          : defaultValue,
        required: true,
        visible: true
      };
    });
};

export const useProjectBillingClauses = () => {
  const { data: { billingContractClauses = [] } = {}, loading } = useQuery(
    getBillingContractClausesQuery
  );
  const me = useMeContext();
  const clauses = billingContractClauses?.map(clause => {
    const clauseEnhancer = planParameterEnhancers[clause.slug];
    const newClause = clauseEnhancer ? clauseEnhancer(clause) : clause;

    const enhancedClause = {
      ...newClause,
      parameters: [...newClause.parameters, estimatedAmountParameter]
    };

    return {
      ...enhancedClause,
      parameters: normalizeScriptParams(enhancedClause, me),
      billPlanPreRequisites: setPrerequisites(enhancedClause.parameters)
    };
  });

  return { clauses, loading };
};

export default useProjectBillingClauses;
