import { useMemo } from 'react';
import {
  ClientFacet,
  PortfolioFacet,
  ProgramFacet,
  ProjectCoManagerFacet,
  ProjectManagerFacet,
  RevenueRecognitionPolicyFacet,
  TagFacet,
  ProjectLinkTypeFacet
} from '~/modules/common/components/SearchBox/Facets';
import { withCriteriaProvider as withClientCriteriaProvider } from '~/modules/common/components/SearchBox/Facets/ClientFacet/enhancers';
import { withCriteriaProvider as withProgramCriteriaProvider } from '~/modules/common/components/SearchBox/Facets/ProgramFacet/enhancers';
import { useMeContext } from '~/modules/me';
import OverbudgetFacet from '~/modules/projects/components/ProjectListPage/Toolbar/OverbudgetFacet';
import {
  useSearchableProjectClients,
  useSearchableProjectPrograms
} from '~/modules/projects/graphql';
import { useProjectGroupFacets } from '~/modules/projects/hooks';

export default ({
  skipClient = false,
  skipProgram = false,
  skipPortfolio = false,
  skipLinkType = false
} = {}) => {
  const me = useMeContext();
  const {
    featureFlags: {
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPrpProjectLinkEnabled
    },
    permissionsMap
  } = me;

  const canViewProject = Boolean(
    permissionsMap['urn:replicon:project-action:view-project']
  );
  const canViewProjectCostData = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-data']
  );

  const canViewClient = Boolean(
    permissionsMap['urn:replicon:project-action:view-client']
  );

  const canViewPortfolio = Boolean(
    permissionsMap['urn:replicon:project-action:view-portfolio']
  );

  const canViewProgram = Boolean(
    permissionsMap['urn:replicon:project-action:view-program']
  );

  const canViewRevenueContracts = Boolean(
    permissionsMap['urn:replicon:project-action:view-revenue-contract']
  );

  const groupFacets = useProjectGroupFacets();

  return useMemo(() => {
    // ordered array
    const facets = [
      canViewProject && ProjectManagerFacet,
      canViewProject && ProjectCoManagerFacet,
      !skipPortfolio && canViewPortfolio && PortfolioFacet,
      !skipClient &&
        canViewClient && {
          ...ClientFacet,
          makeCriteriaProvider: makeClientCriteriaProvider
        },
      !skipProgram &&
        canViewProgram && {
          ...ProgramFacet,
          makeCriteriaProvider: makeProgramCriteriaProvider
        },
      canViewProject &&
        isPsaPrpProjectLinkEnabled &&
        !skipLinkType &&
        ProjectLinkTypeFacet,
      ...(groupFacets?.length ? groupFacets : []),
      canViewRevenueContracts && RevenueRecognitionPolicyFacet,
      TagFacet,
      OverbudgetFacet
    ].filter(x => x);

    return {
      facets,
      showCostOverBudgetOptions:
        isPsaPpmCostEacEnhancementsEnabled && canViewProjectCostData
    };
  }, [
    canViewProject,
    skipPortfolio,
    canViewPortfolio,
    skipClient,
    canViewClient,
    skipProgram,
    canViewProgram,
    groupFacets,
    canViewRevenueContracts,
    isPsaPpmCostEacEnhancementsEnabled,
    canViewProjectCostData,
    skipLinkType,
    isPsaPrpProjectLinkEnabled
  ]);
};

export const makeClientCriteriaProvider = (tag, context) =>
  withClientCriteriaProvider(tag, context, useSearchableProjectClients);

export const makeProgramCriteriaProvider = (tag, context) =>
  withProgramCriteriaProvider(tag, context, useSearchableProjectPrograms);
