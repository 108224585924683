import React from 'react';
import PropTypes from 'prop-types';
import { useChipMenuProps } from '~/modules/common/components/ChipMenu';
import { StatusChipMenu } from '~/modules/common/components';
import useUpdateVendorStatusHandlers from '../../hooks/useUpdateVendorStatusHandlers';

const VendorStatusChipMenu = ({
  id,
  name,
  closedDate,
  editable = true,
  isActive = true
}) => {
  const { anchorEl, openMenu, closeMenu, open } = useChipMenuProps();
  const {
    onStatusActiveHandler,
    onStatusInactiveHandler
  } = useUpdateVendorStatusHandlers({
    id,
    name,
    isActive,
    closeMenu,
    closedDate
  });

  return (
    <StatusChipMenu
      onInactiveClick={onStatusInactiveHandler}
      onActiveClick={onStatusActiveHandler}
      editable={editable}
      isActive={isActive}
      anchorEl={anchorEl}
      openMenu={openMenu}
      closeMenu={closeMenu}
      open={open}
    />
  );
};

VendorStatusChipMenu.propTypes = {
  editable: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  closedDate: PropTypes.object
};

export default VendorStatusChipMenu;
