import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  makeStyles,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { ProjectRequestStatusType } from '~/types';
import { useUpdateProjectRequestStatus } from './hooks';

const useStyles = makeStyles(theme => ({
  secondaryMessage: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  }
}));

export const ApproveProjectRequestConfirmationDialog = ({
  open,
  onClose,
  projectRequestId
}) => {
  const classes = useStyles();
  const {
    updateProjectRequestStatus,
    isLoading: { loading }
  } = useUpdateProjectRequestStatus();

  const handleSubmit = useCallback(async () => {
    const statusType = ProjectRequestStatusType.Approved;

    await updateProjectRequestStatus({
      projectRequestId,
      statusType
    });
    onClose();
  }, [projectRequestId, updateProjectRequestStatus, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      data-qe-id="ApproveRequestConfirmationDialog"
    >
      <DialogTitle>
        <FormattedMessage id="projectRequest.approvalWarning.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.secondaryMessage}>
          <FormattedMessage id="projectRequest.approvalWarning.message" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          color="secondary"
          disabled={loading}
          isLoading={loading}
        >
          <FormattedMessage id="projectRequestStatus.drawerButtons.approveRequest" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ApproveProjectRequestConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectRequestId: PropTypes.string
};

export default ApproveProjectRequestConfirmationDialog;
