import { Grid, makeStyles, Slider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { useScoreItemHandlers, useScoreMetricLables } from './hooks';

const useStyles = makeStyles(theme => ({
  itemLabel: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirecion: 'row',
      alignItems: 'baseline'
    }
  },
  slider: {
    color: '#3758fb',
    padding: 0,
    height: 6,
    '& .MuiSlider-track': {
      border: 'none'
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: '#3758fb',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
      }
    }
  },
  label: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'inline'
    }
  },
  scoreMetricLableText: {
    ...theme.typography.caption,
    color: ({ scoreMetricValue }) =>
      scoreMetricValue < 1
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    margin: theme.spacing(0, 2, 1, 0)
  }
}));

const valueLabelFormat = value => (value < 1 ? '–' : value);

export const ScoreItem = ({ scoreMetricKey }) => {
  const intl = useIntl();
  const scoreMetricLables = useScoreMetricLables(scoreMetricKey);
  const { canEditProjectRequest } = useProjectRequestObjectPermissions();
  const {
    values: { score, id },
    setFieldValue
  } = useFormikContext();
  const scoreMetricValue = score[scoreMetricKey];
  const classes = useStyles({ scoreMetricValue });
  const { handleSubmitScore, handleScoreChange } = useScoreItemHandlers({
    id,
    setFieldValue,
    score,
    scoreMetricKey
  });

  return (
    <>
      <Grid item xs={2}>
        <label
          id={`${scoreMetricKey}_label`}
          htmlFor={scoreMetricKey}
          className={classes.itemLabel}
        >
          <span className={classes.label}>
            {intl.formatMessage({
              id: `projectRequest.scoreDetails.${scoreMetricKey}`
            })}
          </span>
        </label>
      </Grid>
      <Grid item lg={10} md={10} xs={12}>
        <Slider
          id={scoreMetricKey}
          value={scoreMetricValue}
          valueLabelFormat={valueLabelFormat}
          onChange={handleScoreChange}
          min={0}
          step={1}
          max={5}
          marks
          valueLabelDisplay="auto"
          className={classes.slider}
          onBlur={handleSubmitScore}
          disabled={!canEditProjectRequest}
        />

        <div className={classes.scoreMetricLableText}>
          {scoreMetricLables && (
            <span>{scoreMetricLables[scoreMetricValue]}</span>
          )}
        </div>
      </Grid>
    </>
  );
};

ScoreItem.propTypes = {
  scoreMetricKey: PropTypes.string
};

export default ScoreItem;
