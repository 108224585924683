import React from 'react';
import { retryLoading } from '~/util';
import { getRoutePermissions } from '../common/permissions';
import PortfolioIcon from './PortfolioIcon';

const PortfoliosPage = React.lazy(() =>
  retryLoading(() => import('./PortfoliosPage'))
);

export const portfoliosRoute = ({ intl, me }) => {
  const { canViewPortfolios } = getRoutePermissions(me);

  return {
    path: '/portfolios',
    icon: () => <PortfolioIcon />,
    isSideBarRoute: true,
    title: () => intl.formatMessage({ id: 'routes.portfolios' }),
    showDivider: false,
    component: PortfoliosPage,
    hidden: !canViewPortfolios,
    hasPermission: canViewPortfolios
  };
};
