import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { NoValue } from '~/modules/common/components';
import { ProjectHistoryListTable } from './ProjectHistoryListTable';

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(0, 0, 0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    left: 0,
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 25
  },
  infoContainer: {
    padding: theme.spacing(2, 2),
    position: 'sticky',
    left: 0,
    top: theme.spacing(6.25),
    backgroundColor: theme.palette.common.white,
    zIndex: 20
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize
  }
}));

export const DrawerContent = ({
  projectDetails,
  onClose,
  hasMoreRows,
  loadMoreRows,
  ...rest
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    displayText,
    revenueContractDetails,
    revenueEntriesHistory
  } = projectDetails;

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        className={classes.toolbar}
      >
        <Grid item xs={11}>
          <Typography variant="h6">
            {intl.formatMessage(
              { id: 'revenueRun.revrecHistory' },
              { displayText }
            )}
          </Typography>
        </Grid>
        <Grid item xs={1} data-qe-id="closeDrawer">
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label={intl.formatMessage({
              id: 'revenueRun.closeIcon'
            })}
          >
            <span
              aria-label={intl.formatMessage({
                id: 'revenueRun.closeButton'
              })}
              role="img"
            >
              <CloseIcon />
            </span>
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        className={classes.infoContainer}
      >
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.title}>
            {intl.formatMessage({ id: 'revenueRun.revrecPolicyInEffect' })}
          </Typography>
          {revenueContractDetails ? (
            <Typography variant="subtitle1">
              {revenueContractDetails.displayText}
            </Typography>
          ) : (
            <NoValue />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={0}
        className={classes.listContainer}
      >
        <ProjectHistoryListTable
          revenueEntriesHistory={revenueEntriesHistory}
          hasMoreRow={hasMoreRows}
          loadMoreRows={loadMoreRows}
          {...rest}
        ></ProjectHistoryListTable>
      </Grid>
    </>
  );
};

DrawerContent.propTypes = {
  projectDetails: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  hasMoreRows: PropTypes.bool,
  loadMoreRows: PropTypes.func
};

export default DrawerContent;
