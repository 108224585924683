import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { List, Divider, makeStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpSharp';
import { CommunityIcon } from '~/modules/common/components/Icons';
import UserAvatar from '~/modules/common/components/Avatars/UserAvatar';
import LogoutMenuItem from '../LogoutMenuItem';
import AppSettingsMenuItem from '../AppSettingsMenuItem';
import ActionLink from '../ActionLink';
import MenuItem from '../MenuItem';
import SettingsActionLink from '../SettingsActionLink';
import PolicyLinks from '../PolicyLinks';
import ZeroTimeLink from '../ZeroTimeLink';

const profileLinkState = {
  showBackButton: true
};

const useStyles = makeStyles(theme => ({
  primary: {
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: theme.typography.fontWeightMedium
  },
  avatar: {
    marginLeft: theme.spacing(0.5)
  },
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: 'auto'
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  policy: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    width: theme.spacing(30),
    margin: 0
  }
}));

export const ProfileMenuList = ({
  hasProfileAccess,
  me,
  isMobileApp,
  location,
  intl,
  hasSettingsAccess,
  drawerHeight,
  menuContainerHeight,
  showZeroTimeLink,
  zeroTimeUrl
}) => {
  const classes = useStyles();

  const icon = useMemo(
    () => (
      <UserAvatar
        user={me}
        size="xsmall"
        classes={{ avatar: classes.avatar }}
      />
    ),
    [me, classes]
  );

  const { customerZoneUrl, helpUrl } = me;

  const ref = useRef(null);
  const policyLinksRef = useRef(null);
  const profileMenuListHeight = ref.current ? ref.current.clientHeight : 0;
  const policyLinkHeight = policyLinksRef.current
    ? policyLinksRef.current.clientHeight
    : 0;

  const hasSpaceToAlignPolicyBottom =
    drawerHeight - (menuContainerHeight + profileMenuListHeight) >
    policyLinkHeight + 5;

  const policyClasses = useMemo(
    () =>
      hasSpaceToAlignPolicyBottom
        ? {
            policyListItem: classes.policy
          }
        : {},
    [classes.policy, hasSpaceToAlignPolicyBottom]
  );

  return (
    <List ref={ref} disablePadding>
      {hasProfileAccess && (
        <MenuItem
          path="/profile"
          icon={icon}
          title={intl.formatMessage({ id: 'menu.myProfile' })}
          state={profileLinkState}
          selected={location.pathname === '/profile'}
          disableIconPadding
        />
      )}
      {!isMobileApp && hasSettingsAccess && (
        <li>
          <SettingsActionLink intl={intl} classes={classes} />
        </li>
      )}
      {isMobileApp && <AppSettingsMenuItem classes={classes} />}
      {showZeroTimeLink && isMobileApp ? (
        <li>
          <Divider className={classes.divider} light />
          <ZeroTimeLink zeroTimeUrl={zeroTimeUrl} />
          <Divider className={classes.divider} light />
        </li>
      ) : null}
      {isMobileApp && <LogoutMenuItem isMobileApp classes={classes} />}
      {isMobileApp && <Divider className={classes.divider} light />}
      {showZeroTimeLink && !isMobileApp ? (
        <li>
          <Divider className={classes.divider} light />
          <ZeroTimeLink intl={intl} />
          <Divider className={classes.divider} light />
        </li>
      ) : null}
      <ActionLink
        link={customerZoneUrl}
        label={intl.formatMessage({ id: 'customerZone.title' })}
        Icon={CommunityIcon}
        classes={classes}
      />
      <ActionLink
        link={helpUrl}
        label={intl.formatMessage({ id: 'helpZone.title' })}
        Icon={HelpIcon}
        classes={classes}
      />
      {!isMobileApp && (
        <LogoutMenuItem isMobileApp={false} me={me} classes={classes} />
      )}
      <PolicyLinks classes={policyClasses} />
    </List>
  );
};

ProfileMenuList.propTypes = {
  hasProfileAccess: PropTypes.bool,
  me: PropTypes.object,
  intl: PropTypes.object,
  isMobileApp: PropTypes.bool,
  location: PropTypes.object,
  zeroTimeUrl: PropTypes.string,
  hasSettingsAccess: PropTypes.bool,
  drawerHeight: PropTypes.number,
  menuContainerHeight: PropTypes.number,
  showZeroTimeLink: PropTypes.bool.isRequired
};

export default ProfileMenuList;
