import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const clientHoursQuery = gql`
  query GetClientHoursSeries($slug: String!, $dateRange: DateTimeRangeInput!) {
    client(slug: $slug) {
      id
      hourSeriesData(dateRange: $dateRange) {
        period {
          periodStart: periodStart2
          periodEnd: periodEnd2
        }
        timeEnteredActual
        timeEnteredEstimated
      }
    }
  }
`;

export const useClientHourSeries = ({
  chartDataInput: { slug, dateRange }
}) => {
  const { data, loading } = useQuery(clientHoursQuery, {
    variables: { slug, dateRange }
  });

  const hourSeriesData = get(data, 'client.hourSeriesData') || [];

  return {
    loading,
    hourSeriesData: !loading ? hourSeriesData : []
  };
};

export default useClientHourSeries;
