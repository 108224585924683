import { gql } from 'graphql-tag';
import { notificationFragment } from './notificationFragment';

export const MARK_NOTIFICATION_AS_READ_MUTATION = gql`
  mutation MarkNotificationAsRead($id: String!) {
    markNotificationAsRead(id: $id) {
      notification {
        ...Notification
      }
    }
  }
  ${notificationFragment}
`;
