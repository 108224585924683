import { useHistory } from 'react-router-dom';

export const useOnCreatePortfolioProjectHistoryState = () => {
  const history = useHistory();

  return {
    goBackButtonPath: history.location.pathname
  };
};

export default useOnCreatePortfolioProjectHistoryState;
