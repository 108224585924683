import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import ConfirmProjectRequestMoveDialogTable from './ConfirmProjectRequestMoveDialogTable';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  confirmationMessage: {
    padding: theme.spacing(2),
    width: '100%'
  },
  actions: {
    flexWrap: 'wrap'
  }
}));

const ConfirmProjectRequestMoveDialog = ({
  portfolioName,
  open,
  onClose,
  onConfirm,
  projectRequests,
  submitting
}) => {
  const classes = useStyles();

  const titleMessageValues = useMemo(
    () => ({ value: projectRequests.length }),
    [projectRequests]
  );

  const title =
    projectRequests.length === 1 ? (
      <FormattedMessage id="projectRequest.addExistingProjectRequestDialog.projectRequestHasPortfolio" />
    ) : (
      <FormattedMessage
        id="projectRequest.addExistingProjectRequestDialog.xProjectRequestHasPortfolio"
        values={titleMessageValues}
      />
    );

  const handleSubmit = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  const isMobile = useIsBreakpointDown('sm');

  const confirmationMessageValues = useMemo(
    () => ({ portfolio: <b>{portfolioName}</b> }),
    [portfolioName]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <ConfirmProjectRequestMoveDialogTable
          projectRequests={projectRequests}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Typography className={classes.confirmationMessage}>
          <FormattedMessage
            id="projectRequest.addExistingProjectRequestDialog.moveToPortfolioConfirmation"
            values={confirmationMessageValues}
          />
        </Typography>
        <Button onClick={onClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          disabled={submitting}
          isLoading={false}
          onClick={handleSubmit}
          color="secondary"
        >
          <FormattedMessage id="projectRequest.addExistingProjectRequestDialog.moveProjectRequests" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmProjectRequestMoveDialog.propTypes = {
  portfolioName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  projectRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitting: PropTypes.bool
};

export default ConfirmProjectRequestMoveDialog;
