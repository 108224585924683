import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import { usePortfolioPageEditContext } from '../../PortfolioPageEditContext';
import ProjectListTable from './ProjectListTable';
import Toolbar from './Toolbar';
import AddPortfolioProject from './AddPortfolioProject';

const useStyles = makeStyles(theme => ({
  root: { margin: theme.spacing(2, 2, 0, 2) }
}));

export const ProjectList = () => {
  const classes = useStyles();
  const { edit, loading, portfolio } = usePortfolioPageEditContext();

  return (
    <>
      <ErrorBoundary>
        {loading ? (
          <Skeleton height={40} className={classes.root} />
        ) : (
          <>
            <Toolbar portfolioId={portfolio.id} />
            <ProjectListTable portfolio={portfolio} />
          </>
        )}
      </ErrorBoundary>
      <AddPortfolioProject editable={edit} />
    </>
  );
};

ProjectList.propTypes = {};

export default ProjectList;
