import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TableSortLabel as MuiTableSortLabel } from '@material-ui/core';

const headerCellStyle = align => ({
  flexDirection: align === 'right' ? 'row-reverse' : 'row'
});

const TableSortLabel = ({
  show,
  field,
  customFieldUri,
  children,
  onClick,
  align,
  ...rest
}) => {
  const onSortClick = useCallback(() => onClick(field, customFieldUri), [
    customFieldUri,
    field,
    onClick
  ]);

  if (!show) {
    return <>{children}</>;
  }

  return (
    <MuiTableSortLabel
      style={headerCellStyle(align)}
      onClick={onSortClick}
      {...rest}
    >
      {children}
    </MuiTableSortLabel>
  );
};

TableSortLabel.propTypes = {
  show: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  align: PropTypes.string,
  ...MuiTableSortLabel.propTypes
};

export default TableSortLabel;
