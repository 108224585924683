import { object, mixed, number } from 'yup';
import { useIntl } from 'react-intl';
import { isNumeric } from '~/modules/common/numbers';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';

const getInitialValuesEditMode = ({
  resourceEstimate: {
    projectRole,
    initialEstimatedHours,
    taskResourceEstimateId
  }
}) => ({
  role: projectRole,
  initialEstimatedHours,
  taskResourceEstimateId
});

export const validationSchema = ({ intl }) =>
  object().shape({
    startDate: mixed().test(
      'startDate',
      intl.formatMessage({
        id: 'addResourceEstimateDialog.validations.startDateRequired'
      }),
      function validate() {
        return (
          this.parent.startDate || !this.parent.isCreateTaskAllocationChecked
        );
      }
    ),
    endDate: mixed().test(
      'endDate',
      intl.formatMessage({
        id: 'addResourceEstimateDialog.validations.endDateRequired'
      }),
      function validate() {
        return (
          this.parent.endDate || !this.parent.isCreateTaskAllocationChecked
        );
      }
    ),
    initialEstimatedHours: number()
      .nullable()
      .test(
        'initialEstimatedHours',
        intl.formatMessage({
          id:
            'addResourceEstimateDialog.validations.initialEstimatedHoursRequired'
        }),
        function validate(value) {
          return (
            (isNumeric(value) && value !== 0) ||
            !this.parent.isCreateTaskAllocationChecked
          );
        }
      )
  });

const useAddResourceEstimateDialogFormState = ({
  task,
  totalResourceEstimateHours,
  resourceEstimate,
  canEditCompletedResourceAllocation
}) => {
  const intl = useIntl();

  const { assignedRole, initialEstimatedHours, startDate, endDate } = task;

  const initialValues = {
    role: assignedRole || null,
    resource: null,
    initialEstimatedHours:
      initialEstimatedHours > totalResourceEstimateHours
        ? roundToDecimals(initialEstimatedHours - totalResourceEstimateHours)
        : null,
    isCreateTaskAllocationChecked: canEditCompletedResourceAllocation,
    startDate: startDate || null,
    endDate: endDate || null,
    ...(resourceEstimate && getInitialValuesEditMode({ resourceEstimate }))
  };

  return {
    initialValues,
    validationSchema: validationSchema({ intl })
  };
};

export default useAddResourceEstimateDialogFormState;
