import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import AddPortfolioForm from '~/modules/portfolios/PortfoliosPage/components/AddPortfolio/AddPortfolioForm';
import DialogButtonActions from '~/modules/common/components/DialogButtonActions';
import { useAddPortfolio } from '~/modules/portfolios/PortfoliosPage/components/AddPortfolio/hooks/useAddPortfolio';

const savePortfolioLabel = <FormattedMessage id="button.save" />;

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const putMutationProps = {
  refetchQueries: ['portfolio']
};

export const EditPortfolioBasicInfoDialog = ({ open, onClose, portfolio }) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  const { validationSchema, onSubmit, initialState } = useAddPortfolio({
    onClose,
    portfolio,
    putMutationProps
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        fullScreen={isMobile}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <DescriptionIcon className={classes.icon} />
          <Typography variant="h6">{portfolio?.name}</Typography>
        </DialogTitle>
        <DialogContent>
          <AddPortfolioForm isNew={false} />
        </DialogContent>
        <DialogButtonActions
          onClose={onClose}
          primaryButtonMessage={savePortfolioLabel}
        />
      </Dialog>
    </Formik>
  );
};

EditPortfolioBasicInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  portfolio: PropTypes.object.isRequired
};

export default EditPortfolioBasicInfoDialog;
