import React, { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { useIsRTL, useDialogState } from '~/modules/common/hooks';
import {
  ListTable,
  PAGINATION_TYPE,
  ListTableThemeProvider,
  ListTableNavigationContext
} from '~/modules/common/components/ListTable';
import ErrorMessage from '~/modules/vendors/components/ErrorMessage';
import { useVendorPageEditContext } from '../../VendorPageEditContext';
import { useVendorResources } from './hooks/useVendorResources';
import { useVendorResourcesStatesHandlers } from './hooks/useVendorResourcesStatesHandlers';
import NoResourcesMessage from './components/NoResourcesMessage';
import RemoveActionForResource from './components/RemoveActionForResource';
import RemoveResourceConfirmationDialog from './components/RemoveResourceConfirmationDialog';
import { useListColumns } from './useListColumns';
import { useListHeaders } from './useListHeaders';
import { useRemoveUserAssignmentForVendor } from './hooks/useRemoveUserAssignmentForVendor';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate'
  },
  headerCellWithNavigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.body2.fontSize
  },
  tableHeaderCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    overflowY: 'visible',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const useContainerClasses = makeStyles(() => ({
  root: {
    minWidth: 'max-content'
  }
}));

const VENDOR_RESOURCES_PAGE_SIZE = 20;

export const VendorResourcesListTable = ({
  currentPage,
  setCurrentPage,
  isEditable,
  selectedVendorResourcesItems,
  setSelectedVendorResourcesItems
}) => {
  const { vendor } = useVendorPageEditContext();
  const isRTL = useIsRTL();
  const providerClasses = useListTableThemeProviderStyles();
  const containerClasses = useContainerClasses();

  const { open, openDialog, closeDialog } = useDialogState(false);
  const {
    removeUserAssignmentForVendor,
    loading: isSubmitting
  } = useRemoveUserAssignmentForVendor();

  const {
    loadMore,
    hasMore,
    loadingMore,
    loading,
    error,
    userAssignments
  } = useVendorResources({
    id: vendor.id,
    page: currentPage,
    pageSize: VENDOR_RESOURCES_PAGE_SIZE
  });

  const vendoresourcesListNavigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: VENDOR_RESOURCES_PAGE_SIZE,
      disableInfiniteScroll: true
    }),
    [setCurrentPage, currentPage]
  );

  const {
    selectedResourcesCount,
    resetSelectedResourcesItems,
    isRowSelected,
    onSelectClick,
    onConfirmClick,
    onClearClick
  } = useVendorResourcesStatesHandlers({
    selectedVendorResourcesItems,
    setSelectedVendorResourcesItems,
    removeUserAssignmentForVendor,
    closeDialog
  });

  const columns = useListColumns({
    isVendorResourcesListTable: true,
    isEditable,
    vendorResourcesStates: {
      isRowSelected,
      onSelectClick
    },
    isRTL
  });
  const headers = useListHeaders({
    isVendorResourcesListTable: true,
    isEditable
  });

  useEffect(() => {
    if (!isEditable) resetSelectedResourcesItems();
  }, [isEditable, resetSelectedResourcesItems]);

  if (!loading && error) {
    return <ErrorMessage />;
  }

  if (!loading && userAssignments.length === 0 && currentPage === 1) {
    return <NoResourcesMessage />;
  }

  return (
    <>
      <div className={containerClasses.root}>
        <ListTableThemeProvider classes={providerClasses}>
          <ListTableNavigationContext.Provider
            value={vendoresourcesListNavigationContext}
          >
            <ListTable
              variant="table"
              records={userAssignments}
              headers={headers}
              columns={columns}
              elevation={0}
              hasMore={hasMore}
              loadMore={loadMore}
              isLoading={loading}
              showLoadingComponent={loading && !loadingMore}
              paginationType={PAGINATION_TYPE.NAVIGATION}
            />
          </ListTableNavigationContext.Provider>
        </ListTableThemeProvider>
        {open && (
          <RemoveResourceConfirmationDialog
            open={open}
            selectedResourcesCount={selectedResourcesCount}
            closeDialog={closeDialog}
            isSubmitting={isSubmitting}
            onConfirmClick={onConfirmClick}
          />
        )}
      </div>
      {selectedVendorResourcesItems.length ? (
        <RemoveActionForResource
          openDialog={openDialog}
          selectedResourcesCount={selectedResourcesCount}
          onClearClick={onClearClick}
        />
      ) : null}
    </>
  );
};

VendorResourcesListTable.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isEditable: PropTypes.bool,
  selectedVendorResourcesItems: PropTypes.array,
  setSelectedVendorResourcesItems: PropTypes.func
};

export default VendorResourcesListTable;
