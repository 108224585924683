import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsSharp';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(6),
    paddingLeft: theme.spacing(0.5)
  }
}));

export const SettingsActionLink = forwardRef(
  ({ onClose, intl, classes: classesOverride }, ref) => {
    const classes = useStyles({ classes: classesOverride });

    return (
      <MenuItem
        component={Link}
        ref={ref}
        to="/settings"
        onClick={onClose}
        underline="none"
        classes={classes}
      >
        <ListItemIcon className={classes.icon}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'settingsActionLink.settings' })}
          classes={{ primary: classes.primary }}
        />
      </MenuItem>
    );
  }
);

SettingsActionLink.propTypes = {
  onClose: PropTypes.func,
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object
};

export default SettingsActionLink;
