import { useMarkResourceRequestAsCompleteAndMerge } from '../../hooks';
import useMarkResourceRequestAsComplete from './useMarkResourceRequestAsComplete';
import useMarkResourceRequestAsTentative from './useMarkResourceRequestAsTentative';
import useMarkResourceRequestAsSubmitted from './useMarkResourceRequestAsSubmitted';
import useMarkResourceRequestAsRejected from './useMarkResourceRequestAsRejected';
import useMarkResourceRequestAsToBeHired from './useMarkResourceRequestAsToBeHired';
import useMarkResourceRequestAsAllocationRejected from './useMarkResourceRequestAsAllocationRejected';
import useMarkResourceRequestAsDraft from './useMarkResourceRequestAsDraft';

export const useResourceRequestStatusHandlers = ({
  resourceRequest,
  onMarkResourceRequestAsCompleteAndMergeCompleted
}) => {
  const { onMarkResourceRequestAsComplete } = useMarkResourceRequestAsComplete({
    resourceRequest
  });

  const {
    onMarkResourceRequestAsToBeHired
  } = useMarkResourceRequestAsToBeHired({
    resourceRequest
  });

  const {
    onMarkResourceRequestAsTentative
  } = useMarkResourceRequestAsTentative({
    resourceRequest
  });

  const { onMarkResourceRequestAsRejected } = useMarkResourceRequestAsRejected({
    resourceRequest
  });

  const {
    onMarkResourceRequestAsCompleteAndMerge
  } = useMarkResourceRequestAsCompleteAndMerge({
    resourceRequest,
    onCompleted: onMarkResourceRequestAsCompleteAndMergeCompleted
  });

  const {
    onMarkResourceRequestAsSubmitted
  } = useMarkResourceRequestAsSubmitted({
    resourceRequest
  });

  const {
    onMarkResourceRequestAsAllocationRejected
  } = useMarkResourceRequestAsAllocationRejected({
    resourceRequest
  });

  const { onMarkResourceRequestAsDraft } = useMarkResourceRequestAsDraft({
    resourceRequest
  });

  return {
    onMarkResourceRequestAsComplete,
    onMarkResourceRequestAsToBeHired,
    onMarkResourceRequestAsTentative,
    onMarkResourceRequestAsRejected,
    onMarkResourceRequestAsSubmitted,
    onMarkResourceRequestAsAllocationRejected,
    onMarkResourceRequestAsDraft,
    onMarkResourceRequestAsCompleteAndMerge
  };
};
