import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

export const NotificationLink = ({
  id,
  message,
  path,
  type,
  onMarkNotificationAsRead,
  classes
}) => {
  const intl = useIntl();
  const listItemTextClasses = useMemo(
    () => ({
      primary: classes.primary,
      secondary: classes.secondary
    }),
    [classes]
  );
  const markNotificationAsRead = useCallback(
    () => onMarkNotificationAsRead(id),
    [id, onMarkNotificationAsRead]
  );

  return (
    <MenuItem component={Link} onClick={markNotificationAsRead} to={path}>
      <Tooltip title={message} placement="bottom">
        <ListItemText
          className={classes.notificationItem}
          classes={listItemTextClasses}
          primary={intl.formatMessage({ id: `notificationTypes.${type}` })}
          secondary={message}
        />
      </Tooltip>
    </MenuItem>
  );
};

NotificationLink.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  path: PropTypes.string,
  onMarkNotificationAsRead: PropTypes.func
};

export default NotificationLink;
