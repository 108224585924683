import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import QuickFilterSummaryTabs, {
  MobileQuickFilterSummaryTabs
} from '~/modules/common/components/QuickFilterSummaryTabs';
import { useProjectRequestFilter } from '~/modules/project-request/ProjectRequestPage/hooks';
import useProjectRequestsCountSummaryQuery from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestsSummary/useProjectRequestsCountSummary';
import usePortfolioProjectRequestQuickFilterSummaryTabs from './usePortfolioProjectRequestQuickFilterSummaryTabs';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    right: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2)
  }
}));

export const PortfolioProjectRequestSummary = ({
  isMobile,
  searchCriteria,
  summaryFilterState
}) => {
  const classes = useStyles();

  const { projectRequestFilter } = useProjectRequestFilter({
    searchCriteria
  });

  const {
    loading: isProjectRequestsCountSummaryLoading,
    projectRequestsCountSummary
  } = useProjectRequestsCountSummaryQuery({ projectRequestFilter });

  const {
    loading: isSubPortfoliosProjectRequestCountSummaryLoading,
    projectRequestsCountSummary: subPortfoliosProjectRequestCountSummary
  } = useProjectRequestsCountSummaryQuery({
    projectRequestFilter: {
      ...projectRequestFilter,
      includeSubPortfolios: true
    }
  });

  const {
    allItems,
    summaryItems
  } = usePortfolioProjectRequestQuickFilterSummaryTabs({
    projectRequestsCountSummary,
    subPortfoliosProjectRequestCountSummary,
    filterByAccess: summaryFilterState.quickFilter.filterByAccess
  });
  const isLoading =
    isProjectRequestsCountSummaryLoading ||
    isSubPortfoliosProjectRequestCountSummaryLoading;

  return isMobile ? (
    <MobileQuickFilterSummaryTabs
      showSingleAllItem
      allItems={allItems}
      summaryItems={summaryItems}
      summaryFilterState={summaryFilterState}
      isValueLoading={isLoading}
    />
  ) : (
    <div className={classes.root}>
      <QuickFilterSummaryTabs
        allItems={allItems}
        summaryItems={summaryItems}
        summaryFilterState={summaryFilterState}
        isValueLoading={isLoading}
      />
    </div>
  );
};

PortfolioProjectRequestSummary.propTypes = {
  isMobile: PropTypes.bool,
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object
};

export default PortfolioProjectRequestSummary;
