import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import User from '~/modules/common/components/User';
import {
  SideCard,
  SideCardSectionDetail,
  SideCardSectionHeader
} from '~/modules/common/components/SideCard';
import { CollapsibleDetailsPanel } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useDialogState } from '~/modules/common/hooks';
import { usePortfolioObjectPermissions } from '~/modules/common/hooks/portfolio';
import usePortfolioProjectRequestsPermissions from '../../hooks/usePortfolioProjectRequestsPermissions';
import EditPortfolioBasicInfoDialog from './EditPortfolioBasicInfoDialog';
import EditPortfolioBudgetDialog from './EditPortfolioBudgetDialog';
import ChildPortfolios from './ChildPortfolios';
import BudgetInfo from './BudgetInfo';

const resourceLabels = {
  name: <FormattedMessage id="portfolio.name" />,
  code: <FormattedMessage id="portfolio.code" />,
  description: <FormattedMessage id="portfolio.description" />,
  portfolioManager: <FormattedMessage id="portfolio.portfolioManager" />,
  basicInfoTitle: <FormattedMessage id="portfolio.basicInfo" />
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#fafafa !important',
    marginTop: theme.spacing(2)
  }
}));

export const PortfolioBasicInfo = ({ edit, loading, portfolio }) => {
  const isMobile = useIsBreakpointDown('sm');

  return isMobile ? (
    <CollapsibleDetailsPanel>
      <InfoCard edit={edit} loading={loading} portfolio={portfolio} />
    </CollapsibleDetailsPanel>
  ) : (
    <InfoCard edit={edit} loading={loading} portfolio={portfolio} />
  );
};

export const InfoCard = ({ edit, loading, portfolio }) => {
  const subPortfolioCardClasses = useStyles();

  const {
    id,
    name,
    code,
    description,
    portfolioManager,
    ancestors,
    portfolioCurrency,
    budget,
    permittedActionUris
  } = portfolio;

  const {
    canViewPortfolioProjectRequestsPlanningData
  } = usePortfolioProjectRequestsPermissions({
    portfolioManager,
    ancestors
  });

  const canViewPlanningData = canViewPortfolioProjectRequestsPlanningData;

  const { canViewCostData, canEditCostData } = usePortfolioObjectPermissions(
    permittedActionUris
  );

  const portfolioManagerNode = useMemo(
    () => portfolioManager && <User user={portfolioManager} />,
    [portfolioManager]
  );
  const {
    open: basicDialogOpen,
    openDialog: openBasicDialog,
    closeDialog: closeBasicDialog
  } = useDialogState();

  const {
    open: editBudgetDialogOpen,
    openDialog: openEditBudgetDialog,
    closeDialog: closeEditBudgetDialog
  } = useDialogState();

  return (
    <>
      {!loading && (
        <>
          <SideCard>
            <Grid container spacing={0}>
              <Grid item xs={6} md={12}>
                {edit && (
                  <SideCardSectionHeader
                    title={resourceLabels.basicInfoTitle}
                    onEditClick={openBasicDialog}
                    showEdit={edit}
                    dataQeId="EditBasicInfo"
                    ariaLabelKey="button.basicInfoEditButton"
                  />
                )}
                {edit && name && (
                  <SideCardSectionDetail
                    detail={name}
                    label={resourceLabels.name}
                  />
                )}
                {(edit || code) && (
                  <SideCardSectionDetail
                    detail={code}
                    label={resourceLabels.code}
                  />
                )}
                <SideCardSectionDetail
                  detail={portfolioManagerNode}
                  label={resourceLabels.portfolioManager}
                />
                {(edit || description) && (
                  <SideCardSectionDetail
                    detail={description}
                    label={resourceLabels.description}
                  />
                )}
              </Grid>
              {canViewCostData ? (
                <Grid item xs={6} md={12}>
                  <BudgetInfo
                    portfolioCurrency={portfolioCurrency}
                    budget={budget}
                    edit={canEditCostData && edit}
                    onEditClick={openEditBudgetDialog}
                  />
                </Grid>
              ) : null}
            </Grid>
          </SideCard>
          {basicDialogOpen && (
            <EditPortfolioBasicInfoDialog
              open={basicDialogOpen}
              onClose={closeBasicDialog}
              portfolio={portfolio}
            />
          )}
          <EditPortfolioBudgetDialog
            portfolioCurrency={portfolioCurrency}
            portfolioId={id}
            open={editBudgetDialogOpen}
            budget={budget}
            onClose={closeEditBudgetDialog}
            canViewPlanningData={canViewPlanningData}
          />
        </>
      )}
      <SideCard classes={subPortfolioCardClasses}>
        {name && (
          <ChildPortfolios
            parentId={portfolio.id}
            parentName={name}
            showEdit={edit}
            ancestorsLength={ancestors.length}
          ></ChildPortfolios>
        )}
      </SideCard>
    </>
  );
};

InfoCard.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  portfolio: PropTypes.object
};

PortfolioBasicInfo.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  portfolio: PropTypes.object
};

export default PortfolioBasicInfo;
