import {
  BILLING_INVOICING_PERMISSION_URIS,
  canViewBillingInvoicingTab
} from '~/modules/common/enums';
import { route as infoRoute } from './ClientInfo';
import { route as projectsRoute } from './ClientProjects';
import { route as timeAndExpenseRoute } from './ClientTimeAndExpense';
import { route as billingInvoicingRoute } from './BillingInvoicingTab';

const showRoute = (show, route) => (show ? [route] : []);

export const clientRoutes = ({ me, isMobile }) => [
  infoRoute,
  ...showRoute(
    me.permissionsMap['urn:replicon:project-action:view-project'],
    projectsRoute
  ),
  ...showRoute(!isMobile, timeAndExpenseRoute({ me, isMobile })),
  ...showRoute(
    canViewBillingInvoicingTab({
      me,
      viewBillingDataPermissionUri:
        BILLING_INVOICING_PERMISSION_URIS.VIEW_CLIENT_BILLING_DATA,
      isMobile
    }),
    billingInvoicingRoute()
  )
];
