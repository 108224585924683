import React from 'react';
import { retryLoading } from '~/util';
import { hasPermission } from '../../resourcingRoute';

const ResourceAssignmentDialog = React.lazy(() =>
  retryLoading(() => import('./ResourceAssignmentDialog'))
);

const route = ({ intl, me }) => ({
  path: '/resourcing/assignment/:groupUri/:planStatus/:resourceRequestId',
  icon: () => null,
  hidden: true,
  title: () => intl.formatMessage({ id: 'resourceManagementList.details' }),
  component: ResourceAssignmentDialog,
  hasPermission: hasPermission(me)
});

export default route;
