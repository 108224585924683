import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  buildCustomFieldFormValues,
  customFieldValueToInput,
  customFieldValidationSchema
} from '~/modules/customFields/utils';
import { EditCustomFieldDialogForm } from '~/modules/customFields/components';
import EditClientDialog from './EditClientDialog';

const title = <FormattedMessage id="client.additionalInfo" />;

export const EditClientAddressDialog = ({
  open,
  onClose,
  details,
  customFieldDefinitions,
  intl
}) => {
  const { id, customFieldValues } = details;

  const initialValues = useMemo(
    () => ({
      id,
      customFieldValues: buildCustomFieldFormValues(customFieldValues || [])
    }),
    [customFieldValues, id]
  );

  const validationSchema = customFieldValidationSchema({
    customFieldDefinitions,
    intl
  });

  const mapValuesOnSubmit = useCallback(
    values => ({
      ...values,
      customFieldValues: Object.values(values.customFieldValues).map(
        customFieldValueToInput
      )
    }),
    []
  );

  return (
    <EditClientDialog
      open={open}
      onClose={onClose}
      title={title}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mapValuesOnSubmit={mapValuesOnSubmit}
    >
      <EditCustomFieldDialogForm />
    </EditClientDialog>
  );
};

EditClientAddressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  details: PropTypes.object,
  intl: PropTypes.object,
  customFieldDefinitions: PropTypes.array
};

export default injectIntl(EditClientAddressDialog);
