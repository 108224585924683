import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataItem } from '~/modules/common/components';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';

const title = <FormattedMessage id="resourceDrawer.profileLinks" />;

export const ProfileLinksSection = () => (
  <InfoSection editable={false} title={title}>
    <NoDataItem>
      <FormattedMessage id="resourceDrawer.noProfileLinks" />
    </NoDataItem>
  </InfoSection>
);

export default ProfileLinksSection;
