import React from 'react';
import PropTypes from 'prop-types';
import { SeriesChart } from '~/modules/common/charts/dashboard';
import { BarsChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { useHourChartConfig } from '~/modules/common/charts/dashboard/config';
import { useHourChartData } from '~/modules/common/charts/hooks';
import { useClientHourSeries } from './hooks';

export const ResourceHours = ({ chartDataInput, titleId }) => {
  const { actualBarColor, gradientId, legends } = useHourChartConfig();
  const { loading, hourSeriesData } = useClientHourSeries({ chartDataInput });
  const { actualDataPoints, estimatedDataPoints } = useHourChartData({
    hourSeriesData
  });

  return loading ? (
    <BarsChartLoading />
  ) : (
    <SeriesChart
      name="ResourceHours"
      actualBarColor={actualBarColor}
      gradientId={gradientId}
      legends={legends}
      actualDataPoints={actualDataPoints}
      estimatedDataPoints={estimatedDataPoints}
      titleId={titleId}
    />
  );
};

ResourceHours.propTypes = {
  chartDataInput: PropTypes.object,
  titleId: PropTypes.string
};

export default ResourceHours;
