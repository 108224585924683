import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { DonutChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { DonutChart } from '~/modules/common/charts/dashboard/DonutChart/DonutChart';
import useProjectRequestsCountSummaryQuery from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestsSummary/useProjectRequestsCountSummary';
import { usePortfolioProjectRequestStatusConfig } from './hooks/usePortfolioProjectRequestStatusConfig';
import usePortfolioProjectRequestStatusClickHandlers from './hooks/usePortfolioProjectRequestStatusClickHandlers';
import { usePortfolioProjectRequestStatusEstimatedCostSummary } from './hooks/usePortfolioProjectRequestStatusEstimatedCostSummary';

const useLegendItemStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.body1
  }
}));

export const PortfolioProjectRequestStatusChart = ({ chartDataInput }) => {
  const legendItemClasses = useLegendItemStyles();

  const {
    portfolioId,
    portfolioCurrency,
    canViewPlanningData
  } = chartDataInput;

  const {
    loading,
    projectRequestsCountSummary
  } = useProjectRequestsCountSummaryQuery({
    projectRequestFilter: {
      portfolios: [portfolioId],
      includeSubPortfolios: true
    }
  });

  const {
    loading: loadingEstimatedCostSummary,
    estimatedCostSummaryAmounts
  } = usePortfolioProjectRequestStatusEstimatedCostSummary({
    portfolioId,
    skip: !canViewPlanningData
  });

  const {
    colorScale,
    legends,
    dataPoints,
    overrideChartConfig,
    overrideChartStyles
  } = usePortfolioProjectRequestStatusConfig({
    estimatedCostSummaryAmounts,
    projectRequestsCountSummary,
    portfolioCurrency,
    canViewPlanningData
  });

  const {
    events,
    legendClickHandlers
  } = usePortfolioProjectRequestStatusClickHandlers();

  const intl = useIntl();

  return loadingEstimatedCostSummary || loading ? (
    <DonutChartLoading />
  ) : (
    <>
      <DonutChart
        dataQeId="projectRequestChart"
        dataPoints={dataPoints}
        colorScale={colorScale}
        legends={legends}
        legendItemClasses={legendItemClasses}
        chartTitle={
          projectRequestsCountSummary && projectRequestsCountSummary.all
        }
        chartValue={intl.formatMessage({
          id: 'portfolio.projectRequestStatusChart.title'
        })}
        overrideChartConfig={overrideChartConfig}
        showNoDataCircle={
          projectRequestsCountSummary && projectRequestsCountSummary.all === 0
        }
        overrideChartStyles={overrideChartStyles}
        events={events}
        legendClickHandlers={legendClickHandlers}
      />
    </>
  );
};

PortfolioProjectRequestStatusChart.propTypes = {
  chartDataInput: PropTypes.shape({
    portfolioId: PropTypes.string,
    portfolioCurrency: PropTypes.object,
    canViewPlanningData: PropTypes.bool
  }).isRequired
};

export default PortfolioProjectRequestStatusChart;
