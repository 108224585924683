import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useTableSettings } from '~/modules/common/components/ListTable';

const TABLE_KEY = 'client-projects-list';

const useClientProjectListTableSettings = () => {
  const {
    enabledProjectColumnNames,
    enabledProjectColumnGroups
  } = useEnabledProjectColumns({
    hideClient: true
  });

  const { data: tableSettingsData, loading } = useTableSettings({
    tableKey: TABLE_KEY,
    defaultColumns: enabledProjectColumnNames
  });

  return {
    tableKey: TABLE_KEY,
    enabledProjectColumnGroups,
    enabledProjectColumnNames,
    selectedColumnNames: tableSettingsData?.columns,
    tableSettingsDataLoading: loading
  };
};

export default useClientProjectListTableSettings;
