import React from 'react';
import PropTypes from 'prop-types';
import {
  OverviewChart,
  OverviewChartLoading
} from '~/modules/common/charts/dashboard';
import { useClientKPI } from './hooks';

export const KPIOverview = ({ chartDataInput, ...rest }) => {
  const {
    slug,
    dateRange,
    clientPermissions: { canViewClientBillingData }
  } = chartDataInput;

  const {
    loading,
    costAmountSummary,
    revenueAmountSummary,
    profitAmountSummary,
    timeEnteredSummary
  } = useClientKPI({ slug, dateRange });

  return loading ? (
    <OverviewChartLoading />
  ) : (
    <OverviewChart
      costAmountSummary={costAmountSummary}
      timeEnteredSummary={timeEnteredSummary}
      revenueAmountSummary={revenueAmountSummary}
      profitAmountSummary={profitAmountSummary}
      showRevenue={Boolean(canViewClientBillingData)}
      {...rest}
    />
  );
};

KPIOverview.propTypes = {
  chartDataInput: PropTypes.object.isRequired
};

export default KPIOverview;
