import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import {
  ListTable,
  PAGINATION_TYPE,
  ListTablePropTypes,
  computeTableColumns,
  ListTableThemeProvider
} from '~/modules/common/components/ListTable';
import { PROJECT_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectListPageType';
import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useMeContext } from '~/modules/me/useMeContext';
import { NoLinksData } from '~/modules/projects/project/ProjectLinksTab/NoLinksData';
import { renders } from './enhancers';
import MobileProjectListItem from './MobileProjectListItem';
import { NoProjectData } from './NoProjectData';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    }
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    lineHeight: 1.2,
    fontSize: theme.typography.body2.fontSize,
    top: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(8)
    },
    '& span': {
      backgroundColor: 'inherit'
    }
  },
  tableCell: {
    height: 40,
    padding: theme.spacing(0.5, 2)
  },
  tableFooter: {
    backgroundColor: theme.palette.grey[100],
    color: 'inherit'
  },
  tableFooterCell: {
    padding: theme.spacing(1, 2),
    height: 20
  },
  tableFixedCellWithSelection: {
    left: theme.spacing(5.125),
    paddingLeft: theme.spacing(0.5)
  },
  tabelCellSelection: {
    padding: 0,
    width: theme.spacing(5.25)
  }
}));

const useListTableWithNavigationButtonThemeProviderStyles = makeStyles(
  theme => ({
    headerCellWithNavigationButtons: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: theme.spacing(37.5)
    },
    pageOutOfBoundHeaderFix: { paddingLeft: theme.spacing(4) }
  })
);

const useColumnStyles = makeStyles(theme => ({
  name: {
    width: theme.spacing(31.25),
    height: 49,
    borderRight: `1px solid ${theme.palette.table.border}`,
    whiteSpace: 'normal'
  },
  client: {
    width: 200,
    minWidth: 200,
    whiteSpace: 'normal'
  },
  program: {
    width: 200,
    minWidth: 200,
    whiteSpace: 'normal'
  }
}));

const useColumnWithNavigationButtonStyles = makeStyles(theme => ({
  name: isPsaPrp2023q3BugFixesEnabled =>
    isPsaPrp2023q3BugFixesEnabled
      ? {
          width: theme.spacing(37.5),
          borderRight: `1px solid ${theme.palette.table.border}`,
          whiteSpace: 'normal'
        }
      : {
          width: theme.spacing(37.5)
        }
}));

const useListTableStyles = makeStyles(theme => ({
  card: {
    overflow: 'inherit'
  }
}));

export const ProjectListTableBase = ({
  headerClasses,
  projects,
  hasMoreProjects,
  loadMoreProjects,
  totals,
  projectsLoading,
  sort,
  onSortChange,
  disableSort,
  enabledFields,
  tagFieldData,
  selected,
  onRowSelectionChange,
  useHistoryForRedirect,
  currentPage,
  paginationType,
  noProjectDataComponentProps: { showClickToAdd } = {},
  projectListPageType
}) => {
  const {
    featureFlags: { isPsaPrp2023q3BugFixesEnabled, isPsaPrpProjectLinkEnabled }
  } = useMeContext();
  const isMobile = useIsBreakpointDown('sm');

  const columnClasses = useColumnStyles();
  const columnWithNavigationButtonClasses = useColumnWithNavigationButtonStyles(
    isPsaPrp2023q3BugFixesEnabled
  );

  const providerClasses = useListTableThemeProviderStyles();
  const providerWithNavigationButtonClasses = useListTableWithNavigationButtonThemeProviderStyles();
  const listTableClasses = useListTableStyles();

  const { enabledProjectColumnGroups } = useEnabledProjectColumns({
    tagFieldData,
    includeGroupsInDefaultColumnNames: false,
    projectListPageType
  });

  const { headers, footers, columns } = computeTableColumns({
    columnSpec: enabledProjectColumnGroups,
    renders,
    defaultRender: renders.tag
  })({
    enabledFields,
    columnClasses:
      paginationType === PAGINATION_TYPE.NAVIGATION
        ? { ...columnClasses, ...columnWithNavigationButtonClasses }
        : columnClasses,
    headerClasses
  });

  const records = useMemo(
    () =>
      projects.map(project => ({
        ...project,
        selectionCheckboxProps: {
          ariaLabel: project.name
        },
        useHistoryForRedirect
      })),
    [projects, useHistoryForRedirect]
  );

  const showNoProjectDataComponent =
    !projectsLoading &&
    projects.length === 0 &&
    (paginationType === PAGINATION_TYPE.NAVIGATION ? currentPage === 1 : true);
  const overrideListTableClasses = useMemo(
    () =>
      paginationType === PAGINATION_TYPE.NAVIGATION
        ? { ...providerClasses, ...providerWithNavigationButtonClasses }
        : providerClasses,
    [paginationType, providerClasses, providerWithNavigationButtonClasses]
  );

  const noDataComponent =
    isPsaPrpProjectLinkEnabled &&
    projectListPageType === PROJECT_LIST_PAGE_TYPE.PROJECT_LINK ? (
      <NoLinksData />
    ) : (
      <NoProjectData showClickToAdd={showClickToAdd} />
    );

  return (
    <div id="project-list-container">
      {showNoProjectDataComponent ? (
        noDataComponent
      ) : (
        <ListTableThemeProvider classes={overrideListTableClasses}>
          <ListTable
            classes={listTableClasses}
            headers={headers}
            footers={footers}
            columns={columns}
            totals={totals}
            records={records}
            hasMore={hasMoreProjects}
            loadMore={loadMoreProjects}
            isLoading={projectsLoading}
            mobileListItem={MobileProjectListItem}
            variant="table"
            useInlineStyles={false}
            sort={sort}
            onSortChange={onSortChange}
            disableSort={disableSort}
            selected={selected}
            onRowSelectionChange={onRowSelectionChange}
            paginationType={paginationType}
            isMobile={isMobile}
          />
        </ListTableThemeProvider>
      )}
    </div>
  );
};

ProjectListTableBase.propTypes = {
  projects: PropTypes.array,
  hasMoreProjects: PropTypes.bool,
  loadMoreProjects: PropTypes.func,
  totals: PropTypes.object,
  projectsLoading: PropTypes.bool,
  sort: ListTablePropTypes.sort,
  onSortChange: PropTypes.func,
  disableSort: PropTypes.bool,
  enabledFields: PropTypes.array,
  headerClasses: PropTypes.object,
  tagFieldData: PropTypes.array,
  onRowSelectionChange: PropTypes.func,
  selected: ListTablePropTypes.selected,
  useHistoryForRedirect: PropTypes.bool,
  currentPage: PropTypes.number,
  noProjectDataComponentProps: PropTypes.shape({
    showClickToAdd: PropTypes.bool
  }),
  paginationType: PropTypes.oneOf([
    PAGINATION_TYPE.NAVIGATION,
    PAGINATION_TYPE.SCROLLABLE
  ]).isRequired,
  projectListPageType: PropTypes.oneOf([
    PROJECT_LIST_PAGE_TYPE.PORTFOLIO,
    PROJECT_LIST_PAGE_TYPE.PROJECT_LINK
  ])
};

export default ProjectListTableBase;
