import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TabBar, TabContents } from '~/modules/common/components';
import { useIsDefaultEdit } from '~/modules/common/hooks';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useVendorPermissions } from '../hooks/useVendorPermissions';
import VendorHeader from './components/VendorHeader';
import tabRoutes from './tabRoutes';
import { VendorPageEditContextProvider } from './VendorPageEditContextProvider';
import { useVendor } from './hooks/useVendor';

export const VendorPage = () => {
  const isDefaultEdit = useIsDefaultEdit();
  const { canEditVendor } = useVendorPermissions();
  const edit = isDefaultEdit && canEditVendor;
  const vendorTabRoutes = tabRoutes();
  const {
    params: { slug }
  } = useRouteMatch();

  const { error, loading, vendor } = useVendor({ slug });

  return (
    <VendorPageEditContextProvider
      edit={edit}
      loading={loading}
      error={error}
      vendor={vendor}
    >
      <VendorHeader />
      <StickyHeader level={1} bottomBorder>
        <TabBar
          tabRoutes={vendorTabRoutes}
          variant="scrollable"
          scrollButtons="off"
          noBottomBorder
        />
      </StickyHeader>
      <TabContents tabRoutes={vendorTabRoutes} slug={slug} />
    </VendorPageEditContextProvider>
  );
};

export default VendorPage;
