import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'auto',
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '-webkit-overflow-scrolling': 'touch',
    marginTop: ({ isSubstituteUser }) =>
      isSubstituteUser ? theme.spacing(4) : 0
  },
  '@supports (-webkit-overflow-scrolling: touch)': {
    content: {
      overflow: 'hidden',
      overflowY: 'scroll'
    }
  }
}));

export default useStyles;
