import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { debounce, scope } from '~/modules/common/debounce';
import {
  getOptionsToDisplay,
  MORE_AVAILABLE_OPTION_ID
} from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import useProjectDropdownOptions, {
  PROJECT_DROPDOWN_PAGE_SIZE
} from './useProjectDropdownOptions';

const resourceKey = {
  project: <FormattedMessage id="customLineItem.project" />
};

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

const projectScope = scope();

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

const CustomPopper = props => {
  const { style } = props;

  // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
  return <div {...props} style={{ ...style, position: 'absolute' }} />;
};

export const SearchableProjectDropdown = ({ value, onChange, clientId }) => {
  const [projectSearch, setProjectSearch] = useState(null);
  const intl = useIntl();
  const noneLabel = intl.formatMessage({ id: 'simpleDropdown.none' });

  const { options, loading } = useProjectDropdownOptions(
    clientId,
    noneLabel,
    projectSearch
      ? {
          text: projectSearch
        }
      : {}
  );
  const projectOptions = getOptionsToDisplay({
    options,
    intl,
    optionTypeText: intl.formatMessage({ id: 'moreOptions.projects' }),
    shouldAddMoreAvailableOption:
      !loading && options.length > PROJECT_DROPDOWN_PAGE_SIZE
  });

  const renderInput = useCallback(
    params => <TextField {...params} label={resourceKey.project} />,
    []
  );

  const handleInputChange = useCallback(
    (_, v) => {
      debounce(
        projectScope,
        finish => {
          setProjectSearch(v.trim());
          finish();
        },
        250
      );
    },
    [setProjectSearch]
  );

  return (
    <Autocomplete
      loading={loading}
      options={projectOptions}
      fullWidth
      forcePopupIcon
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      getOptionSelected={getOptionSelected}
      onInputChange={handleInputChange}
      getOptionDisabled={getOptionDisabled}
      PopperComponent={CustomPopper}
    />
  );
};

CustomPopper.propTypes = {
  style: PropTypes.object
};

SearchableProjectDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  clientId: PropTypes.string
};

export default SearchableProjectDropdown;
