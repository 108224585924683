import React from 'react';
import { FormattedMessage } from 'react-intl';
import ProjectRequestTab from './ProjectRequestTab';

const route = () => ({
  path: '/portfolios/:slug/requests',
  title: () => <FormattedMessage id="portfolio.tabs.requests" />,
  component: ProjectRequestTab
});

export default route;
