import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import EditClientDialog from './EditClientDialog';
import EditClientContactInfoDialogForm from './EditClientContactInfoDialogForm';
import { contactInfoValidationSchema } from './validationSchemas';
import { mapContactInfoInitialValues } from './mapContactInfoInitialValues';

const title = <FormattedMessage id="client.contact" />;

export const EditClientContactInfoDialog = ({ open, onClose, details }) => {
  const { id, contactName, contactInfo } = details;

  const initialValues = useMemo(
    () => ({
      id,
      contactName: contactName || '',
      contactInfo: mapContactInfoInitialValues(contactInfo)
    }),
    [contactInfo, contactName, id]
  );

  return (
    <EditClientDialog
      open={open}
      onClose={onClose}
      title={title}
      initialValues={initialValues}
      validationSchema={contactInfoValidationSchema}
    >
      <EditClientContactInfoDialogForm />
    </EditClientDialog>
  );
};

EditClientContactInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  details: PropTypes.object
};

export default EditClientContactInfoDialog;
