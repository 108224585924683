import { useCallback, useState } from 'react';
import { useMenuState, useDialogState } from '~/modules/common/hooks';
import { useDeletePortfolioHandler } from './useDeletePortfolioHandler';

export const usePortfolioActions = ({ id, canDeletePortfolio }) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const [isRemoving, setIsRemoving] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const { deletePortfolio } = useDeletePortfolioHandler({
    portfolioId: id,
    canDeletePortfolio
  });
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const onRemovePortfolioMenuItemClick = useCallback(() => {
    onMenuClose();
    openRemoveDialog();
  }, [onMenuClose, openRemoveDialog]);

  const onDeleteConfirm = useCallback(async () => {
    setIsRemoving(true);
    await deletePortfolio();
    setIsRemoving(false);
    closeRemoveDialog();
  }, [deletePortfolio, closeRemoveDialog]);

  return {
    onRemovePortfolioMenuItemClick,
    onMenuClick,
    anchorEl,
    isMenuOpen,
    onMenuClose,
    showRemoveDialog,
    closeRemoveDialog,
    onDeleteConfirm,
    isRemoving
  };
};

export default usePortfolioActions;
