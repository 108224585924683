import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useMeContext } from '~/modules/me';
import GoBackContextProvider from '~/modules/navigation/GoBackContextProvider';
import buildRoutes from './buildRoutes';

export const RoutesContext = React.createContext();

const { Provider, Consumer: RoutesConsumer } = RoutesContext;

export { RoutesConsumer };

const RoutesProvider = ({ children, width }) => {
  const intl = useIntl();
  const me = useMeContext();
  const isMobile = isWidthDown('sm', width);

  return (
    <GoBackContextProvider>
      <Provider value={buildRoutes({ intl, me, isMobile })}>
        {children}
      </Provider>
    </GoBackContextProvider>
  );
};

RoutesProvider.propTypes = {
  children: PropTypes.any,
  width: PropTypes.any
};

export default withWidth()(RoutesProvider);

export const withRoutes = BaseComponent => props => (
  <RoutesConsumer>
    {routes => <BaseComponent routes={routes} {...props} />}
  </RoutesConsumer>
);
