import React, { useEffect } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';

export const RepliconAssistant = () => {
  const { featureFlags } = useMeContext();

  useEffect(() => {
    if (
      !featureFlags?.repliconAssistant ||
      featureFlags?.repliconAssistant === 'off'
    ) {
      return () => {};
    }

    const script = document.createElement('script');

    script.setAttribute('type', 'module');
    script.setAttribute('src', featureFlags.repliconAssistant);
    script.async = true;

    document.body.append(script);

    return () => {
      document.removeChild(script);
    };
  }, [featureFlags]);

  if (
    !featureFlags?.repliconAssistant ||
    featureFlags?.repliconAssistant === 'off'
  ) {
    return <></>;
  }

  return (
    <>
      <replicon-assistant></replicon-assistant>
    </>
  );
};

export default RepliconAssistant;
