import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  PERIOD_SCALE_ENUM,
  DISPLAY_UNIT_ENUM
} from '~/modules/resourcing/common/enums';
import { useRedirectToAssignResource } from '~/modules/resourcing/hooks';

const useStyles = makeStyles(theme => ({
  alternative: {
    marginTop: theme.spacing(1.75)
  }
}));

export const ResourceMatchAlternativeLink = ({
  scale,
  displayUnit,
  resourceRequest
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const onAssignResourceAction = useRedirectToAssignResource({
    history,
    resourceRequest,
    isResourcingTab: false,
    scale,
    displayUnit
  });

  return (
    <div
      data-qe-id="ResourceMatchAlternativeLink"
      className={classes.alternative}
    >
      <ButtonBase onClick={onAssignResourceAction}>
        <Typography variant="body1" color="primary">
          {formatMessage({
            id: 'resourceMatchPopover.findAlternative'
          })}
        </Typography>
      </ButtonBase>
    </div>
  );
};

ResourceMatchAlternativeLink.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.YEARS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.ENTIRE_PROJECT
  ]),
  defaultDisplayUnit: PropTypes.oneOf([
    DISPLAY_UNIT_ENUM.HOURS,
    DISPLAY_UNIT_ENUM.PERCENTAGE
  ])
};

export default ResourceMatchAlternativeLink;
