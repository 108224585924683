import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  makeStyles,
  Grid,
  Checkbox,
  Typography
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import ChartDateRangePeriod from '~/modules/common/charts/dashboard/ChartDateRangePeriod';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  settings: {
    marginLeft: 'auto'
  },
  title: {
    margin: 'auto'
  },
  container: {
    height: theme.spacing(8.5)
  }
}));

export const AllowedExpensesAndEstimatesHeader = ({
  dateRange,
  editable,
  onNext,
  onPrevious,
  setDate
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { setFieldValue, values } = useFormikContext();

  const onChange = useCallback(
    event => {
      const { checked } = event?.target || {};

      setFieldValue('showActuals', checked);
    },
    [setFieldValue]
  );

  const control = useMemo(
    () => (
      <Checkbox
        onChange={onChange}
        checked={values.showActuals}
        color="primary"
      />
    ),
    [onChange, values.showActuals]
  );

  return (
    <StickyHeader>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} data-qe-id="ExpenseCode">
          <div className={classes.flex}>
            <ChartDateRangePeriod
              onPreviousClick={onPrevious}
              onNextClick={onNext}
              scale={PERIOD_SCALE_ENUM.MONTHS}
              dateRange={dateRange}
              setDateRange={setDate}
              setDateValue
              scaleFormat
            />
            {editable && (
              <div className={classes.settings}>
                <FormControlLabel
                  control={control}
                  label={formatMessage({
                    id: 'allowedExpensesAndEstimates.showActuals'
                  })}
                  labelPlacement="start"
                  data-qe-id="ShowActual"
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.flex}>
            <Typography variant="subtitle1" className={classes.title}>
              <FormattedMessage
                id={
                  values.showActuals
                    ? 'allowedExpensesAndEstimates.estimatesAndActuals'
                    : 'allowedExpensesAndEstimates.estimates'
                }
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </StickyHeader>
  );
};

AllowedExpensesAndEstimatesHeader.propTypes = {
  dateRange: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired
};

export default AllowedExpensesAndEstimatesHeader;
