import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AddFabButton } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import { useVendorPermissions } from '~/modules/vendors/hooks/useVendorPermissions';
import { useVendorPageEditContext } from '../../VendorPageEditContext';
import { VendorResourcesListTable } from './VendorResourcesListTable';
import { ResourceAssignmentDialog } from './ResourceAssignmentDialog/ResourceAssignmentDialog';
import ResourceAssignmentDialogContextProvider from './ResourceAssignmentDialog/ResourceAssignmentDialogContextProvider';

export const VendorResources = () => {
  const { vendor, edit } = useVendorPageEditContext();
  const [currentPage, setCurrentPage] = useState(1);
  const { open, closeDialog, openDialog } = useDialogState();
  const { formatMessage } = useIntl();
  const { canEditVendor } = useVendorPermissions();

  const [
    selectedVendorResourcesItems,
    setSelectedVendorResourcesItems
  ] = useState([]);

  return (
    <>
      <VendorResourcesListTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isEditable={edit}
        selectedVendorResourcesItems={selectedVendorResourcesItems}
        setSelectedVendorResourcesItems={setSelectedVendorResourcesItems}
      />
      {canEditVendor && !selectedVendorResourcesItems.length && (
        <AddFabButton
          label={formatMessage({ id: 'resourceAssignmentAddFab.label' })}
          dataQeId="assignResourcesFabButton"
          onClick={openDialog}
        />
      )}
      {open && (
        <ResourceAssignmentDialogContextProvider vendor={vendor}>
          <ResourceAssignmentDialog open={open} onClose={closeDialog} />
        </ResourceAssignmentDialogContextProvider>
      )}
    </>
  );
};

export default VendorResources;
