import { makeStyles, CircularProgress, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useAttachmentsHandlers } from '~/modules/attachments/AttachmentsBlock/hooks';
import { useProjectRequest } from './hooks';
import { ProjectRequestDrawerForm } from './ProjectRequestDrawerForm';

const useStyles = makeStyles(theme => ({
  spinnerContainer: {
    width: 700,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

export const EditProjectRequestDrawer = ({ id, onClose }) => {
  const classes = useStyles();
  const { loading, projectRequestDetails } = useProjectRequest(id);
  const attachmentsData = useAttachmentsHandlers({
    attachToUri: id
  });

  const [editable, setEditable] = useState(false);

  return loading ? (
    <Grid
      container
      className={classes.spinnerContainer}
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <CircularProgress size={24} />
    </Grid>
  ) : (
    <ProjectRequestDrawerForm
      editable={editable}
      setEditable={setEditable}
      onClose={onClose}
      projectRequestDetails={projectRequestDetails}
      attachmentsData={attachmentsData}
    />
  );
};

EditProjectRequestDrawer.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string
};

export default EditProjectRequestDrawer;
