import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ProgressChart } from './ProgressChart';

export const ProgressHourChart = ({
  projectsBudgetHours,
  projectsEstimateAtCompletionHours,
  projectsActualHours
}) => {
  const intl = useIntl();

  return (
    <ProgressChart
      budgetAmount={projectsBudgetHours}
      eacAmount={projectsEstimateAtCompletionHours}
      actualAmount={projectsActualHours}
      suffix={intl.formatMessage({
        id: 'dashboardProgressChart.hrs'
      })}
      budgetMissing="portfolio.budgetBurnedCard.budgetHoursMissing"
      budgetMissingAlert="portfolio.budgetBurnedCard.budgetHoursMissingAlert"
      eacMissingAlert="portfolio.budgetBurnedCard.eacMissingAlert"
      eacMissing="portfolio.budgetBurnedCard.eacMissing"
      fixedDecimalScale={false}
      actualAmountResourceKey="portfolio.budgetBurnedCard.actualHours"
      tooltipPrecision={4}
      dataQeId="BudgetBurnedHourChart"
    />
  );
};

ProgressHourChart.propTypes = {
  projectsBudgetHours: PropTypes.number,
  projectsEstimateAtCompletionHours: PropTypes.number,
  projectsActualHours: PropTypes.number
};

export default ProgressHourChart;
