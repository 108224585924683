import { useEffect, useState, useRef, useReducer } from 'react';
import { inFlightRequestObservable } from '~/modules/App/inFlightRequestLink';

const useInFlightRequest = (observable = inFlightRequestObservable) => {
  const [isLoading, setIsLoading] = useState(false);

  // Use the dispatch function of a reducer to cause React to
  // enqueue a state update. This was referenced from react-apollo:
  // https://github.com/apollographql/react-apollo/blob/5639aa2d3ce06b52d63f548594b8cedc1c3a5f8b/packages/hooks/src/utils/useBaseQuery.ts#L19
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  // Keep track of the "loading" status in a ref, as it allows us
  // to detach the loading state from the rendering of the component.
  // Re-renders upon change to this state should be triggered using the
  // forceUpdate function. This is to prevent issues where GraphQL
  // made from other components would cause consumers of this hook to
  // immediately update their loading state and spit errors in to the
  // console regarding out-of-band state changes.
  // https://github.com/facebook/react/issues/18178
  const loadingRef = useRef(false);

  // Track the number of in-flight requests, and trigger a
  // re-render of the consumer on the next execution of the
  // JS runtime loop.
  useEffect(() => {
    let timeout = -1;
    const sub = observable.subscribe(count => {
      loadingRef.current = count > 0;
      if (timeout >= 0) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(forceUpdate, 0);
    });

    return () => {
      sub.unsubscribe();
      if (timeout >= 0) {
        clearTimeout(timeout);
      }
    };
  }, [observable]);

  // We want this effect to run on every render of the ProfileMenu, so we
  // don't pass any arguments to the dependency array.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isLoading !== loadingRef.current) {
      setIsLoading(loadingRef.current);
    }
  });

  return isLoading;
};

export default useInFlightRequest;
