import React, { useMemo } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useFormikContext } from 'formik';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';
import {
  CurrencyDropdown,
  Money2,
  MoneyValue,
  NoValue
} from '~/modules/common/components';
import { PORTFOLIO_BUDGET_OPTIONS } from '~/modules/portfolios/enums';
import { useBudgetFormOnChangeHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  currencyRoot: {
    borderBottom: 'solid 1px #ebebeb',
    marginBottom: theme.spacing(2)
  },
  portfolioCurrency: { marginBottom: theme.spacing(2) },
  capexOpexGridItem: {
    padding: theme.spacing(0, 1, 0, 1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1)
    }
  },
  totalGridItem: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1)
    }
  },
  opex: {
    marginTop: theme.spacing(1)
  },
  moneyContainer: {
    marginLeft: theme.spacing(4)
  },
  controlContainer: {
    minHeight: theme.spacing(30)
  },
  total: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingRight: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
    fontSize: '1rem'
  },
  currencyChangeWarning: {
    marginBottom: theme.spacing(1)
  }
}));
const RadioButton = <Radio />;

export const EditPortfolioBudgetDialogContent = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    values,
    setFieldValue,
    errors,
    initialValues: { budgetCurrency: initialBudgetCurrency }
  } = useFormikContext();
  const {
    total,
    operational,
    capital,
    budgetCurrency,
    isCapexOpexMode
  } = values;

  const {
    onBudgetAmountChange,
    onBudgetCurrencyChange,
    onOptionChange
  } = useBudgetFormOnChangeHandlers({ setFieldValue });

  const totalOfOpexCapexAmount = useMemo(
    () =>
      operational?.amount || capital?.amount
        ? {
            amount: parseFloat(
              (operational?.amount || 0) + (capital?.amount || 0),
              2
            ),
            currency: budgetCurrency
          }
        : null,
    [budgetCurrency, capital, operational]
  );

  const error = useMemo(() => {
    return {
      total: get(errors, 'total', null),
      capital: get(errors, 'capital', null),
      operational: get(errors, 'operational', null)
    };
  }, [errors]);

  return (
    <FormControl
      component="fieldset"
      fullWidth
      className={classes.controlContainer}
    >
      <Grid container spacing={0} className={classes.currencyRoot}>
        <Grid item md={6} xs={12} className={classes.portfolioCurrency}>
          <CurrencyDropdown
            dataQeId="portfolioCurrency"
            variant="filled"
            label={formatMessage({ id: 'portfolio.portfolioCurrency' })}
            value={budgetCurrency}
            noneOption={false}
            onChange={onBudgetCurrencyChange}
            editable
          />
        </Grid>
        {budgetCurrency.id !== initialBudgetCurrency.id ? (
          <Alert severity="warning" className={classes.currencyChangeWarning}>
            <FormattedMessage id="portfolio.budget.currencyChangeWarning" />
          </Alert>
        ) : null}
      </Grid>
      <RadioGroup
        value={
          isCapexOpexMode
            ? PORTFOLIO_BUDGET_OPTIONS.CAPEX_OPEX
            : PORTFOLIO_BUDGET_OPTIONS.TOTAL
        }
        onChange={onOptionChange}
      >
        <Grid container spacing={0}>
          <Grid item md={6} xs={12} className={classes.totalGridItem}>
            <FormControlLabel
              value={PORTFOLIO_BUDGET_OPTIONS.TOTAL}
              control={RadioButton}
              label={formatMessage({
                id: 'portfolio.budget.generalBudgetLabel'
              })}
            />
            {!isCapexOpexMode ? (
              <div className={classes.moneyContainer}>
                <Money2
                  name="total"
                  variant="filled"
                  label={formatMessage({ id: 'portfolio.budget.total' })}
                  amount={total?.amount}
                  currency={budgetCurrency}
                  onAmountChange={onBudgetAmountChange}
                  onCurrencyChange={onBudgetCurrencyChange}
                  error={Boolean(error.total)}
                  helperText={error.total}
                  currencyDataQeId="PortfolioTotalBudgetCurrency"
                  valueDataQeId="PortfolioTotalCostTextField"
                />
              </div>
            ) : null}
          </Grid>
          <Grid item md={6} xs={12} className={classes.capexOpexGridItem}>
            <FormControlLabel
              value={PORTFOLIO_BUDGET_OPTIONS.CAPEX_OPEX}
              control={RadioButton}
              label={formatMessage({
                id: 'portfolio.budget.capexOpexLabel'
              })}
            />
            {isCapexOpexMode ? (
              <div className={classes.moneyContainer}>
                <Money2
                  name="capital"
                  label={formatMessage({ id: 'portfolio.budget.capital' })}
                  variant="filled"
                  amount={capital?.amount}
                  currency={budgetCurrency}
                  onAmountChange={onBudgetAmountChange}
                  onCurrencyChange={onBudgetCurrencyChange}
                  currencyDataQeId="PortfolioCapexBudgetCurrency"
                  valueDataQeId="PortfolioCapexCostTextField"
                  error={Boolean(error.capital)}
                  helperText={error.capital}
                />
                <div className={classes.opex}>
                  <Money2
                    name="operational"
                    label={formatMessage({
                      id: 'portfolio.budget.operational'
                    })}
                    variant="filled"
                    amount={operational?.amount}
                    currency={budgetCurrency}
                    currencyEditable={false}
                    onAmountChange={onBudgetAmountChange}
                    onCurrencyChange={onBudgetCurrencyChange}
                    valueDataQeId="PortfolioOpexCostTextField"
                    error={Boolean(error.operational)}
                    helperText={error.operational}
                  />
                  <div className={classes.total}>
                    <Typography
                      component="label"
                      variant="caption"
                      color="textSecondary"
                    >
                      <FormattedMessage id="portfolio.budget.total" />
                    </Typography>
                    {totalOfOpexCapexAmount ? (
                      <MoneyValue money={totalOfOpexCapexAmount} />
                    ) : (
                      <NoValue />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export default EditPortfolioBudgetDialogContent;
