import { useCallback } from 'react';
import { isNullOrUndefined } from '~/modules/common/util';
import { validationMessage } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestDrawer/hooks/useFormState';
import { hasError } from '~/util';
import { ESTIMATED_COST_MAX_AMOUNT } from '~/modules/project-request/ProjectRequestPage/hooks/constants';
import { useUpdateEstimatedCostOverride } from './useUpdateEstimatedCostOverride';

export const setEstimatedCostOverrideError = ({ setFieldError, value }) => {
  setFieldError(
    'estimatedCostOverride',
    value > ESTIMATED_COST_MAX_AMOUNT
      ? validationMessage(ESTIMATED_COST_MAX_AMOUNT)
      : null
  );
};

export const useOnChangeHandlers = ({
  projectRequestId,
  estimatedCost,
  estimatedCostOverride,
  setFieldValue,
  setFieldError,
  errors,
  initialEstimatedCostCurrency,
  setHasEstimatedCostOverride
}) => {
  const { updateEstimatedCostOverride } = useUpdateEstimatedCostOverride({
    projectRequestId
  });

  return {
    onEstimatedCostAmountChange: useCallback(
      event => {
        const { value } = event.target;

        setFieldValue(`estimatedCostOverride.amount`, value);
        setEstimatedCostOverrideError({ setFieldError, value });
      },
      [setFieldError, setFieldValue]
    ),

    onEstimatedCostCurrencyChange: useCallback(
      value => {
        setFieldValue(`estimatedCostOverride.currency`, value);
        !isNullOrUndefined(estimatedCostOverride?.amount) &&
          updateEstimatedCostOverride({
            estimatedCostOverride: {
              amount: estimatedCostOverride.amount,
              currency: value
            }
          });
      },
      [estimatedCostOverride, setFieldValue, updateEstimatedCostOverride]
    ),

    onBlurHandler: useCallback(() => {
      const hasValidationError = hasError(errors, 'estimatedCostOverride');
      const hasEstimatedCostOverride = !isNullOrUndefined(
        estimatedCostOverride?.amount
      );

      if (hasEstimatedCostOverride) {
        !hasValidationError &&
          updateEstimatedCostOverride({
            estimatedCostOverride: {
              amount: estimatedCostOverride?.amount,
              currency:
                estimatedCostOverride?.currency || initialEstimatedCostCurrency
            }
          });
      } else {
        updateEstimatedCostOverride({
          estimatedCostOverride: null
        });
      }
    }, [
      errors,
      estimatedCostOverride,
      initialEstimatedCostCurrency,
      updateEstimatedCostOverride
    ]),

    onUseLinkClickHandler: useCallback(() => {
      setHasEstimatedCostOverride(false);
      setFieldValue(`estimatedCostOverride`, null);
      updateEstimatedCostOverride({
        estimatedCostOverride: null
      });
    }, [
      setFieldValue,
      setHasEstimatedCostOverride,
      updateEstimatedCostOverride
    ])
  };
};
