import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCancelBillingBatch } from '~/modules/billing-invoicing/common/hooks';
import { AVAILABLE_TO_BILL_BILLING_TRANSACTION_REDIRECT_KEY } from '~/modules/billing-invoicing/common/const';
import { useSessionStorage } from '~/modules/common/hooks';
import { useAvailableToBillSearchContext } from '~/modules/billing-invoicing/common/components/AvailableToBillSubTab/AvailableToBillSearchContext';
import { useFetchBulkCreateBillFromBillItemsBatchResult } from './useFetchBulkCreateBillFromBillItemsBatchResult';

const refreshData = ({
  refetchAvailableToBillRows,
  refetchAvailableToBillTotal,
  refetchFilteredTotals
}) => () => {
  refetchAvailableToBillRows && refetchAvailableToBillRows();
  refetchAvailableToBillTotal && refetchAvailableToBillTotal();
  refetchFilteredTotals && refetchFilteredTotals();
};

export const useBulkBillProgressAndConfirmationDialogActions = ({
  refetchAvailableToBillRows,
  refetchAvailableToBillTotal,
  refetchFilteredTotals,
  resetSelectedAvailableToBillItems,
  billingBatchState,
  setBillingBatchState,
  onCancel,
  setSelectedTab,
  onCreateBill
}) => {
  const { refetchAvailableToBillQueries } = useAvailableToBillSearchContext();
  const [createdBills, setCreatedBills] = useState([]);
  const {
    fetchBulkCreateBillFromBillItemsBatchResult
  } = useFetchBulkCreateBillFromBillItemsBatchResult();

  const { setValue } = useSessionStorage(
    AVAILABLE_TO_BILL_BILLING_TRANSACTION_REDIRECT_KEY
  );
  const history = useHistory();
  const cancelBillingBatch = useCancelBillingBatch();

  const onBatchComplete = useCallback(
    async overallProgress => {
      const result = await fetchBulkCreateBillFromBillItemsBatchResult(
        billingBatchState?.batchId
      );

      setCreatedBills(result);
      if (overallProgress) {
        setBillingBatchState({
          batchId: billingBatchState?.batchId,
          batchInProgress: false,
          overallProgress,
          hasProcessed: true
        });
      }

      const lastBillId = result[result.length - 1]?.id;

      if (lastBillId) {
        await refetchAvailableToBillQueries({
          billId: lastBillId,
          refetchCallback: refreshData({
            refetchAvailableToBillRows,
            refetchAvailableToBillTotal,
            refetchFilteredTotals
          })
        });
      }

      resetSelectedAvailableToBillItems && resetSelectedAvailableToBillItems();
    },
    [
      fetchBulkCreateBillFromBillItemsBatchResult,
      billingBatchState?.batchId,
      setBillingBatchState,
      resetSelectedAvailableToBillItems,
      refetchAvailableToBillQueries,
      refetchAvailableToBillRows,
      refetchAvailableToBillTotal,
      refetchFilteredTotals
    ]
  );

  const onCancelClick = useCallback(async () => {
    if (billingBatchState?.batchId && billingBatchState?.batchInProgress) {
      await cancelBillingBatch(billingBatchState?.batchId);
      onBatchComplete();
    } else {
      setBillingBatchState({ batchId: null, batchInProgress: false });
      onCancel();
    }
  }, [
    billingBatchState?.batchId,
    billingBatchState?.batchInProgress,
    cancelBillingBatch,
    onBatchComplete,
    setBillingBatchState,
    onCancel
  ]);

  const onConfirmClick = useCallback(async () => {
    if (billingBatchState?.hasProcessed) {
      setValue(createdBills);
      if (setSelectedTab) {
        setSelectedTab('transactions');
      } else {
        history.push('/financials/billing/transactions');
      }
    } else {
      onCreateBill();
    }
  }, [
    billingBatchState?.hasProcessed,
    createdBills,
    history,
    onCreateBill,
    setSelectedTab,
    setValue
  ]);

  return {
    createdBills,
    onBatchComplete,
    onCancelClick,
    onConfirmClick
  };
};
