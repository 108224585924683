import React from 'react';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

import NotificationsIconBase from '@material-ui/icons/NotificationsSharp';

const MAX_NOTIFICATIONS = 9;
const NotificationsIcon = ({ notificationsCount }) => {
  if (notificationsCount > 0) {
    return (
      <Badge
        overlap="rectangular"
        badgeContent={
          notificationsCount > MAX_NOTIFICATIONS
            ? `${MAX_NOTIFICATIONS}+`
            : notificationsCount
        }
        color="secondary"
        data-qe-id="Notification_Count"
      >
        <NotificationsIconBase />
      </Badge>
    );
  }

  return <NotificationsIconBase />;
};

NotificationsIcon.propTypes = {
  notificationsCount: PropTypes.number
};

export default NotificationsIcon;
