import gql from 'graphql-tag';

export const portfolioFragment = gql`
  fragment Portfolio on Portfolio {
    id
    code
    name
    slug
    parent {
      id
      slug
      displayText: name
      effectiveStatus
    }
    portfolioManager {
      displayText
      id
    }
    description
    effectiveStatus
    status
    permittedActionUris
    ancestors {
      id
      displayText
      portfolioManager {
        id
      }
    }
    portfolioCurrency {
      id
      displayText
    }
    budget {
      total {
        amount
        currency {
          id
          displayText
        }
      }
      operational {
        amount
        currency {
          id
          displayText
        }
      }
      capital {
        amount
        currency {
          id
          displayText
        }
      }
    }
    canBeDeleted
    hasViewAccess
  }
`;
