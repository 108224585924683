import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { ProjectLinkType } from '~/types';
import { useStyles, useHeaderStyles, useContentStyles } from './useStyles';
import { ProjectLinkRow } from './ProjectLinkRow';
import useChangeHandlers from './useChangeHandlers';

const title = <FormattedMessage id="projectLink.title" />;
const addLink = <FormattedMessage id="projectLink.addLink" />;

export const getDefaultLinkObject = (
  linkType = ProjectLinkType.Changerequest
) => ({
  id: uuid(),
  linkType,
  isDirty: true
});

export const ProjectLinks = ({ projectLinks = [], setFieldValue }) => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const contentClasses = useContentStyles();
  const {
    onLinkTypeChange,
    onProjectChange,
    onProjectLinkAdd,
    onProjectLinkRemove
  } = useChangeHandlers({ projectLinks, setFieldValue });

  const handleOnAddClick = useCallback(
    () => onProjectLinkAdd(getDefaultLinkObject()),
    [onProjectLinkAdd]
  );

  return (
    <Card className={classes.card}>
      <CardHeader title={title} classes={headerClasses} />
      <CardContent classes={contentClasses}>
        {projectLinks.map((link, index) => (
          <ProjectLinkRow
            key={link.id}
            link={link}
            index={index}
            onLinkTypeChange={onLinkTypeChange}
            onProjectChange={onProjectChange}
            onProjectLinkRemove={onProjectLinkRemove}
            linkedProjectIds={projectLinks
              .filter(x => x.targetProject)
              .map(p => p.targetProject.id)}
          />
        ))}
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={handleOnAddClick}>
          {addLink}
        </Button>
      </CardActions>
    </Card>
  );
};

ProjectLinks.propTypes = {
  projectLinks: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ProjectLinks;
