import React from 'react';
import { FormattedMessage } from 'react-intl';
import BillingInvoicingPage from './BillingInvoicingPage';

export const route = () => ({
  path: '/clients/:slug/billing-invoicing',
  title: () => <FormattedMessage id="client.billingAndInvoicing" />,
  component: BillingInvoicingPage,
  exact: false,
  dataQeId: 'ClientPage_BillingAndInvoicing'
});
