import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  message: {
    color: theme.palette.text.secondary,
    whiteSpace: 'break-spaces'
  }
}));

export const MoreResult = ({ message, classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });

  return <Typography className={classes.message}>{message}</Typography>;
};

MoreResult.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.node.isRequired
};

export default MoreResult;
