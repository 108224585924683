import { hasPermission as hasPermissionProvider } from '~/modules/common/permissions';
import { useMeContext } from '~/modules/me';
import {
  PROJECT_REQUEST_PERMISSION,
  PROJECT_REQUEST_DAL
} from '~/modules/common/enums';

const checkPermission = ({ hasPermission, isManager, permissionUri }) => {
  return (
    (isManager &&
      hasPermission({
        actionUri: permissionUri,
        dataAccessLevelUri: PROJECT_REQUEST_DAL.PORTFOLIO_MANAGER
      })) ||
    hasPermission({
      actionUri: permissionUri,
      dataAccessLevelUri: PROJECT_REQUEST_DAL.ALL
    })
  );
};

export const usePortfolioProjectRequestsPermissions = ({
  portfolioManager,
  ancestors
} = {}) => {
  const { id, permissionsMap } = useMeContext();

  const hasPermission = hasPermissionProvider(permissionsMap);

  const isManager =
    portfolioManager?.id === id ||
    (ancestors || []).some(x => x.portfolioManager?.id === id);

  return {
    canViewPortfolioProjectRequests: checkPermission({
      hasPermission,
      isManager,
      permissionUri: PROJECT_REQUEST_PERMISSION.VIEW_PROJECT_REQUEST
    }),
    canViewPortfolioProjectRequestsPlanningData: checkPermission({
      hasPermission,
      isManager,
      permissionUri: PROJECT_REQUEST_PERMISSION.VIEW_PLANNING_DATA
    })
  };
};

export default usePortfolioProjectRequestsPermissions;
