import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useValidationSchema } from '~/modules/common/hooks';
import AddProgramDialogContent from './AddProgramDialogContent';
import { FormErrorContext } from './FormErrorContext';
import { useAddProgramOnSubmit } from './hooks';

const nameExceededMaxLengthMessage = (
  <FormattedMessage id="addProgram.programNameExceedsMaxLength" />
);
const nameRequiredMessage = (
  <FormattedMessage id="addProgram.enterProgramNameErrorMsg" />
);

export const AddProgramDialog = ({
  open,
  onClose,
  initialName = '',
  onProgramAdd,
  onCancel
}) => {
  const history = useHistory();

  const { onSubmit, error, clearError, initialState } = useAddProgramOnSubmit({
    history,
    onClose,
    initialName,
    onProgramAdd
  });

  const errorContextValue = useMemo(() => ({ error, clearError }), [
    error,
    clearError
  ]);

  const validationSchema = useValidationSchema({
    nameExceededMaxLengthMessage,
    nameRequiredMessage
  });

  return (
    <FormErrorContext.Provider value={errorContextValue}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onReset={clearError}
        enableReinitialize
      >
        <AddProgramDialogContent
          onClose={onClose}
          open={open}
          onCancel={onCancel}
        />
      </Formik>
    </FormErrorContext.Provider>
  );
};

AddProgramDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  initialName: PropTypes.string,
  onProgramAdd: PropTypes.func
};

export default AddProgramDialog;
