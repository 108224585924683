import { useColumns } from '~/modules/common/components/ListTable';
import { formatters } from './formatters';

export const buildColumns = ({ classes, headers, intl }) =>
  headers.reduce(
    (resColumns, current) => ({
      ...resColumns,
      [current.name]: {
        id: current.name,
        value:
          current.visible &&
          intl.formatMessage({ id: `revenueRun.${current.name}` }),
        visible: current.visible,
        className: classes[current.type],
        align: current.sortIndex > 0 ? 'right' : 'left'
      }
    }),
    {}
  );

export const useListColumns = ({ classes, headers, intl }) =>
  useColumns({
    columns: buildColumns({ classes, headers, intl }),
    renders: formatters({ headers })
  });
