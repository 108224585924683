import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from '@material-ui/core';
import get from 'lodash.get';
import LoadingButton from '~/modules/common/components/LoadingButton';
import {
  TABLE_KEY,
  getParsedDialogColumns,
  getSmartMatchColumnOptions,
  getAssignmentDialogColumnOptions,
  getDefaultAssignmentDialogColumnsUnfiltered
} from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers';
import { usePutTableSettings } from '~/modules/common/components/ListTable';
import { AssignmentDialogColumns } from '~/modules/resourcing/components/ResourceAssignmentDialog/enhancers/constants';
import { useResourceAssignmentDialogContext } from '~/modules/resourcing/components/ResourceAssignmentDialog/ResourceAssignmentDialogContext';
import useDialogSettings from '~/modules/common/hooks/useDialogSettings';
import { useTagKeys } from '~/modules/tags/components/Dropdowns/TagKeyDropdown';
import { useAssignmentDialogColumnPermission } from '~/modules/resourcing/components/ResourceAssignmentDialog/hooks';
import { useGetEnabledCustomFieldsQuery } from '~/modules/customFields/graphql';
import useSelectedOptions from '../../hooks/useSelectedOptions';
import useDialogSettingsSave from '../../hooks/useDialogSettingsSave';
import ResourceAllocationUserChartDialogDropdown from './ResourceAllocationUserChartDialogDropdown';
import useStyles from './useStyles';

const ResourceAllocationUserChartDialog = ({ open, onClose, me }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [isSaving, setIsSaving] = useState(false);
  const defaultColumns = getDefaultAssignmentDialogColumnsUnfiltered({ me });

  const { data: initialState, isLoading } = useDialogSettings({
    tableKey: TABLE_KEY,
    defaultColumns
  });

  const columnPermissions = useAssignmentDialogColumnPermission();

  const {
    data: customFieldsData,
    loading: customFieldLoading
  } = useGetEnabledCustomFieldsQuery({
    variables: { groupName: 'User' }
  });

  const customFieldDefinitions =
    get(customFieldsData, 'enabledCustomFields') || [];

  const { data: tagOptions, loading: tagsLoading } = useTagKeys({
    objectBinding: 'RESOURCE',
    search: ''
  });

  const smartMatchDropdownOptions = getSmartMatchColumnOptions({ me });
  const dropdownOptions = getAssignmentDialogColumnOptions({
    me,
    columnPermissions,
    customFieldDefinitions,
    tagFieldData: tagOptions
  });

  const dropdownFilteredOptions = dropdownOptions.filter(
    option => option.field !== AssignmentDialogColumns.DisplayText
  );

  const {
    selectedDialogOptions,
    setSelectedDialogOptions,
    setEnabledAssignmentDialogColumns,
    setIsCostOptimizationSmartMatchModeEnabled
  } = useResourceAssignmentDialogContext();

  const { onOptionChange, resetSelection, resetToDefault } = useSelectedOptions(
    {
      initialState,
      defaultColumns,
      allDropdownOptions: [...smartMatchDropdownOptions, ...dropdownOptions],
      selectedOptions: selectedDialogOptions,
      setSelectedOptions: setSelectedDialogOptions
    }
  );

  const onCancel = useCallback(() => {
    onClose();
    resetSelection();
  }, [resetSelection, onClose]);

  useEffect(() => {
    if (!isLoading && !tagsLoading && !customFieldLoading) {
      const parsedState = getParsedDialogColumns({
        columns: initialState,
        allDropdownOptions: [...smartMatchDropdownOptions, ...dropdownOptions]
      });

      setSelectedDialogOptions(parsedState);
    }
  }, [isLoading, tagsLoading, customFieldLoading]);

  const [putTableSettings] = usePutTableSettings();

  const onSave = useDialogSettingsSave({
    putTableSettings,
    setIsSaving,
    onClose,
    selectedOptions: selectedDialogOptions,
    tableKey: TABLE_KEY,
    setEnabledAssignmentDialogColumns,
    setIsCostOptimizationSmartMatchModeEnabled
  });
  const inputProps = useMemo(
    () => ({
      inputProps: {
        'aria-label': formatMessage({
          id: 'resourceAllocationUserItem.hours'
        })
      }
    }),
    [formatMessage]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="resource-allocation-settings-dialog-title"
      aria-describedby="resource-allocation-settings-dialog-description"
    >
      <DialogTitle id="resource-allocation-dialog-title">
        <FormattedMessage id="columnSettings.tableSettings" />
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            variant="standard"
            className={classes.root}
            fullWidth
            disabled
            InputProps={inputProps}
            defaultValue={formatMessage({
              id: 'resourceAllocationUserChartDialog.resource'
            })}
            label={formatMessage({
              id: 'resourceAllocationUserChartDialog.columnOne'
            })}
          />
          <ResourceAllocationUserChartDialogDropdown
            className={classes.root}
            options={dropdownFilteredOptions}
            label={formatMessage({
              id: 'resourceAllocationUserChartDialog.columnTwo'
            })}
            value={selectedDialogOptions[1]}
            onChange={e => onOptionChange(e, 1)}
            selectedOptions={selectedDialogOptions}
          />
          <ResourceAllocationUserChartDialogDropdown
            className={classes.root}
            options={smartMatchDropdownOptions}
            label={formatMessage({
              id: 'resourceAllocationUserChartDialog.columnThree'
            })}
            value={selectedDialogOptions[2]}
            onChange={e => onOptionChange(e, 2)}
            selectedOptions={selectedDialogOptions}
            disableClearable
            disabled={smartMatchDropdownOptions.length === 1}
          />
          <ResourceAllocationUserChartDialogDropdown
            options={dropdownFilteredOptions}
            label={formatMessage({
              id: 'resourceAllocationUserChartDialog.columnFour'
            })}
            value={selectedDialogOptions[3]}
            onChange={e => onOptionChange(e, 3)}
            selectedOptions={selectedDialogOptions}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetToDefault}>
          <FormattedMessage id="button.resetToDefault" />
        </Button>
        <span className={classes.actionsSpacer} />
        <Button onClick={onCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          isLoading={isSaving}
          onClick={onSave}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ResourceAllocationUserChartDialog.propTypes = {
  me: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ResourceAllocationUserChartDialog;
