import { IconButton, makeStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircleSharp';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  iconContainer: {
    padding: theme.spacing(1)
  }
}));

export const DeleteRow = ({ record }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext();

  const onClick = useCallback(() => {
    setFieldValue(
      'allowedExpensesAndEstimates',
      values.allowedExpensesAndEstimates.filter(({ id }) => id !== record.id)
    );

    setFieldValue('availableExpenseCodes', [
      ...values.availableExpenseCodes,
      record.expenseType
    ]);
  }, [
    record.expenseType,
    record.id,
    setFieldValue,
    values.allowedExpensesAndEstimates,
    values.availableExpenseCodes
  ]);

  return (
    <IconButton
      className={classes.iconContainer}
      onClick={onClick}
      aria-label={formatMessage({ id: 'button.delete' })}
    >
      <RemoveIcon fontSize="small" />
    </IconButton>
  );
};

DeleteRow.propTypes = {
  record: PropTypes.object.isRequired
};

export default DeleteRow;
