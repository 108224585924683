import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProgressTypes } from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';

const useStyles = makeStyles(theme => ({
  root: {
    '& button': {
      padding: theme.spacing(1.375),
      height: theme.spacing(4),
      width: '50%',
      fontFamily: theme.typography.button.fontFamily
    }
  }
}));

const BudgetBurnedToggle = ({ budgetBurnedView, setBudgetBurnedView }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    async (_, view) => {
      if (view !== null) {
        setBudgetBurnedView(view);
      }
    },
    [setBudgetBurnedView]
  );

  return (
    <ToggleButtonGroup
      classes={classes}
      size="small"
      value={budgetBurnedView}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton
        value={ProgressTypes.HOURS}
        data-qe-id="BudgetBurnedToggleButton_Hours"
      >
        <FormattedMessage id="dashboardProgressChart.hours" />
      </ToggleButton>
      <ToggleButton
        value={ProgressTypes.COST}
        data-qe-id="BudgetBurnedToggleButton_Cost"
      >
        <FormattedMessage id="dashboardProgressChart.cost" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

BudgetBurnedToggle.propTypes = {
  budgetBurnedView: PropTypes.string,
  setBudgetBurnedView: PropTypes.func
};

export default BudgetBurnedToggle;
