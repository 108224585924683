import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { StatusChipMenu } from '~/modules/common/components';
import { useChipMenuProps } from '~/modules/common/components/ChipMenu';
import { PortfolioStatus } from '~/types';
import useUpdateStatusHandlers from './hooks';

const tooltipProps = {
  title: <FormattedMessage id="portfolio.message" />
};

const PortfolioStatusChipMenu = ({
  id,
  editable = true,
  status,
  parentStatus
}) => {
  const { anchorEl, openMenu, closeMenu, open } = useChipMenuProps();

  const {
    onStatusActiveHandler,
    onStatusInActiveHandler
  } = useUpdateStatusHandlers({ id, closeMenu });

  const isParentInActive = parentStatus === PortfolioStatus.Inactive;

  return (
    <StatusChipMenu
      anchorEl={anchorEl}
      open={open}
      openMenu={openMenu}
      closeMenu={closeMenu}
      editable={editable && !isParentInActive}
      isActive={status === PortfolioStatus.Active}
      onActiveClick={onStatusActiveHandler}
      onInactiveClick={onStatusInActiveHandler}
      showTooltip={editable && isParentInActive}
      tooltipProps={tooltipProps}
    />
  );
};

PortfolioStatusChipMenu.propTypes = {
  id: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  parentStatus: PropTypes.string
};

export default PortfolioStatusChipMenu;
