import get from 'lodash.get';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useState, useCallback } from 'react';

export const getProjectRevenueEntriesHistoryQuery = gql`
  query getProjectRevenueEntriesHistory(
    $projectId: String!
    $page: Int!
    $pageSize: Int!
    $filter: RevenueEntryFilter
  ) {
    project(projectId: $projectId) {
      id
      displayText
      revenueContractDetails {
        id
        displayText
      }
      pageOfRevenueEntries(page: $page, pageSize: $pageSize, filter: $filter)
    }
    pageOfRevenueEntries2(page: $page, pageSize: $pageSize, filter: $filter)
  }
`;

export const useProjectRevenueEntriesHistory = ({
  page = 1,
  pageSize = 20,
  projectId
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const filter = {
    statusUris: [
      'urn:replicon:revenue-run-status:draft',
      'urn:replicon:revenue-run-status:complete'
    ],
    projects: [projectId]
  };
  const { data, loading, fetchMore, variables } = useQuery(
    getProjectRevenueEntriesHistoryQuery,
    {
      variables: { page, pageSize, projectId, filter },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    }
  );

  const { id, displayText, code, revenueContractDetails } =
    get(data, 'project') || {};

  const pageOfRevenueEntries = get(data, 'pageOfRevenueEntries2') || {};

  const hasMoreRows =
    pageOfRevenueEntries &&
    pageOfRevenueEntries.length !== 0 &&
    pageOfRevenueEntries.length % variables.pageSize === 0;

  const loadMoreRows = useCallback(() => {
    if (!hasMoreRows) {
      return;
    }

    setIsLoadingMore(true);
    fetchMore({
      query: getProjectRevenueEntriesHistoryQuery,
      variables: {
        page: pageOfRevenueEntries.length / variables.pageSize + 1,
        pageSize: variables.pageSize,
        projectId: variables.projectId,
        filter
      },
      updateQuery: (
        previousResult,
        {
          fetchMoreResult: {
            project: { pageOfRevenueEntries: nextResult }
          }
        }
      ) => ({
        project: {
          ...previousResult.project,
          pageOfRevenueEntries: [
            ...previousResult.project.pageOfRevenueEntries,
            ...nextResult
          ]
        }
      })
    })
      .then(() => {
        setIsLoadingMore(false);
      })
      .catch(() => {
        setIsLoadingMore(false);
      });
  }, [
    fetchMore,
    filter,
    hasMoreRows,
    pageOfRevenueEntries.length,
    variables.pageSize,
    variables.projectId
  ]);

  return {
    loading,
    hasMoreRows,
    isLoadingMore,
    loadMoreRows,
    project: loading
      ? {}
      : {
          id,
          displayText,
          code,
          revenueContractDetails: revenueContractDetails || {},
          revenueEntriesHistory: pageOfRevenueEntries || []
        }
  };
};

export default useProjectRevenueEntriesHistory;
