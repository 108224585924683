import { useCallback, useState } from 'react';
import { usePutTaskResourceEstimate } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/hooks';

const toggleTaskAssignment = async ({
  defaultRole,
  estimatedHours,
  isAssignedTask,
  onTaskAssignmentUpdateSuccess,
  openRemoveTaskAssignmentDialog,
  putTaskResourceEstimate,
  setRoleDisplayText,
  setIsAssignedTask,
  taskId,
  userId
}) => {
  if (!isAssignedTask) {
    await putTaskResourceEstimate({
      taskId,
      resourceUserId: userId,
      projectRoleId: defaultRole?.id,
      initialEstimatedHours: estimatedHours
    });
    setRoleDisplayText(defaultRole?.displayText);
    setIsAssignedTask(true);
    onTaskAssignmentUpdateSuccess({ userId, isAssignedTask });
  } else {
    openRemoveTaskAssignmentDialog();
  }
};

const useOnChangeHandlers = ({
  isAssignedTask,
  defaultRole,
  userId,
  taskId,
  estimatedHours,
  setIsAssignedTask,
  setRoleDisplayText,
  setEstimatedHours,
  openRemoveTaskAssignmentDialog,
  closeRemoveTaskAssignmentDialog,
  onTaskAssignmentUpdateSuccess,
  canEditTask
}) => {
  const { putTaskResourceEstimate, loading } = usePutTaskResourceEstimate();
  const [autoFocus, setAutoFocus] = useState(false);

  return {
    loading,
    handleTaskClick: useCallback(() => {
      if (!canEditTask) return;

      toggleTaskAssignment({
        defaultRole,
        estimatedHours,
        isAssignedTask,
        onTaskAssignmentUpdateSuccess,
        openRemoveTaskAssignmentDialog,
        putTaskResourceEstimate,
        setRoleDisplayText,
        setIsAssignedTask,
        taskId,
        userId
      });
    }, [
      canEditTask,
      isAssignedTask,
      putTaskResourceEstimate,
      taskId,
      userId,
      defaultRole,
      estimatedHours,
      setRoleDisplayText,
      setIsAssignedTask,
      openRemoveTaskAssignmentDialog,
      onTaskAssignmentUpdateSuccess
    ]),
    handleKeyDown: useCallback(
      event => {
        if (!canEditTask || event.code !== 'Space') return;
        setAutoFocus(true);
        toggleTaskAssignment({
          defaultRole,
          estimatedHours,
          isAssignedTask,
          onTaskAssignmentUpdateSuccess,
          openRemoveTaskAssignmentDialog,
          putTaskResourceEstimate,
          setRoleDisplayText,
          setIsAssignedTask,
          taskId,
          userId
        });
      },
      [
        canEditTask,
        defaultRole,
        estimatedHours,
        isAssignedTask,
        onTaskAssignmentUpdateSuccess,
        openRemoveTaskAssignmentDialog,
        putTaskResourceEstimate,
        setIsAssignedTask,
        setRoleDisplayText,
        taskId,
        userId
      ]
    ),
    handleDrawerIconClick: useCallback(e => {
      e.stopPropagation();
    }, []),
    onRemoveTaskAssignmentSuccess: useCallback(() => {
      setIsAssignedTask(false);
      setRoleDisplayText(defaultRole?.displayText);
      setEstimatedHours(null);
      onTaskAssignmentUpdateSuccess({ userId, isAssignedTask });
      closeRemoveTaskAssignmentDialog();
    }, [
      setIsAssignedTask,
      setRoleDisplayText,
      defaultRole?.displayText,
      setEstimatedHours,
      userId,
      isAssignedTask,
      closeRemoveTaskAssignmentDialog,
      onTaskAssignmentUpdateSuccess
    ]),
    autoFocus
  };
};

export default useOnChangeHandlers;
