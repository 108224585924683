import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { Skeleton } from '@material-ui/lab';
import {
  ResourcingPeriodDropDown,
  ResourcingLegends,
  resourcingLegendItems,
  ResourcingDisplayUnitToggleButtonGroup
} from '~/modules/resourcing/common/components';
import useResourceAllocationToolbarStateContext from '~/modules/resourcing/hooks/useResourceAllocationToolbarStateContext';
import useResourceAllocationChartTabStateContext, {
  RESOURCE_ALLOCATION_CHART_TAB_ENUM
} from '~/modules/resourcing//hooks/useResourceAllocationChartTabStateContext';
import { PeriodSelector } from '~/modules/common/components';
import ResourcePlanGroupDropdown from '~/modules/resourcing/components/ResourcePlanGroupDropdown';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { useHasFeatureFlag } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.background.paper,
    flexGrow: 0,
    flexShrink: 0,
    position: 'sticky',
    left: 0,
    zIndex: 10,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  toggleGroup: {
    marginLeft: theme.spacing(1)
  },
  spacing: {
    marginLeft: theme.spacing(2)
  }
}));

export const getLegends = selectedTab => {
  switch (selectedTab) {
    case RESOURCE_ALLOCATION_CHART_TAB_ENUM.PROPOSED:
      return [
        resourcingLegendItems.request,
        resourcingLegendItems.proposed,
        resourcingLegendItems.pending
      ];
    case RESOURCE_ALLOCATION_CHART_TAB_ENUM.TOBEHIRED:
      return [resourcingLegendItems.toBeHired];
    case RESOURCE_ALLOCATION_CHART_TAB_ENUM.ALL:
      return [
        resourcingLegendItems.request,
        resourcingLegendItems.requestRejected,
        resourcingLegendItems.toBeHired,
        resourcingLegendItems.resourceRejected,
        resourcingLegendItems.pending,
        resourcingLegendItems.proposed,
        resourcingLegendItems.complete
      ];
    default:
      return [
        resourcingLegendItems.request,
        resourcingLegendItems.resourceRejected,
        resourcingLegendItems.pending
      ];
  }
};

export const ResourceAllocationToolbar = ({ classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    dateRange,
    scale,
    setScale,
    resourcePlanGroup,
    setResourcePlanGroup,
    displayUnit,
    setDisplayUnit,
    onNextClick: onFixedRangeNextClick,
    onPreviousClick: onFixedRangePreviousClick,
    isResourcingPageChartSettingsLoading
  } = useResourceAllocationToolbarStateContext();

  const isPSAQuickAllocationDemandEnabled = useHasFeatureFlag({
    featureFlag: 'isPSAQuickAllocationDemandEnabled'
  });

  const handleChange = useCallback(
    event => {
      const newScale = event.target && event.target.value;

      setScale(newScale);
    },
    [setScale]
  );

  const { selectedChartTab } = useResourceAllocationChartTabStateContext();

  const legends = useMemo(() => getLegends(selectedChartTab), [
    selectedChartTab
  ]);

  return (
    <StickyHeader level={1} rightPadding>
      <PeriodSelector
        onPreviousClick={onFixedRangePreviousClick}
        onNextClick={onFixedRangeNextClick}
        dateRange={dateRange}
      />
      <div className={classes.spacer}>
        <ResourcePlanGroupDropdown
          value={resourcePlanGroup}
          onChange={setResourcePlanGroup}
        />
      </div>
      <div className={classes.spacer} />
      <ResourcingLegends legends={legends} />
      <div className={classes.toggleGroup}>
        {isResourcingPageChartSettingsLoading ? (
          <Skeleton variant="rect" width={65} height={32} />
        ) : (
          <ResourcingDisplayUnitToggleButtonGroup
            displayUnit={displayUnit}
            setDisplayUnit={setDisplayUnit}
          />
        )}
      </div>
      {isResourcingPageChartSettingsLoading ? (
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width={101}
          height={32}
        />
      ) : (
        <ResourcingPeriodDropDown
          data-qe-id="ResourceFrequencyFilter"
          scale={scale}
          setScale={setScale}
          onChange={handleChange}
          showYears={!isPSAQuickAllocationDemandEnabled}
        />
      )}
    </StickyHeader>
  );
};

ResourceAllocationToolbar.propTypes = {
  classes: PropTypes.object
};

export default ResourceAllocationToolbar;
