import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { useProjectDropdownOptions } from './useProjectDropdownOptions';

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

const getOptionLabel = option => option.displayText;

const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const SearchableProjectDropdown = ({
  classes,
  label,
  value,
  includePermissions = false,
  onChange,
  disabled = false,
  excludeProjectIds = []
}) => {
  const intl = useIntl();

  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);
  const { loading, projects, hasMore } = useProjectDropdownOptions({
    searchTerm: value && value.id ? '' : searchTerm,
    includePermissions
  });
  const renderInput = useCallback(
    params => <TextField {...params} label={label} />,
    [label]
  );
  const options =
    excludeProjectIds.length > 0
      ? projects.filter(p => !excludeProjectIds.includes(p.id))
      : projects;

  return (
    <SimpleAutocomplete
      classes={classes}
      hasMore={hasMore}
      loading={loading}
      forcePopupIcon
      fullWidth
      value={value}
      disabled={disabled}
      onChange={onChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      renderInput={renderInput}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={getOptionDisabled}
      optionTypeText={intl.formatMessage({ id: 'moreOptions.projects' })}
    />
  );
};

SearchableProjectDropdown.propTypes = {
  classes: PropTypes.object,
  includePermissions: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.node,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  excludeProjectIds: PropTypes.array
};

export default SearchableProjectDropdown;
