import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { useIsBreakpointUp } from '~/modules/common/hooks/useBreakpoint';
import { ProjectTypeToggle } from '~/modules/common/components/ProjectTypeToggle';
import { ColumnSettingsDialog } from '~/modules/common/components/ListTable';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { PROJECTS_TAG_BINDING_KEY } from '~/modules/common/hooks/project/projectColumns';
import { PROJECT_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectListPageType';
import { usePortfolioProjectContext } from '../../../PortfolioProjectsContext';
import ProjectSearchBox from '../../../Toolbar/ProjectSearchBox';

import { PORTFOLIO_PROJECTS_DIALOG_TABLE_KEY } from '../../portfolioProjectsDialogTableSettings';
import defaultColumns from './defaultColumns';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    minHeight: '-webkit-fit-content',
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1)
    }
  }
}));

const useSearchStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1)
    }
  }
}));

const SearchFilterToolbar = ({ portfolioId, enabledColumns }) => {
  const classes = useStyles();
  const searchClasses = useSearchStyles();
  const showSettings = useIsBreakpointUp('md');

  const { formatMessage } = useIntl();
  const {
    projectType,
    onProjectTypeClick,
    searchCriteria,
    setSearchCriteria
  } = usePortfolioProjectContext();

  const projectListPageType = PROJECT_LIST_PAGE_TYPE.PORTFOLIO;

  const { enabledProjectColumnGroups = [] } = useEnabledProjectColumns({
    projectListPageType
  });

  const { open, openDialog, closeDialog } = useDialogState();

  return (
    <>
      <div className={classes.root}>
        {setSearchCriteria && <ProjectSearchBox classes={searchClasses} />}
        <ProjectTypeToggle
          projectType={projectType}
          searchCriteria={searchCriteria}
          onProjectTypeClick={onProjectTypeClick}
          excludePortfolios={[portfolioId]}
        />
        {showSettings && (
          <IconButton
            aria-label={formatMessage({ id: 'settingsActionLink.settings' })}
            onClick={openDialog}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </div>
      {showSettings && (
        <ColumnSettingsDialog
          tableKey={PORTFOLIO_PROJECTS_DIALOG_TABLE_KEY}
          groups={enabledProjectColumnGroups}
          selectedColumns={enabledColumns}
          defaultColumns={defaultColumns}
          open={open}
          onClose={closeDialog}
          tagsObjectBinding={PROJECTS_TAG_BINDING_KEY}
        />
      )}
    </>
  );
};

SearchFilterToolbar.propTypes = {
  portfolioId: PropTypes.string,
  enabledColumns: PropTypes.array
};

export default SearchFilterToolbar;
