import { notFoundRoute } from '~/modules/common/notfound';
import { notAuthorizedRoute } from '~/modules/common/notAuthorized';
import { unhandledErrorRoute } from '~/modules/common/unhandled-error';
import { taskBeatRoute } from '~/modules/taskBeat';
import { projectsRoute, projectRoute } from '~/modules/projects';
import { resourcingRoute } from '~/modules/resourcing';
import { quickAllocationRoute } from '~/modules/quickAllocation';
import resourcingAssignmentRoute from '~/modules/resourcing/components/ResourceAssignmentDialog/route';
import projectResourceAssignmentRoute from '~/modules/resourcing/components/ResourceAssignmentDialog/projectResourceAssignmentRoute';
import { resourcePoolDetailsRoute } from '~/modules/resource-management/resourceUserDetails';
import { resourcePoolRoute } from '~/modules/resource-management';
import { programRoute, programsRoute } from '~/modules/programs';
import { clientRoute, clientsRoute } from '~/modules/clients';
import { billingInvoicingRoute } from '~/modules/billing-invoicing';
import { projectRequestRoute } from '~/modules/project-request';
import { portfolioRoute, portfoliosRoute } from '~/modules/portfolios';
import { notificationsRoute } from '~/modules/notifications';
import { userProfileRoute } from '~/modules/userProfile';
import { myWorkRoute } from '~/modules/myWork';
import { userSettingsRoute } from '~/modules/userSettings';
import { offlineRoute } from '~/modules/common/components/Offline';
import { businessIntelligenceRoute } from '~/modules/businessIntelligence';
import scheduleMonthDetailsRoutes from '~/modules/billing-invoicing/revenueRecognition/components/Schedule/ScheduleMonthDetails/scheduleMonthDetailsRoutes';
import { myTeamRoute } from '~/modules/myTeam';
import { vendorRoute, vendorsRoute } from '~/modules/vendors';
import { cpoRoute } from './cpo';
import { payrollRoute } from './payroll';

const buildRoutes = ({ intl, me, isMobile }) => {
  const routes = {
    resourcePoolDetails: resourcePoolDetailsRoute({ intl, me }),
    resourcePool: resourcePoolRoute({ intl, me }),
    resourcingAssignmentRoute: resourcingAssignmentRoute({
      intl,
      me,
      isMobile
    }),
    projectResourceAssignmentRoute: projectResourceAssignmentRoute({
      me,
      isMobile
    }),
    resourcing: resourcingRoute({ intl, me, isMobile }),
    portfolio: portfolioRoute({ intl, me }),
    portfolios: portfoliosRoute({ intl, me }),
    client: clientRoute({ intl, me }),
    clients: clientsRoute({ intl, me }),
    program: programRoute({ intl, me }),
    programs: programsRoute({ intl, me }),
    project: projectRoute({ intl, me }),
    projects: projectsRoute({ intl, me }),
    vendor: vendorRoute({ intl, me }),
    vendors: vendorsRoute({ intl, me }),
    quickAllocation: quickAllocationRoute({ intl, me }),
    projectRequest: projectRequestRoute({ intl, me }),
    userSettings: userSettingsRoute({ intl, me }),
    myWork: myWorkRoute({ intl, me }),
    myTeam: myTeamRoute({ intl, me }),
    userProfile: userProfileRoute({ intl, me }),
    taskBeat: taskBeatRoute({ intl, me }),
    scheduleMonthDetailsRoutes: scheduleMonthDetailsRoutes({ intl, me }),
    billing: billingInvoicingRoute({ intl, me, isMobile }),
    notifications: notificationsRoute({ intl, me }),
    payroll: payrollRoute({ intl, me, isMobile }),
    costPriceOptimizer: cpoRoute({ intl, me, isMobile }),
    businessIntelligence: businessIntelligenceRoute({ intl, me }),
    unhandledError: unhandledErrorRoute({ intl, me }),
    notAuthorized: notAuthorizedRoute({ intl, me }),
    offline: offlineRoute({ intl, me, isMobile }),
    notFound: notFoundRoute({ intl, me }) // don't put new routes below me
  };

  const filteredRoutes = {};

  Object.keys(routes)
    .filter(key => routes[key] && routes[key].hasPermission)
    .forEach(key => {
      filteredRoutes[key] = routes[key];
    });

  return filteredRoutes;
};

export default buildRoutes;
