import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CardExpansionPanel } from '~/modules/common/components';
import { allowedExpensesAndEstimatesColumns } from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';
import { AllowedExpensesAndEstimates } from '../AllowedExpensesAndEstimates';
import { ExpenseSummary } from './ExpenseSummary';
import { ExpenseReadOnlyTitle } from './ExpenseReadOnlyTitle';

export const useCardExpansionPanelStyles = makeStyles(theme => ({
  expansionPanel: {
    boxShadow: 'none',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  expansionPanelDetails: {
    display: 'block',
    overflow: 'auto',
    padding: 0,
    '& thead tr th': {
      borderTop: 'unset'
    }
  },
  expansionPanelSummary: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    backgroundColor: 'unset',
    fontSize: theme.typography.body1.fontSize,
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: 0,
    '&$expanded': {
      minHeight: 0,
      padding: 0,
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  expansionPanelSummaryContent: {
    margin: 0,
    display: 'flex',
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {
    borderBottom: 'unset'
  },
  expandIcon: { marginRight: 0 }
}));

const useColumnStyles = makeStyles(theme => ({
  expenseType: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    width: 'auto',
    minWidth: 'auto',
    whiteSpace: 'break-spaces'
  }
}));

const useProviderClasses = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    padding: theme.spacing(0, 1)
  },
  tableHeaderCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: 'unset',
    padding: theme.spacing(0.5),
    height: 'auto',
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
    borderCollapse: 'separate',
    height: 'auto'
  },
  tableFooterCell: {
    borderTop: 'unset',
    borderBottom: 'unset',
    padding: theme.spacing(0.5),
    borderCollapse: 'separate',
    height: 'auto'
  }
}));

const visibleColumns = [
  allowedExpensesAndEstimatesColumns.EXPENSE_TYPE,
  allowedExpensesAndEstimatesColumns.ESTIMATE
];

export const ExpenseReadOnlyContent = ({
  expenseBudgetedCost,
  onViewSummaryClick
}) => {
  const providerClasses = useProviderClasses();
  const columnClasses = useColumnStyles();
  const cardExpansionPanelStyles = useCardExpansionPanelStyles();
  const { values } = useFormikContext();

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <ExpenseSummary expenseBudgetedCost={expenseBudgetedCost} />
      </Grid>
      {values.allowedExpensesAndEstimates.length > 0 ? (
        <>
          <Grid item xs={12}>
            <CardExpansionPanel
              TitleComponent={ExpenseReadOnlyTitle}
              defaultExpanded={false}
              classes={cardExpansionPanelStyles}
            >
              <AllowedExpensesAndEstimates
                editable={false}
                visibleColumns={visibleColumns}
                columnClasses={columnClasses}
                providerClasses={providerClasses}
              />
            </CardExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" onClick={onViewSummaryClick}>
              <FormattedMessage id="projectTimeAndExpenseCard.viewSummary" />
            </Button>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

ExpenseReadOnlyContent.propTypes = {
  expenseBudgetedCost: PropTypes.object,
  onViewSummaryClick: PropTypes.func
};
