import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './styles';

export const NotificationPolicyHeader = () => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.row, classes.header)} role="row">
      <div
        className={classNames(classes.cell, classes.label)}
        role="columnheader"
      >
        <FormattedMessage id="settingsPage.notifications.event" />
      </div>
      <div
        data-qe-id="web header"
        className={classNames(classes.cell, classes.channel)}
        role="columnheader"
      >
        <FormattedMessage id="settingsPage.notifications.web" />
      </div>
      <div
        data-qe-id="email header"
        className={classNames(classes.cell, classes.channel)}
        role="columnheader"
      >
        <FormattedMessage id="settingsPage.notifications.email" />
      </div>
      <div
        data-qe-id="mobilePush header"
        className={classNames(classes.cell, classes.channel)}
        role="columnheader"
      >
        <FormattedMessage id="settingsPage.notifications.mobilePush" />
      </div>
    </div>
  );
};

NotificationPolicyHeader.propTypes = {};

export default NotificationPolicyHeader;
