import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import { PROJECT_LIST_PAGE_TYPE } from '~/modules/common/enums/ProjectListPageType';

export const TABLE_KEY = 'psa-projects';
export const PROJECTS_TAG_BINDING_KEY = 'PROJECT';

export const columns = {
  projectLink: {
    field: 'linkType',
    value: <FormattedMessage id="projectLink.linkType" />,
    fixed: true,
    sortable: false
  },
  name: {
    field: 'name',
    value: <FormattedMessage id="projectList.project" />,
    required: true,
    fixed: true,
    sortable: true,
    includesNavigationComponent: true
  },
  projectCode: {
    field: 'code',
    value: <FormattedMessage id="projectList.projectCode" />,
    align: 'left',
    sortable: true
  },
  workflowStatus: {
    field: 'status',
    value: <FormattedMessage id="projectList.projectStatus" />,
    sortable: false // status column is metadata column, sorting on this column is hence disabled
  },
  portfolio: {
    field: 'portfolio',
    value: <FormattedMessage id="projectList.portfolio" />,
    sortable: true
  },
  program: {
    field: 'program',
    value: <FormattedMessage id="projectList.program" />,
    sortable: true
  },
  client: {
    field: 'client',
    value: <FormattedMessage id="projectList.client" />,
    sortable: true
  },
  projectManagerReference: {
    field: 'projectManagerReference',
    value: <FormattedMessage id="projectList.projectLeader" />,
    sortable: true
  },
  budgetHours: {
    field: 'budgetHours',
    value: <FormattedMessage id="projectList.budget" />,
    align: 'right',
    sortable: true
  },
  estimatedHours: {
    field: 'estimatedHours',
    value: <FormattedMessage id="projectList.totalContractValue" />,
    align: 'right',
    sortable: true
  },
  actualHours: {
    field: 'actualHours',
    value: <FormattedMessage id="projectList.actual" />,
    align: 'right',
    sortable: true
  },
  rolledUpEstimatedRemainingHours: {
    field: 'rolledUpEstimatedRemainingHours',
    value: <FormattedMessage id="projectList.workRemaining" />,
    align: 'right',
    sortable: true
  },
  scopeHoursCompleted: {
    field: 'scopeHoursCompleted',
    value: <FormattedMessage id="projectList.scopeCompleted" />,
    align: 'right',
    sortable: true
  },
  estimatedHoursAtCompletion: {
    field: 'estimatedHoursAtCompletion',
    value: <FormattedMessage id="projectList.estimatedAtTaskCompletion" />,
    align: 'right',
    sortable: true
  },
  taskHours: {
    field: 'totalTaskHours',
    value: <FormattedMessage id="projectList.taskHours" />,
    align: 'right',
    sortable: true
  },
  budgetHoursUsed: {
    field: 'budgetHoursUsed',
    value: <FormattedMessage id="projectList.budgetHoursUsed" />,
    align: 'right',
    sortable: true
  },
  budgetHoursRemaining: {
    field: 'budgetHoursRemaining',
    value: <FormattedMessage id="projectList.budgetRemaining" />,
    align: 'right',
    sortable: true
  },
  estimatedBudgetHoursRemaining: {
    field: 'estimatedBudgetHoursRemaining',
    value: <FormattedMessage id="projectList.budgetVsScope" />,
    align: 'right',
    sortable: true
  },
  taskHoursCompleted: {
    field: 'taskHoursCompleted',
    value: <FormattedMessage id="projectList.taskHoursCompleted" />,
    align: 'right',
    sortable: true
  },
  startDate: {
    field: 'startDate',
    value: <FormattedMessage id="projectList.startDate" />,
    align: 'right',
    sortable: true
  },
  endDate: {
    field: 'endDate',
    value: <FormattedMessage id="projectList.endDate" />,
    align: 'right',
    sortable: true
  },
  initialTaskStartDate: {
    field: 'initialTaskStartDate',
    value: <FormattedMessage id="projectList.actualTaskStartDate" />,
    align: 'right',
    sortable: true
  },
  estimatedTaskCompletionDate: {
    field: 'estimatedTaskCompletionDate',
    value: <FormattedMessage id="projectList.estimatedTaskCompletionDate" />,
    align: 'right',
    sortable: true
  },
  finalTaskEndDate: {
    field: 'finalTaskEndDate',
    value: <FormattedMessage id="projectList.actualTaskCompletionDate" />,
    align: 'right',
    sortable: true
  },
  budgetedCost: {
    field: 'budgetedCost',
    value: <FormattedMessage id="projectList.budget" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: ({ symbol }) => symbol || null
  },
  budgetCostAmountInPortfolioCurrency: {
    field: 'budgetCostAmountInPortfolioCurrency',
    value: <FormattedMessage id="projectList.budget" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.portfolioCurrency" />
    )
  },
  budgetedCostInProjectCurrency: {
    field: 'budgetedCostInProjectCurrency',
    value: <FormattedMessage id="projectList.budget" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.projectCurrency" />
    )
  },
  estimatedCost: {
    field: 'estimatedCost',
    value: <FormattedMessage id="projectList.totalContractValue" />,
    align: 'right',
    sortable: true
  },
  actualResourceCost: {
    field: 'actualResourceCost',
    value: <FormattedMessage id="projectList.actualResourceCost" />,
    align: 'right',
    sortable: true
  },
  actualExpenseCost: {
    field: 'actualExpenseCost',
    value: <FormattedMessage id="projectList.actualExpenseCost" />,
    align: 'right',
    sortable: true
  },
  actualCost: {
    field: 'actualCost',
    value: <FormattedMessage id="projectList.actual" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: ({ symbol }) => symbol || null
  },
  estimatedCostAtCompletion: {
    field: 'estimatedCostAtCompletion',
    value: <FormattedMessage id="projectList.estimatedAtTaskCompletion" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: ({ symbol }) => symbol || null
  },
  estimatedCostAtCompletionInProjectCurrency: {
    field: 'estimatedCostAtCompletionInProjectCurrency',
    value: <FormattedMessage id="projectList.estimatedAtTaskCompletion" />,
    align: 'right',
    sortable: false,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.projectCurrency" />
    )
  },
  estimatedCostAtCompletionInPortfolioCurrency: {
    field: 'estimatedCostAtCompletionInPortfolioCurrency',
    value: <FormattedMessage id="projectList.estimatedAtTaskCompletion" />,
    align: 'right',
    sortable: false,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.portfolioCurrency" />
    )
  },
  budgetCostUsedPercentage: {
    field: 'budgetCostUsedPercentage',
    value: <FormattedMessage id="projectList.budgetHoursUsed" />,
    align: 'right',
    sortable: true
  },
  estimatedBudgetCostRemaining: {
    field: 'estimatedBudgetCostRemaining',
    value: <FormattedMessage id="projectList.budgetVsScope" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: ({ symbol }) => symbol || null
  },
  estimatedBudgetCostRemainingInProjectCurrency: {
    field: 'estimatedBudgetCostRemainingInProjectCurrency',
    value: <FormattedMessage id="projectList.budgetVsScope" />,
    align: 'right',
    sortable: false,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.projectCurrency" />
    )
  },
  estimatedBudgetCostRemainingInPortfolioCurrency: {
    field: 'estimatedBudgetCostRemainingInPortfolioCurrency',
    value: <FormattedMessage id="projectList.budgetVsScope" />,
    align: 'right',
    sortable: false,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.portfolioCurrency" />
    )
  },
  actualCostAmountInPortfolioCurrency: {
    field: 'actualCostAmountInPortfolioCurrency',
    value: <FormattedMessage id="projectList.actualCost" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.portfolioCurrency" />
    )
  },
  actualCostInProjectCurrency: {
    field: 'actualCostInProjectCurrency',
    value: <FormattedMessage id="projectList.actual" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.projectCurrency" />
    )
  },
  estimatedContract: {
    field: 'totalEstimatedContract',
    value: <FormattedMessage id="projectList.totalContractValue" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: ({ symbol }) => symbol || null
  },
  estimatedContractInProjectCurrency: {
    field: 'totalEstimatedContractInProjectCurrency',
    value: <FormattedMessage id="projectList.totalContractValue" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.projectCurrency" />
    )
  },
  actualBilling: {
    field: 'actualBilling',
    value: <FormattedMessage id="projectList.billableRevenue" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: ({ symbol }) => symbol || null
  },
  actualBillingInProjectCurrency: {
    field: 'actualBillingInProjectCurrency',
    value: <FormattedMessage id="projectList.billableRevenue" />,
    align: 'right',
    sortable: true,
    getSecondaryValue: () => (
      <FormattedMessage id="projectList.projectCurrency" />
    )
  },
  revenueRecognitionPolicy: {
    field: 'revenueRecognitionPolicy',
    value: <FormattedMessage id="projectList.revenueRecognitionPolicy" />,
    sortable: true
  }
};

const configuredGroupColumns = ({
  costCenterSettings,
  departmentSettings,
  divisionSettings,
  employeeTypeSettings,
  locationSettings,
  serviceCenterSettings
}) => ({
  costCenter: {
    field: 'costCenter',
    value: costCenterSettings.singularName || (
      <FormattedMessage id="groups.costCenter" />
    ),
    sortable: true
  },
  department: {
    field: 'department',
    value: departmentSettings.singularName || (
      <FormattedMessage id="groups.department" />
    ),
    sortable: true
  },
  division: {
    field: 'division',
    value: divisionSettings.singularName || (
      <FormattedMessage id="groups.division" />
    ),
    sortable: true
  },
  employeeType: {
    field: 'employeeType',
    value: employeeTypeSettings.singularName || (
      <FormattedMessage id="groups.employeeType" />
    ),
    sortable: true
  },
  location: {
    field: 'location',
    value: locationSettings.singularName || (
      <FormattedMessage id="groups.location" />
    ),
    sortable: true
  },
  serviceCenter: {
    field: 'serviceCenter',
    value: serviceCenterSettings.singularName || (
      <FormattedMessage id="groups.serviceCenter" />
    ),
    sortable: true
  }
});

const footers = {
  total: {
    fixed: true,
    value: <FormattedMessage id="projectList.total" />,
    align: 'right'
  },
  totalBudgetHours: {
    field: 'totalBudgetHours',
    requiredColumn: 'budgetHours',
    align: 'right'
  },
  totalRolledUpEstimatedRemainingHours: {
    field: 'totalRolledUpEstimatedRemainingHours',
    requiredColumn: 'rolledUpEstimatedRemainingHours',
    align: 'right'
  },
  totalBudgetHoursUsed: {
    field: 'totalBudgetHoursUsed',
    requiredColumn: 'budgetHoursUsed',
    align: 'right'
  },
  totalScopeHoursCompleted: {
    field: 'totalScopeHoursCompleted',
    requiredColumn: 'scopeHoursCompleted',
    align: 'right'
  },
  totalEstimatedHours: {
    field: 'totalEstimatedHours',
    requiredColumn: 'estimatedHours',
    align: 'right'
  },
  totalActualHours: {
    field: 'totalActualHours',
    requiredColumn: 'actualHours',
    align: 'right'
  },
  totalEstimatedCostAtCompletion: {
    field: 'totalEstimatedCostAtCompletion',
    requiredColumn: 'estimatedCostAtCompletion',
    align: 'right'
  },
  totalEstimatedCostAtCompletionInPortfolioCurrency: {
    field: 'totalEstimatedCostAtCompletionInPortfolioCurrency',
    requiredColumn: 'estimatedCostAtCompletionInPortfolioCurrency',
    align: 'right'
  },
  totalBudgetCostUsedPercentage: {
    field: 'totalBudgetCostUsedPercentage',
    requiredColumn: 'budgetCostUsedPercentage',
    align: 'right'
  },
  totalEstimatedBudgetCostRemaining: {
    field: 'totalEstimatedBudgetCostRemaining',
    requiredColumn: 'estimatedBudgetCostRemaining',
    align: 'right'
  },
  totalEstimatedBudgetCostRemainingInPortfolioCurrency: {
    field: 'totalEstimatedBudgetCostRemainingInPortfolioCurrency',
    requiredColumn: 'estimatedBudgetCostRemainingInPortfolioCurrency',
    align: 'right'
  },
  totalBudgetHoursRemaining: {
    field: 'totalBudgetHoursRemaining',
    requiredColumn: 'budgetHoursRemaining',
    align: 'right'
  },
  totalEstimatedBudgetHoursRemaining: {
    field: 'totalEstimatedBudgetHoursRemaining',
    requiredColumn: 'estimatedBudgetHoursRemaining',
    align: 'right'
  },
  totalTaskHours: {
    field: 'totalTaskHours',
    requiredColumn: 'totalTaskHours',
    align: 'right'
  },
  totalEstimatedHoursAtCompletion: {
    field: 'totalEstimatedHoursAtCompletion',
    requiredColumn: 'estimatedHoursAtCompletion',
    align: 'right'
  },
  totalBudgetedCost: {
    field: 'totalBudgetedCost',
    requiredColumn: 'budgetedCost',
    align: 'right'
  },
  totalBudgetCostAmountInPortfolioCurrency: {
    field: 'totalBudgetCostAmountInPortfolioCurrency',
    requiredColumn: 'budgetCostAmountInPortfolioCurrency',
    align: 'right'
  },
  totalActualCostAmountInPortfolioCurrency: {
    field: 'totalActualCostAmountInPortfolioCurrency',
    requiredColumn: 'actualCostAmountInPortfolioCurrency',
    align: 'right'
  },
  totalActualExpenseCost: {
    field: 'totalActualExpenseCost',
    requiredColumn: 'actualExpenseCost',
    align: 'right'
  },
  totalActualResourceCost: {
    field: 'totalActualResourceCost',
    requiredColumn: 'actualResourceCost',
    align: 'right'
  },
  totalActualCost: {
    field: 'totalActualCost',
    requiredColumn: 'actualCost',
    align: 'right'
  },
  totalEstimatedContract: {
    field: 'totalEstimatedContract',
    requiredColumn: 'totalEstimatedContract',
    align: 'right'
  },
  totalActualBilling: {
    field: 'totalActualBilling',
    requiredColumn: 'actualBilling',
    align: 'right'
  }
};

const emptyFooter = field => ({
  field,
  requiredColumn: field
});

const columnGroups = (me, tagFieldData = [], options = {}) => {
  const {
    isExpenseProductEnabled,
    permissionsMap = {},
    featureFlags = {},
    isRolledUpTaskEstimateCalculationMethodEnabled,
    groupSettings,
    baseCurrency
  } = me;

  const { projectListPageType, hideProgram, hideClient } = options;

  const { costColumns, costFooters } = enabledCostColumnsAndFooters({
    isExpenseProductEnabled,
    permissionsMap,
    featureFlags,
    baseCurrency,
    projectListPageType,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });
  const { hourColumns, hourFooters } = enabledHourColumnsAndFooters(
    permissionsMap,
    isRolledUpTaskEstimateCalculationMethodEnabled
  );

  const { isPsaPrpProjectLinkEnabled } = featureFlags;

  const { dateColumns, dateFooters } = enabledDateColumnsAndFooters();
  const { billingColumns, billingFooters } = enabledBillingColumnsAndFooters(
    permissionsMap,
    baseCurrency
  );

  const { groupColumns, groupFooters } = enabledGroupColumnsAndFooters(
    groupSettings,
    permissionsMap
  );

  const tagsColumnGroup =
    tagFieldData.length > 0 && mapTagFieldsToColumnGroup(tagFieldData);

  const canViewPortfolio = Boolean(
    permissionsMap['urn:replicon:project-action:view-portfolio']
  );

  return [
    {
      id: 'basic',
      title: null,
      columns: [
        isPsaPrpProjectLinkEnabled &&
          projectListPageType === PROJECT_LIST_PAGE_TYPE.PROJECT_LINK &&
          columns.projectLink,
        columns.name,
        columns.projectCode,
        columns.workflowStatus,
        canViewPortfolio && columns.portfolio,
        !hideProgram && columns.program,
        !hideClient && columns.client,
        columns.projectManagerReference
      ].filter(x => x),
      footers: [
        isPsaPrpProjectLinkEnabled &&
          projectListPageType === PROJECT_LIST_PAGE_TYPE.PROJECT_LINK &&
          emptyFooter(columns.projectLink.field),
        footers.total,
        emptyFooter(columns.projectCode.field),
        emptyFooter(columns.workflowStatus.field),
        canViewPortfolio && emptyFooter(columns.portfolio.field),
        !hideProgram && emptyFooter(columns.program.field),
        !hideClient && emptyFooter(columns.client.field),
        emptyFooter(columns.projectManagerReference.field)
      ].filter(x => x)
    },
    {
      id: 'hours',
      title: <FormattedMessage id="projectList.hours" />,
      columns: hourColumns,
      footers: hourFooters
    },
    {
      id: 'dates',
      title: <FormattedMessage id="projectList.date" />,
      columns: dateColumns,
      footers: dateFooters
    },
    Boolean(permissionsMap['urn:replicon:project-action:view-cost-data']) && {
      id: 'cost',
      title: <FormattedMessage id="projectList.cost" />,
      columns: costColumns,
      footers: costFooters
    },
    groupColumns &&
      groupColumns.length > 0 && {
        id: 'groups',
        title: <FormattedMessage id="projectList.groups" />,
        columns: groupColumns,
        footers: groupFooters
      },
    Boolean(
      permissionsMap['urn:replicon:project-action:view-billing-data']
    ) && {
      id: 'revenue',
      title: <FormattedMessage id="projectList.revenue" />,
      columns: billingColumns,
      footers: billingFooters
    },
    tagsColumnGroup
  ].filter(x => x);
};

export const enabledCostColumnsAndFooters = ({
  isExpenseProductEnabled,
  permissionsMap,
  featureFlags,
  baseCurrency,
  projectListPageType,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const canViewCostData = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-data']
  );

  const isPortfolioProjectListPage =
    projectListPageType === PROJECT_LIST_PAGE_TYPE.PORTFOLIO;

  const {
    isPsaPpmPortfolioListActualsColumnsEnabled,
    isPsaPpmEstimatedCostAtCompletionEnabled
  } = featureFlags;

  if (!canViewCostData) {
    return { costColumns: [], costFooters: [] };
  }
  const canViewProjectEstimates = Boolean(
    permissionsMap['urn:replicon:project-action:view-project-estimates']
  );

  const showColumnsInBaseCurrency =
    (isPsaPpmPortfolioListActualsColumnsEnabled &&
      !isPortfolioProjectListPage) ||
    !isPsaPpmPortfolioListActualsColumnsEnabled;

  const costColumnsAndFooters = {
    costColumns: [
      showColumnsInBaseCurrency &&
        canViewProjectEstimates &&
        columns.budgetedCost,
      isPsaPpmPortfolioListActualsColumnsEnabled &&
      isPortfolioProjectListPage &&
      canViewProjectEstimates
        ? columns.budgetCostAmountInPortfolioCurrency
        : undefined,
      canViewProjectEstimates && columns.budgetedCostInProjectCurrency,
      isExpenseProductEnabled && columns.actualExpenseCost,
      isExpenseProductEnabled && columns.actualResourceCost,
      showColumnsInBaseCurrency && {
        ...columns.actualCost,
        value: <FormattedMessage id="projectList.actualCost" />
      },
      isPsaPpmPortfolioListActualsColumnsEnabled && isPortfolioProjectListPage
        ? columns.actualCostAmountInPortfolioCurrency
        : undefined,
      {
        ...columns.actualCostInProjectCurrency,
        value: <FormattedMessage id="projectList.actualCost" />
      },
      ...(isPsaPpmEstimatedCostAtCompletionEnabled &&
      isRolledUpTaskEstimateCalculationMethodEnabled
        ? [
            showColumnsInBaseCurrency && columns.estimatedCostAtCompletion,
            isPortfolioProjectListPage &&
              columns.estimatedCostAtCompletionInPortfolioCurrency,
            columns.estimatedCostAtCompletionInProjectCurrency,
            canViewProjectEstimates && columns.budgetCostUsedPercentage,
            showColumnsInBaseCurrency &&
              canViewProjectEstimates &&
              columns.estimatedBudgetCostRemaining,
            isPortfolioProjectListPage &&
              canViewProjectEstimates &&
              columns.estimatedBudgetCostRemainingInPortfolioCurrency,
            canViewProjectEstimates &&
              columns.estimatedBudgetCostRemainingInProjectCurrency
          ].filter(x => x)
        : [])
    ].filter(x => x),
    costFooters: [
      showColumnsInBaseCurrency &&
        canViewProjectEstimates &&
        footers.totalBudgetedCost,
      isPsaPpmPortfolioListActualsColumnsEnabled &&
      isPortfolioProjectListPage &&
      canViewProjectEstimates
        ? footers.totalBudgetCostAmountInPortfolioCurrency
        : undefined,
      canViewProjectEstimates &&
        emptyFooter(columns.budgetedCostInProjectCurrency.field),
      isExpenseProductEnabled && footers.totalActualExpenseCost,
      isExpenseProductEnabled && footers.totalActualResourceCost,
      showColumnsInBaseCurrency && footers.totalActualCost,
      isPsaPpmPortfolioListActualsColumnsEnabled && isPortfolioProjectListPage
        ? footers.totalActualCostAmountInPortfolioCurrency
        : undefined,
      emptyFooter(columns.actualCostInProjectCurrency.field),
      ...(isPsaPpmEstimatedCostAtCompletionEnabled &&
      isRolledUpTaskEstimateCalculationMethodEnabled
        ? [
            showColumnsInBaseCurrency && footers.totalEstimatedCostAtCompletion,
            isPortfolioProjectListPage &&
              footers.totalEstimatedCostAtCompletionInPortfolioCurrency,
            emptyFooter(
              columns.estimatedCostAtCompletionInProjectCurrency.field
            ),
            canViewProjectEstimates && footers.totalBudgetCostUsedPercentage,
            showColumnsInBaseCurrency &&
              canViewProjectEstimates &&
              footers.totalEstimatedBudgetCostRemaining,
            canViewProjectEstimates &&
              isPortfolioProjectListPage &&
              footers.totalEstimatedBudgetCostRemainingInPortfolioCurrency,
            canViewProjectEstimates &&
              emptyFooter(
                columns.estimatedBudgetCostRemainingInProjectCurrency.field
              )
          ]
        : [])
    ].filter(x => x)
  };

  return {
    ...costColumnsAndFooters,
    costColumns: costColumnsAndFooters.costColumns.map(col =>
      col.getSecondaryValue
        ? { ...col, secondaryValue: col.getSecondaryValue(baseCurrency) }
        : col
    )
  };
};

export const enabledHourColumnsAndFooters = (
  permissionsMap,
  isRolledUpTaskEstimateCalculationMethodEnabled
) => {
  const canViewProjectEstimates = Boolean(
    permissionsMap['urn:replicon:project-action:view-project-estimates']
  );

  return {
    hourColumns: [
      ...(canViewProjectEstimates ? [columns.budgetHours] : []),
      columns.actualHours,
      columns.budgetHoursRemaining,
      columns.rolledUpEstimatedRemainingHours,
      ...(canViewProjectEstimates ? [columns.estimatedHoursAtCompletion] : []),
      columns.budgetHoursUsed,
      ...(isRolledUpTaskEstimateCalculationMethodEnabled
        ? [columns.scopeHoursCompleted, columns.estimatedBudgetHoursRemaining]
        : [])
    ],
    hourFooters: [
      ...(canViewProjectEstimates ? [footers.totalBudgetHours] : []),
      footers.totalActualHours,
      footers.totalBudgetHoursRemaining,
      footers.totalRolledUpEstimatedRemainingHours,
      ...(canViewProjectEstimates
        ? [footers.totalEstimatedHoursAtCompletion]
        : []),
      footers.totalBudgetHoursUsed,
      ...(isRolledUpTaskEstimateCalculationMethodEnabled
        ? [
            footers.totalScopeHoursCompleted,
            footers.totalEstimatedBudgetHoursRemaining
          ]
        : [])
    ]
  };
};

export const enabledDateColumnsAndFooters = () => ({
  dateColumns: [
    columns.startDate,
    columns.endDate,
    columns.finalTaskEndDate,
    columns.initialTaskStartDate,
    columns.estimatedTaskCompletionDate
  ],
  dateFooters: [
    emptyFooter(columns.startDate.field),
    emptyFooter(columns.endDate.field),
    emptyFooter(columns.finalTaskEndDate.field),
    emptyFooter(columns.initialTaskStartDate.field),
    emptyFooter(columns.estimatedTaskCompletionDate.field)
  ]
});

export const enabledBillingColumnsAndFooters = (
  permissionsMap,
  baseCurrency
) => {
  const canViewBillingData = Boolean(
    permissionsMap['urn:replicon:project-action:view-billing-data']
  );

  if (!canViewBillingData) {
    return {
      billingColumns: [],
      billingFooters: []
    };
  }

  const canViewProjectEstimates = Boolean(
    permissionsMap['urn:replicon:project-action:view-project-estimates']
  );
  const canViewRevenueContracts = Boolean(
    permissionsMap['urn:replicon:project-action:view-revenue-contract']
  );
  const defaultBillingColumns = [
    columns.actualBilling,
    columns.actualBillingInProjectCurrency
  ];
  const defaultBillingFooters = [
    footers.totalActualBilling,
    emptyFooter(columns.actualBillingInProjectCurrency.field)
  ];

  if (canViewRevenueContracts) {
    defaultBillingColumns.push(columns.revenueRecognitionPolicy);
    defaultBillingFooters.push(
      emptyFooter(columns.revenueRecognitionPolicy.field)
    );
  }

  const billingColumnsAndFooters = {
    billingColumns: canViewProjectEstimates
      ? [
          columns.estimatedContract,
          columns.estimatedContractInProjectCurrency,
          ...defaultBillingColumns
        ]
      : defaultBillingColumns,
    billingFooters: canViewProjectEstimates
      ? [
          footers.totalEstimatedContract,
          emptyFooter(columns.estimatedContractInProjectCurrency.field),
          ...defaultBillingFooters
        ]
      : defaultBillingFooters
  };

  return {
    ...billingColumnsAndFooters,
    billingColumns: billingColumnsAndFooters.billingColumns.map(col =>
      col.getSecondaryValue
        ? { ...col, secondaryValue: col.getSecondaryValue(baseCurrency) }
        : col
    )
  };
};

export const enabledGroupColumnsAndFooters = (
  groupSettings,
  permissionsMap
) => {
  const locationSettings = get(groupSettings, 'location', {});
  const divisionSettings = get(groupSettings, 'division', {});
  const departmentSettings = get(groupSettings, 'department', {});
  const employeeTypeSettings = get(groupSettings, 'employeeType', {});
  const serviceCenterSettings = get(groupSettings, 'serviceCenter', {});
  const costCenterSettings = get(groupSettings, 'costCenter', {});

  const canViewDivision = Boolean(
    permissionsMap['urn:replicon:project-action:view-division']
  );

  const canViewServiceCenter = Boolean(
    permissionsMap['urn:replicon:project-action:view-service-center']
  );

  const canViewCostCenter = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-center']
  );

  const canViewLocation = Boolean(
    permissionsMap['urn:replicon:project-action:view-location']
  );

  const canViewDepartment = Boolean(
    permissionsMap['urn:replicon:project-action:view-department']
  );

  const canViewEmployeeType = Boolean(
    permissionsMap['urn:replicon:project-action:view-employee-type']
  );

  const groupColumns = configuredGroupColumns({
    costCenterSettings,
    departmentSettings,
    divisionSettings,
    employeeTypeSettings,
    locationSettings,
    serviceCenterSettings
  });

  const getGroupColumnAndFooter = group => ({
    column: group,
    footer: emptyFooter(group.field)
  });

  const groups = [
    locationSettings.isEnabled &&
      canViewLocation &&
      getGroupColumnAndFooter(groupColumns.location),
    divisionSettings.isEnabled &&
      canViewDivision &&
      getGroupColumnAndFooter(groupColumns.division),
    serviceCenterSettings.isEnabled &&
      canViewServiceCenter &&
      getGroupColumnAndFooter(groupColumns.serviceCenter),
    costCenterSettings.isEnabled &&
      canViewCostCenter &&
      getGroupColumnAndFooter(groupColumns.costCenter),
    departmentSettings.isEnabled &&
      canViewDepartment &&
      getGroupColumnAndFooter(groupColumns.department),
    employeeTypeSettings.isEnabled &&
      canViewEmployeeType &&
      getGroupColumnAndFooter(groupColumns.employeeType)
  ].filter(x => x);

  return {
    groupColumns: groups.map(({ column }) => column),
    groupFooters: groups.map(({ footer }) => footer)
  };
};

export const mapTagFieldsToColumnGroup = tagFieldData => ({
  id: 'tags',
  title: null,
  columns: tagFieldData.reduce(
    (acc, tagField) => [
      ...acc,
      {
        field: tagField.id,
        value: tagField.name
      }
    ],
    []
  ),
  footers: tagFieldData.reduce(
    (acc, tagField) => [...acc, emptyFooter(tagField.id)],
    []
  )
});

export const getDefaultCostColumns = ({
  isExpenseProductEnabled,
  permissionsMap,
  featureFlags,
  baseCurrency,
  projectListPageType,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const { costColumns: enabledCostColumns } = enabledCostColumnsAndFooters({
    isExpenseProductEnabled,
    permissionsMap,
    featureFlags,
    baseCurrency,
    projectListPageType,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  const {
    isPsaPpmEstimatedCostAtCompletionEnabled,
    isPsaPpmPortfolioListActualsColumnsEnabled
  } = featureFlags;

  const portfolioProjectListDefaults = [
    columns.budgetCostAmountInPortfolioCurrency.field,
    columns.actualCostAmountInPortfolioCurrency.field,
    isPsaPpmEstimatedCostAtCompletionEnabled &&
      isRolledUpTaskEstimateCalculationMethodEnabled &&
      columns.estimatedCostAtCompletionInPortfolioCurrency.field,
    isPsaPpmEstimatedCostAtCompletionEnabled &&
      isRolledUpTaskEstimateCalculationMethodEnabled &&
      columns.estimatedBudgetCostRemainingInPortfolioCurrency.field
  ];

  const excludeList = [
    columns.budgetedCostInProjectCurrency.field,
    columns.actualCostInProjectCurrency.field,
    isPsaPpmEstimatedCostAtCompletionEnabled &&
      columns.budgetCostUsedPercentage.field,
    isPsaPpmEstimatedCostAtCompletionEnabled &&
      columns.estimatedCostAtCompletionInProjectCurrency.field,
    isPsaPpmEstimatedCostAtCompletionEnabled &&
      columns.estimatedBudgetCostRemainingInProjectCurrency.field
  ].filter(x => x);

  return isPsaPpmPortfolioListActualsColumnsEnabled &&
    projectListPageType === PROJECT_LIST_PAGE_TYPE.PORTFOLIO
    ? enabledCostColumns.filter(c =>
        portfolioProjectListDefaults.includes(c.field)
      )
    : enabledCostColumns.filter(c => !excludeList.includes(c.field));
};

export const getDefaultHourColumns = ({
  permissionsMap,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => {
  const { hourColumns } = enabledHourColumnsAndFooters(
    permissionsMap,
    isRolledUpTaskEstimateCalculationMethodEnabled
  );

  const excludeList = [
    columns.budgetHoursUsed.field,
    columns.taskHours.field,
    columns.scopeHoursCompleted.field,
    columns.budgetHoursRemaining.field,
    columns.rolledUpEstimatedRemainingHours.field
  ];

  return hourColumns.filter(c => !excludeList.includes(c.field));
};

export const getDefaultBillingColumns = ({ permissionsMap, baseCurrency }) => {
  const {
    billingColumns: enabledBillingColumns
  } = enabledBillingColumnsAndFooters(permissionsMap, baseCurrency);

  const excludeList = [
    columns.actualBillingInProjectCurrency.field,
    columns.estimatedContractInProjectCurrency.field
  ];

  return enabledBillingColumns.filter(c => !excludeList.includes(c.field));
};

export const defaultColumns = (me, options = {}) => {
  const {
    isExpenseProductEnabled,
    permissionsMap,
    featureFlags,
    isRolledUpTaskEstimateCalculationMethodEnabled,
    groupSettings,
    baseCurrency
  } = me;
  const {
    hideClient,
    hideProgram,
    includeGroupsInDefaultColumnNames,
    projectListPageType
  } = options;

  const defaultCostColumns = getDefaultCostColumns({
    isExpenseProductEnabled,
    permissionsMap,
    featureFlags,
    baseCurrency,
    projectListPageType,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  const defaultHourColumns = getDefaultHourColumns({
    permissionsMap,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  const defaultBillingColumns = getDefaultBillingColumns({
    permissionsMap,
    baseCurrency
  });

  const { groupColumns } = enabledGroupColumnsAndFooters(
    groupSettings,
    permissionsMap
  );
  const {
    isPsaPpmPortfolioListBudgetColumnsEnabled,
    isPsaPrpProjectLinkEnabled
  } = featureFlags;

  return [
    ...(isPsaPrpProjectLinkEnabled &&
    projectListPageType === PROJECT_LIST_PAGE_TYPE.PROJECT_LINK
      ? [columns.projectLink]
      : []),
    columns.name,
    columns.workflowStatus,
    ...(hideProgram ||
    (isPsaPpmPortfolioListBudgetColumnsEnabled &&
      projectListPageType === PROJECT_LIST_PAGE_TYPE.PORTFOLIO)
      ? []
      : [columns.program]),
    ...(hideClient ? [] : [columns.client]),
    columns.projectManagerReference,
    ...defaultHourColumns,
    columns.initialTaskStartDate,
    columns.estimatedTaskCompletionDate,
    ...defaultCostColumns,
    ...(includeGroupsInDefaultColumnNames ? groupColumns : []),
    ...defaultBillingColumns
  ];
};

export const columnDefinitions = me => {
  const { groupSettings } = me;
  const locationSettings = get(groupSettings, 'location', {});
  const divisionSettings = get(groupSettings, 'division', {});
  const departmentSettings = get(groupSettings, 'department', {});
  const employeeTypeSettings = get(groupSettings, 'employeeType', {});
  const serviceCenterSettings = get(groupSettings, 'serviceCenter', {});
  const costCenterSettings = get(groupSettings, 'costCenter', {});

  const groupColumns = configuredGroupColumns({
    costCenterSettings,
    departmentSettings,
    divisionSettings,
    employeeTypeSettings,
    locationSettings,
    serviceCenterSettings
  });

  return { ...columns, ...groupColumns };
};

export const mobileColumnNames = [
  columns.name,
  columns.workflowStatus,
  columns.projectManagerReference
].map(c => c.field);
export const defaultColumnNames = (me, options) =>
  defaultColumns(me, options).map(c => c.field);
export default columnGroups;
