import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { isoStringToObject } from '~/modules/common/dates/convert';
import { extractFirstValidationError } from '~/modules/common/graphql/errors';
import { isNullOrUndefined } from '~/modules/common/util';
import usePutVendor from '../../hooks/usePutVendor';

const STRING_MAX_LENGTH = 255;
const validationMessages = {
  nameRequired: (
    <FormattedMessage id="vendor.validationMessages.nameRequired" />
  ),
  nameExceedsMaxLength: (
    <FormattedMessage id="vendor.validationMessages.nameExceedsMaxLength" />
  )
};

export const validationSchema = object().shape({
  name: string()
    .trim()
    .max(STRING_MAX_LENGTH, validationMessages.nameExceedsMaxLength)
    .required(validationMessages.nameRequired)
});

export const useAddVendor = ({
  onClose,
  vendor,
  refetchQueries,
  isNew = false
}) => {
  const { putVendor } = usePutVendor({ refetchQueries: [] });
  const { formatMessage } = useIntl();
  const history = useHistory();

  const initialState = {
    id: vendor?.id || undefined,
    name: vendor?.name || undefined,
    closedDate: isoStringToObject(vendor?.closedDate) || undefined,
    isActive: isNullOrUndefined(vendor?.isActive) ? true : vendor?.isActive
  };

  const onSubmit = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);

      const { id, name, closedDate, isActive } = values;

      try {
        const {
          data: {
            putVendor: { slug }
          }
        } = await putVendor({
          id,
          name,
          closedDate: closedDate || undefined,
          isActive
        });

        onClose();
        if (isNew && slug)
          history.push({
            pathname: `/vendors/${slug}`,
            search: '?edit=true'
          });
      } catch (e) {
        const duplicateNameError = extractFirstValidationError(e);

        if (duplicateNameError)
          setFieldError(
            'name',
            formatMessage({
              id: 'vendor.validationMessages.duplicateVendor'
            })
          );
      } finally {
        setSubmitting(false);
      }
    },
    [formatMessage, history, isNew, onClose, putVendor]
  );

  return {
    initialState,
    onSubmit,
    validationSchema
  };
};

export default useAddVendor;
