import React from 'react';
import { TenantType } from '~/types';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { retryLoading } from '~/util';
import AnalyticsIcon from './AnalyticsIcon';

const BusinessIntelligencePage = React.lazy(() =>
  retryLoading(() => import('./BusinessIntelligence'))
);

export const businessIntelligenceRoute = ({ intl, me }) => ({
  path: '/businessIntelligence',
  icon: () => <AnalyticsIcon />,
  hidden: true,
  title: () => intl.formatMessage({ id: 'routes.businessIntelligence' }),
  component: BusinessIntelligencePage,
  hasPermission:
    me.userAccessRoles.includes(USER_ACCESS_ROLE.REPORT_USER) &&
    me.tenantType === TenantType.Trial
});
