import { Dialog, DialogTitle, makeStyles, Tabs, Tab } from '@material-ui/core';
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import get from 'lodash.get';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { AddProjectRequestDialogContent } from '~/modules/project-request/ProjectRequestPage/components/AddProjectRequestDialog/AddProjectRequestDialogContent';
import {
  useTableSettings,
  useSetListSort
} from '~/modules/common/components/ListTable';
import { columns } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks/requestColumns';
import AddExistingProjectRequestForm, {
  ExistingProjectRequestSearchContextProvider
} from '../AddExistingProjectRequestForm';
import SearchFilterToolbar from '../AddExistingProjectRequestForm/SearchFilterToolbar';

const PORTFOLIO_PROJECT_REQUEST_DIALOG_TABLE_KEY =
  'PORTFOLIO_PROJECT_REQUESTS_DIALOG';

const getSort = ({ currentSort }) => {
  if (!currentSort) {
    return null;
  }

  return {
    field: currentSort.field,
    direction: currentSort.direction
  };
};

const getTabLabels = formatMessage => ({
  addProjectRequest: formatMessage({
    id: 'projectRequest.addNewProjectRequest'
  }),
  existingRequests: formatMessage({
    id: 'projectRequest.existingRequests'
  })
});

const addDialogRole = {
  role: 'presentation'
};

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    }
  },
  paperMD: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.md,
      height: theme.spacing(70)
    }
  },
  title: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  titleText: {
    padding: theme.spacing(1, 2),
    ...theme.typography.h6
  }
}));

const AddPortfolioProjectRequestDialog = ({ portfolio, open, onClose }) => {
  const { id: portfolioId, name: portfolioName } = portfolio;
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const tabLabels = getTabLabels(formatMessage);

  const handleClose = useCallback(() => {
    onClose();
    setTab(0);
  }, [onClose]);

  const [tab, setTab] = useState(0);
  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);
  const dialogClasses = useMemo(
    () => ({ paper: tab === 1 ? classes.paperMD : classes.paper }),
    [classes.paper, classes.paperMD, tab]
  );

  const defaultColumns = useMemo(() => {
    const allColumns = columns();
    const portfolioProjectRequestColumnsNames = [
      allColumns.name.field,
      allColumns.description.field,
      allColumns.portfolio.field,
      allColumns.status.field,
      allColumns.effectiveTotal.field
    ];

    return portfolioProjectRequestColumnsNames;
  }, []);

  const {
    data: tableSettingsData,
    loading: tableSettingsLoading
  } = useTableSettings({
    tableKey: PORTFOLIO_PROJECT_REQUEST_DIALOG_TABLE_KEY,
    defaultColumns
  });

  const tableColumns = get(tableSettingsData, 'columns');
  const currentSort = get(tableSettingsData, 'sort');

  const { onSortChange, loading: sortLoading } = useSetListSort({
    tableKey: PORTFOLIO_PROJECT_REQUEST_DIALOG_TABLE_KEY,
    currentColumns: tableColumns,
    currentSort
  });

  const sortCriteria = useMemo(() => getSort({ currentSort }), [currentSort]);

  if (tableSettingsLoading) return null;

  return (
    <ExistingProjectRequestSearchContextProvider>
      <Dialog
        classes={dialogClasses}
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        TransitionProps={addDialogRole}
      >
        <DialogTitle className={classes.title}>
          <Tabs value={tab} indicatorColor="primary" onChange={handleTabChange}>
            <Tab label={tabLabels.addProjectRequest} />
            <Tab label={tabLabels.existingRequests} />
          </Tabs>
          {tab === 1 && (
            <SearchFilterToolbar
              enabledColumns={tableColumns}
              defaultColumns={defaultColumns}
            />
          )}
        </DialogTitle>
        {tab === 0 ? (
          <AddProjectRequestDialogContent
            onClose={onClose}
            assignedPortfolioId={portfolioId}
          />
        ) : (
          <AddExistingProjectRequestForm
            excludePortfolio={portfolioId}
            onCancel={handleClose}
            enabledColumns={tableColumns}
            isSortLoading={sortLoading}
            sortCriteria={sortCriteria}
            onSortChange={onSortChange}
            portfolioId={portfolioId}
            portfolioName={portfolioName}
          />
        )}
      </Dialog>
    </ExistingProjectRequestSearchContextProvider>
  );
};

AddPortfolioProjectRequestDialog.propTypes = {
  portfolio: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddPortfolioProjectRequestDialog;
