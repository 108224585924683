import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ProgressItemMetric from '~/modules/common/charts/dashboard/ProgressChart/ProgressItemMetric';
import ProgressItemMetricDetail from './ProgressItemMetricDetail';
import { getActualsFieldProps } from './hooks';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  }
}));

export const ProgressChartForSumAllTasks = ({
  projectsBudgetHours,
  projectsActualHours,
  projectsBudgetCost,
  projectsActualCost,
  portfolioCurrency,
  showCostContent
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    percentage: actualCostPercentage,
    value: actualCost
  } = getActualsFieldProps({
    actualAmount: projectsActualCost?.amount,
    budgetAmount: projectsBudgetCost?.amount
  });

  const {
    percentage: actualHoursPercentage,
    value: actualHours
  } = getActualsFieldProps({
    actualAmount: projectsActualHours,
    budgetAmount: projectsBudgetHours
  });

  return (
    <Grid container className={classes.container} spacing={0}>
      {showCostContent ? (
        <Grid item className={classes.item} xs={6}>
          <ProgressItemMetric
            title="portfolio.budgetBurnedCard.cost"
            value={actualCostPercentage}
          />
          <ProgressItemMetricDetail
            message="portfolio.budgetBurnedCard.budgetCostMissingAlert"
            id="portfolio.budgetBurnedCard.budgetCostMissing"
            denominator={projectsBudgetCost?.amount}
            numerator={actualCost}
            fixedDecimalScale
            tooltipPrecision={2}
            prefix={portfolioCurrency.displayText}
            numeratorLabel="portfolio.budgetBurnedCard.actualCost"
            denominatorLabel="portfolio.budgetBurnedCard.totalProjectBudget"
          />
        </Grid>
      ) : null}
      <Grid item className={classes.item} xs={6}>
        <ProgressItemMetric
          title="portfolio.budgetBurnedCard.hours"
          value={actualHoursPercentage}
        />
        <ProgressItemMetricDetail
          message="portfolio.budgetBurnedCard.budgetHoursMissingAlert"
          id="portfolio.budgetBurnedCard.budgetHoursMissing"
          denominator={projectsBudgetHours}
          numerator={actualHours}
          fixedDecimalScale={false}
          tooltipPrecision={4}
          suffix={intl.formatMessage({
            id: 'dashboardProgressChart.hrs'
          })}
          numeratorLabel="portfolio.budgetBurnedCard.actualHours"
          denominatorLabel="portfolio.budgetBurnedCard.totalProjectBudget"
        />
      </Grid>
    </Grid>
  );
};

ProgressChartForSumAllTasks.propTypes = {
  projectsBudgetHours: PropTypes.number,
  projectsActualHours: PropTypes.number,
  projectsBudgetCost: PropTypes.object,
  projectsActualCost: PropTypes.object,
  portfolioCurrency: PropTypes.object,
  showCostContent: PropTypes.bool
};

export default ProgressChartForSumAllTasks;
