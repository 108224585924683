import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { clientPageHeaderFragment } from './graphql';

export const UPDATE_CLIENT_STATUS_MUTATION = gql`
  mutation UpdateClientStatus($input: UpdateClientStatusInput!) {
    updateClientStatus2(input: $input) {
      status
    }
  }
`;

export const updateCache = clientId => (proxy, { data }) => {
  if (data && data.updateClientStatus2) {
    const {
      updateClientStatus2: { status }
    } = data;

    const fragmentId = `Client:${clientId}`;

    const client = proxy.readFragment({
      id: fragmentId,
      fragment: clientPageHeaderFragment
    });

    proxy.writeFragment({
      id: fragmentId,
      fragment: clientPageHeaderFragment,
      data: {
        ...client,
        isActive: status
      }
    });
  }
};

export const useUpdateClientStatusMutation = ({ clientId }) =>
  useMutation(UPDATE_CLIENT_STATUS_MUTATION, {
    update: updateCache(clientId)
  });
