import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

import QuickFilterSummaryTabs, {
  MobileQuickFilterSummaryTabs
} from '~/modules/common/components/QuickFilterSummaryTabs';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { usePortfolioProjectsQuickFilterSummary } from './usePortfolioProjectsQuickFilterSummary';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    right: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2)
  }
}));

export const PortfolioProjectsQuickFilterSummary = ({
  searchCriteria,
  summaryFilterState
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const {
    allItems,
    executionPhases,
    alternativeItems,
    isLoading
  } = usePortfolioProjectsQuickFilterSummary({
    searchCriteria,
    summaryFilterState
  });

  return isMobile ? (
    <MobileQuickFilterSummaryTabs
      allItems={allItems}
      alternativeItems={alternativeItems}
      summaryItems={executionPhases}
      summaryFilterState={summaryFilterState}
      isValueLoading={isLoading}
    />
  ) : (
    <div className={classes.root}>
      <QuickFilterSummaryTabs
        allItems={allItems}
        summaryItems={executionPhases}
        summaryFilterState={summaryFilterState}
        isValueLoading={isLoading}
        alternativeItems={alternativeItems}
      />
    </div>
  );
};

PortfolioProjectsQuickFilterSummary.propTypes = {
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object
};

export default PortfolioProjectsQuickFilterSummary;
