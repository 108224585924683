import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useHasPermission } from '~/modules/common/permissions';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { isNullOrUndefined } from '~/modules/common/util';
import { CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE } from '../enum';
import getProjectAllocationHours from './getProjectAllocationHours';
import getTaskAllocationHoursForResourceUser from './getTaskAllocationHoursForResourceUser';

export const useOnChangeHandler = ({
  projectId,
  taskResourceUserAllocation,
  initialEstimatedHours,
  setIsNewUserInfoLoading
}) => {
  const apolloClient = useApolloClient();
  const { values, setValues, setFieldValue } = useFormikContext();

  const isResourceManager = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });

  const onResourceUserChange = useCallback(
    async newUser => {
      let availableHours = null;

      setFieldValue('newResourceUser', newUser);
      if (!newUser || !taskResourceUserAllocation) return;
      setIsNewUserInfoLoading(true);
      const startDate = mapIsoStringtoUtcObject(
        taskResourceUserAllocation.startDate
      );
      const endDate = mapIsoStringtoUtcObject(
        taskResourceUserAllocation.endDate
      );

      if (!isResourceManager) {
        const [
          projectAllocationHours,
          otherTaskAllocationHours
        ] = await Promise.all([
          getProjectAllocationHours({
            apolloClient,
            projectId,
            userId: newUser.id,
            startDate,
            endDate
          }),
          getTaskAllocationHoursForResourceUser({
            apolloClient,
            projectId,
            userId: newUser.id,
            startDate,
            endDate,
            taskAllocationId: taskResourceUserAllocation.id
          })
        ]);

        if (!isNullOrUndefined(projectAllocationHours))
          availableHours = Math.max(
            projectAllocationHours - otherTaskAllocationHours,
            0
          );
      }
      setIsNewUserInfoLoading(false);
      setValues({
        ...values,
        allocationMode: !availableHours
          ? CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.REMOVE_ALLOCATION
          : values.allocationMode,
        newResourceUser: newUser,
        availableHours
      });
    },
    [
      setFieldValue,
      taskResourceUserAllocation,
      setIsNewUserInfoLoading,
      isResourceManager,
      setValues,
      values,
      apolloClient,
      projectId
    ]
  );

  return {
    onResourceUserChange
  };
};

export default useOnChangeHandler;
