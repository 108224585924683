import { makeStyles } from '@material-ui/core';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  accordion: {
    width: '100%',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    '&.Mui-expanded': {
      margin: 0
    },
    '&:before': {
      content: 'none'
    }
  },
  summary: {
    flexDirection: 'row-reverse',
    padding: 0,
    '&.Mui-expanded': {
      margin: 0,
      minHeight: theme.spacing(6)
    }
  },
  summaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  },
  expandIcon: {
    marginRight: theme.spacing(-1),
    marginLeft: 0
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  },
  title: {
    fontWeight: 500
  }
}));

const useAccordionStyles = () => {
  const classes = useStyles();

  const { accordionClasses, summaryClasses } = useMemo(
    () => ({
      accordionClasses: {
        root: classes.accordion
      },
      summaryClasses: {
        root: classes.summary,
        expandIcon: classes.expandIcon,
        content: classes.summaryContent
      }
    }),
    [classes]
  );

  return {
    accordionClasses,
    summaryClasses,
    titleClass: classes.title,
    summaryContainerClass: classes.summaryContainer
  };
};

export default useAccordionStyles;
