import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddFabButton } from '~/modules/common/components';
import { useTableSettings } from '~/modules/common/components/ListTable';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useRequiredCustomFieldDefinitions } from '~/modules/customFields/hooks/useRequiredCustomFieldDefinitions';
import { useEnabledProjectColumns } from '~/modules/common/hooks/project';
import { useGetEnabledTagFieldsQuery } from '~/modules/tags/graphql';
import { usePortfolioProjectsPermissions } from '~/modules/common/hooks/portfolio';
import { ProjectManagementType } from '~/types';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { usePortfolioPageEditContext } from '../../../PortfolioPageEditContext';
import PortfolioProjectsContextProvider from '../PortfolioProjectsContextProvider';
import defaultColumns, {
  mobileColumnNames
} from './AddExistingProjectForm/ProjectList/defaultColumns';
import AddPortfolioProjectDialog from './AddPortfolioProjectDialog';
import { PORTFOLIO_PROJECTS_DIALOG_TABLE_KEY } from './portfolioProjectsDialogTableSettings';

const AddPortfolioProject = ({ editable }) => {
  const isMobile = useIsBreakpointDown('sm');
  const intl = useIntl();
  const {
    portfolio: { id, name, portfolioManager, ancestors }
  } = usePortfolioPageEditContext();
  const { open, closeDialog, openDialog } = useDialogState();
  const { canEditPortfolioProjects } = usePortfolioProjectsPermissions({
    portfolioManager,
    ancestors
  });
  const {
    customFieldDefinitionsLoading,
    customFieldDefinitions
  } = useRequiredCustomFieldDefinitions('Project');

  const {
    data: tableSettings,
    loading: tableSettingsLoading
  } = useTableSettings({
    tableKey: PORTFOLIO_PROJECTS_DIALOG_TABLE_KEY, // TODO: check if need to add enum in endpoint
    defaultColumns
  });

  const enabledColumns = (tableSettings && tableSettings.columns) || [];
  const sort = tableSettings && tableSettings.sort;

  const { data: tagFieldData } = useGetEnabledTagFieldsQuery(enabledColumns);

  const { enabledProjectColumnGroups } = useEnabledProjectColumns({
    tagFieldData
  });

  if (customFieldDefinitionsLoading || tableSettingsLoading) return null;

  return (
    <>
      {editable && canEditPortfolioProjects && (
        <AddFabButton
          label={intl.formatMessage({ id: 'projectAddFab.label' })}
          shortLabel={intl.formatMessage({ id: 'projectAddFab.shortLabel' })}
          dataQeId="addProjectOpenDialog"
          onClick={openDialog}
        />
      )}
      <PortfolioProjectsContextProvider disableQueryParams>
        <AddPortfolioProjectDialog
          portfolioId={id}
          portfolioName={name}
          projectType={ProjectManagementType.Managed}
          open={open}
          onClose={closeDialog}
          customFieldDefinitions={customFieldDefinitions}
          enabledColumnGroups={enabledProjectColumnGroups}
          enabledColumns={isMobile ? mobileColumnNames : enabledColumns}
          sort={sort}
        />
      </PortfolioProjectsContextProvider>
    </>
  );
};

AddPortfolioProject.propTypes = {
  editable: PropTypes.bool.isRequired
};

export default AddPortfolioProject;
