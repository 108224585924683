import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ListItem, ListItemText } from '@material-ui/core';
import Title from './Title';
import Body from './Body';

const useStyles = makeStyles(theme => ({
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1)
  }
}));

export const ViewResourceRequestListItem = React.memo(
  ({
    request,
    onClose,
    onClick,
    qeIdTag,
    button = true,
    showResourceRequestDetailIndicator
  }) => {
    const classes = useStyles();

    const handleClick = useCallback(() => {
      onClose && onClose();
      onClick && onClick({ resourceRequestId: request.id });
    }, [onClick, onClose, request.id]);

    const primaryText = useMemo(
      () => (
        <Title
          request={request}
          showResourceRequestDetailIndicator={
            showResourceRequestDetailIndicator
          }
        />
      ),
      [request, showResourceRequestDetailIndicator]
    );

    const secondaryText = useMemo(() => <Body request={request} />, [request]);

    const secondaryTypographyProps = useMemo(
      () => ({
        component: 'span'
      }),
      []
    );

    return (
      <ListItem
        button={button}
        className={classes.listItem}
        onClick={handleClick}
      >
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          secondaryTypographyProps={secondaryTypographyProps}
          data-qe-id={qeIdTag}
        />
      </ListItem>
    );
  }
);

ViewResourceRequestListItem.propTypes = {
  request: PropTypes.object,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  qeIdTag: PropTypes.string,
  button: PropTypes.bool,
  showResourceRequestDetailIndicator: PropTypes.bool
};

export default ViewResourceRequestListItem;
