import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { makeStyles, Chip, TextField, ListItem } from '@material-ui/core';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { useBuildBillLineItemOptions } from './hooks/useBuildBillLineItemOptions';

const useStyles = makeStyles(theme => ({
  addButton: {
    padding: 0
  },
  columnChip: {
    margin: theme.spacing(0, 0.5, 0.125, 0),
    backgroundColor: theme.palette.grey[400]
  },
  groupByLabel: {
    flexGrow: 0,
    flexShrink: 0,
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
}));

export const useFormStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2, 1, 2),
    backgroundColor: theme.palette.background.paper
  },
  fieldItem: {
    marginBottom: theme.spacing(2)
  }
}));

const renderOption = option => {
  return <ListItem component="div">{option.value}</ListItem>;
};
const getOptionSelected = (option, selected) =>
  option.groupId === selected.groupId;

export const BillLineItemsByDropdown = ({
  billLineItemOptions,
  setFieldValue
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const billLineItemsByText = intl.formatMessage({
    id: 'billingCard.subTitle.billLineItemsBy'
  });
  const lineItemOptions = Object.values(
    useBuildBillLineItemOptions({
      isPsaFPOvertimeBillingEnabled
    })
  );
  const selectedLineItemOptions = billLineItemOptions.map(col =>
    lineItemOptions.find(opt => col === opt.groupId)
  );

  const onDeleteClick = useCallback(
    option => () => {
      const newColumns = billLineItemOptions.filter(
        column => column !== option.groupId
      );

      setFieldValue('billLineItemsBy', newColumns);
    },
    [billLineItemOptions, setFieldValue]
  );

  const renderTags = useCallback(
    options =>
      options.map(option => (
        <Chip
          key={option.groupId}
          size="small"
          label={option.value}
          disabled={option.groupId === BILLING_COLUMN_TYPE.PROJECT}
          className={classes.columnChip}
          onDelete={onDeleteClick(option)}
          data-qe-id={`${option.groupId}_deleteBillingItemGroup`}
        />
      )),
    [classes.columnChip, onDeleteClick]
  );

  const handleColumnOptionChange = useCallback(
    options => {
      const billLineItemsBy = options.map(option => option.groupId);

      if (billLineItemsBy.length > 0) {
        setFieldValue('billLineItemsBy', billLineItemsBy);
      }
    },
    [setFieldValue]
  );

  const renderInput = useCallback(
    params => <TextField {...params} label={billLineItemsByText} />,
    [billLineItemsByText]
  );

  return (
    <SimpleAutocomplete
      options={lineItemOptions}
      size="small"
      fullWidth
      value={selectedLineItemOptions}
      multiple
      renderTags={renderTags}
      onChange={handleColumnOptionChange}
      disableClearable
      optionPropText="groupId"
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      renderInput={renderInput}
      filterSelectedOptions
      dataQeId="BillLineItemsBy"
    />
  );
};

BillLineItemsByDropdown.propTypes = {
  billLineItemOptions: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default BillLineItemsByDropdown;
