import React from 'react';
import { Formik } from 'formik';
import { PropTypes } from 'prop-types';
import { useValidationSchema, useAddRoleOnSubmit } from './hooks';
import AddRoleDialogContent from './AddRoleDialogContent';
import { useInitialState } from './useInitialState';

export const AddRoleDialog = ({
  open,
  onClose,
  onChange,
  initialName = '',
  refetchActiveRoles = false,
  onCancel
}) => {
  const validationSchema = useValidationSchema();

  const initialValues = useInitialState({ initialName });

  const { onSubmit } = useAddRoleOnSubmit({
    onClose,
    onChange,
    refetchActiveRoles
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <AddRoleDialogContent onClose={onClose} open={open} onCancel={onCancel} />
    </Formik>
  );
};

AddRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  initialName: PropTypes.string,
  refetchActiveRoles: PropTypes.bool,
  onCancel: PropTypes.func
};

export default AddRoleDialog;
