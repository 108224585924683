import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { isMobileApp } from '~/util';
import { useIsBreakpointUp, useDialogState } from '~/modules/common/hooks';
import { useSubstituteUserMetadata } from '~/modules/me/useSubstituteUserMetadata';
import { PinIcon } from '~/modules/common/components/Icons';
import { useMeContext } from '~/modules/me';
import MainMenu from '../MainMenu';
import ProfileMenu from '../ProfileMenu';
import ExternalLinksMenu from '../ExternalLinksMenu';
import NotificationsMenu from '../NotificationsMenu';
import SubstituteUsersDialog from '../SubstituteUsers/SubstituteUsersDialog';
import FinancialsMenu from '../FinancialsMenu';
import useStyles from './useStyles';

export const DesktopMenu = ({
  open,
  onClose,
  logo,
  isMenuPinned,
  toggleMenuPinned
}) => {
  const me = useMeContext();
  const { formatMessage } = useIntl();
  const mobileApp = isMobileApp();
  const isSubstituteUser = useSubstituteUserMetadata();
  const isDocked = useIsBreakpointUp('md');
  const classes = useStyles({ isSubstituteUser, isDocked });
  const drawerVariant = useMemo(() => (isDocked ? 'permanent' : 'temporary'), [
    isDocked
  ]);
  const drawerClasses = useMemo(
    () => ({
      paper: classNames(
        classes.drawerPaper,
        isDocked && classes.drawerPaperPermanent,
        isDocked && isMenuPinned && classes.drawerPaperPinned
      )
    }),
    [
      classes.drawerPaper,
      isDocked,
      isMenuPinned,
      classes.drawerPaperPermanent,
      classes.drawerPaperPinned
    ]
  );

  const {
    open: substituteUsersDialogOpen,
    openDialog: openSubstituteUsersDialog,
    closeDialog: closeSubstituteUsersDialog
  } = useDialogState(false);

  const drawerRef = useRef(null);
  const menuRef = useRef(null);

  const drawerHeight = drawerRef.current ? drawerRef.current.clientHeight : 0;
  const menuContainerHeight = menuRef.current
    ? menuRef.current.clientHeight
    : 0;

  return (
    <Drawer
      variant={drawerVariant}
      classes={drawerClasses}
      open={open || isDocked}
      onClose={onClose}
      ref={drawerRef}
    >
      <div
        id="desktop-menu-logo"
        className={classes.logoContainer}
        role="banner"
      >
        {logo}
        {isDocked && (
          <IconButton
            aria-label={formatMessage({ id: 'desktopMenu.pin' })}
            onClick={toggleMenuPinned}
          >
            <PinIcon color={isMenuPinned ? 'primary' : 'inherit'} />
          </IconButton>
        )}
      </div>
      <Divider className={classes.divider} light />
      <nav
        id="desktop-menu-container"
        className={classes.menuContainer}
        ref={menuRef}
      >
        <MainMenu open={open} />
        <FinancialsMenu />
        <ExternalLinksMenu />
        {!mobileApp && !isDocked && <NotificationsMenu />}
        {!isDocked && (
          <ProfileMenu
            me={me}
            drawerHeight={drawerHeight}
            menuContainerHeight={menuContainerHeight}
          />
        )}
      </nav>
      {isDocked && (
        <div
          id="desktop-menu-bottom"
          className={classes.profileContainer}
          role="contentinfo"
        >
          <NotificationsMenu />
          <ProfileMenu
            me={me}
            openSubstituteUsersDialog={openSubstituteUsersDialog}
          />
        </div>
      )}
      {substituteUsersDialogOpen && (
        <SubstituteUsersDialog
          closeDialog={closeSubstituteUsersDialog}
          open={substituteUsersDialogOpen}
        />
      )}
    </Drawer>
  );
};

DesktopMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  logo: PropTypes.node,
  isMenuPinned: PropTypes.bool.isRequired,
  toggleMenuPinned: PropTypes.func.isRequired
};

export default DesktopMenu;
