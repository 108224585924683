import React from 'react';
import PropTypes from 'prop-types';
import ResourceRequestChartBanner from './ResourceRequestChartBanner';
import ResourceRequestSubmitAllButton from './ResourceRequestSubmitAllButton';

export const ResourceRequestSubmitAllChartBanner = ({ resourceRequests }) => {
  const resourceRequestsFromAIIds = resourceRequests
    .filter(resourceRequest => resourceRequest.isDelaDraftResourceRequest)
    .map(resourceRequest => resourceRequest.id);

  return (
    <>
      {resourceRequestsFromAIIds.length > 0 && (
        <ResourceRequestChartBanner messageId="resourceRequestChartBanner.submitRequests">
          <ResourceRequestSubmitAllButton
            resourceRequestIds={resourceRequestsFromAIIds}
          />
        </ResourceRequestChartBanner>
      )}
    </>
  );
};

ResourceRequestSubmitAllChartBanner.propTypes = {
  resourceRequests: PropTypes.array.isRequired
};
export default ResourceRequestSubmitAllChartBanner;
