import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { supportedPolicies } from './supported-policy';

export const isNotificationPolicySupported = ({ eventType, channel }) => {
  const policy = supportedPolicies[eventType];

  if (!policy) return false;
  switch (channel) {
    case 'web':
      return policy.config.isWebNotificationSupported;
    case 'email':
      return policy.config.isEmailNotificationSupported;
    case 'mobilePush':
      return policy.config.isPushNotificationSupported;
    default:
      return false;
  }
};

export const getNotificationAnnotationMessageId = (
  eventType,
  userAccessRoles
) => {
  if (eventType !== 'resource-allocation.modification.accepted') return null;

  const isResourceManager = userAccessRoles.includes(
    USER_ACCESS_ROLE.RESOURCE_MANAGER
  );
  const isProjectResource = userAccessRoles.includes(
    USER_ACCESS_ROLE.PROJECT_RESOURCE
  );

  if (isResourceManager && isProjectResource) return '';
  if (isProjectResource) return USER_ACCESS_ROLE.PROJECT_RESOURCE;
  if (isResourceManager) return USER_ACCESS_ROLE.RESOURCE_MANAGER;

  return '';
};
