import { useState, useMemo, useCallback } from 'react';
import { useAssignProjectRequestsToPortfolio } from './useAssignProjectRequestsToPortfolio';

export const useAddExistingProjectRequestsFormProps = ({
  portfolioId,
  openDialog,
  onCancel,
  openTooManyRequestDialog,
  maxNumberOfRequestsAllowed
}) => {
  const [selectedProjectRequests, setSelectedProjectRequests] = useState({});
  const [error, setError] = useState(null);

  const selectedProjectRequestIds = Object.entries(selectedProjectRequests)
    .filter(([_, { isSelected }]) => isSelected)
    .map(([id]) => id);

  const selectedCount = selectedProjectRequestIds.length;

  const selectedCountValue = useMemo(
    () => ({
      value: selectedCount === 0 ? '' : selectedCount
    }),
    [selectedCount]
  );

  const {
    submitting,
    assignProjectRequestsToPortfolio
  } = useAssignProjectRequestsToPortfolio({
    portfolioId,
    projectRequestIds: selectedProjectRequestIds,
    onComplete: onCancel,
    onError: setError
  });

  const selectedProjectRequestsWithPortfolios = useMemo(
    () =>
      Object.values(selectedProjectRequests).filter(
        x => x.isSelected && x.hasPortfolio
      ),
    [selectedProjectRequests]
  );

  const onRowClick = useCallback(
    ({ id, name, portfolio }) => {
      if (submitting) return;

      setSelectedProjectRequests({
        ...selectedProjectRequests,
        [id]: {
          isSelected: selectedProjectRequests[id]
            ? !selectedProjectRequests[id].isSelected
            : true,
          name,
          portfolio,
          hasPortfolio: Boolean(portfolio)
        }
      });
    },
    [selectedProjectRequests, submitting]
  );

  const handleSubmit = useCallback(() => {
    if (selectedCount > maxNumberOfRequestsAllowed.number)
      openTooManyRequestDialog();
    else if (selectedProjectRequestsWithPortfolios.length > 0) openDialog();
    else assignProjectRequestsToPortfolio();
  }, [
    selectedCount,
    maxNumberOfRequestsAllowed.number,
    openTooManyRequestDialog,
    selectedProjectRequestsWithPortfolios.length,
    openDialog,
    assignProjectRequestsToPortfolio
  ]);

  return {
    selectedProjectRequests,
    selectedCount,
    onRowClick,
    submitting,
    handleSubmit,
    error,
    selectedCountValue,
    assignProjectRequestsToPortfolio,
    selectedProjectRequestsWithPortfolios
  };
};
