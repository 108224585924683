import React from 'react';
import ClientPage from './ClientPage';
import ClientPageBreadcrumb from './ClientPageBreadcrumb';

export const clientRoute = ({ intl, me }) => ({
  path: '/clients/:slug',
  icon: () => null,
  hidden: true,
  component: ClientPage,
  title: () => <ClientPageBreadcrumb />,
  hasPermission: Boolean(
    me.permissions.find(
      p => p.permissionActionUri === 'urn:replicon:client-action:view-client'
    )
  )
});
