import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import {
  FullPath,
  GoBackButton,
  User,
  ToggleEditButton,
  RemoveEntityConfirmationDialog
} from '~/modules/common/components';
import { PageHeaderLoading } from '~/modules/common/components/DetailsPage';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { usePortfolioObjectPermissions } from '~/modules/common/hooks/portfolio';
import { usePortfolioPageEditContext } from '../../PortfolioPageEditContext';
import PortfolioStatusChipMenu from './PortfolioStatusChipMenu';
import { usePortfolioActions } from './hooks/usePortfolioActions';
import DeactivatePortfolioConfirmationDialog from './DeactivatePortfolioConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0
  },
  back: { width: '1.5rem', height: '1.5rem' },
  statusContainer: {
    paddingLeft: theme.spacing(2)
  },
  name: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  spacing: {
    marginLeft: theme.spacing(2)
  },
  deleteDialogInfo: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line'
  }
}));
const deleteDialogResourceKeys = {
  header: 'portfolio.deleteDialog.removePortfolio',
  actionButton: 'portfolio.deleteDialog.removePortfolio'
};

const PortfolioHeader = () => {
  const classes = useStyles();
  const goBackButtonClasses = useMemo(() => ({ back: classes.back }), [
    classes.back
  ]);

  const {
    edit,
    toggleEdit,
    loading,
    error,
    portfolio
  } = usePortfolioPageEditContext();
  const { id, canBeDeleted } = portfolio || {};
  const {
    onRemovePortfolioMenuItemClick,
    onMenuClick,
    anchorEl,
    isMenuOpen,
    onMenuClose,
    showRemoveDialog,
    closeRemoveDialog,
    onDeleteConfirm,
    isRemoving
  } = usePortfolioActions({
    id,
    canDeletePortfolio: canBeDeleted
  });
  const { canEditPortfolio } = usePortfolioObjectPermissions(
    portfolio.permittedActionUris
  );

  if (error) return <RedirectToNotFound />;

  return loading ? (
    <PageHeaderLoading />
  ) : (
    <>
      <StickyHeader>
        <GoBackButton
          classes={goBackButtonClasses}
          to="/portfolios"
          contextual
        />
        <Typography
          className={classes.name}
          variant="h6"
          data-qe-id="PortfolioHeaderTitle"
        >
          {Boolean(portfolio.ancestors.length) && (
            <FullPath
              fullPath={portfolio.ancestors.map(
                ancestor => ancestor.displayText
              )}
            />
          )}
          {portfolio.name}
        </Typography>
        {canEditPortfolio ? (
          <>
            <ToggleEditButton edit={edit} onClick={toggleEdit} />
            <IconButton onClick={onMenuClick} data-qe-id="Portfolio_MoreButton">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="portfolio-actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={isMenuOpen}
              onClose={onMenuClose}
            >
              <MenuItem onClick={onRemovePortfolioMenuItemClick}>
                <Typography color="secondary" variant="body2">
                  <FormattedMessage id="portfolio.removePortfolio" />
                </Typography>
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </StickyHeader>
      <Paper className={classes.root} square elevation={0}>
        <Grid
          spacing={0}
          className={classes.statusContainer}
          container
          alignItems="center"
        >
          <Grid item data-qe-id="PortfolioStatus_MenuButton">
            <PortfolioStatusChipMenu
              id={portfolio.id}
              editable={edit && canEditPortfolio}
              status={portfolio.effectiveStatus}
              parentStatus={portfolio.parent?.effectiveStatus}
            />
          </Grid>
          {portfolio.portfolioManager && (
            <Grid className={classes.spacing} item>
              <User user={portfolio.portfolioManager} />
            </Grid>
          )}
        </Grid>
      </Paper>
      {canBeDeleted ? (
        <RemoveEntityConfirmationDialog
          resourceKeys={deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onDeleteConfirm}
          isRemoving={isRemoving}
        >
          <Typography variant="body2" className={classes.deleteDialogInfo}>
            <FormattedMessage id="portfolio.deleteDialog.deleteDescription" />
          </Typography>
        </RemoveEntityConfirmationDialog>
      ) : (
        <DeactivatePortfolioConfirmationDialog
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onDeleteConfirm}
          isRemoving={isRemoving}
        />
      )}
    </>
  );
};

PortfolioHeader.propTypes = {};

export default PortfolioHeader;
