import React from 'react';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { retryLoading } from '~/util';
import { ProgramIcon } from '~/modules/common/components/Icons';

const ProgramsPage = React.lazy(() =>
  retryLoading(() => import('./components/ProgramsPage'))
);

const programsRoute = ({ intl, me }) => ({
  path: '/programs',
  icon: () => <ProgramIcon />,
  isSideBarRoute: true,
  title: () => intl.formatMessage({ id: 'routes.programs' }),
  component: ProgramsPage,
  hasPermission:
    Boolean(
      me.permissions.find(
        p =>
          p.permissionActionUri === 'urn:replicon:program-action:view-program'
      )
    ) &&
    Boolean(
      me.userAccessRoles.find(
        role =>
          role === USER_ACCESS_ROLE.PROJECT_MANAGER ||
          role === USER_ACCESS_ROLE.PROGRAM_MANAGER ||
          role === USER_ACCESS_ROLE.PROJECT_ADMIN
      )
    )
});

export default programsRoute;
