import { DateTime } from 'luxon';

export const minDate = DateTime.utc(2000, 1, 1);
export const maxDate = DateTime.utc(2100, 12, 31);

export const wellKnownScriptParameters = {
  expenseType: 'urn:replicon:script-key:parameter:expense-type',
  markUp: 'urn:replicon:script-key:parameter:markup',
  approvalStatus: 'urn:replicon:script-key:parameter:approval-status'
};
