import { makeStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';
import { TableHeaderPagination } from '~/modules/resourcing/common/components';

const useStyles = makeStyles(theme => ({
  tabelCell: {
    ...theme.typography.subtitle2,
    minWidth: theme.spacing(31.5),
    alignSelf: 'stretch',
    marginLeft: theme.spacing(-1.5)
  },
  root: {
    marginLeft: theme.spacing(3),
    display: 'flex'
  }
}));

const HeaderContentModel1 = ({
  resourceSortField,
  sort,
  onSortChange,
  loadingUsers,
  paginationClasses,
  currentPage,
  setCurrentPage,
  pageSize,
  currentPageRowCount
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableSortLabel
        className={classes.tabelCell}
        field={resourceSortField}
        show
        active={sort?.field === resourceSortField}
        direction={sort && sort.direction && sort.direction.toLowerCase()}
        onClick={onSortChange}
      >
        <FormattedMessage id="resourceRequestChartHeader.resources" />
      </TableSortLabel>
      {!loadingUsers ? (
        <TableHeaderPagination
          classes={paginationClasses}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          isPastPaginationLimit={false}
          currentPageRowCount={currentPageRowCount}
        />
      ) : null}
    </div>
  );
};

HeaderContentModel1.propTypes = {
  resourceSortField: PropTypes.string,
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  loadingUsers: PropTypes.bool,
  paginationClasses: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageSize: PropTypes.number,
  currentPageRowCount: PropTypes.number
};

export default HeaderContentModel1;
