import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const ExpenseType = ({ field, record }) => (
  <Typography variant="body2">{record[field]?.displayText}</Typography>
);

ExpenseType.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default ExpenseType;
