export const LegendTypes = {
  OVER: 'OVER',
  UNDER: 'UNDER'
};

export const ProgressTypes = {
  COST: 'COST',
  HOURS: 'HOURS',
  DATE: 'DATE'
};
