import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import {
  NoValue,
  ReadOnlyField,
  User,
  ReadOnlyContainer,
  PortfolioFullPath
} from '~/modules/common/components';
import {
  DocumentsAccordion,
  ScoresAccordion,
  GatesAccordion,
  PlanningAccordion
} from './components';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    borderTop: `1px solid ${theme.palette.table.border}`,
    padding: theme.spacing(1, 1, 0)
  },
  accordionsContainer: {
    marginTop: theme.spacing(1)
  },
  createdByContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  item: {
    padding: theme.spacing(1)
  },
  displayText: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    opacity: '0.7'
  }
}));

const resourceLabels = {
  name: <FormattedMessage id="projectRequest.name" />,
  description: <FormattedMessage id="projectRequest.description" />,
  requestedBy: <FormattedMessage id="projectRequest.requestedBy" />,
  createdBy: <FormattedMessage id="projectRequest.createdBy" />,
  portfolio: <FormattedMessage id="projectRequest.portfolio" />
};
const NullValue = <NoValue />;

export const ProjectRequestDrawerContentReadOnly = ({
  attachmentsData,
  gatesFormik,
  editableGates,
  setEditableGates,
  resourceAssignmentFormik
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { id, name, description, requestedBy, createdBy, portfolio } = values;
  const { state: { isDocumentsExpanded } = {} } = useLocation();

  const { canViewPlanningData } = useProjectRequestObjectPermissions();

  return (
    <Grid container className={classes.mainContainer} spacing={0}>
      <Grid item xs={12} container spacing={0}>
        <Grid item xs={12} className={classes.item}>
          <ReadOnlyField
            className={classes.displayText}
            label={resourceLabels.name}
            value={name}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <ReadOnlyField
            className={classes.displayText}
            label={resourceLabels.description}
            value={description}
          />
        </Grid>
        <Grid item xs={12} container spacing={0}>
          <Grid item xs={6} className={classes.item}>
            <ReadOnlyField
              className={classes.displayText}
              label={resourceLabels.requestedBy}
              value={requestedBy || NullValue}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Typography className={classes.label}>
              <FormattedMessage id="projectRequest.createdBy" />
            </Typography>
            <User user={createdBy} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <ReadOnlyContainer label={resourceLabels.portfolio}>
            {portfolio ? (
              <PortfolioFullPath portfolio={portfolio} />
            ) : (
              <NoValue />
            )}
          </ReadOnlyContainer>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        className={classes.accordionsContainer}
      >
        <DocumentsAccordion
          attachmentsData={attachmentsData}
          initialIsExpanded={Boolean(isDocumentsExpanded)}
        />
        <ScoresAccordion />
        {canViewPlanningData && (
          <PlanningAccordion
            resourceAssignmentFormik={resourceAssignmentFormik}
          />
        )}
        <GatesAccordion
          formik={gatesFormik}
          projectRequestId={id}
          editableGates={editableGates}
          setEditableGates={setEditableGates}
        />
      </Grid>
    </Grid>
  );
};
ProjectRequestDrawerContentReadOnly.propTypes = {
  attachmentsData: PropTypes.object.isRequired,
  gatesFormik: PropTypes.object,
  editableGates: PropTypes.bool,
  setEditableGates: PropTypes.func,
  resourceAssignmentFormik: PropTypes.object
};

export default ProjectRequestDrawerContentReadOnly;
