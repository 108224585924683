import { DateTime } from 'luxon';
import { get, sumBy } from 'lodash';
import { v4 } from 'uuid';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { ExpenseEntryType } from '~/types';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { maxDate, wellKnownScriptParameters } from './const';

const scale = PERIOD_SCALE_ENUM.MONTHS;

const getKey = date => `${scale}-${mapIsoStringtoUtcObject(date).toISODate()}`;

const isValidEstimatesKey = key => key.startsWith(scale);

const getOrDefaultValue = v => v || 0;

const getDateFromKey = key => {
  const date = key.replace(`${scale}-`, '');

  if (isNaN(Date.parse(date))) {
    return null;
  }

  return date;
};

const flatSeriesData = ({ dataPoints, billableKey, nonBillableKey }) =>
  dataPoints.reduce((retVal, { billableAmount, nonBillableAmount, period }) => {
    const key = getKey(period.start);

    return {
      ...retVal,
      [key]: {
        [billableKey]: getOrDefaultValue(billableAmount),
        [nonBillableKey]: getOrDefaultValue(nonBillableAmount)
      }
    };
  }, {});

const mapSeriesByExpenseType = (series, billableKey, nonBillableKey) =>
  series.reduce(
    (retVal, { expenseCode, dataPoints }) => ({
      ...retVal,
      [expenseCode.id]: flatSeriesData({
        dataPoints,
        billableKey,
        nonBillableKey
      })
    }),
    {}
  );

export const mapActualsSeriesByExpenseType = series =>
  mapSeriesByExpenseType(
    series,
    'actualBillableAmount',
    'actualNonBillableAmount'
  );

export const mapEstimatedSeriesByExpenseType = series =>
  mapSeriesByExpenseType(
    series,
    'estimatedBillableAmount',
    'estimatedNonBillableAmount'
  );

const mapSummaryByExpenseType = (summary, billableKey, nonBillableKey) =>
  summary.reduce(
    (retVal, { expenseCode, billableAmount, nonBillableAmount }) => ({
      ...retVal,
      [expenseCode.id]: {
        totalEstimates: {
          [billableKey]: getOrDefaultValue(billableAmount),
          [nonBillableKey]: getOrDefaultValue(nonBillableAmount)
        }
      }
    }),
    {}
  );

export const mapActualsSummaryByExpenseType = summary =>
  mapSummaryByExpenseType(
    summary,
    'actualBillableAmount',
    'actualNonBillableAmount'
  );

export const mapEstimatedSummaryByExpenseType = summary =>
  mapSummaryByExpenseType(
    summary,
    'estimatedBillableAmount',
    'estimatedNonBillableAmount'
  );

export const mergeNestedObjectValues = (obj1, obj2) =>
  [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])].reduce(
    (acc, key) => {
      if (obj1[key] && obj2[key]) {
        acc[key] = { ...obj1[key], ...obj2[key] };
      } else if (obj1[key]) {
        acc[key] = obj1[key];
      } else if (obj2[key]) {
        acc[key] = obj2[key];
      }

      return acc;
    },
    {}
  );

const isValidPeriod = (date, endDate) =>
  !isNaN(Date.parse(date)) && compareISOStrings(date, endDate.toISODate()) <= 0;

const getEndDate = (date, endDate) => {
  const d = DateTime.fromISO(date)
    .plus({ month: 1 })
    .minus({ day: 1 });

  if (d <= endDate) return d.toISODate();

  return endDate.toISODate();
};

const hasBillableAmountOrNonBillableAmount = (item, field) =>
  get(item, `${field}.estimatedBillableAmount`) ||
  get(item, `${field}.estimatedNonBillableAmount`);

export const mapToEstimatedExpensesEntries = ({
  allowedExpensesAndEstimates,
  projectEndDate,
  currencyId
}) => {
  const endDate = projectEndDate
    ? mapIsoStringtoUtcObject(projectEndDate)
    : maxDate;

  return allowedExpensesAndEstimates.reduce((retVal, curr) => {
    const result = Object.keys(curr)
      .filter(
        key =>
          isValidEstimatesKey(key) &&
          isValidPeriod(getDateFromKey(key), endDate) &&
          hasBillableAmountOrNonBillableAmount(curr, key)
      )
      .map(key => {
        const {
          estimatedBillableAmount: billableAmount,
          estimatedNonBillableAmount: nonBillableAmount
        } = get(curr, key);

        const entry = {
          expenseCodeId: curr.id,
          currencyId,
          incurredDate: getEndDate(getDateFromKey(key), endDate)
        };

        if (
          curr.billableType !== ExpenseEntryType.NonBillable &&
          billableAmount
        ) {
          entry.billableAmount = billableAmount;
        }

        if (
          curr.billableType !== ExpenseEntryType.Billable &&
          nonBillableAmount
        ) {
          entry.nonBillableAmount = nonBillableAmount;
        }

        return entry;
      });

    return [...retVal, ...result];
  }, []);
};

export const mapToExpenseScripts = (
  allowedExpensesAndEstimates,
  approvalStatus
) =>
  allowedExpensesAndEstimates
    .filter(({ billableType }) => billableType !== ExpenseEntryType.NonBillable)
    .map(({ scriptId, expenseType, markUp }) => ({
      scriptId: scriptId || v4(),
      [wellKnownScriptParameters.expenseType]: expenseType.id,
      [wellKnownScriptParameters.markUp]: markUp,
      [wellKnownScriptParameters.approvalStatus]: approvalStatus
    }));

export const getProjectPeriodKeys = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return [];
  }

  const s = startDate.set({ day: 1 });

  return Array(
    endDate
      .set({ day: 1 })
      .plus({ month: 1 })
      .diff(s, 'months').months
  )
    .fill(0)
    .map((_, i) => `${scale}-${s.plus({ month: i }).toISODate()}`);
};

const getSummaryForDataPoint = (
  allowedExpensesAndEstimates,
  field,
  billableKey,
  nonBillableKey
) => {
  const getBillableAmount = o => get(o, `${field}.${billableKey}`, 0);
  const getNonBillableAmount = o => get(o, `${field}.${nonBillableKey}`, 0);

  return sumBy(allowedExpensesAndEstimates, item => {
    if (item.billableType === ExpenseEntryType.Billable) {
      return getBillableAmount(item);
    }

    if (item.billableType === ExpenseEntryType.NonBillable) {
      return getNonBillableAmount(item);
    }

    return getBillableAmount(item) + getNonBillableAmount(item);
  });
};

const getSummary = (allowedExpensesAndEstimates, field, showActuals) => {
  if (!showActuals) {
    return {
      estimated: getSummaryForDataPoint(
        allowedExpensesAndEstimates,
        field,
        'estimatedBillableAmount',
        'estimatedNonBillableAmount'
      )
    };
  }

  return {
    actuals: getSummaryForDataPoint(
      allowedExpensesAndEstimates,
      field,
      'actualBillableAmount',
      'actualNonBillableAmount'
    ),
    estimated: getSummaryForDataPoint(
      allowedExpensesAndEstimates,
      field,
      'estimatedBillableAmount',
      'estimatedNonBillableAmount'
    )
  };
};

export const getTotalForEstimatedExpense = (
  totalEstimatesAndActuals,
  expenseEntryType
) => {
  const billableAmount = getOrDefaultValue(
    get(totalEstimatesAndActuals, 'estimatedBillableAmount', 0)
  );
  const nonBillableAmount = getOrDefaultValue(
    get(totalEstimatesAndActuals, 'estimatedNonBillableAmount', 0)
  );

  if (expenseEntryType === ExpenseEntryType.Billable) return billableAmount;
  if (expenseEntryType === ExpenseEntryType.NonBillable)
    return nonBillableAmount;

  return billableAmount + nonBillableAmount;
};

export const getTotalForActualExpense = (
  totalEstimatesAndActuals,
  expenseEntryType
) => {
  const billableAmount = getOrDefaultValue(
    get(totalEstimatesAndActuals, 'actualBillableAmount', 0)
  );
  const nonBillableAmount = getOrDefaultValue(
    get(totalEstimatesAndActuals, 'actualNonBillableAmount', 0)
  );

  if (expenseEntryType === ExpenseEntryType.Billable) return billableAmount;
  if (expenseEntryType === ExpenseEntryType.NonBillable)
    return nonBillableAmount;

  return billableAmount + nonBillableAmount;
};

export const getTotalsForDataPoints = (
  allowedExpensesAndEstimates,
  chartDates,
  showActuals
) =>
  chartDates.reduce(
    (retVal, { key: field }) => {
      return {
        ...retVal,
        [field]: getSummary(allowedExpensesAndEstimates, field, showActuals)
      };
    },
    {
      totalEstimates: getSummary(
        allowedExpensesAndEstimates,
        'totalEstimates',
        showActuals
      )
    }
  );
