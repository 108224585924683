import React from 'react';
import { FormattedMessage } from 'react-intl';
import ProjectList from './ProjectList';

export const portfolioProjectsTabPath = '/portfolios/:slug/projects';
const route = () => ({
  path: portfolioProjectsTabPath,
  title: () => <FormattedMessage id="portfolio.tabs.projects" />,
  component: ProjectList
});

export default route;
