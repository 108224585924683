/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo } from 'react';
import { DateTime, Duration } from 'luxon';

export const EXPIRY_INTERVAL = 1000; // 1 second
export const SESSION_TOUCH_COOLDOWN_TICKS = 10; // 10 seconds = 10 ticks * EXPIRY_INTERVAL

export const expiryMemo = sessionTimeoutRemaining => () => {
  if (!sessionTimeoutRemaining) return null;

  const { __typename, ...timeoutRemaining } = sessionTimeoutRemaining;

  return DateTime.local().plus(Duration.fromObject(timeoutRemaining));
};

export const warnMemo = (expiry, sessionWarningPeriod) => () =>
  expiry ? expiry.minus(sessionWarningPeriod) : null;

export const expiryInterval = ({
  expiry,
  warn,
  setActivityCount,
  isExpiringSoon,
  setExpiringSoon,
  onLogout,
  touchSession,
  setTouchCooldownTicks,
  state,
  isRedirecting,
  setIsRedirecting
}) => () => {
  const now = DateTime.local();

  if (now.valueOf() >= expiry.valueOf()) {
    if (!isRedirecting) {
      setIsRedirecting(true);
      onLogout();
    }

    return;
  }

  if (
    !isExpiringSoon &&
    state.activityCount > 0 &&
    state.touchCooldownTicks >= SESSION_TOUCH_COOLDOWN_TICKS
  ) {
    touchSession();
    setTouchCooldownTicks(0);
    setActivityCount(0);

    return;
  }

  if (now.valueOf() >= warn.valueOf()) {
    setExpiringSoon(true);
  } else if (isExpiringSoon) {
    setExpiringSoon(false);
  }

  setTouchCooldownTicks(state.touchCooldownTicks + 1);
};

export const registerUserActivityMonitor = ({ state, setActivityCount }) => {
  const userActions = ['mousedown', 'keydown', 'scroll', 'touchstart'];

  function countActivity() {
    setActivityCount(state.activityCount + 1);
  }

  userActions.forEach(action =>
    document.addEventListener(action, countActivity, true)
  );

  return function cleanup() {
    userActions.forEach(action =>
      document.removeEventListener(action, countActivity, true)
    );
  };
};

export const expiryEffect = ({
  expiry,
  warn,
  isExpiringSoon,
  setExpiringSoon,
  onLogout,
  touchSession,
  isRedirecting,
  setIsRedirecting
}) => () => {
  if (expiry && warn) {
    const state = {
      activityCount: 0,
      touchCooldownTicks: 0
    };

    const setActivityCount = value => {
      state.activityCount = value;
    };
    const setTouchCooldownTicks = value => {
      state.touchCooldownTicks = value;
    };

    const interval = setInterval(
      expiryInterval({
        expiry,
        warn,
        setActivityCount,
        isExpiringSoon,
        setExpiringSoon,
        onLogout,
        touchSession,
        setTouchCooldownTicks,
        state,
        isRedirecting,
        setIsRedirecting
      }),
      EXPIRY_INTERVAL
    );

    const cleanupActivityMonitor = registerUserActivityMonitor({
      state,
      setActivityCount
    });

    return () => {
      clearInterval(interval);
      cleanupActivityMonitor();
    };
  }
};

export const withSessionExpiry = BaseComponent => props => {
  const {
    sessionWarningPeriod,
    sessionTimeoutRemaining,
    onLogout,
    touchSession
  } = props;

  const expiry = useMemo(expiryMemo(sessionTimeoutRemaining), [
    sessionTimeoutRemaining
  ]);

  const warn = useMemo(warnMemo(expiry, sessionWarningPeriod), [
    expiry,
    sessionWarningPeriod
  ]);

  const [isExpiringSoon, setExpiringSoon] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(
    expiryEffect({
      expiry,
      warn,
      isExpiringSoon,
      setExpiringSoon,
      onLogout,
      touchSession,
      isRedirecting,
      setIsRedirecting
    }),
    [
      expiry,
      warn,
      isExpiringSoon,
      setExpiringSoon,
      onLogout,
      touchSession,
      isRedirecting,
      setIsRedirecting
    ]
  );

  const onContinue = () => {
    touchSession();
    setExpiringSoon(false);
  };

  return (
    <BaseComponent
      {...props}
      isExpiringSoon={isExpiringSoon}
      expiry={expiry}
      onContinue={onContinue}
    />
  );
};

export default withSessionExpiry;
