import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { VendorPageEditContext } from './VendorPageEditContext';

export const VendorPageEditContextProvider = ({
  vendor = {},
  loading,
  error,
  edit: initialEdit,
  children
}) => {
  const [edit, setEdit] = useState(initialEdit);

  useEffect(() => {
    setEdit(initialEdit);
  }, [initialEdit]);

  const toggleEdit = useCallback(() => setEdit(!edit), [edit, setEdit]);

  const value = useMemo(
    () => ({
      vendor,
      loading,
      error,
      edit,
      toggleEdit
    }),
    [vendor, loading, error, edit, toggleEdit]
  );

  return (
    <VendorPageEditContext.Provider value={value}>
      {children}
    </VendorPageEditContext.Provider>
  );
};

VendorPageEditContextProvider.propTypes = {
  edit: PropTypes.bool,
  children: PropTypes.node,
  vendor: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object
};
