import { useGetProjectTotalsQuery } from '~/modules/projects';

const usePortfolioProjects = ({
  columnArguments,
  portfolioCurrency,
  projectFilter
}) => {
  const { data, loading } = useGetProjectTotalsQuery({
    variables: {
      projectFilter,
      includeTotalBudgetCostAmountInPortfolioCurrency:
        columnArguments.includeBudgetCostAmountInPortfolioCurrency,
      includeTotalActualCostAmountInPortfolioCurrency:
        columnArguments.includeActualCostAmountInPortfolioCurrency,
      includeTotalEstimatedCostAtCompletionInPortfolioCurrency:
        columnArguments.includeEstimatedCostAtCompletionInPortfolioCurrency,
      includeTotalEstimatedBudgetCostRemainingInPortfolioCurrency:
        columnArguments.includeEstimatedBudgetCostRemainingInPortfolioCurrency
    },
    fetchPolicy: 'network-only'
  });

  const totals = data?.projectTotals;

  return {
    totals: {
      ...(totals || {}),
      totalBudgetCostAmountInPortfolioCurrency: {
        amount: totals?.totalBudgetCostAmountInPortfolioCurrency,
        currency: portfolioCurrency
      },
      totalActualCostAmountInPortfolioCurrency: {
        amount: totals?.totalActualCostAmountInPortfolioCurrency,
        currency: portfolioCurrency
      },
      totalEstimatedCostAtCompletionInPortfolioCurrency: {
        amount: totals?.totalEstimatedCostAtCompletionInPortfolioCurrency,
        currency: portfolioCurrency
      },
      totalEstimatedBudgetCostRemainingInPortfolioCurrency: {
        amount: totals?.totalEstimatedBudgetCostRemainingInPortfolioCurrency,
        currency: portfolioCurrency
      }
    },
    totalsLoading: loading
  };
};

export default usePortfolioProjects;
