import { useQuery } from '@apollo/client';
import { useState, useCallback } from 'react';
import get from 'lodash.get';
import { useMeContext } from '~/modules/me';
import { getStartOfTodayUTCString } from '~/modules/common/dates/today';
import { transformTask } from '~/modules/tasks/modecs/taskTransforms';
import { taskDetailsQuery } from './taskDetailsQuery';

const updateQueryOnFetchMoreResult = (prev, fetchMoreResult) => {
  if (!fetchMoreResult) return prev;

  return {
    task: {
      ...prev.task,
      children: [
        ...prev.task.children,
        ...fetchMoreResult.fetchMoreResult.task.children
      ]
    }
  };
};

export const useTaskDetailsQuery = ({
  taskId,
  page = 1,
  pageSize = 10,
  isRolledUpTaskEstimateCalculationMethodEnabled = false
}) => {
  const {
    featureFlags: {
      isPsaPpmEstimatedCostAtCompletionEnabled,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaRmpTaskAllocation1Enabled
    }
  } = useMeContext();

  const includeRolledUpCostSummary =
    isPsaPpmEstimatedCostAtCompletionEnabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled;
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const effectiveTimestamp = getStartOfTodayUTCString();

  const nextFetchPolicy = isPsaRmpTaskAllocation1Enabled
    ? {
        nextFetchPolicy: 'cache-first'
      }
    : {};

  const { data, error, loading, fetchMore, refetch } = useQuery(
    taskDetailsQuery,
    {
      fetchPolicy: 'network-only',
      ...nextFetchPolicy,
      variables: {
        taskId,
        page,
        pageSize,
        effectiveTimestamp,
        effectiveTimestamp2: effectiveTimestamp,
        isRolledUpTaskEstimateCalculationMethodEnabled,
        includeRolledUpCostSummary,
        isPsaPrpPsaPpmMergerEnabled,
        isPsaRmpTaskAllocation1Enabled
      },
      errorPolicy: 'all'
    }
  );

  const handleRefetch = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await refetch();
    } finally {
      setIsLoading(false);
    }
  }, [refetch, isLoading]);

  const task = get(data, 'task');

  const hasMore =
    task && task.children && task.children.length % pageSize === 0;

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          taskId,
          page: task.children.length / pageSize + 1,
          pageSize,
          effectiveTimestamp: getStartOfTodayUTCString(),
          isRolledUpTaskEstimateCalculationMethodEnabled,
          includeRolledUpCostSummary
        },
        updateQuery: updateQueryOnFetchMoreResult
      });
    } finally {
      setLoadingMore(false);
    }
  }, [
    taskId,
    task,
    pageSize,
    fetchMore,
    hasMore,
    isRolledUpTaskEstimateCalculationMethodEnabled,
    includeRolledUpCostSummary
  ]);

  return {
    loading,
    error,
    task: task && transformTask(task),
    hasMore,
    loadMore,
    loadingMore,
    handleRefetch,
    isLoading
  };
};
