import React from 'react';
import PropTypes from 'prop-types';
import { DialogContentText, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  description: {
    whiteSpace: 'pre-line',
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export const ConfirmationContent = ({ selectedBillCount }) => {
  const classes = useStyles();

  return (
    <DialogContentText
      className={classes.description}
      dataQeId="ConfirmationMessageForBulkBill"
    >
      <FormattedMessage
        id="availableToBillList.confirmationMessageForBulkBill"
        values={selectedBillCount}
      />
    </DialogContentText>
  );
};

ConfirmationContent.propTypes = {
  selectedBillCount: PropTypes.object
};

export default ConfirmationContent;
