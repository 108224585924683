import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LiveAnnouncer } from 'react-aria-live';

import { PageLayout } from '~/modules/navigation';
import RoutesProvider from '~/modules/common/routes';
import DrawerRouting from '~/modules/common/components/DrawerRouting';
import SessionExpiry from '~/modules/common/components/SessionExpiry';

import withAppProviders from './withAppProviders';
import { useSentry, useSentryUserContext } from './useSentry';
import { useConfigureTimeZoneEffect } from './useConfigureTimeZoneEffect';
import AppContextProvider from './AppContextProvider';
import { useRecordUserLoginAccess } from './useRecordUserLoginAccess';

export const App = () => {
  useConfigureTimeZoneEffect();
  useSentry();
  useSentryUserContext();
  useRecordUserLoginAccess();

  return (
    <AppContextProvider>
      <Router>
        <>
          <RoutesProvider>
            <LiveAnnouncer>
              <PageLayout />
            </LiveAnnouncer>{' '}
          </RoutesProvider>{' '}
          <DrawerRouting />
          <SessionExpiry />
        </>{' '}
      </Router>
    </AppContextProvider>
  );
};

export default withAppProviders(App);
