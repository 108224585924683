import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { compact } from 'lodash';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ExpenseEntryType } from '~/types';
import { allowedExpensesAndEstimatesColumns } from '~/modules/projects/project/AllowedExpensesAndEstimates/hooks';
import ApprovalStatusDropdown from '../AllowedExpensesAndEstimates/ApprovalStatusDropdown';
import ExpenseDialog from './ExpenseDialog';
import { useColumnVisibilityProps } from './hooks';

const useColumnStyles = makeStyles(theme => ({
  totalEstimates: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    minWidth: theme.spacing(22),
    right: theme.spacing(-2.75),
    zIndex: 1,
    backgroundColor: theme.palette.grey[50]
  }
}));

const useStyles = makeStyles(theme => ({
  approvalStatusContainer: {
    margin: theme.spacing(2, 1.5, 1.5, 0),
    width: theme.spacing(25)
  },
  closeButton: {
    margin: theme.spacing(0.5, 1, 1, 0)
  }
}));

const defaultVisibleColumns = [
  allowedExpensesAndEstimatesColumns.EXPENSE_TYPE,
  allowedExpensesAndEstimatesColumns.ESTIMATES,
  allowedExpensesAndEstimatesColumns.TOTAL_ESTIMATES
];

export const ExpenseReadOnlyDialog = ({
  onClose,
  open,
  projectEndDate,
  projectStartDate,
  canViewBillingContracts
}) => {
  const classes = useStyles();
  const columnClasses = useColumnStyles();
  const { values } = useFormikContext();
  const hasAtleastOneBillableRow = useMemo(
    () =>
      values.allowedExpensesAndEstimates.some(
        ({ billableType }) => billableType !== ExpenseEntryType.NonBillable
      ),
    [values.allowedExpensesAndEstimates]
  );

  const { shouldShowMarkUp, shouldShowBillableType } = useColumnVisibilityProps(
    {
      canViewBillingContracts,
      hasAtleastOneBillableRow
    }
  );

  const visibleColumns = useMemo(
    () =>
      compact([
        ...defaultVisibleColumns,
        shouldShowMarkUp && allowedExpensesAndEstimatesColumns.MARK_UP,
        shouldShowBillableType &&
          allowedExpensesAndEstimatesColumns.BILLABLE_TYPE
      ]),
    [shouldShowBillableType, shouldShowMarkUp]
  );

  const dialogAction = useMemo(
    () => (
      <Grid container spacing={0}>
        {canViewBillingContracts && hasAtleastOneBillableRow && (
          <Grid item xs={12} container justifyContent="flex-end">
            <div className={classes.approvalStatusContainer}>
              <ApprovalStatusDropdown value={values.approvalStatus} disabled />
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          className={classes.closeButton}
        >
          <Button onClick={onClose}>
            <FormattedMessage id="button.close" />
          </Button>
        </Grid>
      </Grid>
    ),
    [
      canViewBillingContracts,
      classes.approvalStatusContainer,
      classes.closeButton,
      hasAtleastOneBillableRow,
      onClose,
      values.approvalStatus
    ]
  );

  return (
    <ExpenseDialog
      columnClasses={columnClasses}
      editable={false}
      onClose={onClose}
      open={open}
      projectEndDate={projectEndDate}
      projectStartDate={projectStartDate}
      dialogAction={dialogAction}
      visibleColumns={visibleColumns}
    />
  );
};

ExpenseReadOnlyDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  projectEndDate: PropTypes.string,
  projectStartDate: PropTypes.string,
  canViewBillingContracts: PropTypes.bool
};

export default ExpenseReadOnlyDialog;
