import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { NoDataItem } from '~/modules/common/components';
import ClientInfoDetail from './ClientInfoDetail';

export const noAddressText = (
  <NoDataItem>
    <FormattedMessage id="client.noAddress" />
  </NoDataItem>
);

export const ClientInfoContactAddress = ({ addressDetails }) => {
  const {
    address,
    city,
    country,
    stateProvince,
    zipPostalCode
  } = addressDetails;

  const hasAddress =
    address || city || country || stateProvince || zipPostalCode;

  const detail = hasAddress ? (
    <>
      {address && (
        <Typography variant="caption" display="block">
          {address}
        </Typography>
      )}
      <div>
        {city && (
          <Typography variant="caption" display="inline" component="span">
            {city}
          </Typography>
        )}
        {stateProvince && (
          <Typography variant="caption" display="inline" component="span">
            , {stateProvince}
          </Typography>
        )}
        {zipPostalCode && (
          <Typography variant="caption" display="inline" component="span">
            , {zipPostalCode}
          </Typography>
        )}
      </div>
      {country && (
        <Typography variant="caption">{country.displayText}</Typography>
      )}
    </>
  ) : (
    noAddressText
  );

  return <ClientInfoDetail detail={detail} />;
};

ClientInfoContactAddress.propTypes = {
  addressDetails: PropTypes.object
};

export default ClientInfoContactAddress;
