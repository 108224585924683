import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

export const GET_BULK_CREATE_BILL_FROM_BILLING_ITEMS_BATCH_RESULT = gql`
  query GetBulkCreateBillFromBillingItemsBatchResult($batchId: String!) {
    getBulkCreateBillFromBillingItemsBatchResult(batchId: $batchId) {
      id
      displayId
    }
  }
`;

export const useFetchBulkCreateBillFromBillItemsBatchResult = () => {
  const apolloClient = useApolloClient();

  return {
    fetchBulkCreateBillFromBillItemsBatchResult: async batchId => {
      const { data } = await apolloClient.query({
        query: GET_BULK_CREATE_BILL_FROM_BILLING_ITEMS_BATCH_RESULT,
        variables: { batchId },
        fetchPolicy: 'network-only'
      });

      return data?.getBulkCreateBillFromBillingItemsBatchResult;
    }
  };
};
