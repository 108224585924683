import { useCallback } from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import {
  getInitialEstimatedHoursForTaskAllocation,
  getProjectAvailableHours,
  startIsAfterEnd
} from '../../common/taskAllocationEditorUtil';

export const onDateChange = ({
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  initialEstimatedHours,
  setValues
}) => {
  if (!startDate || !endDate) {
    setValues({
      startDate,
      endDate,
      projectAvailableHours: 0,
      allocatedHours: null
    });
  } else {
    const projectAvailableHours = getProjectAvailableHours({
      start: mapRepliconDateToUtcObject(startDate),
      end: mapRepliconDateToUtcObject(endDate),
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules
    });

    const allocatedHours = getInitialEstimatedHoursForTaskAllocation({
      initialEstimatedHours,
      projectAvailableHours
    });

    setValues({
      startDate,
      endDate,
      projectAvailableHours,
      allocatedHours
    });
  }
};

const useFormChangeHandlers = ({
  values,
  setValues,
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  initialEstimatedHours
}) => ({
  onStartDateChange: useCallback(
    val =>
      onDateChange({
        startDate: val,
        endDate:
          endDate && startIsAfterEnd({ start: val, end: endDate })
            ? val
            : endDate,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        initialEstimatedHours,
        setValues
      }),
    [
      endDate,
      initialEstimatedHours,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      setValues
    ]
  ),
  onEndDateChange: useCallback(
    val =>
      onDateChange({
        startDate:
          startDate && startIsAfterEnd({ start: startDate, end: val })
            ? val
            : startDate,
        endDate: val,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        initialEstimatedHours,
        setValues
      }),
    [
      initialEstimatedHours,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      setValues,
      startDate
    ]
  ),

  onTaskAllocationHoursChange: useCallback(
    event => {
      setValues({ ...values, allocatedHours: event.target.value || 0 });
    },
    [setValues, values]
  )
});

export default useFormChangeHandlers;
