import React, { useMemo, useCallback } from 'react';
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import { FormattedMessage, useIntl } from 'react-intl';
import { isoStringToObject } from '~/modules/common/dates/convert';
import { RedirectToNotFound } from '~/modules/common/notfound';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { PageHeaderLoading } from '~/modules/common/components/DetailsPage';
import {
  GoBackButton,
  ToggleEditButton,
  DeleteConfirmationDialog
} from '~/modules/common/components';
import { useVendorPageEditContext } from '../VendorPageEditContext';
import { useVendorActions } from '../../hooks/useVendorActions';
import { useVendorPermissions } from '../../hooks/useVendorPermissions';
import useCanVendorBeDeleted from '../hooks/useCanVendorBeDeleted';
import VendorStatusChipMenu from './VendorStatusChipMenu';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0
  },
  back: { width: '1.5rem', height: '1.5rem' },
  statusContainer: {
    paddingLeft: theme.spacing(2)
  },
  titleContainer: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden'
  },
  name: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline'
  },
  spacing: {
    marginLeft: theme.spacing(2)
  },
  deleteDialogInfo: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line'
  },
  errorText: {
    marginBottom: theme.spacing(2)
  }
}));

const VendorHeader = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const goBackButtonClasses = useMemo(() => ({ back: classes.back }), [
    classes.back
  ]);

  const {
    edit,
    toggleEdit,
    error,
    loading,
    vendor
  } = useVendorPageEditContext();

  const { isActive, name, id } = vendor;
  const closedDate = isoStringToObject(vendor?.closedDate);
  const {
    onRemoveVendorMenuItemClick,
    onMenuClick,
    anchorEl,
    isMenuOpen,
    onMenuClose,
    showRemoveDialog,
    closeRemoveDialog
  } = useVendorActions({
    id
  });

  const { canEditVendor } = useVendorPermissions();
  const canBeDeletedCallback = useCallback(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useCanVendorBeDeleted({ id }),
    [id]
  );

  if (error) return <RedirectToNotFound />;

  return loading ? (
    <PageHeaderLoading />
  ) : (
    <>
      <StickyHeader>
        <GoBackButton classes={goBackButtonClasses} to="/vendors" contextual />
        <div className={classes.titleContainer}>
          <Tooltip title={name}>
            <Typography className={classes.name} variant="h6">
              {name}
            </Typography>
          </Tooltip>
        </div>
        {canEditVendor ? (
          <>
            <ToggleEditButton edit={edit} onClick={toggleEdit} />
            <IconButton
              onClick={onMenuClick}
              data-qe-id="Vendor_MoreButton"
              aria-label={formatMessage({
                id: 'vendor.menu'
              })}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="vendor-actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={isMenuOpen}
              onClose={onMenuClose}
            >
              <MenuItem onClick={onRemoveVendorMenuItemClick}>
                <Typography color="secondary" variant="body2">
                  <FormattedMessage id="vendor.removeVendor" />
                </Typography>
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </StickyHeader>
      <Paper className={classes.root} square elevation={0}>
        <Grid
          spacing={0}
          className={classes.statusContainer}
          container
          alignItems="center"
        >
          <Grid item>
            {!loading && (
              <VendorStatusChipMenu
                editable={edit && canEditVendor}
                id={id}
                name={name}
                isActive={isActive}
                closedDate={closedDate}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      {showRemoveDialog && (
        <DeleteConfirmationDialog
          open={showRemoveDialog}
          onCancel={closeRemoveDialog}
          objectId={id}
          objectType="VENDOR"
          canBeDeletedCallback={canBeDeletedCallback}
          objectName={name}
        />
      )}
    </>
  );
};

export default VendorHeader;
