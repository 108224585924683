import { useState, useCallback, useEffect } from 'react';
import {
  useKeyValueSettingsMutation,
  updateCache,
  useGetKeyValueSettings
} from '~/modules/common/hooks';
import { SortDirection } from '~/types';

const AVAILABILITY_COLUMN_VISIBILITY_SETTING_TASKS =
  'availability_column_visibility_setting_tasks';

export const sortableFields = {
  availableDuration: 'availableDuration',
  displayText: 'displayText'
};

export const updateColumnState = ({
  isAvailabilityColumnVisible,
  setIsAvailabilityColumnEnabled,
  setSort
}) => {
  isAvailabilityColumnVisible
    ? setSort({
        field: sortableFields.availableDuration,
        direction: SortDirection.Desc
      })
    : setSort({
        field: sortableFields.displayText,
        direction: SortDirection.Asc
      });
  setIsAvailabilityColumnEnabled(isAvailabilityColumnVisible);
};

export const useColumnSettings = ({ initialValue }) => {
  const {
    keyValueSettings: availabilityColumnSetting
  } = useGetKeyValueSettings(
    AVAILABILITY_COLUMN_VISIBILITY_SETTING_TASKS,
    !initialValue
  );
  const [
    isAvailabilityColumnEnabled,
    setIsAvailabilityColumnEnabled
  ] = useState(null);

  const [sort, setSort] = useState(null);

  useEffect(() => {
    const isAvailabilityColumnVisible =
      initialValue &&
      Boolean(availabilityColumnSetting?.availabilityColumnVisibility);

    updateColumnState({
      isAvailabilityColumnVisible,
      setIsAvailabilityColumnEnabled,
      setSort
    });
  }, [availabilityColumnSetting, initialValue]);

  const [putKeyValueSettings] = useKeyValueSettingsMutation(
    updateCache(AVAILABILITY_COLUMN_VISIBILITY_SETTING_TASKS)
  );

  const onSortChange = useCallback(
    field =>
      setSort({
        field,
        direction:
          sort.field === field && sort.direction === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc
      }),
    [sort]
  );

  const toggleAvailabilityColumnVisibility = useCallback(() => {
    const newValue = Boolean(!isAvailabilityColumnEnabled);

    putKeyValueSettings({
      variables: {
        input: {
          key: AVAILABILITY_COLUMN_VISIBILITY_SETTING_TASKS,
          settings: {
            availabilityColumnVisibility: newValue
          }
        }
      }
    });

    updateColumnState({
      isAvailabilityColumnVisible: newValue,
      setIsAvailabilityColumnEnabled,
      setSort
    });
  }, [
    isAvailabilityColumnEnabled,
    setIsAvailabilityColumnEnabled,
    putKeyValueSettings,
    setSort
  ]);

  return {
    isAvailabilityColumnEnabled,
    sort,
    toggleAvailabilityColumnVisibility,
    onSortChange
  };
};
