import { Grid, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { FileAttachment } from '~/modules/attachments/AttachmentsBlock';
import {
  ChartCard,
  ClickableProjectStatusChart
} from '~/modules/common/charts/dashboard';
import { useScrollToTop } from '~/modules/common/hooks';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import {
  ProjectIcon,
  KPIIcon,
  HoursIcon,
  CostIcon
} from '~/modules/common/components/Icons';
import { useHasPermission } from '~/modules/common/permissions';
import { usePageTitle } from '~/modules/common/title';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline/periodScale';
import ResourceHoursHelp from '~/modules/common/CardHelp/ResourceHoursHelp';
import { useMeContext } from '~/modules/me/useMeContext';
import ResourceCostHelp from '~/modules/common/CardHelp/ResourceCostHelp';
import { CostChart, HoursChart, ClientOverviewChart } from '../charts';
import { useClientPermissions } from '../../hooks';
import { useClientContext } from '../context';
import ClientBasicInformation from './ClientBasicInformation';
import { ClientInfoLoadingContext } from './ClientInfoLoadingContext';
import RateCard from './RateCard';
import ClientBillingCard from './ClientBillingCard';
import BillingCardForm from './BillingCard';
import useClientPageEditContext from './useClientPageEditContext';

const labels = {
  HoursChartTitle: <FormattedMessage id="clientPageCharts.resourceHours" />,
  CostChartTitle: <FormattedMessage id="clientPageCharts.resourceCost" />,
  ClientOverviewChartTitle: <FormattedMessage id="clientPageCharts.overview" />
};

const resourceKeys = {
  noRates: 'rateCard.noRates.client'
};

const ProjectsTitle = <FormattedMessage id="clientPageCharts.projects" />;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minHeight: '-webkit-max-content'
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2, 0, 2, 2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0, 2, 0)
    }
  },
  clientBasicInfo: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexBasis: 200
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0
    },
    flexGrow: 0,
    flexShrink: 0,
    margin: 0,
    fontSize: theme.typography.caption.fontSize,
    paddingBottom: theme.spacing(2)
  },
  overviewItem: {
    padding: theme.spacing(0, 2, 2, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: theme.spacing(0)
    }
  },
  item: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0)
    }
  }
}));

const useChartCardStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const icons = {
  project: <ProjectIcon />,
  cost: <CostIcon />,
  hours: <HoursIcon />,
  overview: <KPIIcon />
};

const getVisibleCards = ({
  slug,
  editable,
  clientUri,
  canViewProject,
  clientPermissions,
  isLoading,
  chartDataProps,
  statusDataProps,
  resourceChartProps,
  chartClasses,
  resourceHoursHelpAction,
  resourceCostHelpAction,
  hasViewClientBillingData,
  clientDetails,
  isPsaPrpBillingDefaultForProjectEnabled
}) => [
  ...(canViewProject
    ? [
        <ChartCard
          key="ProjectsStatusChart"
          chartTitle={ProjectsTitle}
          titleId="ClientProject"
          chartAvatar={icons.project}
          chartDataProps={statusDataProps}
          ChartComponent={ClickableProjectStatusChart}
          classes={chartClasses}
          isLoading={isLoading}
        />
      ]
    : []),
  ...(clientPermissions.canViewClientCostData &&
  clientPermissions.canViewClientEstimates
    ? [
        <ChartCard
          key="ClientCostChart"
          chartTitle={labels.CostChartTitle}
          titleId="ClientCost"
          chartAvatar={icons.cost}
          chartDataProps={chartDataProps}
          ChartComponent={CostChart}
          scale={resourceChartProps.scale}
          dateRange={resourceChartProps.dateRange}
          showPeriodFilter
          classes={chartClasses}
          chartAction={resourceCostHelpAction}
        />
      ]
    : []),
  ...(clientPermissions.canViewClientEstimates
    ? [
        <ChartCard
          key="ClientHoursChart"
          chartTitle={labels.HoursChartTitle}
          titleId="ClientHours"
          chartAvatar={icons.hours}
          chartDataProps={chartDataProps}
          ChartComponent={HoursChart}
          scale={resourceChartProps.scale}
          dateRange={resourceChartProps.dateRange}
          showPeriodFilter
          classes={chartClasses}
          chartAction={resourceHoursHelpAction}
        />
      ]
    : []),
  ...(clientPermissions.canViewBillingRates
    ? [
        <RateCard
          key="RateCard"
          context="client"
          slug={slug}
          editable={editable}
          clientUri={clientUri}
          resourceKeys={resourceKeys}
        />
      ]
    : []),
  ...(clientPermissions.canViewDocuments
    ? [
        <FileAttachment
          key="attachments"
          attachToUri={clientUri}
          editable={editable && clientPermissions.canEditDocuments}
          isLoading={isLoading}
        />
      ]
    : []),
  ...(hasViewClientBillingData
    ? [
        isPsaPrpBillingDefaultForProjectEnabled ? (
          <ClientBillingCard
            key="BillingCard"
            clientDetails={clientDetails}
            editable={editable}
            isLoading={isLoading}
          />
        ) : (
          <BillingCardForm
            key="BillingCard"
            clientDetails={clientDetails}
            editable={editable}
            isLoading={isLoading}
          />
        )
      ]
    : [])
];

export const ClientInfo = ({ slug }) => {
  const { hasViewClientBillingData, featureFlags } = useMeContext();
  const { isPsaPrpBillingDefaultForProjectEnabled } = featureFlags;
  const { state: { dateRange: _dateRange, scale } = {} } = useLocation();
  const classes = useStyles();

  const dateRange = useMemo(
    () =>
      _dateRange
        ? {
            startDate: mapIsoStringtoUtcObject(_dateRange.startDate),
            endDate: mapIsoStringtoUtcObject(_dateRange.endDate)
          }
        : null,
    [_dateRange]
  );

  const clientPermissions = useClientPermissions();
  const canViewProject = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-project'
  });

  const chartDataProps = useMemo(() => ({ slug, clientPermissions }), [
    slug,
    clientPermissions
  ]);

  const { client: clientDetails, isLoading } = useClientContext();

  usePageTitle(
    isLoading
      ? []
      : [
          { messageId: 'routes.clients' },
          { title: clientDetails.name },
          { messageId: 'client.summary' }
        ]
  );

  const projectsStatusCountSummary =
    clientDetails.projectsStatusCountSummary || {};

  const {
    initiate = 0,
    planning = 0,
    execution = 0,
    closed = 0
  } = projectsStatusCountSummary;

  const statusDataProps = useMemo(
    () => ({
      projectsStatusCountSummary: {
        ...projectsStatusCountSummary,
        total: initiate + planning + execution + closed
      },
      isLoading
    }),
    [
      initiate,
      planning,
      execution,
      closed,
      isLoading,
      projectsStatusCountSummary
    ]
  );

  const { editable } = useClientPageEditContext();

  const resourceHoursHelpAction = useMemo(
    () => <ResourceHoursHelp type="client" />,
    []
  );
  const resourceCostHelpAction = useMemo(
    () => <ResourceCostHelp type="client" />,
    []
  );

  const canEditClient = clientPermissions.canEditClient && editable;

  const resourceChartDate =
    (dateRange && dateRange.startDate) || DateTime.local();
  const resourceChartProps = {
    scale: PERIOD_SCALE_ENUM.YEARS,
    dateRange: {
      startDate: resourceChartDate.startOf('year'),
      endDate: resourceChartDate.endOf('year')
    }
  };

  const chartClasses = useChartCardStyles();

  const visibleCards = getVisibleCards({
    slug,
    editable: canEditClient,
    clientUri: clientDetails ? clientDetails.id : null,
    clientPermissions,
    isLoading,
    chartDataProps,
    statusDataProps,
    canViewProject,
    resourceChartProps,
    chartClasses,
    resourceHoursHelpAction,
    resourceCostHelpAction,
    hasViewClientBillingData,
    clientDetails,
    isPsaPrpBillingDefaultForProjectEnabled
  });

  useScrollToTop();

  return (
    <ClientInfoLoadingContext.Provider value={isLoading}>
      <div className={classes.root}>
        <div className={classes.clientBasicInfo}>
          <ClientBasicInformation
            isLoading={isLoading}
            isClientEditable={canEditClient}
            clientDetails={clientDetails}
          />
        </div>
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={12} sm={12} lg={12} className={classes.overviewItem}>
            {clientPermissions.canViewClientCostData &&
              clientPermissions.canViewClientEstimates && (
                <ErrorBoundary key="ClientOverviewChart">
                  <ChartCard
                    chartTitle={labels.ClientOverviewChartTitle}
                    chartAvatar={icons.overview}
                    chartDataProps={chartDataProps}
                    ChartComponent={ClientOverviewChart}
                    isLoading={isLoading}
                    showPeriodFilter
                    showScaleSelector
                    scale={scale}
                    dateRange={dateRange}
                  />
                </ErrorBoundary>
              )}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} className={classes.item}>
            {visibleCards.filter((_, index) => index % 2 === 0)}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} className={classes.item}>
            {visibleCards.filter((_, index) => index % 2 === 1)}
          </Grid>
        </Grid>
      </div>
    </ClientInfoLoadingContext.Provider>
  );
};

ClientInfo.propTypes = {
  slug: PropTypes.string.isRequired
};

export default ClientInfo;
