import { useCallback } from 'react';
import { v4 } from 'uuid';

export const useOnChangeHandlers = ({
  values: { resourceAssignments, deletedResourceAssignmentsUris },
  setFieldValue
}) => {
  return {
    onAddNewResourceAssignment: useCallback(() => {
      const updatedResourceAssignments = [
        ...resourceAssignments,
        {
          id: null,
          role: null,
          quantity: 1,
          rowId: v4()
        }
      ];

      setFieldValue('resourceAssignments', updatedResourceAssignments, false);
    }, [resourceAssignments, setFieldValue]),
    onRemoveResourceAssignment: useCallback(
      ({ id, rowId }) => {
        const updatedResourceAssignments = resourceAssignments.filter(
          resourceAssignment =>
            id
              ? resourceAssignment.id !== id
              : resourceAssignment.rowId !== rowId
        );

        setFieldValue('resourceAssignments', updatedResourceAssignments);
        setFieldValue('deletedResourceAssignmentsUris', [
          ...deletedResourceAssignmentsUris,
          ...(id ? [id] : [])
        ]);
      },
      [deletedResourceAssignmentsUris, resourceAssignments, setFieldValue]
    ),
    onRoleChange: useCallback(
      (value, entryIndex) => {
        setFieldValue(`resourceAssignments[${entryIndex}].role`, value);
      },
      [setFieldValue]
    ),
    onQuantityChange: useCallback(
      (value, entryIndex) => {
        setFieldValue(`resourceAssignments[${entryIndex}].quantity`, value);
      },
      [setFieldValue]
    ),
    onQuantityBlur: useCallback(
      entryIndex => {
        if (!resourceAssignments[entryIndex].quantity)
          setFieldValue(`resourceAssignments[${entryIndex}].quantity`, 1);
      },
      [setFieldValue, resourceAssignments]
    )
  };
};
