import React from 'react';
import { useIntl } from 'react-intl';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import { MoneyValue, NoValue } from '~/modules/common/components';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useIsBreakpointDown, useHasFeatureFlag } from '~/modules/common/hooks';
import { PROJECT_STATUS } from '~/modules/common/enums';
import { recursivelyStripTypeName } from '~/util';
import { isNullOrUndefined } from '~/modules/common/util';

const chartConfig = {
  width: 270,
  height: 270,
  innerRadius: 85,
  valueX: 135,
  valueY: 147,
  padding: { top: 12, bottom: 13, right: 0, left: 0 }
};

const mobileChartConfig = {
  width: 250,
  height: 250,
  innerRadius: 75,
  valueX: 125,
  valueY: 150
};

const overrideChartStyles = {
  chartValue: {
    fontSize: 16
  }
};

export const LABEL_KEYS = {
  REMAINING: 'Remaining',
  OVERALLOCATED: 'Overallocated'
};

export const dataPoints = ({
  budgetSummaryAmounts,
  budgetDifference,
  totalProjectsBudgetAmount
}) =>
  totalProjectsBudgetAmount === 0
    ? [{ y: 1 }]
    : [
        { y: budgetSummaryAmounts.initiate, id: PROJECT_STATUS.INITIATE },
        { y: budgetSummaryAmounts.planning, id: PROJECT_STATUS.PLANNING },
        {
          y: budgetSummaryAmounts.execution,
          id: PROJECT_STATUS.EXECUTION
        },
        { y: budgetSummaryAmounts.closeout, id: PROJECT_STATUS.CLOSEOUT },
        { y: budgetSummaryAmounts.archived, id: PROJECT_STATUS.ARCHIVED },
        ...(budgetDifference > 0
          ? [
              {
                y: budgetDifference
              }
            ]
          : [])
      ];

export const statusColors = {
  initiate: theme.palette.project.initiate.main,
  planning: theme.palette.project.planning.main,
  execution: theme.palette.project.execution.main,
  closeout: theme.palette.project.closeout.main,
  archived: theme.palette.project.archived.main,
  remaining: '#f5f5f5',
  overallocated: '#d9240f',
  zeroTotal: theme.palette.common.white
};

export const legends = ({
  budgetSummary,
  countSummary,
  portfolioCurrency,
  budgetDifference,
  intl,
  customStatusMapping,
  isCustomStatusNameEnabled
}) => {
  const { initiate, planning, execution, closeout, archived } = budgetSummary;

  const buildCustomLabels = (status, count) =>
    intl.formatMessage(
      {
        id: `portfolio.projectBudgetStatusChart.customStatusLabelTitle.${status}`
      },
      {
        count,
        status: customStatusMapping[status]
      }
    );

  const legendsArray = [
    {
      value: initiate ? (
        <MoneyValue money={initiate} precision={0} />
      ) : (
        <NoValue />
      ),
      title:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_STATUS.INITIATE]
          ? buildCustomLabels(PROJECT_STATUS.INITIATE, countSummary.initiate)
          : intl.formatMessage(
              {
                id: 'portfolio.projectBudgetStatusChart.labelTitle.initiate'
              },
              {
                count: countSummary.initiate
              }
            ),
      colorScale: statusColors.initiate,
      key: PROJECT_STATUS.INITIATE,
      dataQeId: 'initiate'
    },
    {
      value: planning ? (
        <MoneyValue money={planning} precision={0} />
      ) : (
        <NoValue />
      ),
      title:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_STATUS.PLANNING]
          ? buildCustomLabels(PROJECT_STATUS.PLANNING, countSummary.planning)
          : intl.formatMessage(
              {
                id: 'portfolio.projectBudgetStatusChart.labelTitle.planning'
              },
              {
                count: countSummary.planning
              }
            ),
      colorScale: statusColors.planning,
      key: PROJECT_STATUS.PLANNING,
      dataQeId: 'planning'
    },
    {
      value: execution ? (
        <MoneyValue money={execution} precision={0} />
      ) : (
        <NoValue />
      ),
      title:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_STATUS.EXECUTION]
          ? buildCustomLabels(PROJECT_STATUS.EXECUTION, countSummary.execution)
          : intl.formatMessage(
              {
                id: 'portfolio.projectBudgetStatusChart.labelTitle.execution'
              },
              {
                count: countSummary.execution
              }
            ),
      colorScale: statusColors.execution,
      key: PROJECT_STATUS.EXECUTION,
      dataQeId: 'execution'
    },
    {
      value: closeout ? (
        <MoneyValue money={closeout} precision={0} />
      ) : (
        <NoValue />
      ),
      title:
        isCustomStatusNameEnabled &&
        customStatusMapping[PROJECT_STATUS.CLOSEOUT]
          ? buildCustomLabels(PROJECT_STATUS.CLOSEOUT, countSummary.closed)
          : intl.formatMessage(
              {
                id: 'portfolio.projectBudgetStatusChart.labelTitle.closeout'
              },
              {
                count: countSummary.closed
              }
            ),
      colorScale: statusColors.closeout,
      key: PROJECT_STATUS.CLOSEOUT,
      dataQeId: 'closeout'
    },
    ...(countSummary.archived || archived?.amount
      ? [
          {
            value: archived ? (
              <MoneyValue money={archived} precision={0} />
            ) : (
              <NoValue />
            ),
            title: intl.formatMessage(
              {
                id: 'portfolio.projectBudgetStatusChart.labelTitle.archived'
              },
              {
                count: countSummary.archived
              }
            ),
            colorScale: statusColors.archived,
            key: PROJECT_STATUS.ARCHIVED,
            dataQeId: 'archived'
          }
        ]
      : []),
    ...(budgetDifference == null
      ? []
      : budgetDifference >= 0
      ? [
          {
            value: (
              <MoneyValue
                money={{
                  amount: budgetDifference,
                  currency: portfolioCurrency
                }}
                precision={0}
              />
            ),
            title: intl.formatMessage({
              id: 'portfolio.projectBudgetStatusChart.labelTitle.remaining'
            }),
            colorScale: statusColors.remaining,
            key: LABEL_KEYS.REMAINING,
            dataQeId: 'remaining'
          }
        ]
      : [
          {
            value: (
              <MoneyValue
                money={{
                  amount: Math.abs(budgetDifference),
                  currency: portfolioCurrency
                }}
                precision={0}
              />
            ),
            title: intl.formatMessage({
              id: 'portfolio.projectBudgetStatusChart.labelTitle.overallocated'
            }),
            colorScale: statusColors.overallocated,
            key: LABEL_KEYS.OVERALLOCATED,
            dataQeId: 'overallocated'
          }
        ])
  ];

  return legendsArray;
};

const colorScale = totalProjectsBudgetAmount =>
  totalProjectsBudgetAmount === 0
    ? [statusColors.zeroTotal]
    : [
        statusColors.initiate,
        statusColors.planning,
        statusColors.execution,
        statusColors.closeout,
        statusColors.archived,
        statusColors.remaining
      ];

const innerPieConfig = ({
  portfolioBudget,
  overallocatedBudget,
  isMobile
}) => ({
  dataPoints: [
    {
      y: portfolioBudget - overallocatedBudget
    },
    {
      y: overallocatedBudget
    }
  ],
  colorScale: [theme.palette.common.white, statusColors.overallocated],
  innerRadius: isMobile ? 65 : 75
});

const titleComponentConfig = isMobile => ({
  x: isMobile ? 125 : 135,
  y: isMobile ? 135 : 132,
  style: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fill: theme.palette.text.primary
  },
  textAnchor: 'middle'
});

export const usePortfolioProjectStatusBudgetChartConfig = ({
  budgetSummaryAmounts,
  countSummary,
  portfolioBudget,
  portfolioCurrency
}) => {
  const intl = useIntl();
  const isMobile = useIsBreakpointDown('md');
  const isPsaPrpRenameProjectStatusEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRenameProjectStatusEnabled'
  });

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const budgetSummary = Object.keys(
    recursivelyStripTypeName(budgetSummaryAmounts)
  ).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: isNullOrUndefined(budgetSummaryAmounts[curr])
        ? null
        : {
            amount: budgetSummaryAmounts[curr],
            currency: portfolioCurrency
          }
    };
  }, {});

  const totalProjectsBudgetAmount = Object.values(budgetSummary).reduce(
    (acc, curr) => {
      return curr?.amount ? acc + curr.amount : acc;
    },
    0
  );

  const budgetDifference =
    portfolioBudget >= 0 ? portfolioBudget - totalProjectsBudgetAmount : null;

  const overrideChartConfig = isMobile ? mobileChartConfig : chartConfig;

  return {
    colorScale: colorScale(totalProjectsBudgetAmount),
    legends: legends({
      budgetSummary,
      countSummary,
      portfolioCurrency,
      intl,
      budgetDifference,
      customStatusMapping,
      isCustomStatusNameEnabled
    }),
    dataPoints: dataPoints({
      budgetSummaryAmounts,
      budgetDifference,
      totalProjectsBudgetAmount
    }),
    overrideChartConfig,
    totalProjectsBudgetAmount,
    innerPieConfig:
      budgetDifference < 0
        ? innerPieConfig({
            portfolioBudget,
            overallocatedBudget: Math.abs(budgetDifference),
            isMobile
          })
        : null,
    overrideChartStyles,
    titleComponentConfig: titleComponentConfig(isMobile)
  };
};

export default usePortfolioProjectStatusBudgetChartConfig;
