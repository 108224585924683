import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { ProjectRequestSearchBox } from '~/modules/project-request/ProjectRequestPage/components';
import { portfolioTag } from '~/modules/common/components/SearchBox/Facets/PortfolioFacet/tag';
import { columns } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks/requestColumns';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useProjectRequestListTableSettings } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestList/hooks';
import { usePortfolioProjectRequestsContext } from '~/modules/portfolios/portfolio//PortfolioProjectRequestsContext';
import PortfolioProjectRequestSummary from './PortfolioProjectRequestSummary';
import { PortfolioProjectRequestList } from './PortfolioProjectRequestList';

const useStyles = makeStyles(theme => ({
  root: { margin: theme.spacing(2, 2, 0, 2) },
  toolBar: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    minHeight: '-webkit-fit-content',
    left: 0,
    right: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar - 2,
    [theme.breakpoints.down('xs')]: {
      flexDirection: `column-reverse`,
      alignItems: 'flex-end'
    }
  }
}));
const TABLE_KEY = 'ppm-portfolio-project-requests-table';

export const ProjectRequestTabContent = ({ loading, portfolio }) => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const { id, portfolioCurrency } = portfolio;
  const {
    searchCriteria,
    setSearchCriteria,
    summaryFilterState
  } = usePortfolioProjectRequestsContext();

  const {
    sortLoading,
    sortCriteria,
    onSortChange
  } = useProjectRequestListTableSettings({ tableKey: TABLE_KEY });

  const updatedSearchCriteria = useMemo(
    () => ({
      ...searchCriteria,
      criterions: id
        ? {
            ...(searchCriteria.criterions || {}),
            [portfolioTag]: [{ value: id }]
          }
        : {}
    }),
    [id, searchCriteria]
  );

  const portfolioProjectRequestSelectedColumns = useMemo(() => {
    const allColumns = columns();
    const portfolioProjectRequestColumnsNames = [
      allColumns.name.field,
      allColumns.description.field,
      allColumns.requestedBy.field,
      allColumns.status.field,
      allColumns.effectiveTotal.field,
      allColumns.value.field,
      allColumns.investment.field,
      allColumns.risk.field,
      allColumns.estimatedCostInPortfolioCurrency.field
    ];

    return portfolioProjectRequestColumnsNames;
  }, []);

  return loading ? (
    <Skeleton height={40} className={classes.root} />
  ) : (
    <>
      <div className={classes.toolBar}>
        <ProjectRequestSearchBox
          hidePortfolioFacet
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
        />
      </div>
      <PortfolioProjectRequestSummary
        isMobile={isMobile}
        searchCriteria={updatedSearchCriteria}
        summaryFilterState={summaryFilterState}
      />
      <PortfolioProjectRequestList
        isMobile={isMobile}
        selectedColumnNames={portfolioProjectRequestSelectedColumns}
        searchCriteria={updatedSearchCriteria}
        summaryFilterState={summaryFilterState}
        isSortLoading={sortLoading}
        sortCriteria={sortCriteria}
        onSortChange={onSortChange}
        portfolioCurrency={portfolioCurrency}
      />
    </>
  );
};

ProjectRequestTabContent.propTypes = {
  loading: PropTypes.bool,
  portfolio: PropTypes.object
};

export default ProjectRequestTabContent;
