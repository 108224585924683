import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';
import { DateTime } from 'luxon';

export const projectRiskQuery = gql`
  query GetProjectRiskQuery($projectUri: String!) {
    predictionCacheProjectRisk(projectUri: $projectUri) {
      projectUri
      predictedDate
      riskScore
    }
  }
`;

const useProjectRisk = ({ projectUri, endDate }) => {
  const { loading, error, data } = useQuery(projectRiskQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUri
    },
    errorPolicy: 'all'
  });

  const predictedEndDate = get(
    data,
    'predictionCacheProjectRisk.predictedDate',
    null
  );

  const riskScore = get(data, 'predictionCacheProjectRisk.riskScore', null);

  let predictedDiff = null;

  const predictedISODate =
    predictedEndDate &&
    DateTime.fromISO(predictedEndDate, {
      setZone: true,
      zone: 'utc'
    });

  if (endDate && predictedISODate) {
    const end = DateTime.fromISO(endDate, { setZone: true });

    predictedDiff = end.diff(predictedISODate, 'days').days;
  }

  return {
    loading,
    predictedEndDate: predictedISODate && predictedISODate.toISO(),
    predictedDiff,
    riskScore,
    error
  };
};

export default useProjectRisk;
