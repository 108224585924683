import React, { memo, useMemo, useCallback } from 'react';
import deepEqual from 'fast-deep-equal';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  makeStyles
} from '@material-ui/core';
import ListItemContent from './MobileProjectListItemContent';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ({ checked }) =>
      checked ? theme.palette.grey[100] : theme.palette.background.paper,
    '&:hover': {
      backgroundColor: ({ checked }) =>
        checked ? theme.palette.grey[100] : theme.palette.background.paper
    }
  },
  icon: {
    minWidth: theme.spacing(6)
  }
}));

const MobileProjectListItem = ({ record, onClick }) => {
  const {
    id,
    checked = false,
    name,
    client,
    portfolio,
    status,
    startDate,
    endDate,
    projectManagerReference
  } = record;

  const classes = useStyles({ checked });
  const rootClasses = useMemo(
    () => ({
      root: classes.root
    }),
    [classes.root]
  );

  const handleClick = useCallback(() => onClick({ id, name, portfolio }), [
    onClick,
    id,
    name,
    portfolio
  ]);

  return (
    <ListItem
      classes={rootClasses}
      button
      onClick={handleClick}
      disableGutters
      dense
      disableTouchRipple
    >
      <ListItemIcon className={classes.icon}>
        <Checkbox checked={checked} />
      </ListItemIcon>
      <ListItemContent
        name={name}
        client={client}
        portfolio={portfolio}
        status={status}
        startDate={startDate}
        endDate={endDate}
        projectManagerReference={projectManagerReference}
      />
    </ListItem>
  );
};

MobileProjectListItem.propTypes = {
  record: PropTypes.object,
  onClick: PropTypes.func
};

const pickCompareProps = ({
  record: {
    id,
    checked,
    name,
    client,
    portfolio,
    status,
    startDate,
    endDate,
    projectManagerReference
  },
  onClick
}) => ({
  id,
  checked,
  name,
  client,
  portfolio,
  status,
  startDate,
  endDate,
  projectManagerReference,
  onClick
});

const areEqual = (prev, next) =>
  deepEqual(pickCompareProps(prev), pickCompareProps(next));

export default memo(MobileProjectListItem, areEqual);
