import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  makeStyles,
  MenuList,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { SideCardSectionHeader } from '~/modules/common/components/SideCard';
import { useDialogState } from '~/modules/common/hooks';
import AddPortfolioDialog from '~/modules/portfolios/PortfoliosPage/components/AddPortfolio';
import { usePageOfChildrenPortfolios } from '../../hooks/usePageOfChildrenPortfolios';

const subPortfoliosTitle = <FormattedMessage id="portfolio.subPortfolios" />;
const addCircleIcon = <AddCircleIcon />;

const useStyles = makeStyles(theme => ({
  portfolioButton: {
    justifyContent: 'space-between',
    '&:hover': {
      '& svg': {
        opacity: 0.85
      }
    },
    '& svg': {
      opacity: 0,
      transition: theme.transitions.easing.easeInOut
    },
    margin: theme.spacing(0, -2),
    paddingTop: theme.spacing(0.8),
    paddingLeft: theme.spacing(2)
  },
  showMoreButton: {
    textTransform: 'none',
    width: '100%'
  },
  iconDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  portfolioName: {
    fontSize: theme.typography.body2.fontSize,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  actionBtn: {
    marginLeft: theme.spacing(1)
  },
  childPortfolioButton: {
    margin: theme.spacing(0, 0, 0, -0.5)
  },
  maxSubPortfolios: {
    fontStyle: 'italic'
  }
}));

const putMutationProps = { doCacheUpdate: true, refetchQueries: [] };

export const ChildPortfolios = ({
  parentId,
  parentName,
  showEdit,
  ancestorsLength
}) => {
  const classes = useStyles();

  const {
    open: childPortfolioDialogOpen,
    openDialog: openChildPortfolioDialog,
    closeDialog: closeChildPortfolioDialog
  } = useDialogState(false);

  const {
    loading,
    childPortfolios,
    loadMore,
    hasMore
  } = usePageOfChildrenPortfolios({
    parentId
  });

  return (
    <>
      {childPortfolios.length || showEdit ? (
        <>
          <SideCardSectionHeader
            title={subPortfoliosTitle}
            showEdit={false}
            ariaLabelKey="button.subPortfoliosEditbutton"
          />
          <MenuList disablePadding>
            {childPortfolios.map(x => (
              <MenuItem
                className={classes.portfolioButton}
                key={x.id}
                component={Link}
                to={`${x.slug}`}
                disableGutters
              >
                <Typography className={classes.portfolioName}>
                  {x.displayText}
                </Typography>
                <div className={classes.iconDiv}>
                  <ArrowForwardIcon fontSize="small" />
                </div>
              </MenuItem>
            ))}
          </MenuList>
          {hasMore && !loading ? (
            <Button
              className={classes.showMoreButton}
              color="primary"
              onClick={loadMore}
            >
              <FormattedMessage id="portfolio.showMore" />
            </Button>
          ) : null}
        </>
      ) : null}
      {showEdit ? (
        ancestorsLength < 9 ? (
          <Button
            color="primary"
            startIcon={addCircleIcon}
            onClick={openChildPortfolioDialog}
            className={classes.childPortfolioButton}
          >
            <FormattedMessage id="portfolio.subPortfolio" />
          </Button>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.maxSubPortfolios}
          >
            <FormattedMessage id="portfolio.maxSubPortfolios" />
          </Typography>
        )
      ) : null}
      {childPortfolioDialogOpen && (
        <AddPortfolioDialog
          open={childPortfolioDialogOpen}
          onClose={closeChildPortfolioDialog}
          parentId={parentId}
          parentName={parentName}
          putMutationProps={putMutationProps}
        />
      )}
    </>
  );
};

ChildPortfolios.propTypes = {
  parentId: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  showEdit: PropTypes.bool,
  ancestorsLength: PropTypes.number
};

export default ChildPortfolios;
