import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const PUT_PORTFOLIO_BUDGET = gql`
  mutation putPortfolioBudget($input: PutPortfolioBudgetInput!) {
    putPortfolioBudget(input: $input)
  }
`;

export const usePutPortfolioBudgetMutation = ({
  portfolioId,
  canViewPlanningData
}) => {
  const [putPortfolio] = useMutation(PUT_PORTFOLIO_BUDGET);

  return {
    putPortfolioBudget: ({ budget = {}, budgetCurrency }) => {
      const { total, operational, capital } = budget;

      return putPortfolio({
        variables: {
          input: {
            id: portfolioId,
            totalBudgetAmount: total,
            capitalBudgetAmount: capital,
            operationalBudgetAmount: operational,
            budgetCurrency
          }
        },
        refetchQueries: [
          'portfolio',
          'portfolioProjectStatusBudgetSummary',
          'portfolioProjectsProgressSummary',
          canViewPlanningData &&
            'portfolioProjectRequestStatusEstimatedCostSummary'
        ].filter(x => x)
      });
    }
  };
};

export default usePutPortfolioBudgetMutation;
