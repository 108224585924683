import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AttachmentIcon from '@material-ui/icons/AttachmentSharp';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import { useGetAttachments } from '~/modules/attachments/AttachmentsBlock/hooks';
import NoAttachments from '~/modules/attachments/AttachmentsBlock/NoAttachments';
import AttachmentsList from '~/modules/attachments/AttachmentsBlock/AttachmentsList';

const useStyles = makeStyles(() => ({
  title: {
    position: 'relative',
    top: '-8px',
    padding: '0 8px'
  }
}));

export const AttachmentsSection = ({ userUri }) => {
  const classes = useStyles();

  const { data: { attachments } = {}, isLoading } = useGetAttachments({
    objectUri: userUri
  });

  const title = useMemo(
    () => (
      <>
        <AttachmentIcon />
        <span className={classes.title}>
          <FormattedMessage id="resourceDrawer.documents" />
        </span>
      </>
    ),
    [classes.title]
  );

  return isLoading ? (
    <Grid item className={classes.section}>
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
      <Skeleton width="100%" height={50} />
    </Grid>
  ) : (
    <InfoSection editable={false} title={title}>
      {attachments && attachments.length > 0 ? (
        <AttachmentsList attachments={attachments} />
      ) : (
        <NoAttachments />
      )}
    </InfoSection>
  );
};

AttachmentsSection.propTypes = {
  userUri: PropTypes.string.isRequired
};

export default AttachmentsSection;
