import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const ESTIMATED_EXPENSES_SUMMARY = gql`
  query estimatedExpensesSummary(
    $projectId: String!
    $dateRange: DateRangeInput2
    $currencyId: String
  ) {
    estimatedExpensesSummary(
      projectId: $projectId
      dateRange: $dateRange
      currencyId: $currencyId
    ) {
      currency {
        id
        symbol
        displayText
      }
      expenseCode {
        id
        displayText
      }
      billableAmount
      nonBillableAmount
    }
  }
`;

export const useEstimatedExpensesSummary = ({
  projectId,
  dateRange,
  currencyId
}) => {
  const { data, loading, refetch } = useQuery(ESTIMATED_EXPENSES_SUMMARY, {
    variables: {
      projectId,
      dateRange,
      currencyId
    },
    skip: !projectId || !dateRange,
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const estimatedExpensesSummary = get(data, 'estimatedExpensesSummary', []);

  return {
    estimatedExpensesSummary,
    loading,
    refetch
  };
};

export default useEstimatedExpensesSummary;
