import React from 'react';
import { FormattedMessage } from 'react-intl';
import TimeAndExpenseTab from './TimeAndExpenseTab';

export const route = ({ me: { permissionsMap }, isMobile }) => {
  const canViewExpenseData = Boolean(
    permissionsMap['urn:replicon:combined-actuals-action:view-expense']
  );
  const titleId = canViewExpenseData ? 'client.timeAndExpense' : 'client.time';

  return {
    path: canViewExpenseData
      ? '/clients/:slug/time-and-expense'
      : '/clients/:slug/time',
    hidden: isMobile,
    title: () => <FormattedMessage id={titleId} />,
    component: TimeAndExpenseTab,
    dataQeId: 'ClientPage_TimeAndExpense'
  };
};
