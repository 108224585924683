import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useIsRTL } from '~/modules/common/hooks/useIsRTL';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import useLocalStorage from '~/modules/common/hooks/useLocalStorage';
import RepliconAssistant from '~/modules/replicon-assistant';
import DesktopMenu from '../DesktopMenu';
import useStyles from './useStyles';
import withSideNavigationRouteFilter from './withSideNavigationRouteFilter';
import SideNavigationContext from './SideNavigationContext';
import PolarisLogo from './PolarisLogo.svg';
import PolarisLogoMob from './PolarisLogoMob.svg';
import PolarisLogoRTL from './PolarisLogoRTL.svg';

export const Logo = ({ isRTL }) => {
  const isMobile = useIsBreakpointDown('xs');
  const showsMenuButton = useIsBreakpointDown('sm');
  const logoStyles = {
    mobile: {
      width: '100%'
    },
    desktop: {
      height: '64px',
      verticalAlign: 'middle'
    }
  };

  return (
    <img
      src={isMobile ? PolarisLogoMob : isRTL ? PolarisLogoRTL : PolarisLogo}
      alt="Polaris Logo"
      style={showsMenuButton ? logoStyles.mobile : logoStyles.desktop}
    />
  );
};

export const SideNavigation = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isMenuPinned, setIsMenuPinned] = useLocalStorage('menu-pinned', false);
  const toggleMenuPinned = useCallback(() => setIsMenuPinned(!isMenuPinned), [
    setIsMenuPinned,
    isMenuPinned
  ]);
  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen]);
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const isRTL = useIsRTL();
  const value = useMemo(
    () => ({
      menuOpen,
      openMenu,
      closeMenu,
      isMenuPinned,
      toggleMenuPinned
    }),
    [menuOpen, openMenu, closeMenu, isMenuPinned, toggleMenuPinned]
  );

  useEffect(() => {
    closeMenu();
  }, [location.pathname, closeMenu]);

  return (
    <SideNavigationContext.Provider value={value}>
      <div id="side-navigation-root" className={classes.root}>
        <DesktopMenu
          logo={useMemo(
            () => (
              <Logo isRTL={isRTL} />
            ),
            [isRTL]
          )}
          open={menuOpen}
          onClose={closeMenu}
          isMenuPinned={isMenuPinned}
          toggleMenuPinned={toggleMenuPinned}
        />
        {children}
        <RepliconAssistant />
      </div>
    </SideNavigationContext.Provider>
  );
};

SideNavigation.propTypes = {
  children: PropTypes.node
};

Logo.propTypes = {
  isRTL: PropTypes.bool.isRequired
};

export default withSideNavigationRouteFilter(SideNavigation);
