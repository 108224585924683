import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { getSentryDsn, getVersion } from '~/config';
import { useMeContext, MeType } from '../me';

type MeInput = {
  me: MeType | null;
};

export const useSentry = (): void => {
  useEffect(() => {
    Sentry.init({
      dsn: getSentryDsn()
    });

    Sentry.configureScope(scope => scope.setTag('version', getVersion()));
  }, []);
};

const extractSwimlaneFromRootPath = (rootPath: string): string | null => {
  const matches = /^https:\/\/([^.]+).*$/.exec(rootPath);

  return matches && matches.length > 1 ? matches[1] : null;
};

const setSentryUserContext = ({ me }: { me: MeType }): void => {
  if (me) {
    Sentry.configureScope(scope => {
      scope.setUser({ username: me.loginName as string, id: me.id });
      scope.setTag('tenantSlug', me.tenantSlug as string);
      scope.setTag('companyKey', me.companyKey as string);

      const swimlane = extractSwimlaneFromRootPath(me.uiRootPath as string);

      if (swimlane) {
        scope.setTag('swimlane', swimlane);
      }
    });
  }
};

export function useSetUserContextFromMe({ me }: MeInput): void {
  useEffect(() => {
    if (me) {
      setSentryUserContext({ me });
    }
  }, [me]);
}

export function useSentryUserContext(): void {
  const me = useMeContext();

  useSetUserContextFromMe({ me });
}
