import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useProjectObjectPermissions } from '~/modules/common/hooks/project';
import ProjectContext from './ProjectContext';

export const getProjectFromProps = ({ project }) => {
  if (!project) return null;

  return {
    ...project,
    hasStartDate: Boolean(project.startDate),
    hasEndDate: Boolean(project.endDate)
  };
};

const ProjectContextProvider = ({ children, project }) => {
  const projectObjectPermissions = useProjectObjectPermissions({ project });
  const contextValues = useMemo(
    () => ({
      project: getProjectFromProps({ project }),
      projectObjectPermissions
    }),
    [project, projectObjectPermissions]
  );

  return (
    <ProjectContext.Provider value={contextValues}>
      {children}
    </ProjectContext.Provider>
  );
};

ProjectContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.object
};

export default ProjectContextProvider;
