import { useMeContext } from '~/modules/me/useMeContext';
import {
  PROJECT_DAL,
  PROJECT_PERMISSION,
  USER_ACCESS_ROLE
} from '~/modules/common/enums';

export default () => {
  const { permissionsMap, userAccessRoles } = useMeContext();
  const editProjectPermission = permissionsMap[PROJECT_PERMISSION.EDIT_PROJECT];

  return Boolean(
    userAccessRoles.includes(USER_ACCESS_ROLE.PORTFOLIO_ADMIN) &&
      editProjectPermission &&
      editProjectPermission.dataAccessLevelUris.includes(PROJECT_DAL.ALL)
  );
};
