import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { useIsRTL } from '~/modules/common/hooks';
import {
  ListTable,
  PAGINATION_TYPE,
  ListTableThemeProvider,
  ListTableNavigationContext
} from '~/modules/common/components/ListTable';
import ErrorMessage from '~/modules/vendors/components/ErrorMessage';
import { useListColumns } from '../useListColumns';
import { useListHeaders } from '../useListHeaders';
import { useResourceAssignmentDialogContext } from './ResourceAssignmentDialogContext';
import { useUsersAvailableForVendorAssignment } from './hooks/useUsersAvailableForVendorAssignment';
import NoResourcesMessage from './NoResourcesMessage';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate'
  },
  headerCellWithNavigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.body2.fontSize
  },
  tableHeaderCell: {
    height: theme.spacing(4.5),
    padding: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    overflowY: 'visible',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tableCellDisabled: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'default'
    }
  }
}));

const useContainerClasses = makeStyles(() => ({
  root: {
    minWidth: 'max-content'
  }
}));

const RESOURCE_ASSIGNMENT_PAGE_SIZE = 5;

export const ResourceAssignmentList = ({ onRowClick, selectedUser }) => {
  const {
    users,
    currentPage,
    setCurrentPage
  } = useResourceAssignmentDialogContext();
  const isRTL = useIsRTL();

  const providerClasses = useListTableThemeProviderStyles({});
  const containerClasses = useContainerClasses();
  const {
    loadMore,
    hasMore,
    loadingMore,
    loading,
    error
  } = useUsersAvailableForVendorAssignment({
    page: currentPage,
    pageSize: RESOURCE_ASSIGNMENT_PAGE_SIZE
  });

  const resourceAssignmentListNavigationContext = useMemo(
    () => ({
      navigatePage: setCurrentPage,
      currentPage,
      maximumPageSize: RESOURCE_ASSIGNMENT_PAGE_SIZE,
      disableInfiniteScroll: true
    }),
    [setCurrentPage, currentPage]
  );

  const columns = useListColumns({ isVendorResourcesListTable: false, isRTL });
  const headers = useListHeaders({ isVendorResourcesListTable: false });

  const selected = {
    records: [selectedUser?.id],
    isSelectAll: false
  };

  if (!loading && error) {
    return <ErrorMessage />;
  }

  if (!loading && users.length === 0 && currentPage === 1) {
    return <NoResourcesMessage />;
  }

  return (
    <div className={containerClasses.root}>
      <ListTableThemeProvider classes={providerClasses}>
        <ListTableNavigationContext.Provider
          value={resourceAssignmentListNavigationContext}
        >
          <ListTable
            selected={selected}
            variant="table"
            records={users}
            headers={headers}
            columns={columns}
            elevation={0}
            hasMore={hasMore}
            loadMore={loadMore}
            isLoading={loading}
            onRowClick={onRowClick}
            showLoadingComponent={loading && !loadingMore}
            paginationType={PAGINATION_TYPE.NAVIGATION}
          />
        </ListTableNavigationContext.Provider>
      </ListTableThemeProvider>
    </div>
  );
};

ResourceAssignmentList.propTypes = {
  onRowClick: PropTypes.func,
  selectedUser: PropTypes.object
};

export default ResourceAssignmentList;
