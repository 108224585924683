import React, { useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash.get';
import {
  GoBackButton,
  User,
  ToggleEditButton,
  MoreMenu,
  MoreMenuOption,
  DeleteConfirmationDialog
} from '~/modules/common/components';
import { slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { PageHeaderLoading } from '~/modules/common/components/DetailsPage';
import { useDialogState } from '~/modules/common/hooks';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { canEditBillingTransactions } from '~/modules/common/enums/BillingInvoicingPermissions';
import { useClientPermissions } from '../hooks';
import ClientPageHeaderStatusChipMenu from './ClientPageHeaderStatusChipMenu';
import { ClientPageEditContext } from './ClientPageEditContext';
import { CLIENT_PAGE_HEADER_QUERY } from './graphql';
import { useCanClientBeDeleted } from './hooks';

export const HEADER_HEIGHT = 56;

export const RemoveClientLabel = <FormattedMessage id="client.removeClient" />;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0
  },
  back: { width: '1.5rem', height: '1.5rem' },
  statusContainer: {
    paddingLeft: theme.spacing(2)
  },
  name: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  spacing: {
    marginLeft: theme.spacing(2)
  }
}));

const ClientPageHeader = ({ me, slug, initialDialogState = false }) => {
  const classes = useStyles();

  const { edit, toggleEdit } = useContext(ClientPageEditContext);
  const { canEditClient } = useClientPermissions();
  const hasEditPermission = Boolean(
    canEditClient || canEditBillingTransactions({ me })
  );
  const { hasClientManagerRole } = me;
  const { loading, data, error } = useQuery(CLIENT_PAGE_HEADER_QUERY, {
    variables: { slug }
  });

  const goBackButtonClasses = useMemo(() => ({ back: classes.back }), [
    classes.back
  ]);
  const {
    open: dialogOpen,
    openDialog,
    closeDialog: onDialogCancel
  } = useDialogState(initialDialogState);

  if (error) return slugNotFound({ data: { error } }) && <RedirectToNotFound />;

  const name = get(data, 'client.name');
  const isActive = get(data, 'client.isActive');
  const clientManager = get(data, 'client.clientManager');
  const clientId = get(data, 'client.id');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const canBeDeletedCallback = useCallback(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => useCanClientBeDeleted({ slug }),
    [slug]
  );

  return loading ? (
    <PageHeaderLoading />
  ) : (
    <>
      <StickyHeader>
        <GoBackButton classes={goBackButtonClasses} to="/clients" contextual />
        {!loading && (
          <Typography
            className={classes.name}
            variant="h6"
            data-qe-id="ClientInfoHeaderTitle"
          >
            {name}
          </Typography>
        )}
        <>
          {hasEditPermission && (
            <ToggleEditButton
              edit={edit}
              onClick={toggleEdit}
              dataQeId="ClientEditButton"
            />
          )}
          {canEditClient && (
            <MoreMenu PaperProps={{ role: 'main' }}>
              <MoreMenuOption
                data-qe-id="RemoveClient"
                onClick={openDialog}
                disabled={!canEditClient}
                dense
              >
                <Typography color="secondary" variant="body2">
                  {RemoveClientLabel}
                </Typography>
              </MoreMenuOption>
            </MoreMenu>
          )}
        </>
      </StickyHeader>
      <Paper className={classes.root} square elevation={0}>
        <Grid
          spacing={0}
          className={classes.statusContainer}
          container
          alignItems="center"
        >
          <Grid item data-qe-id="ClientInfoHeaderStatus">
            {!loading && typeof isActive === 'boolean' && (
              <ClientPageHeaderStatusChipMenu
                editable={canEditClient && edit}
                clientId={clientId}
                isActive={isActive}
              />
            )}
          </Grid>
          {!loading && Boolean(hasClientManagerRole) && clientManager && (
            <Grid className={classes.spacing} item>
              <User
                dataQeId="ClientPageHeader_ManagerName"
                user={clientManager}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      {!loading && dialogOpen && (
        <DeleteConfirmationDialog
          open={dialogOpen}
          onCancel={onDialogCancel}
          objectId={clientId}
          objectType="CLIENT"
          canBeDeletedCallback={canBeDeletedCallback}
        />
      )}
    </>
  );
};

ClientPageHeader.propTypes = {
  me: PropTypes.object.isRequired,
  slug: PropTypes.string,
  initialDialogState: PropTypes.bool
};

export default ClientPageHeader;
