import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Grid, makeStyles, Button } from '@material-ui/core';
import classNames from 'classnames';
import FieldLabel from './FieldLabel';

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    ...theme.typography.subtitle1
  },
  titleSpacing: {
    paddingTop: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  projectLabel: {
    color: theme.palette.text.secondary
  },
  gridItem: {
    textAlign: 'center'
  },
  taskAllocationGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'end'
  }
}));

const availableProjectLabelValue = {
  project: (
    <span>
      <FormattedMessage id="taskAllocationEditor.project" />
    </span>
  )
};

const projectManagerMessageIds = {
  taskAllocationMessageId: 'taskAllocationEditor.taskAllocation',
  availabilityMessageId: 'taskAllocationEditor.availableProject',
  remainingMessageId: 'taskAllocationEditor.remaining'
};

const resourceManagerMessageIds = {
  taskAllocationMessageId: 'taskAllocationEditor.taskAllocation',
  availabilityMessageId: 'taskAllocationEditor.resourceAvailability',
  remainingMessageId: 'taskAllocationEditor.totalAvailability'
};

const TaskAllocationEditorSkeleton = ({
  onClose,
  canEditResourceAllocation
}) => {
  const classes = useStyles();

  const {
    taskAllocationMessageId,
    availabilityMessageId,
    remainingMessageId
  } = canEditResourceAllocation
    ? resourceManagerMessageIds
    : projectManagerMessageIds;

  return (
    <>
      <>
        {!canEditResourceAllocation && (
          <Skeleton variant="rect" width={480} height={50} />
        )}
        <FieldLabel
          messageId={taskAllocationMessageId}
          variant="subtitle1"
          className={classNames(classes.title, {
            [classes.titleSpacing]: !canEditResourceAllocation
          })}
        />
        <Grid>
          <Grid item container spacing={2} className={classes.datePicker}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" width={220} height={40} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Skeleton variant="rect" width={220} height={40} />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FieldLabel
                variant="body2"
                messageId={availabilityMessageId}
                messageValues={availableProjectLabelValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Skeleton variant="text" width={64} height={20} />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.taskAllocationGrid}>
              <FieldLabel variant="body2" messageId={taskAllocationMessageId} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton variant="text" width={64} height={20} />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FieldLabel variant="body2" messageId={remainingMessageId} />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Skeleton variant="text" width={64} height={20} />
            </Grid>
          </Grid>
        </Grid>
      </>
      <div className={classes.closeButton}>
        <Button variant="contained" onClick={onClose} size="small">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          disabled
        >
          <FormattedMessage id="taskAllocationEditor.setAllocation" />
        </Button>
      </div>
    </>
  );
};

TaskAllocationEditorSkeleton.propTypes = {
  onClose: PropTypes.func,
  canEditResourceAllocation: PropTypes.bool
};

export default TaskAllocationEditorSkeleton;
