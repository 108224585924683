import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik, useFormikContext } from 'formik';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { BILLING_COLUMN_TYPE } from '~/modules/common/enums';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import BillingCardReadOnly from './BillingCardReadOnly';
import BillingCardEditableTitle from './BillingCardEditableTitle';
import BillingCardEditable from './BillingCardEditable';
import useFormState from './hooks/useFormState';

const avatar = <RequestQuoteIcon />;
const title = <FormattedMessage id="billingCard.cardTitle" />;

export const billingCardResourceKeys = {
  cardTitle: 'billingCard.cardTitle',
  schedule: 'billingCard.title.schedule',
  paymentTermNet: 'billingCard.subTitleValue.net',
  paymentTerm: 'billingCard.subTitle.paymentTerm',
  structure: 'billingCard.title.structure',
  billLineItemsBy: 'billingCard.subTitle.billLineItemsBy',
  defaults: 'billingCard.title.defaults',
  billingCurrency: 'billingCard.subTitle.invoiceCurrency',
  taxProfile: 'billingCard.subTitle.taxProfile',
  noTaxProfile: 'client.noTaxProfile',
  invoiceTemplate: 'billingCard.subTitle.invoiceTemplate',
  noInvoiceTemplate: 'client.noInvoiceTemplate',
  description: 'billingCard.subTitle.description',
  internalNotes: 'billingCard.subTitle.internalNotes',
  showComments: 'billingCard.subTitle.showComments'
};

export const BillingCard = ({ editable, isLoading }) => {
  const {
    handleReset,
    handleSubmit,
    isValid,
    isSubmitting,
    errors
  } = useFormikContext();

  if (isLoading) return <CardLoading avatar={avatar} title={title} />;

  return (
    <EditableCard
      editable={editable}
      dataQeId="BillingCard"
      ariaLabelKey="billingCard.billingCardDialog"
    >
      <ReadOnly avatar={avatar} title={title}>
        <ReadOnlyContent>
          <>
            <BillingCardReadOnly
              billingCardResourceKeys={billingCardResourceKeys}
            />
          </>
        </ReadOnlyContent>
      </ReadOnly>
      <Edit
        saveable={isValid && !isSubmitting}
        onCancel={handleReset}
        onSave={handleSubmit}
      >
        <EditTitle>
          <BillingCardEditableTitle
            billingCardResourceKeys={billingCardResourceKeys}
          />
        </EditTitle>
        <EditContent>
          <BillingCardEditable
            billingCardResourceKeys={billingCardResourceKeys}
            isSubmitting={isSubmitting}
            errors={errors}
          />
        </EditContent>
      </Edit>
    </EditableCard>
  );
};

BillingCard.propTypes = {
  editable: PropTypes.bool,
  isLoading: PropTypes.bool
};

const BillingCardForm = ({ clientDetails, editable, isLoading }) => {
  const isPsaFPOvertimeBillingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaFPOvertimeBillingEnabled'
  });
  const {
    id,
    billingSettings: {
      billLineItemsBy,
      description,
      internalNotes,
      showComments
    } = {},
    defaultInvoicePaymentTerm,
    invoiceCurrency,
    invoiceTemplate,
    taxProfile
  } = clientDetails;

  const filteredBillLineItemOptions = billLineItemsBy?.filter(columnOption => {
    if (columnOption === BILLING_COLUMN_TYPE.EXPENSE_CODE) {
      return true;
    }
    if (
      columnOption === BILLING_COLUMN_TYPE.PAYCODE ||
      columnOption === BILLING_COLUMN_TYPE.ROLE
    ) {
      return isPsaFPOvertimeBillingEnabled;
    }

    return true;
  });

  const { onSubmit, initialValues, validationSchema } = useFormState({
    id,
    defaultInvoicePaymentTerm,
    invoiceCurrency,
    invoiceTemplate,
    taxProfile,
    billLineItemsBy: filteredBillLineItemOptions,
    description,
    internalNotes,
    showComments
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <BillingCard editable={editable} isLoading={isLoading} />
    </Formik>
  );
};

BillingCardForm.propTypes = {
  clientDetails: PropTypes.any,
  editable: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default BillingCardForm;
