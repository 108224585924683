import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useFetchProjectRequestField } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestDrawer/hooks/useFetchProjectRequestField';

const PUT_PROJECT_REQUEST_TIMELINE = gql`
  mutation putProjectRequestTimeline($input: PutProjectRequestTimelineInput!) {
    putProjectRequestTimeline(input: $input) {
      projectRequestUri
    }
  }
`;

export const usePutProjectRequestTimeline = ({ id }) => {
  const { fetchProjectRequestField } = useFetchProjectRequestField(id);
  const [putProjectRequestTimeline] = useMutation(
    PUT_PROJECT_REQUEST_TIMELINE,
    {
      onCompleted: fetchProjectRequestField
    }
  );

  return {
    putProjectRequestTimeline: async ({
      startDate,
      periodQuantity,
      periodResolution
    }) => {
      await putProjectRequestTimeline({
        variables: {
          input: {
            id,
            startDate,
            timelineDuration: { periodQuantity, periodResolution }
          }
        }
      });
    }
  };
};

export default usePutProjectRequestTimeline;
