import { makeStyles } from '@material-ui/core/styles';

export const useDateRangeStyles = makeStyles(theme => ({
  range: {
    fontSize: theme.typography.caption.fontSize
  },
  date: {
    fontSize: theme.typography.caption.fontSize
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    fontSize: theme.typography.caption.fontSize
  },
  name: {
    width: 140
  },
  cellHeader: {
    verticalAlign: 'bottom'
  },
  cell: {
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(0, 1)
  },
  projectName: {
    width: 140
  },
  project: {
    fontSize: theme.typography.caption.fontSize
  },
  range: {
    width: 170
  },
  manager: {
    width: 130
  },
  hoursWorked: {
    width: 50,
    whiteSpace: 'pre-wrap'
  },
  showMore: {
    textTransform: 'none'
  },
  title: {
    position: 'relative',
    top: '-8px',
    padding: '0 8px'
  }
}));

export default useStyles;
