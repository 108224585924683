import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden',
    maxWidth: 150
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

const ProjectHistoryUser = ({ user }) => {
  const classes = useStyles();

  return <User classes={classes} user={user} />;
};

ProjectHistoryUser.propTypes = {
  user: PropTypes.object.isRequired
};

export default ProjectHistoryUser;
