import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { get } from 'lodash';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';

export const clientSummaryQuery = gql`
  query clientBySlug($slug: String!, $dateRange: DateTimeRangeInput!) {
    client(slug: $slug) {
      id
      costAmountSummary(dateRange: $dateRange) {
        costActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      revenueAmountSummary(dateRange: $dateRange) {
        revenueActual {
          amount
          currency {
            id
            uri
            displayText
            name
            symbol
          }
        }
      }
      timeEnteredSummary(dateRange: $dateRange) {
        timeEnteredActual
      }
    }
  }
`;

export const useClientKPI = ({ slug, dateRange }) => {
  const { data, loading } = useQuery(clientSummaryQuery, {
    variables: {
      slug,
      dateRange: {
        startDate: dateToMidnightUTCString(dateRange.startDate),
        endDate: dateToMidnightUTCString(dateRange.endDate)
      }
    }
  });

  const client = get(data, 'client') || {};
  const hasProfitAmount =
    get(client, 'revenueAmountSummary.revenueActual.amount', null) !== null &&
    get(client, 'costAmountSummary.costActual.amount', null) !== null;

  return {
    costAmountSummary: client.costAmountSummary,
    revenueAmountSummary: client.revenueAmountSummary,
    profitAmountSummary: {
      profitActual: hasProfitAmount
        ? {
            amount:
              client.revenueAmountSummary.revenueActual.amount -
              client.costAmountSummary.costActual.amount,
            currency: client.costAmountSummary.costActual.currency
          }
        : null
    },
    timeEnteredSummary: client.timeEnteredSummary,
    loading
  };
};

export default useClientKPI;
