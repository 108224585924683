import React, { useState, useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { deepPure } from '~/util';
import { useMeContext } from '~/modules/me/useMeContext';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import {
  actionsWidth,
  itemWidth
} from '~/modules/common/charts/timeline/calculations';
import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import { useDialogState } from '~/modules/common/hooks';
import { useChartDisplayPeriods } from '~/modules/resourcing/common/chart/hooks';
import { useProjectResourceAllocationsByIds } from '~/modules/resourcing/common/hooks';
import { useGetTaskResourceUserAllocationsSummaryForUser } from '~/modules/common/components/TaskDrawer/common/hooks';
import { ReleaseResourceAllocationType, ResourceCostModeType } from '~/types';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { ResourceAllocationChartLeftItem2 } from '../ResourceAllocationChartLeftItem';
import { ResourceAllocationChartRightItem } from '../ResourceAllocationChartRightItem';
import { ResourceAllocationChartTimeLines } from './ResourceAllocationChartTimeLines';
import { UserTaskAssignments } from './components';
import { getUserOverDistributionDetails } from './components/UserTaskAssignments/hooks/useUserOverDistributionDetails';

const useChartRangeItemStyles = makeStyles(() => ({
  itemContainer: {
    alignItems: 'normal',
    zIndex: 4
  },
  totalContainer: {
    zIndex: 4
  }
}));

const useModel1Styles = makeStyles(() => ({
  itemContainer: {
    alignItems: 'normal',
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center'
  }
}));

const useChartRowStyles = makeStyles(theme => ({
  userRows: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper
  }
}));

const getAllocationIdsForUser = allocatedUser =>
  allocatedUser.roles.reduce(
    (acc, role) => [
      ...acc,
      ...(role.resourceAllocationReference || []).map(ref => ref.id)
    ],
    []
  );

export const getAllocationByAllocationId = entries =>
  (entries || []).reduce(
    (acc, entry) => ({
      ...acc,
      [entry.id]: entry
    }),
    {}
  );

export const ResourceAllocationChartRow2 = ({
  allocatedUser,
  autofocusedUserId,
  currentPage,
  handleEditResourceAllocation,
  handleRemoveResourceAllocation,
  isEditEnabled = true,
  loadingUserScheduleDetails,
  userActuals,
  isActualsLoading,
  projectId,
  resourceCostMode,
  setAutofocusedUserId,
  setSnackBarState,
  userScheduleDetails,
  onUserRowExpansionPanelClick,
  isUserRowExpanded
}) => {
  const classes = useChartRowStyles();
  const me = useMeContext();
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = me;

  const chartRangeItemClasses = useChartRangeItemStyles();
  const model1Classes = useModel1Styles();

  const chartRangeItemClassesFFOn = useMemo(
    () => ({
      ...chartRangeItemClasses,
      ...model1Classes
    }),
    [chartRangeItemClasses, model1Classes]
  );

  const {
    openDialog,
    closeDialog,
    open: userAllocationDialogOpen
  } = useDialogState();
  const [newResourceUser, setNewResourceUser] = useState(null);
  const onUserChange = useCallback(
    newUser => {
      if (!newUser) return;
      setNewResourceUser(newUser);
      openDialog();
    },
    [openDialog]
  );
  const {
    projectObjectPermissions: { canViewTasks }
  } = useProjectContext();
  const { scale, chartDates, dateRange } = useResourceRequestToolbarContext();
  const [{ start: chartStartDate }] = chartDates;
  const [userAssignmentsRowKey, setUserAssignmentsRowKey] = useState(
    allocatedUser.user.id
  );
  const onReleaseResourceAllocationSuccess = useCallback(
    ({ releaseType }) => {
      if (releaseType === ReleaseResourceAllocationType.Asof) {
        setUserAssignmentsRowKey(v4());
      }
    },
    [setUserAssignmentsRowKey]
  );

  const allocationIds = getAllocationIdsForUser(allocatedUser);
  const {
    loadingAllocations,
    resourceAllocations
  } = useProjectResourceAllocationsByIds({
    allocationIds,
    resourceCostMode,
    skip: !allocationIds.length
  });

  const {
    loading: userTaskAllocationsSummaryLoading,
    taskResourceUserAllocationsSummary
  } = useGetTaskResourceUserAllocationsSummaryForUser({
    userId: allocatedUser.user.id,
    projectId,
    skip: !isPsaRmpTaskAllocation1Enabled || !canViewTasks
  });

  const resourceAllocationsById = getAllocationByAllocationId(
    resourceAllocations
  );

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange: dateRange,
    scale
  });

  const {
    isUserTaskAllocationsOverDistributed,
    userOverDistributedPeriodsMap
  } = getUserOverDistributionDetails({
    userTaskAllocationsSummaryScheduleRules:
      taskResourceUserAllocationsSummary?.scheduleRules,
    resourceAllocationScheduleRules: resourceAllocations[0]?.scheduleRules,
    isPsaRmpTaskAllocation1Enabled,
    chartDisplayPeriods
  });

  return (
    <div key={allocatedUser.user.uri} className={classes.userRow}>
      <StyledChartRangeItem2
        classes={
          isPsaRmpTaskAllocation1Enabled
            ? chartRangeItemClassesFFOn
            : chartRangeItemClasses
        }
        scale={scale}
        scaleItemCount={chartDates.length}
        chartStartDate={chartStartDate}
        leftComponentWidth={actionsWidth + itemWidth * 2}
      >
        <ResourceAllocationChartLeftItem2
          isEditEnabled={isEditEnabled}
          projectId={projectId}
          allocatedUser={allocatedUser}
          currentPage={currentPage}
          onUserChange={onUserChange}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          setSnackBarState={setSnackBarState}
          autofocusedUserId={autofocusedUserId}
          setAutofocusedUserId={setAutofocusedUserId}
          onUserRowExpansionPanelClick={onUserRowExpansionPanelClick}
          isUserRowExpanded={isUserRowExpanded}
          onReleaseResourceAllocationSuccess={
            onReleaseResourceAllocationSuccess
          }
          isUserTaskAllocationsOverDistributed={
            isUserTaskAllocationsOverDistributed
          }
        />
        <ResourceAllocationChartTimeLines
          loadingAllocations={loadingAllocations}
          loadingUserScheduleDetails={loadingUserScheduleDetails}
          userActuals={userActuals}
          isActualsLoading={isActualsLoading}
          resourceAllocationsById={resourceAllocationsById}
          userScheduleDetails={userScheduleDetails}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          setSnackBarState={setSnackBarState}
          isEditSwitchEnabled={isEditEnabled}
          allocatedUser={allocatedUser}
          newResourceUser={newResourceUser}
          closeUserAllocationDialog={closeDialog}
          userAllocationDialogOpen={userAllocationDialogOpen}
          handleEditResourceAllocation={handleEditResourceAllocation}
          projectId={projectId}
          chartDisplayPeriods={chartDisplayPeriods}
        />
        <ResourceAllocationChartRightItem
          allocatedUser={allocatedUser}
          loadingAllocations={loadingAllocations}
          resourceAllocationsById={resourceAllocationsById}
        />
      </StyledChartRangeItem2>
      {isUserRowExpanded && (
        <UserTaskAssignments
          key={userAssignmentsRowKey}
          user={allocatedUser.user}
          userScheduleDetails={userScheduleDetails}
          resourceAllocationScheduleRules={
            resourceAllocations[0]?.scheduleRules
          }
          taskResourceUserAllocationsSummary={
            taskResourceUserAllocationsSummary
          }
          userTaskAllocationsSummaryLoading={userTaskAllocationsSummaryLoading}
          loadingResourceAllocation={loadingAllocations}
          userOverDistributedPeriodsMap={userOverDistributedPeriodsMap}
          chartDisplayPeriods={chartDisplayPeriods}
        />
      )}
    </div>
  );
};

ResourceAllocationChartRow2.propTypes = {
  allocatedUser: PropTypes.object.isRequired,
  isEditEnabled: PropTypes.bool,
  projectId: PropTypes.string,
  currentPage: PropTypes.number,
  handleRemoveResourceAllocation: PropTypes.func,
  handleEditResourceAllocation: PropTypes.func,
  loadingUserScheduleDetails: PropTypes.bool,
  isActualsLoading: PropTypes.bool,
  userActuals: PropTypes.array,
  resourceCostMode: PropTypes.oneOf(Object.values(ResourceCostModeType)),
  setSnackBarState: PropTypes.func,
  autofocusedUserId: PropTypes.string,
  setAutofocusedUserId: PropTypes.func,
  userScheduleDetails: PropTypes.object.isRequired,
  onUserRowExpansionPanelClick: PropTypes.func.isRequired,
  isUserRowExpanded: PropTypes.bool.isRequired
};

export default deepPure(ResourceAllocationChartRow2);
