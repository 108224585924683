import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { isoStringToObject } from '~/modules/common/dates/convert';
import {
  getEffectiveDateObject,
  compareDateObjects,
  dateAfterMinRepliconDate
} from '~/modules/common/dates/compare';
import { NoValue } from '~/modules/common/components';
import { ResourceRoleSchedule } from '~/modules/common/components/entities/role';

export const RoleFormatter = ({ record }) => {
  const roleDates = (record.effectiveRoles || []).map(
    role => role && isoStringToObject(role.effectiveDate)
  );

  const roles = record.effectiveRoles || [];

  const effectiveDate =
    roleDates.length > 0 && getEffectiveDateObject(roleDates);

  const effectiveSchedule = useMemo(
    () => ({
      effectiveDate: dateAfterMinRepliconDate(effectiveDate) && effectiveDate,
      projectRoles: effectiveDate
        ? (roles || []).filter(
            r =>
              r &&
              r.effectiveDate &&
              compareDateObjects(
                isoStringToObject(r.effectiveDate),
                effectiveDate
              ) === 0
          )
        : roles
    }),
    [effectiveDate, roles]
  );

  return roles.length > 0 ? (
    <ResourceRoleSchedule
      effectiveSchedule={effectiveSchedule}
      showTooltip
      showEffectiveDate
    />
  ) : (
    <NoValue />
  );
};

RoleFormatter.propTypes = {
  record: PropTypes.object.isRequired
};

export default RoleFormatter;
