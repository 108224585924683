import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    height: 40,
    padding: theme.spacing(1, 2)
  }
}));

export const TaskBurndownSectionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Skeleton variant="rect" height={16} />
      </div>
      <div className={classes.root}>
        <Skeleton variant="rect" height={16} />
      </div>
    </>
  );
};

export default TaskBurndownSectionSkeleton;
