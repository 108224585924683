import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Decimal } from '~/modules/common/components';
import { ResourceRequestDetailIndicator } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestItem/components/ResourceRequestDetailIndicator';

const useStyles = makeStyles(theme => ({
  noRole: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  },
  resourceRequestDetailIndicator: {
    display: 'inline-flex',
    position: 'absolute',
    paddingLeft: theme.spacing(0.5)
  }
}));

export const Title = ({
  request,
  showResourceRequestDetailIndicator = false
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <span>
        {request.role ? (
          request.role.displayText
        ) : (
          <span className={classes.noRole}>
            {intl.formatMessage({ id: 'resourceRequestTitle.anyRole' })}
          </span>
        )}
      </span>
      {request.quantity > 1 ? (
        <>
          &nbsp;×&nbsp;
          <Decimal value={request.quantity} fixedDecimalScale={false} />
        </>
      ) : null}
      {showResourceRequestDetailIndicator && (
        <div className={classes.resourceRequestDetailIndicator}>
          <ResourceRequestDetailIndicator resourceRequest={request} />
        </div>
      )}
    </>
  );
};

Title.propTypes = {
  request: PropTypes.object,
  showResourceRequestDetailIndicator: PropTypes.bool
};

export default Title;
