import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const VendorsIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="M22,21V7L12,3L2,7v14h5v-9h10v9H22z M11,19H9v2h2V19z M13,16h-2v2h2V16z M15,19h-2v2h2V19z" />
    </>
  </SvgIcon>
));

export default VendorsIcon;
