import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { StatusChipMenu } from '~/modules/common/components';
import { useChipMenuProps } from '~/modules/common/components/ChipMenu';
import { useUpdateClientStatusMutation } from './useUpdateClientStatusMutation';

const buildUpdateClientStatusOptimisticResponse = status => ({
  __typename: 'Mutation',
  updateClientStatus2: {
    __typename: 'UpdateClientStatusResult',
    status
  }
});

export const ClientPageHeaderStatusChipMenu = ({
  editable,
  clientId,
  isActive
}) => {
  const chipMenuProps = useChipMenuProps();

  const [updateClientStatus] = useUpdateClientStatusMutation({
    clientId
  });

  const { closeMenu } = chipMenuProps;

  const activateClient = useCallback(() => {
    updateClientStatus({
      variables: {
        input: {
          clientId,
          status: true
        }
      },
      optimisticResponse: buildUpdateClientStatusOptimisticResponse(true)
    });

    closeMenu();
  }, [clientId, updateClientStatus, closeMenu]);

  const inactivateClient = useCallback(() => {
    updateClientStatus({
      variables: {
        input: {
          clientId,
          status: false
        }
      },
      optimisticResponse: buildUpdateClientStatusOptimisticResponse(false)
    });

    closeMenu();
  }, [clientId, updateClientStatus, closeMenu]);

  return (
    <StatusChipMenu
      {...chipMenuProps}
      editable={editable}
      isActive={isActive}
      onActiveClick={activateClient}
      onInactiveClick={inactivateClient}
    />
  );
};

ClientPageHeaderStatusChipMenu.propTypes = {
  editable: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default ClientPageHeaderStatusChipMenu;
