import React, { useMemo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Drawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from '~/modules/common/components/Drawer';
import { useSkillLevels } from '~/modules/common/hooks';
import { notAuthorized } from '~/modules/common/nonOptimalStates';
import {
  ResourceDrawerContent,
  ResourceDrawerHeader,
  ResourceDrawerContentLoading,
  ResourceDrawerHeaderLoading,
  AccessDenied
} from './components';

import { useResourceDetails } from './hooks';

const useStyles = makeStyles(theme => ({
  headerArea: {
    padding: theme.spacing(2)
  }
}));

export const ResourceDrawer = ({ classes: classesOverride, id, onClose }) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    resourceDetails,
    loading: resourceLoading,
    error
  } = useResourceDetails({
    userSlug: id
  });

  const noViewAccess = notAuthorized({ data: { error } });

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = useCallback(() => setShowMore(!showMore), [
    showMore,
    setShowMore
  ]);

  const { maxSkillLevel, isSkillLoading } = useSkillLevels();

  const loading = isSkillLoading || resourceLoading;

  return (
    <Drawer>
      <DrawerHeader
        classes={useMemo(
          () => ({
            headerArea: classes.headerArea
          }),
          [classes.headerArea]
        )}
        onClose={onClose}
        bgColor={noViewAccess ? '#fff' : null}
      >
        {!loading && !noViewAccess && (
          <ResourceDrawerHeader
            resource={resourceDetails}
            permittedActions={resourceDetails.permittedActions}
            toggleShowMore={toggleShowMore}
            showMore={showMore}
          />
        )}
        {loading && <ResourceDrawerHeaderLoading />}
      </DrawerHeader>
      <DrawerContent>
        {!loading &&
          (noViewAccess ? (
            <AccessDenied />
          ) : (
            <ResourceDrawerContent
              resource={resourceDetails}
              maxSkillLevel={maxSkillLevel}
              showMore={showMore}
            />
          ))}
        {loading && <ResourceDrawerContentLoading />}
      </DrawerContent>
      <DrawerFooter />
    </Drawer>
  );
};

ResourceDrawer.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default withRouter(ResourceDrawer);
