import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const TOTAL_RESOURCE_ESTIMATE_HOURS_FOR_TASK_QUERY = gql`
  query GetTotalResourceEstimateHoursForTask($taskId: String!) {
    task(taskId: $taskId) {
      resourceEstimatesSummary {
        totalResourceEstimateHours
      }
    }
  }
`;

export const useGetTotalResourceEstimateHoursForTask = ({ taskId, skip }) => {
  const { data, error, loading } = useQuery(
    TOTAL_RESOURCE_ESTIMATE_HOURS_FOR_TASK_QUERY,
    {
      variables: { taskId },
      fetchPolicy: 'network-only',
      skip
    }
  );

  return {
    error,
    loading,
    totalResourceEstimateHours:
      data?.task?.resourceEstimatesSummary?.totalResourceEstimateHours
  };
};

export default useGetTotalResourceEstimateHoursForTask;
