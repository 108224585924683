import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import { useHasPermission } from '~/modules/common/permissions';
import useRoles from '~/modules/common/hooks/useRoles';
import { CreatableAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import {
  AddRoleDialog,
  RoleDropdownItem
} from '~/modules/common/components/RoleDropdown';
import MoreResult from '~/modules/common/components/MoreResult';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import useRoleDropdownEventHandlers from './hooks/useRoleDropdownEventHandlers';

const filter = createFilterOptions();
const getOptionLabel = o => o.displayText;
const getOptionSelected = (o, v) => o.id === v.id;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const RoleDropdown = ({
  classes,
  value,
  onValueChange,
  disabled,
  errorMessage,
  dataQeId,
  enableAutoFocus
}) => {
  const intl = useIntl();
  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const { hasMore, isLoading, defaultOptions: roles } = useRoles({
    searchTerm: value ? '' : searchTerm,
    includeCurrentRate: false
  });
  const { open, closeDialog, openDialog } = useDialogState();
  const [newRoleName, setNewRoleName] = useState('');

  const {
    openDialogAndSetInitialName,
    handleOnChange,
    onCancel
  } = useRoleDropdownEventHandlers({
    openDialog,
    setNewRoleName,
    onValueChange
  });

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);

      if (
        canCreateRole &&
        params.inputValue !== '' &&
        !options.some(o => o.displayText === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          displayText: params.inputValue
        });
      }

      return filtered;
    },
    [canCreateRole]
  );

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <RoleDropdownItem
          option={option}
          onCreate={openDialogAndSetInitialName}
        />
      ),
    [openDialogAndSetInitialName]
  );

  return (
    <>
      <CreatableAutocomplete
        classes={classes}
        hasMore={hasMore}
        loading={isLoading}
        fullWidth
        canAddNewOption={canCreateRole}
        dataQeId={dataQeId}
        clearOnBlur
        value={value}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        options={roles}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={filterOptions}
        inputLabel={
          value
            ? null
            : intl.formatMessage({
                id: 'projectRequest.resourceRequirement.selectAResource'
              })
        }
        ariaLabel={intl.formatMessage({
          id: 'projectRequest.resourceRequirement.roleDropdown'
        })}
        renderOption={renderOption}
        getOptionDisabled={getOptionDisabled}
        optionTypeText={intl.formatMessage({
          id: 'moreOptions.roles'
        })}
        disabled={disabled}
        errorMessage={errorMessage}
        showError={Boolean(errorMessage)}
        autoFocus={enableAutoFocus}
      />
      <AddRoleDialog
        open={open}
        onClose={closeDialog}
        onChange={onValueChange}
        initialName={newRoleName}
        onCancel={onCancel}
      />
    </>
  );
};

RoleDropdown.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
  onValueChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  dataQeId: PropTypes.string,
  enableAutoFocus: PropTypes.bool
};

export default RoleDropdown;
