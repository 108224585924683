import { useState, useCallback } from 'react';
import { useUpdateClientMutation } from '~/modules/clients/graphql';

export const useUpdateClientOnSubmit = ({ mapValuesOnSubmit, onClose }) => {
  const [error, setError] = useState();

  const clearError = useCallback(() => setError(null), [setError]);

  const [updateClient] = useUpdateClientMutation();

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const payload = mapValuesOnSubmit ? mapValuesOnSubmit(values) : values;

      try {
        await updateClient({
          variables: {
            client: payload
          }
        });
      } catch (err) {
        setError(err);
      } finally {
        setSubmitting(false);
        onClose();
      }
    },
    [updateClient, setError, onClose, mapValuesOnSubmit]
  );

  return { onSubmit, error, clearError };
};
