export const PSA_DEEPLINK_TEMPLATE =
  '/?externalApplicationId=psa-ui&externalResourceUri=';

// base64 encode externalResourceUri value to avoid double URL encoding and leverage /api/deeplink/:base64 to unravel.
export const createPsaDeepLink = url =>
  encodeURIComponent(`${PSA_DEEPLINK_TEMPLATE}/api/deeplink/${btoa(url)}`);

export const getCurrentUrl = () => {
  let currentUrl = window.location.pathname;

  if (window.location.search) {
    currentUrl = `${currentUrl}${window.location.search}`;
  }

  if (window.location.hash) {
    currentUrl = `${currentUrl}${window.location.hash}`;
  }

  return currentUrl;
};

export const redirectToLogout = logoutHref => () => {
  clearSessionStorage();
  window.location = logoutHref;

  return false;
};

export const redirectToSessionLogout = uiRootPath => () => {
  clearSessionStorage();
  window.location = `${uiRootPath}/Redirect/SessionTimeoutRedirect?requestUrl=${createPsaDeepLink(
    getCurrentUrl()
  )}`;

  return false;
};

export const clearSessionStorage = () => {
  window.sessionStorage.clear();
};
