import { useMuiDrawerOverriddenProps } from '~/modules/common/hooks';
import TaskDrawer from './TaskDrawer';

const taskDrawerRoute = {
  path: '#/task/:taskId',
  component: TaskDrawer,
  useMuiDrawerOverriddenProps
};

export default taskDrawerRoute;
