import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { ProjectTeamMemberType } from '~/types';

export const projectResourcesAndTeamMembersQuery2 = gql`
  query projectResourcesAndTeamMembersQuery2(
    $projectSlug: String!
    $page: Int!
    $pageSize: Int!
    $paginationToken: PaginationTokenInput
    $searchTerm: String
    $teamMemberTypes: [ProjectTeamMemberType!]
  ) {
    project(projectSlug: $projectSlug) {
      id
      pageOfTeamMembers(
        page: $page
        pageSize: $pageSize
        teamMemberFilter: {
          searchTerm: $searchTerm
          teamMemberTypes: $teamMemberTypes
        }
      ) {
        user {
          id
          uri
          displayText
        }
      }
      pageOfResources(
        paginationToken: $paginationToken
        pageSize: $pageSize
        filter: { searchTerm: $searchTerm }
      ) {
        items {
          id
          displayText
          projectRoles {
            isPrimary
            projectRole {
              uri
              name
            }
          }
        }
        paginationToken
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 100;

export const usePageOfProjectResources = ({
  projectSlug,
  searchTerm = '',
  pageSize,
  skip = false
}) => {
  const { loading, error, data } = useQuery(
    projectResourcesAndTeamMembersQuery2,
    {
      fetchPolicy: 'network-only',
      variables: {
        projectSlug,
        page: 1,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
        paginationToken: null,
        searchTerm,
        teamMemberTypes: [ProjectTeamMemberType.User]
      },
      context: {
        debounceKey: 'project-resource-and-teammembers',
        debounceTimeout: 250
      },
      skip
    }
  );

  const pageOfResources = data?.project?.pageOfResources?.items || [];
  const teamMembers =
    (data?.project?.pageOfTeamMembers || []).map(t => t.user) || [];

  const combinedUsers = [
    ...pageOfResources.map(u => ({
      ...u,
      projectRoles: u.projectRoles.filter(r => r)
    })),
    ...teamMembers.map(m => ({ ...m, isUnusedTeamMember: true }))
  ].filter(u => u);

  const pageToken = data?.project?.pageOfResources?.paginationToken;

  return {
    loading,
    users: combinedUsers,
    error,
    hasMore: Boolean(pageToken)
  };
};

export default usePageOfProjectResources;
