import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { NoValue, User } from '~/modules/common/components';
import Date from '~/modules/common/components/Date';
import { TASK_STATUS } from '~/modules/common/enums';
import Decimal from '~/modules/common/components/Decimal';
import { RoleName } from '~/modules/common/components/entities/role';
import ReadOnlyContainer from '~/modules/common/components/ReadOnlyContainer';
import ExtensionFieldValueText from '~/modules/extensionFields/components/ExtensionFieldValueText';
import { useMeContext } from '~/modules/me';
import { ReadOnlyCustomFieldValue } from './components';
import TaskDrawerGroupsReadOnly from './TaskDrawerGroupsReadOnly';

const useStyles = makeStyles({
  label: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize
  },
  expanded: {
    backgroundColor: theme.palette.background.default
  },
  details: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginBottom: 'auto'
  },
  chip: {
    backgroundColor: theme.palette.text.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5)
  },
  select: {
    paddingLeft: '5px',
    paddingTop: theme.spacing(1)
  },
  noValue: { fontSize: theme.typography.body2.fontSize },
  roleName: {
    fontSize: theme.typography.body2.fontSize
  },
  description: {
    whiteSpace: 'pre-wrap',
    hyphens: 'auto',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  },
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    display: 'inline'
  },
  container: {
    overflow: 'hidden',
    marginTop: theme.spacing(1.5)
  },
  name: {
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    paddingLeft: theme.spacing(1) - 1
  }
});

const taskHeaderDetailsLabels = ({
  isExpenseProductEnabled,
  timeAndExpenseEntryTypeUri,
  costTypeUri
}) => ({
  startDate: <FormattedMessage id="taskDrawerEdit.startDate" />,
  endDate: <FormattedMessage id="taskDrawerEdit.endDate" />,
  role: <FormattedMessage id="taskDrawerEdit.taskRole" />,
  taskOwner: <FormattedMessage id="taskDrawerEdit.taskOwner" />,
  initialEstimatedHours: (
    <FormattedMessage id="taskDrawerEdit.initialEstimatedHours" />
  ),
  timeAndExpenseEntryOptionUri: (
    <FormattedMessage
      id={`timeAndExpenseEntryOptions.${timeAndExpenseEntryTypeUri}`}
    />
  ),
  costTypeUri: <FormattedMessage id={`projectFinancialsCard.${costTypeUri}`} />,
  costType: <FormattedMessage id="taskDrawer.costType" />,
  description: <FormattedMessage id="taskDrawerEdit.description" />,
  timeAndExpenseEntryOptionYes: (
    <FormattedMessage id="timeAndExpenseEntryOptions.yes" />
  ),
  timeEntry: (
    <FormattedMessage
      id={
        isExpenseProductEnabled
          ? 'taskDrawerEdit.timeAndExpenseEntryAllowed'
          : 'taskDrawerEdit.timeEntryAllowed'
      }
    />
  ),
  groupTimesheetAccess: (
    <FormattedMessage id="taskDrawerEdit.groupTimesheetAccess" />
  )
});

export const TaskDrawerHeaderInfoBarExpanded = ({
  task,
  expandedInitialState = false,
  visibleCustomFieldDefinitions,
  customFields,
  hasCostType,
  templateSettings
}) => {
  const {
    id,
    uri,
    displayText,
    featureFlags: { isPsaPrpPsaPpmMergerEnabled },
    isRolledUpTaskEstimateCalculationMethodEnabled,
    isExpenseProductEnabled,
    hasViewProjectBillingOptions
  } = useMeContext();

  const {
    startDate,
    endDate,
    taskStatus,
    assignedUser,
    assignedRole,
    extensionFieldValues,
    initialEstimatedHours,
    timeAndExpenseEntryType,
    isTimeEntryAllowed,
    costTypeUri,
    description,
    project,
    rolledUpSummary
  } = task;

  const hasStartAndEndDate = startDate && endDate;
  const actualHours = rolledUpSummary?.actualHours;
  const rolledUpTaskStatus =
    taskStatus === TASK_STATUS.COMPLETED
      ? TASK_STATUS.COMPLETED
      : actualHours
      ? TASK_STATUS.INPROGRESS
      : TASK_STATUS.NOTSTARTED;

  const classes = useStyles({
    status: isRolledUpTaskEstimateCalculationMethodEnabled
      ? rolledUpTaskStatus
      : taskStatus,
    hasStartAndEndDate,
    isRolledUpTaskEstimateCalculationMethodEnabled
  });

  const canViewProject = Boolean(
    project?.permittedActionUris?.includes(
      'urn:replicon:project-action:view-project'
    )
  );

  const canViewCostType = isPsaPrpPsaPpmMergerEnabled
    ? hasCostType &&
      Boolean(
        project?.permittedActionUris?.includes(
          'urn:replicon:project-action:view-cost-type'
        )
      )
    : Boolean(
        project?.permittedActionUris?.includes(
          'urn:replicon:project-action:view-cost-type'
        )
      );

  const noValueClasses = useMemo(
    () => ({
      root: classes.noValue
    }),
    [classes.noValue]
  );

  const taskOwner = useMemo(
    () => (canViewProject ? assignedUser : { id, uri, displayText }),
    [canViewProject, assignedUser, id, uri, displayText]
  );

  const canViewEstimate = Boolean(
    project?.permittedActionUris?.includes(
      'urn:replicon:project-action:view-project-estimates'
    )
  );

  const taskDetailsLabels = taskHeaderDetailsLabels({
    isExpenseProductEnabled,
    timeAndExpenseEntryTypeUri: timeAndExpenseEntryType?.uri,
    costTypeUri
  });

  const hasViewProjectBillingOption = isPsaPrpPsaPpmMergerEnabled
    ? templateSettings?.hasBilling && hasViewProjectBillingOptions
    : hasViewProjectBillingOptions;

  const selectedTimeAndExpenseEntryType = useMemo(() => {
    if (!hasViewProjectBillingOption && isTimeEntryAllowed)
      return taskDetailsLabels.timeAndExpenseEntryOptionYes;

    if (timeAndExpenseEntryType && timeAndExpenseEntryType.uri)
      return taskDetailsLabels.timeAndExpenseEntryOptionUri;

    return '';
  }, [
    hasViewProjectBillingOption,
    isTimeEntryAllowed,
    taskDetailsLabels.timeAndExpenseEntryOptionYes,
    taskDetailsLabels.timeAndExpenseEntryOptionUri,
    timeAndExpenseEntryType
  ]);

  return (
    <div className={classes.expanded}>
      <Divider />
      <Grid container spacing={1} className={classes.details}>
        <Grid item xs={6}>
          <ReadOnlyContainer label={taskDetailsLabels.startDate}>
            <Date value={startDate} />
          </ReadOnlyContainer>
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyContainer label={taskDetailsLabels.endDate}>
            <Date value={endDate} />
          </ReadOnlyContainer>
        </Grid>
        {timeAndExpenseEntryType && (
          <Grid item xs={6}>
            <ReadOnlyContainer label={taskDetailsLabels.timeEntry}>
              <Typography variant="body2">
                {selectedTimeAndExpenseEntryType}
              </Typography>
            </ReadOnlyContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyContainer label={taskDetailsLabels.role}>
            {assignedRole ? (
              <RoleName
                role={assignedRole}
                showTooltip={false}
                showRate={false}
                className={classes.roleName}
              />
            ) : (
              <NoValue classes={noValueClasses} />
            )}
          </ReadOnlyContainer>
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyContainer label={taskDetailsLabels.taskOwner}>
            {taskOwner ? (
              <User user={taskOwner} />
            ) : (
              <NoValue classes={noValueClasses} />
            )}
          </ReadOnlyContainer>
        </Grid>
        {canViewEstimate && (
          <>
            <Grid item xs={12}>
              <ReadOnlyContainer
                label={taskDetailsLabels.initialEstimatedHours}
              >
                <Typography variant="body2">
                  <Decimal value={initialEstimatedHours} />
                </Typography>
              </ReadOnlyContainer>
            </Grid>
          </>
        )}
        {canViewCostType && (
          <Grid item xs={6}>
            <ReadOnlyContainer label={taskDetailsLabels.costType}>
              <Typography variant="body2" data-qe-id="costType">
                {costTypeUri ? taskDetailsLabels.costTypeUri : <NoValue />}
              </Typography>
            </ReadOnlyContainer>
          </Grid>
        )}
        {task.timesheetAccessAssignments.length > 0 && (
          <Grid item xs={12}>
            <ReadOnlyContainer label={taskDetailsLabels.groupTimesheetAccess}>
              <TaskDrawerGroupsReadOnly
                assignments={task.timesheetAccessAssignments}
                key="group-timesheet-access-read-only"
              />
            </ReadOnlyContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyContainer label={taskDetailsLabels.description}>
            <Typography variant="body2" className={classes.description}>
              {description || <NoValue />}
            </Typography>
          </ReadOnlyContainer>
        </Grid>
        {(visibleCustomFieldDefinitions || []).map(definition => (
          <Grid key={definition.uri} item xs={12} sm={6}>
            <ReadOnlyContainer label={definition.displayText}>
              <ReadOnlyCustomFieldValue
                definition={definition}
                value={customFields[definition.uri]}
              />
            </ReadOnlyContainer>
          </Grid>
        ))}
        {(extensionFieldValues || []).map(e => (
          <Grid item xs={12} sm={6} key={e.definition.id}>
            <ReadOnlyContainer label={e.definition.displayText}>
              <Typography variant="body2" className={classes.description}>
                <ExtensionFieldValueText extensionFieldValue={e} />
              </Typography>
            </ReadOnlyContainer>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

TaskDrawerHeaderInfoBarExpanded.propTypes = {
  task: PropTypes.object.isRequired,
  expandedInitialState: PropTypes.bool,
  visibleCustomFieldDefinitions: PropTypes.array,
  customFields: PropTypes.object,
  hasCostType: PropTypes.bool,
  templateSettings: PropTypes.object
};

export default TaskDrawerHeaderInfoBarExpanded;
