import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNewSharp';
import { redirectToLogout } from '../redirectToLogout';

const logoutFromMobileApp = () => {
  window.postMessage(JSON.stringify({ action: 'LOGOUT_MOBILE_APP' }));

  return false;
};

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(6),
    paddingLeft: theme.spacing(0.5)
  }
}));

export const LogoutMenuItem = ({
  me,
  classes: classesOverride,
  isMobileApp
}) => {
  const classes = useStyles({ classes: classesOverride });

  const listItemClasses = useMemo(() => ({ primary: classes.primary }), [
    classes.primary
  ]);

  const logoutLabel = <FormattedMessage id="menu.logout" />;

  return (
    <MenuItem
      onClick={
        isMobileApp ? logoutFromMobileApp : redirectToLogout(me.logoutHref)
      }
      classes={classes}
    >
      <ListItemIcon className={classes.icon}>
        <PowerSettingsNew />
      </ListItemIcon>
      <ListItemText primary={logoutLabel} classes={listItemClasses} />
    </MenuItem>
  );
};

LogoutMenuItem.propTypes = {
  me: PropTypes.object,
  classes: PropTypes.object,
  isMobileApp: PropTypes.bool
};

export default LogoutMenuItem;
