import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { RemoveCircleSharp } from '@material-ui/icons';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import { NumberInput } from '~/modules/common/components';
import RoleDropdown from './RoleDropdown';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  deleteIcon: {
    alignSelf: 'start',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px 12px 6px'
    }
  }
}));

const useRoleDropdownStylesOverride = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(35),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '52%'
    }
  },
  inputRoot: {
    paddingTop: `${theme.spacing(1.4375)}px !important`,
    paddingBottom: `${theme.spacing(1.0625)}px !important`
  }
}));

const useNumberInputStylesOverride = makeStyles(theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(37.5),
    paddingLeft: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      flexBasis: '30%',
      paddingLeft: theme.spacing(1.25)
    }
  },
  input: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.625)
  }
}));

export const ResourceAssignment = ({
  entryIndex,
  resourceAssignment,
  onChangeHandlers,
  canEditPlanningData,
  errors,
  isSubmitting,
  enableAutoFocus
}) => {
  const roleValidationError = get(
    errors,
    `resourceAssignments[${entryIndex}].role`
  );

  const quantityValidationError = get(
    errors,
    `resourceAssignments[${entryIndex}].quantity`
  );

  const classes = useStyles({
    hasErrors: roleValidationError || quantityValidationError
  });
  const roleDropdownClassesOverride = useRoleDropdownStylesOverride();
  const numberInputClassesOverride = useNumberInputStylesOverride();

  const { formatMessage } = useIntl();
  const {
    onRoleChange,
    onQuantityChange,
    onRemoveResourceAssignment,
    onQuantityBlur
  } = onChangeHandlers;

  const roleChange = useCallback(value => onRoleChange(value, entryIndex), [
    entryIndex,
    onRoleChange
  ]);

  const quantityChange = useCallback(
    e => onQuantityChange(e.target.value, entryIndex),
    [entryIndex, onQuantityChange]
  );

  const quantityBlur = useCallback(() => onQuantityBlur(entryIndex), [
    entryIndex,
    onQuantityBlur
  ]);

  const removeResourceAssignmentEntryHandler = useCallback(
    () =>
      onRemoveResourceAssignment({
        id: resourceAssignment.id,
        rowId: resourceAssignment.rowId
      }),
    [
      onRemoveResourceAssignment,
      resourceAssignment.id,
      resourceAssignment.rowId
    ]
  );

  return (
    <>
      <div className={classes.container}>
        <RoleDropdown
          classes={roleDropdownClassesOverride}
          value={resourceAssignment.role}
          onValueChange={roleChange}
          disabled={!canEditPlanningData || isSubmitting}
          errorMessage={roleValidationError}
          dataQeId={`ResourceAssignment_Role_${entryIndex}`}
          enableAutoFocus={enableAutoFocus}
        />
        <NumberInput
          classes={numberInputClassesOverride}
          value={resourceAssignment.quantity}
          error={Boolean(quantityValidationError)}
          helperText={quantityValidationError}
          onValueChange={quantityChange}
          onBlur={quantityBlur}
          disabled={!canEditPlanningData || isSubmitting}
          align="left"
          ariaLabel={formatMessage({
            id: 'projectRequest.resourceRequirement.resourceCount'
          })}
          dataQeId={`ResourceAssignment_Quantity_${entryIndex}`}
        />
        {canEditPlanningData && (
          <IconButton
            onClick={removeResourceAssignmentEntryHandler}
            data-qe-id={`ResourceAssignment_Remove_${entryIndex}`}
            className={classes.deleteIcon}
            disabled={isSubmitting}
            aria-label={formatMessage({
              id: 'projectRequest.resourceRequirement.delete'
            })}
          >
            <RemoveCircleSharp />
          </IconButton>
        )}
      </div>
    </>
  );
};
ResourceAssignment.propTypes = {
  entryIndex: PropTypes.number,
  resourceAssignment: PropTypes.object,
  onChangeHandlers: PropTypes.object,
  canEditPlanningData: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  enableAutoFocus: PropTypes.bool
};
export default ResourceAssignment;
