import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import {
  useNotificationPolicies,
  usePutNotificationPoliciesOnSubmit
} from './hooks';
import {
  NotificationSettingsDialogActions,
  NotificationSettingsDialogContent
} from './components';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px 2vh'
    }
  },
  root: {
    ...theme.typography.body1,
    display: 'grid',
    height: '100%',
    gridTemplateColumns: [
      [
        `minmax(${theme.spacing(20)}px, auto)`,
        `repeat(3, ${theme.spacing(8)}px)`
      ]
    ],
    gridTemplateRows: 'auto',
    gridColumnGap: 0,
    gridRowGap: 0,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      justifyContent: 'normal',
      gridTemplateColumns: [
        [`minmax(auto, auto)`, `repeat(3, ${theme.spacing(8)}px)`]
      ]
    }
  },
  title: {
    margin: theme.spacing(0, 3)
  }
}));

export const NotificationSettingsDialog = ({ open, closeDialog }) => {
  const fullScreen = useIsBreakpointDown('xs');

  const classes = useStyles();
  const { notificationPolicies, loading } = useNotificationPolicies();

  const initialValues = useMemo(() => ({ notificationPolicies }), [
    notificationPolicies
  ]);

  const { onSubmit } = usePutNotificationPoliciesOnSubmit({
    closeDialog
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="entity-confirmation-dialog-title"
        aria-describedby="entity-confirmation-dialog-description"
        maxWidth="xs"
        fullScreen={fullScreen}
      >
        <DialogTitle
          id="entity-confirmation-dialog-title"
          className={classes.title}
        >
          <FormattedMessage id="notificationSettingsDialog.title" />
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          id="entity-confirmation-dialog-description"
        >
          <div className={classes.root}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <NotificationSettingsDialogContent />
            )}
          </div>
        </DialogContent>
        <NotificationSettingsDialogActions onClose={closeDialog} />
      </Dialog>
    </Formik>
  );
};

NotificationSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired
};

export default NotificationSettingsDialog;
