import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TaskSummaryContext } from './TaskSummaryContext';

const TaskSummaryContextProvider = ({ children }) => {
  const [taskSummaryLoading, setTaskSummaryLoading] = useState(false);

  const value = useMemo(
    () => ({
      taskSummaryLoading,
      setTaskSummaryLoading
    }),
    [taskSummaryLoading, setTaskSummaryLoading]
  );

  return (
    <TaskSummaryContext.Provider value={value}>
      {children}
    </TaskSummaryContext.Provider>
  );
};

TaskSummaryContextProvider.propTypes = {
  children: PropTypes.node
};

export default TaskSummaryContextProvider;
