import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NoDataItem } from '~/modules/common/components';
import User from './User';
import ClientInfoDetail from './ClientInfoDetail';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(0.25, 0),
    overflow: 'hidden'
  }
}));

export const noClientRepText = (
  <NoDataItem>
    <FormattedMessage id="client.noClientRepresentatives" />
  </NoDataItem>
);

export const ClientInfoClientRepSection = ({ clientRepresentatives }) => {
  const classes = useStyles();

  return clientRepresentatives.length > 0 ? (
    <List className={classes.root} dense disablePadding>
      {clientRepresentatives.map(user => (
        <ListItem
          className={classes.listItem}
          key={user.id}
          dense
          disableGutters
          data-qe-id="ClientRepresentativeListItem"
        >
          <User user={user} />
        </ListItem>
      ))}
    </List>
  ) : (
    <ClientInfoDetail detail={noClientRepText} />
  );
};

ClientInfoClientRepSection.propTypes = {
  clientRepresentatives: PropTypes.array.isRequired
};

export default ClientInfoClientRepSection;
