import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ArrowIcon from '@material-ui/icons/ArrowForwardSharp';
import { Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Decimal from '~/modules/common/components/Decimal';
import DateRange from '~/modules/common/components/DateRange';
import TaskStatusBadge from '../TaskStatusBadge';

export const NotStartedTaskHoursRow = ({ facts, classes }) => {
  return (
    <div className={classNames(classes.row, classes.plan)} role="row">
      <div className={classNames(classes.title, classes.label)} role="cell">
        <FormattedMessage id="taskStatus.estimatedHours" />
      </div>
      <div className={classes.value} role="cell">
        <Decimal className={classes.fact} value={facts.planned.hours} />
      </div>
    </div>
  );
};

NotStartedTaskHoursRow.propTypes = {
  facts: PropTypes.object,
  classes: PropTypes.object
};

export const NotStartedTaskDateRow = ({ facts, classes }) => {
  const dateRangeClasses = useMemo(() => ({ date: classes.fact }), [
    classes.fact
  ]);

  return (
    <div className={classNames(classes.row, classes.plan)} role="row">
      <div
        data-qe-id="Status_PlannedDate"
        className={classNames(classes.title, classes.label)}
        role="cell"
      >
        <FormattedMessage id="taskStatus.plannedDates" />
      </div>
      <div className={classNames(classes.value)} role="cell">
        <DateRange
          classes={dateRangeClasses}
          start={facts.planned.date.start}
          end={facts.planned.date.end}
        />
      </div>
    </div>
  );
};

NotStartedTaskDateRow.propTypes = {
  facts: PropTypes.object,
  classes: PropTypes.object
};

export const InProgressTaskHoursRow = ({ facts, classes, canViewEstimate }) => {
  return (
    <div className={classNames(classes.row, classes.plan)} role="row">
      <div className={classNames(classes.title, classes.label)} role="cell">
        <FormattedMessage id="taskStatus.actualHours" />
      </div>
      <div data-qe-id="Status_TotalHours" className={classes.value} role="cell">
        <Decimal className={classes.fact} value={facts.actual.hours} />
        {canViewEstimate && (
          <>
            <ArrowIcon className={classes.arrow} />
            <Decimal
              className={classNames(
                classes.fact,
                classes.estimatedPart,
                facts.estimated.delta > 0 ? classes.overPlan : classes.onPlan
              )}
              value={facts.estimated.hours}
            />
            <sup className={classes.projectedMark}>†</sup>
            {facts.estimated.delta > 0 && (
              <Typography
                component="span"
                className={classNames(classes.fact, classes.diffNegative)}
              >
                +<Decimal value={facts.estimated.delta} />
              </Typography>
            )}
          </>
        )}
      </div>
    </div>
  );
};

InProgressTaskHoursRow.propTypes = {
  facts: PropTypes.object,
  classes: PropTypes.object,
  canViewEstimate: PropTypes.bool
};

export const InProgressTaskDateRow = ({ facts, classes, canViewEstimate }) => {
  const dateRangeClasses = useMemo(
    () => ({
      range:
        facts.estimated.date.delta > 0
          ? classes.dateRangeOverPlan
          : classes.dateRangeOnPlan,
      date: classes.fact
    }),
    [
      classes.fact,
      classes.dateRangeOverPlan,
      classes.dateRangeOnPlan,
      facts.estimated.date.delta
    ]
  );

  const msgValues = useMemo(
    () => ({
      days: facts.estimated.date.delta
    }),
    [facts.estimated.date.delta]
  );

  return (
    <div className={classNames(classes.row)} role="row">
      <div
        data-qe-id="Status_PlannedDate"
        className={classNames(classes.title, classes.label)}
        role="cell"
      >
        <FormattedMessage id="taskStatus.actualDates" />
      </div>
      <div
        data-qe-id="Status_Estimated_EndDate"
        className={classNames(classes.value, classes.dates)}
        role="cell"
      >
        <DateRange
          classes={dateRangeClasses}
          start={facts.actual.date.start}
          end={facts.estimated.date.end}
        />
        <sup className={classes.projectedMark}>†</sup>
        {facts.estimated.date.delta > 0 && (
          <Typography
            component="span"
            className={classNames(classes.fact, classes.diffNegative)}
          >
            <FormattedMessage
              id="taskStatus.additionalDays"
              values={msgValues}
            />
          </Typography>
        )}
      </div>
    </div>
  );
};

InProgressTaskDateRow.propTypes = {
  facts: PropTypes.object,
  classes: PropTypes.object,
  canViewEstimate: PropTypes.bool
};

export const CompleteTaskHoursRow = ({ facts, classes }) => {
  return (
    <div className={classes.row} role="row">
      <div
        className={classNames(classes.title, classes.label)}
        role="rowheader"
      >
        <FormattedMessage id="taskStatus.actualHours" />
      </div>
      <div data-qe-id="Status_TotalHours" className={classes.value} role="cell">
        <Decimal
          className={classNames(
            classes.fact,
            facts.actual.delta > 0 && classes.overPlan
          )}
          value={facts.actual.hours}
        />
      </div>
    </div>
  );
};

CompleteTaskHoursRow.propTypes = {
  facts: PropTypes.object,
  classes: PropTypes.object
};

export const CompleteTaskDateRow = ({ facts, classes }) => {
  const dateRangeClasses = useMemo(
    () => ({
      range: facts.actual.date.delta > 0 && classes.dateRangeOverPlan,
      date: classes.fact
    }),
    [classes.fact, classes.dateRangeOverPlan, facts.actual.date.delta]
  );

  const msgValues = useMemo(
    () => ({
      days: facts.actual.date.delta
    }),
    [facts.actual.date.delta]
  );

  return (
    <div className={classes.row} role="row">
      <div
        data-qe-id="Status_PlannedDate"
        className={classNames(classes.title, classes.label)}
        role="cell"
      >
        <FormattedMessage id="taskStatus.actualDates" />
      </div>
      <div className={classNames(classes.value, classes.dates)} role="cell">
        <DateRange
          classes={dateRangeClasses}
          start={facts.actual.date.start}
          end={facts.actual.date.end}
        />
        {facts.actual.date.delta > 0 && (
          <Typography
            component="span"
            className={classNames(classes.fact, classes.diffNegative)}
          >
            <FormattedMessage
              id="taskStatus.additionalDays"
              values={msgValues}
            />
          </Typography>
        )}
      </div>
    </div>
  );
};

CompleteTaskDateRow.propTypes = {
  facts: PropTypes.object,
  classes: PropTypes.object
};

export const EstimateAtCompletion = ({ classes }) => (
  <div
    className={classNames(
      classes.estimateAtCompletion,
      classes.label,
      classes.subtext
    )}
  >
    <sup className={classes.projectedMark}>†</sup>
    <FormattedMessage id="taskStatus.estimatedAtCompletion" />
  </div>
);

EstimateAtCompletion.propTypes = {
  classes: PropTypes.object
};

export const TaskStatus = ({ task, classes }) => (
  <div className={classNames(classes.row, classes.header)} role="row">
    <div
      className={classNames(classes.title, classes.label, classes.status)}
      role="cell"
    >
      <TaskStatusBadge status={task.taskStatus} progressStatus={task.status} />
    </div>
    <div className={classNames(classes.cell, classes.placeHolder)}></div>
  </div>
);

TaskStatus.propTypes = {
  task: PropTypes.object,
  classes: PropTypes.object
};
