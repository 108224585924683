/**
 * Batches together concurrent Apollo queries made within a time interval and
 * sends them together. This allows us to execute larger workloads on a single lambda
 * and load data faster.
 *
 * If a query needs to be executed in its own batch or distinctly from other operations,
 * the operation can be prefixed with the key "Eager_" and it will be run on its own.
 */

import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { v4 as uuid } from 'uuid';

export const EAGER_KEY = 'Eager_';
export const DEFAULT_KEY = 'default';

export const batchKeyGenerator = operation =>
  operation.operationName && operation.operationName.startsWith(EAGER_KEY)
    ? uuid()
    : DEFAULT_KEY;

const httpLink = new BatchHttpLink({
  uri: '/graphql',
  batchKey: batchKeyGenerator
});

export default httpLink;
