import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const notificationPolicyQuery = gql`
  query GetNotificationPolicyForUser {
    me {
      id
      notificationPolicies {
        id
        userUri
        name
        eventType
        config {
          isWebNotificationEnabled
          isEmailNotificationEnabled
          isPushNotificationEnabled
        }
        enabled
      }
    }
  }
`;

const useNotificationPolicies = () => {
  const { loading, error, data } = useQuery(notificationPolicyQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const notificationPolicies = get(data, 'me.notificationPolicies') || [];

  return {
    loading,
    error,
    notificationPolicies
  };
};

export default useNotificationPolicies;
