import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useRouteMatch } from 'react-router-dom';
import get from 'lodash.get';

export const GET_PROJECT_REFERENCE = gql`
  query GetProjectReferenceFromSlug($projectSlug: String!) {
    project(projectSlug: $projectSlug) {
      id
      name
    }
  }
`;

export const useProjectReference = () => {
  const {
    params: { slug }
  } = useRouteMatch();
  const { data, loading, error } = useQuery(GET_PROJECT_REFERENCE, {
    variables: {
      projectSlug: slug
    }
  });

  const project = get(data, 'project', {});

  return {
    loading,
    error,
    projectId: project.id,
    projectName: project.name
  };
};

export default useProjectReference;
