import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useResourceAssignmentDialogContext } from '../ResourceAssignmentDialogContext';

export const PUT_USER_ASSIGNMENT_FOR_VENDOR = gql`
  mutation PutUserAssignmentForVendor($input: UserAssignmentForVendorInput!) {
    putUserAssignmentForVendor(input: $input) {
      vendorRef
      userRef
    }
  }
`;

export const usePutUserAssignmentForVendor = ({
  vendorUri,
  userUri,
  onError
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { users, setUsers } = useResourceAssignmentDialogContext();

  const [putUserAssignmentForVendorMutation] = useMutation(
    PUT_USER_ASSIGNMENT_FOR_VENDOR
  );

  const setUserDisabled = users.map(user =>
    user.uri === userUri ? { ...user, disabled: true } : user
  );

  const assignUserToVendor = useCallback(async () => {
    setSubmitting(true);
    try {
      await putUserAssignmentForVendorMutation({
        variables: {
          input: { vendorUri, userUri }
        },
        refetchQueries: ['vendorAssignments']
      });

      setUsers(setUserDisabled);
    } catch (e) {
      onError(e);
    } finally {
      setSubmitting(false);
    }
  }, [
    putUserAssignmentForVendorMutation,
    vendorUri,
    userUri,
    setUsers,
    setUserDisabled,
    onError
  ]);

  return { submitting, assignUserToVendor };
};

export default usePutUserAssignmentForVendor;
