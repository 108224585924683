import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import {
  Checkbox,
  Typography,
  TextField,
  IconButton,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpSharp';
import RemoveCircleSharp from '@material-ui/icons/RemoveCircleSharp';
import get from 'lodash.get';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import { ProjectRequestGateValue } from '~/types';

const useStyles = makeStyles(theme => ({
  displayTextField: {
    width: '65%',
    margin: theme.spacing(1.25, 0, 1.25, 1.25),
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1.5)
  }
}));

export const Gate = ({
  details,
  changeHandlers,
  indexValue,
  editable,
  errors,
  canEditProjectRequest
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const {
    onGateValueChange,
    onGateDisplayTextChange,
    onRemoveGate
  } = changeHandlers;
  const { uri, value, isDefault, displayText } = details;
  const onValueChange = useCallback(
    e => onGateValueChange(e.target.checked, uri, indexValue),
    [uri, indexValue, onGateValueChange]
  );
  const onDisplayTextChange = useCallback(
    e => onGateDisplayTextChange(e.target.value, indexValue),
    [indexValue, onGateDisplayTextChange]
  );
  const onRemove = useCallback(() => onRemoveGate(uri), [uri, onRemoveGate]);
  const displayTextError = useMemo(
    () => get(errors, `gates[${indexValue}].displayText`),
    [errors, indexValue]
  );

  return (
    <div className={classes.container}>
      {!editable ? (
        <>
          <Checkbox
            checked={value === ProjectRequestGateValue.Met}
            onChange={onValueChange}
            disabled={!canEditProjectRequest}
          />
          <Typography variant="body2">{displayText}</Typography>
        </>
      ) : (
        <>
          <TextField
            value={displayText}
            hiddenLabel
            onChange={onDisplayTextChange}
            className={classes.displayTextField}
            error={Boolean(displayTextError)}
            data-qe-id={
              isDefault ? 'DefaultGateDisplayText' : 'CustomGateDisplayText'
            }
            disabled={isDefault}
          />
          {!isDefault ? (
            <IconButton onClick={onRemove}>
              <RemoveCircleSharp />
            </IconButton>
          ) : (
            <Tooltip
              title={intl.formatMessage({
                id: 'projectRequest.defaultCheckListItemsToolTip'
              })}
              enterTouchDelay={0}
            >
              {isPsaPrpAccessibilityIssuesEnabled ? (
                <HelpIcon className={classes.helpIcon} tabIndex={0} />
              ) : (
                <HelpIcon className={classes.helpIcon} />
              )}
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

Gate.propTypes = {
  details: PropTypes.object,
  changeHandlers: PropTypes.object,
  indexValue: PropTypes.number,
  editable: PropTypes.bool,
  errors: PropTypes.object,
  canEditProjectRequest: PropTypes.bool
};
export default Gate;
