import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { SecondaryActions, RequestActionButton } from './components';
import {
  useProjectRequestDrawerActions,
  useProjectRequestStatusHandler
} from './hooks';

const useActionStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.table.border}`,
    width: '100%',
    zIndex: '1000',
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(1, 2, 1, 2)
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  buttonSpacing: {
    marginLeft: theme.spacing(1)
  }
}));

export const ProjectRequestStatusDrawerActions = ({
  editable,
  projectRequestId,
  deleteItem,
  openRequestHistoryDialog,
  onCreateProject,
  projectRequestStatus,
  openApproveProjectRequestConfirmationDialog,
  projectRequestGates
}) => {
  const classes = useActionStyles();

  const [clickedButtonStatusType, setClickedButtonStatusType] = useState();
  const {
    onRequestStatusChange,
    approveProjectRequestHandler,
    isLoading
  } = useProjectRequestStatusHandler({
    projectRequestId,
    projectRequestGates,
    openApproveProjectRequestConfirmationDialog,
    setClickedButtonStatusType
  });

  const actions = useProjectRequestDrawerActions({
    editable,
    deleteItem,
    openRequestHistoryDialog,
    onCreateProject,
    projectRequestStatus,
    onRequestStatusChange,
    approveProjectRequestHandler
  });

  const primaryActions = actions.filter(action => !action.secondary);
  const secondaryActions = actions.filter(action => action.secondary);

  return (
    <>
      {primaryActions.length > 0 &&
        primaryActions.map((primaryAction, index) => {
          const {
            label,
            variant,
            onClick,
            paletteclassvalue,
            statusType
          } = primaryAction;

          return (
            <RequestActionButton
              key={primaryAction.label}
              label={label}
              className={classNames(index > 0 && classes.buttonSpacing)}
              paletteClass={paletteclassvalue}
              variant={variant}
              onClick={onClick}
              clickedButtonStatusType={clickedButtonStatusType}
              statusType={statusType}
              isStatusUpdateLoading={isLoading}
            />
          );
        })}
      {secondaryActions.length > 0 && (
        <>
          <div className={classes.spacer} />
          <SecondaryActions actions={secondaryActions} />
        </>
      )}
    </>
  );
};

ProjectRequestStatusDrawerActions.propTypes = {
  editable: PropTypes.bool,
  projectRequestId: PropTypes.string,
  deleteItem: PropTypes.func,
  openRequestHistoryDialog: PropTypes.func,
  onCreateProject: PropTypes.func,
  projectRequestStatus: PropTypes.object,
  openApproveProjectRequestConfirmationDialog: PropTypes.func,
  projectRequestGates: PropTypes.array
};

export default ProjectRequestStatusDrawerActions;
