import { Info } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { weekUris } from '~/modules/common/dates/dayOfWeek';

const rotateRight = arr => [
  arr[arr.length - 1],
  ...arr.slice(0, arr.length - 1)
];
const rotateLeft = arr => [...arr.slice(1, arr.length), arr[0]];

// Repeats a function call (f) against a given input (x) multiple (n) times.
//  e.g. repeat(10)(x => x + 1)(0) = 10
const repeat = n => f => x => (n === 0 ? x : repeat(n - 1)(f)(f(x)));

/**
 * Overrides the Luxon utils to provide the monthly calendar according
 * to the specification of the locale.
 *
 * For example, in France, Australia, Canada, and USA the week starts on
 * Sunday. Other locales have the week start on Monday.
 */

const createCustomLuxonUtils = workWeekStartUri => {
  class CustomLuxonUtils extends LuxonUtils {
    getWeekdays() {
      // Normalize the luxon weekdays
      const normalizedWeekdays = rotateRight(Info.weekdaysFormat('short'));

      // Rotate to match the week start
      const weekStart = weekUris.indexOf(workWeekStartUri) + 1;
      const weekdays = repeat(weekStart)(rotateLeft)(normalizedWeekdays);

      return weekdays;
    }

    /**
     * Function was taken from the @date-io/luxon library
     * and then altered to localize based on locale.
     */
    getWeekArray(date) {
      const weekStart = weekUris.indexOf(workWeekStartUri) + 1;

      const startOfMonth = date.startOf('month');

      let startDate = startOfMonth;

      while (startDate.weekday !== weekStart) {
        startDate = startDate.minus({ days: 1 });
      }

      const endOfMonth = date.endOf('month');
      const weekEnd = ((weekStart - 2 + 7) % 7) + 1;
      let endDate = endOfMonth;

      while (endDate.weekday !== weekEnd) {
        endDate = endDate.plus({ days: 1 });
      }

      const { days } = endDate.diff(startDate, 'days').toObject();
      const weeks = [];

      new Array(Math.round(days))
        .fill(0)
        .map((_, i) => i)
        .map(day => startDate.plus({ days: day }))
        .forEach((v, i) => {
          if (i === 0 || (i % 7 === 0 && i > 6)) {
            weeks.push([v]);

            return;
          }

          weeks[weeks.length - 1].push(v);
        });

      return weeks;
    }
  }

  return CustomLuxonUtils;
};

export default createCustomLuxonUtils;
