import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback, useState } from 'react';

const ASSIGN_PROJECT_REQUESTS_TO_PORTFOLIO_MUTATION = gql`
  mutation AssignProjectRequestsToPortfolio(
    $input: AssignProjectRequestsToPortfolioInput
  ) {
    assignProjectRequestsToPortfolio(input: $input)
  }
`;

export const useAssignProjectRequestsToPortfolio = ({
  portfolioId,
  projectRequestIds,
  onComplete,
  onError
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [assignProjectRequestsToPOrtfolioMutation] = useMutation(
    ASSIGN_PROJECT_REQUESTS_TO_PORTFOLIO_MUTATION
  );

  const assignProjectRequestsToPortfolio = useCallback(async () => {
    setSubmitting(true);
    try {
      await assignProjectRequestsToPOrtfolioMutation({
        variables: {
          input: {
            portfolioId,
            projectRequestIds
          }
        },
        refetchQueries: [
          'getPageOfProjectRequests',
          'Eager_GetProjectRequestsCountSummary'
        ]
      });

      onComplete();
    } catch (e) {
      onError(e);
    } finally {
      setSubmitting(false);
    }
  }, [
    assignProjectRequestsToPOrtfolioMutation,
    portfolioId,
    projectRequestIds,
    onComplete,
    onError
  ]);

  return { submitting, assignProjectRequestsToPortfolio };
};
