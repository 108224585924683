import { Date, Hours } from '~/modules/common/components/ListTable/renderers';
import { Date as EstimatedCompletionDate } from './renderers';

export const getFormatters = taskEndDate => ({
  date: Date,
  totalHoursWorkedToDate: Hours,
  estimatedRemainingHours: Hours,
  estimatedHoursAtCompletion: Hours,
  estimatedCompletionDate: EstimatedCompletionDate(taskEndDate)
});

export default getFormatters;
