import React from 'react';
import { useIntl } from 'react-intl';
import ErrorBoundary from '~/modules/common/components/ErrorBoundary';
import { useDialogState } from '~/modules/common/hooks';
import { AddFabButton } from '~/modules/common/components';
import { useProjectRequestPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { usePageTitle } from '~/modules/common/title';
import { usePortfolioPageEditContext } from '../../PortfolioPageEditContext';
import ProjectRequestTabContent from './ProjectRequestTabContent';
import AddPortfolioProjectRequestDialog from './AddPortfolioProjectRequest/AddPortfolioProjectRequestDialog';

export const ProjectRequestTab = () => {
  const { edit, loading, portfolio } = usePortfolioPageEditContext();
  const { formatMessage } = useIntl();
  const {
    open: addDialogOpen,
    openDialog: openAddDialog,
    closeDialog: closeAddDialog
  } = useDialogState(false);

  const { canEditProjectRequest } = useProjectRequestPermissions();

  usePageTitle([
    { messageId: 'routes.portfolios' },
    { title: portfolio.name },
    { messageId: 'portfolio.tabs.requests' }
  ]);

  return (
    <>
      <ErrorBoundary>
        <ProjectRequestTabContent loading={loading} portfolio={portfolio} />
      </ErrorBoundary>
      {edit && canEditProjectRequest && (
        <AddFabButton
          label={formatMessage({ id: 'projectRequest.label' })}
          shortLabel={formatMessage({ id: 'projectRequest.shortLabel' })}
          onClick={openAddDialog}
          dataQeId="addRequestFabButton"
        />
      )}
      <AddPortfolioProjectRequestDialog
        portfolio={portfolio}
        open={addDialogOpen}
        onClose={closeAddDialog}
      />
    </>
  );
};

export default ProjectRequestTab;
