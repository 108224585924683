import { useMutation, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useMeContext } from '~/modules/me/useMeContext';
import { MARK_ALL_NOFICATIONS_AS_READ_MUTATION } from '../graphql/markAllNotificationsAsReadMutation';
import { UNREAD_NOTIFICATIONS_QUERY } from '../graphql/unreadNotificationsQuery';

export const buildNotificationsOptimisticResponse = cache => {
  const { me } = cache.readQuery({
    query: UNREAD_NOTIFICATIONS_QUERY
  });

  const { unreadNotifications } = me;

  return {
    __typename: 'Mutation',
    markAllNotificationsAsRead: {
      __typename: 'MarkAllNotificationsAsReadResult',
      notifications: unreadNotifications.map(n => ({
        ...n,
        unreadOnTimestamp_id: null,
        __typename: 'Notification'
      }))
    }
  };
};

export const updateCache = (
  cache,
  { data: { markAllNotificationsAsRead } }
) => {
  const { notifications } = markAllNotificationsAsRead;

  const readNotificationsMap = notifications.reduce(
    (map, n) => ({
      ...map,
      [n.id]: n.id
    }),
    {}
  );

  const { me } = cache.readQuery({
    query: UNREAD_NOTIFICATIONS_QUERY
  });

  const {
    unreadNotifications: cachedNotifications,
    unreadNotificationsCount
  } = me;

  const count = (
    parseInt(unreadNotificationsCount, 10) - notifications.length
  ).toString();

  cache.writeQuery({
    query: UNREAD_NOTIFICATIONS_QUERY,
    data: {
      me: {
        ...me,
        unreadNotifications: cachedNotifications.filter(
          n => !readNotificationsMap[n.id]
        ),
        unreadNotificationsCount: count
      }
    }
  });
};

export const useMarkAllNotificationsAsRead = () => {
  const { id } = useMeContext();
  const { cache } = useApolloClient();

  const [markAllNotificationsAsRead] = useMutation(
    MARK_ALL_NOFICATIONS_AS_READ_MUTATION
  );

  return useCallback(() => {
    const optimisticResponse = buildNotificationsOptimisticResponse(cache);

    markAllNotificationsAsRead({
      variables: {
        userUri: id
      },
      optimisticResponse,
      update: updateCache
    });
  }, [id, markAllNotificationsAsRead]);
};
