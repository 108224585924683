import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS } from '~/modules/common/enums';

export const getQuickFilterSummaryTabs = ({
  totalProjects,
  statusOptions,
  subPortfoliosProjects,
  archivedStatus
} = {}) => ({
  allItems: [
    {
      key: PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS.THIS_PORTFOLIO,
      label: <FormattedMessage id="projectList.thisPortfolio" />,
      value: totalProjects,
      dataQeId: 'PortfolioProjectSummary_This'
    },
    {
      key: PORTFOLIO_PROJECTS_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS,
      label: <FormattedMessage id="projectList.subPortfolios" />,
      value: subPortfoliosProjects,
      dataQeId: 'PortfolioProjectSummary_SubPortfolio'
    }
  ].filter(r => r),
  executionPhases: statusOptions.map(statusOption => ({
    key: statusOption.id,
    label: statusOption.displayText,
    value: statusOption.projectsCount,
    color: statusOption.color.dark
  })),
  alternativeItems: [
    {
      key: archivedStatus.id,
      label: archivedStatus.displayText,
      value: archivedStatus.projectsCount,
      color: archivedStatus.color.dark
    }
  ]
});
