import React from 'react';
import { retryLoading } from '~/util';
import ProgramNavigationBreadcrumb from './ProgramNavigationBreadcrumb';

const ProgramPage = React.lazy(() =>
  retryLoading(() => import('./ProgramPage'))
);

const programRoute = ({ intl, me }) => ({
  path: '/programs/:slug',
  icon: () => null,
  hidden: true,
  title: () => <ProgramNavigationBreadcrumb />,
  component: ProgramPage,
  hasPermission: Boolean(
    me.permissions.find(
      p => p.permissionActionUri === 'urn:replicon:program-action:view-program'
    )
  )
});

export default programRoute;
