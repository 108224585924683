import { Duration } from 'luxon';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useMeContext } from '~/modules/me';
import { redirectToSessionLogout } from '~/modules/navigation';
import { recursivelyStripTypeName } from '~/util';
import SessionExpiryDialog from './SessionExpiryDialog';
import useSessionTimeoutRemainingQuery from './useSessionTimeoutRemainingQuery';
import useSessionWarningPeriod from './useSessionWarningPeriod';
import withSessionExpiry from './withSessionExpiry';

export const Nothing = () => <div />;

const SessionExpiryDialogHOC = withSessionExpiry(SessionExpiryDialog);

export const WithSessionExpiryHOCWrapper = ({
  onLogout,
  sessionTimeoutDuration
}) => {
  const {
    error,
    sessionTimeoutRemaining,
    touchSession
  } = useSessionTimeoutRemainingQuery();

  const { sessionWarningPeriod } = useSessionWarningPeriod({
    sessionTimeoutDuration
  });

  if (error) return <Nothing />;

  return (
    <SessionExpiryDialogHOC
      onLogout={onLogout}
      touchSession={touchSession}
      sessionTimeoutDuration={sessionTimeoutDuration}
      sessionWarningPeriod={sessionWarningPeriod}
      sessionTimeoutRemaining={sessionTimeoutRemaining}
    />
  );
};

WithSessionExpiryHOCWrapper.propTypes = {
  sessionTimeoutDuration: PropTypes.object,
  onLogout: PropTypes.func
};

const SessionExpiry = () => {
  const {
    uiRootPath,
    sessionTimeoutDuration: timeoutDuration
  } = useMeContext();

  const sessionTimeoutDuration = timeoutDuration
    ? recursivelyStripTypeName(timeoutDuration)
    : null;
  const hasNoExpiry =
    !sessionTimeoutDuration ||
    Duration.fromObject(sessionTimeoutDuration).valueOf() ===
      Duration.fromMillis(0).valueOf();

  if (hasNoExpiry) return <Nothing />;

  return (
    <WithSessionExpiryHOCWrapper
      onLogout={redirectToSessionLogout(uiRootPath)}
      sessionTimeoutDuration={sessionTimeoutDuration}
    />
  );
};

SessionExpiry.propTypes = {};

export default SessionExpiry;
