import React, { memo } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListTablePropTypes from '../../../PropTypes';
import { useListTableContext } from '../../../ListTableContext';
import TableSortLabel from './TableSortLabel';
import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';
import ListTableNavigation from './ListTableNavigation';
import { getColumnSortProps } from './sortUtil';

export const TableHeaderRow = ({
  classes,
  variant,
  headers,
  columns,
  styles,
  loading = false,
  onSelectAll,
  enableRowSelection = false,
  useInlineStyles,
  navigationProps,
  recordCount
}) => {
  const {
    field: sortField,
    direction,
    sortArray,
    isMultiSortEnabled,
    onSortChange,
    disableSort,
    customFieldUri
  } = useListTableContext() || {};
  const showSelectAllCheckBox = !!onSelectAll;
  const { currentPage, navigatePage, maximumPageSize, showNavigationButtons } =
    navigationProps || {};

  const isPageOutOfBound = showNavigationButtons && recordCount === 0;

  return variant === 'table' && headers ? (
    <>
      {headers.map((h, index) => (
        <TableRow key={h.id} className={classes.tableHeaderRow}>
          {index === 0 && enableRowSelection && !isPageOutOfBound && (
            <TableHeaderCell
              align="center"
              fixed
              className={classNames(
                classes.tableHeaderCell,
                classes.tabelCellSelection
              )}
              rowSpan={headers.length}
            >
              {showSelectAllCheckBox && (
                <Checkbox
                  className={classes.checkbox}
                  checked={false}
                  indeterminate={false}
                  disabled={loading}
                />
              )}
            </TableHeaderCell>
          )}
          {h.columns.map((c, columnIndex) => {
            const { isSorted, sortDirection } = getColumnSortProps({
              isMultiSortEnabled,
              sortField,
              direction,
              customFieldUri,
              currentColumn: c,
              sortArray
            });

            const value = c.value || columns[c.column]?.value;
            const secondaryValue =
              c.secondaryValue || columns[c.column]?.secondaryValue;

            return (
              <TableHeaderCell
                key={c.column}
                fixed={c.fixed}
                leftOfFixed={!c.fixed}
                className={classNames(
                  classes.tableHeaderCell,
                  c.className,
                  {
                    [classes.tableFixedCellWithSelection]:
                      index === 0 &&
                      columnIndex === 0 &&
                      enableRowSelection &&
                      c.fixed &&
                      !isPageOutOfBound
                  },
                  { [classes.pageOutOfBoundHeaderFix]: isPageOutOfBound }
                )}
                rowSpan={c.rowSpan || 1}
                colSpan={c.colSpan || 1}
                align={c.align}
              >
                <div
                  className={classNames({
                    [classes.headerCellWithNavigationButtons]:
                      c.includesNavigationComponent && showNavigationButtons
                  })}
                >
                  <TableSortLabel
                    field={c.sortField || c.column}
                    show={!disableSort && !!c.sortable}
                    active={isSorted}
                    direction={isSorted ? sortDirection : 'asc'}
                    customFieldUri={
                      c.sortField === 'customField' ? c.column : null
                    }
                    onClick={onSortChange}
                    align={c.align}
                  >
                    <span>
                      {value}
                      {secondaryValue && (
                        <Typography
                          data-testid="secondary-label"
                          className={classes.tableHeaderSecondaryLabel}
                        >
                          ({secondaryValue})
                        </Typography>
                      )}
                    </span>
                  </TableSortLabel>
                  {c.includesNavigationComponent &&
                  !loading &&
                  showNavigationButtons ? (
                    <ListTableNavigation
                      currentPage={currentPage}
                      navigatePage={navigatePage}
                      pageSize={maximumPageSize}
                      recordCount={recordCount}
                    />
                  ) : null}
                </div>
              </TableHeaderCell>
            );
          })}
        </TableRow>
      ))}
    </>
  ) : (
    <TableRow className={classes.tableHeaderRow}>
      {Object.keys(columns).map((field, index) => (
        <TableHeaderCell
          key={field}
          fixed={index === 0}
          leftOfFixed={index === 1}
          className={classNames(
            classes.tableHeaderCell,
            columns[field].headerClassName
          )}
          style={useInlineStyles ? styles[field] : undefined}
        >
          {columns[field].value}
        </TableHeaderCell>
      ))}
    </TableRow>
  );
};

TableHeaderRow.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string,
  headers: ListTablePropTypes.headers,
  columns: ListTablePropTypes.columns,
  styles: PropTypes.object,
  loading: PropTypes.bool,
  onSelectAll: PropTypes.func,
  enableRowSelection: PropTypes.bool,
  useInlineStyles: PropTypes.bool,
  recordCount: PropTypes.number,
  navigationProps: PropTypes.object
};

export default memo(TableHeaderRow);
