import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useSideNavigationContext } from '../SideNavigation/useSideNavigationContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      minHeight: '100%',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      overscrollBehaviorY: 'none',
      '-webkit-overflow-scrolling': 'touch'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: ({ isMenuPinned }) =>
        theme.direction !== 'rtl' ? (isMenuPinned ? 0 : theme.spacing(8)) : 0,
      marginRight: ({ isMenuPinned }) =>
        theme.direction === 'rtl' ? (isMenuPinned ? 0 : theme.spacing(8)) : 0
    }
  }
}));

export const PageLayoutPageContainer = ({ children }) => {
  const { isMenuPinned } = useSideNavigationContext();
  const classes = useStyles({ isMenuPinned });

  return (
    <div id="page-layout-page-container" className={classes.root}>
      {children}
    </div>
  );
};

PageLayoutPageContainer.propTypes = {
  children: PropTypes.node
};

export default PageLayoutPageContainer;
