import { useCallback, useMemo } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { minDate, maxDate } from './const';
import { useEstimatedExpensesSeries } from './useEstimatedExpensesSeries';
import { useExpenseActualsSeries } from './useExpenseActualsSeries';
import { useEstimatedExpensesSummary } from './useEstimatedExpensesSummary';
import { useExpenseActualsSummary } from './useExpensesActualsSummary';

export const useExpenseActualsAndEstimates = ({
  currencyId,
  endDate,
  projectId,
  startDate,
  skipActuals,
  skipEstimates
}) => {
  const dateRange = useMemo(
    () => ({
      startDate: startDate ? mapIsoStringtoUtcObject(startDate) : minDate,
      endDate: endDate ? mapIsoStringtoUtcObject(endDate) : maxDate
    }),
    [endDate, startDate]
  );

  const {
    loading: estimatedExpensesSeriesLoading,
    estimatedExpensesSeries,
    refetch: refetchEstimatedExpensesSeries
  } = useEstimatedExpensesSeries(
    !skipEstimates && projectId,
    dateRange,
    currencyId
  );
  const {
    loading: expenseActualsSeriesLoading,
    expenseActualsSeries
  } = useExpenseActualsSeries(!skipActuals && projectId, dateRange, currencyId);

  const {
    estimatedExpensesSummary,
    loading: estimatedExpensesSummaryLoading,
    refetch: refetchEstimatedExpensesSummary
  } = useEstimatedExpensesSummary({
    projectId: !skipEstimates && projectId,
    dateRange,
    currencyId
  });

  const {
    expenseActualsSummary,
    loading: expenseActualsSummaryLoading
  } = useExpenseActualsSummary({
    projectId: !skipActuals && projectId,
    dateRange,
    currencyId
  });

  const refetchEstimates = useCallback(() => {
    if (!skipEstimates) {
      refetchEstimatedExpensesSeries();
      refetchEstimatedExpensesSummary();
    }
  }, [
    refetchEstimatedExpensesSeries,
    refetchEstimatedExpensesSummary,
    skipEstimates
  ]);

  const loading =
    estimatedExpensesSeriesLoading ||
    expenseActualsSeriesLoading ||
    estimatedExpensesSummaryLoading ||
    expenseActualsSummaryLoading;

  return {
    loading,
    estimatedExpensesSeries,
    expenseActualsSeries,
    estimatedExpensesSummary,
    expenseActualsSummary,
    refetchEstimates
  };
};
