import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import EditIcon from '@material-ui/icons/EditSharp';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { MoneyValue, NoValue } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import EstimatedCostHelp from './EstimatedCostHelp';
import { OverrideEstimatedCost } from './OverrideEstimatedCost';

const useStyles = makeStyles(theme => ({
  summaryheading: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  title: {
    fontWeight: '500'
  },
  editButton: {
    padding: 0,
    margin: theme.spacing(0, 1, 0, 1)
  },
  estimatedCost: {
    paddingTop: theme.spacing(0.25)
  },
  noEstimatedCostMessage: {
    fontSize: theme.typography.caption.fontSize,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    opacity: '0.7'
  }
}));

export const EstimatedCost = ({ hasResourceAssignments }) => {
  const {
    setFieldValue,
    setFieldError,
    values: {
      estimatedCostOverride,
      estimatedCost,
      initialEstimatedCostCurrency
    }
  } = useFormikContext();

  const classes = useStyles();
  const [hasEstimatedCostOverride, setHasEstimatedCostOverride] = useState(
    !isNullOrUndefined(estimatedCostOverride)
  );

  const handleHasEstimatedCostOverrideChange = useCallback(() => {
    setFieldValue('estimatedCostOverride', {
      currency: initialEstimatedCostCurrency
    });

    setFieldError('estimatedCostOverride', null);

    setHasEstimatedCostOverride(true);
  }, [setFieldValue, initialEstimatedCostCurrency, setFieldError]);

  const { canEditPlanningData } = useProjectRequestObjectPermissions();

  const { formatMessage } = useIntl();

  return (
    <>
      <div className={classes.summaryheading}>
        <Typography className={classes.title}>
          <FormattedMessage id="projectRequest.estimatedCost" />
        </Typography>
        <EstimatedCostHelp
          hasEstimatedCostOverride={hasEstimatedCostOverride}
        />
      </div>
      <>
        {hasEstimatedCostOverride ? (
          <OverrideEstimatedCost
            setHasEstimatedCostOverride={setHasEstimatedCostOverride}
            canEditPlanningData={canEditPlanningData}
            hasResourceAssignments={hasResourceAssignments}
          />
        ) : (
          <div className={classes.estimatedCost}>
            {hasResourceAssignments ? (
              <>
                {estimatedCost === null ? (
                  <NoValue />
                ) : (
                  <MoneyValue money={estimatedCost} />
                )}
              </>
            ) : (
              <Typography
                display="inline"
                className={classes.noEstimatedCostMessage}
              >
                <FormattedMessage
                  id={
                    canEditPlanningData
                      ? 'projectRequest.noEstimatedCostWithEditPermission'
                      : 'projectRequest.noEstimatedCost'
                  }
                />
              </Typography>
            )}
            {canEditPlanningData && (
              <IconButton
                className={classes.editButton}
                onClick={handleHasEstimatedCostOverrideChange}
                aria-label={formatMessage({
                  id: 'projectRequest.edit'
                })}
                data-qe-id="EstimatedCostOverride"
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        )}
      </>
    </>
  );
};

EstimatedCost.propTypes = {
  hasResourceAssignments: PropTypes.bool
};

export default EstimatedCost;
