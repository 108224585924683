import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Checkbox } from '@material-ui/core';

import { DateRange } from '~/modules/common/components';
import { renders } from '~/modules/projects/components/ProjectListPage/ProjectList/enhancers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  checkbox: {
    marginLeft: theme.spacing(-1)
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    width: 250 - theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'normal'
  }
}));

const Name = ({ record }) => {
  const { checked = false, name: recordName, startDate, endDate } = record;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Checkbox
        className={classes.checkbox}
        checked={checked}
        color="primary"
      />
      <div>
        <Typography className={classes.name}>{recordName}</Typography>
        <DateRange start={startDate} end={endDate} />
      </div>
    </div>
  );
};

Name.propTypes = {
  record: PropTypes.object
};

export default {
  ...renders,
  name: Name
};
