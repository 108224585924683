import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    height: 'auto',
    paddingLeft: theme.spacing(1)
  }
}));

export const ClosedOnDatePickerButton = forwardRef(
  ({ onClick, inputValue, validationError, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <ButtonBase
        onClick={onClick}
        className={classes.root}
        ref={ref}
        {...rest}
      >
        <EditIcon className={classes.icon} />
      </ButtonBase>
    );
  }
);

ClosedOnDatePickerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  inputValue: PropTypes.object,
  validationError: PropTypes.object
};

export default ClosedOnDatePickerButton;
