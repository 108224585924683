import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveTaskAllocationDialogContent from './RemoveTaskAllocationDialogContent';
import RemoveTaskAllocationDialogTitle from './RemoveTaskAllocationDialogTitle';
import RemoveTaskAllocationDialogFooter from './RemoveTaskAllocationDialogFooter';
import RemoveTaskAllocationDialogContentSkeleton from './RemoveTaskAllocationDialogContentSkeleton';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.spacing(75)
  }
}));

const RemoveTaskAllocationDialog = ({
  isLoading,
  open,
  onClose,
  onRemoveTaskAllocationSuccess,
  updateCache,
  refetchQueries
}) => {
  const dialogClasses = useDialogStyles();

  return (
    <Dialog
      classes={dialogClasses}
      open={open}
      onClose={onClose}
      aria-labelledby="remove-task-allocation-dialog-title"
      aria-describedby="remove-task-allocation-dialog-description"
    >
      <RemoveTaskAllocationDialogTitle />
      {isLoading && <RemoveTaskAllocationDialogContentSkeleton />}
      {!isLoading && <RemoveTaskAllocationDialogContent onClose={onClose} />}
      <RemoveTaskAllocationDialogFooter
        isLoading={isLoading}
        onClose={onClose}
        onRemoveTaskAllocationSuccess={onRemoveTaskAllocationSuccess}
        updateCache={updateCache}
        refetchQueries={refetchQueries}
      />
    </Dialog>
  );
};

RemoveTaskAllocationDialog.propTypes = {
  isLoading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveTaskAllocationSuccess: PropTypes.func.isRequired,
  updateCache: PropTypes.func,
  refetchQueries: PropTypes.array
};

export default RemoveTaskAllocationDialog;
