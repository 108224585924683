import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import ProgressItemMetric from '~/modules/common/charts/dashboard/ProgressChart/ProgressItemMetric';
import { isNullOrUndefined } from '~/modules/common/util';
import ProgressItemMetricDetail from './ProgressItemMetricDetail';
import { getActualsFieldProps, getEACFieldProps } from './hooks';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  },
  eacProgressChart: {
    background: '#F8F9FC'
  }
}));

export const ProgressChart = ({
  budgetAmount,
  eacAmount,
  actualAmount,
  prefix,
  suffix,
  budgetMissing,
  budgetMissingAlert,
  eacMissingAlert,
  eacMissing,
  fixedDecimalScale,
  actualAmountResourceKey,
  tooltipPrecision,
  dataQeId
}) => {
  const classes = useStyles();
  const isNoBudgetDefined = isNullOrUndefined(budgetAmount);
  const {
    percentage: actualValuePercentage,
    value: actualValue
  } = getActualsFieldProps({
    actualAmount,
    budgetAmount
  });
  const { percentage: eacValuePercentage, value: eacValue } = getEACFieldProps({
    eacAmount,
    budgetAmount
  });

  return (
    <Grid container className={classes.container} spacing={0}>
      <Grid item className={classes.item} xs={6}>
        <ProgressItemMetric
          title="portfolio.budgetBurnedCard.actualToDate"
          value={actualValuePercentage}
          dataQeId={`${dataQeId}_ActualToDatePercentage`}
        />
        <ProgressItemMetricDetail
          message={budgetMissingAlert}
          id={budgetMissing}
          denominator={budgetAmount}
          numerator={actualValue}
          fixedDecimalScale={fixedDecimalScale}
          tooltipPrecision={tooltipPrecision}
          prefix={prefix}
          suffix={suffix}
          numeratorLabel={actualAmountResourceKey}
          denominatorLabel="portfolio.budgetBurnedCard.totalProjectBudget"
        />
      </Grid>
      <Grid
        item
        className={classNames(classes.item, classes.eacProgressChart)}
        xs={6}
      >
        <ProgressItemMetric
          title="portfolio.budgetBurnedCard.estimatedAtCompletion"
          value={eacValuePercentage}
          dataQeId={`${dataQeId}_EACPercentage`}
        />
        <ProgressItemMetricDetail
          message={isNoBudgetDefined ? budgetMissingAlert : eacMissingAlert}
          id={isNoBudgetDefined ? budgetMissing : eacMissing}
          denominator={budgetAmount}
          numerator={eacValue}
          fixedDecimalScale={fixedDecimalScale}
          tooltipPrecision={tooltipPrecision}
          prefix={prefix}
          suffix={suffix}
          numeratorLabel="portfolio.budgetBurnedCard.estimatedAtCompletion"
          denominatorLabel="portfolio.budgetBurnedCard.totalProjectBudget"
        />
      </Grid>
    </Grid>
  );
};

ProgressChart.propTypes = {
  budgetAmount: PropTypes.number,
  eacAmount: PropTypes.number,
  actualAmount: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  budgetMissing: PropTypes.string,
  budgetMissingAlert: PropTypes.string,
  eacMissingAlert: PropTypes.string,
  eacMissing: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  actualAmountResourceKey: PropTypes.string,
  tooltipPrecision: PropTypes.number,
  dataQeId: PropTypes.string
};

export default ProgressChart;
