import { useEffect, useRef } from 'react';
import { notificationCreatedSubscription } from '~/modules/notifications/graphql/notificationCreatedSubscription';

export const updateUnreadNotifications = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;

  const unreadNotifications = [...(prev.me.unreadNotifications || [])];

  let prevUnreadCount = parseInt(prev.me.unreadNotificationsCount || '0', 10);

  if (subscriptionData.data) {
    const newNotification = subscriptionData.data.notificationCreated;

    unreadNotifications.unshift(newNotification);

    prevUnreadCount++;
  }

  return {
    ...prev,
    me: {
      ...prev.me,
      unreadNotifications,
      unreadNotificationsCount: prevUnreadCount.toString()
    }
  };
};

export const useUnreadNotificationsSubscription = ({ subscribeToMore }) => {
  const unsubscribeRef = useRef();

  useEffect(() => {
    unsubscribeRef.current = subscribeToMore({
      document: notificationCreatedSubscription,
      updateQuery: updateUnreadNotifications
    });

    return () => unsubscribeRef.current();
  }, []);
};
