import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MoneyValue, NoValue } from '~/modules/common/components';
import { ProjectRequestStatusType } from '~/types';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { isNullOrUndefined } from '~/modules/common/util';

const chartConfig = {
  width: 270,
  height: 270,
  innerRadius: 85,
  titleX: 135,
  titleY: 126,
  valueX: 135,
  valueY: 147,
  padding: { top: 12, bottom: 13, right: 0, left: 0 }
};

const mobileChartConfig = {
  width: 250,
  height: 250,
  innerRadius: 75,
  titleX: 125,
  titleY: 125,
  valueX: 125,
  valueY: 150
};

const overrideChartStyles = {
  chartTitle: {
    fontSize: 20
  },
  chartValue: {
    fontSize: 16
  }
};

const statusResourceKeys = {
  NEW: 'portfolio.projectRequestStatusChart.labelTitle.new',
  IN_REVIEW: 'portfolio.projectRequestStatusChart.labelTitle.inReview',
  APPROVED: 'portfolio.projectRequestStatusChart.labelTitle.approved',
  REJECTED: 'portfolio.projectRequestStatusChart.labelTitle.rejected'
};

export const dataPoints = countSummary =>
  countSummary.all === 0
    ? [{ y: 1 }]
    : [
        { y: countSummary.new, id: ProjectRequestStatusType.New },
        { y: countSummary.inReview, id: ProjectRequestStatusType.InReview },
        { y: countSummary.approved, id: ProjectRequestStatusType.Approved },
        { y: countSummary.rejected, id: ProjectRequestStatusType.Rejected }
      ];

const statusColors = {
  new: theme.palette.projectRequestStatus[ProjectRequestStatusType.New],
  inReview:
    theme.palette.projectRequestStatus[ProjectRequestStatusType.InReview],
  approved:
    theme.palette.projectRequestStatus[ProjectRequestStatusType.Approved],
  rejected:
    theme.palette.projectRequestStatus[ProjectRequestStatusType.Rejected],
  zeroTotal: theme.palette.common.white
};

const getValue = ({ estimatedCost }) => {
  return estimatedCost ? (
    <MoneyValue money={estimatedCost} precision={0} />
  ) : (
    <NoValue />
  );
};

const getTitle = ({ count, requestStatus }) => {
  return (
    <FormattedMessage
      id={statusResourceKeys[requestStatus]}
      values={{ count }}
    />
  );
};

export const legends = ({
  estimatedCostSummary,
  projectRequestsCountSummary: countSummary,
  canViewPlanningData
}) => [
  {
    value: canViewPlanningData
      ? getValue({
          estimatedCost: estimatedCostSummary.new
        })
      : null,
    title: getTitle({
      count: countSummary.new,
      requestStatus: ProjectRequestStatusType.New
    }),
    colorScale: statusColors.new,
    key: ProjectRequestStatusType.New,
    dataQeId: 'New'
  },
  {
    value: canViewPlanningData
      ? getValue({
          estimatedCost: estimatedCostSummary.inReview
        })
      : null,
    title: getTitle({
      count: countSummary.inReview,
      requestStatus: ProjectRequestStatusType.InReview
    }),
    colorScale: statusColors.inReview,
    key: ProjectRequestStatusType.InReview,
    dataQeId: 'InReview'
  },
  {
    value: canViewPlanningData
      ? getValue({
          estimatedCost: estimatedCostSummary.approved
        })
      : null,
    title: getTitle({
      count: countSummary.approved,
      requestStatus: ProjectRequestStatusType.Approved
    }),
    colorScale: statusColors.approved,
    key: ProjectRequestStatusType.Approved,
    dataQeId: 'Approved'
  },
  {
    value: canViewPlanningData
      ? getValue({
          estimatedCost: estimatedCostSummary.rejected
        })
      : null,
    title: getTitle({
      count: countSummary.rejected,
      requestStatus: ProjectRequestStatusType.Rejected
    }),
    colorScale: statusColors.rejected,
    key: ProjectRequestStatusType.Rejected,
    dataQeId: 'Rejected'
  }
];

const colorScale = countSummary =>
  countSummary.all === 0
    ? [statusColors.zeroTotal]
    : [
        statusColors.new,
        statusColors.inReview,
        statusColors.approved,
        statusColors.rejected
      ];

export const usePortfolioProjectRequestStatusConfig = ({
  canViewPlanningData,
  estimatedCostSummaryAmounts,
  portfolioCurrency,
  projectRequestsCountSummary
}) => {
  const isMobile = useIsBreakpointDown('md');

  const estimatedCostSummary = Object.keys(estimatedCostSummaryAmounts).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr]: isNullOrUndefined(estimatedCostSummaryAmounts[curr])
          ? null
          : {
              amount: estimatedCostSummaryAmounts[curr],
              currency: portfolioCurrency
            }
      };
    },
    {}
  );

  const overrideChartConfig = isMobile ? mobileChartConfig : chartConfig;

  return {
    colorScale: colorScale(projectRequestsCountSummary),
    legends: legends({
      estimatedCostSummary,
      projectRequestsCountSummary,
      canViewPlanningData
    }),
    dataPoints: dataPoints(projectRequestsCountSummary),
    overrideChartConfig,
    overrideChartStyles
  };
};

export default usePortfolioProjectRequestStatusConfig;
