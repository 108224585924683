import React from 'react';
import { FormattedMessage } from 'react-intl';
import ProjectListPage from './ProjectListPage';

export const clientProjectsTabPath = '/clients/:slug/projects';

export const route = {
  path: clientProjectsTabPath,
  title: () => <FormattedMessage id="client.projects" />,
  component: ProjectListPage
};
