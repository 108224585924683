import { gql } from 'graphql-tag';

export const CLIENT_REPRESENTATIVES_QUERY = gql`
  query GetClientRepresentatives(
    $page: Int!
    $pageSize: Int!
    $searchText: String
  ) {
    clientRepresentatives(
      page: $page
      pageSize: $pageSize
      searchText: $searchText
    ) {
      id
      displayText
    }
  }
`;
