import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  Typography,
  Icon,
  Grid
} from '@material-ui/core';
import BillPlanIcon from '@material-ui/icons/AttachMoneySharp';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { BillPlanPreRequisites } from '~/modules/projects/project/BillPlanV2/BillPlanPreRequisites';
import ScriptParamsEditableCard from '~/modules/projects/project/ScriptParamsEditableCard';
import { RefreshButton } from '~/modules/projects/project/common/components';
import { BatchStatus, NoDataItem } from '~/modules/common/components';
import { MoneyValue } from '~/modules/common/components/Money';
import { useMeContext } from '~/modules/me/useMeContext';
import { getCreateExpenseScript } from './hooks/utils';
import {
  getHeaderProps,
  getFooterProps,
  readOnlyParameters,
  useBillPlanState,
  useProjectBillPlans,
  usePutProjectBillPlans,
  useDefaultBillingContract,
  usePutProjectBillingContract,
  useUpdateProjectContractAmount,
  useRecalculateProjectsBillingContractClauses
} from './hooks';
import { editors } from './editors';
import { formatters } from './formatters';
import ContractAmount from './components/ContractAmount';
import ExpenseScriptParamsEditableCard from './ExpenseScriptParamsEditableCard';

import { AddBillPlanTypeButton } from './AddBillPlan';
import { ExpenseBillPlan } from './ExpenseBillPlan';

const avatar = <BillPlanIcon />;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.grey[200],
    color: theme.palette.text.secondary
  },
  spinner: {
    marginRight: theme.spacing(3)
  }
}));
const useHeaderStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    ...theme.typography.h6,
    color: theme.palette.text.secondary
  },
  action: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(-2)
  },
  subheader: {
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'right',
    marginTop: '2px',
    fontSize: theme.typography.body2.fontSize,
    '& strong': {
      color: theme.palette.common.black
    }
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const useCardStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&:last-child': { paddingBottom: 0 }
  }
}));

const useNoDataStyles = makeStyles(theme => ({
  noData: { padding: theme.spacing(2) }
}));

const shouldRemoveMilestoneContractValue = (billPlans, planId) =>
  billPlans.some(
    billPlan =>
      billPlan.slug === 'fixed-bid-milestone' &&
      billPlan.id === planId &&
      billPlan.scripts.length
  );

const amountKeyUri = 'urn:replicon:script-key:parameter:milestone-amount';
const percentageKeyUri = 'urn:replicon:script-key:parameter:percentage';
const totalAmountKeyUri = 'milestone-total-amount';
const totalPercentageKeyUri = 'milestone-total-percentage';
const useIconStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    marginRight: theme.spacing(2)
  }
}));

export const BillPlan = ({
  editable,
  projectUri,
  hasBillingContractAssignment,
  canRecalculateBillingData,
  isProjectCurrencyChanged,
  onSetBillPlanChanged,
  projectSlug,
  clientUri: clientId,
  projectPermissions,
  isBillingContractType
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const me = useMeContext();
  const additionalProps = { projectUri };
  const headerClasses = useHeaderStyles();
  const cardClasses = useCardStyles();

  const noDataCasses = useNoDataStyles();
  const iconClasses = useIconStyles();
  const {
    loading,
    refetchBillPlans,
    billPlans: { totalEstimatedAmount, billPlans },
    dates,
    projectName,
    projectCurrency,
    expenseCodes
  } = useProjectBillPlans(projectUri);

  const { putBillPlans } = usePutProjectBillPlans({ projectUri, billPlans });

  const { defaultBillingContract } = useDefaultBillingContract();
  const putProjectBillingContract = usePutProjectBillingContract({
    projectId: projectUri,
    contractId: defaultBillingContract.contractId
  });
  const { updateProjectContractAmount } = useUpdateProjectContractAmount({
    projectUri
  });

  const { batchState, setBatchState } = useBillPlanState();
  const {
    recalculateProjectsBillingContractClauses
  } = useRecalculateProjectsBillingContractClauses(projectUri);
  const recalculate = useCallback(() => {
    recalculateProjectsBillingContractClauses(setBatchState);
    onSetBillPlanChanged();
  }, [
    recalculateProjectsBillingContractClauses,
    setBatchState,
    onSetBillPlanChanged
  ]);

  const [selectedBillPlan, setSelectedBillPlan] = useState(null);

  const onSave = useCallback(
    async updatedBillPlan => {
      setSelectedBillPlan(null);
      if (!hasBillingContractAssignment && defaultBillingContract.contractId)
        await putProjectBillingContract();
      await putBillPlans({ updatedBillPlan, setBatchState });
      onSetBillPlanChanged();
      if (updatedBillPlan.hasHeaderComponent) {
        await updateProjectContractAmount({
          updatedContractAmount:
            updatedBillPlan.headerComponentProps.contractAmount
        });
      }
    },
    [
      putBillPlans,
      setBatchState,
      updateProjectContractAmount,
      hasBillingContractAssignment,
      putProjectBillingContract,
      defaultBillingContract.contractId,
      onSetBillPlanChanged
    ]
  );

  const onCancelCallback = useCallback(() => {
    setSelectedBillPlan(null);
  }, []);

  const onBillPlanItemClick = useCallback(
    billPlan => {
      const newScript = billPlan.parameters.reduce(
        (retVal, current) => ({
          ...retVal,
          [current.id]: current.defaultValue
        }),
        { scriptId: v4() }
      );
      const newBillPlan = {
        ...billPlan,
        isAddMode: true,
        contractAmount: getHeaderProps[billPlan.slug]
          ? getHeaderProps[billPlan.slug](me)
          : null,
        footerProps: getFooterProps[billPlan.slug]
          ? getFooterProps[billPlan.slug](me)
          : null,
        scripts:
          billPlan.slug === 'expenses'
            ? (expenseCodes || [])
                .filter(code => code.isExpenseEntryToThisCodeAllowed)
                .map(code =>
                  getCreateExpenseScript(billPlan.parameters, code, [])
                )
            : [newScript]
      };

      setSelectedBillPlan(newBillPlan);
    },
    [expenseCodes, me]
  );

  const onDeleteScript = useCallback(
    planId => () => {
      setSelectedBillPlan(null);
      putBillPlans({
        updatedBillPlan: { id: planId },
        removePlan: true,
        setBatchState
      });
      if (shouldRemoveMilestoneContractValue(billPlans, planId)) {
        updateProjectContractAmount({
          updatedContractAmount: null
        });
      }
    },
    [billPlans, putBillPlans, setBatchState, updateProjectContractAmount]
  );

  const onDeleteRow = {
    'fixed-bid-milestone': useCallback(
      ({ record, setFieldValue, footerProps }) => {
        setFieldValue(
          `footerProps.${totalPercentageKeyUri}.value`,
          footerProps[totalPercentageKeyUri].value - record[percentageKeyUri] ||
            0
        );
        setFieldValue(
          `footerProps.${totalAmountKeyUri}.amount`,
          footerProps[totalAmountKeyUri].amount - record[amountKeyUri].amount ||
            0
        );
      },
      []
    )
  };

  const subHeader = useMemo(
    () =>
      totalEstimatedAmount && !isProjectCurrencyChanged ? (
        <Grid item xs={12} container direction="column">
          <FormattedMessage id="billPlan.totalEstimatedAmount" />
          <strong>
            <MoneyValue money={totalEstimatedAmount} />
          </strong>
        </Grid>
      ) : null,
    [totalEstimatedAmount, isProjectCurrencyChanged]
  );

  const enhancedBillPlans = useMemo(
    () =>
      selectedBillPlan ? [...(billPlans || []), selectedBillPlan] : billPlans,
    [selectedBillPlan, billPlans]
  );

  const hasBillPlan = useMemo(
    () => (billPlans || []).some(billPlan => billPlan.scripts.length),
    [billPlans]
  );

  const title =
    loading || isProjectCurrencyChanged ? (
      <FormattedMessage id="billPlan.title" />
    ) : (
      <>
        <Icon classes={iconClasses}>
          <BillPlanIcon />
        </Icon>
        <Typography variant="h6">
          <FormattedMessage id="billPlan.title" />
        </Typography>
      </>
    );

  const getComponent = useCallback(
    plan =>
      plan.slug === 'expenses'
        ? me?.featureFlags?.isPsaPrpExpenseApprovalInExpenseBillPlanEnabled
          ? ExpenseBillPlan
          : ExpenseScriptParamsEditableCard
        : ScriptParamsEditableCard,
    [me.featureFlags.isPsaPrpExpenseApprovalInExpenseBillPlanEnabled]
  );

  const getAllowAddScriptParam = useCallback(
    plan => plan.slug !== 'expenses',
    []
  );

  const shouldRender = useCallback(
    plan => plan.scripts.length > 0 || plan.slug === 'expenses',
    []
  );

  if (loading || isProjectCurrencyChanged)
    return <CardLoading avatar={avatar} title={title} subheader={subHeader} />;

  return (
    <Card className={classes.root} data-qe-id="BillPlanCard">
      <CardHeader
        title={title}
        subheader={subHeader}
        action={
          editable &&
          canRecalculateBillingData && (
            <RefreshButton
              onClick={recalculate}
              disabled={batchState.batchInProgress}
              titleLabel={intl.formatMessage({
                id: 'billPlan.recalculateBillableData'
              })}
            />
          )
        }
        classes={headerClasses}
      />
      <Divider variant="fullWidth" />
      {batchState.batchInProgress && (
        <BatchStatus
          batchState={batchState}
          setBatchState={setBatchState}
          onBatchComplete={refetchBillPlans}
        >
          <Grid item xs={12} className={classes.progress}>
            <CircularProgress size={30} className={classes.spinner} />
            <Typography>
              <FormattedMessage id="billPlan.recalculatingBillableData" />
            </Typography>
          </Grid>
          <Divider variant="fullWidth" />
        </BatchStatus>
      )}
      <CardContent classes={cardClasses}>
        {enhancedBillPlans.length
          ? enhancedBillPlans.map(billPlan => {
              const Component = getComponent(billPlan);

              return shouldRender(billPlan) ? (
                <Component
                  key={billPlan.id}
                  me={me}
                  scriptType={intl.formatMessage({
                    id: 'billPlan.billingType'
                  })}
                  scriptDetails={billPlan}
                  HeaderComponent={ContractAmount}
                  allowAddScriptParam={getAllowAddScriptParam(billPlan)}
                  customEditors={editors}
                  customFormatters={formatters}
                  editable={editable}
                  additionalProps={additionalProps}
                  onSave={onSave}
                  recalculate={recalculate}
                  onCancelCallback={onCancelCallback}
                  onDeleteScript={onDeleteScript}
                  onDeleteRow={onDeleteRow[billPlan.slug]}
                  readOnlyParameters={readOnlyParameters}
                  BillPlanPreRequisites={BillPlanPreRequisites}
                  projectSlug={projectSlug}
                  expenseCodes={expenseCodes}
                  projectId={projectUri}
                  startDate={dates.startDate}
                  endDate={dates.endDate}
                  projectName={projectName}
                  projectCurrency={projectCurrency}
                  clientUri={clientId}
                  projectPermissions={projectPermissions}
                  canRecalculateBillingData={canRecalculateBillingData}
                  isBillingContractType={isBillingContractType}
                />
              ) : null;
            })
          : null}
        {!hasBillPlan && (
          <div className={noDataCasses.noData}>
            <NoDataItem>
              <FormattedMessage id="billPlan.noBillPlan" />
            </NoDataItem>
          </div>
        )}
        {editable && (
          <AddBillPlanTypeButton
            onBillPlanItemClick={onBillPlanItemClick}
            billPlans={billPlans}
          />
        )}
      </CardContent>
    </Card>
  );
};

BillPlan.propTypes = {
  editable: PropTypes.bool,
  projectUri: PropTypes.string,
  hasBillingContractAssignment: PropTypes.bool,
  canRecalculateBillingData: PropTypes.bool,
  isProjectCurrencyChanged: PropTypes.bool,
  onSetBillPlanChanged: PropTypes.func,
  projectSlug: PropTypes.string,
  clientUri: PropTypes.string,
  projectPermissions: PropTypes.object,
  isBillingContractType: PropTypes.bool
};

export default BillPlan;
