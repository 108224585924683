import React from 'react';
import { DialogTitle, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TaskIcon, TaskParentIcon } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    width: '1em',
    height: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  title: {
    whiteSpace: 'nowrap'
  }
}));

const useTitleStyles = makeStyles(() => ({
  root: {
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

const AddResourceEstimateDialogHeader = ({ task, isEditMode }) => {
  const { hasChildren, displayText } = task;
  const classes = useStyles();
  const titleClasses = useTitleStyles();

  const { formatMessage } = useIntl();

  const IconComponent = hasChildren ? TaskParentIcon : TaskIcon;

  return (
    <DialogTitle classes={titleClasses}>
      <span
        className={classes.title}
        data-qe-id="AddResourceEstimateDialogHeader"
      >
        {formatMessage({
          id: isEditMode
            ? 'editResourceEstimateDialog.title'
            : 'addResourceEstimateDialog.title'
        })}
      </span>
      <IconComponent className={classes.icon} />
      <span>{displayText}</span>
    </DialogTitle>
  );
};

AddResourceEstimateDialogHeader.propTypes = {
  task: PropTypes.object,
  isEditMode: PropTypes.bool
};

export default AddResourceEstimateDialogHeader;
