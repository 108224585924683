import { IconButton, makeStyles, Typography } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpIcon from '@material-ui/icons/ArrowUpwardSharp';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortDirection } from '~/types';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    padding: theme.spacing(1, 0, 1, 1)
  },
  sortText: {
    paddingRight: theme.spacing(1)
  },
  iconSize: {
    fontSize: '16px'
  }
}));

export const SortingButton = ({ sortOrder, onSortOrderChange }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.closeIcon}
      color="inherit"
      onClick={onSortOrderChange}
    >
      <Typography className={classes.sortText} variant="body2" noWrap>
        <FormattedMessage
          id={
            sortOrder === SortDirection.Desc
              ? 'projectRequestHistory.newest'
              : 'projectRequestHistory.oldest'
          }
        />
      </Typography>
      {sortOrder === SortDirection.Desc ? (
        <ArrowDownIcon className={classes.iconSize} />
      ) : (
        <ArrowUpIcon className={classes.iconSize} />
      )}
    </IconButton>
  );
};
SortingButton.propTypes = {
  onSortOrderChange: PropTypes.func.isRequired,
  sortOrder: PropTypes.string.isRequired
};

export default SortingButton;
