import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { PAGE_SIZE } from '~/modules/common/const';
import { CLIENT_REPRESENTATIVES_QUERY } from '../../graphql/clientRepresentativesQuery';

export const useClientRepresentatives = ({ searchTerm }) => {
  const { data, loading } = useQuery(CLIENT_REPRESENTATIVES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      searchText: searchTerm
    },
    context: {
      debounceKey: 'client-representatives-search',
      debounceTimeout: 250
    }
  });

  const clientRepresentatives = get(data, 'clientRepresentatives', []);
  const options = clientRepresentatives.slice(0, PAGE_SIZE);

  return {
    options,
    loading,
    hasMore: clientRepresentatives.length > PAGE_SIZE
  };
};

export default useClientRepresentatives;
