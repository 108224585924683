import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  popper: {
    marginTop: theme.spacing(-1)
  },
  tooltip: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1, 2)
  }
}));
