import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import { ExpenseCodeMenu } from '../AllowedExpensesAndEstimates';
import { ExpenseBillPlanTable } from './expenseBillPlanListTable';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  noData: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'pre'
  }
}));

export const ExpenseScriptParamsEditor = ({
  editable,
  projectId,
  context,
  values,
  allowBillable,
  showActuals,
  customEditors,
  customFormatters,
  errors,
  setFieldValue,
  classes: classesOverride,
  HeaderComponent,
  readOnlyParameters,
  onDeleteRow,
  isMobile,
  slug,
  setSaveable,
  chartDates,
  expenseCodes,
  projectCurrency,
  viewSummary,
  loading,
  anchorEl,
  onMenuClose,
  onAddRowHandler,
  availableExpenseCodes,
  onDeleteRowHandler,
  updatedParameters,
  projectPermissions,
  ...rest
}) => {
  const {
    estimatedAmount,
    scripts,
    monthlyExpenses,
    hasHeaderComponent,
    headerComponentProps,
    footerProps
  } = values;

  const {
    canViewBillingContracts,
    canEditBillingContracts,
    canEditExpenseCodes,
    canViewProjectBillingData
  } = projectPermissions;

  const classes = useStyles({ classes: classesOverride });

  const noDataClasses = useMemo(() => ({ root: classes.noData }), [
    classes.noData
  ]);

  return (
    <>
      {Boolean(HeaderComponent) && (
        <HeaderComponent
          setFieldValue={setFieldValue}
          scripts={scripts}
          headerComponentProps={headerComponentProps}
          errors={errors}
          isMobile={isMobile}
        />
      )}
      {!monthlyExpenses || !monthlyExpenses.length ? (
        <NoDataItem classes={noDataClasses}>
          <FormattedMessage id="expenseBillPlan.noExpenseType" />
        </NoDataItem>
      ) : null}
      <ExpenseBillPlanTable
        editable={editable}
        context={context}
        parameters={updatedParameters}
        totalEstimatedAmount={estimatedAmount}
        headerComponentProps={headerComponentProps}
        hasHeaderComponent={hasHeaderComponent}
        footerProps={footerProps}
        scripts={scripts}
        monthlyExpenses={monthlyExpenses}
        allowBillable={allowBillable}
        showActuals={showActuals}
        setFieldValue={setFieldValue}
        customEditors={customEditors}
        customFormatters={customFormatters}
        errors={errors}
        onDeleteRow={onDeleteRowHandler}
        isMobile={isMobile}
        chartDates={chartDates}
        expenseCodes={expenseCodes}
        projectId={projectId}
        projectCurrency={projectCurrency}
        viewSummary={viewSummary}
        loading={loading}
        canEditExpenseCodes={canEditExpenseCodes}
        canEditBillingContracts={canEditBillingContracts}
        canViewBillingData={
          canViewBillingContracts && canViewProjectBillingData
        }
        {...rest}
      />
      <ExpenseCodeMenu
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorEl={anchorEl}
        onMenuItemClick={onAddRowHandler}
        availableExpenseCodes={availableExpenseCodes}
      />
    </>
  );
};

ExpenseScriptParamsEditor.propTypes = {
  editable: PropTypes.bool,
  scriptType: PropTypes.string,
  values: PropTypes.object.isRequired,
  allowBillable: PropTypes.bool,
  showActuals: PropTypes.bool,
  allowAddScriptParam: PropTypes.bool,
  setFieldValue: PropTypes.func,
  customEditors: PropTypes.object,
  customFormatters: PropTypes.object,
  classes: PropTypes.object,
  errors: PropTypes.object,
  HeaderComponent: PropTypes.func,
  readOnlyParameters: PropTypes.array,
  onDeleteRow: PropTypes.func,
  isMobile: PropTypes.bool,
  canViewParams: PropTypes.bool,
  context: PropTypes.string,
  canEditParams: PropTypes.bool,
  slug: PropTypes.string,
  setSaveable: PropTypes.func,
  projectId: PropTypes.string,
  chartDates: PropTypes.array,
  expenseCodes: PropTypes.array,
  projectCurrency: PropTypes.object,
  loading: PropTypes.bool,
  viewSummary: PropTypes.bool,
  anchorEl: PropTypes.object,
  onMenuClose: PropTypes.func,
  onAddRowHandler: PropTypes.func,
  availableExpenseCodes: PropTypes.array,
  onDeleteRowHandler: PropTypes.func,
  updatedParameters: PropTypes.array,
  projectPermissions: PropTypes.object
};

export default ExpenseScriptParamsEditor;
