import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useIntl } from 'react-intl';
import { DateField } from '~/modules/common/components';
import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';

const InputLabelProps = { shrink: true };

export const EditableDateField = ({
  name,
  labelResourceKey,
  onChange,
  ariaLabel,
  value,
  editable = true,
  clearable = true,
  variant = 'filled',
  dataQeId = 'EditableDateField'
}) => {
  const intl = useIntl();
  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <ArrowDropDownIcon />
        </InputAdornment>
      ),
      placeholder: intl.formatMessage({ id: 'rescheduleTaskDialog.noDateSet' })
    }),
    [intl]
  );

  return (
    <DateField
      name={name}
      variant={variant}
      label={labelResourceKey}
      onChange={onChange}
      ariaLabel={labelResourceKey}
      value={isoStringToObject(value)}
      editable={editable}
      InputProps={inputProps}
      clearable={clearable}
      InputLabelProps={InputLabelProps}
      dataQeId={dataQeId}
    />
  );
};

EditableDateField.propTypes = {
  name: PropTypes.string,
  clearable: PropTypes.bool,
  labelResourceKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  editable: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  dataQeId: PropTypes.string
};

export default EditableDateField;
