import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import { NoDataItem } from '~/modules/common/components';
import {
  ListTable,
  ListTableThemeProvider
} from '~/modules/common/components/ListTable';
import ProjectListRowSkeleton from './ProjectListRowSkeleton';
import { useProjectListProps } from './hooks/useProjectListProps';
import MobileProjectListItem from './MobileProjectList/MobileProjectListItem';
import MobileProjectListItemSkeleton from './MobileProjectList/MobileProjectListItemSkeleton';

const useListTableStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& td:last-child, th:last-child': {
      paddingRight: theme.spacing(2)
    },
    '& th:first-child': {
      paddingLeft: theme.spacing(6)
    }
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    color: theme.palette.grey[600],
    fontSize: theme.typography.body2.fontSize,
    top: 0
  },
  tableCell: {
    height: 40,
    padding: theme.spacing(0, 2)
  },
  tableRow: {
    '&:hover': {
      cursor: ({ submitting }) => (submitting ? 'default' : 'pointer')
    }
  }
}));

const useHeaderStyles = makeStyles(theme => ({
  bottomHeader: {
    top: theme.spacing(5)
  }
}));

const useNoProjectStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1)
    }
  }
}));

export const ProjectList = ({
  portfolioId,
  selectedProjects,
  enabledColumnGroups,
  enabledColumns,
  onRowClick,
  sort,
  submitting
}) => {
  const listClasses = useListTableStyles({ submitting });
  const headerClasses = useHeaderStyles();
  const noProjectsClasses = useNoProjectStyles();

  const {
    isLoading,
    loadMore,
    onSortChange,
    headers,
    columns,
    projects,
    hasMore
  } = useProjectListProps({
    portfolioId,
    selectedProjects,
    enabledColumnGroups,
    enabledColumns,
    sort,
    headerClasses
  });

  const selectedProjectIds = Object.entries(selectedProjects)
    .filter(([_, { selected }]) => selected)
    .map(([id]) => id);
  const selected = {
    records: selectedProjectIds,
    isSelectAll: false
  };

  return (
    <>
      {!isLoading && projects.length === 0 ? (
        <NoDataItem classes={noProjectsClasses}>
          <FormattedMessage id="projectList.noMatchingProjects" />
        </NoDataItem>
      ) : (
        <>
          <ListTableThemeProvider classes={listClasses}>
            <ListTable
              headers={headers}
              columns={columns}
              records={projects}
              hasMore={false}
              isLoading={projects.length === 0 && isLoading}
              mobileListItem={MobileProjectListItem}
              mobileListItemSkeleton={MobileProjectListItemSkeleton}
              variant="table"
              useInlineStyles={false}
              sort={sort}
              onSortChange={onSortChange}
              onRowClick={onRowClick}
              onListItemClick={onRowClick}
              elevation={0}
              selected={selected}
            />
          </ListTableThemeProvider>
          {projects.length > 0 && isLoading && <ProjectListRowSkeleton />}

          {!isLoading && hasMore && (
            <Waypoint bottomOffset="-25%" onEnter={loadMore} />
          )}
        </>
      )}
    </>
  );
};

ProjectList.propTypes = {
  portfolioId: PropTypes.string,
  selectedProjects: PropTypes.object,
  enabledColumnGroups: PropTypes.array,
  enabledColumns: PropTypes.array,
  onRowClick: PropTypes.func,
  sort: PropTypes.object,
  submitting: PropTypes.bool
};

export default ProjectList;
