import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useMenuState } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  secondaryActionsButton: {
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(-1)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  removeRequest: {
    color: '#e9055b'
  }
}));

export const SecondaryActions = ({ actions }) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const menuOpen = Boolean(anchorEl);

  const intl = useIntl();
  const classes = useStyles();

  const onActionClick = action => () => {
    if (action.onClick) action.onClick();
    onMenuClose();
  };

  const menuProps = {
    transformOrigin: { vertical: 'bottom', horizontal: 'left' }
  };

  return actions.length ? (
    <>
      <IconButton
        data-qe-id="ProjectRequest_MoreButton"
        className={classes.secondaryActionsButton}
        onClick={onMenuClick}
        aria-label={intl.formatMessage({
          id: 'resourceRequestActions.more'
        })}
        aria-owns={menuOpen ? 'action-menu' : undefined}
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={onMenuClose}
        transformOrigin={menuProps.transformOrigin}
      >
        {actions.map(action => [
          Boolean(action.id === 'remove-request') && actions.length > 1 && (
            <Divider className={classes.divider} light />
          ),
          <MenuItem
            data-qe-id={`${action.label}.Option`}
            key={action.id}
            onClick={onActionClick(action)}
            className={classes[action.className]}
          >
            {action.label}
          </MenuItem>
        ])}
      </Menu>
    </>
  ) : null;
};

SecondaryActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      className: PropTypes.string,
      primary: PropTypes.bool,
      secondary: PropTypes.bool
    })
  ).isRequired
};

export default SecondaryActions;
