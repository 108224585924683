import { makeStyles } from '@material-ui/core/styles';

export const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& tbody tr:last-child td': {
      borderBottom: 'unset'
    }
  },
  tableHeaderCell: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
    position: 'sticky',
    top: theme.spacing(8.5),
    zIndex: '2 !important',
    height: 'auto'
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
    borderCollapse: 'separate',
    height: 'auto'
  },
  tableFooterCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50],
    zIndex: '2 !important',
    position: 'sticky',
    bottom: 0,
    height: 'auto'
  },
  tableFooterRow: {
    backgroundColor: theme.palette.common.white
  }
}));

export const useColumnStyles = makeStyles(theme => ({
  estimate: {
    backgroundColor: theme.palette.grey[50]
  },
  estimates: {
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  totalEstimates: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    right: theme.spacing(5),
    zIndex: 1
  },
  deleteRow: {
    position: 'sticky',
    right: theme.spacing(-3),
    width: theme.spacing(8),
    minWidth: theme.spacing(8),
    zIndex: 1,
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50],
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  expenseType: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    width: theme.spacing(11),
    minWidth: theme.spacing(11),
    whiteSpace: 'break-spaces'
  },
  markUp: {
    position: 'sticky',
    left: theme.spacing(11),
    zIndex: 1,
    width: theme.spacing(9.25),
    minWidth: theme.spacing(9.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  billableType: {
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    left: theme.spacing(20),
    width: theme.spacing(20),
    minWidth: theme.spacing(20),
    zIndex: 1
  },
  noMarkUp: {
    left: theme.spacing(11)
  },
  noDeleteRow: {
    right: 0
  },
  extraZIndex: {
    zIndex: `3 !important`
  }
}));

export const useCardStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    boxShadow: 'none'
  }
}));
