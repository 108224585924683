import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: theme.spacing(2.25, 1.5),
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.Dela.mainBackground,
    color: theme.palette.primary.main
  },
  container: {
    display: 'flex'
  },
  buttons: {
    display: 'flex',
    margin: theme.spacing(0, 3),
    columnGap: theme.spacing(2)
  }
}));

export default useStyles;
