import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import TagsCardContentReadOnly from '~/modules/tags/components/TagsCard/TagsCardContentReadOnly';

const title = <FormattedMessage id="resourceDrawer.tags" />;

const useStyles = makeStyles(theme => ({
  readOnlyCard: {},
  tagItem: {
    minWidth: '100%',
    flexBasis: '100%',
    flexDirection: 'row',
    padding: theme.spacing(0.25, 0)
  },
  tagValue: {
    fontSize: theme.typography.body2.fontSize,
    flexBasis: '50%',
    textAlign: 'left',
    padding: theme.spacing(0, 0, 0, 1.5)
  },
  tagLabel: {
    flexBasis: '50%',
    textAlign: 'left',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const TagSection = ({ tags }) => {
  const classes = useStyles();

  return (
    <InfoSection editable={false} title={title}>
      <TagsCardContentReadOnly tags={tags} classes={classes} />
    </InfoSection>
  );
};

TagSection.propTypes = {
  tags: PropTypes.array
};

export default TagSection;
