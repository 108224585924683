import React from 'react';
import PropTypes from 'prop-types';

import { useIsBreakpointDown } from '~/modules/common/hooks';
import { NotSupportedPage } from '~/modules/common/components';
import ClientTimeAndExpense from './ClientTimeAndExpense';

export const TimeAndExpenseTab = props => {
  const isMobile = useIsBreakpointDown('sm');

  if (isMobile) return <NotSupportedPage />;

  return <ClientTimeAndExpense {...props} />;
};

TimeAndExpenseTab.propTypes = {
  width: PropTypes.string.isRequired
};

export default TimeAndExpenseTab;
