import { useQuery } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash.get';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTimeAndExpenseSupportedSettings } from '~/modules/common/timeAndExpenseSupportedSettings';
import { usePageTitle } from '~/modules/common/title';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  TimeAndExpenseDetails,
  TimeAndExpenseDetailsThemeContextProvider
} from '~/modules/time-expense';
import { CLIENT_ID_QUERY_BY_SLUG } from '../graphql';

const useTimeAndExpenseStyles = makeStyles({
  header: {
    top: 96
  },
  groupedheader: {
    top: 137
  }
});

export const ClientTimeAndExpense = ({ slug }) => {
  const intl = useIntl();
  const me = useMeContext();
  const { permissionsMap } = me;
  const canViewExpenseData = Boolean(
    permissionsMap['urn:replicon:combined-actuals-action:view-expense']
  );
  const { supportedSettings } = useTimeAndExpenseSupportedSettings({
    section: 'client'
  });
  const timeAndExpenseClasses = useTimeAndExpenseStyles();

  const { data, loading } = useQuery(CLIENT_ID_QUERY_BY_SLUG, {
    variables: { slug }
  });

  usePageTitle(
    loading
      ? []
      : [
          { messageId: 'routes.clients' },
          { title: get(data, 'client.name') },
          {
            messageId: canViewExpenseData
              ? 'client.timeAndExpense'
              : 'client.time'
          }
        ]
  );

  const id = get(data, 'client.id');

  const billingFilter = useMemo(
    () => ({
      clientUri: id
    }),
    [id]
  );

  if (loading) return <LinearProgress />;

  return (
    <TimeAndExpenseDetailsThemeContextProvider classes={timeAndExpenseClasses}>
      <TimeAndExpenseDetails
        me={me}
        intl={intl}
        supportedSettings={supportedSettings}
        id={id}
        objectSlug={slug}
        billingFilter={billingFilter}
      />
    </TimeAndExpenseDetailsThemeContextProvider>
  );
};

ClientTimeAndExpense.propTypes = {
  slug: PropTypes.string
};

export default ClientTimeAndExpense;
