import React from 'react';
import { retryLoading } from '~/util';

const NotificationsListPage = React.lazy(() =>
  retryLoading(() => import('./components/NotificationsListPage'))
);

const notificationsRoute = ({ intl, me }) => ({
  path: '/notifications',
  icon: () => null,
  hidden: true,
  title: () => intl.formatMessage({ id: 'routes.notifications' }),
  component: NotificationsListPage,
  hasPermission: true
});

export default notificationsRoute;
