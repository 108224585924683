import { useMemo, useCallback } from 'react';

export const useVendorResourcesStatesHandlers = ({
  selectedVendorResourcesItems,
  setSelectedVendorResourcesItems,
  removeUserAssignmentForVendor,
  closeDialog
}) => {
  const selectedResourcesCount = useMemo(
    () => ({ count: selectedVendorResourcesItems.length }),
    [selectedVendorResourcesItems.length]
  );

  const resetSelectedResourcesItems = useCallback(
    () => setSelectedVendorResourcesItems([]),
    [setSelectedVendorResourcesItems]
  );

  const isRowSelected = useCallback(
    record =>
      new Set(selectedVendorResourcesItems.map(x => x.id)).has(record.id),
    [selectedVendorResourcesItems]
  );

  const onSelectClick = useCallback(
    record => event => {
      setSelectedVendorResourcesItems([record]);
    },
    [setSelectedVendorResourcesItems]
  );

  const onConfirmClick = useCallback(async () => {
    await removeUserAssignmentForVendor({
      userUri: selectedVendorResourcesItems[0].id,
      vendorUri: null
    });
    resetSelectedResourcesItems();
    closeDialog();
  }, [
    closeDialog,
    removeUserAssignmentForVendor,
    resetSelectedResourcesItems,
    selectedVendorResourcesItems
  ]);

  const onClearClick = useCallback(() => resetSelectedResourcesItems(), [
    resetSelectedResourcesItems
  ]);

  return {
    selectedResourcesCount,
    resetSelectedResourcesItems,
    isRowSelected,
    onSelectClick,
    onConfirmClick,
    onClearClick
  };
};

export default useVendorResourcesStatesHandlers;
