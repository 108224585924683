import { Formik } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { ProjectRequestStatusType } from '~/types';
import {
  useFormState,
  useGatesFormik,
  useUpdateGates,
  usePutResourceAssignments,
  useResourceAssignmentFormik
} from './hooks';
import {
  useUnsavedChangesDialogProps,
  UnsavedChangesWarningDialog
} from './components';
import { ProjectRequestDrawerContent } from './ProjectRequestDrawerContent';
import ProjectRequestDrawerFooter from './ProjectRequestDrawerFooter';
import { ProjectRequestDrawerHeader } from './ProjectRequestDrawerHeader';
import { ProjectRequestDrawerContentReadOnly } from './ProjectRequestDrawerContentReadOnly';

export const InnerProjectRequestDrawerForm = ({
  editable,
  setEditable,
  onClose,
  projectRequestDetails,
  attachmentsData
}) => {
  const { id, statusGates, resourceAssignments } = projectRequestDetails || {};
  const inReviewStatusTypeGates =
    (statusGates || []).find(
      x => x.status.type === ProjectRequestStatusType.InReview
    )?.gates || [];

  const [editableGates, setEditableGates] = useState(false);

  const { updateGates } = useUpdateGates({
    projectRequestId: id,
    refetchQueries: ['projectRequest']
  });
  const gatesFormik = useGatesFormik({
    gates: inReviewStatusTypeGates,
    updateGates,
    setEditableGates
  });

  const { putResourceAssignments } = usePutResourceAssignments({
    projectRequestId: id
  });

  const resourceAssignmentFormik = useResourceAssignmentFormik({
    resourceAssignments,
    putResourceAssignments
  });

  const {
    openDialog,
    onCloseDialog,
    onDrawerCloseWithWarning,
    warningMessageId
  } = useUnsavedChangesDialogProps({
    editableGates,
    onClose,
    resourceAssignmentFormik
  });

  return (
    <>
      <div data-qe-id="ProjectRequestDrawer">
        <ProjectRequestDrawerHeader
          onClose={onDrawerCloseWithWarning}
          editable={editable}
          setEditable={setEditable}
          gatesFormik={gatesFormik}
        />
        {editable ? (
          <ProjectRequestDrawerContent />
        ) : (
          <ProjectRequestDrawerContentReadOnly
            attachmentsData={attachmentsData}
            gatesFormik={gatesFormik}
            editableGates={editableGates}
            setEditableGates={setEditableGates}
            resourceAssignmentFormik={resourceAssignmentFormik}
          />
        )}
        <ProjectRequestDrawerFooter
          onClose={onClose}
          setEditable={setEditable}
          editable={editable}
          projectRequestName={projectRequestDetails?.name}
          gatesFormik={gatesFormik}
          portfolioId={projectRequestDetails?.portfolio?.id}
          portfolioName={projectRequestDetails?.portfolio?.displayText}
        />
      </div>
      {openDialog && (
        <UnsavedChangesWarningDialog
          open={openDialog}
          onClose={onCloseDialog}
          warningMessageId={warningMessageId}
        />
      )}
    </>
  );
};

InnerProjectRequestDrawerForm.propTypes = {
  onClose: PropTypes.func,
  editable: PropTypes.bool,
  setEditable: PropTypes.func,
  projectRequestDetails: PropTypes.object,
  attachmentsData: PropTypes.object
};

export const ProjectRequestDrawerForm = ({
  editable,
  setEditable,
  onClose,
  projectRequestDetails,
  attachmentsData
}) => {
  const { initialValues, validationSchema, onSubmit } = useFormState({
    onClose,
    projectRequestDetails,
    setEditable
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      <InnerProjectRequestDrawerForm
        editable={editable}
        setEditable={setEditable}
        onClose={onClose}
        projectRequestDetails={projectRequestDetails}
        attachmentsData={attachmentsData}
      />
    </Formik>
  );
};

ProjectRequestDrawerForm.propTypes = {
  onClose: PropTypes.func,
  editable: PropTypes.bool,
  setEditable: PropTypes.func,
  projectRequestDetails: PropTypes.object,
  attachmentsData: PropTypes.object
};

export default ProjectRequestDrawerForm;
