import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/RemoveSharp';
import { Decimal } from '~/modules/common/components';
import { FieldLabel } from '../../common/components';

const useStyles = makeStyles(theme => ({
  hours: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightMedium
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  varianceIcon: {
    fontSize: theme.typography.body2.fontSize,
    marginBottom: '1px'
  },
  negative: {
    color: theme.palette.error.main
  }
}));

const AvailabiltyInfoVariance = ({ hours }) => {
  const classes = useStyles();

  const icon = !hours ? null : hours > 0 ? (
    <AddIcon className={classNames(classes.varianceIcon)} />
  ) : (
    <RemoveIcon
      className={classNames(classes.varianceIcon, classes.negative)}
    />
  );

  return (
    <>
      {icon}
      <Decimal
        className={classNames(classes.label, { [classes.negative]: hours < 0 })}
        value={Math.abs(hours)}
      />
      <FieldLabel
        variant="body2"
        color="textSecondary"
        messageId="taskAllocationEditor.hoursSuffix"
        className={classNames(classes.hours, { [classes.negative]: hours < 0 })}
      />
    </>
  );
};

AvailabiltyInfoVariance.propTypes = {
  hours: PropTypes.number.isRequired
};

export default AvailabiltyInfoVariance;
