import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { mapToServiceRequest } from '~/modules/resourcing/common/util';
import { ResourceRequestStatus } from '~/types';
import { TENTATIVE_RESOURCE_REQUEST } from './withMarkResourceRequestAsTentative';

const useMarkResourceRequestAsTentative = ({ resourceRequest }) => {
  const [markResourceRequestAsTentative] = useMutation(
    TENTATIVE_RESOURCE_REQUEST
  );

  const onMarkResourceRequestAsTentative = useCallback(
    () =>
      markResourceRequestAsTentative(
        mapToServiceRequest({
          request: resourceRequest,
          responseType: 'markResourceRequestAsTentative2',
          responseTypeName: 'MarkResourceRequestAsTentativeResult',
          requestStatus: ResourceRequestStatus.Tentative
        })
      ),
    [markResourceRequestAsTentative, resourceRequest]
  );

  return {
    onMarkResourceRequestAsTentative
  };
};

export default useMarkResourceRequestAsTentative;
