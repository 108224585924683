import { gql } from 'graphql-tag';

export const projectListItemFragment = gql`
  fragment ProjectListItem on Project {
    id
    uri
    slug
    name
    code
    displayText
    startDate: startDate2
    endDate: endDate2
    status @include(if: $includeWorkflowStatus) {
      id
      uri
      displayText
    }
    client @include(if: $includeClient) {
      id
      displayText
    }
    portfolio @include(if: $includePortfolio) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    program @include(if: $includeProgram) {
      id
      displayText
    }
    projectManagerReference @include(if: $includeProjectManagerReference) {
      id
      uri
      displayText
    }
    actualBilling @include(if: $includeActualBilling) {
      ...MoneyFragment
    }
    actualBillingInProjectCurrency
      @include(if: $includeActualBillingInProjectCurrency) {
      amount
      currency {
        symbol
        displayText
      }
    }
    revenueRecognitionPolicy @include(if: $includeRevenueRecognitionPolicy) {
      id
      displayText
    }
    budgetedCost @include(if: $includeBudgetCost) {
      ...MoneyFragment
    }
    budgetedCostInProjectCurrency
      @include(if: $includeBudgetCostInProjectCurrency) {
      amount
      currency {
        symbol
        displayText
      }
    }
    budgetCostAmountInPortfolioCurrency
      @include(if: $includeBudgetCostAmountInPortfolioCurrency)
    actualExpenseCost @include(if: $includeActualExpenseCost) {
      ...MoneyFragment
    }
    actualResourceCost @include(if: $includeActualResourceCost) {
      ...MoneyFragment
    }
    actualCost @include(if: $includeActualCost) {
      ...MoneyFragment
    }
    actualCostInProjectCurrency
      @include(if: $includeActualCostInProjectCurrency) {
      amount
      currency {
        symbol
        displayText
      }
    }
    actualCostAmountInPortfolioCurrency
      @include(if: $includeActualCostAmountInPortfolioCurrency)
    estimatedCost @include(if: $includeEstimatedCost) {
      ...MoneyFragment
    }
    tags {
      tag {
        id
        name
      }
      value {
        id
        value
      }
    }
    budgetHours @include(if: $includeBudgetHours)
    totalTaskHours @include(if: $includeTotalTaskHours)
    estimatedTaskCompletionDate: estimatedTaskCompletionDate2
      @include(if: $includeEstimatedTaskCompletionDate)
    budgetHoursUsed @include(if: $includeBudgetHoursUsed)
    initialTaskStartDate: initialTaskStartDate2
      @include(if: $includeInitialTaskStartDate)
    finalTaskEndDate: finalTaskEndDate2 @include(if: $includeFinalTaskEndDate)
    actualHours @include(if: $includeActualHours)
    estimatedHours @include(if: $includeEstimatedHours)
    projectManagementType
    permittedActionUris
    estimatedHoursAtCompletion @include(if: $includeEstimatedHoursAtCompletion)
    totalEstimatedContract @include(if: $includeEstimatedContract) {
      ...MoneyFragment
    }
    totalEstimatedContractInProjectCurrency
      @include(if: $includeEstimatedContractInProjectCurrency) {
      amount
      currency {
        symbol
        displayText
      }
    }
    rescheduleProjectJob {
      jobId
      projectId
    }
    costCenter @include(if: $includeCostCenter) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    division @include(if: $includeDivision) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    serviceCenter @include(if: $includeServiceCenter) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    location @include(if: $includeLocation) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    department @include(if: $includeDepartment) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    employeeType @include(if: $includeEmployeeType) {
      id
      displayText
      ancestors {
        displayText
      }
    }
    coManagerCount
    budgetHoursRemaining
    estimatedBudgetHoursRemaining
    scopeHoursCompleted
    rolledUpEstimatedRemainingHours
    estimatedCostAtCompletion @include(if: $includeEstimatedCostAtCompletion) {
      ...MoneyFragment
    }
    estimatedCostAtCompletionInProjectCurrency
      @include(if: $includeEstimatedCostAtCompletionInProjectCurrency) {
      amount
      currency {
        symbol
        displayText
      }
    }
    estimatedCostAtCompletionInPortfolioCurrency
      @include(if: $includeEstimatedCostAtCompletionInPortfolioCurrency)
    budgetCostUsedPercentage @include(if: $includeBudgetCostUsedPercentage)
    estimatedBudgetCostRemaining
      @include(if: $includeEstimatedBudgetCostRemaining) {
      ...MoneyFragment
    }
    estimatedBudgetCostRemainingInProjectCurrency
      @include(if: $includeEstimatedBudgetCostRemainingInProjectCurrency) {
      amount
      currency {
        symbol
        displayText
      }
    }
    estimatedBudgetCostRemainingInPortfolioCurrency
      @include(if: $includeEstimatedBudgetCostRemainingInPortfolioCurrency)
  }

  fragment MoneyFragment on Money {
    amount
    currency {
      id
      symbol
      displayText
    }
  }
`;
