import { columns } from '~/modules/common/hooks/project/projectColumns';

const defaultColumns = [
  columns.name.field,
  columns.workflowStatus.field,
  columns.portfolio.field,
  columns.client.field,
  columns.projectManagerReference.field
];

export default defaultColumns;
export { defaultColumns as mobileColumnNames };
