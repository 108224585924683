import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const clientCanBeDeletedQuery = gql`
  query GetClientCanBeDeleted($slug: String!) {
    client(slug: $slug) {
      id
      canBeDeleted
    }
  }
`;

const useCanClientBeDeleted = ({ slug }) => {
  const { loading, error, data } = useQuery(clientCanBeDeletedQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slug
    },
    errorPolicy: 'all'
  });

  return {
    loading,
    canBeDeleted: data && data.client ? data.client.canBeDeleted : true,
    error
  };
};

export default useCanClientBeDeleted;
