import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DateRange } from '~/modules/common/components';

export const TotalEstimatesLabel = ({
  projectStartDate,
  projectEndDate,
  showActuals
}) => (
  <>
    <FormattedMessage
      id={
        showActuals
          ? 'allowedExpensesAndEstimates.columns.totalEstimatesAndActuals'
          : 'allowedExpensesAndEstimates.columns.totalEstimates'
      }
    />
    <div>
      <DateRange start={projectStartDate} end={projectEndDate} />
    </div>
  </>
);

TotalEstimatesLabel.propTypes = {
  projectStartDate: PropTypes.string,
  projectEndDate: PropTypes.string,
  showActuals: PropTypes.bool
};
