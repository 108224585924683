import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  extractFirstValidationError,
  extractFirstErrorMessage
} from '~/modules/common/graphql/errors';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { isValid } from '~/modules/programs/program/validator';
import { useMeContext } from '~/modules/me';
import { useAddProgramMutation } from './useAddProgramMutation';

export const useAddProgramOnSubmit = ({
  history,
  onClose,
  initialName = '',
  onProgramAdd
}) => {
  const [error, setError] = useState();
  const intl = useIntl();
  const me = useMeContext();

  const clearError = useCallback(() => setError(null), [setError]);

  const mapValuesOnSubmit = useCallback(
    values => ({
      name: values.name,
      programManagerReference: isValid(values.programManagerReference)
        ? {
            id: values.programManagerReference.id,
            displayText: values.programManagerReference.displayText
          }
        : null
    }),
    []
  );

  const [addProgram] = useAddProgramMutation();

  const onSubmit = useCallback(
    async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);

      const payload = mapValuesOnSubmit(values);

      try {
        const { data } = await addProgram({
          variables: {
            addProgramInput: payload
          },
          refetchQueries: ['ProgramDropdownSearch'],
          awaitRefetchQueries: true
        });

        const { addProgram2: { program: { slug = '' } } = {} } = data;

        if (onProgramAdd) {
          onProgramAdd(data.addProgram2.program);
          onClose();

          return;
        }

        onClose();
        history.push(`/programs/${slug}?edit=true`);
      } catch (err) {
        const validationErrors = extractFirstValidationError(err);

        if (validationErrors) {
          const duplicationNameError = validationErrors.find(
            v =>
              v.failureUri ===
              'urn:replicon:validation-failure:program-name-duplicated'
          );

          setFieldError(
            'name',
            duplicationNameError
              ? intl.formatMessage({
                  id: 'addProgram.duplicateNameValidation'
                })
              : null
          );
        } else {
          setError(extractFirstErrorMessage(err));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [addProgram, history, mapValuesOnSubmit, onClose, intl]
  );

  return {
    onSubmit,
    error,
    clearError,
    initialState: initialState({ me, initialName })
  };
};

export const initialState = ({ me, initialName = '' }) => ({
  name: initialName,
  programManagerReference: me.userAccessRoles.includes(
    USER_ACCESS_ROLE.PROGRAM_MANAGER
  )
    ? {
        id: me.uri,
        displayText: me.displayText
      }
    : null
});

export default useAddProgramOnSubmit;
