import { useEffect, useState, useMemo } from 'react';

import psaEn from './messages/en';

const mergeFallbacks = messages => ({
  ...psaEn,
  ...messages
});

/**
 * For PERFORMANCE reasons this function directly mutates the messages
 * object. This is due to the size of the messages object, which is 3500+
 * records.
 */
const replaceTerms = (customTerms, messages) => {
  Object.keys(messages).forEach(key => {
    // eslint-disable-next-line no-param-reassign
    messages[key] = customTerms.reduce(
      (m, term) =>
        m.replace(
          new RegExp(`(^|\\W)${term.originalTerm}($|\\W)`, 'gi'),
          `$1${term.customTerm.replace(/\\/g, '\\\\')}$2`
        ),
      messages[key]
    );
  });

  return messages;
};

const buildMessages = locales => (locale, customTerms = []) => {
  const lcLocale = locale.toLowerCase();
  const messages =
    locales[lcLocale] ||
    locales[Object.keys(locales).find(k => lcLocale.startsWith(k))] ||
    psaEn;
  const messagesWithFallbacks = mergeFallbacks(messages);

  if (customTerms.length) {
    return replaceTerms(customTerms, messagesWithFallbacks);
  }

  return messagesWithFallbacks;
};

const LANGUAGE_CODE_FILE_MAP = {
  'en-us': 'en',
  'sw-ke': 'sw'
};

const useLocaleMessages = (locale, customTerms) => {
  const baseMessages = useMemo(
    () =>
      buildMessages({
        en: psaEn
      })(locale, customTerms),
    [customTerms, locale]
  );
  const [messages, setMessages] = useState(
    locale === 'en-US' ? baseMessages : null
  );

  useEffect(() => {
    const lcLocale = locale.toLowerCase();
    const localeShortCode = lcLocale.split('-')[0];

    const updateLanguageMessages = async () => {
      const languageFile = LANGUAGE_CODE_FILE_MAP[lcLocale] || lcLocale;
      const psaLocale = await import(`./messages/${languageFile}`);

      if (!Intl.PluralRules) {
        await import('@formatjs/intl-pluralrules/polyfill');
        await import(
          `@formatjs/intl-pluralrules/dist/locale-data/${localeShortCode}`
        );
      }

      if (!Intl.RelativeTimeFormat) {
        await import('@formatjs/intl-relativetimeformat/polyfill');
        await import(
          `@formatjs/intl-relativetimeformat/dist/locale-data/${localeShortCode}`
        );
      }

      setMessages(
        buildMessages({
          en: psaEn,
          [lcLocale]: psaLocale.default
        })(lcLocale, customTerms)
      );
    };

    updateLanguageMessages();
  }, [customTerms, locale]);

  return messages;
};

export default useLocaleMessages;
