import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ViewResourceRequestListItem from '~/modules/resourcing/common/components/ViewResourceRequestListItem';

export const useContentStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0, -1, 0),
    padding: 0
  },
  subTitle: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(2, 0, 1, 0),
    padding: 0
  }
}));

export const ViewMultipleResourceRequestContent = ({ resourceRequests }) => {
  const containerStyles = useContentStyles();

  return (
    <div className={containerStyles.root}>
      <h3 className={containerStyles.title}>
        <FormattedMessage id="resourceRequestItem.resourceRequests" />
      </h3>
      <h4 className={containerStyles.subTitle}>
        <FormattedMessage id="resourceRequestItem.viewMultipleResourceRequestDescription" />
      </h4>
      <List>
        {resourceRequests.map((request, index) => (
          <ViewResourceRequestListItem
            key={request.id}
            request={request}
            qeIdTag={`ResourceRequest_ListItem_${index}`}
            resourceRequests={resourceRequests}
            button={false}
            showResourceRequestDetailIndicator
          />
        ))}
      </List>
    </div>
  );
};

ViewMultipleResourceRequestContent.propTypes = {
  resourceRequests: PropTypes.array
};

export default ViewMultipleResourceRequestContent;
