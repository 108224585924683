import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FilledInput
} from '@material-ui/core';
import { NoneMenuItem } from '~/modules/common/components';

const label = <FormattedMessage id="client.country" />;
const input = <FilledInput />;

export const CountrySelect = ({ countries, value = '', onChange, name }) => (
  <FormControl variant="filled" fullWidth>
    <InputLabel shrink={Boolean(value)}>{label}</InputLabel>
    <Select name={name} value={value} input={input} onChange={onChange}>
      <NoneMenuItem value="">
        <FormattedMessage id="country.none" />
      </NoneMenuItem>
      {countries.map(country => (
        <MenuItem key={country.id} value={country.id}>
          {country.displayText}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

CountrySelect.propTypes = {
  countries: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default CountrySelect;
