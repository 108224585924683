import { gql } from 'graphql-tag';

const resourceMatchesFragment = gql`
  fragment ResourceMatchesFragment on ResourceRequest {
    resourceMatches(
      page: $resourceMatchesPage
      pageSize: $resourceMatchesPageSize
    ) @include(if: $isPsaPraaResourcingUIEnabled) {
      id
      displayText
    }
  }
`;

export default resourceMatchesFragment;
