import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import SearchBox from '~/modules/common/components/SearchBox/SearchBox';
import getFacets from './facets';

const useSearchStyles = makeStyles(() => ({
  root: {},
  searchFacet: {
    overflow: 'auto',
    maxHeight: 295
  }
}));

const useStyles = makeStyles(() => ({
  searchBoxContainer: {
    padding: 0,
    flexGrow: 1,
    flexShrink: 1
  },
  searchBox: {},
  searchFacet: {
    overflow: 'auto',
    maxHeight: 295
  }
}));

export const ResourceRequestSearchbox = ({
  classes: classesOverrides,
  setSearchCriteria,
  searchCriteria
}) => {
  const facets = getFacets();

  const classes = useStyles({ classes: classesOverrides });
  const { formatMessage } = useIntl();
  const expandIcon = formatMessage({ id: 'resourcePoolSearchBox.expand' });

  const searchBoxClasses = useSearchStyles();

  return (
    <>
      {setSearchCriteria && (
        <div className={classes.searchBoxContainer}>
          <SearchBox
            searchIconAriaLabel={expandIcon}
            classes={searchBoxClasses}
            setSearchCriteria={setSearchCriteria}
            searchCriteria={searchCriteria}
            facets={facets}
          />
        </div>
      )}
    </>
  );
};

ResourceRequestSearchbox.propTypes = {
  classes: PropTypes.object,
  setSearchCriteria: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired
};

export default ResourceRequestSearchbox;
