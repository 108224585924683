import React from 'react';
import PropTypes from 'prop-types';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import get from 'lodash.get';
import { NavigationBreadcrumb } from '~/modules/common/components';
import { slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotFound } from '~/modules/common/notfound';

export const CLIENT_NAME_QUERY_BY_SLUG = gql`
  query clientBySlug($slug: String!) {
    client(slug: $slug) {
      id
      name
    }
  }
`;

const paths = [
  {
    to: '/clients',
    title: <FormattedMessage id="routes.clients" />
  }
];

export const ClientPageBreadcrumb = ({ match }) => {
  const { params } = match;
  const { slug } = params;

  const { loading, data, error } = useQuery(CLIENT_NAME_QUERY_BY_SLUG, {
    variables: { slug }
  });

  if (error) return slugNotFound({ data: { error } }) && <RedirectToNotFound />;

  const name = loading ? '' : get(data, 'client.name');

  return <NavigationBreadcrumb paths={paths} title={name} />;
};

ClientPageBreadcrumb.propTypes = {
  match: PropTypes.object
};

export default withRouter(ClientPageBreadcrumb);
