import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ZeroTimeIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path
        fill="#1f5ff3"
        d="M 3.6363412,0 C 1.6280454,0 5.5225921e-8,1.6281206 0,3.6365092 V 20.3646 C 6.1248903e-4,22.372555 1.6284785,24 3.6363412,24 H 20.363659 C 22.371955,24 24,22.371879 24,20.363491 V 3.6365092 C 24,1.6281207 22.371955,1.1046205e-7 20.363659,0 Z"
      />
      <path
        fill="#fff"
        d="m 6.435547,4.8496094 v 2.7871094 h 6.052734 L 15.423828,5.484375 13.509766,7.6367188 6.181641,16.388672 v 2.787109 h 11.517578 v -2.787109 h -6.96875 l -2.363282,1.816406 1.654297,-1.816406 7.347657,-8.7519532 V 4.8496094 Z"
      />
    </>
  </SvgIcon>
));

export default ZeroTimeIcon;
