import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS } from '~/modules/project-request/enums';
import { usePortfolioProjectRequestsContext } from '~/modules/portfolios/portfolio//PortfolioProjectRequestsContext';
import { ProjectRequestStatusType } from '~/types';

const makeRequestStatusClickHandler = ({
  history,
  requestStatus,
  setKeys,
  setSearchCriteria
}) => () => {
  setKeys(
    PORTFOLIO_PROJECTREQUEST_SUMMARY_FILTER_TABS.SUB_PORTFOLIOS,
    requestStatus
  );
  setSearchCriteria({
    criterions: {},
    keywords: []
  });
  history.push({
    pathname: `${history.location.pathname}/requests`
  });
};

export default () => {
  const history = useHistory();
  const {
    summaryFilterState: { setKeys },
    setSearchCriteria
  } = usePortfolioProjectRequestsContext();
  const onDataClick = useCallback(
    (_, e) => {
      if (e && e.datum && e.datum.id) {
        makeRequestStatusClickHandler({
          history,
          requestStatus: e.datum.id,
          setKeys,
          setSearchCriteria
        })();
      }

      return null;
    },
    [history, setKeys, setSearchCriteria]
  );

  const events = [
    {
      childName: ['all'],
      target: 'data',
      eventHandlers: {
        onClick: onDataClick
      }
    }
  ];

  return useMemo(
    () => ({
      events,
      legendClickHandlers: {
        [ProjectRequestStatusType.New]: makeRequestStatusClickHandler({
          history,
          requestStatus: ProjectRequestStatusType.New,
          setKeys,
          setSearchCriteria
        }),
        [ProjectRequestStatusType.InReview]: makeRequestStatusClickHandler({
          history,
          requestStatus: ProjectRequestStatusType.InReview,
          setKeys,
          setSearchCriteria
        }),
        [ProjectRequestStatusType.Approved]: makeRequestStatusClickHandler({
          history,
          requestStatus: ProjectRequestStatusType.Approved,
          setKeys,
          setSearchCriteria
        }),
        [ProjectRequestStatusType.Rejected]: makeRequestStatusClickHandler({
          history,
          requestStatus: ProjectRequestStatusType.Rejected,
          setKeys,
          setSearchCriteria
        })
      }
    }),
    [events, history, setKeys, setSearchCriteria]
  );
};
