import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  makeStyles,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import AddProjectDialogForm from '~/modules/common/components/AddProjectDialogForm';
import {
  useAddProjectForm,
  useCopyProjectPermissions,
  useProjectCodeSettings
} from '~/modules/common/hooks/project';
import { DIALOG_FORM_TYPE } from '~/modules/common/enums/AddProjectDialogFormTypes';
import { CopyProjectDialogDetails } from '~/modules/common/components/CopyProjectDialog/CopyProjectDialogDetails';
import { COPY_PROJECT_FLOW } from '~/modules/common/components/CopyProjectDialog/enums';
import { ClientBySlugDocument } from '~/types';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.sm
    }
  },
  title: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  titleText: {
    padding: theme.spacing(1, 2),
    ...theme.typography.h6
  }
}));

const labels = {
  addNewProject: <FormattedMessage id="addProject.addNewProject" />,
  copyProject: <FormattedMessage id="duplicateProject.title" />
};

const AddClientProjectDialog = ({
  clientId,
  clientName,
  clientCurrency,
  projectType,
  open,
  onClose,
  customFieldDefinitions,
  clientSlug
}) => {
  const classes = useStyles();
  const fullScreen = useIsBreakpointDown('sm');
  const {
    nextProjectCode,
    projectCodeSettingsLoading
  } = useProjectCodeSettings();

  const form = useAddProjectForm({
    type: DIALOG_FORM_TYPE.CLIENT,
    clientId,
    clientName,
    clientCurrency,
    projectType,
    customFieldDefinitions,
    projectCode: nextProjectCode,
    onClose,
    refetchQueries: [
      'getProjects',
      'Eager_GetProjectsStatusCountSummary',
      {
        query: ClientBySlugDocument,
        variables: { slug: clientSlug }
      }
    ]
  });

  const { handleReset } = form;

  const {
    canCopyProject,
    hasEditProgramPermission: canEditProgram
  } = useCopyProjectPermissions();

  const [tab, setTab] = useState(0);

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
    setTab(0);
  }, [handleReset, onClose]);

  const handleTabChange = useCallback((_, value) => setTab(value), [setTab]);

  const client = useMemo(() => ({ id: clientId, displayText: clientName }), [
    clientId,
    clientName
  ]);

  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [
    classes.paper
  ]);

  if (projectCodeSettingsLoading) return null;

  return (
    <>
      <Dialog
        classes={dialogClasses}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title}>
          {canCopyProject ? (
            <Tabs
              value={tab}
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              <Tab label={labels.addNewProject} />
              <Tab label={labels.copyProject} />
            </Tabs>
          ) : (
            <Typography className={classes.titleText}>
              <FormattedMessage
                id="addProject.addNewProjectToClient"
                values={{ client: clientName }}
              />
            </Typography>
          )}
        </DialogTitle>
        {canCopyProject ? (
          <TabView
            tab={tab}
            form={form}
            client={client}
            projectType={projectType}
            canEditProgram={canEditProgram}
            handleClose={handleClose}
          />
        ) : (
          <AddProjectDialogForm
            type={DIALOG_FORM_TYPE.CLIENT}
            onCancel={handleClose}
            {...form}
          />
        )}
      </Dialog>
    </>
  );
};

export const TabView = ({
  tab,
  form,
  client,
  handleClose,
  projectType,
  canEditProgram
}) =>
  tab === 0 ? (
    <AddProjectDialogForm
      type={DIALOG_FORM_TYPE.CLIENT}
      onCancel={handleClose}
      {...form}
    />
  ) : (
    <CopyProjectDialogDetails
      client={client}
      onClose={handleClose}
      screenFlow={COPY_PROJECT_FLOW.ADD_PROJECT}
      projectType={projectType}
      canEditClient
      canEditProgram={canEditProgram}
    />
  );

TabView.propTypes = {
  tab: PropTypes.number,
  form: PropTypes.object,
  client: PropTypes.object,
  handleClose: PropTypes.func,
  canEditProgram: PropTypes.bool,
  projectType: PropTypes.string
};

AddClientProjectDialog.propTypes = {
  clientId: PropTypes.string,
  clientName: PropTypes.string,
  clientCurrency: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customFieldDefinitions: PropTypes.array,
  projectType: PropTypes.string,
  clientSlug: PropTypes.string
};

export default AddClientProjectDialog;
