import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import OkButton from '~/modules/common/components/OkButton';

const UnsavedChangesWarningDialog = ({ open, onClose, warningMessageId }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="unsaved-changes-warning-dialog-title"
    aria-describedby="unsaved-changes-warning-dialog-description"
  >
    <DialogTitle id="unsaved-changes-warning-dialog-title">
      <FormattedMessage id="projectRequest.unsavedChangesPrompt.title" />
    </DialogTitle>
    <DialogContent id="entity-confirmation-dialog-description">
      <DialogContentText>
        <FormattedMessage id={warningMessageId} />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <OkButton autoFocus variant="text" onClick={onClose} />
    </DialogActions>
  </Dialog>
);

UnsavedChangesWarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  warningMessageId: PropTypes.string,
  onClose: PropTypes.func
};

export default UnsavedChangesWarningDialog;
