import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import GoBackContext from './GoBackContext';

const GoBackContextProvider = ({ children }) => {
  const [search, setSearch] = useState('');
  const value = useMemo(() => ({ search, setSearch }), [search, setSearch]);

  return (
    <GoBackContext.Provider value={value}>{children}</GoBackContext.Provider>
  );
};

GoBackContextProvider.propTypes = {
  children: PropTypes.node
};

export default GoBackContextProvider;
