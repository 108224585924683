import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  confirmationMessage: {
    width: '100%'
  },
  actions: {
    flexWrap: 'wrap',
    paddingTop: theme.spacing(4)
  }
}));

const TooManyProjectRequestsDialog = ({
  open,
  onClose,
  maxNumberOfRequestsAllowed
}) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        <FormattedMessage id="projectRequest.addExistingProjectRequestDialog.tooManyRequestsSelected" />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.confirmationMessage}>
          <FormattedMessage
            id="projectRequest.addExistingProjectRequestDialog.tooManyRequestsSelectedMessage"
            values={maxNumberOfRequestsAllowed}
          />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose}>
          <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TooManyProjectRequestsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxNumberOfRequestsAllowed: PropTypes.object
};

export default TooManyProjectRequestsDialog;
