import React from 'react';
import { hasPermission } from '../../resourcingRoute';

const ResourceAssignmentDialog = React.lazy(() =>
  import('./ResourceAssignmentDialog')
);

const route = ({ me }) => ({
  path:
    '/projectResourcing/assignment/:groupUri/:planStatus/:resourceRequestId',
  icon: () => null,
  hidden: true,
  title: () => '',
  component: ResourceAssignmentDialog,
  hasPermission: hasPermission(me)
});

export default route;
