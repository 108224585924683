import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { portfolioTag } from '~/modules/common/components/SearchBox/Facets/PortfolioFacet/tag';
import PortfolioProjectsQuickFilterSummary from '../PortfolioProjectsQuickFilterSummary';
import { usePortfolioProjectContext } from '../PortfolioProjectsContext';
import ProjectSearchBox from './ProjectSearchBox';

const useStyles = makeStyles(theme => ({
  toolBar: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2, 1, 2),
    minHeight: '-webkit-fit-content',
    left: 0,
    right: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar - 2,
    [theme.breakpoints.down('xs')]: {
      flexDirection: `column-reverse`,
      alignItems: 'flex-end',
      padding: theme.spacing(1, 2)
    }
  }
}));

const useSearchStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0, 0, 0),
      width: '100%'
    }
  }
}));

export const Toolbar = ({ portfolioId }) => {
  const classes = useStyles();
  const projectSearchStyles = useSearchStyles();

  const {
    searchCriteria,
    summaryFilterState,
    setSearchCriteria
  } = usePortfolioProjectContext();

  const mapSearchCriteria = useMemo(
    () => ({
      ...searchCriteria,
      criterions: {
        ...(searchCriteria.criterions || {}),
        [portfolioTag]: [{ value: portfolioId }]
      }
    }),
    [portfolioId, searchCriteria]
  );

  return (
    <>
      <div className={classes.toolBar}>
        {setSearchCriteria && (
          <ProjectSearchBox classes={projectSearchStyles} />
        )}
      </div>
      <PortfolioProjectsQuickFilterSummary
        key={
          summaryFilterState.quickFilter &&
          summaryFilterState.quickFilter.filterByStatus
        }
        searchCriteria={mapSearchCriteria}
        summaryFilterState={summaryFilterState}
        hideMyProjects
      />
    </>
  );
};

Toolbar.propTypes = {
  portfolioId: PropTypes.string
};

export default Toolbar;
