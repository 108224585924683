import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import AddBillingMenuButton from '~/modules/billing-invoicing/common/components/AddBillingMenuButton';
import BillingTransactionTab from '~/modules/billing-invoicing/common/components/BillingTransactionTab';
import { getTransitionUrlByRecordTypeForClient } from '~/modules/billing-invoicing/common/util';
import {
  BillingTransactionTypeFacet,
  DateRangeFacet,
  BillStatusFacet
} from '~/modules/common/components/SearchBox/Facets';

const getSearchFacets = () => [
  BillStatusFacet,
  DateRangeFacet,
  BillingTransactionTypeFacet
];

export const TransactionsSubTab = ({
  client,
  editable,
  permissions: { canAddBill, canAddPayment, canAddCreditMemo },
  headerLevel
}) => {
  const { id, slug, displayText } = client;
  const history = useHistory();
  const searchFacets = useMemo(() => getSearchFacets(), []);
  const showAddButton =
    editable && (canAddBill || canAddPayment || canAddCreditMemo);

  const onRowClickHandler = useCallback(
    record => {
      const transitionUrl = getTransitionUrlByRecordTypeForClient({
        record,
        clientSlug: slug
      });

      if (transitionUrl) {
        history.push(transitionUrl);
      }
    },
    [history, slug]
  );

  const resourceKeys = useMemo(
    () => ({
      message: 'billingTransactionList.noData.title.client',
      description: showAddButton
        ? 'billingTransactionList.noData.description.client'
        : null
    }),
    [showAddButton]
  );

  return (
    <>
      <BillingTransactionTab
        searchState={id}
        clientName={displayText}
        clientUri={id}
        searchFacets={searchFacets}
        onRowClick={onRowClickHandler}
        resourceKeys={resourceKeys}
        canAddPayment={canAddPayment}
        headerLevel={headerLevel}
      />
      {showAddButton && (
        <AddBillingMenuButton
          client={client}
          history={history}
          showBillAction={canAddBill}
          showBillPaymentAction={canAddPayment}
          showCreditMemoAction={canAddCreditMemo}
        />
      )}
    </>
  );
};

TransactionsSubTab.propTypes = {
  client: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  editable: PropTypes.bool,
  headerLevel: PropTypes.number
};

export default TransactionsSubTab;
