import { Divider, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import RevisionEntryFieldValue from './RevisionEntryFieldValue';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(5)
  },
  changeRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  iconDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 2)
  },
  titleStyle: {
    padding: theme.spacing(1, 0),
    color: theme.palette.text.secondary
  },
  dividerMargin: {
    margin: theme.spacing(2, 0, 1, 0)
  }
}));

export const RevisionEntryField = ({
  dataQeId,
  revisionData,
  metadataValue,
  showDivider
}) => {
  const classes = useStyles();
  const { title, previousValue, currentValue, formatter } = metadataValue;

  return (
    <div className={classes.root} data-qe-id={dataQeId}>
      <Typography className={classes.titleStyle}>{title}</Typography>
      <div className={classes.changeRow}>
        <RevisionEntryFieldValue
          value={get(revisionData, previousValue)}
          formatter={formatter}
        />
        <div className={classes.iconDiv}>
          <Icon>
            <ArrowForwardIcon fontSize="small" />
          </Icon>
        </div>
        <RevisionEntryFieldValue
          value={get(revisionData, currentValue)}
          formatter={formatter}
        />
      </div>
      {showDivider && (
        <Divider className={classes.dividerMargin} variant="fullWidth" />
      )}
    </div>
  );
};

RevisionEntryField.propTypes = {
  dataQeId: PropTypes.string,
  revisionData: PropTypes.object.isRequired,
  metadataValue: PropTypes.object.isRequired,
  showDivider: PropTypes.bool.isRequired,
  formatter: PropTypes.object
};

export default RevisionEntryField;
