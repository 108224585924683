import { useState, useCallback } from 'react';
import { gql } from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';

import get from 'lodash.get';

const GET_SEARCH_SETTINGS = gql`
  query Eager_getSearchSettings($key: String!) {
    searchSettings(key: $key) {
      keywords
      criterions
    }
  }
`;

const PUT_SEARCH_SETTINGS = gql`
  mutation putSearchSettings($input: PutSearchSettingsInput) {
    putSearchSettings(input: $input) {
      keywords
      criterions
    }
  }
`;

export const onCompleted = setSearchCriteriaInternal => ({
  searchSettings
}) => {
  setSearchCriteriaInternal(searchSettings);
};

export const setSearchCriteria = (
  key,
  setSearchCriteriaInternal,
  putSearchSettings,
  updateQuery
) => settings => {
  setSearchCriteriaInternal(settings);

  putSearchSettings({
    variables: { input: { key, settings } }
  });
  updateQuery(() => ({
    searchSettings: {
      ...settings,
      __typename: 'SearchSettings'
    }
  }));
};

export const useClientOnlySearchState = () => {
  const [searchCriteria, setSearchCriteriaInternal] = useState({
    keywords: [],
    criterions: {}
  });

  const $setSearchCriteria = useCallback(
    settings => setSearchCriteriaInternal(settings),
    [setSearchCriteriaInternal]
  );

  return [searchCriteria, $setSearchCriteria];
};

const useSearchState = key => {
  const [_searchCriteria, setSearchCriteriaInternal] = useState({
    keywords: [],
    criterions: {}
  });

  const { data, updateQuery, loading: loadingSearchCriteria } = useQuery(
    GET_SEARCH_SETTINGS,
    {
      variables: { key },
      onCompleted: onCompleted(setSearchCriteriaInternal)
    }
  );

  const searchCriteria = get(data, 'searchSettings', _searchCriteria);

  const [putSearchSettings] = useMutation(PUT_SEARCH_SETTINGS);

  const $setSearchCriteria = useCallback(
    setSearchCriteria(
      key,
      setSearchCriteriaInternal,
      putSearchSettings,
      updateQuery
    ),
    [key, setSearchCriteriaInternal, putSearchSettings, updateQuery]
  );

  return [searchCriteria, loadingSearchCriteria ? null : $setSearchCriteria];
};

export default useSearchState;
