import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  icon: {
    color: ({ selected }) =>
      selected ? theme.palette.primary.dark : theme.palette.text.secondary,
    minWidth: theme.spacing(6),
    paddingLeft: ({ disableIconPadding }) =>
      disableIconPadding ? 0 : theme.spacing(0.5)
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  text: {
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export const createMenuItemLink = ({ path, state, selected, divider }) =>
  React.forwardRef((props, ref) => {
    const classes = useStyles();

    const to = useMemo(
      () => ({
        pathname: path,
        state
      }),
      [path, state]
    );

    return (
      <>
        <li
          className={classNames(
            classes.listItem,
            selected && classes.selected,
            divider && classes.divider
          )}
        >
          <Link to={to} {...props} ref={ref} />
        </li>
        {divider && (
          <Divider component="li" role="" className={classes.divider} light />
        )}
      </>
    );
  });

export const MenuItem = ({
  path,
  state,
  icon,
  title,
  selected,
  divider,
  disableIconPadding,
  dataQeId
}) => {
  const classes = useStyles({ selected, disableIconPadding });

  const MenuItemLink = useMemo(
    () => createMenuItemLink({ path, state, selected, divider }),
    [divider, path, selected, state]
  );

  const primaryTypographyProps = useMemo(
    () => ({
      className: classes.text,
      ...(dataQeId && { 'data-qe-id': `${dataQeId}_MenuItem` })
    }),
    [classes.text, dataQeId]
  );

  return (
    <ListItem button component={MenuItemLink} selected={selected} dense={false}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={primaryTypographyProps}
      />
    </ListItem>
  );
};

MenuItem.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  state: PropTypes.object,
  divider: PropTypes.bool,
  disableIconPadding: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default MenuItem;
