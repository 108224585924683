import React from 'react';
import { retryLoading } from '~/util';

const UserSettingsPage = React.lazy(() =>
  retryLoading(() => import('./UserSettingsPage'))
);

const userSettingsRoute = ({ intl, me }) => ({
  path: '/settings',
  icon: () => null,
  hidden: true,
  title: () => intl.formatMessage({ id: 'routes.settings' }),
  component: UserSettingsPage,
  hasPermission: Boolean(
    me.permissions.find(
      p =>
        p.permissionActionUri === 'urn:replicon:user-action:edit-preferences' &&
        p.dataAccessLevelUris &&
        p.dataAccessLevelUris.includes(
          'urn:replicon:user-data-access-level:self'
        )
    )
  )
});

export default userSettingsRoute;
