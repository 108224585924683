import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { PROJECT_EXECUTION_PHASE } from '~/modules/common/enums';
import { AbbreviatedDecimal } from '~/modules/common/components';
import { DonutChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { DonutChart } from '~/modules/common/charts/dashboard/DonutChart/DonutChart';
import { useProjectsSummaryStatusClickHandler } from '~/modules/common/charts/dashboard/ClickableProjectStatusChart/hooks/useProjectsSummaryStatusClickHandler';
import { useProjectsStatusCountSummaryQuery } from '~/modules/common/hooks';
import { isNullOrUndefined } from '~/modules/common/util';
import { usePortfolioProjectContext } from '~/modules/portfolios/portfolio/components/ProjectList/PortfolioProjectsContext';
import { usePortfolioProjectStatusBudgetChartConfig } from './hooks/usePortfolioProjectStatusBudgetChartConfig';
import { usePortfolioProjectStatusBudgetSummary } from './hooks/usePortfolioProjectStatusBudgetSummary';

const useChartStyles = makeStyles({
  donut: {
    alignSelf: 'start'
  }
});

const useLegendItemStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.body1
  }
}));

export const PortfolioProjectStatusBudgetChart = ({ chartDataInput }) => {
  const intl = useIntl();
  const {
    portfolioId,
    portfolioBudgetAmount,
    portfolioCurrency
  } = chartDataInput;

  const {
    loading: loadingCountSummary,
    projectsStatusCountSummary: countSummary
  } = useProjectsStatusCountSummaryQuery({
    projectFilter: {
      executionPhase: [
        PROJECT_EXECUTION_PHASE.INITIATE,
        PROJECT_EXECUTION_PHASE.EXECUTION,
        PROJECT_EXECUTION_PHASE.PLANNING,
        PROJECT_EXECUTION_PHASE.CLOSEOUT,
        PROJECT_EXECUTION_PHASE.ARCHIVED
      ],
      includeSubPortfolios: true,
      portfolios: [portfolioId]
    }
  });

  const {
    loading: loadingBudgetSummary,
    budgetSummaryAmounts
  } = usePortfolioProjectStatusBudgetSummary({
    id: portfolioId
  });

  const {
    summaryFilterState: { setKeys },
    setSearchCriteria
  } = usePortfolioProjectContext();

  const { legendClickHandlers, events } = useProjectsSummaryStatusClickHandler(
    setSearchCriteria,
    setKeys
  );

  const chartClasses = useChartStyles();
  const legendItemClasses = useLegendItemStyles();

  const {
    colorScale,
    legends,
    dataPoints,
    overrideChartConfig,
    totalProjectsBudgetAmount,
    innerPieConfig,
    overrideChartStyles,
    titleComponentConfig
  } = usePortfolioProjectStatusBudgetChartConfig({
    budgetSummaryAmounts,
    countSummary,
    portfolioBudget: portfolioBudgetAmount,
    portfolioCurrency
  });

  const renderText = useCallback(
    value => <text {...titleComponentConfig}>{value}</text>,
    [titleComponentConfig]
  );

  const titleTextComponent = isNullOrUndefined(portfolioBudgetAmount) ? (
    <Typography component="text" {...titleComponentConfig}>
      <FormattedMessage id="portfolio.projectBudgetStatusChart.na" />
    </Typography>
  ) : (
    <AbbreviatedDecimal
      prefix={`${portfolioCurrency.displayText} `}
      value={portfolioBudgetAmount}
      renderText={renderText}
    />
  );

  return loadingBudgetSummary || loadingCountSummary ? (
    <DonutChartLoading />
  ) : (
    <DonutChart
      dataQeId="portfolioProjectStatusBudgetChart"
      classes={chartClasses}
      dataPoints={dataPoints}
      colorScale={colorScale}
      legends={legends}
      legendItemClasses={legendItemClasses}
      titleTextComponent={titleTextComponent}
      chartValue={intl.formatMessage({
        id: 'portfolio.projectBudgetStatusChart.title'
      })}
      overrideChartConfig={overrideChartConfig}
      showNoDataCircle={totalProjectsBudgetAmount === 0}
      innerPieConfig={innerPieConfig}
      overrideChartStyles={overrideChartStyles}
      events={events}
      legendClickHandlers={legendClickHandlers}
    />
  );
};

PortfolioProjectStatusBudgetChart.propTypes = {
  chartDataInput: PropTypes.shape({
    portfolioId: PropTypes.string,
    portfolioBudgetAmount: PropTypes.number,
    portfolioCurrency: PropTypes.object,
    canViewPortfolioProjects: PropTypes.bool
  }).isRequired
};

export default PortfolioProjectStatusBudgetChart;
