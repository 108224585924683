import { useCallback } from 'react';

const useRoleDropdownEventHandlers = ({
  openDialog,
  setNewRoleName,
  onValueChange
}) => {
  const openDialogAndSetInitialName = useCallback(
    name => {
      setNewRoleName(name);
      openDialog();
    },
    [openDialog, setNewRoleName]
  );

  const handleOnChange = useCallback(
    v => {
      if (v?.isCreatable) {
        openDialogAndSetInitialName(v?.displayText);
      }

      onValueChange(v);
    },
    [onValueChange, openDialogAndSetInitialName]
  );

  const onCancel = useCallback(() => {
    onValueChange(null);
  }, [onValueChange]);

  return { openDialogAndSetInitialName, handleOnChange, onCancel };
};

export default useRoleDropdownEventHandlers;
