import { useState } from 'react';
import { ProgressTypes } from '~/modules/portfolios/portfolio/components/PortfolioInfo/enums';

const useToggleState = (initialState = ProgressTypes.COST) => {
  const [budgetBurnedView, setBudgetBurnedView] = useState(initialState);

  return {
    budgetBurnedView,
    setBudgetBurnedView
  };
};

export default useToggleState;
