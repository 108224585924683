import React, { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import useAccordionStyles from '../useAccordionStyles';
import ResourceAssignments from './components/ResourceAssignments';
import AccordionHeader from './components/AccordionHeader';
import Timeline from './components/Timeline';
import EstimatedCost from './components/EstimatedCost';

const useStyles = makeStyles(theme => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75)
    }
  }
}));

const expandIcon = <ExpandMoreIcon />;

export const PlanningAccordion = ({ resourceAssignmentFormik }) => {
  const { accordionClasses, summaryClasses } = useAccordionStyles();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const onChange = useCallback(() => setExpanded(!expanded), [expanded]);

  const {
    initialValues: { estimatedCostOverride, estimatedCost }
  } = useFormikContext();

  const {
    initialValues: { resourceAssignments }
  } = resourceAssignmentFormik;

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      classes={accordionClasses}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        classes={summaryClasses}
        data-qe-id="PlanningAccordion"
      >
        <AccordionHeader
          expanded={expanded}
          resourceAssignments={resourceAssignments}
          estimatedCost={estimatedCostOverride || estimatedCost}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Timeline />
        <ResourceAssignments
          resourceAssignmentFormik={resourceAssignmentFormik}
        />
        <EstimatedCost
          hasResourceAssignments={resourceAssignments?.length > 0}
        />
      </AccordionDetails>
    </Accordion>
  );
};
PlanningAccordion.propTypes = {
  resourceAssignmentFormik: PropTypes.object
};
export default PlanningAccordion;
