import { makeStyles, Card, CardContent } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { NoDataItem } from '~/modules/common/components';
import { useListColumns } from './columns';

import { mapRevenueEntriesHistoryToListData } from './util';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    '& tbody td:first-child': {
      position: 'sticky',
      left: 0,
      zIndex: 11
    },
    '& th:last-child': {
      paddingRight: theme.spacing(2)
    },
    '& th:first-child': {
      position: 'sticky',
      left: 0,
      zIndex: 15
    },
    '& td:last-child': {
      paddingRight: 0
    },
    '& tbody tr:first-child td': {
      fontWeight: 500
    }
  },
  tableHeaderCell: {
    padding: theme.spacing(1, 2),
    top: theme.spacing(10),
    position: 'sticky',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 35,
    zIndex: 13,
    color: theme.palette.text.secondary
  },
  tableCell: {
    height: 35,
    padding: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: 'none'
  }
}));

const useColumnStyles = makeStyles(theme => ({
  text: {
    '& p': {
      maxWidth: theme.spacing(15),
      whiteSpace: 'break-spaces'
    }
  }
}));

const useCardStyles = makeStyles({
  card: {
    width: '100%',
    boxShadow: 'none'
  }
});

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

export const ProjectHistoryListTable = ({
  revenueEntriesHistory,
  hasMoreRow,
  loadMoreRows,
  date
}) => {
  const intl = useIntl();
  const { headers, rows } = mapRevenueEntriesHistoryToListData({
    entries: revenueEntriesHistory,
    currentMonth: date
  });
  const classes = useStyles();
  const providerClasses = useListTableThemeProviderStyles();
  const cardClasses = useCardStyles();
  const columnClasses = useColumnStyles();
  const columns = useListColumns({
    classes: columnClasses,
    headers,
    intl
  });

  return (
    <>
      {rows.length ? (
        <ListTableThemeProvider classes={providerClasses}>
          <ListTable
            variant="table"
            classes={cardClasses}
            columns={columns}
            loadMore={loadMoreRows}
            hasMore={hasMoreRow}
            records={rows}
          />
        </ListTableThemeProvider>
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <NoDataItem>
              <FormattedMessage id="revenueRun.noMatchingHistory" />
            </NoDataItem>
          </CardContent>
        </Card>
      )}
    </>
  );
};

ProjectHistoryListTable.propTypes = {
  revenueEntriesHistory: PropTypes.array.isRequired,
  hasMoreRow: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  date: PropTypes.object
};

export default ProjectHistoryListTable;
