import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  RemoveTaskEstimateConfirmationDialogTitle,
  RemoveTaskEstimateConfirmationDialogContent
} from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/components/RemoveTaskEstimateConfirmationDialog';
import TaskEstimateRowContextProvider from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContextProvider';
import { useTaskEstimateRowContextDataBuilder } from '../../hooks';
import RemoveTaskAssignmentDialogContentSkeleton from './RemoveTaskAssignmentDialogContentSkeleton';
import { RemoveTaskAssignmentConfirmationDialogFooter } from './RemoveTaskAssignmentConfirmationDialogFooter';

const useDialogStyles = makeStyles(theme => ({
  paper: {
    maxWidth: theme.spacing(90)
  }
}));

export const RemoveTaskAssignmentConfirmationDialog = ({
  projectId,
  task,
  user,
  open,
  onClose,
  onRemoveTaskAssignmentSuccess,
  refetchQueries
}) => {
  const dialogClasses = useDialogStyles();

  const {
    loading,
    taskDetail,
    taskResourceEstimate
  } = useTaskEstimateRowContextDataBuilder({ projectId, task, user });

  return (
    <TaskEstimateRowContextProvider
      task={taskDetail}
      resourceEstimate={taskResourceEstimate}
      canEditTasks={false}
    >
      <Dialog
        classes={dialogClasses}
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="remove-task-estimate-confirmation-dialog-title"
        aria-describedby="remove-task-estimate-confirmation-dialog-description"
      >
        <RemoveTaskEstimateConfirmationDialogTitle
          taskName={task.displayText}
          isParentTask={task.hasChildren}
        />
        {loading && <RemoveTaskAssignmentDialogContentSkeleton />}
        {!loading && (
          <RemoveTaskEstimateConfirmationDialogContent
            resourceEstimate={taskResourceEstimate}
            task={task}
          />
        )}
        <RemoveTaskAssignmentConfirmationDialogFooter
          onClose={onClose}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
          isLoading={loading}
          refetchQueries={refetchQueries}
        />
      </Dialog>
    </TaskEstimateRowContextProvider>
  );
};

RemoveTaskAssignmentConfirmationDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  task: PropTypes.object,
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveTaskAssignmentSuccess: PropTypes.func.isRequired,
  refetchQueries: PropTypes.array
};
