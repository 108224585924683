import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { useLoadMore2 as useLoadMore } from '~/modules/common/hooks';
import { useResourceAssignmentDialogContext } from '../ResourceAssignmentDialogContext';
import { useResourceAssignmentSearchFilter } from './useResourceAssignmentSearchFilter';

export const GET_USERS_AVAILABLE_FOR_VENDOR_ASSIGNMENT = gql`
  query usersAvailableForVendorAssignment(
    $page: Int!
    $pageSize: Int!
    $filter: UsersAvailableForVendorAssignmentFilter
  ) {
    usersAvailableForVendorAssignment(
      page: $page
      pagesize: $pageSize
      filter: $filter
    ) {
      id
      uri
      displayText
      slug
      employeeId
      startDate
      endDate
      currentDepartment {
        id
        displayText
        ancestors {
          displayText
        }
      }
      effectiveRoles {
        isPrimary
        effectiveDate
        projectRole {
          uri
          name
        }
      }
      supervisorAssignment {
        supervisor {
          uri
          displayText
        }
        effectiveDate
      }
    }
  }
`;

export const useUsersAvailableForVendorAssignment = ({
  page = 1,
  pageSize = 5
}) => {
  const { setUsers, searchCriteria } = useResourceAssignmentDialogContext();

  const resourceAssignmentFilter = useResourceAssignmentSearchFilter({
    searchCriteria
  });

  const { data, fetchMore, error, loading } = useQuery(
    GET_USERS_AVAILABLE_FOR_VENDOR_ASSIGNMENT,
    {
      variables: {
        page,
        pageSize,
        filter: resourceAssignmentFilter
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const users = get(data, 'usersAvailableForVendorAssignment', []);

  useEffect(() => {
    if (!loading && users) {
      setUsers(users);
    }
  }, [loading, data, users, setUsers]);

  const { loadMore, hasMore, loadingMore } = useLoadMore({
    totalFetchedItems: users.length,
    fetchMore,
    loading,
    pageSize
  });

  return {
    loadMore,
    hasMore,
    loadingMore,
    loading,
    error
  };
};

export default useUsersAvailableForVendorAssignment;
