import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NoValue } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  text: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  }
}));

export const TextFormatter = ({ record, field }) => {
  const classes = useStyles();

  return record[field] ? (
    <Typography variant="body2" className={classes.text}>
      {record[field]}
    </Typography>
  ) : (
    <NoValue />
  );
};

TextFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default TextFormatter;
