import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from '~/modules/common/hooks';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  icon: {
    minWidth: theme.spacing(6),
    paddingLeft: theme.spacing(0.5)
  }
}));

const ActionLink = ({
  link,
  label,
  Icon,
  target = '_blank',
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { setValue: setCurrentView } = useSessionStorage('current-view', null);
  const location = useLocation();
  const onClick = useCallback(() => {
    setCurrentView(location);
  });

  const listItemClasses = useMemo(() => ({ primary: classes.primary }), [
    classes.primary
  ]);

  return (
    link && (
      <MenuItem
        component={Link}
        href={link}
        target={target}
        underline="none"
        rel="noopener noreferrer"
        className={classes.root}
        onClick={onClick}
      >
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label} classes={listItemClasses} />
      </MenuItem>
    )
  );
};

ActionLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Icon: PropTypes.object,
  target: PropTypes.string,
  classes: PropTypes.object
};

export default ActionLink;
