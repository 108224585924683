import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import User from '~/modules/common/components/User';

const useStyles = makeStyles(theme => ({
  user: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'break-spaces'
  }
}));

export const UserFormatter = ({ classes: classesOverride, record }) => {
  const { id, uri, displayText, disabled = false } = record;

  const classes = useStyles({ classes: classesOverride });
  const userClasses = useMemo(() => ({ name: classes.user }), [classes.user]);
  const user = useMemo(() => ({ id, uri, displayText }), [
    id,
    uri,
    displayText
  ]);

  return <User user={user} classes={userClasses} disabled={disabled} />;
};

UserFormatter.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default UserFormatter;
