import { DialogContentText, LinearProgress } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useBillPaymentDetails,
  useAssociatedAndOutStandingBills,
  usePutBillPayment,
  usePutBillPaymentFormState,
  useBillPaymentTotalProps,
  useDeleteBillPayment
} from '~/modules/billing-invoicing/billPayment/hooks';
import {
  DrawerFooter,
  DrawerHeaderComponent
} from '~/modules/billing-invoicing/common/components';
import {
  useFormOnCancel,
  useSaving,
  useEditableState
} from '~/modules/billing-invoicing/common/hooks';
import { useDialogState } from '~/modules/common/hooks';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { BILLING_INVOICING_PERMISSION_URIS } from '~/modules/common/enums/BillingInvoicingPermissions';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  BillPaymentDetails,
  BillPaymentDetailsReadOnly
} from '../BillPaymentDetails';

const footerResourceKeys = ({ intl, isRefund }) => ({
  actionButton: 'addDialog.save',
  deleteButton: isRefund
    ? 'billPaymentDetails.deleteRefund'
    : 'billPaymentDetails.deletePayment',
  deleteMenu: intl.formatMessage({
    id: isRefund
      ? 'billPaymentDetails.deleteRefund'
      : 'billPaymentDetails.deletePayment'
  })
});

const paymentResourceKeys = ({ isRefund }) => {
  return isRefund
    ? {
        editTitle: 'billPaymentDetails.editRefund',
        drawerTitle: 'billPaymentDetails.refundDrawerTitle',
        description: 'billPaymentDetails.deleteDialog.refundDescription',
        deleteDialogResourceKeys: {
          header: 'billPaymentDetails.deleteDialog.removeRefund',
          actionButton: 'billPaymentDetails.deleteDialog.removeRefund'
        }
      }
    : {
        editTitle: 'billPaymentDetails.editPayment',
        drawerTitle: 'billPaymentDetails.drawerTitle',
        description: 'billPaymentDetails.deleteDialog.description',
        deleteDialogResourceKeys: {
          header: 'billPaymentDetails.deleteDialog.removePayment',
          actionButton: 'billPaymentDetails.deleteDialog.removePayment'
        }
      };
};

const useDrawerStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

export const EditBillPaymentDrawer = ({
  id,
  open,
  billId,
  client,
  billBalanceTotal,
  bill,
  history,
  onClose
}) => {
  const drawerClasses = useDrawerStyles();
  const me = useMeContext();
  const permissions = useMemo(
    () => ({
      canEditPayment: Boolean(
        me.permissionsMap[BILLING_INVOICING_PERMISSION_URIS.EDIT_PAYMENT]
      )
    }),
    [me]
  );
  const intl = useIntl();
  const { loading, billPayment } = useBillPaymentDetails({ id });
  const { isRefund } = billPayment;
  const { associatedAndOutstandingBills } = useAssociatedAndOutStandingBills({
    me,
    client,
    billPayment,
    billId,
    billBalanceTotal,
    isRefund
  });
  const { saving, setSaving } = useSaving();

  const { putBillPayment } = usePutBillPayment();
  const { editable, setEditable } = useEditableState({
    loading,
    creditItem: billPayment
  });
  const formik = usePutBillPaymentFormState({
    me,
    bill,
    client,
    setEditable,
    history,
    setSaving,
    onClose,
    billPayment,
    billBalanceTotal,
    associatedAndOutstandingBills,
    billId,
    putBillPayment,
    isRefund
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    errors,
    isSubmitting
  } = formik;

  const {
    associatedBillsTotal,
    billPaymentBalanceTotal
  } = useBillPaymentTotalProps({ values });
  const { onCancel } = useFormOnCancel({ handleReset, onClose, saving });
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);
  const { deleteBillPayment } = useDeleteBillPayment();
  const onDeleteConfirm = useCallback(() => {
    deleteBillPayment({ id: values.id });
    onCancel();
  }, [values, deleteBillPayment, onCancel]);

  const setToEditMode = useCallback(() => {
    if (!editable) setEditable(!editable);
  }, [editable, setEditable]);

  const { canEditPayment } = permissions;
  const allowEditableActions = editable && canEditPayment;
  const resourceKeys = paymentResourceKeys({ isRefund });

  return (
    <>
      {loading && <LinearProgress variant="query" />}
      <MuiDrawer
        anchor="right"
        open={open}
        onClose={editable ? null : onCancel}
        classes={drawerClasses}
      >
        {!loading && (
          <DrawerHeaderComponent
            ariaLabel={intl.formatMessage({
              id: resourceKeys.editTitle
            })}
            values={values}
            onCancel={onCancel}
            canEdit={canEditPayment}
            balanceStatus={billPayment.balanceStatus}
            editable={editable}
            setToEditMode={setToEditMode}
            transactionType="PAYMENT"
            drawerTitleId={resourceKeys.drawerTitle}
            isRefund={isRefund}
          />
        )}
        {!loading && allowEditableActions && (
          <BillPaymentDetails
            me={me}
            values={values}
            hasClient
            errors={errors}
            setFieldValue={setFieldValue}
            handleReset={handleReset}
            associatedBillsTotal={associatedBillsTotal}
            isSubmitting={isSubmitting}
            isRefund={isRefund}
          />
        )}
        {!loading && !allowEditableActions && (
          <BillPaymentDetailsReadOnly
            associatedAndOutstandingBills={associatedAndOutstandingBills}
            associatedBillsTotal={associatedBillsTotal}
            values={values}
            hasClient
            billBalanceTotal={billBalanceTotal}
            billPayment={billPayment}
            isRefund={isRefund}
          />
        )}
        {!loading && (
          <DrawerFooter
            balanceTotal={billPaymentBalanceTotal}
            isSaving={saving}
            canDelete={allowEditableActions}
            resourceKeys={footerResourceKeys({ intl, isRefund })}
            onAdd={handleSubmit}
            onCancel={onCancel}
            onDelete={openRemoveDialog}
            editable={allowEditableActions}
          />
        )}
      </MuiDrawer>
      {showRemoveDialog && (
        <RemoveEntityConfirmationDialog
          resourceKeys={resourceKeys.deleteDialogResourceKeys}
          open={showRemoveDialog}
          onClose={closeRemoveDialog}
          onConfirm={onDeleteConfirm}
        >
          <DialogContentText>
            <FormattedMessage id={resourceKeys.description} />
          </DialogContentText>
        </RemoveEntityConfirmationDialog>
      )}
    </>
  );
};
EditBillPaymentDrawer.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  billId: PropTypes.string,
  client: PropTypes.object,
  billBalanceTotal: PropTypes.object,
  bill: PropTypes.object,
  history: PropTypes.object,
  onClose: PropTypes.func
};

export default EditBillPaymentDrawer;
