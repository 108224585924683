import { useCallback } from 'react';
import { mergePeriodRulesIntoScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { buildTaskAllocationPeriodsForUpdatedAllocation } from '~/modules/common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import { getScheduleRulesFromAllocationPeriods } from '~/modules/common/hooks/resourcing';

export const getUpdatedTaskAllocation = ({
  userTaskAllocationsSummaryScheduleRules,
  resourceAllocationScheduleRules,
  taskResourceAllocation,
  periodStartDate,
  periodEndDate,
  updatedTaskAllocationPeriodHours,
  effectiveUserScheduleSeriesData
}) => {
  const periodScheduleRules = getScheduleRulesFromAllocationPeriods(
    buildTaskAllocationPeriodsForUpdatedAllocation({
      resourceAllocationScheduleRules,
      effectiveUserScheduleSeriesData,
      userTaskAllocationsSummaryScheduleRules,
      currentTaskAllocationScheduleRules: taskResourceAllocation?.scheduleRules,
      periodStartDate,
      periodEndDate,
      updatedTaskAllocationPeriodHours
    })
  );

  const newScheduleRules = mergePeriodRulesIntoScheduleRules(
    taskResourceAllocation?.scheduleRules,
    true
  )(periodScheduleRules);

  return {
    ...taskResourceAllocation,
    scheduleRules: newScheduleRules
  };
};

export const useOnChangeHandlers = ({
  userTaskAllocationsSummaryScheduleRules,
  resourceAllocationScheduleRules,
  taskResourceUserAllocation,
  periodStartDate,
  periodEndDate,
  onChange,
  effectiveUserScheduleSeriesData,
  periodTaskAllocatedHours
}) => {
  const onAllocationChange = useCallback(
    updatedTaskAllocationPeriodHours => {
      const updatedAllocation = getUpdatedTaskAllocation({
        userTaskAllocationsSummaryScheduleRules,
        resourceAllocationScheduleRules,
        taskResourceAllocation: taskResourceUserAllocation,
        periodStartDate,
        periodEndDate,
        updatedTaskAllocationPeriodHours,
        effectiveUserScheduleSeriesData
      });

      onChange({
        allocation: updatedAllocation,
        periodDetails: {
          periodStartDate,
          periodEndDate,
          newPeriodTaskAllocatedHours: updatedTaskAllocationPeriodHours,
          periodTaskAllocatedHours
        }
      });
    },
    [
      onChange,
      periodEndDate,
      periodStartDate,
      resourceAllocationScheduleRules,
      effectiveUserScheduleSeriesData,
      taskResourceUserAllocation,
      userTaskAllocationsSummaryScheduleRules,
      periodTaskAllocatedHours
    ]
  );

  return {
    onAllocationChange
  };
};
