import EditProjectRequestDrawer from './EditProjectRequestDrawer';

export const projectRequestDrawerRoute = {
  path: '#/request/:id',
  component: EditProjectRequestDrawer,
  disableBackdropClick: true,
  disableEscapeKeyDown: true
};

export default projectRequestDrawerRoute;
