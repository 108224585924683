import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_PORTFOLIO_PROJECT_REQUEST_STATUS_ESTIMATED_COST_SUMMARY = gql`
  query portfolioProjectRequestStatusEstimatedCostSummary(
    $portfolioId: String!
  ) {
    portfolioProjectRequestStatusEstimatedCostSummary(
      portfolioId: $portfolioId
    ) {
      new
      inReview
      approved
      rejected
    }
  }
`;

export const usePortfolioProjectRequestStatusEstimatedCostSummary = ({
  portfolioId,
  skip
}) => {
  const { data, loading } = useQuery(
    GET_PORTFOLIO_PROJECT_REQUEST_STATUS_ESTIMATED_COST_SUMMARY,
    {
      variables: {
        portfolioId
      },
      fetchPolicy: 'cache-and-network',
      skip
    }
  );

  const estimatedCostSummaryAmounts =
    !loading && data
      ? data.portfolioProjectRequestStatusEstimatedCostSummary
      : {};

  return {
    loading,
    estimatedCostSummaryAmounts
  };
};
