import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles, Link, List } from '@material-ui/core';
import { useIsBreakpointDown } from '~/modules/common/hooks';

const useStyles = makeStyles(theme => ({
  policyLink: {
    margin: theme.spacing(0, 1)
  },
  policyListItem: {
    padding: theme.spacing(0, 1),
    margin: ({ isMobile }) =>
      isMobile ? theme.spacing(1, 0, 1, 0) : theme.spacing(1, 0, 0, 0),
    display: 'flex',
    alignIontent: 'center',
    justifyContent: 'space-between'
  }
}));

export const PolicyLinks = ({ classes: classesOverride }) => {
  const intl = useIntl();
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles({ classes: classesOverride, isMobile });

  const policyClasses = useMemo(() => ({ root: classes.policyLink }), [
    classes.policyLink
  ]);

  const policyListItemClasses = useMemo(
    () => ({
      root: classes.policyListItem
    }),
    [classes.policyListItem]
  );

  return (
    <List classes={policyListItemClasses}>
      <Link
        component="li"
        href="https://www.replicon.com/privacy-policy/"
        target="_blank"
        variant="caption"
        color="textSecondary"
        classes={policyClasses}
      >
        {intl.formatMessage({ id: 'policyLinks.privacy' })}
      </Link>
      <Link
        component="li"
        href="https://www.replicon.com/terms-and-conditions/"
        target="_blank"
        variant="caption"
        color="textSecondary"
        classes={policyClasses}
      >
        {intl.formatMessage({ id: 'policyLinks.license' })}
      </Link>
    </List>
  );
};

PolicyLinks.propTypes = {
  classes: PropTypes.object
};

export default PolicyLinks;
