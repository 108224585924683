import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: 600,
    margin: theme.spacing(0, 0.75)
  },
  section: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2)
  }
}));

export const ResourceDrawerContentLoading = ({ classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={6} className={classes.section}>
          <Skeleton width="100%" height={30} />
          <Skeleton width="100%" height={40} />
          <Skeleton width="100%" height={30} />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.section}>
          <Skeleton width="100%" height={30} />
          <Skeleton width="100%" height={40} />
          <Skeleton width="100%" height={30} />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.section}>
          <Skeleton width="100%" height={30} />
          <Skeleton width="100%" height={30} />
          <Skeleton width="100%" height={30} />
        </Grid>

        <Grid item xs={12} className={classes.section}>
          <Skeleton width="100%" height={250} />
        </Grid>

        <Grid item xs={12} className={classes.section}>
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
        </Grid>

        <Grid item xs={12} className={classes.section}>
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
          <Skeleton width="100%" height={50} />
        </Grid>
      </Grid>
    </div>
  );
};

ResourceDrawerContentLoading.propTypes = {
  classes: PropTypes.object
};

export default ResourceDrawerContentLoading;
