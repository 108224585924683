import { ProjectManagementType } from '~/types';
import { useLoadMore } from '~/modules/common/hooks/useLoadMoreResults';
import { useProjectsColumnSelection } from '~/modules/common/hooks/project';
import { useProjectsContext } from '../../ProjectsContext';
import { useFilteredProjectsQuery } from './useFilteredProjectsQuery';
import { useProjectFilterMemo } from './useProjectFilterMemo';

export const DEFAULT_PAGE_SIZE = 50;

export const usePaginatedFilteredProjectsQuery = ({
  currentPage,
  pageSize = DEFAULT_PAGE_SIZE,
  enabledFields = [],
  tagIds = [],
  sort
}) => {
  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });

  const isManaged = projectType === ProjectManagementType.Managed;

  const columnArguments = useProjectsColumnSelection({
    enabledFields
  });

  const {
    loading,
    projects,
    fetchMore,
    networkStatus,
    refetch
  } = useFilteredProjectsQuery({
    currentPage,
    pageSize,
    isManaged,
    searchCriteria,
    sort,
    quickFilter: summaryFilterState.quickFilter,
    tagIds,
    enabledFields: columnArguments
  });

  const { loadingMore, loadMore } = useLoadMore({
    variables: {
      pageSize,
      projectFilter,
      sort,
      isManaged,
      tagIds,
      ...columnArguments
    },
    fetchMore,
    fetchMoreResultPath: 'projects',
    loading,
    networkStatus,
    initialHasMore: projects.length % DEFAULT_PAGE_SIZE === 0
  });

  return {
    loading,
    projects,
    loadMore,
    loadingMore,
    refetch
  };
};
