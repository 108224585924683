import React from 'react';
import PropTypes from 'prop-types';
import { SeriesChart } from '~/modules/common/charts/dashboard';
import { BarsChartLoading } from '~/modules/common/components/DetailsPage/Charts';
import { useCostChartConfig } from '~/modules/common/charts/dashboard/config';
import { useCostChartData } from '~/modules/common/charts/hooks';
import { useClientCostSeries } from './hooks';

export const ResourceCost = ({ chartDataInput, titleId }) => {
  const { actualBarColor, gradientId, legends } = useCostChartConfig();
  const { loading, costCurrency, costSeriesData } = useClientCostSeries({
    chartDataInput
  });
  const { actualDataPoints, estimatedDataPoints } = useCostChartData({
    costSeriesData,
    costCurrency
  });

  return loading ? (
    <BarsChartLoading />
  ) : (
    <SeriesChart
      name="ResourceCost"
      actualBarColor={actualBarColor}
      gradientId={gradientId}
      legends={legends}
      actualDataPoints={actualDataPoints}
      estimatedDataPoints={estimatedDataPoints}
      titleId={titleId}
    />
  );
};

ResourceCost.propTypes = {
  chartDataInput: PropTypes.object,
  titleId: PropTypes.string
};

export default ResourceCost;
