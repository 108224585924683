import get from 'lodash.get';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { ProjectManagementType } from '~/types';
import { useGetProjectTotalsQuery } from '../../../graphql';
import { useProjectsContext } from '../../../ProjectsContext';
import { useProjectFilterMemo } from '../useProjectFilterMemo';

export const useFilteredProjectTotalsQuery = ({ dateRange } = {}) => {
  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();
  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });

  const isManaged =
    projectType === ProjectManagementType.Managed
      ? true
      : projectType === ProjectManagementType.Unmanaged
      ? false
      : null;

  const options = {
    variables: {
      projectFilter: {
        ...(projectFilter || {}),
        dateRange: dateRange
          ? {
              startDate: dateToMidnightUTCString(dateRange.startDate),
              endDate: dateToMidnightUTCString(dateRange.endDate)
            }
          : null
      },
      isManaged
    },
    fetchPolicy: 'cache-and-network'
  };

  const { data, loading, refetch } = useGetProjectTotalsQuery(options);

  const totals = get(data, 'projectTotals') || null;

  return {
    totals,
    loading,
    refetch
  };
};
