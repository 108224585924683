import { useGetProjectsQuery } from '~/modules/projects';
import { useLoadMore } from '~/modules/common/hooks/useLoadMoreResults';

export const DEFAULT_PAGE_SIZE = 20;

const usePortfolioProjects = ({
  columnArguments,
  portfolioCurrency,
  projectFilter
}) => {
  const { loading, fetchMore, data, networkStatus } = useGetProjectsQuery({
    variables: {
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      projectFilter,
      ...columnArguments
    },
    fetchPolicy: 'network-only'
  });

  const projects = data?.projects || [];

  const { loadMore, hasMore } = useLoadMore({
    variables: { pageSize: DEFAULT_PAGE_SIZE },
    fetchMore,
    fetchMoreResultPath: 'projects',
    loading,
    networkStatus,
    initialHasMore: projects.length % DEFAULT_PAGE_SIZE === 0
  });

  return {
    projects: projects.map(project => ({
      ...project,
      budgetCostAmountInPortfolioCurrency: {
        amount: project?.budgetCostAmountInPortfolioCurrency,
        currency: portfolioCurrency
      },
      actualCostAmountInPortfolioCurrency: {
        amount: project?.actualCostAmountInPortfolioCurrency,
        currency: portfolioCurrency
      },
      estimatedCostAtCompletionInPortfolioCurrency: {
        amount: project?.estimatedCostAtCompletionInPortfolioCurrency,
        currency: portfolioCurrency
      },
      estimatedBudgetCostRemainingInPortfolioCurrency: {
        amount: project?.estimatedBudgetCostRemainingInPortfolioCurrency,
        currency: portfolioCurrency
      }
    })),
    loading,
    loadMore,
    hasMore
  };
};

export default usePortfolioProjects;
