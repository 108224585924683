import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TIME_SERIES_LIST_COLUMN_TYPE } from '~/modules/common/enums';

export const buildColumns = ({ selectedColumns = [], isRTL }) => ({
  date: {
    groupId: TIME_SERIES_LIST_COLUMN_TYPE.DATE,
    visible: selectedColumns.includes(TIME_SERIES_LIST_COLUMN_TYPE.DATE),
    value: <FormattedMessage id="taskDrawer.Date" />,
    align: isRTL ? 'right' : 'left'
  },
  totalHoursWorkedToDate: {
    groupId: TIME_SERIES_LIST_COLUMN_TYPE.TOTAL_HOURS_WORKED_TO_DATE,
    visible: selectedColumns.includes(
      TIME_SERIES_LIST_COLUMN_TYPE.TOTAL_HOURS_WORKED_TO_DATE
    ),
    align: isRTL ? 'left' : 'right',
    value: <FormattedMessage id="taskDrawer.Total Work Done" />
  },
  estimatedRemainingHours: {
    groupId: TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_REMAINING_HOURS,
    visible: selectedColumns.includes(
      TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_REMAINING_HOURS
    ),
    align: isRTL ? 'left' : 'right',
    value: <FormattedMessage id="taskDrawer.Work Remaining" />
  },
  estimatedHoursAtCompletion: {
    groupId: TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_HOURS_AT_COMPLETION,
    visible: selectedColumns.includes(
      TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_HOURS_AT_COMPLETION
    ),
    align: isRTL ? 'left' : 'right',
    value: <FormattedMessage id="taskDrawer.Estimated Hours At Completion" />
  },
  estimatedCompletionDate: {
    groupId: TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_COMPLETION_DATE,
    visible: selectedColumns.includes(
      TIME_SERIES_LIST_COLUMN_TYPE.ESTIMATED_COMPLETION_DATE
    ),
    align: isRTL ? 'right' : 'left',
    value: <FormattedMessage id="taskDrawer.Estimated Completion Date" />
  }
});
