import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useMeContext } from '~/modules/me';
import { SubstituteUserIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(6),
    paddingLeft: theme.spacing(0.5)
  }
}));

const label = <FormattedMessage id="substituteUsersDialog.title" />;

export const SubstituteUserMenuItem = ({ openDialog }) => {
  const classes = useStyles();
  const { substituteUsers, substituteUserMetadata } = useMeContext();

  return (
    substituteUsers.length !== 0 &&
    !substituteUserMetadata && (
      <MenuItem onClick={openDialog}>
        <ListItemIcon className={classes.icon}>
          <SubstituteUserIcon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </MenuItem>
    )
  );
};

SubstituteUserMenuItem.propTypes = {
  openDialog: PropTypes.func
};

export default SubstituteUserMenuItem;
