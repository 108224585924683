import React from 'react';
import { FocusableDiv } from '~/modules/common/components/FocusableDiv';

const AddTaskTemplate = () => {
  return <FocusableDiv className="gantt_add"></FocusableDiv>;
};

AddTaskTemplate.propTypes = {};

export default AddTaskTemplate;
