import React from 'react';

import { retryLoading } from '~/util';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { ClientIcon } from '~/modules/common/components/Icons';

const ClientsPage = React.lazy(() =>
  retryLoading(() => import('./ClientsPage'))
);

export const clientsRoute = ({ intl, me }) => ({
  path: '/clients',
  icon: () => <ClientIcon />,
  isSideBarRoute: true,
  title: () => intl.formatMessage({ id: 'routes.clients' }),
  component: ClientsPage,
  hasPermission:
    Boolean(
      me.permissions.find(
        p => p.permissionActionUri === 'urn:replicon:client-action:view-client'
      )
    ) &&
    Boolean(
      me.userAccessRoles.find(
        role =>
          role === USER_ACCESS_ROLE.PROJECT_MANAGER ||
          role === USER_ACCESS_ROLE.CLIENT_MANAGER ||
          role === USER_ACCESS_ROLE.PROJECT_ADMIN ||
          role === USER_ACCESS_ROLE.BILLING_MANAGER
      )
    ),
  dataQeId: 'MainNav_Clients'
});
