import { ApolloError } from "@apollo/client";
import { useClientQuery as useClientQuery_ } from '~/types';

type UseClientOutput = {
  client: any;
  loading: boolean;
  error: ApolloError | undefined;
};

const useClientQuery = (slug: string): UseClientOutput => {
  const { data: { client = {} } = {}, loading, error } = useClientQuery_({
    variables: { slug }
  });

  return {
    client,
    loading,
    error
  };
};

export default useClientQuery;
