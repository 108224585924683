import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { gql } from 'graphql-tag';
import React, { useMemo } from 'react';
import { ProjectManagementType } from '~/types';
import { useProjectsContext } from '~/modules/projects/ProjectsContext';
import { useMeContext } from '~/modules/me';
import { mapSearchKeyToOverbugetFilter } from '../../../useProjectFilterMemo.util';
import { useProjectFilterMemo } from '../../../useProjectFilterMemo';
import { ProjectRowLoading } from '../../common';
import OverbudgetExpansionRow from './OverbudgetExpansionRow';
import { ProjectList } from './ProjectList/ProjectList';
import { useProjectListSort } from './ProjectList/useProjectListSort';

const PROJECT_NAME_QUERY = gql`
  query getProjectsOverbudget(
    $page: Int
    $pageSize: Int
    $sort: ProjectSort
    $projectFilter: ProjectFilter2
    $isManaged: Boolean
    $includeEstimatedBudgetHoursRemaining: Boolean = false
    $includeEstimatedBudgetCostRemaining: Boolean = false
  ) {
    projects(
      page: $page
      pagesize: $pageSize
      projectFilter: $projectFilter
      sort: $sort
      isManaged: $isManaged
    ) {
      id
      slug
      displayText
      estimatedBudgetHoursRemaining
        @include(if: $includeEstimatedBudgetHoursRemaining)
      estimatedBudgetCostRemaining
        @include(if: $includeEstimatedBudgetCostRemaining) {
        amount
        currency {
          symbol
          displayText
        }
      }
    }
  }
`;

const OverbudgetExpansionDetails = ({
  count,
  searchKey,
  isOverBudgetProjectListVisible
}) => {
  const me = useMeContext();
  const { permissionsMap } = me;
  const canViewProjectCostData = Boolean(
    permissionsMap['urn:replicon:project-action:view-cost-data']
  );
  const {
    searchCriteria,
    projectType,
    summaryFilterState
  } = useProjectsContext();

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    quickFilter: summaryFilterState.quickFilter
  });

  const isManaged = useMemo(
    () => projectType === ProjectManagementType.Managed,
    [projectType]
  );

  const projectFilterWithOverbudgetKey = useMemo(
    () => ({
      ...projectFilter,
      overbudget: {
        ...projectFilter.overbudget,
        ...mapSearchKeyToOverbugetFilter({ searchKey })
      }
    }),
    [searchKey, projectFilter]
  );
  const { sort, onSortChange, isLoading } = useProjectListSort(
    isOverBudgetProjectListVisible
  );

  const { data, loading } = useQuery(PROJECT_NAME_QUERY, {
    variables: {
      page: 1,
      isManaged,
      sort: isOverBudgetProjectListVisible ? sort : null,
      projectFilter: projectFilterWithOverbudgetKey,
      pageSize: count,
      includeEstimatedBudgetCostRemaining:
        isOverBudgetProjectListVisible && canViewProjectCostData,
      includeEstimatedBudgetHoursRemaining: isOverBudgetProjectListVisible
    },
    fetchPolicy: 'cache-and-network'
  });

  const projects = (data && data.projects) || [];

  return (
    <>
      {loading ? (
        <ProjectRowLoading />
      ) : isOverBudgetProjectListVisible ? (
        <ProjectList
          projects={projects}
          sort={sort}
          onSortChange={onSortChange}
          isLoading={isLoading}
        />
      ) : (
        projects.map(project => (
          <OverbudgetExpansionRow
            key={project.id}
            to={`/projects/${project.slug}`}
          >
            {project.displayText}
          </OverbudgetExpansionRow>
        ))
      )}
    </>
  );
};

OverbudgetExpansionDetails.propTypes = {
  count: PropTypes.number.isRequired,
  searchKey: PropTypes.string.isRequired,
  isOverBudgetProjectListVisible: PropTypes.bool
};

export default OverbudgetExpansionDetails;
