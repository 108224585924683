import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  value: {
    maxWidth: '50%',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const RevisionEntryFieldValue = ({
  value,
  formatter: FieldFormatter
}) => {
  const classes = useStyles();

  if (value === null || value === undefined || !FieldFormatter) {
    return <Typography className={classes.value}>{value || 'N/A'}</Typography>;
  }

  return <FieldFormatter fieldValue={value} />;
};

RevisionEntryFieldValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formatter: PropTypes.elementType
};

export default RevisionEntryFieldValue;
