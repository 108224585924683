import { useCallback, useState } from 'react';
import { useDialogState } from '~/modules/common/hooks';
import { useRemoveProjectLinks } from './useRemoveProjectLinks';

export const useProjectLinkActions = () => {
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { removeProjectLinks } = useRemoveProjectLinks();

  const onRemoveLinkClick = useCallback(() => {
    openRemoveDialog();
  }, [openRemoveDialog]);

  const onRemoveConfirm = useCallback(
    async link => {
      setIsRemoving(true);
      await removeProjectLinks(link);
      setIsRemoving(false);
      closeRemoveDialog();
    },
    [removeProjectLinks, closeRemoveDialog]
  );

  return {
    onRemoveLinkClick,
    onRemoveConfirm,
    closeRemoveDialog,
    isRemoving,
    showRemoveDialog
  };
};

export default useProjectLinkActions;
