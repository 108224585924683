import React from 'react';
import {
  ResourcingLegends,
  resourcingLegendItems
} from '~/modules/resourcing/common/components';

export const ResourceLegendComponent = () => {
  const legends = [
    resourcingLegendItems.allocatedProgress,
    resourcingLegendItems.overAllocated,
    resourcingLegendItems.notWorking
  ];

  return <ResourcingLegends legends={legends} />;
};

export default ResourceLegendComponent;
