import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useURLSearchParams } from '~/modules/common/hooks';
import { ProjectManagementType } from '~/types';
import { overbudgetTag } from '~/modules/projects/components/ProjectListPage/Toolbar/OverbudgetFacet';
import { PROJECTS_SUMMARY_FILTER_TABS } from '~/modules/common/enums';
import ClientProjectsContext from './ClientProjectsContext';

const getOverbudgetCriteria = ({ overbudgetKey }) => [
  { key: overbudgetKey, value: overbudgetKey }
];

export default function ProjectsContextProvider({ children }) {
  const { state: { executionPhaseId } = {} } = useLocation();
  const queryParams = useURLSearchParams();
  const [quickFilter, setQuickFilter] = useState({
    filterByAccess: PROJECTS_SUMMARY_FILTER_TABS.ALL_PROJECTS,
    filterByStatus: executionPhaseId || null
  });

  const [projectType, setProjectType] = useState(ProjectManagementType.Managed);

  const onProjectTypeClick = useCallback((_, newValue) => {
    if (!newValue) {
      return;
    }

    setProjectType(newValue);
  }, []);

  const handleSetQuickFilter = useCallback(
    (filterByAccess, filterByStatus) => {
      setQuickFilter({
        filterByAccess,
        filterByStatus
      });
    },
    [setQuickFilter]
  );

  const summaryFilterState = {
    quickFilter,
    setKeys: handleSetQuickFilter
  };
  const overbudgetKey = queryParams.get('overbudgetKey');

  const [searchCriteria, setSearchCriteria] = useState({
    keywords: [],
    criterions: overbudgetKey
      ? {
          [overbudgetTag]: getOverbudgetCriteria({
            overbudgetKey
          })
        }
      : {}
  });

  return (
    <ClientProjectsContext.Provider
      value={useMemo(
        () => ({
          searchCriteria,
          projectType,
          onProjectTypeClick,
          setSearchCriteria,
          summaryFilterState
        }),
        [searchCriteria, projectType, onProjectTypeClick, summaryFilterState]
      )}
    >
      {children}
    </ClientProjectsContext.Provider>
  );
}

ProjectsContextProvider.propTypes = {
  children: PropTypes.node
};

export const withClientProjectsContextProvider = BaseComponent => props => (
  <ProjectsContextProvider>
    <BaseComponent {...props} />
  </ProjectsContextProvider>
);
