import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { ReleaseResourceAllocationType } from '~/types';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { getTotalHoursAndCostFromAllocations } from '~/modules/resourcing/util/getTotalHoursAndCostFromAllocations';
import useGetUsersAssignedImpactedTaskSummary from '~/modules/resourcing/common/hooks/useGetUsersAssignedImpactedTaskSummary';
import { getImpactedTasksByReleasingResources } from './useImpactedTasksByReleasingResources';
import useReleaseTaskOwners from './useReleaseTaskOwners';
import useBulkReleaseTaskOwnership from './useBulkReleaseTaskOwnership';
import useTaskOwnedByResources from './useTaskOwnedByResources';

export const useReleaseResourceState = ({
  resourceRequest,
  allocation,
  projectUri,
  onCancelClick,
  onResourceRequestSubmit,
  onReleaseSuccess,
  onReleaseResourceAllocation,
  onReleaseResourceAllocationsForRequest
}) => {
  const endDate =
    !resourceRequest && allocation
      ? allocation.endDate
      : resourceRequest.endDate;
  const today = new Date();
  const isPsaRmpTaskAllocation1Enabled = useHasFeatureFlag({
    featureFlag: 'isPsaRmpTaskAllocation1Enabled'
  });

  const [releaseResourceValues, setReleaseResourceValues] = useState({
    comment: '',
    releaseConfirmed: false,
    releaseDate:
      mapIsoStringtoUtcObject(endDate) < today ? endDate : today.toISOString(),
    releaseType: ReleaseResourceAllocationType.Entirely,
    requestStartDate:
      (resourceRequest && resourceRequest.startDate) ||
      (allocation && allocation.startDate),
    requestEndDate:
      (resourceRequest && endDate) || (allocation && allocation.endDate),
    role: (resourceRequest && resourceRequest.role) || allocation.role,
    allocations: (resourceRequest &&
      resourceRequest.resourceAllocations.map(alloc => ({
        id: alloc.id,
        startDate: alloc.startDate,
        endDate: alloc.endDate,
        user: {
          ...alloc.user.user
        },
        isSelected: true,
        totalHours: alloc.totalHours,
        totalUserCostByCurrency: alloc.totalUserCostByCurrency,
        totalRoleCostByCurrency: alloc.totalRoleCostByCurrency
      }))) || [
      {
        ...allocation,
        user: {
          ...allocation.user.user
        },
        isSelected: true
      }
    ],
    projectId: (resourceRequest && resourceRequest.projectUri) || projectUri
  });

  const [isReleaseLoading, setIsReleaseLoading] = useState(false);

  const onReleaseConfirmChange = useCallback(
    e => {
      setReleaseResourceValues({
        ...releaseResourceValues,
        releaseConfirmed: e.target && e.target.checked
      });
    },
    [releaseResourceValues]
  );

  const onReleaseTypeChange = useCallback(
    e => {
      setReleaseResourceValues({
        ...releaseResourceValues,
        releaseType: e.target && e.target.value
      });
    },
    [releaseResourceValues]
  );

  const onCommentsChange = useCallback(
    e => {
      setReleaseResourceValues({
        ...releaseResourceValues,
        comment: (e.target && e.target.value) || ''
      });
    },
    [releaseResourceValues]
  );

  const onReleaseDateChange = useCallback(
    date => {
      setReleaseResourceValues({
        ...releaseResourceValues,
        releaseDate: DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0)
      });
    },
    [releaseResourceValues]
  );

  const onRowSelectionChange = useCallback(
    (allocationId, event) => {
      setReleaseResourceValues({
        ...releaseResourceValues,
        allocations: releaseResourceValues.allocations.map(alloc => {
          if (allocationId === alloc.id) {
            return {
              ...alloc,
              isSelected: event.target && event.target.checked
            };
          }

          return alloc;
        })
      });
    },
    [releaseResourceValues]
  );

  const onSelectAllClick = useCallback(
    event => {
      setReleaseResourceValues({
        ...releaseResourceValues,
        releaseDate: releaseResourceValues.allocations.map(alloc => ({
          ...alloc,
          isSelected: event.target && event.target.checked
        }))
      });
    },
    [releaseResourceValues]
  );

  const resourceIds = releaseResourceValues.allocations.map(
    alloc => alloc.user.uri
  );
  const { bulkReleaseTaskOwnership } = useBulkReleaseTaskOwnership();
  const { releaseTaskOwners } = useReleaseTaskOwners();
  const {
    resources,
    isLoading: isLoadingImpactedTasksSummary
  } = useTaskOwnedByResources({
    projectId: releaseResourceValues.projectId,
    resourceIds,
    skip: isPsaRmpTaskAllocation1Enabled
  });

  const {
    usersAssignedImpactedTaskSummary,
    isLoading
  } = useGetUsersAssignedImpactedTaskSummary({
    projectId: releaseResourceValues.projectId,
    userIds: resourceIds,
    asOfDate:
      releaseResourceValues.releaseType === ReleaseResourceAllocationType.Asof
        ? releaseResourceValues.releaseDate
        : null,
    skip: !isPsaRmpTaskAllocation1Enabled
  });

  const {
    impactedTasks,
    selectedUsersForReleasing
  } = getImpactedTasksByReleasingResources({
    resources: isLoadingImpactedTasksSummary ? [] : resources,
    allocations: releaseResourceValues.allocations,
    releaseDate: releaseResourceValues.releaseDate,
    releaseType: releaseResourceValues.releaseType
  });

  const onReleaseClick = useCallback(async () => {
    setIsReleaseLoading(true);
    if (
      releaseResourceValues.allocations.length === 0 &&
      onResourceRequestSubmit
    ) {
      await onResourceRequestSubmit();
    }

    if (onReleaseResourceAllocationsForRequest && resourceRequest) {
      const filteredAllocations = releaseResourceValues.allocations.filter(
        a => a.isSelected
      );

      await onReleaseResourceAllocationsForRequest({
        resourceAllocations: filteredAllocations,
        releaseDate: releaseResourceValues.releaseDate,
        releaseType: releaseResourceValues.releaseType,
        comment: releaseResourceValues.comment,
        initialTotalAllocationsCostAndHours: getTotalHoursAndCostFromAllocations(
          {
            allocations: filteredAllocations
          }
        )
      });
    }
    if (onReleaseResourceAllocation && !resourceRequest) {
      await onReleaseResourceAllocation({
        releaseDate: releaseResourceValues.releaseDate,
        releaseType: releaseResourceValues.releaseType,
        comment: releaseResourceValues.comment,
        initialTotalAllocationsCostAndHours: getTotalHoursAndCostFromAllocations(
          {
            allocations: releaseResourceValues.allocations
          }
        )
      });
    }
    if (impactedTasks.length > 0 && !isPsaRmpTaskAllocation1Enabled)
      await releaseTaskOwners(impactedTasks);
    else if (isPsaRmpTaskAllocation1Enabled) {
      await bulkReleaseTaskOwnership({
        entries: usersAssignedImpactedTaskSummary,
        projectId: releaseResourceValues.projectId
      });
    }

    if (onReleaseSuccess) onReleaseSuccess();
    setIsReleaseLoading(false);
    onCancelClick();
  }, [
    bulkReleaseTaskOwnership,
    releaseResourceValues.allocations,
    releaseResourceValues.releaseDate,
    releaseResourceValues.releaseType,
    releaseResourceValues.comment,
    releaseResourceValues.projectId,
    onResourceRequestSubmit,
    onReleaseResourceAllocationsForRequest,
    resourceRequest,
    onReleaseResourceAllocation,
    impactedTasks,
    isPsaRmpTaskAllocation1Enabled,
    releaseTaskOwners,
    onReleaseSuccess,
    onCancelClick,
    usersAssignedImpactedTaskSummary
  ]);

  return {
    isPsaRmpTaskAllocation1Enabled,
    releaseResourceValues,
    selectedUsersForReleasing,
    isLoadingImpactedTasksSummary: isLoading || isLoadingImpactedTasksSummary,
    usersAssignedImpactedTaskSummary,
    impactedTasks,
    setReleaseResourceValues,
    onCommentsChange,
    onReleaseConfirmChange,
    isReleaseLoading,
    onReleaseDateChange,
    onReleaseTypeChange,
    onRowSelectionChange,
    onSelectAllClick,
    onReleaseClick
  };
};

export default useReleaseResourceState;
