import { useCallback, useState } from 'react';
import { gql } from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { extractFirstValidationError } from '~/modules/common/graphql/errors';
import { useClientPermissions } from '~/modules/clients/hooks';

export const UPDATE_CLIENT_BASIC_INFO_MUTATION = gql`
  mutation UpdateClientBasicInfo($input: UpdateClientBasicInfoInput!) {
    updateClientBasicInfo2(input: $input) {
      client {
        id
        name
        code
        comment
        clientManager {
          id
          displayText
        }
        comanagers {
          id
          displayText
        }
      }
    }
  }
`;

export const buildMutationInput = ({ canEditComanagers, values }) => {
  const { id, name, code, comment, clientManager, comanagers } = values;

  return {
    id,
    name,
    code,
    comment,
    clientManagerUri: clientManager ? clientManager.id : null,
    comanagerUris: canEditComanagers ? comanagers.map(cr => cr.id) : undefined
  };
};

export const useUpdateClientBasicInfoOnSubmit = closeDialog => {
  const { canEditComanagers } = useClientPermissions();
  const [submitErrors, setSubmitErrors] = useState(null);

  const onReset = useCallback(() => setSubmitErrors(null), [setSubmitErrors]);

  const [updateClientBasicInfo] = useMutation(
    UPDATE_CLIENT_BASIC_INFO_MUTATION
  );

  const onSubmit = useCallback(
    async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitErrors(null);

      const { id, name, code, comment, clientManager, comanagers } = values;

      try {
        await updateClientBasicInfo({
          variables: {
            input: buildMutationInput({ canEditComanagers, values })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateClientBasicInfo2: {
              __typename: 'UpdateClientBasicInfoResponse',
              client: {
                __typename: 'Client',
                id,
                name,
                code,
                comment,
                clientManager,
                comanagers
              }
            }
          }
        });

        closeDialog();
        resetForm();
      } catch (error) {
        const validationErrors = extractFirstValidationError(error);

        const duplicationNameError =
          validationErrors &&
          validationErrors.find(
            v =>
              v.failureUri ===
              'urn:replicon:validation-failure:client-name-duplicated'
          );

        if (duplicationNameError)
          setFieldError('name', duplicationNameError.displayText);

        setSubmitErrors(!duplicationNameError && (validationErrors || error));
      } finally {
        setSubmitting(false);
      }
    },
    [canEditComanagers, closeDialog, updateClientBasicInfo]
  );

  return { onSubmit, submitErrors, onReset };
};
