const hiddenColumns = ['hasCarryOver', 'revenueEntryId', 'project'];

const getIsVisible = (entries, name) =>
  entries.some(cols => cols.find(x => x.name === name)?.value !== undefined) &&
  !hiddenColumns.includes(name);

export const mapRevenueEntriesHistoryToListData = ({
  entries,
  currentMonth
}) => {
  const nameMap = {};
  const headersAndRows = { headers: [], rows: [] };

  entries.forEach(reColumns => {
    const revenuePeriod = reColumns.find(x => x.name === 'revenuePeriod').value;
    const isActive =
      revenuePeriod?.endDate?.month === currentMonth?.month &&
      revenuePeriod?.endDate?.year === currentMonth?.year;
    const row = reColumns.reduce(
      (retVal, current) => {
        const { type, name, sortIndex, value } = current;

        if (!nameMap[name]) {
          nameMap[name] = name;
          const visible = getIsVisible(entries, name);

          headersAndRows.headers = [
            ...headersAndRows.headers,
            {
              type,
              name,
              sortIndex,
              visible
            }
          ];
        }

        return {
          ...retVal,
          [name]: value
        };
      },
      { isActive }
    );

    headersAndRows.rows = [...headersAndRows.rows, row];
  });
  headersAndRows.headers.sort((a, b) => a.sortIndex - b.sortIndex);

  return headersAndRows;
};
