import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
import ActualSummaryPeriodPopupContent2 from './ActualSummaryPeriodPopupContent2';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    margin: theme.spacing(0.5, 0, 0.5, 0)
  }
}));

export const ActualSummaryPeriodPopupContent = ({
  totalActualHours,
  totalAllocatedHours
}) => {
  const classes = useStyles();

  return (
    <>
      <ActualSummaryPeriodPopupContent2
        totalHours={totalAllocatedHours}
        hoursType="totalAllocated"
      />
      <ActualSummaryPeriodPopupContent2
        totalHours={totalActualHours}
        hoursType="totalActuals"
      />
      <Divider className={classes.divider} />
      <ActualSummaryPeriodPopupContent2
        totalHours={totalAllocatedHours - totalActualHours}
        hoursType="totalDifference"
      />
    </>
  );
};

ActualSummaryPeriodPopupContent.propTypes = {
  totalActualHours: PropTypes.number,
  totalAllocatedHours: PropTypes.number
};

export default ActualSummaryPeriodPopupContent;
