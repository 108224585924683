import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import get from 'lodash.get';

export const ESTIMATED_EXPENSES_SERIES = gql`
  query estimatedExpensesSeries(
    $projectId: String!
    $dateRange: DateRangeInput2
    $currencyId: String
  ) {
    estimatedExpensesSeries(
      projectId: $projectId
      dateRange: $dateRange
      currencyId: $currencyId
    ) {
      currency {
        id
        symbol
        displayText
      }
      dataPoints {
        billableAmount
        nonBillableAmount
        period {
          start: periodStart2
          end: periodEnd2
        }
      }
      expenseCode {
        id
        displayText
      }
    }
  }
`;

export const useEstimatedExpensesSeries = (
  projectId,
  dateRange,
  currencyId
) => {
  const { data, loading, refetch } = useQuery(ESTIMATED_EXPENSES_SERIES, {
    variables: {
      projectId,
      dateRange:
        dateRange && dateRange.startDate && dateRange.endDate
          ? {
              startDate: dateRange.startDate.toISODate(),
              endDate: dateRange.endDate.toISODate()
            }
          : null,
      currencyId
    },
    skip: !projectId || !dateRange,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  const estimatedExpensesSeries = get(data, 'estimatedExpensesSeries', []);

  return {
    loading,
    estimatedExpensesSeries,
    refetch
  };
};

export default useEstimatedExpensesSeries;
