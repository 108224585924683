import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import ZeroTimeIcon from './ZeroTimeIcon';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(6),
    paddingLeft: theme.spacing(0.5)
  }
}));

export const ZeroTimeLink = ({ onClose, zeroTimeUrl }) => {
  const intl = useIntl();

  const classes = useStyles();

  return (
    <MenuItem
      component="a"
      href={zeroTimeUrl}
      target="_blank"
      onClick={onClose}
    >
      <ListItemIcon className={classes.icon}>
        <ZeroTimeIcon />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({ id: 'zeroTimeLink.zeroTime' })}
      />
    </MenuItem>
  );
};

ZeroTimeLink.propTypes = {
  onClose: PropTypes.func,
  zeroTimeUrl: PropTypes.string.isRequired
};

export default ZeroTimeLink;
