import { useCallback } from 'react';
import { useDialogState, useMenuState } from '~/modules/common/hooks';

export const useVendorActions = ({ id }) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const isMenuOpen = Boolean(anchorEl);
  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const onRemoveVendorMenuItemClick = useCallback(() => {
    onMenuClose();
    openRemoveDialog();
  }, [onMenuClose, openRemoveDialog]);

  return {
    onRemoveVendorMenuItemClick,
    onMenuClick,
    anchorEl,
    isMenuOpen,
    onMenuClose,
    showRemoveDialog,
    closeRemoveDialog
  };
};

export default useVendorActions;
