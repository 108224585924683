/* eslint-disable react/jsx-max-depth */
import { Divider, Grid, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CurrencyDropdown,
  FormSection,
  Money2,
  NumberInput
} from '~/modules/common/components';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { CostTypeDropdown } from '~/modules/projects/project/common/components';
import { getError, hasError } from '~/util';
import { useMeContext } from '~/modules/me/useMeContext';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useFormOnChangeHandlers } from './useFormOnChangeHandlers';

export const highlightTarget = event => event.target.select();

const useStyles = makeStyles(theme => ({
  legend: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 1, 0)
  },
  label: {
    right: 0,
    left: 'unset',
    transform: 'translate(-12px, 7px) scale(0.75) !important',
    transformOrigin: 'top right',
    fontSize: theme.typography.body1.fontSize
  },
  textFieldClassName: {
    margin: theme.spacing(0)
  },
  currencyChangeWarning: {
    margin: theme.spacing(1, 0, 1, 1)
  },
  sign: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(3)
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%'
    }
  },
  left: {
    width: '33%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '30%'
    }
  },
  middle: {
    width: '33%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '30%'
    }
  },
  right: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '30%'
    }
  }
}));

const labels = {
  budgetLabel: <FormattedMessage id="projectFinancialsCard.budget" />,
  estimatesLabel: <FormattedMessage id="projectFinancialsCard.estimates" />,
  budgetHoursLabel: <FormattedMessage id="projectFinancialsCard.budgetHours" />,
  budgetedCostLabel: (
    <FormattedMessage id="projectFinancialsCard.budgetedCost" />
  ),
  totalEstimatedContractLabel: (
    <FormattedMessage id="projectFinancialsCard.totalEstimatedContract" />
  ),
  estimatedHoursLabel: (
    <FormattedMessage id="projectFinancialsCard.estimatedHours" />
  ),
  estimatedCostLabel: (
    <FormattedMessage id="projectFinancialsCard.estimatedCost" />
  ),
  projectCurrencyLabel: (
    <FormattedMessage id="projectFinancialsCard.projectCurrency" />
  ),
  projectCurrencyWarningLabel: (
    <FormattedMessage id="projectFinancialsCard.projectCurrencyWarning" />
  ),
  costTypeLabel: <FormattedMessage id="projectFinancialsCard.costType" />,
  resourceBudgetedLabel: (
    <FormattedMessage id="projectFinancialsCard.resourceBudgeted" />
  ),
  expenseBudgetedLabel: (
    <FormattedMessage id="projectFinancialsCard.expenseBudgeted" />
  ),
  budgetCurrencyWarningLabel: (
    <FormattedMessage id="projectFinancialsCard.budgetCurrencyWarning" />
  )
};

const plusSign = <FormattedMessage id="projectFinancialsCard.plusSign" />;
const equalSign = <FormattedMessage id="projectFinancialsCard.equalSign" />;

const FinancialsEditable = ({ projectPermissions = {} }) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpProductPackagingEnabled },
    isExpenseProductEnabled
  } = me;
  const isMobile = useIsBreakpointDown('sm');

  const checkForExpenseProduct = isPsaPrpProductPackagingEnabled
    ? isExpenseProductEnabled
    : true;

  const classes = useStyles();
  const {
    canViewBillingContracts,
    canEditBillingContracts,
    canEditCostTypes,
    canViewProjectCostData
  } = projectPermissions;

  const mdWidthBudgetSectionDivisor =
    [canViewProjectCostData, canViewBillingContracts].filter(Boolean).length +
    1;
  const mdWidthCostSectionDivisor =
    [canViewProjectCostData, canEditCostTypes].filter(Boolean).length + 1;
  const mdWidthBudgetSection = 12 / mdWidthBudgetSectionDivisor;
  const mdWidthCostTypeSection = 12 / mdWidthCostSectionDivisor;
  const mdWidthEstimateSection = canEditCostTypes ? 8 : 12;

  const { values, errors, setFieldValue } = useFormikContext();
  const {
    budgetHours,
    budgetedCost,
    estimatedHours,
    estimatedCost,
    projectCurrency,
    totalEstimatedContract,
    isProjectCurrencyChanged,
    costType,
    resourceBudgetedCost,
    expenseBudgetedCost,
    isBudgetCurrencyChanged
  } = values;
  const {
    onProjectCurrencyChange,
    onBudgetHoursChange,
    onBudgetedCostAmountChange,
    onBudgetedCostCurrencyChange,
    onTotalEstimatedContractAmountChange,
    onTotalEstimatedContractCurrencyChange,
    onEstimatedHoursChange,
    onEstimatedCostAmountChange,
    onEstimatedCostCurrencyChange,
    onCostTypeChange,
    onResourceCostAmountChange,
    onResourceCostCurrencyChange,
    onExpenseCostAmountChange,
    onExpenseCostCurrencyChange
  } = useFormOnChangeHandlers({
    setFieldValue,
    values,
    checkForExpenseProduct
  });

  const costClasses = useMemo(
    () => ({
      legend: classes.legend
    }),
    [classes.legend]
  );

  const { totalBudgetedCost } = useMemo(
    () => ({
      totalBudgetedCost:
        resourceBudgetedCost.amount + expenseBudgetedCost.amount
    }),
    [resourceBudgetedCost, expenseBudgetedCost]
  );

  const showTotalsWarning = isBudgetCurrencyChanged;
  const InputLabelProps = useMemo(() => ({ shrink: true }), []);

  return (
    <>
      {!isEmpty(errors) && <FormErrorAlert />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CurrencyDropdown
            classes={classes}
            variant="filled"
            label={labels.projectCurrencyLabel}
            value={projectCurrency}
            noneOption={false}
            onChange={onProjectCurrencyChange}
            dataQeId="FinancialsEditable_CurrencyDropdown"
            editable
          />
        </Grid>
        {isProjectCurrencyChanged ? (
          <Alert severity="warning" className={classes.currencyChangeWarning}>
            {labels.projectCurrencyWarningLabel}
          </Alert>
        ) : null}
        <Grid item xs={12} sm={12}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={labels.budgetLabel}
          />
        </Grid>
        {checkForExpenseProduct ? (
          <>
            {canViewProjectCostData && (
              <>
                <Grid className={classes.content} item>
                  <Grid item className={classes.left}>
                    <Money2
                      textFieldClassName={classes.textFieldClassName}
                      labelClassName={classes.label}
                      label={labels.resourceBudgetedLabel}
                      amount={resourceBudgetedCost.amount}
                      currency={resourceBudgetedCost.currency}
                      onCurrencyChange={onResourceCostCurrencyChange}
                      onAmountChange={onResourceCostAmountChange}
                      InputLabelProps={isMobile ? InputLabelProps : null}
                      error={hasError(errors, 'resourceBudgetedCost.amount')}
                      helperText={getError(
                        errors,
                        'resourceBudgetedCost.amount'
                      )}
                      margin="dense"
                      valueDataQeId="ResourceBudgetCostAmount"
                      currencyDataQeId="BudgetCostCurrency"
                    />
                  </Grid>
                  <span className={classes.sign}>{plusSign}</span>
                  <Grid item className={classes.middle}>
                    <Money2
                      textFieldClassName={classes.textFieldClassName}
                      labelClassName={classes.label}
                      label={labels.expenseBudgetedLabel}
                      amount={expenseBudgetedCost.amount}
                      currency={expenseBudgetedCost.currency}
                      onCurrencyChange={onExpenseCostCurrencyChange}
                      onAmountChange={onExpenseCostAmountChange}
                      error={hasError(errors, 'expenseBudgetedCost.amount')}
                      helperText={getError(
                        errors,
                        'expenseBudgetedCost.amount'
                      )}
                      InputLabelProps={isMobile ? InputLabelProps : null}
                      margin="dense"
                      valueDataQeId="ExpenseBudgetCostAmount"
                      currencyDataQeId="BudgetCostCurrency"
                    />
                  </Grid>
                  <span className={classes.sign}>{equalSign}</span>
                  <Grid item className={classes.right}>
                    <Money2
                      editable={false}
                      textFieldClassName={classes.textFieldClassName}
                      labelClassName={classes.label}
                      label={labels.budgetedCostLabel}
                      amount={totalBudgetedCost}
                      currency={expenseBudgetedCost.currency}
                      onCurrencyChange={onBudgetedCostCurrencyChange}
                      onAmountChange={onBudgetedCostAmountChange}
                      error={hasError(errors, 'budgetedCost.amount')}
                      helperText={getError(errors, 'budgetedCost.amount')}
                      margin="dense"
                      valueDataQeId="BudgetCostReadOnlyAmount"
                      currencyDataQeId="BudgetCostReadOnlyCurrency"
                    />
                    {showTotalsWarning ? (
                      <Alert severity="warning">
                        {labels.budgetCurrencyWarningLabel}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    textFieldClassName={classes.textFieldClassName}
                    labelClassName={classes.label}
                    data-qe-id="RevenueBudgetHours"
                    label={labels.budgetHoursLabel}
                    value={budgetHours}
                    onValueChange={onBudgetHoursChange}
                    fullWidth
                    error={hasError(errors, 'budgetHours')}
                    helperText={getError(errors, 'budgetHours')}
                    onFocus={highlightTarget}
                    variant="filled"
                    margin="dense"
                  />
                </Grid>
                {Boolean(canViewBillingContracts) && (
                  <Grid item xs={6}>
                    <Money2
                      editable={canEditBillingContracts}
                      textFieldClassName={classes.textFieldClassName}
                      labelClassName={classes.label}
                      label={labels.totalEstimatedContractLabel}
                      currencyDataQeId="TotalContractCurrency"
                      valueDataQeId="TotalContractTextField"
                      amount={totalEstimatedContract.amount}
                      currency={totalEstimatedContract.currency}
                      onCurrencyChange={onTotalEstimatedContractCurrencyChange}
                      onAmountChange={onTotalEstimatedContractAmountChange}
                      error={hasError(errors, 'totalEstimatedContract.amount')}
                      helperText={getError(
                        errors,
                        'totalEstimatedContract.amount'
                      )}
                      margin="dense"
                    />
                  </Grid>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={mdWidthBudgetSection}>
              <NumberInput
                textFieldClassName={classes.textFieldClassName}
                labelClassName={classes.label}
                data-qe-id="RevenueBudgetHours"
                label={labels.budgetHoursLabel}
                value={budgetHours}
                onValueChange={onBudgetHoursChange}
                fullWidth
                error={hasError(errors, 'budgetHours')}
                helperText={getError(errors, 'budgetHours')}
                onFocus={highlightTarget}
                variant="filled"
                margin="dense"
              />
            </Grid>
            {canViewProjectCostData && (
              <Grid item xs={12} sm={6} md={mdWidthBudgetSection}>
                <Money2
                  textFieldClassName={classes.textFieldClassName}
                  labelClassName={classes.label}
                  label={labels.budgetedCostLabel}
                  currencyDataQeId="BudgetCostCurrency"
                  valueDataQeId="BudgetCostTextField"
                  amount={budgetedCost.amount}
                  currency={budgetedCost.currency}
                  onCurrencyChange={onBudgetedCostCurrencyChange}
                  onAmountChange={onBudgetedCostAmountChange}
                  error={hasError(errors, 'budgetedCost.amount')}
                  helperText={getError(errors, 'budgetedCost.amount')}
                  margin="dense"
                />
                {showTotalsWarning ? (
                  <Alert severity="warning">
                    {labels.budgetCurrencyWarningLabel}
                  </Alert>
                ) : null}
              </Grid>
            )}
            {Boolean(canViewBillingContracts) && (
              <Grid item xs={12} sm={6} md={mdWidthBudgetSection}>
                <Money2
                  editable={canEditBillingContracts}
                  textFieldClassName={classes.textFieldClassName}
                  labelClassName={classes.label}
                  label={labels.totalEstimatedContractLabel}
                  currencyDataQeId="TotalContractCurrency"
                  valueDataQeId="TotalContractTextField"
                  amount={totalEstimatedContract.amount}
                  currency={totalEstimatedContract.currency}
                  onCurrencyChange={onTotalEstimatedContractCurrencyChange}
                  onAmountChange={onTotalEstimatedContractAmountChange}
                  error={hasError(errors, 'totalEstimatedContract.amount')}
                  helperText={getError(errors, 'totalEstimatedContract.amount')}
                  margin="dense"
                />
              </Grid>
            )}
          </>
        )}
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={12} md={mdWidthEstimateSection}>
          <FormSection
            classes={useMemo(
              () => ({
                legend: classes.legend
              }),
              [classes.legend]
            )}
            title={labels.estimatesLabel}
          />
        </Grid>
        {canEditCostTypes ? (
          <Grid item xs={12} sm={12} md={4}>
            <FormSection classes={costClasses} title={labels.costTypeLabel} />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6} md={mdWidthCostTypeSection}>
          <NumberInput
            textFieldClassName={classes.textFieldClassName}
            labelClassName={classes.label}
            label={labels.estimatedHoursLabel}
            value={estimatedHours}
            onValueChange={onEstimatedHoursChange}
            fullWidth
            error={hasError(errors, 'estimatedHours')}
            helperText={getError(errors, 'estimatedHours')}
            onFocus={highlightTarget}
            variant="filled"
            margin="dense"
          />
        </Grid>
        {canViewProjectCostData && (
          <Grid item xs={12} sm={6} md={mdWidthCostTypeSection}>
            <Money2
              textFieldClassName={classes.textFieldClassName}
              labelClassName={classes.label}
              label={labels.estimatedCostLabel}
              amount={estimatedCost.amount}
              currency={estimatedCost.currency}
              onAmountChange={onEstimatedCostAmountChange}
              onCurrencyChange={onEstimatedCostCurrencyChange}
              error={hasError(errors, 'estimatedCost.amount')}
              helperText={getError(errors, 'estimatedCost.amount')}
              margin="dense"
            />
          </Grid>
        )}
        {canEditCostTypes ? (
          <Grid item xs={12} sm={6} md={mdWidthCostTypeSection}>
            <CostTypeDropdown
              value={costType}
              onChange={onCostTypeChange}
              formControlMargin="dense"
              fullWidth
              dataQeId="CostTypeDropdown"
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

FinancialsEditable.propTypes = {
  projectPermissions: PropTypes.object
};

export default FinancialsEditable;
