import { gql } from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
import {
  omitForbiddenFields,
  updateCache,
  tryLoadCachedProjectQuery,
  appendResourceRequestFields,
  appendScheduleRuleTypeNameFields
} from '~/modules/resourcing/common/util';
import {
  resourceRequestTotalsFragment,
  specificResourceRequestWithAllocationsFragment
} from '~/modules/resourcing/common/fragments';
import { ResourceRequestStatus } from '~/types';
import resourceRequestQuery from '~/modules/resourcing/common/resourceRequestQuery';
import RESOURCE_REQUESTS_ALLOCATIONS_QUERY, {
  RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
} from '~/modules/resourcing/common/fragments/resourceRequestsQuery';
import { updateProjectTotalsCacheForNewRequest } from '~/modules/resourcing/enhancers/updateResourceRequestTotalsCache';

export const CREATE_RESOURCE_REQUEST = gql`
  mutation Eager_CreateResourceRequest($input: CreateResourceRequestInput!) {
    createResourceRequest2(input: $input) {
      resourceRequest {
        ...SpecificResourceRequestWithAllocation
        ...ResourceRequestTotalsFragment
      }
    }
  }
  ${specificResourceRequestWithAllocationsFragment}
  ${resourceRequestTotalsFragment}
`;

const appendToProjectResourceRequests = (
  existingResourceRequests,
  newRequest
) => [...existingResourceRequests, newRequest];

export const _updateCache = ({
  resourceRequest,
  filter,
  skipTotalsCacheUpdate = false,
  isResourceActualModeEnabled
}) => (proxy, mutationResponse) => {
  const responseType = 'createResourceRequest2';

  const { project: cachedProjectFragment } = tryLoadCachedProjectQuery({
    proxy,
    projectId: resourceRequest.projectUri
  });

  if (cachedProjectFragment) {
    const {
      data: {
        [responseType]: { resourceRequest: newRequest }
      }
    } = mutationResponse;

    proxy.writeQuery({
      query: resourceRequestQuery,
      variables: {
        id: newRequest.id
      },
      data: {
        resourceRequest: { ...newRequest, project: cachedProjectFragment }
      }
    });

    if (!skipTotalsCacheUpdate) {
      updateProjectTotalsCacheForNewRequest({
        proxy,
        newRequest,
        filter: filter || {},
        isResourceActualModeEnabled
      });
    }

    updateCache(
      appendToProjectResourceRequests,
      resourceRequest.projectUri,
      responseType,
      resourceRequest.requestStatus,
      resourceRequest
    )(proxy, mutationResponse);
  }
};

export const updateCachedProjectResourceRequests = ({
  variables,
  filter,
  skipTotalsCacheUpdate = false,
  isResourceActualModeEnabled
}) => (proxy, mutationResponse) => {
  const responseType = 'createResourceRequest2';

  const { showTimeOff, showHolidays } = variables;
  const query =
    showHolidays || showTimeOff
      ? RESOURCE_REQUESTS_ALLOCATIONS_QUERY_WITH_TIMEOFF
      : RESOURCE_REQUESTS_ALLOCATIONS_QUERY;

  const {
    data: {
      [responseType]: { resourceRequest: newRequest }
    }
  } = mutationResponse;

  try {
    const cachedResourceRequests = proxy.readQuery({
      query,
      variables
    });

    if (cachedResourceRequests) {
      const { resourceRequests: data } = cachedResourceRequests;

      proxy.writeQuery({
        query,
        variables,
        data: {
          resourceRequests: {
            ...data,
            resourceRequests: [
              ...data.resourceRequests,
              {
                ...newRequest,
                resourceMatches: []
              }
            ]
          }
        }
      });
    }

    if (!skipTotalsCacheUpdate) {
      updateProjectTotalsCacheForNewRequest({
        proxy,
        newRequest,
        filter: filter || {},
        isResourceActualModeEnabled
      });
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

const optimisticResponse = ({ resourceRequest, skills }) => ({
  __typename: 'Mutation',
  createResourceRequest2: {
    __typename: 'CreateResourceRequestResult',
    isOptimistic: true,
    resourceRequest: {
      __typename: 'ResourceRequest',
      comment: resourceRequest.comment || null,
      scheduleRules: resourceRequest.scheduleRules.map(
        appendScheduleRuleTypeNameFields
      ),
      endDate:
        resourceRequest.endDate ||
        resourceRequest.scheduleRules[0].dateRange.endDate,
      startDate:
        resourceRequest.startDate ||
        resourceRequest.scheduleRules[0].dateRange.startDate,
      id: resourceRequest.id,
      load: resourceRequest.load,
      role: resourceRequest.role || null,
      requestStatus: ResourceRequestStatus.Draft,
      projectUri: resourceRequest.projectUri,
      quantity: resourceRequest.quantity,
      location: resourceRequest.location || null,
      division: resourceRequest.division || null,
      costCenter: resourceRequest.costCenter || null,
      serviceCenter: resourceRequest.serviceCenter || null,
      department: resourceRequest.department || null,
      employeeType: resourceRequest.employeeType || null,
      roleRate: resourceRequest.roleRate || null,
      currency: resourceRequest.currency || null,
      currencyUri: resourceRequest.currencyUri,
      totalHours: resourceRequest.totalHours || 0,
      resourcePools: resourceRequest.resourcePools || [],
      resourceAllocations: [],
      skills: skills || [],
      tags: resourceRequest.tags || [],
      exchangeRateValues: resourceRequest.exchangeRateValues || null,
      isAdjustedLoading: resourceRequest.isAdjustedLoading || false,
      requestAttributeWeights: resourceRequest.requestAttributeWeights || null,
      totalRequestedCostByCurrency:
        resourceRequest.totalRequestedCostByCurrency || null,
      preferredResources: resourceRequest.preferredResources || [],
      sourceMetadata: resourceRequest.sourceMetadata || null
    }
  }
});

export const duplicateResourceRequest = ({
  createResourceRequest,
  me,
  variables,
  filter,
  skipTotalsCacheUpdate = false,
  isResourceActualModeEnabled
}) => request => {
  const { tenantSlug } = me;
  const resourceRequest = {
    ...request,
    ...appendResourceRequestFields(request),
    id: `urn:replicon-tenant:${tenantSlug}:psa-resource-request:${uuidv4()}`
  };

  return createResourceRequest({
    variables: {
      input: omitForbiddenFields(resourceRequest)
    },
    optimisticResponse: optimisticResponse({
      resourceRequest,
      skills: request.skills
    }),
    update: updateCachedProjectResourceRequests({
      variables,
      filter,
      skipTotalsCacheUpdate,
      isResourceActualModeEnabled
    })
  });
};
