import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSearchState } from '~/modules/common/components/SearchBox';
import { ResourceAssignmentDialogContext } from './ResourceAssignmentDialogContext';

export default function ResourceAssignmentDialogContextProvider({
  children,
  vendor
}) {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCriteria, setSearchCriteria] = useSearchState(
    'usersAvailableForVendorAssignment'
  );

  const value = useMemo(
    () => ({
      searchCriteria,
      setSearchCriteria,
      currentPage,
      setCurrentPage,
      vendor,
      users,
      setUsers
    }),
    [searchCriteria, setSearchCriteria, currentPage, vendor, users]
  );

  return (
    <ResourceAssignmentDialogContext.Provider value={value}>
      {children}
    </ResourceAssignmentDialogContext.Provider>
  );
}

ResourceAssignmentDialogContextProvider.propTypes = {
  children: PropTypes.node,
  vendor: PropTypes.object
};
