import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useFetchProjectRequestField } from '~/modules/project-request/ProjectRequestPage/components/ProjectRequestDrawer/hooks/useFetchProjectRequestField';

const UPDATE_ESTIMATED_COST_OVERRIDE = gql`
  mutation updateEstimatedCostOverride(
    $input: UpdateEstimatedCostOverrideInput!
  ) {
    updateEstimatedCostOverride(input: $input) {
      projectRequestId
    }
  }
`;

export const useUpdateEstimatedCostOverride = ({ projectRequestId }) => {
  const { fetchProjectRequestField } = useFetchProjectRequestField(
    projectRequestId
  );
  const [updateEstimatedCostOverride] = useMutation(
    UPDATE_ESTIMATED_COST_OVERRIDE,
    {
      onCompleted: fetchProjectRequestField
    }
  );

  return {
    updateEstimatedCostOverride: async ({ estimatedCostOverride }) => {
      await updateEstimatedCostOverride({
        variables: {
          input: {
            projectRequestId,
            estimatedCostOverride: estimatedCostOverride
              ? {
                  amount: estimatedCostOverride.amount,
                  currency: {
                    id: estimatedCostOverride.currency.id
                  }
                }
              : null
          }
        },
        skip: !projectRequestId
      });
    }
  };
};

export default useUpdateEstimatedCostOverride;
