import React, { useCallback, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@material-ui/core';
import { connect } from 'formik';
import { getError } from '~/util';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { ProgramManagerDropdown } from '../../program/BasicInformation/components';
import { FormErrorContext } from './FormErrorContext';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const AddProgramForm = ({
  formik: { handleChange, setFieldValue, values, errors }
}) => {
  const { formatMessage } = useIntl();
  const programNameLabel = formatMessage({ id: 'addProgram.name' });

  const { name, programManagerReference } = values;

  const { error: submitError, clearError: clearDuplicateNameError } =
    useContext(FormErrorContext) || {};

  const handleNameChange = useCallback(
    event => {
      handleChange(event);
      clearDuplicateNameError();
    },
    [handleChange, clearDuplicateNameError]
  );

  const handleProgramManagerChange = useCallback(
    event => {
      setFieldValue('programManagerReference', event);
    },
    [setFieldValue]
  );

  const nameError = getError(errors, 'name');

  return (
    <>
      {!nameError && submitError && (
        <FormErrorAlert errorMessage={submitError} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            required
            fullWidth
            variant="filled"
            data-qe-id="AddProgramName"
            label={programNameLabel}
            inputProps={getAriaLabel(programNameLabel)}
            value={name}
            onChange={handleNameChange}
            autoFocus
            error={Boolean(nameError)}
            helperText={nameError}
          />
        </Grid>
        <Grid item xs={12}>
          <ProgramManagerDropdown
            dataQeId="AddProgramManagerDropdown"
            value={programManagerReference}
            onChange={handleProgramManagerChange}
            programId=""
          />
        </Grid>
      </Grid>
    </>
  );
};

AddProgramForm.propTypes = {
  formik: PropTypes.object
};

export default connect(AddProgramForm);
