import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const REMOVE_USER_ASSIGNMENT_FOR_VENDOR = gql`
  mutation PutUserAssignmentForVendor($input: UserAssignmentForVendorInput!) {
    putUserAssignmentForVendor(input: $input) {
      vendorRef
      userRef
    }
  }
`;

export const useRemoveUserAssignmentForVendor = () => {
  const [putUserAssignmentForVendor, { loading }] = useMutation(
    REMOVE_USER_ASSIGNMENT_FOR_VENDOR
  );

  return {
    removeUserAssignmentForVendor: async input => {
      await putUserAssignmentForVendor({
        variables: {
          input
        },
        refetchQueries: ['vendorAssignments']
      });
    },
    loading
  };
};

export default useRemoveUserAssignmentForVendor;
