import React, { useMemo } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';
import { Money2, MoneyValue, NoValue } from '~/modules/common/components';
import { getError, hasError } from '~/util';
import { useOnChangeHandlers } from './hooks/useOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  moneyField: {
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  estimatedCostOverrideSection: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  useRecommendedLink: {
    margin: theme.spacing(0, 0, 0, 0.5),
    cursor: 'pointer'
  },
  suggestedEstimatedCost: ({ hasValidationError }) => ({
    ...theme.typography.caption,
    ...(hasValidationError && {
      marginBottom: theme.spacing(3)
    }),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 0)
    }
  }),
  costAmount: {
    margin: '0px 4px 0px 4px'
  }
}));

const useMoneyStyles = makeStyles(theme => ({
  currency: {
    marginBottom: theme.spacing(1.5)
  }
}));

const useNumberInputStyles = makeStyles(theme => ({
  input: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.625)
  }
}));

export const OverrideEstimatedCost = ({
  setHasEstimatedCostOverride,
  canEditPlanningData,
  hasResourceAssignments
}) => {
  const {
    setFieldValue,
    setFieldError,
    values: {
      id: projectRequestId,
      estimatedCostOverride,
      estimatedCost,
      initialEstimatedCostCurrency
    },
    errors
  } = useFormikContext();

  const hasValidationError = hasError(errors, 'estimatedCostOverride');

  const { formatMessage } = useIntl();
  const classes = useStyles({
    hasValidationError
  });
  const numberInputClasses = useNumberInputStyles();
  const currencyClass = useMoneyStyles();

  const {
    onEstimatedCostAmountChange,
    onEstimatedCostCurrencyChange,
    onBlurHandler,
    onUseLinkClickHandler
  } = useOnChangeHandlers({
    projectRequestId,
    estimatedCost,
    estimatedCostOverride,
    initialEstimatedCostCurrency,
    setFieldValue,
    setFieldError,
    errors,
    setHasEstimatedCostOverride
  });

  const value = useMemo(
    () => ({
      estimatedCost:
        estimatedCost === null ? (
          <NoValue className={classes.costAmount} />
        ) : (
          <MoneyValue money={estimatedCost} className={classes.costAmount} />
        )
    }),
    [classes.costAmount, estimatedCost]
  );

  return (
    <div className={classes.estimatedCostOverrideSection}>
      <div className={classes.moneyField}>
        <Money2
          classes={currencyClass}
          numberInputClasses={numberInputClasses}
          variant="filled"
          amount={estimatedCostOverride?.amount}
          editable={canEditPlanningData}
          currency={
            estimatedCostOverride?.currency || initialEstimatedCostCurrency
          }
          onAmountChange={onEstimatedCostAmountChange}
          onCurrencyChange={onEstimatedCostCurrencyChange}
          onAmountBlur={onBlurHandler}
          error={hasError(errors, 'estimatedCostOverride')}
          helperText={getError(errors, 'estimatedCostOverride')}
          ariaLabel={formatMessage({
            id: 'projectRequest.estimatedCost'
          })}
          valueDataQeId="EstimatedCostOverride_Amount"
          currencyDataQeId="EstimatedCostOverride_Currency"
        />
      </div>
      {canEditPlanningData && (
        <div className={classes.suggestedEstimatedCost}>
          {hasResourceAssignments ? (
            <FormattedMessage
              id="projectRequest.suggestedCost"
              values={value}
            />
          ) : null}
          <Link
            component="button"
            onClick={onUseLinkClickHandler}
            className={classes.useRecommendedLink}
            data-qe-id="UseSuggestedCost"
          >
            <FormattedMessage
              id={
                hasResourceAssignments
                  ? 'projectRequest.useThis'
                  : 'projectRequest.useCalculatedEstimatedCost'
              }
            />
          </Link>
        </div>
      )}
    </div>
  );
};

OverrideEstimatedCost.propTypes = {
  setHasEstimatedCostOverride: PropTypes.func,
  canEditPlanningData: PropTypes.bool,
  hasResourceAssignments: PropTypes.bool
};

export default OverrideEstimatedCost;
