import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

import QuickFilterSummaryTabs, {
  MobileQuickFilterSummaryTabs
} from '~/modules/common/components/QuickFilterSummaryTabs';

import { ProjectManagementType } from '~/types';
import {
  useProjectsStatusCountSummaryQuery,
  useIsBreakpointDown
} from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me/useMeContext';
import { useProjectFilterMemo } from '~/modules/projects/components/ProjectListPage/useProjectFilterMemo';
import { useCustomProjectStatusNamesQuery } from '~/modules/projects/graphql/useCustomProjectStatusNameQuery';
import { getStatusCountSummaryBasedProps } from './useStatusCountSummary';
import { getQuickFilterSummaryTabs } from './getQuickFilterSummaryTabs';
import { useProjectFilterWithExcludingArchiveProjects } from './useProjectFilterWithExcludingArchiveProjects';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    left: 0,
    right: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2)
  }
}));

export const ProjectsQuickFilterSummary = ({
  projectType,
  searchCriteria,
  summaryFilterState,
  hideMyProjects = false,
  includeBaseLinkInList
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const {
    featureFlags: { isPsaPrpRenameProjectStatusEnabled }
  } = useMeContext();
  const classes = useStyles();

  const { projectFilter } = useProjectFilterMemo({
    searchCriteria,
    includeBaseLinkInList
  });

  const filterWithExcludingArchiveProjects = useProjectFilterWithExcludingArchiveProjects(
    {
      projectFilter
    }
  );

  const {
    isProjectCustomStatusNamesLoading,
    customStatusMapping
  } = useCustomProjectStatusNamesQuery({
    skip: !isPsaPrpRenameProjectStatusEnabled
  });

  const isCustomStatusNameEnabled =
    isPsaPrpRenameProjectStatusEnabled &&
    !isProjectCustomStatusNamesLoading &&
    customStatusMapping;

  const {
    loading: isAllProjectsSummaryLoading,
    projectsStatusCountSummary: allProjectsStatusCountSummary = {}
  } = useProjectsStatusCountSummaryQuery({
    isManaged: projectType === ProjectManagementType.Managed,
    projectFilter: filterWithExcludingArchiveProjects
  });

  const {
    loading: isMyProjectsSummaryLoading,
    projectsStatusCountSummary: myProjectsStatusCountSummary = {}
  } = useProjectsStatusCountSummaryQuery({
    isManaged: projectType === ProjectManagementType.Managed,
    projectFilter: {
      ...filterWithExcludingArchiveProjects,
      includeMyProjectsOnly: true
    }
  });

  const {
    loading: isArchivedCountLoading,
    projectsStatusCountSummary: archivedProjectsCountSummary = {}
  } = useProjectsStatusCountSummaryQuery({
    isManaged: projectType === ProjectManagementType.Managed,
    projectFilter
  });

  const {
    statusOptions,
    totalProjects,
    myProjects,
    archivedStatus
  } = getStatusCountSummaryBasedProps({
    allProjectsStatusCountSummary,
    myProjectsStatusCountSummary,
    archivedProjectsCount: archivedProjectsCountSummary.archived || 0,
    filterByAccess: summaryFilterState.quickFilter.filterByAccess,
    customStatusMapping,
    isCustomStatusNameEnabled
  });

  const {
    allItems,
    executionPhases,
    alternativeItems
  } = getQuickFilterSummaryTabs({
    hideMyProjects,
    statusOptions,
    totalProjects,
    myProjects,
    archivedStatus
  });

  const isLoading =
    isAllProjectsSummaryLoading ||
    isMyProjectsSummaryLoading ||
    isArchivedCountLoading;

  return isMobile ? (
    <MobileQuickFilterSummaryTabs
      allItems={allItems}
      alternativeItems={alternativeItems}
      summaryItems={executionPhases}
      summaryFilterState={summaryFilterState}
      isValueLoading={isLoading}
    />
  ) : (
    <div className={classes.root}>
      <QuickFilterSummaryTabs
        allItems={allItems}
        summaryItems={executionPhases}
        summaryFilterState={summaryFilterState}
        isValueLoading={isLoading}
        alternativeItems={alternativeItems}
      />
    </div>
  );
};

ProjectsQuickFilterSummary.propTypes = {
  projectType: PropTypes.string,
  searchCriteria: PropTypes.object,
  summaryFilterState: PropTypes.object,
  hideMyProjects: PropTypes.bool,
  includeBaseLinkInList: PropTypes.bool
};

export default ProjectsQuickFilterSummary;
