import { makeStyles, Popover, Button, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListTable } from '~/modules/common/components/ListTable';
import { ListTableThemeProvider } from '~/modules/common/components/ListTable/ListTableThemeContext';
import { useMenuState } from '~/modules/common/hooks';
import { useListColumns } from '../dialogs/billing/columns';

const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    borderCollapse: 'separate',
    borderBottom: '1px solid',
    borderBottomColor: '#e0e0e0'
  },
  tableBody: {
    backgroundColor: 'inherit',
    borderWidth: theme.spacing(0.2, 0),
    borderColor: theme.palette.grey[400],
    borderStyle: 'solid'
  },
  tableHeader: {
    backgroundColor: 'inherit'
  },
  tableHeaderCell: {
    padding: theme.spacing(0, 2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 0,
    color: theme.palette.grey[800],
    fontSize: theme.typography.caption.fontSize,
    position: 'static'
  },
  tableCell: {
    height: 0,
    padding: theme.spacing(0.5, 2),
    fontSize: theme.typography.caption.fontSize,
    '& span': {
      fontSize: theme.typography.caption.fontSize
    },
    '& p': {
      fontSize: theme.typography.caption.fontSize
    },
    border: theme.spacing(0)
  }
}));

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto'
    }
  },
  button: {
    textAlign: 'right',
    padding: theme.spacing(0.8)
  },
  showDetails: {
    padding: theme.spacing(0)
  },
  heading: {
    padding: theme.spacing(0, 0, 0, 1.9)
  }
}));

const useColumnStyles = makeStyles(theme => ({
  text: {
    '& p': {
      maxWidth: theme.spacing(15),
      whiteSpace: 'break-spaces'
    }
  }
}));

const useCardStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(1, 0)
  }
}));

const popoverProps = {
  anchor: {
    vertical: 'center',
    horizontal: 'center'
  },
  transform: {
    vertical: 'center',
    horizontal: 'center'
  }
};

export const ClientTaxRatesListTable = ({
  taxRatesLoading,
  taxRateRows,
  taxRateHeaders
}) => {
  const providerClasses = useListTableThemeProviderStyles();
  const cardClasses = useCardStyles();
  const columnClasses = useColumnStyles();
  const classes = useStyles();
  const columns = useListColumns({
    classes: columnClasses,
    taxRateHeaders
  });

  const { anchorEl, onMenuClose, onMenuOpen } = useMenuState();

  const handleClick = event => {
    onMenuOpen(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        aria-describedby="TaxprofileDetails"
        variant="text"
        color="primary"
        onClick={handleClick}
        className={classes.showDetails}
      >
        <FormattedMessage id="client.showDetails" />
      </Button>
      <Popover
        id="tax-profile-details"
        open={open}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        anchorOrigin={popoverProps.anchor}
        transformOrigin={popoverProps.transform}
      >
        <div className={classes.wrapper}>
          <Typography variant="subtitle1" className={classes.heading}>
            <FormattedMessage id="client.taxTypesHeading" />
          </Typography>
          {taxRateRows.length ? (
            <ListTableThemeProvider classes={providerClasses}>
              <ListTable
                variant="table"
                classes={cardClasses}
                columns={columns}
                records={taxRateRows}
                isLoading={taxRatesLoading}
              />
            </ListTableThemeProvider>
          ) : null}
          <div className={classes.button}>
            <Button size="small" onClick={onMenuClose}>
              <FormattedMessage id="client.cancelDetails" />
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

ClientTaxRatesListTable.propTypes = {
  taxRatesLoading: PropTypes.bool,
  taxRateRows: PropTypes.array,
  taxRateHeaders: PropTypes.array
};

export default ClientTaxRatesListTable;
