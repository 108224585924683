import { getRoutePermissions } from '~/modules/common/permissions';
import VendorPage from './VendorPage';

export const vendorRoute = ({ me }) => {
  const { canViewVendors } = getRoutePermissions(me);

  return {
    path: '/vendors/:slug',
    icon: () => null,
    hidden: true,
    component: VendorPage,
    hasPermission: canViewVendors
  };
};
