import { useCallback } from 'react';
import { useChangeTaskResource } from './useChangeTaskResource';

export const useOnSubmitHandler = ({
  existingResourceUserId,
  newResourceUserId,
  onClose,
  onChangeTaskResourceSuccess,
  projectId,
  setIsSaving,
  taskId,
  allocationMode
}) => {
  const { changeTaskResource } = useChangeTaskResource();

  const onSubmit = useCallback(async () => {
    setIsSaving(true);
    const {
      data: {
        changeTaskResourceUser: { taskResourceEstimateId }
      }
    } = await changeTaskResource({
      existingResourceUserId,
      newResourceUserId,
      projectId,
      taskId,
      allocationMode
    });

    await onChangeTaskResourceSuccess({
      newTaskResourceEstimateId: taskResourceEstimateId
    });
    setIsSaving(false);
    onClose();
  }, [
    changeTaskResource,
    existingResourceUserId,
    newResourceUserId,
    onChangeTaskResourceSuccess,
    onClose,
    projectId,
    setIsSaving,
    taskId,
    allocationMode
  ]);

  return { onSubmit };
};

export default useOnSubmitHandler;
