import React, { useState, useCallback } from 'react';
import { connect } from 'formik';
import { useIntl } from 'react-intl';
import { FormHelperText, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { SearchableUserDropDown } from '~/modules/common/components/UserDropdown';
import { getError } from '~/util';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { useClientRepresentatives } from './useClientRepresentatives';

export const EditClientRepresentativesDialogForm = ({
  formik: { values, setFieldValue, setFieldError, errors }
}) => {
  const { clientRepresentatives } = values;
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);
  const { loading, options, hasMore } = useClientRepresentatives({
    searchTerm
  });

  const onChange = useCallback(
    (selectedUsers, _) => {
      setFieldValue('clientRepresentatives', selectedUsers || []);
      setFieldError('clientRepresentatives', null);
    },
    [setFieldError, setFieldValue]
  );

  const submitErrors = getError(errors, 'clientRepresentatives');

  return (
    <>
      {!submitErrors && !isEmpty(errors) && <FormErrorAlert />}
      <SearchableUserDropDown
        loading={loading}
        onChange={onChange}
        label={formatMessage({ id: 'client.clientRepresentatives' })}
        noOptionsText={formatMessage({
          id: 'client.noClientRepresentatives'
        })}
        multiple
        options={options}
        value={clientRepresentatives}
        handleInputChange={handleInputChange}
        autoFocus
        hasMore={hasMore}
        optionTypeText={formatMessage({
          id: 'moreOptions.clientRepresentatives'
        })}
      />
      {submitErrors && (
        <FormHelperText variant="filled">
          {submitErrors.map(error => (
            <Typography key={error.failureUri} variant="caption" color="error">
              {error.displayText}
            </Typography>
          ))}
        </FormHelperText>
      )}
    </>
  );
};

EditClientRepresentativesDialogForm.propTypes = {
  formik: PropTypes.object
};

export default connect(EditClientRepresentativesDialogForm);
