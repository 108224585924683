import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { MoneyValue, NoValue } from '~/modules/common/components';
import { getRoundedValue } from '~/modules/billing-invoicing/common/enhancers';
import useStyles from './useStyles';

export const MoneyFormatter = ({ field, record }) => {
  const { isActive } = record;
  const classes = useStyles({ isActive });
  const money = useMemo(
    () =>
      record[field] && {
        ...record[field],
        amount: getRoundedValue(record[field].amount, 2) || 0
      },
    [field, record]
  );

  return (
    <div className={classes.root}>
      {money ? <MoneyValue money={money} /> : <NoValue />}
    </div>
  );
};

MoneyFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default MoneyFormatter;
