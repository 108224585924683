import { DialogContentText, Grid, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { RemoveEntityConfirmationDialog } from '~/modules/common/components';
import { useDialogState } from '~/modules/common/hooks';
import ProjectRequestHistoryDialog from '../ProjectRequestHistory/ProjectRequestHistoryDialog';
import { CreateProjectFromRequestDialog } from '../ProjectRequestList/CreateProjectFromRequestDialog';
import ApproveProjectRequestConfirmationDialog from './ApproveProjectRequestConfirmationDialog';
import useDeleteProjectRequest from './hooks/useDeleteProjectRequestHandler';
import ProjectRequestStatusDrawerActions from './ProjectRequestStatusDrawerActions';

const deleteDialogResourceKeys = {
  header: 'projectRequest.deleteDialog.removeRequest',
  actionButton: 'projectRequest.deleteDialog.removeRequest'
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'end'
  }
}));

export const ProjectRequestDrawerActions = ({
  editable,
  projectRequestName,
  projectRequestId,
  onDrawerClose,
  projectRequestStatus,
  projectRequestGates,
  portfolioId,
  portfolioName
}) => {
  const classes = useStyles();

  const { open, openDialog, closeDialog } = useDialogState(false);
  const onCreateProject = useCallback(() => {
    openDialog();
  }, [openDialog]);

  const {
    open: showRemoveDialog,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState(false);

  const onRemoveRequestMenuItemClick = useCallback(() => {
    openRemoveDialog();
  }, [openRemoveDialog]);

  const {
    open: requestHistoryDialogOpen,
    openDialog: openRequestHistoryDialog,
    closeDialog: closeRequestHistoryDialog
  } = useDialogState(false);

  const {
    open: approveProjectRequestConfirmationDialogOpen,
    openDialog: openApproveProjectRequestConfirmationDialog,
    closeDialog: closeApproveProjectRequestConfirmationDialog
  } = useDialogState(false);

  const { deleteProjectRequest } = useDeleteProjectRequest();
  const onDeleteConfirm = useCallback(async () => {
    await deleteProjectRequest(projectRequestId);

    onDrawerClose();
  }, [deleteProjectRequest, onDrawerClose, projectRequestId]);

  return (
    <>
      <Grid
        item
        xs={editable ? 6 : 12}
        className={classes.container}
        data-qe-id="ProjectRequestDrawerAction"
      >
        <ProjectRequestStatusDrawerActions
          editable={editable}
          projectRequestId={projectRequestId}
          projectRequestStatus={projectRequestStatus}
          deleteItem={onRemoveRequestMenuItemClick}
          openRequestHistoryDialog={openRequestHistoryDialog}
          onCreateProject={onCreateProject}
          openApproveProjectRequestConfirmationDialog={
            openApproveProjectRequestConfirmationDialog
          }
          projectRequestGates={projectRequestGates}
        />
      </Grid>
      {open && (
        <CreateProjectFromRequestDialog
          open={open}
          onClose={closeDialog}
          projectRequestName={projectRequestName}
          portfolioId={portfolioId}
          portfolioName={portfolioName}
          projectRequestId={projectRequestId}
        />
      )}
      <RemoveEntityConfirmationDialog
        resourceKeys={deleteDialogResourceKeys}
        open={showRemoveDialog}
        onClose={closeRemoveDialog}
        onConfirm={onDeleteConfirm}
      >
        <DialogContentText>
          <FormattedMessage id="projectRequest.deleteDialog.description" />
        </DialogContentText>
      </RemoveEntityConfirmationDialog>
      {requestHistoryDialogOpen && (
        <ProjectRequestHistoryDialog
          open={requestHistoryDialogOpen}
          onClose={closeRequestHistoryDialog}
          projectRequestName={projectRequestName}
          projectRequestId={projectRequestId}
        />
      )}
      {approveProjectRequestConfirmationDialogOpen && (
        <ApproveProjectRequestConfirmationDialog
          open={approveProjectRequestConfirmationDialogOpen}
          onClose={closeApproveProjectRequestConfirmationDialog}
          projectRequestId={projectRequestId}
        />
      )}
    </>
  );
};

ProjectRequestDrawerActions.propTypes = {
  onDrawerClose: PropTypes.func,
  editable: PropTypes.bool,
  projectRequestName: PropTypes.string,
  projectRequestId: PropTypes.string,
  projectRequestStatus: PropTypes.object,
  projectRequestGates: PropTypes.array,
  portfolioId: PropTypes.string,
  portfolioName: PropTypes.string
};

export default ProjectRequestDrawerActions;
