import PropTypes from 'prop-types';
import React from 'react';
import { String } from '~/modules/common/components/ListTable/renderers';
import useStyles from './useStyles';

export const StringFormatter = ({ field, record }) => {
  const { isActive } = record;
  const classes = useStyles({ isActive });

  return (
    <div className={classes.root}>
      <String field={field} record={record} />
    </div>
  );
};

StringFormatter.propTypes = {
  field: PropTypes.string,
  record: PropTypes.object
};

export default StringFormatter;
