import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonBase, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserWithRoles } from '~/modules/common/components/User';
import IconLinkButton from '~/modules/resourcing/common/components/IconLinkButton';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0)
  },
  score: {
    marginRight: theme.spacing(6)
  }
}));

const useUserWithRolesStyles = makeStyles(() => ({
  nameRow: {
    display: 'flex'
  },
  roleRow: {
    display: 'flex'
  }
}));

export const ResourceMatchListRow = ({ resourceMatch, userSlug }) => {
  const classes = useStyles();
  const userWithRolesClasses = useUserWithRolesStyles();
  const { formatMessage } = useIntl();

  const user = {
    id: resourceMatch.id,
    displayText: resourceMatch.displayText,
    roles: [
      {
        projectRole: {
          displayText: resourceMatch.role,
          name: resourceMatch.role
        },
        isPrimary: true
      }
    ]
  };

  return (
    <ListItem data-qe-id="ResourceMatchListRow" className={classes.root}>
      <div>
        <ButtonBase>
          <UserWithRoles classes={userWithRolesClasses} user={user} />
          <IconLinkButton toLink={`#/resource/${userSlug}`} />
        </ButtonBase>
      </div>
      <div className={classes.score}>
        <Typography variant="subtitle2">
          {formatMessage(
            {
              id: 'resourceMatchPopover.score'
            },
            { score: resourceMatch.score }
          )}
        </Typography>
      </div>
      <div>
        <Typography variant="subtitle2">
          {formatMessage({
            id: 'resourceMatchPopover.propose'
          })}
        </Typography>
      </div>
    </ListItem>
  );
};

ResourceMatchListRow.propTypes = {
  resourceMatch: PropTypes.object.isRequired,
  userSlug: PropTypes.string.isRequired
};

export default ResourceMatchListRow;
