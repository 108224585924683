import React, { useCallback } from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import useDeleteTaskResourceUserAllocation from './useDeleteTaskResourceUserAllocation';

const RemoveTaskAllocationDialogFooter = ({
  isLoading,
  onClose,
  onRemoveTaskAllocationSuccess,
  updateCache,
  refetchQueries
}) => {
  const {
    deleteTaskResourceUserAllocation,
    loading
  } = useDeleteTaskResourceUserAllocation({ updateCache, refetchQueries });

  const {
    task,
    resourceEstimate: { taskResourceUserAllocation }
  } = useTaskEstimateRowContext();

  const onRemoveTaskAllocationSubmit = useCallback(async () => {
    const {
      data: deleteTaskResourceUserAllocationResult
    } = await deleteTaskResourceUserAllocation({
      taskUri: task.id,
      projectUri: task.project.id,
      taskAllocationId: taskResourceUserAllocation?.id
    });

    onRemoveTaskAllocationSuccess({ deleteTaskResourceUserAllocationResult });
  }, [
    task.id,
    task.project.id,
    taskResourceUserAllocation?.id,
    deleteTaskResourceUserAllocation,
    onRemoveTaskAllocationSuccess
  ]);

  return (
    <DialogActions>
      <Button onClick={onClose} autoFocus>
        <FormattedMessage id="removeTaskAllocationDialog.cancel" />
      </Button>
      <LoadingButton
        color="secondary"
        disabled={isLoading}
        isLoading={loading}
        onClick={onRemoveTaskAllocationSubmit}
      >
        <FormattedMessage id="removeTaskAllocationDialog.removeAllocatedTime" />
      </LoadingButton>
    </DialogActions>
  );
};

RemoveTaskAllocationDialogFooter.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRemoveTaskAllocationSuccess: PropTypes.func.isRequired,
  updateCache: PropTypes.func,
  refetchQueries: PropTypes.array
};

export default RemoveTaskAllocationDialogFooter;
