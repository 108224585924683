import React from 'react';
import PropTypes from 'prop-types';
import CoManagerSelect from '~/modules/common/components/CoManagerSelect';
import { useEligibleClientManagersQuery } from '~/modules/clients/graphql';

export const ClientCoManagerSelect = ({ onChange, coManagers, clientId }) => {
  const { clientManagers: options, loading } = useEligibleClientManagersQuery({
    clientId
  });

  return (
    <CoManagerSelect
      coManagers={coManagers}
      onChange={onChange}
      options={options}
      loading={loading}
    />
  );
};

ClientCoManagerSelect.propTypes = {
  coManagers: PropTypes.array,
  onChange: PropTypes.func,
  clientId: PropTypes.string
};

export default ClientCoManagerSelect;
