import React from 'react';
import { USER_ACCESS_ROLE } from '~/modules/common/enums';
import { retryLoading } from '~/util';
import { hasPermission } from '~/modules/common/permissions';
import MyTeamBadgeIcon from './MyTeamBadgeIcon';

const allowedPermissions = [
  {
    actionUri: 'urn:replicon:timesheet-action:view-timesheet',
    dataAccessLevelUri: 'urn:replicon:timesheet-data-access-level:supervisor'
  },
  {
    actionUri: 'urn:replicon:time-off-action:view-time-off',
    dataAccessLevelUri: 'urn:replicon:time-off-data-access-level:supervisor'
  },
  {
    actionUri: 'urn:replicon:expense-action:view-expense-sheet',
    dataAccessLevelUri: 'urn:replicon:expense-data-access-level:supervisor'
  },
  {
    actionUri: 'urn:replicon:timesheet-action:approve-reject'
  },
  {
    actionUri: 'urn:replicon:time-off-action:approve-reject'
  },
  {
    actionUri: 'urn:replicon:expense-action:approve-reject'
  },
  {
    actionUri:
      'urn:replicon:time-data-workbench-action:view-time-data-workbench'
  }
];

const MyTeamPage = React.lazy(() =>
  retryLoading(() => import('./components/MyTeamPage'))
);

const myTeamRoute = ({ intl, me }) => {
  const { permissionsMap, userAccessRoles } = me;

  const isSupervisor = userAccessRoles.includes(USER_ACCESS_ROLE.SUPERVISOR);

  return {
    path: '/team',
    icon: () => <MyTeamBadgeIcon />,
    isSideBarRoute: true,
    title: () => intl.formatMessage({ id: 'routes.myTeam' }),
    component: MyTeamPage,
    hasPermission:
      isSupervisor ||
      allowedPermissions.some(({ actionUri, dataAccessLevelUri }) =>
        hasPermission(permissionsMap)({ actionUri, dataAccessLevelUri })
      ),
    showDivider: false
  };
};

export default myTeamRoute;
