import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { hasErrors } from '~/util';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import EditorPopoverFooterLayout from '../../common/components/PopoverFooterLayout';

const EditorPopoverFooter = ({ onClose, hasTaskResourceUserAllocation }) => {
  const {
    dialogProps: { openRemoveAllocationDialog }
  } = useTaskEstimateRowContext();

  const { errors, handleSubmit, isSubmitting } = useFormikContext();
  const onRemoveAllocatedTimeClick = useCallback(() => {
    openRemoveAllocationDialog();
    onClose();
  }, [onClose, openRemoveAllocationDialog]);

  return (
    <EditorPopoverFooterLayout
      onClose={onClose}
      showMoreMenu={hasTaskResourceUserAllocation}
      onMenuClick={onRemoveAllocatedTimeClick}
      submitForm={handleSubmit}
      isLoading={isSubmitting}
      isDisabled={hasErrors(errors) || isSubmitting}
    />
  );
};

EditorPopoverFooter.propTypes = {
  onClose: PropTypes.func,
  hasTaskResourceUserAllocation: PropTypes.bool.isRequired
};

export default EditorPopoverFooter;
