import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AccessDeniedIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(8)
  },
  title: {
    margin: theme.spacing(2, 0)
  },
  icon: {
    height: theme.spacing(37.5),
    width: theme.spacing(47.5)
  }
}));

export const AccessDenied = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AccessDeniedIcon className={classes.icon} />
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="resourceDrawer.accessDenied.title" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="resourceDrawer.accessDenied.details" />
      </Typography>
      <Typography variant="caption" color="textSecondary">
        <FormattedMessage id="resourceDrawer.accessDenied.caption" />
      </Typography>
    </div>
  );
};

AccessDenied.propTypes = {};

export default AccessDenied;
