import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2)
    }
  }
}));

export const OfflineTitle = ({ width }) => {
  const classes = useStyles();

  return (
    <Typography
      component="h1"
      variant={
        isWidthUp('md', width) ? 'h2' : isWidthUp('sm', width) ? 'h3' : 'h4'
      }
      className={classes.title}
    >
      <FormattedMessage id="offlinePage.title" />
    </Typography>
  );
};

OfflineTitle.propTypes = {
  width: PropTypes.string.isRequired
};

export default withWidth()(OfflineTitle);
