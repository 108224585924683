import { Grid, TextField } from '@material-ui/core';
import { connect } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GridItem } from '~/modules/common/components';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { getError } from '~/util';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const EditClientContactInfoDialogForm = ({
  formik: { handleChange, values, errors }
}) => {
  const { formatMessage } = useIntl();

  const label = useMemo(
    () => ({
      contactName: formatMessage({ id: 'client.contactName' }),
      contactEmail: formatMessage({ id: 'client.contactEmail' }),
      phone: formatMessage({ id: 'client.phone' }),
      fax: formatMessage({ id: 'client.fax' }),
      website: formatMessage({ id: 'client.website' })
    }),
    [formatMessage]
  );

  const { contactName, contactInfo } = values;

  const { email, phoneNumber, faxNumber, website } = contactInfo;

  const contactNameError = getError(errors, 'contactName');
  const emailError = getError(errors, 'contactInfo.email');
  const phoneNumberError = getError(errors, 'contactInfo.phoneNumber');
  const faxNumberError = getError(errors, 'contactInfo.faxNumber');
  const websiteError = getError(errors, 'contactInfo.website');

  const mappedErrors =
    !contactNameError &&
    !emailError &&
    !phoneNumberError &&
    !faxNumberError &&
    !websiteError;

  return (
    <>
      {mappedErrors && !isEmpty(errors) && <FormErrorAlert />}
      <Grid container>
        <GridItem rightPadding>
          <TextField
            name="contactName"
            variant="filled"
            label={label.contactName}
            inputProps={getAriaLabel(label.contactName)}
            value={contactName}
            fullWidth
            onChange={handleChange}
            autoFocus
            error={Boolean(contactNameError)}
            helperText={contactNameError}
          />
        </GridItem>
        <GridItem>
          <TextField
            name="contactInfo.email"
            variant="filled"
            label={label.contactEmail}
            inputProps={getAriaLabel(label.contactEmail)}
            value={email}
            fullWidth
            onChange={handleChange}
            error={Boolean(emailError)}
            helperText={emailError}
          />
        </GridItem>
        <GridItem rightPadding>
          <TextField
            name="contactInfo.phoneNumber"
            variant="filled"
            label={label.phone}
            inputProps={getAriaLabel(label.phone)}
            value={phoneNumber}
            fullWidth
            onChange={handleChange}
            error={Boolean(phoneNumberError)}
            helperText={phoneNumberError}
          />
        </GridItem>
        <GridItem>
          <TextField
            name="contactInfo.faxNumber"
            variant="filled"
            label={label.fax}
            inputProps={getAriaLabel(label.fax)}
            value={faxNumber}
            fullWidth
            onChange={handleChange}
            error={Boolean(faxNumberError)}
            helperText={faxNumberError}
          />
        </GridItem>
        <GridItem fullWidth>
          <TextField
            name="contactInfo.website"
            variant="filled"
            label={label.website}
            inputProps={getAriaLabel(label.website)}
            value={website}
            fullWidth
            onChange={handleChange}
            error={Boolean(websiteError)}
            helperText={websiteError}
          />
        </GridItem>
      </Grid>
    </>
  );
};

EditClientContactInfoDialogForm.propTypes = {
  formik: PropTypes.object
};

export default connect(EditClientContactInfoDialogForm);
