export const invokeProposeTopMatch = proposeTopMatch => ({
  resourceRequestUris
}) =>
  proposeTopMatch({
    variables: {
      input: {
        resourceRequestUris
      }
    },
    refetchQueries: ['resourceRequestWithTimeoff']
  });
