import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTaskEstimateRowContext } from '../../../TaskEstimateRowContext';
import { useFormState as useEditorFormState } from '../hooks';
import EditorPopoverSummaryHeader from './EditorPopoverSummaryHeader';
import EditorPopoverContent from './EditorPopoverContent';
import EditorPopoverFooter from './EditorPopoverFooter';

const ProjectManagerRoleEditorForm = ({
  onClose,
  resourceAllocation,
  otherTaskAllocationsSummary
}) => {
  const {
    resourceEstimate: {
      id: resourceEstimateId,
      resourceUser: { id: userId },
      projectRole,
      taskResourceUserAllocation,
      initialEstimatedHours
    },
    task: {
      id: taskId,
      startDate: taskStartDate,
      endDate: taskEndDate,
      estimatedCompletionHours: estimatedAtCompletionHours,
      project: { id: projectId, slug: projectSlug }
    }
  } = useTaskEstimateRowContext();

  const { initialValues, validationSchema, onSubmit } = useEditorFormState({
    taskId,
    taskStartDate,
    taskEndDate,
    userId,
    roleId: projectRole?.id,
    resourceEstimateId,
    onClose,
    projectId,
    otherTaskAllocationsSummary,
    resourceAllocation,
    initialEstimatedHours,
    taskResourceUserAllocation
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <>
        <EditorPopoverSummaryHeader
          resourceAllocation={resourceAllocation}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          projectSlug={projectSlug}
        />
        <EditorPopoverContent
          resourceAllocation={resourceAllocation}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          initialEstimatedHours={initialEstimatedHours}
          estimatedAtCompletionHours={estimatedAtCompletionHours}
          taskResourceUserAllocation={taskResourceUserAllocation}
        />
        <EditorPopoverFooter
          onClose={onClose}
          hasTaskResourceUserAllocation={Boolean(taskResourceUserAllocation)}
        />
      </>
    </Formik>
  );
};

ProjectManagerRoleEditorForm.propTypes = {
  onClose: PropTypes.func,
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object
};

export default ProjectManagerRoleEditorForm;
