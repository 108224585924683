import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { objectToUTCString } from '~/modules/common/dates/convert';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import { isValidValueOrNull, isValidProgramOrNull } from '../../validator';
import {
  UPDATE_PROJECT_BASIC_INFO_MUTATION,
  VALIDATE_PROJECT_NAME_QUERY,
  optimisticResponse
} from './graphql';

const mapValuesToProjectInput = values => {
  const retVal = {
    ...values,
    name: values.name.trim(),
    startDate: values.startDate ? objectToUTCString(values.startDate) : null,
    endDate: values.endDate ? objectToUTCString(values.endDate) : null,
    projectManagementType: values.projectTypeReference.id,
    earnedRevenueScriptId: values.earnedRevenueScript
      ? values.earnedRevenueScript.id
      : null,
    projectManagerReference: isValidValueOrNull(values.projectManagerReference),
    coManagers: (values.coManagers || []).map(isValidValueOrNull),
    program: isValidProgramOrNull(values.program),
    portfolioId: values.portfolio?.id || null,
    timeAndExpenseEntryType: values.isTimeEntryAllowed
      ? values.timeAndExpenseEntryType
      : {
          id: TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
        },
    keyValues: [
      {
        keyUri: 'replicon-psa:slack-channel',
        value: { text: values.slackChannel || null }
      }
    ],
    // omit keys
    slackChannel: undefined,
    projectTypeReference: undefined,
    earnedRevenueScript: undefined,
    portfolio: undefined
  };

  return retVal;
};

export const mapProjectLinksToInput = ({
  projectLinks = [],
  originalProjectLinks = [],
  isPsaPrpProjectLinkEnabled
}) => {
  if (!isPsaPrpProjectLinkEnabled) return { linksToAdd: [], linksToDelete: [] };

  if (
    projectLinks.length === originalProjectLinks.length &&
    !projectLinks.some(l => l.isDirty)
  )
    return { linksToAdd: [], linksToDelete: [] };

  const linksToAdd = projectLinks.filter(
    link =>
      link.targetProject &&
      !originalProjectLinks.some(originalLink => originalLink.id === link.id)
  );

  const linksToDelete = originalProjectLinks.filter(
    originalLink => !projectLinks.some(link => link.id === originalLink.id)
  );

  return {
    linksToAdd: linksToAdd.map(l => ({
      linkType: l.linkType,
      targetProjectId: l.targetProject.id
    })),
    linksToDelete: linksToDelete.map(l => ({
      id: l.id,
      linkType: l.linkType,
      targetProjectId: l.targetProject.id
    }))
  };
};

export const onSubmit = ({
  projectName,
  apolloClient,
  updateProject,
  closeDialog,
  defaultScheduleRule,
  submitFromUnsavedPrompt,
  setSubmitFromUnsavedPrompt,
  onReschedule,
  originalProjectLinks,
  isPsaPrpProjectLinkEnabled,
  isPsaEarnedRevenueEnabled
}) => async (values, { setSubmitting, setFieldError, resetForm }) => {
  const { projectLinks, ...otherValues } = values;
  const { linksToAdd, linksToDelete } = mapProjectLinksToInput({
    projectLinks,
    originalProjectLinks,
    isPsaPrpProjectLinkEnabled
  });
  const payload = mapValuesToProjectInput({
    ...otherValues,
    linksToAdd,
    linksToDelete
  });

  if (payload.name !== projectName) {
    const { data } = await apolloClient.query({
      query: VALIDATE_PROJECT_NAME_QUERY,
      variables: {
        updatedProjectName: payload.name,
        originalProjectName: projectName,
        projectOrDraftUri: payload.projectUri
      }
    });

    const validationMessage = extractValidationMessage(data);

    if (validationMessage) {
      setFieldError('name', validationMessage);
      setSubmitting(false);
      // eslint-disable-next-line max-depth
      if (submitFromUnsavedPrompt) setSubmitFromUnsavedPrompt(false);

      return;
    }
  }

  updateProject({
    variables: {
      projectInput: payload,
      isPsaEarnedRevenueEnabled,
      isPsaPrpProjectLinkEnabled
    },
    optimisticResponse: optimisticResponse({
      payload: { ...payload, portfolio: values.portfolio },
      defaultScheduleRule,
      earnedRevenue: values.earnedRevenueScript
    })
  });

  if (submitFromUnsavedPrompt) {
    setSubmitFromUnsavedPrompt(false);
    onReschedule();
  } else {
    if (isPsaPrpProjectLinkEnabled) resetForm();
    closeDialog();
  }

  setSubmitting(false);
};

export const useUpdateProjectBasicInfo = ({
  projectName,
  closeDialog,
  defaultScheduleRule,
  submitFromUnsavedPrompt,
  setSubmitFromUnsavedPrompt,
  onReschedule,
  originalProjectLinks,
  isPsaPrpProjectLinkEnabled,
  isPsaEarnedRevenueEnabled
}) => {
  const apolloClient = useApolloClient();
  const [updateProject] = useMutation(UPDATE_PROJECT_BASIC_INFO_MUTATION);

  return useCallback(
    onSubmit({
      projectName,
      closeDialog,
      apolloClient,
      updateProject,
      defaultScheduleRule,
      submitFromUnsavedPrompt,
      setSubmitFromUnsavedPrompt,
      onReschedule,
      originalProjectLinks,
      isPsaPrpProjectLinkEnabled,
      isPsaEarnedRevenueEnabled
    }),
    [
      projectName,
      closeDialog,
      apolloClient,
      updateProject,
      defaultScheduleRule,
      submitFromUnsavedPrompt,
      setSubmitFromUnsavedPrompt,
      onReschedule
    ]
  );
};

const extractValidationMessage = data =>
  ((data || {}).validateProjectName || [])
    .filter(
      notification =>
        notification.id ===
        'urn:replicon:validation-failure:project-name-duplicated'
    )
    .reduce((message, validation) => validation.displayText, null);
