import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import SubstituteUserItem from './SubstituteUserItem';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    minWidth: 400,
    minHeight: 200,
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(50)
    }
  },
  list: {
    paddingLeft: theme.spacing(1)
  },
  noneMessage: {
    padding: theme.spacing(3, 2),
    color: theme.palette.text.secondary
  }
}));

export const SubstituteUserDialogContent = ({ users, onClick }) => {
  const classes = useStyles();

  return (
    <DialogContent
      className={classes.root}
      id="entity-confirmation-dialog-descriptio"
    >
      {users.length > 0 ? (
        <List className={classes.list} disablePadding>
          {users.map(user => (
            <SubstituteUserItem key={user.id} user={user} onClick={onClick} />
          ))}
        </List>
      ) : (
        <div className={classes.noneMessage}>
          <FormattedMessage id="substituteUsersDialog.noMatchingUsers" />
        </div>
      )}
    </DialogContent>
  );
};

SubstituteUserDialogContent.propTypes = {
  users: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SubstituteUserDialogContent;
