import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.paper
  },
  noResourcesMsg: {
    alignSelf: 'flex-start',
    fontStyle: 'italic',
    padding: theme.spacing(1, 2.5)
  }
}));

export const NoResourcesMessage = () => {
  const classes = useStyles();

  return (
    <div data-qe-id="NoResourcesMessage" className={classes.root}>
      <div className={classes.noResourcesMsg}>
        <Typography>
          <FormattedMessage id="vendorResourcesListTable.noResourcesMsg" />
        </Typography>
      </div>
    </div>
  );
};

export default NoResourcesMessage;
