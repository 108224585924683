import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { makeStyles, alpha } from '@material-ui/core';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { DelaStarIcon } from '~/modules/common/components';
import { RoleName } from '~/modules/common/components/entities/role';
import {
  IconLinkButton,
  ResourceRequestStatusChip
} from '~/modules/resourcing/common/components';
import { ResourceRequestStatus } from '~/types';
import { getResourceRequestStatusStyles } from '~/modules/resourcing/common/util/status-color-util';
import ResourceRequestAllocations from '../../ResourceRequestAllocations';
import ResourceRequestResourceInfo from '../../ResourceRequestResourceInfo';

export const useStatusChipStyle = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    borderRadius: theme.spacing(2),
    maxWidth: theme.spacing(12.5)
  },
  label: {
    padding: theme.spacing(0, 1.5)
  },
  ...getResourceRequestStatusStyles(theme)
}));

const useDelaStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.Dela.lightBackground
  }
}));

const useDelaStarIconStyles = makeStyles(theme => ({
  root: {
    fill: theme.palette.Dela.darkIcon,
    margin: theme.spacing(0, 2, 0, 2)
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0.5, 1, 0, 1),
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  labelRow: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    lineHeight: `${theme.spacing(2)}px`,
    height: `${theme.spacing(2)}px`
  },
  roleName: {
    display: 'block',
    lineHeight: `${theme.spacing(5)}px`,
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    ...theme.palette.text.primary,
    flexBasis: 196
  },
  noValue: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    lineHeight: `${theme.spacing(5)}px`,
    color: theme.palette.text.secondary,
    flexBasis: 196,
    ...theme.palette.text.secondary
  },
  tooltipDetails: {
    margin: theme.spacing(0.5, 0, 0, 0)
  },
  statusColumn: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: theme.spacing(0, 0, 0, 0),
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    minWidth: 120,
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  outerColumn: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: theme.spacing(0, 0, 0, 0),
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    minWidth: 240,
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  delaColumn: {
    minWidth: 'unset',
    flexBasis: 'unset'
  },
  column: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: [[0, 0, 0, 0]],
    display: 'flex',
    textAlign: 'left',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    minWidth: 120,
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  clickableColumn: {
    cursor: 'pointer',
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.15)
    }
  },
  openButton: {
    display: 'none',
    opacity: 1,
    alignSelf: 'flex-start',
    margin: theme.spacing(-0.5, -2, 0, -1)
  }
}));

export const ResourceRequestRowDetailsContent1 = ({
  allocations,
  resourceRequest,
  showResourceAllocation,
  selectedRole,
  maxSkillLevel,
  isPsaPraaResourcingUIAndRMRole
}) => {
  const isPsaPraaResourcingUIEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPraaResourcingUIEnabled'
  });
  const classes = useStyles();
  const statusChipClasses = useStatusChipStyle();
  const delaClasses = useDelaStyles();
  const delaStarIconClasses = useDelaStarIconStyles();

  const shouldUseDelaColumn =
    isPsaPraaResourcingUIAndRMRole &&
    resourceRequest.requestStatus === ResourceRequestStatus.Submitted;
  const showAllocations = isPsaPraaResourcingUIEnabled
    ? (resourceRequest.requestStatus !== ResourceRequestStatus.Submitted ||
        !isPsaPraaResourcingUIAndRMRole) &&
      showResourceAllocation
    : showResourceAllocation;
  const showResourceRequestInfo = isPsaPraaResourcingUIEnabled
    ? (resourceRequest.requestStatus === ResourceRequestStatus.Draft ||
        (resourceRequest.requestStatus === ResourceRequestStatus.Submitted &&
          !isPsaPraaResourcingUIAndRMRole)) &&
      allocations.length === 0
    : (resourceRequest.requestStatus === ResourceRequestStatus.Draft ||
        resourceRequest.requestStatus === ResourceRequestStatus.Submitted) &&
      allocations.length === 0;

  const iconLinkButtonClasses = useMemo(
    () => ({ openButton: classes.openButton }),
    [classes.openButton]
  );

  return (
    <div
      className={classNames(classes.root, {
        [delaClasses.root]: resourceRequest.isDelaDraftResourceRequest
      })}
    >
      <div className={classes.column}>
        {selectedRole ? (
          <RoleName role={selectedRole} className={classes.roleName} />
        ) : (
          <span className={classes.noValue}>
            <FormattedMessage id="resourceRequestReadonlyItem.noRole" />
          </span>
        )}
        <div
          className={classNames(classes.outerColumn, {
            [classes.delaColumn]: shouldUseDelaColumn
          })}
        >
          {(!isPsaPraaResourcingUIEnabled || showAllocations) && (
            <div className={classes.column}>
              {showResourceRequestInfo && (
                <ResourceRequestResourceInfo
                  resourceRequest={resourceRequest}
                  maxSkillLevel={maxSkillLevel}
                />
              )}
              {showAllocations && (
                <ResourceRequestAllocations
                  allocations={allocations}
                  showResourceAllocation={showResourceAllocation}
                  requestStatus={resourceRequest.requestStatus}
                  preferredResources={resourceRequest?.preferredResources}
                />
              )}
            </div>
          )}
          <div
            className={classNames(classes.statusColumn, {
              [classes.delaColumn]: shouldUseDelaColumn
            })}
          >
            {resourceRequest.isDelaDraftResourceRequest && (
              <DelaStarIcon classes={delaStarIconClasses} />
            )}
            <ResourceRequestStatusChip
              status={resourceRequest.requestStatus}
              classes={statusChipClasses}
            />

            <div>
              <IconLinkButton
                classes={iconLinkButtonClasses}
                qeIdTag="Drawer_Icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResourceRequestRowDetailsContent1.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  allocations: PropTypes.array,
  showResourceAllocation: PropTypes.bool,
  selectedRole: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  isPsaPraaResourcingUIAndRMRole: PropTypes.bool
};

export default ResourceRequestRowDetailsContent1;
