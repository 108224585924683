import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

export const ClientPageEditContext = createContext();

export const ClientPageEditContextProvider = ({
  edit: initialEdit,
  children
}) => {
  const [edit, setEdit] = useState(initialEdit);

  useEffect(() => {
    setEdit(initialEdit);
  }, [initialEdit]);

  const toggleEdit = useCallback(() => setEdit(!edit), [edit, setEdit]);

  const value = useMemo(() => ({ edit, toggleEdit }), [edit, toggleEdit]);

  return (
    <ClientPageEditContext.Provider value={value}>
      {children}
    </ClientPageEditContext.Provider>
  );
};

ClientPageEditContextProvider.propTypes = {
  edit: PropTypes.bool,
  children: PropTypes.node
};
