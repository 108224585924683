import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, Grid, makeStyles } from '@material-ui/core';

import { DateRange, User } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  resource: {
    paddingLeft: theme.spacing(1.25),
    fontSize: theme.typography.body2.fontSize
  },
  dates: {
    paddingLeft: theme.spacing(2.25),
    overflow: 'hidden'
  }
}));

const useDateRangeStyles = makeStyles(theme => ({
  range: {
    display: 'block',
    marginTop: theme.spacing(0.625)
  },
  date: {
    fontSize: theme.typography.body2.fontSize,
    display: 'inline-block'
  },
  spacer: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const useUserStyles = makeStyles(theme => ({
  label: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const ResourceAllocationDetails = ({ allocatedUser, allocations }) => {
  const classes = useStyles();
  const dateRangeClasses = useDateRangeStyles();
  const userClasses = useUserStyles();
  const { startDate, endDate } = allocations[0];

  return (
    <Grid container spacing={0}>
      <Grid item xs={6} className={classes.resource}>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="releaseResourceRequestDialog.resource" />
        </Typography>
        <User
          user={allocatedUser?.user}
          classes={userClasses}
          disablePadding
          size="small"
        />
      </Grid>
      <Grid item xs={6} className={classes.dates}>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="releaseResourceRequestDialog.projectAllocationDates" />
        </Typography>
        <DateRange start={startDate} end={endDate} classes={dateRangeClasses} />
      </Grid>
    </Grid>
  );
};

ResourceAllocationDetails.propTypes = {
  allocations: PropTypes.array.isRequired,
  allocatedUser: PropTypes.object.isRequired
};

export default ResourceAllocationDetails;
