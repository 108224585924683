import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { PortfolioStatus } from '~/types';
import { portfolioFragment } from '../../../graphql/portfolioFragment';

export const UPDATE_STATUS = gql`
  mutation updateStatus($input: UpdateStatusInput!) {
    updateStatus(input: $input) {
      status
    }
  }
`;

export const useUpdateStatus = ({ id }) => {
  const [updateStatus] = useMutation(UPDATE_STATUS, {
    update: updateCache({ id })
  });

  return {
    updateStatus: input => {
      return updateStatus({
        variables: {
          input
        }
      });
    }
  };
};

export const updateCache = ({ id }) => (proxy, { data }) => {
  if (data && data.updateStatus) {
    const {
      updateStatus: { status }
    } = data;

    const fragmentId = `Portfolio:${id}`;

    const portfolio = proxy.readFragment({
      id: fragmentId,
      fragment: portfolioFragment
    });

    proxy.writeFragment({
      id: fragmentId,
      fragment: portfolioFragment,
      data: {
        ...portfolio,
        status,
        effectiveStatus: status
      }
    });
  }
};

const useUpdateStatusHandlers = ({ id, closeMenu }) => {
  const { updateStatus } = useUpdateStatus({ id });

  const onStatusActiveHandler = useCallback(() => {
    updateStatus({ id, status: PortfolioStatus.Active });
    closeMenu();
  }, [closeMenu, id, updateStatus]);

  const onStatusInActiveHandler = useCallback(() => {
    updateStatus({ id, status: PortfolioStatus.Inactive });
    closeMenu();
  }, [closeMenu, id, updateStatus]);

  return {
    onStatusActiveHandler,
    onStatusInActiveHandler
  };
};

export default useUpdateStatusHandlers;
