import { useState, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useDialogState } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import { uploadFile } from '~/modules/attachments/uploader';
import {
  LINK_SOURCE_URI,
  FILE_SOURCE_URI
} from '~/modules/attachments/uploader/uris';
import useGetAttachments from './useGetAttachments';
import useLinkAttachmentsToObject from './useLinkAttachmentsToObject';
import useOnFileUpload from './useOnFileUpload';

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($input: DeleteAttachmentInput!) {
    deleteAttachment2(input: $input) {
      uploadUri
    }
  }
`;

export const useAttachmentsHandlers = ({
  attachToUri,
  customMetadataFn,
  enabledSourceUris = [FILE_SOURCE_URI, LINK_SOURCE_URI]
}) => {
  const me = useMeContext();

  const {
    open: isDialogOpen,
    openDialog: onDialogOpen,
    closeDialog: onDialogClose
  } = useDialogState();
  const [isUploading, setIsUploading] = useState(false);

  const { data, isLoading, refetch } = useGetAttachments({
    objectUri: attachToUri
  });

  const doLinkAttachmentsToObject = useLinkAttachmentsToObject({
    onCompleted: refetch
  });

  const [doDeleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    onCompleted: refetch
  });

  const linkAttachmentsToObject = useCallback(
    ({ attachments }) => {
      doLinkAttachmentsToObject({
        variables: {
          objectUri: attachToUri,
          attachments
        }
      });
    },
    [attachToUri, doLinkAttachmentsToObject]
  );

  const [error, setError] = useState(null);

  const onDone = useCallback(() => {
    setIsUploading(false);
    onDialogClose();
  }, [onDialogClose]);

  const uploadFilesCallback = files =>
    uploadFile({
      servicesUrl: me.servicesUrl,
      attachToUri,
      onDone,
      setError,
      linkAttachmentsToObject,
      customMetadataFn
    })(files);

  const onFileUpload = useOnFileUpload({
    setIsUploading,
    uploadFilesCallback
  });

  const deleteAttachment = useCallback(
    ({ uploadUri, type }) => {
      doDeleteAttachment({
        variables: {
          input: {
            uploadUri,
            objectUri: attachToUri,
            type
          }
        }
      });
    },
    [attachToUri, doDeleteAttachment]
  );

  const attachments = useMemo(() => (data && data.attachments) || [], [data]);

  const [showDelaBanner, setShowDelaBanner] = useState(false);

  return {
    attachments,
    isDialogOpen,
    onDialogOpen,
    onDialogClose,
    onFileUpload,
    isUploading,
    isLoading,
    error,
    deleteAttachment,
    enabledSourceUris,
    showDelaBanner,
    setShowDelaBanner
  };
};

export default useAttachmentsHandlers;
