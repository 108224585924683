import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  PERIOD_SCALE_ENUM,
  DISPLAY_UNIT_ENUM
} from '~/modules/resourcing/common/enums';
import { DelaStarIcon } from '~/modules/common/components';
import { ResourceMatchAlternativeLink, ResourceMatchList } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 1.75),
    minWidth: 500
  },
  subHeader: {
    display: 'flex',
    marginTop: theme.spacing(1.75)
  },
  delaStarIcon: {
    fill: theme.palette.Dela.darkIcon,
    marginRight: theme.spacing(0.5)
  }
}));

const resourceMatchPopoverOrigin = {
  vertical: 'center',
  horizontal: 'left'
};

export const ResourceMatchPopover = ({
  open,
  anchorEl,
  onClose,
  scale,
  displayUnit,
  resourceRequest
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Popover
      id="resource-match-popover"
      anchorOrigin={resourceMatchPopoverOrigin}
      transformOrigin={resourceMatchPopoverOrigin}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <div className={classes.root}>
        <Typography data-qe-id="ResourceMatchPopover_Role" variant="h6">
          {resourceRequest.role?.displayText ||
            formatMessage({
              id: 'resourceMatchPopover.noRole'
            })}
        </Typography>
        <div className={classes.subHeader}>
          <DelaStarIcon
            data-qe-id="ResourceMatchPopover_DelaStarIcon"
            className={classes.delaStarIcon}
          />
          <Typography
            data-qe-id="ResourceMatchPopover_Suggestions"
            variant="subtitle1"
          >
            {formatMessage({
              id: 'resourceMatchPopover.delaTopSuggestions'
            })}
          </Typography>
        </div>
        <ResourceMatchList resourceRequest={resourceRequest} />
        <ResourceMatchAlternativeLink
          resourceRequest={resourceRequest}
          scale={scale}
          displayUnit={displayUnit}
        />
      </div>
    </Popover>
  );
};

ResourceMatchPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  scale: PropTypes.oneOf([
    PERIOD_SCALE_ENUM.DAYS,
    PERIOD_SCALE_ENUM.MONTHS,
    PERIOD_SCALE_ENUM.YEARS,
    PERIOD_SCALE_ENUM.QUARTERS,
    PERIOD_SCALE_ENUM.WEEKS,
    PERIOD_SCALE_ENUM.ENTIRE_PROJECT
  ]),
  displayUnit: PropTypes.oneOf([
    DISPLAY_UNIT_ENUM.HOURS,
    DISPLAY_UNIT_ENUM.PERCENTAGE
  ])
};

export default ResourceMatchPopover;
