import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const RESOURCE_PERMITTED_ACTIONS_QUERY = gql`
  query getResourcePermittedActionsBySlug($userSlug: String!) {
    resource(userSlug: $userSlug) {
      permittedActions
    }
  }
`;

export const hasPermittedAction = (permittedActionUris, permittedActionUri) =>
  !!(permittedActionUris && permittedActionUris.includes(permittedActionUri));

export const useResourcePermissions = userSlug => {
  const { data } = useQuery(RESOURCE_PERMITTED_ACTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userSlug
    }
  });

  const permittedActionUris = data?.resource?.permittedActions;

  return {
    hasViewProjectRolePermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-project-role'
    ),
    hasViewCostCenterPermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-cost-center'
    ),
    hasViewServiceCenterPermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-service-center'
    ),
    hasViewLocationPermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-location'
    ),
    hasViewDepartmentPermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-department'
    ),
    hasViewEmployeeTypePermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-employee-type'
    ),
    hasViewDivisionPermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-division'
    ),
    hasViewCustomFieldsPermission: hasPermittedAction(
      permittedActionUris,
      'urn:replicon:user-action:view-custom-fields'
    )
  };
};
export default useResourcePermissions;
