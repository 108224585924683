import React, { useState, useEffect } from 'react';
import { DateTime, Duration } from 'luxon';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

export const countDownInterval = ({ expiry, setRemaining }) => () => {
  setRemaining(
    expiry.diff(DateTime.local()).shiftTo('minutes', 'seconds', 'milliseconds')
  );
};

export const SessionExpiryDialog = ({
  isExpiringSoon,
  onContinue,
  onLogout,
  sessionTimeoutDuration,
  expiry
}) => (
  <Dialog
    open={isExpiringSoon}
    aria-labelledby="session-expiry-dialog-title"
    aria-describedby="session-expiry-dialog-description"
  >
    <SessionExpiryDialogContent
      onContinue={onContinue}
      onLogout={onLogout}
      sessionTimeoutDuration={sessionTimeoutDuration}
      expiry={expiry}
    />
  </Dialog>
);

SessionExpiryDialog.propTypes = {
  isExpiringSoon: PropTypes.bool,
  onContinue: PropTypes.func,
  onLogout: PropTypes.func,
  sessionTimeoutDuration: PropTypes.object,
  expiry: PropTypes.object
};

export const SessionExpiryDialogContent = ({
  onContinue,
  onLogout,
  sessionTimeoutDuration,
  expiry
}) => {
  const { formatMessage } = useIntl();
  const [remaining, setRemaining] = useState(Duration.fromMillis(0));

  useEffect(() => {
    const interval = setInterval(
      countDownInterval({ expiry, setRemaining }),
      1000 // 1 second
    );

    return () => {
      clearInterval(interval);
    };
  }, [expiry, setRemaining]);

  return (
    <>
      <DialogTitle id="session-expiry-dialog-title">
        {formatMessage({ id: 'sessionExpiry.title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="session-expiry-dialog-details">
          {formatMessage(
            { id: 'sessionExpiry.details' },
            {
              minutes: Math.max(0, remaining.minutes),
              seconds: Math.max(0, remaining.seconds)
            }
          )}
        </DialogContentText>
        <DialogContentText id="session-expiry-dialog-description">
          {formatMessage(
            { id: 'sessionExpiry.description' },
            {
              hours: sessionTimeoutDuration.hours,
              minutes: sessionTimeoutDuration.minutes
            }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogout} color="primary">
          {formatMessage({ id: 'sessionExpiry.logout' })}
        </Button>
        <Button onClick={onContinue} color="primary" autoFocus>
          {formatMessage({ id: 'sessionExpiry.continue' })}
        </Button>
      </DialogActions>
    </>
  );
};

SessionExpiryDialogContent.propTypes = {
  onContinue: PropTypes.func,
  onLogout: PropTypes.func,
  sessionTimeoutDuration: PropTypes.object,
  expiry: PropTypes.object
};

export default SessionExpiryDialog;
