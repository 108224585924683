import { Duration } from 'luxon';

export const resolveWarningPeriodInMinutes = timeout => {
  if (timeout > 12) {
    return 5;
  }

  if (timeout > 7 && timeout <= 12) {
    return 4;
  }

  return 3;
};

const useSessionWarningPeriod = ({ sessionTimeoutDuration }) => ({
  sessionWarningPeriod: Duration.fromObject({
    minutes: resolveWarningPeriodInMinutes(
      Duration.fromObject(sessionTimeoutDuration).as('minutes')
    )
  })
});

export default useSessionWarningPeriod;
