import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
// eslint-disable-next-line camelcase
import { AuditTrail_ProjectRequestStatusType } from '~/types';

export const GET_PROJECT_REQUESTS_REVISION = gql`
  query GetProjectRequestRevisions(
    $cursor: String
    $limit: Int!
    $filter: AuditTrail_ProjectRequestFilter
    $sortOrder: AuditTrail_SortOrder
  ) {
    projectRequestRevisions(
      limit: $limit
      cursor: $cursor
      filter: $filter
      sortOrder: $sortOrder
    ) {
      items {
        timestampUtc
        actualUser {
          uri
          displayText
        }
        effectiveUser {
          uri
          displayText
        }
        revisionValue {
          name {
            previous
            current
          }
          description {
            previous
            current
          }
          requestedBy {
            previous
            current
          }
          portfolio {
            previous {
              ancestors {
                displayText
              }
              displayText
            }
            current {
              ancestors {
                displayText
              }
              displayText
            }
          }
          statusType {
            previous
            current
          }
        }
      }
      cursor
    }
  }
`;

const getFormattedStatus = ({ intl, statusType }) => {
  const resourceKey = {
    [AuditTrail_ProjectRequestStatusType.New]:
      'projectRequestStatus.statusLabel.new',
    [AuditTrail_ProjectRequestStatusType.InReview]:
      'projectRequestStatus.statusLabel.inReview',
    [AuditTrail_ProjectRequestStatusType.Approved]:
      'projectRequestStatus.statusLabel.approved',
    [AuditTrail_ProjectRequestStatusType.Rejected]:
      'projectRequestStatus.statusLabel.rejected'
  }[statusType];

  return resourceKey && intl.formatMessage({ id: resourceKey });
};

const mapToStatusRevisionField = (statusTypeField, intl) => {
  const { previous, current } = statusTypeField;

  return {
    previous: previous && getFormattedStatus({ intl, statusType: previous }),
    current: current && getFormattedStatus({ intl, statusType: current })
  };
};

export const mapToRevisionData = (revisionItems, intl) => {
  return revisionItems.map(revision => {
    const revisionValue = Object.keys(revision.revisionValue).reduce(
      (retval, key) => {
        const value = revision.revisionValue[key];

        if (value?.current || value?.previous) {
          return key === 'statusType'
            ? {
                ...retval,
                status: mapToStatusRevisionField(value, intl)
              }
            : {
                ...retval,
                [key]: value
              };
        }

        return retval;
      },
      {}
    );

    const isInitialRevision = Boolean(
      !revisionValue.name?.previous && revisionValue.name?.current
    );

    return {
      actualUser: revision.actualUser,
      effectiveUser: revision.effectiveUser,
      timestamp: revision.timestampUtc,
      isInitialRevision,
      revisionValue: {
        ...revisionValue,
        status: !isInitialRevision ? revisionValue.status : undefined
      }
    };
  });
};

const getProjectRequestRevisionFilter = ({ projectRequestUri } = null) => ({
  projectRequestUris: projectRequestUri ? [projectRequestUri] : null
});

export const useProjectRequestRevisionHistory = ({
  sortOrder,
  projectRequestUri,
  limit = 100,
  cursor = null
}) => {
  const intl = useIntl();
  const inputVariables = useMemo(
    () => ({
      sortOrder: sortOrder.toLowerCase(),
      limit,
      cursor,
      filter: getProjectRequestRevisionFilter({ projectRequestUri })
    }),
    [cursor, limit, projectRequestUri, sortOrder]
  );

  const { data, loading, error } = useQuery(GET_PROJECT_REQUESTS_REVISION, {
    variables: inputVariables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !projectRequestUri
  });

  const revisionData = mapToRevisionData(
    data ? data.projectRequestRevisions.items : [],
    intl
  );

  return {
    loading,
    revisionData,
    error
  };
};
export default useProjectRequestRevisionHistory;
