import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useCallback, useState } from 'react';

const ASSIGN_PROJECTS_TO_PORTFOLIO_MUTATION = gql`
  mutation AssignProjectsToPortfolio($input: AssignProjectsToPortfolioInput) {
    assignProjectsToPortfolio(input: $input)
  }
`;

export const useAssignProjectsToPortfolio = ({
  portfolioId,
  projectIds,
  onComplete,
  onError
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [assignProjectsToPOrtfolioMutation] = useMutation(
    ASSIGN_PROJECTS_TO_PORTFOLIO_MUTATION
  );

  const assignProjectsToPortfolio = useCallback(async () => {
    setSubmitting(true);
    try {
      await assignProjectsToPOrtfolioMutation({
        variables: {
          input: {
            portfolioId,
            projectIds
          }
        },
        refetchQueries: ['getProjects', 'Eager_GetProjectsStatusCountSummary']
      });

      onComplete();
    } catch (e) {
      onError(e);
    } finally {
      setSubmitting(false);
    }
  }, [
    assignProjectsToPOrtfolioMutation,
    portfolioId,
    projectIds,
    onComplete,
    onError
  ]);

  return { submitting, assignProjectsToPortfolio };
};
