import React from 'react';
import { FormattedMessage } from 'react-intl';

export const buildColumns = ({
  isVendorResourcesListTable,
  isEditable,
  vendorResourcesStates,
  isRTL
}) => ({
  ...(isVendorResourcesListTable && {
    rowSelector: {
      id: 'rowSelector',
      vendorResourcesStates,
      value: '',
      align: isRTL ? 'right' : 'left',
      visible: isEditable
    }
  }),
  name: {
    id: 'name',
    value: <FormattedMessage id="vendorResourcesListTable.name" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  },
  employeeId: {
    id: 'employeeId',
    value: <FormattedMessage id="vendorResourcesListTable.employeeId" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  },
  startDate: {
    id: 'startDate',
    value: <FormattedMessage id="vendorResourcesListTable.startDate" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  },
  endDate: {
    id: 'endDate',
    value: <FormattedMessage id="vendorResourcesListTable.endDate" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  },
  effectiveRoles: {
    id: 'role',
    value: <FormattedMessage id="vendorResourcesListTable.role" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  },
  supervisorAssignment: {
    id: 'supervisor',
    value: <FormattedMessage id="vendorResourcesListTable.supervisor" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  },
  currentDepartment: {
    id: 'department',
    value: <FormattedMessage id="vendorResourcesListTable.department" />,
    align: isRTL ? 'right' : 'left',
    visible: true
  }
});
