import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  List,
  CircularProgress,
  DialogContent,
  Button
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import ViewResourceRequestListItem from '~/modules/resourcing/common/components/ViewResourceRequestListItem';
import useResourceRequestsSummary from './hooks/useResourceRequestsSummary';

export const useContentStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 550,
    minHeight: 350
  }
}));

const useSpinnerStyles = makeStyles(theme => ({
  progress: { position: 'absolute', left: '50%', top: '50%' },
  spinner: { color: theme.palette.text.disabled, marginRight: theme.spacing(3) }
}));

const useShowMoreButtonStyles = makeStyles(theme => ({
  showMore: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0.75, 0, 0.5, 0)
  }
}));

export const ViewMultipleResourceRequestsDialogContent = ({
  onClose,
  projectId,
  userId,
  resourceRequestListItemClickHandler
}) => {
  const contentStyles = useContentStyles();
  const loadingStyles = useSpinnerStyles();
  const buttonStyles = useShowMoreButtonStyles();

  const {
    loadingRows,
    resourceRequests,
    loadingMoreRows,
    hasMoreRows,
    loadMoreRows
  } = useResourceRequestsSummary({
    projectUri: projectId,
    userUri: userId
  });

  return (
    <DialogContent classes={contentStyles}>
      {loadingRows ? (
        <div className={loadingStyles.progress}>
          <CircularProgress size={24} className={loadingStyles.spinner} />
        </div>
      ) : (
        <>
          <Typography variant="subtitle1" noWrap>
            <FormattedMessage id="viewResourceRequestsSummaryDialog.description" />
          </Typography>
          <List>
            {resourceRequests.map((request, index) => (
              <ViewResourceRequestListItem
                key={request.id}
                request={request}
                onClose={onClose}
                onClick={resourceRequestListItemClickHandler}
                qeIdTag={`ResourceRequest_ListItem_${index}`}
              />
            ))}
            {loadingMoreRows && (
              <Skeleton variant="rect" width="100%" height={40} />
            )}
            {!loadingMoreRows && hasMoreRows && (
              <div className={buttonStyles.showMore}>
                <Button onClick={loadMoreRows}>
                  <FormattedMessage id="viewResourceRequestsSummaryDialog.showMore" />
                </Button>
              </div>
            )}
          </List>
        </>
      )}
    </DialogContent>
  );
};

ViewMultipleResourceRequestsDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  projectId: PropTypes.string,
  resourceRequestListItemClickHandler: PropTypes.func
};

export default ViewMultipleResourceRequestsDialogContent;
