import React, { useMemo, useState } from 'react';
import { Grid, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import { isoStringToFormattedISOString } from '~/modules/common/dates/convert';
import EstimateItemMetric from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/components/TaskEstimateCard/components/EstimateItemMetric';
import TaskAllocationCard from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/components/TaskAllocationCard';
import {
  ChangeTaskResourceConfirmationArea,
  ResourceUserSelectionRow
} from './components';
import { useOnChangeHandler } from './hooks';

const dateFormatString = 'LLL dd, yyyy';

const useStyles = makeStyles(theme => ({
  metricContainer: {
    paddingBottom: `${theme.spacing(2)}px !important`
  },
  root: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  role: {
    paddingTop: '0px !important'
  },
  roleLabel: {
    color: theme.palette.text.secondary
  },
  confirmationArea: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(0, 1)
  },
  infoArea: {
    marginBottom: theme.spacing(0)
  }
}));

const useEstimateMetricStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1)
  }
}));

const useFormattedHoursStyles = makeStyles(theme => ({
  noValue: {
    marginBottom: 0
  }
}));

export const useAllocationCardStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: theme.spacing(33.25)
  }
}));

export const ChangeTaskResourceDialogContent = ({ resourceEstimate, task }) => {
  const classes = useStyles();
  const estimateMetricClasses = useEstimateMetricStyles();
  const allocationCardClasses = useAllocationCardStyles();
  const formattedHoursClasses = useFormattedHoursStyles();
  const [isNewUserInfoLoading, setIsNewUserInfoLoading] = useState(false);

  const {
    values: { availableHours, newResourceUser }
  } = useFormikContext();

  const {
    initialEstimatedHours,
    projectRole,
    taskResourceUserAllocation,
    resourceUser
  } = resourceEstimate;

  const { startDate, endDate } = taskResourceUserAllocation || {};

  const taskAllocationDateRange = useMemo(
    () => ({
      startDate: isoStringToFormattedISOString(startDate, dateFormatString),
      endDate: isoStringToFormattedISOString(endDate, dateFormatString)
    }),
    [endDate, startDate]
  );

  const { onResourceUserChange } = useOnChangeHandler({
    projectId: task.projectReference.id,
    taskResourceUserAllocation,
    initialEstimatedHours,
    setIsNewUserInfoLoading
  });

  const { displayText: projectRoleDisplayText } = projectRole || {};

  return (
    <DialogContent className={classes.root}>
      <Grid container className={classes.infoArea}>
        <Grid item className={classes.role}>
          <Typography>
            <span className={classes.roleLabel}>
              <FormattedMessage id="changeTaskResourceDialog.role" />
            </span>
            <span
              className={classNames({
                [classes.roleLabel]: !projectRoleDisplayText
              })}
            >
              {projectRoleDisplayText || (
                <FormattedMessage id="changeTaskResourceDialog.noRole" />
              )}
            </span>
          </Typography>
        </Grid>
        <Grid container item spacing={2} className={classes.metricContainer}>
          <Grid item md={6}>
            <EstimateItemMetric
              formatMessageKey="changeTaskResourceDialog.estimate"
              hours={initialEstimatedHours}
              classes={estimateMetricClasses}
              formattedHoursClasses={formattedHoursClasses}
            />
          </Grid>
          <Grid item md={6}>
            <TaskAllocationCard
              isReadOnlyView
              classes={allocationCardClasses}
              isOverAllocation
            />
          </Grid>
        </Grid>
        <ResourceUserSelectionRow
          existingResourceUser={resourceUser}
          taskResourceUserAllocation={taskResourceUserAllocation}
          onResourceUserChange={onResourceUserChange}
          newResourceUser={newResourceUser}
          task={task}
          role={projectRole}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
      <Grid item container className={classes.confirmationArea}>
        {isNewUserInfoLoading ? (
          <Skeleton height={110} width="100%" />
        ) : (
          newResourceUser && (
            <ChangeTaskResourceConfirmationArea
              existingResource={resourceUser}
              selectedResource={newResourceUser}
              taskResourceUserAllocation={taskResourceUserAllocation}
              taskAllocationDateRange={taskAllocationDateRange}
              availableHours={availableHours}
            />
          )
        )}
      </Grid>
    </DialogContent>
  );
};

ChangeTaskResourceDialogContent.propTypes = {
  resourceEstimate: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

export default ChangeTaskResourceDialogContent;
