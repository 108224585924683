import { useDialogState } from '~/modules/common/hooks';

export const useDialogStates = () => {
  const {
    open: basicDialogOpen,
    openDialog: openBasicDialog,
    closeDialog: closeBasicDialog
  } = useDialogState();

  const {
    open: contactDialogOpen,
    openDialog: openContactDialog,
    closeDialog: closeContactDialog
  } = useDialogState();

  const {
    open: addressDialogOpen,
    openDialog: openAddressDialog,
    closeDialog: closeAddressDialog
  } = useDialogState();

  const {
    open: additionalInfoDialogOpen,
    openDialog: openAdditionalInfoDialog,
    closeDialog: closeAdditionalInfoDialog
  } = useDialogState();

  const {
    open: clientRepDialogOpen,
    openDialog: openClientRepDialog,
    closeDialog: closeClientRepDialog
  } = useDialogState();

  const {
    open: billingDialogOpen,
    openDialog: openBillingDialog,
    closeDialog: closeBillingDialog
  } = useDialogState();

  return {
    basicDialogOpen,
    openBasicDialog,
    closeBasicDialog,
    contactDialogOpen,
    openContactDialog,
    closeContactDialog,
    addressDialogOpen,
    openAddressDialog,
    closeAddressDialog,
    additionalInfoDialogOpen,
    openAdditionalInfoDialog,
    closeAdditionalInfoDialog,
    clientRepDialogOpen,
    openClientRepDialog,
    closeClientRepDialog,
    billingDialogOpen,
    openBillingDialog,
    closeBillingDialog
  };
};
