import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { RequestActions } from '~/modules/resourcing/common/components';
import ViewMultipleResourceRequestsDialog from '~/modules/resourcing/common/components/ViewMultipleResourceRequestsDialog';
import {
  QaReleaseAllocationsDialog,
  useBulkReleaseResourceAllocations
} from '../QaReleaseAllocationsDialog';
import { ResourceRequestDrawer } from '../ResourceRequestDrawer';
import { useQuickAllocationProjectRowActions } from '../../hooks';

const useStyles = makeStyles(theme => ({
  rowActions: {
    marginTop: theme.spacing(1)
  }
}));

export const QuickAllocationActions = ({
  resourceAllocations,
  allocatedProject,
  resourceUser,
  handleRemoveResourceAllocation,
  setSnackBarState,
  showResourceActions
}) => {
  const classes = useStyles();

  const resourceRequestIds = resourceAllocations
    .map(alloc => alloc.resourceRequestId)
    .filter(Boolean);

  const {
    actions,
    showReleaseResourcesDialog,
    closeReleaseResourcesDialog,
    showViewMultipleResourceRequestsDialog,
    onViewMultipleResourceRequestsDialogClose,
    selectedResourceRequestId,
    isDrawerOpen,
    onDrawerClose,
    onDrawerOpen
  } = useQuickAllocationProjectRowActions({
    resourceRequestIds,
    projectSlug: allocatedProject.project.slug,
    showResourceActions
  });

  const canEditTask = resourceAllocations.some(allocation =>
    allocation.project.permittedActionUris.includes(
      'urn:replicon:project-action:edit-tasks'
    )
  );

  const {
    loading: releaseAllocationLoading,
    releaseResourceAllocation
  } = useBulkReleaseResourceAllocations({
    allocatedProject,
    handleRemoveResourceAllocation,
    resourceAllocations,
    userName: resourceUser.displayText,
    setSnackBarState
  });

  return (
    <div className={classes.rowActions}>
      <RequestActions
        actions={actions}
        primaryActionPlaceHolder={false}
        qeIdTag={allocatedProject.project.displayText}
      />
      {showReleaseResourcesDialog && (
        <QaReleaseAllocationsDialog
          allocatedProject={allocatedProject}
          canEditTask={canEditTask}
          onCancelClick={closeReleaseResourcesDialog}
          onReleaseResourceAllocation={releaseResourceAllocation}
          open={showReleaseResourcesDialog}
          releaseAllocationLoading={releaseAllocationLoading}
          user={resourceUser}
        />
      )}
      {isDrawerOpen && (
        <ResourceRequestDrawer
          resourceRequestId={selectedResourceRequestId}
          onClose={onDrawerClose}
          open={isDrawerOpen}
        />
      )}
      {showViewMultipleResourceRequestsDialog && (
        <ViewMultipleResourceRequestsDialog
          open={showViewMultipleResourceRequestsDialog}
          onClose={onViewMultipleResourceRequestsDialogClose}
          projectId={allocatedProject?.project.id}
          user={resourceUser}
          resourceRequestListItemClickHandler={onDrawerOpen}
        />
      )}
    </div>
  );
};

QuickAllocationActions.propTypes = {
  allocatedProject: PropTypes.object,
  handleRemoveResourceAllocation: PropTypes.func,
  resourceAllocations: PropTypes.array,
  resourceUser: PropTypes.object,
  setSnackBarState: PropTypes.func,
  showResourceActions: PropTypes.bool
};

export default QuickAllocationActions;
