import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  allocationTotalsFragment,
  specificResourceAllocationFragment,
  mergedResourceRequestsFragment
} from '~/modules/resourcing/common/fragments';

import { SpecificResourceRequestFragmentDoc } from '~/types';

export const RESOURCE_REQUEST_QUERY = gql`
  query getResourceRequest(
    $id: String!
    $includeMergeResourceRequests: Boolean = false
  ) {
    resourceRequest(id: $id) {
      ...SpecificResourceRequest
      project {
        id
        slug
        startDate
        endDate
        name
        displayText
        permittedActionUris
        defaultScheduleRule {
          dateRange {
            startDate
            endDate
          }
          do
        }
      }
      mergedResourceRequests @include(if: $includeMergeResourceRequests) {
        ...MergedResourceRequestsFragment
      }
      resourceAllocations {
        ...SpecificResourceAllocation
        ...AllocationTotalsFragment
      }
    }
  }
  ${SpecificResourceRequestFragmentDoc}
  ${mergedResourceRequestsFragment}
  ${specificResourceAllocationFragment}
  ${allocationTotalsFragment}
`;

const useResourceRequest = ({
  resourceRequestId,
  skip = false,
  includeMergeResourceRequests = false
}) => {
  const { loading, error, data } = useQuery(RESOURCE_REQUEST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: resourceRequestId,
      includeMergeResourceRequests
    },
    errorPolicy: 'all',
    skip: Boolean(!resourceRequestId) || skip
  });

  return {
    isLoading: loading,
    resourceRequest: data && data.resourceRequest,
    error
  };
};

export default useResourceRequest;
