import { useEffect } from 'react';
import { useRecordUserAccess } from '~/modules/common/hooks/useRecordUserAccess';
import { UserAccessType } from '~/types';

export const useRecordUserLoginAccess = () => {
  const [recordUserAccess] = useRecordUserAccess();
  const urlParams = new URLSearchParams(window.location.search);
  const isLandingPage = urlParams.get('isLandingPage');

  useEffect(() => {
    if (isLandingPage) {
      recordUserAccess({
        variables: {
          accessInput: { accessType: UserAccessType.Login }
        }
      });
    }
  }, [isLandingPage, recordUserAccess]);
};
