import React from 'react';
import { useRouteMatch, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import SettingsButton from '~/modules/common/components/ListTableToolBar/SettingsButton';
import { TabBar, TabContents } from '~/modules/common/components';
import {
  useIsDefaultEdit,
  useIsBreakpointDown,
  useDialogState
} from '~/modules/common/hooks';
import { useClientPermissions } from '~/modules/clients/hooks';
import { ColumnSettingsDialog } from '~/modules/common/components/ListTable';
import { useMeContext } from '~/modules/me';
import StickyHeader from '~/modules/common/components/StickyHeader/StickyHeader';
import { notAuthorized, slugNotFound } from '~/modules/common/nonOptimalStates';
import { RedirectToNotAuthorized } from '~/modules/common/notAuthorized';
import { RedirectToNotFound } from '~/modules/common/notfound';
import { ClientBySlugDocument } from '~/types';
import { clientProjectsTabPath } from '~/modules/clients/client/ClientProjects/route';
import { ClientContextProvider } from './context';
import { ClientPageEditContextProvider } from './ClientPageEditContext';
import ClientPageHeader from './ClientPageHeader';
import { clientRoutes } from './clientRoutes';
import { useClientProjectListTableSettings } from './hooks';

const useStyles = makeStyles(() => ({
  clientSettingsButton: {
    marginLeft: 'auto'
  }
}));

export const ClientPageContents = () => {
  const { canEditClient } = useClientPermissions();
  const isMobile = useIsBreakpointDown('sm');
  const me = useMeContext();
  const classes = useStyles();
  const {
    params: { slug }
  } = useRouteMatch();

  const { open, closeDialog, openDialog } = useDialogState();
  const isDefaultEdit = useIsDefaultEdit();
  const edit = isDefaultEdit && canEditClient;

  const clientTabRoutes = clientRoutes({ me, isMobile });
  const { pathname } = useLocation();

  const isProjectsTabSelected = matchPath(pathname, clientProjectsTabPath);

  const {
    tableKey,
    enabledProjectColumnGroups,
    enabledProjectColumnNames,
    selectedColumnNames,
    tableSettingsDataLoading
  } = useClientProjectListTableSettings();

  return (
    <ClientPageEditContextProvider edit={edit}>
      <ClientPageHeader slug={slug} me={me} />
      <StickyHeader level={1} bottomBorder>
        <TabBar
          tabRoutes={clientTabRoutes}
          variant="scrollable"
          scrollButtons="off"
          noBottomBorder
        />
        {isProjectsTabSelected && !isMobile && (
          <span className={classes.clientSettingsButton}>
            <SettingsButton
              data-qe-id="ClientProjectList"
              onClick={openDialog}
            />
          </span>
        )}
      </StickyHeader>
      <TabContents tabRoutes={clientTabRoutes} slug={slug} />
      {!tableSettingsDataLoading && selectedColumnNames && (
        <ColumnSettingsDialog
          tableKey={tableKey}
          groups={enabledProjectColumnGroups}
          selectedColumns={selectedColumnNames}
          settingsColumnJustify="flex-start"
          defaultColumns={enabledProjectColumnNames}
          open={open}
          onClose={closeDialog}
          tagsObjectBinding="PROJECT"
        />
      )}
    </ClientPageEditContextProvider>
  );
};

export const ClientPage = () => {
  const {
    params: { slug }
  } = useRouteMatch();

  const { loading, data = {}, error } = useQuery(ClientBySlugDocument, {
    variables: {
      slug
    }
  });

  if (error)
    return (
      (slugNotFound({ data: { error } }) && <RedirectToNotFound />) ||
      (notAuthorized({ data: { error } }) && <RedirectToNotAuthorized />)
    );

  return (
    <ClientContextProvider data={data} loading={loading}>
      <ClientPageContents />
    </ClientContextProvider>
  );
};

export default ClientPage;
