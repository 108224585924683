import deepEqual from 'fast-deep-equal';

export const isBillingSameAsAddress = ({ contactInfo, billingContactInfo }) => {
  const { address, city, stateProvince, zipPostalCode, country } = contactInfo;

  const {
    address: billingAddress,
    city: billingCity,
    stateProvince: billingStateProvince,
    zipPostalCode: billingZipPostalCode,
    country: billingCountry
  } = billingContactInfo;

  return deepEqual(
    {
      address,
      city,
      stateProvince,
      zipPostalCode,
      country
    },
    {
      address: billingAddress,
      city: billingCity,
      stateProvince: billingStateProvince,
      zipPostalCode: billingZipPostalCode,
      country: billingCountry
    }
  );
};
