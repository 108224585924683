import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: '0px'
      }
    }
  },
  to: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    backgroundColor: `rgba(0, 0, 0, 0.09)`,
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    padding: theme.spacing(0, 1)
  },
  toDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomStyle: 'dotted !important'
  },
  leftDropDown: {
    borderTopRightRadius: 'unset'
  },
  rightDropDown: {
    borderTopLeftRadius: 'unset'
  },
  card: {
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: 'unset'
  },
  link: {
    display: 'flex',
    flexGrow: '1',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  search: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1)
    }
  }
}));

export const useHeaderStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(0.5, 1.5) },
  title: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase'
  }
}));

export const useContentStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(0.5, 1.5) }
}));

export const useDialogStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold
  },
  content: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line'
  },
  highlighted: {
    color: theme.palette.text.primary
  }
}));
