import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const putNotificationPoliciesQuery = gql`
  mutation PutNotificationPolicies(
    $notificationPolicies: [NotificationPolicyInput2!]
  ) {
    putNotificationPolicies: putNotificationPolicies3(
      notificationPolicies: $notificationPolicies
    ) {
      notificationPolicies {
        id
        userUri
        eventType
        config {
          isWebNotificationEnabled
          isEmailNotificationEnabled
          isPushNotificationEnabled
        }
      }
    }
  }
`;

const NOTIFICATION_EVENT_TYPE_RESULT = {
  'resource-request.modification.submitted': 'RESOURCE_REQUEST_SUBMITTED',
  'resource-request.modification.to-be-hired': 'RESOURCE_REQUEST_TO_BE_HIRED',
  'resource-request.modification.rejected': 'RESOURCE_REQUEST_REJECTED',
  'resource-allocation.modification.proposed': 'RESOURCE_ALLOCATION_PROPOSED',
  'resource-allocation.modification.accepted': 'RESOURCE_ALLOCATION_ACCEPTED',
  'resource-allocation.modification.rejected': 'RESOURCE_ALLOCATION_REJECTED',
  'resource-allocation.modification.released': 'RESOURCE_ALLOCATION_RELEASED',
  'task-assignment.modification.assigned': 'TASK_ASSIGNMENT_ASSIGNED',
  'task.modification.status-changed': 'TASK_STATUS_CHANGED',
  'project.modification.project-stage-changed': 'PROJECT_STAGE_CHANGED'
};

const usePutNotificationPoliciesOnSubmit = ({ closeDialog }) => {
  const [error, setError] = useState();

  const clearError = useCallback(() => setError(null), [setError]);

  const [putNotificationPolicies] = useMutation(putNotificationPoliciesQuery);

  const onSubmit = useCallback(
    async (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);

      try {
        putNotificationPolicies({
          variables: {
            notificationPolicies: values.notificationPolicies.map(np => ({
              id: np.id,
              userUri: np.userUri,
              eventType: NOTIFICATION_EVENT_TYPE_RESULT[np.eventType],
              config: {
                isWebNotificationEnabled: np.config.isWebNotificationEnabled,
                isEmailNotificationEnabled:
                  np.config.isEmailNotificationEnabled,
                isPushNotificationEnabled: np.config.isPushNotificationEnabled
              }
            }))
          }
        });
      } finally {
        closeDialog();
        setSubmitting(false);
      }
    },
    [closeDialog, putNotificationPolicies]
  );

  return { onSubmit, error, clearError };
};

export default usePutNotificationPoliciesOnSubmit;
