import React, { useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { useIntl } from 'react-intl';
import EditorPopoverTooltip from './EditorPopoverTooltip';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    cursor: 'help',
    color: theme.palette.text.secondary
  }
}));

const AvailabilityInfoComponent = ({
  startDate,
  endDate,
  otherTaskAllocationsSummaryScheduleRules,
  projectAvailableHours,
  resourceAllocationScheduleRules
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const tooltipTitle = useMemo(
    () => (
      <EditorPopoverTooltip
        endDate={endDate}
        otherTaskAllocationsSummaryScheduleRules={
          otherTaskAllocationsSummaryScheduleRules
        }
        projectAvailableHours={projectAvailableHours}
        resourceAllocationScheduleRules={resourceAllocationScheduleRules}
        startDate={startDate}
      />
    ),
    [
      endDate,
      otherTaskAllocationsSummaryScheduleRules,
      projectAvailableHours,
      resourceAllocationScheduleRules,
      startDate
    ]
  );

  return (
    <Tooltip title={tooltipTitle} tabIndex={0} placement="right">
      <InfoIcon
        aria-label={formatMessage({ id: 'taskAllocationEditor.details' })}
        className={classes.infoIcon}
        fontSize="small"
      />
    </Tooltip>
  );
};

AvailabilityInfoComponent.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  otherTaskAllocationsSummaryScheduleRules: PropTypes.array,
  projectAvailableHours: PropTypes.number.isRequired,
  resourceAllocationScheduleRules: PropTypes.array.isRequired
};

export default AvailabilityInfoComponent;
