import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import DialogButtonActions from '~/modules/common/components/DialogButtonActions';
import EditPortfolioBudgetDialogContent from './EditPortfolioBudgetDialogContent';
import { usePutPortfolioBudgetForm } from './hooks';

const savePortfolioLabel = <FormattedMessage id="button.save" />;

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dialogPaper: {
    maxWidth: '680px'
  }
}));

export const EditPortfolioBudgetDialog = ({
  open,
  onClose,
  budget,
  portfolioCurrency,
  portfolioId,
  canViewPlanningData
}) => {
  const classes = useStyles();
  const isMobile = useIsBreakpointDown('sm');

  const {
    onSubmit,
    initialState,
    validationSchema
  } = usePutPortfolioBudgetForm({
    onClose,
    budget,
    portfolioCurrency,
    portfolioId,
    canViewPlanningData
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Dialog
        classes={{
          paper: classes.dialogPaper
        }}
        open={open}
        onClose={onClose}
        fullWidth
        fullScreen={isMobile}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <DescriptionIcon className={classes.icon} />
          <Typography variant="h6">
            <FormattedMessage id="portfolio.budget.title" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <EditPortfolioBudgetDialogContent portfolioId={portfolioId} />
        </DialogContent>
        <DialogButtonActions
          onClose={onClose}
          primaryButtonMessage={savePortfolioLabel}
        />
      </Dialog>
    </Formik>
  );
};

EditPortfolioBudgetDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  budget: PropTypes.object,
  portfolioCurrency: PropTypes.object,
  portfolioId: PropTypes.string,
  canViewPlanningData: PropTypes.bool
};

export default EditPortfolioBudgetDialog;
