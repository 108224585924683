import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormikContext } from 'formik';
import { ExpenseEntryType } from '~/types';
import { NumberTextField } from '~/modules/common/components';

export const MarkupEditor = ({ field, index, record }) => {
  const { formatMessage } = useIntl();
  const markup = useMemo(
    () =>
      record[field] ? parseFloat((record[field] * 100 - 100).toFixed(2)) : null,
    [record, field]
  );
  const { setFieldValue } = useFormikContext();

  const onChange = useCallback(
    newPercentage => {
      setFieldValue(
        `allowedExpensesAndEstimates[${index}].markUp`,
        (newPercentage + 100) / 100
      );
    },
    [index, setFieldValue]
  );

  const endAdornment = useMemo(
    () => (
      <InputAdornment position="start">
        <span>%</span>
      </InputAdornment>
    ),
    []
  );

  if (record.billableType === ExpenseEntryType.NonBillable) return null;

  return (
    <NumberTextField
      align="left"
      variant="standard"
      endAdornment={endAdornment}
      ariaLabel={formatMessage({
        id: 'allowedExpensesAndEstimates.columns.markUp'
      })}
      min={0}
      max={999.99}
      onChange={onChange}
      step="any"
      precision={2}
      value={markup}
    />
  );
};

MarkupEditor.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired
};

export default MarkupEditor;
