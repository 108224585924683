import React, { useMemo, useRef } from 'react';
import { InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { DateField, NumberTextField } from '~/modules/common/components';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import { PeriodResolutionDropdown } from './components/PeriodResolutionDropdown';
import { useOnChangeHandlers } from './hooks/useOnChangeHandlers';

const useStyles = makeStyles(theme => ({
  timeline: {
    display: 'flex',
    width: theme.spacing(72.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  startDateField: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(34),
    [theme.breakpoints.down('sm')]: {
      flexBasis: `calc(52% - ${theme.spacing(1)}px)`
    }
  },
  periodQuantityField: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: `calc(15% + ${theme.spacing(1)}px)`
  },
  dateFieldStartAdornment: {
    marginRight: theme.spacing(3.5),
    lineHeight: 1.43
  },
  periodQuantityFieldStartAdornment: {
    lineHeight: 1.43,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5)
    }
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: '500'
  }
}));

const usePeriodQuantityFieldInputStyles = makeStyles(theme => ({
  root: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.25)
  }
}));

const useStartDateFieldInputStyles = makeStyles(theme => ({
  input: {
    borderTopRightRadius: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.25)
    }
  }
}));

const views = ['year', 'month', 'date'];

export const Timeline = () => {
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const periodQuantityFieldInputClasses = usePeriodQuantityFieldInputStyles();
  const startDateFieldInputClasses = useStartDateFieldInputStyles();
  const intl = useIntl();
  const periodQuantityInputRef = useRef(null);
  const { setFieldValue, values } = useFormikContext();
  const { startDate, periodQuantity, periodResolution } = values;

  const dateFieldStartAdornment = useMemo(
    () => (
      <InputAdornment
        position="start"
        className={classes.dateFieldStartAdornment}
      >
        <FormattedMessage id="projectRequest.starting" />
      </InputAdornment>
    ),
    [classes.dateFieldStartAdornment]
  );

  const periodQuantityFieldStartAdornment = useMemo(
    () => (
      <InputAdornment
        position="start"
        className={classes.periodQuantityFieldStartAdornment}
      >
        <FormattedMessage id="projectRequest.for" />
      </InputAdornment>
    ),
    [classes.periodQuantityFieldStartAdornment]
  );
  const { canEditPlanningData } = useProjectRequestObjectPermissions();

  const {
    onStartDateChangeHandler,
    onPeriodQuantityChangeHandler,
    onPeriodQuantityBlurHandler,
    onPeriodResolutionChangeHandler
  } = useOnChangeHandlers({
    setFieldValue,
    values,
    periodQuantityInputRef
  });

  const periodQuantityFieldAdditionalInputProps = useMemo(
    () => ({
      classes: periodQuantityFieldInputClasses
    }),
    [periodQuantityFieldInputClasses]
  );

  const inputLabelProps = useMemo(() => ({ shrink: true }), []);
  const openTo = startDate ? 'date' : 'month';

  return (
    <>
      <Typography className={classes.title}>
        <FormattedMessage id="projectRequest.schedule" />
      </Typography>
      <div className={classes.timeline}>
        <div className={classes.startDateField}>
          <DateField
            variant="filled"
            placeholder={intl.formatMessage({
              id: 'projectRequest.selectStartDate'
            })}
            value={startDate}
            onChange={onStartDateChangeHandler}
            startAdornment={isMobile ? null : dateFieldStartAdornment}
            label={
              isMobile
                ? intl.formatMessage({
                    id: 'projectRequest.starting'
                  })
                : null
            }
            editable
            views={views}
            openTo={openTo}
            classes={startDateFieldInputClasses}
            onAccept={null}
            disabled={!canEditPlanningData}
            InputLabelProps={inputLabelProps}
            hiddenLabel={!isMobile}
            dataQeId="TimelineStartDate"
          />
        </div>
        <NumberTextField
          variant="filled"
          required
          fullWidth
          startAdornment={periodQuantityFieldStartAdornment}
          min={1}
          max={50}
          step={1}
          onChange={onPeriodQuantityChangeHandler}
          onBlur={onPeriodQuantityBlurHandler}
          value={periodQuantity}
          className={classes.periodQuantityField}
          additionalInputProps={periodQuantityFieldAdditionalInputProps}
          disabled={!canEditPlanningData}
          data-qe-id="TimelinePeriodQuantity"
          inputRef={periodQuantityInputRef}
          hiddenLabel
          ariaLabel={intl.formatMessage({ id: 'projectRequest.period' })}
          clearable
        />
        <PeriodResolutionDropdown
          periodResolution={periodResolution}
          onPeriodResolutionChangeHandler={onPeriodResolutionChangeHandler}
          disabled={!canEditPlanningData}
          periodQuantity={periodQuantity || 1}
          dataQeId="TimelinePeriodResolution"
        />
      </div>
    </>
  );
};

export default Timeline;
