export const supportedPolicies = {
  'resource-request.modification.submitted': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'resource-request.modification.to-be-hired': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'resource-request.modification.rejected': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'resource-allocation.modification.proposed': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'resource-allocation.modification.accepted': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'resource-allocation.modification.rejected': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'resource-allocation.modification.released': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'task-assignment.modification.assigned': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'task.modification.status-changed': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  },
  'project.modification.project-stage-changed': {
    config: {
      isWebNotificationSupported: true,
      isEmailNotificationSupported: true,
      isPushNotificationSupported: true
    }
  }
};
