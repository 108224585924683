import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  header: {
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    color: 'rgba(0,0,0,0.66)'
  }
});

export const buildColumnHeaders = ({ classes }) => ({
  DATE: {
    column: 'date',
    fixed: true,
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.Date" />
      </span>
    ),
    align: 'left',
    includesNavigationComponent: true
  },
  TOTAL_HOURS_WORKED_TO_DATE: {
    column: 'totalHoursWorkedToDate',
    fixed: true,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.Total Work Done" />
      </span>
    )
  },
  ESTIMATED_REMAINING_HOURS: {
    column: 'estimatedRemainingHours',
    fixed: true,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.Work Remaining" />
      </span>
    )
  },
  ESTIMATED_HOURS_AT_COMPLETION: {
    column: 'estimatedHoursAtCompletion',
    fixed: true,
    align: 'right',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.Estimated Hours At Completion" />
      </span>
    )
  },
  ESTIMATED_COMPLETION_DATE: {
    column: 'estimatedCompletionDate',
    fixed: true,
    align: 'left',
    value: (
      <span className={classes.header}>
        <FormattedMessage id="taskDrawer.Estimated Completion Date" />
      </span>
    )
  }
});

export const useListHeaders = ({ selectedColumns }) => {
  const classes = useStyles();
  const columnHeaders = buildColumnHeaders({ classes });
  const selectedColumnHeaders = selectedColumns.map(
    column => columnHeaders[column]
  );

  return [
    {
      id: 'header',
      columns: selectedColumnHeaders
    }
  ];
};
