import { get } from 'lodash';
import { useMemo, useCallback } from 'react';
import { ExpenseEntryType } from '~/types';
import {
  mapActualsSeriesByExpenseType,
  mapEstimatedSeriesByExpenseType,
  mapActualsSummaryByExpenseType,
  mapEstimatedSummaryByExpenseType,
  mergeNestedObjectValues,
  mapToEstimatedExpensesEntries,
  mapToExpenseScripts,
  getTotalForEstimatedExpense
} from './utils';
import { wellKnownScriptParameters } from './const';

export const useInitialValues = ({
  estimatedExpensesSeries,
  estimatedExpensesSummary,
  expenseActualsSeries,
  expenseActualsSummary,
  expenseCodes,
  projectCurrency,
  scriptDetails,
  showActuals
}) => {
  const scriptByExpenseType = useMemo(
    () =>
      scriptDetails.scripts.reduce(
        (retVal, script) => ({
          ...retVal,
          [script[wellKnownScriptParameters.expenseType].id]: script
        }),
        {}
      ),
    [scriptDetails.scripts]
  );

  const approvalStatus = useMemo(
    () =>
      get(
        scriptDetails,
        `scripts[0].${wellKnownScriptParameters.approvalStatus}`,
        'Any'
      ),
    [scriptDetails]
  );

  const estimatedExpensesSeriesByExpenseType = useMemo(
    () => mapEstimatedSeriesByExpenseType(estimatedExpensesSeries || []),
    [estimatedExpensesSeries]
  );

  const expenseActualsSeriesByExpenseType = useMemo(
    () => mapActualsSeriesByExpenseType(expenseActualsSeries || []),
    [expenseActualsSeries]
  );

  const estimatedExpensesSummaryByExpenseType = useMemo(
    () => mapEstimatedSummaryByExpenseType(estimatedExpensesSummary || []),
    [estimatedExpensesSummary]
  );

  const expenseActualsSummaryByExpenseType = useMemo(
    () => mapActualsSummaryByExpenseType(expenseActualsSummary || []),
    [expenseActualsSummary]
  );

  const getEstimateAmount = useCallback(
    (expenseTypeId, expenseEntryType) => {
      const amount = getTotalForEstimatedExpense(
        get(
          estimatedExpensesSummaryByExpenseType,
          `${expenseTypeId}.totalEstimates`,
          {}
        ),
        expenseEntryType
      );

      return amount ? { amount, currency: projectCurrency } : null;
    },
    [estimatedExpensesSummaryByExpenseType, projectCurrency]
  );

  return useMemo(
    () => ({
      edit: Boolean(scriptDetails.isAddMode),
      currency: projectCurrency,
      showActuals,
      availableExpenseCodes: expenseCodes
        .filter(
          ({ isExpenseEntryToThisCodeAllowed }) =>
            !isExpenseEntryToThisCodeAllowed
        )
        .map(({ id, displayText }) => ({ id, displayText })),
      allowedExpensesAndEstimates: expenseCodes
        .filter(
          ({ isExpenseEntryToThisCodeAllowed }) =>
            isExpenseEntryToThisCodeAllowed
        )
        .map(({ id, displayText, expenseEntryType }) => ({
          id,
          scriptId: get(scriptByExpenseType, `${id}.scriptId`),
          expenseType: { id, displayText },
          markUp: get(
            scriptByExpenseType,
            `${id}.${wellKnownScriptParameters.markUp}`,
            1
          ),
          billableType: expenseEntryType || ExpenseEntryType.NonBillable,
          estimatedBilling: get(scriptByExpenseType, `${id}.estimatedAmount`),
          estimate: getEstimateAmount(id, expenseEntryType),
          ...mergeNestedObjectValues(
            get(estimatedExpensesSeriesByExpenseType, `${id}`, {}),
            get(expenseActualsSeriesByExpenseType, `${id}`, {})
          ),
          ...mergeNestedObjectValues(
            get(estimatedExpensesSummaryByExpenseType, `${id}`, {}),
            get(expenseActualsSummaryByExpenseType, `${id}`, {})
          )
        })),
      approvalStatus
    }),
    [
      approvalStatus,
      estimatedExpensesSeriesByExpenseType,
      estimatedExpensesSummaryByExpenseType,
      expenseActualsSeriesByExpenseType,
      expenseActualsSummaryByExpenseType,
      expenseCodes,
      getEstimateAmount,
      projectCurrency,
      scriptByExpenseType,
      scriptDetails.isAddMode,
      showActuals
    ]
  );
};

export const useOnSubmit = ({
  bulkPutEstimatedExpenses,
  isBillingContractType,
  onBillPlanSave,
  planId,
  projectEndDate,
  projectId,
  projectPermissions,
  putExpenseCardSettings,
  recalculate,
  savedShowActuals,
  updateProjectExpenses
}) =>
  useCallback(
    async ({
      showActuals,
      allowedExpensesAndEstimates,
      approvalStatus,
      currency
    }) => {
      const {
        canEditExpenseCodes,
        canViewExpenseCodes,
        canEditBillingContracts,
        canRecalculateBillingData
      } = projectPermissions;

      if (savedShowActuals !== showActuals) {
        await putExpenseCardSettings({ showActuals });
      }

      if (canEditExpenseCodes) {
        await updateProjectExpenses({
          expenseCodes: allowedExpensesAndEstimates.map(
            ({ expenseType, billableType }) => ({
              ...expenseType,
              expenseEntryType: billableType,
              isExpenseEntryToThisCodeAllowed: true
            })
          )
        });
      }

      if (canViewExpenseCodes || canEditBillingContracts) {
        await bulkPutEstimatedExpenses(
          projectId,
          mapToEstimatedExpensesEntries({
            allowedExpensesAndEstimates,
            projectEndDate,
            currencyId: currency.id
          })
        );
      }

      if (isBillingContractType && canEditBillingContracts) {
        await onBillPlanSave({
          id: planId,
          scripts: mapToExpenseScripts(
            allowedExpensesAndEstimates,
            approvalStatus
          )
        });
      }

      if (isBillingContractType && canRecalculateBillingData) {
        await recalculate();
      }
    },
    [
      projectPermissions,
      savedShowActuals,
      bulkPutEstimatedExpenses,
      projectId,
      projectEndDate,
      isBillingContractType,
      putExpenseCardSettings,
      updateProjectExpenses,
      onBillPlanSave,
      planId,
      recalculate
    ]
  );
