import { ClickAwayListener, IconButton, makeStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpSharp';
import React from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useMenuState } from '~/modules/common/hooks';
import { useProjectRequestObjectPermissions } from '~/modules/project-request/ProjectRequestPage/hooks';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { useMeContext } from '~/modules/me/useMeContext';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    maxWidth: 350,
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'start',
    alignItems: 'baseline'
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    cursor: 'help',
    opacity: 0.3,
    verticalAlign: 'middle'
  },
  helpIconButton: {
    padding: theme.spacing(0, 1)
  }
}));

const usePopperStyles = makeStyles(theme => ({
  popper: {
    transform:
      theme.direction === 'rtl' ? 'translate3d(-55px,315px,0) !important' : null
  }
}));

export const EstimatedCostHelp = ({ hasEstimatedCostOverride }) => {
  const classes = useStyles();
  const popperClasses = usePopperStyles();
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = useMeContext();
  const intl = useIntl();
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const menuOpen = Boolean(anchorEl);

  const { canEditPlanningData } = useProjectRequestObjectPermissions();
  const isReadOnlyWithNoOverride =
    !canEditPlanningData && !hasEstimatedCostOverride;
  const content = intl.formatMessage({
    id: isReadOnlyWithNoOverride
      ? 'projectRequest.helpIconTextReadOnly'
      : 'projectRequest.helpIconText'
  });

  const { handleKeyDown } = useKeyBoardEventHandlers({ onClose: onMenuClose });

  return (
    (canEditPlanningData || isReadOnlyWithNoOverride) && (
      <>
        <ClickAwayListener onClickAway={onMenuClose}>
          <IconButton
            className={classes.helpIconButton}
            onClick={onMenuClick}
            onKeyDown={isPsaPrpAccessibilityIssuesEnabled && handleKeyDown}
            aria-label="help"
            aria-owns={menuOpen ? 'help-action' : undefined}
            aria-haspopup="true"
          >
            <HelpIcon fontSize="small" className={classes.helpIcon} />
          </IconButton>
        </ClickAwayListener>

        <ArrowPopup
          anchorEl={anchorEl}
          id="estimated-cost-help-popup"
          placement="bottom-start"
          onClose={onMenuClose}
          preventOverflow
          classes={popperClasses}
        >
          <div className={classes.root}>{content}</div>
        </ArrowPopup>
      </>
    )
  );
};

EstimatedCostHelp.propTypes = {
  hasEstimatedCostOverride: PropTypes.bool
};
export default EstimatedCostHelp;
