import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { getError, hasError } from '~/util';
import { DateField, Hours } from '~/modules/common/components';
import {
  ResourceUsersDropdown,
  ProjectTeamMemberDropdown,
  RoleDropdown
} from '~/modules/tasks/components';
import { useMeContext } from '~/modules/me';
import { useTotalResourceAvailableDurationForDateRange } from '../hooks/useTotalResourceAvailableDurationForDateRange';
import useAddResourceEstimateDialogChangeHandlers from './useAddResourceEstimateDialogChangeHandlers';
import AddResourceEstimateDialogContentLoading from './AddResourceEstimateDialogContentLoading';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(2)
  },
  checkboxEnabled: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  checkboxDisabled: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2)
  },
  dateContainer: {
    marginBottom: theme.spacing(1)
  },
  dateIcon: {
    marginRight: theme.spacing(-0.875),
    padding: theme.spacing(0.25),
    color: theme.palette.action.active
  },
  numberInputWithValue: {
    marginBottom: theme.spacing(2),
    left: 0,
    right: 'unset',
    transformOrigin: 'left top'
  },
  numberInputWithoutValue: {
    marginBottom: theme.spacing(2),
    right: theme.spacing(3),
    left: 0,
    transformOrigin: 'left top',
    '&.Mui-focused': {
      right: 'unset',
      left: 0,
      transformOrigin: 'left top'
    }
  },
  warning: {
    marginTop: theme.spacing(2)
  }
}));

const getFieldLabels = formatMessage => ({
  role: formatMessage({ id: 'addResourceEstimateDialog.role' }),
  resource: formatMessage({
    id: 'addResourceEstimateDialog.resource'
  }),
  estimatedHours: formatMessage({
    id: 'addResourceEstimateDialog.estimatedHours'
  }),
  allocateResourceTimeCheckBox: formatMessage({
    id: 'addResourceEstimateDialog.allocateResourceTimeCheckBox'
  }),
  startDate: formatMessage({ id: 'addResourceEstimateDialog.startDate' }),
  endDate: formatMessage({ id: 'addResourceEstimateDialog.endDate' })
});

const AddResourceEstimateDialogContent2 = ({ loading, task }) => {
  const { formatMessage } = useIntl();
  const fieldLabels = getFieldLabels(formatMessage);
  const classes = useStyles();
  const { permissionsMap } = useMeContext();
  const canEditCompletedResourceAllocation = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  const {
    id: taskId,
    projectReference: { slug: projectSlug, id: projectId }
  } = task;

  const useDateFieldInputProps = (ariaLabel, className) =>
    useMemo(
      () => ({
        inputProps: {
          'aria-label': ariaLabel
        },
        endAdornment: (
          <InputAdornment className={className} position="end">
            <ArrowDropDown />
          </InputAdornment>
        )
      }),
      [ariaLabel, className]
    );

  const startDateInputProps = useDateFieldInputProps(
    fieldLabels.startDate,
    classes.dateIcon
  );
  const endDateInputProps = useDateFieldInputProps(
    fieldLabels.endDate,
    classes.dateIcon
  );

  const { errors, values, setValues, setFieldValue } = useFormikContext();
  const {
    role,
    resource,
    initialEstimatedHours,
    isCreateTaskAllocationChecked,
    startDate,
    endDate
  } = values;

  const skipAvailableHoursLoading = !(
    isCreateTaskAllocationChecked &&
    resource?.id &&
    startDate &&
    endDate
  );

  const {
    availableDuration,
    loading: availablilityLoading
  } = useTotalResourceAvailableDurationForDateRange({
    resourceUserId: resource?.id,
    startDate,
    endDate,
    skip: skipAvailableHoursLoading
  });

  const showOverAllocationBanner =
    isCreateTaskAllocationChecked && initialEstimatedHours > availableDuration;

  const {
    onRoleChange,
    onAddRoleDialogCancel,
    onInitialEstimatedHoursChange,
    onCreateTaskAllocationChange,
    onStartDateChange,
    onEndDateChange,
    onResourceChange
  } = useAddResourceEstimateDialogChangeHandlers({
    values,
    setValues,
    setFieldValue
  });

  const hasStartDateError = hasError(errors, 'startDate');
  const hasEndDateError = hasError(errors, 'endDate');
  const hasInitialEstimatedHoursError = hasError(
    errors,
    'initialEstimatedHours'
  );

  if (loading)
    return (
      <AddResourceEstimateDialogContentLoading
        canEditCompletedResourceAllocation={canEditCompletedResourceAllocation}
      />
    );

  return (
    <DialogContent>
      <RoleDropdown
        value={role}
        onChange={onRoleChange}
        variant="outlined"
        placeholder={fieldLabels.role}
        projectSlug={projectSlug}
        className={classes.spacing}
        label={fieldLabels.role}
        onAddRoleDialogCancelButtonClick={onAddRoleDialogCancel}
      />
      <Hours
        className={
          initialEstimatedHours
            ? classes.numberInputWithValue
            : classes.numberInputWithoutValue
        }
        variant="outlined"
        data-qe-id="ResourceAssignmentEstimatedHoursField"
        label={fieldLabels.estimatedHours}
        ariaLabel={fieldLabels.estimatedHours}
        margin="none"
        isEditible
        align="left"
        onChange={onInitialEstimatedHoursChange}
        value={initialEstimatedHours}
        precision={2}
        error={hasInitialEstimatedHoursError}
        helperText={getError(errors, 'initialEstimatedHours')}
      />
      {canEditCompletedResourceAllocation && (
        <>
          <FormControlLabel
            className={
              isCreateTaskAllocationChecked
                ? classes.checkboxEnabled
                : classes.checkboxDisabled
            }
            control={
              <Checkbox
                data-qe-id="AllocateResourceTimeToTaskCheckbox"
                checked={isCreateTaskAllocationChecked}
                onChange={onCreateTaskAllocationChange}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {fieldLabels.allocateResourceTimeCheckBox}
              </Typography>
            }
            labelPlacement="end"
          />
          {isCreateTaskAllocationChecked && (
            <Grid container spacing={2} className={classes.dateContainer}>
              <Grid item xs={6}>
                <DateField
                  required
                  data-qe-id="ResourceAssignmentStartDateField"
                  editable
                  label={fieldLabels.startDate}
                  value={startDate}
                  error={hasStartDateError}
                  helperText={getError(errors, 'startDate')}
                  onChange={onStartDateChange}
                  variant="outlined"
                  InputProps={startDateInputProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DateField
                  required
                  data-qe-id="ResourceAssignmentEndDateField"
                  editable
                  label={fieldLabels.endDate}
                  value={endDate}
                  error={hasEndDateError}
                  helperText={getError(errors, 'endDate')}
                  onChange={onEndDateChange}
                  variant="outlined"
                  InputProps={endDateInputProps}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}

      {canEditCompletedResourceAllocation ? (
        <ResourceUsersDropdown
          disabled={
            hasStartDateError ||
            hasEndDateError ||
            hasInitialEstimatedHoursError
          }
          assignedRole={role}
          onResourceChange={onResourceChange}
          projectId={projectId}
          value={resource}
          selectedResources={[resource]}
          placeholder={fieldLabels.resource}
          ariaLabel={fieldLabels.resource}
          variant="outlined"
          label={fieldLabels.resource}
          taskId={taskId}
          startDate={startDate}
          endDate={endDate}
          initialEstimatedHours={initialEstimatedHours}
          hideAvailability={!isCreateTaskAllocationChecked}
        />
      ) : (
        <ProjectTeamMemberDropdown
          assignedRole={role}
          onResourceChange={onResourceChange}
          projectSlug={projectSlug}
          value={resource}
          selectedResources={[resource]}
          placeholder={fieldLabels.resource}
          ariaLabel={fieldLabels.resource}
          variant="outlined"
          label={fieldLabels.resource}
          taskId={taskId}
        />
      )}

      {availablilityLoading ? (
        <Skeleton height={70} width="100%" />
      ) : (
        showOverAllocationBanner && (
          <Alert severity="warning" icon={false} className={classes.warning}>
            <FormattedMessage id="addResourceEstimateDialog.messages.noEnoughAvailableHoursWarning" />
          </Alert>
        )
      )}
    </DialogContent>
  );
};

AddResourceEstimateDialogContent2.propTypes = {
  loading: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired
};

export default AddResourceEstimateDialogContent2;
