/* eslint-disable max-nested-callbacks */
import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from '@material-ui/core';
import { ExtensionField } from '~/modules/extensionFields/components';
import {
  FILE_TYPE_URI,
  NUMERIC_TYPE_URI,
  TAG_TYPE_URI,
  TEXT_TYPE_URI
} from '~/modules/extensionFields/definitionTypes';

export const handleExtensionFieldValueChange = ({
  extensionFieldValues,
  definition,
  onExtensionFieldsChange
}) => (event, value) => {
  switch (definition.definitionTypeUri) {
    case TEXT_TYPE_URI:
      onExtensionFieldsChange(
        extensionFieldValues.map(e => {
          if (e.definition.id === definition.id) {
            e.textValue = event.target.value;
          }

          return e;
        })
      );
      break;
    case NUMERIC_TYPE_URI:
      onExtensionFieldsChange(
        extensionFieldValues.map(e => {
          if (e.definition.id === definition.id) {
            e.numericValue = event.target.value;
          }

          return e;
        })
      );
      break;
    case FILE_TYPE_URI:
      onExtensionFieldsChange(
        extensionFieldValues.map(e => {
          if (e.definition.id === definition.id) {
            e.fileValue = value;
          }

          return e;
        })
      );
      break;
    case TAG_TYPE_URI:
      onExtensionFieldsChange(
        extensionFieldValues.map(e => {
          if (e.definition.id === definition.id) {
            e.tag = value || null;
          }

          return e;
        })
      );
      break;
    default:
      break;
  }
};

export const TaskExtensionFields = ({
  extensionFieldValues,
  onExtensionFieldsChange,
  variant = 'filled',
  align
}) => {
  const [definitionId, setDefinitionId] = useState('');

  const extensionFields = useMemo(
    () =>
      extensionFieldValues.map(extensionFieldValue => ({
        extensionFieldValue,
        onChange: handleExtensionFieldValueChange({
          // to do: oef is dynamic, array set goes by ref, isDirty will be false
          extensionFieldValues: [
            ...extensionFieldValues.map(e => ({
              ...e
            }))
          ],
          onExtensionFieldsChange,
          definition: extensionFieldValue.definition
        })
      })),
    [extensionFieldValues, onExtensionFieldsChange]
  );

  return extensionFields.map(({ extensionFieldValue, onChange }) => (
    <Grid key={extensionFieldValue.definition.id} item xs={12} sm={6}>
      <ExtensionField
        variant={variant}
        editable
        extensionField={extensionFieldValue}
        onChange={onChange}
        definitionId={definitionId}
        setDefinitionId={setDefinitionId}
        align={align}
      />
    </Grid>
  ));
};

TaskExtensionFields.propTypes = {
  extensionFieldValues: PropTypes.array,
  onExtensionFieldsChange: PropTypes.func,
  align: PropTypes.string,
  variant: PropTypes.string
};

export default TaskExtensionFields;
