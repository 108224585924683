export const useChangeHandlers = ({ projectLinks, setFieldValue }) => {
  const onLinkTypeChange = (linkType, index) => {
    setFieldValue(`projectLinks[${index}].linkType`, linkType);
  };

  const onProjectChange = (project, index) => {
    setFieldValue(`projectLinks[${index}].targetProject`, project);
  };

  const onProjectLinkAdd = projectLink => {
    setFieldValue('projectLinks', [...projectLinks, projectLink]);
  };

  const onProjectLinkRemove = projectLink => {
    const links = projectLinks.filter(l => l.id !== projectLink.id);

    setFieldValue('projectLinks', links);
  };

  return {
    onLinkTypeChange,
    onProjectChange,
    onProjectLinkAdd,
    onProjectLinkRemove
  };
};

export default useChangeHandlers;
