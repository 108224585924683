import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    ...theme.typography.subtitle1,
    paddingTop: theme.spacing(2)
  },
  inputAdornment: {
    paddingLeft: theme.spacing(0.5),
    margin: 0
  },
  taskAllocationGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  error: {
    color: theme.palette.error.main
  },
  diff: {
    display: 'flex',
    alignItems: 'center'
  },
  taskAllocation: {
    display: 'flex',
    justifyContent: 'end'
  },
  hoursLabel: {
    textAlign: 'right',
    paddingRight: '14px'
  },
  label: {
    color: theme.palette.text.primary
  },
  selectDatesPrompt: {
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(20)
  },
  noValue: {
    display: 'flex',
    paddingRight: theme.spacing(1.75),
    justifyContent: 'end'
  }
}));

const useChipStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    height: theme.spacing(2.5),
    borderRadius: theme.spacing(2)
  },
  label: {
    padding: theme.spacing(0, 1)
  },
  colorPrimary: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.allocationChartStatus.overAllocated.main
  }
}));

const useAllocatedHoursStyles = makeStyles(theme => ({
  input: {
    fontSize: theme.typography.body2.fontSize
  }
}));

export { useStyles, useChipStyles, useAllocatedHoursStyles };
