import { object, string } from 'yup';

import { STRING_MAX_LENGTH, exceededMaxLengthMessage } from './common';

export const billingContactInfoValidationSchema = object().shape({
  billingContactInfo: object().shape({
    address: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    city: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    stateProvince: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage),
    zipPostalCode: string().max(STRING_MAX_LENGTH, exceededMaxLengthMessage)
  })
});
