import { makeStyles, Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-1)
  }
}));

export const RowSelectorFormatter = ({
  record,
  column: {
    vendorResourcesStates: { isRowSelected, onSelectClick }
  }
}) => {
  const classes = useStyles();
  const isSelected = useMemo(() => isRowSelected(record), [
    isRowSelected,
    record
  ]);

  return (
    <Radio
      checked={isSelected}
      onChange={onSelectClick(record)}
      color="primary"
      value={record}
      disableRipple
      className={classes.root}
    />
  );
};

RowSelectorFormatter.propTypes = {
  record: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};

export default RowSelectorFormatter;
