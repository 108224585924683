import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { CustomFieldValueText } from '~/modules/customFields/components';
import { hasValue } from '~/modules/customFields/utils';
import ClientInfoDetail from './ClientInfoDetail';

export const noAdditionalInfoText = (
  <Typography color="textSecondary" variant="caption">
    <FormattedMessage id="client.noAdditionalInfo" />
  </Typography>
);

export const ClientInfoAdditionalSection = ({ details }) => {
  const customFieldValues = get(details, 'customFieldValues') || [];

  const hasCustomFieldValues =
    customFieldValues &&
    customFieldValues.length > 0 &&
    customFieldValues.some(hasValue);

  return hasCustomFieldValues ? (
    customFieldValues.map(c => (
      <ClientInfoDetail
        key={c.customField.id}
        label={c.customField.displayText}
        detail={<CustomFieldValueText customFieldValue={c} />}
      />
    ))
  ) : (
    <ClientInfoDetail detail={noAdditionalInfoText} />
  );
};

ClientInfoAdditionalSection.propTypes = {
  details: PropTypes.object
};

export default ClientInfoAdditionalSection;
