import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';

export const getProgramManagersQuery = gql`
  query getProgramManagers($programUri: String!) {
    getProgramManagers(programUri: $programUri) {
      displayText
      id
    }
  }
`;

export const useProgramManagers = ({ programId = '' }) => {
  const { loading, data, error } = useQuery(getProgramManagersQuery, {
    variables: {
      programUri: programId
    }
  });

  return {
    programManagers: get(data, 'getProgramManagers', []),
    loading,
    error
  };
};

export default useProgramManagers;
