import get from 'lodash.get';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import map from 'lodash/fp/map';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {
  billingOverviewDetails,
  mapScaleToPeriodResolution
} from '~/modules/billing-invoicing/common/components/BillingInvoicingTab/enhancers';
import { isoToReplicon } from '~/util/date';
import useClientBillPlanOverview from './useClientBillPlanOverview';

export const GET_CLIENT_BILLING_OVERVIEW = gql`
  query getClientBillingOverview($slug: String!, $periodResolutionUri: String!, $dateRange: DateRangeInput2) {
    client(slug: $slug) {
      id
      ${billingOverviewDetails}
    }
  }
`;

const formatPeriod = point => {
  if (!point) {
    return point;
  }

  return {
    ...point,
    period: point.period
      ? {
          periodStart: isoToReplicon(point.period.periodStart),
          periodEnd: isoToReplicon(point.period.periodEnd)
        }
      : point.period
  };
};

const formatBillingHour = name =>
  update(name, update('series', map(formatPeriod)));

const formatBillingAmount = name =>
  update(name, update('series', update('dataPoints', map(formatPeriod))));

const formatBillingOverview = flow(
  update(
    'availableToBill',
    flow(
      formatBillingHour('nonBillableHours'),
      formatBillingHour('billableHours'),
      formatBillingAmount('amountTotal')
    )
  ),
  update(
    'estimatedBilling',
    flow(formatBillingHour('totalHours'), formatBillingAmount('amountTotal'))
  ),
  update(
    'billingTransactionActuals',
    flow(
      formatBillingAmount('outstanding'),
      formatBillingAmount('payment'),
      formatBillingAmount('creditMemo'),
      formatBillingAmount('billingItemBillings'),
      formatBillingAmount('adhocInvoiceItemBillings'),
      formatBillingAmount('invoiceItemOverrides')
    )
  )
);

export const useClientBillingOverview = ({
  dateRange,
  scale,
  client,
  client: { slug }
}) => {
  const { data, loading } = useQuery(GET_CLIENT_BILLING_OVERVIEW, {
    variables: {
      slug,
      dateRange: {
        startDate: dateRange.startDate.toISODate(),
        endDate: dateRange.endDate.toISODate()
      },
      periodResolutionUri: mapScaleToPeriodResolution(scale)
    },
    context: {
      debounceKey: 'clientBillingOverview',
      debounceTimeout: 50
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const billingOverview = get(data, 'client.billingOverview') || {};

  const formattedBillingOverview = formatBillingOverview(billingOverview);

  const billPlanEstimates = useClientBillPlanOverview({
    client,
    dateRange,
    scale,
    overviewMode: 'ESTIMATED'
  });

  const billPlanAvailableToBill = useClientBillPlanOverview({
    client,
    dateRange,
    scale,
    overviewMode: 'AVAILABLE_TO_BILL'
  });

  const result = {
    ...formattedBillingOverview,
    availableToBill: {
      ...formattedBillingOverview.availableToBill,
      ...billPlanAvailableToBill.availableToBill,
      loading: billPlanAvailableToBill.loading
    },
    estimatedBilling: {
      ...formattedBillingOverview.estimatedBilling,
      ...billPlanEstimates.estimatedBilling,
      loading: billPlanEstimates.loading
    }
  };

  return { loading, result };
};

export default useClientBillingOverview;
