import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import GenericProjectFacetDetails from '../GenericProjectFacetDetails';
import { onChange } from '../../enhancers/facetHelpers';
import { useProjectsForBillingItems } from './useProjectOptionsForBillingItems';

export const BillingProjectFacetDetails = ({
  selected,
  setSelected,
  clientUri
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onValueChange = useCallback(
    selectedOptions => onChange({ setSelected, selectedOptions }),
    [setSelected]
  );

  const { loading, hasMore, projects } = useProjectsForBillingItems({
    searchTerm,
    clientUri
  });

  return (
    <GenericProjectFacetDetails
      selected={selected}
      loading={loading}
      options={projects}
      hasMore={hasMore}
      onChange={onValueChange}
      setSearchTerm={setSearchTerm}
    />
  );
};

BillingProjectFacetDetails.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  clientUri: PropTypes.string
};

export const withBillingProjectFacetDetails = ({ clientUri }) => ({
  selected,
  setSelected
}) => (
  <BillingProjectFacetDetails
    selected={selected}
    setSelected={setSelected}
    clientUri={clientUri}
  />
);

export default withBillingProjectFacetDetails;
