import React, { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { DialogActions, Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { EVENT_TYPE } from '~/modules/common/enums/EventTypes';
import { useOnSubmit } from '../hooks/useOnChangeHandlers';
import LoadingButton from '../../../LoadingButton';
import { DEFAULT_PAGE_SIZE } from '../hooks/useTaskResourceEstimates';

const getResourceKey = ({ isEditMode, isPlaceholder }) => {
  if (isEditMode) {
    return isPlaceholder
      ? 'editResourceEstimateDialog.savePlaceholder'
      : 'editResourceEstimateDialog.setResource';
  }

  return isPlaceholder
    ? 'addResourceEstimateDialog.addPlaceholder'
    : 'addResourceEstimateDialog.addResource';
};

export const InnerDialogActions = ({
  classes,
  disabled,
  loading,
  isPlaceholder,
  onClose,
  onSubmit,
  isEditMode
}) => {
  const { formatMessage } = useIntl();

  return (
    <DialogActions className={classes.actions}>
      <Button onClick={onClose} variant="contained">
        {formatMessage({ id: 'button.cancel' })}
      </Button>
      <LoadingButton
        color="primary"
        onClick={onSubmit}
        variant="contained"
        disabled={disabled}
        isLoading={loading}
      >
        {formatMessage({ id: getResourceKey({ isEditMode, isPlaceholder }) })}
      </LoadingButton>
    </DialogActions>
  );
};

InnerDialogActions.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool
};

const useStyles = makeStyles(theme => ({
  actions: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2)
  }
}));

const AddResourceEstimateDialogFooter = ({
  taskId,
  role,
  resource,
  onClose
}) => {
  const classes = useStyles();

  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  const [loading, setLoading] = useState(false);

  const isPlaceholder = role && !resource;

  const { onResourceEstimateSubmit } = useOnSubmit({
    taskId
  });

  const onSubmit = useCallback(async () => {
    setLoading(true);

    const {
      taskResourceEstimate,
      loading: submitLoading
    } = await onResourceEstimateSubmit(
      {
        resourceUserId: resource?.id,
        projectRoleId: role?.id
      },
      [
        {
          eventName: EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
          payload: {
            taskId,
            updateResourceEstimatesCountBy: 1,
            updateResourceEstimatesWithUsersCountBy: resource ? 1 : 0
          }
        }
      ]
    );

    setLoading(submitLoading);

    const updatedResourceEstimates = [
      {
        ...taskResourceEstimate,
        resourceUser: resource,
        projectRole: role
      },
      ...resourceEstimates
    ];

    setFieldValue(
      'resourceEstimates',
      updatedResourceEstimates?.length > DEFAULT_PAGE_SIZE
        ? updatedResourceEstimates.slice(0, DEFAULT_PAGE_SIZE)
        : updatedResourceEstimates
    );
    onClose();
  }, [
    onClose,
    onResourceEstimateSubmit,
    resource,
    resourceEstimates,
    role,
    setFieldValue,
    taskId
  ]);

  return (
    <InnerDialogActions
      classes={classes}
      disabled={!role && !resource}
      loading={loading}
      isPlaceholder={isPlaceholder}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

AddResourceEstimateDialogFooter.propTypes = {
  taskId: PropTypes.string,
  role: PropTypes.object,
  resource: PropTypes.object,
  onClose: PropTypes.func
};

export default AddResourceEstimateDialogFooter;
