import MonthFormatter from './MonthFormatter';
import StringFormatter from './StringFormatter';
import MoneyFormatter from './MoneyFormatter';
import PercentageFormatter from './PercentageFormatter';
import NumberFormatter from './NumberFormatter';
import RecognizableRevenueFormatter from './RecognizableRevenueFormatter';

const typeFormatters = {
  money: MoneyFormatter,
  dateRange: MonthFormatter,
  text: StringFormatter,
  number: NumberFormatter,
  numeric: StringFormatter,
  percentage: PercentageFormatter
};

export const formatters = ({ headers }) => {
  return headers.reduce(
    (retVal, current) => ({
      ...retVal,
      [current.name]:
        current.name === 'recognizableRevenue'
          ? RecognizableRevenueFormatter
          : typeFormatters[current.type]
    }),
    {}
  );
};
