import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: theme.spacing(2)
  }
}));

export const OfflineDescription = ({ width }) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        component="p"
        variant={isWidthUp('sm', width) ? 'h5' : 'body1'}
        className={classes.message}
      >
        <FormattedMessage id="offlinePage.description" />
      </Typography>
    </>
  );
};

OfflineDescription.propTypes = {
  width: PropTypes.string.isRequired
};

export default withWidth()(OfflineDescription);
