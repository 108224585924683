import React from 'react';
import { retryLoading } from '~/util';
import { getRoutePermissions } from '~/modules/common/permissions';
import { VendorsIcon } from './components/VendorsIcon';

const VendorPage = React.lazy(() =>
  retryLoading(() => import('./VendorsPage/VendorsPage'))
);

export const vendorRoute = ({ intl, me }) => {
  const { canViewProjectRequests, canViewVendors } = getRoutePermissions(me);

  return {
    path: '/vendors',
    title: () => intl.formatMessage({ id: 'routes.vendors' }),
    showDivider: !canViewProjectRequests,
    isSideBarRoute: true,
    component: VendorPage,
    icon: () => <VendorsIcon />,
    hasPermission: canViewVendors,
    hidden: !canViewVendors,
    dataQeId: 'Vendors'
  };
};

export default vendorRoute;
