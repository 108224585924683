import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddSharp';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(3, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
  },
  addIcon: {
    position: 'relative',
    top: 6
  }
}));

export const NoLinksData = () => {
  const classes = useStyles();

  const values = useMemo(
    () => ({
      value: (
        <AddIcon
          className={classes.addIcon}
          color="inherit"
          aria-hidden="false"
          aria-label="add"
        />
      )
    }),
    [classes.addIcon]
  );

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">
        <FormattedMessage id="projectLink.noMatchingLinks" />
      </Typography>
      <FormattedMessage id="projectLink.clickToAddLink" values={values} />
    </div>
  );
};

NoLinksData.propTypes = {};

export default NoLinksData;
