import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import Date from '~/modules/common/components/Date';
import { NoValue } from '~/modules/common/components';

import {
  TEXT_TYPE_URI,
  NUMERIC_TYPE_URI,
  DATE_TYPE_URI,
  DROP_DOWN_TYPE_URI
} from '~/modules/customFields/definitionTypes';

export const ReadOnlyCustomFieldValue = ({ definition, value }) => {
  switch (definition.type.uri) {
    case TEXT_TYPE_URI:
    case NUMERIC_TYPE_URI:
      return <Typography variant="body2">{value || <NoValue />}</Typography>;
    case DATE_TYPE_URI:
      return <Date value={value} />;
    case DROP_DOWN_TYPE_URI:
      return (
        <Typography variant="body2">
          {
            (
              definition.dropDownOptions.find(
                option => option.uri === value
              ) || { displayText: <NoValue /> }
            ).displayText
          }
        </Typography>
      );
    default:
      return <FormattedMessage id="formCustomField.notSupported" />;
  }
};

ReadOnlyCustomFieldValue.propTypes = {
  definition: PropTypes.object,
  value: PropTypes.any
};

export default ReadOnlyCustomFieldValue;
