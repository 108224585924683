import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Grid, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(-2)
  },
  skeleton: {
    marginBottom: theme.spacing(-1),
    height: theme.spacing(8),
    width: '100%'
  },
  skeletonWithPermissionEnabled: {
    height: theme.spacing(8.5),
    width: '100%'
  },
  dateRange: {
    display: 'flex',
    columnGap: theme.spacing(2)
  }
}));

export const AddResourceEstimateDialogContentLoading = ({
  canEditCompletedResourceAllocation
}) => {
  const classes = useStyles();
  const className = canEditCompletedResourceAllocation
    ? classes.skeletonWithPermissionEnabled
    : classes.skeleton;

  return (
    <DialogContent className={classes.spacing}>
      <Skeleton className={className} />
      <Skeleton className={className} />
      {canEditCompletedResourceAllocation && (
        <Grid className={classes.dateRange}>
          <Skeleton className={className} />
          <Skeleton className={className} />
        </Grid>
      )}
      <Skeleton className={className} />
    </DialogContent>
  );
};

AddResourceEstimateDialogContentLoading.propTypes = {
  canEditCompletedResourceAllocation: PropTypes.bool.isRequired
};

export default AddResourceEstimateDialogContentLoading;
