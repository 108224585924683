import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

const openTaskMutation = gql`
  mutation open($taskId: String!) {
    openTask: openTask2(input: { taskId: $taskId }) {
      task {
        id
        isClosed
        taskStatus
        isClosedByTaskInheritance
      }
    }
  }
`;

const closeTaskMutation = gql`
  mutation close($taskId: String!) {
    closeTask: closeTask2(input: { taskId: $taskId }) {
      task {
        id
        isClosed
        taskStatus
        isClosedByTaskInheritance
        closedOnDate: closedOnDate2
        effectiveClosedOnDate: effectiveClosedOnDate2
      }
    }
  }
`;

export default task => {
  const [closeTaskMutationFunc] = useMutation(closeTaskMutation);
  const closeTask = useCallback(
    ({ taskId }) => {
      closeTaskMutationFunc({
        variables: {
          taskId
        },
        refetchQueries: ['projectTasksQuery'],
        awaitRefetchQueries: true
      });
    },
    [closeTaskMutation]
  );
  const [openTaskMutationFunc] = useMutation(openTaskMutation);
  const openTask = useCallback(
    ({ taskId }) => {
      openTaskMutationFunc({
        variables: {
          taskId
        },
        refetchQueries: ['projectTasksQuery'],
        awaitRefetchQueries: true
      });
    },
    [openTaskMutation]
  );

  const [openCloseExpand, setOpenCloseExpand] = useState(false);

  const onOpenCloseExpand = useCallback(() => {
    setOpenCloseExpand(!openCloseExpand);
  }, [setOpenCloseExpand, openCloseExpand]);

  const openCloseSelection = useCallback(async event => {
    const option = event.target.value;

    if (option === 1 && !task.isClosed) {
      await closeTask({ taskId: task.id });
    } else if (option === 2 && task.isClosed) {
      await openTask({ taskId: task.id });
    }
  });

  return {
    openCloseSelection,
    openCloseExpand,
    onOpenCloseExpand
  };
};
