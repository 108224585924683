import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  getTotalForActualExpense,
  getTotalForEstimatedExpense
} from '../AllowedExpensesAndEstimates/hooks';
import { ExpenseSummaryItem } from './ExpenseSummaryItem';

export const ExpenseSummary = ({ expenseBudgetedCost }) => {
  const { values } = useFormikContext();

  const estimatedAmount = useMemo(() => {
    const val = values.allowedExpensesAndEstimates.reduce(
      (retVal, { totalEstimates, billableType }) =>
        retVal + getTotalForEstimatedExpense(totalEstimates, billableType),
      0
    );

    return val ? { amount: val, currency: values.currency } : null;
  }, [values.allowedExpensesAndEstimates, values.currency]);

  const actualAmount = useMemo(() => {
    const val = values.allowedExpensesAndEstimates.reduce(
      (retVal, { totalEstimates, billableType }) =>
        retVal + getTotalForActualExpense(totalEstimates, billableType),
      0
    );

    return val ? { amount: val, currency: values.currency } : null;
  }, [values.allowedExpensesAndEstimates, values.currency]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={4}>
        <ExpenseSummaryItem
          amount={expenseBudgetedCost}
          dataQeId="BudgetExpenseCost"
          labelId="projectTimeAndExpenseCard.budgetExpenseCost"
        />
      </Grid>
      <Grid item xs={4}>
        <ExpenseSummaryItem
          amount={estimatedAmount}
          dataQeId="EstimatedAmount"
          labelId="projectTimeAndExpenseCard.estimatedAmount"
        />
      </Grid>
      <Grid item xs={4}>
        <ExpenseSummaryItem
          amount={actualAmount}
          dataQeId="ActualAmount"
          labelId="projectTimeAndExpenseCard.actualAmount"
        />
      </Grid>
    </Grid>
  );
};

ExpenseSummary.propTypes = {
  expenseBudgetedCost: PropTypes.object
};
