import { object, number } from 'yup';
import { useIntl } from 'react-intl';
import { useFormikContext as useDrawerFormikContext } from 'formik';
import { useMeContext } from '~/modules/me/useMeContext';
import { toRepliconDate } from '~/modules/common/dates/convert';
import {
  getInitialEstimatedHoursForTaskAllocation,
  getProjectAvailableHours
} from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor/common/taskAllocationEditorUtil';
import useOnSubmit from '../../common/useOnSubmit';

export const getInitialValues = ({
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  initialEstimatedHours,
  taskResourceUserAllocation,
  resourceAvailabilityHours
}) => {
  const projectAvailableHours = resourceAllocationScheduleRules
    ? getProjectAvailableHours({
        start: startDate,
        end: endDate,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules
      })
    : 0;

  const allocatedHours = taskResourceUserAllocation
    ? taskResourceUserAllocation.totalHours
    : getInitialEstimatedHoursForTaskAllocation({
        initialEstimatedHours,
        projectAvailableHours
      });

  return {
    startDate: startDate ? toRepliconDate(startDate) : null,
    endDate: endDate ? toRepliconDate(endDate) : null,
    projectAvailableHours,
    allocatedHours: startDate && endDate ? allocatedHours : null,
    resourceAvailabilityHours
  };
};

export const buildValidationSchema = ({ formatMessage }) =>
  object().shape({
    startDate: object()
      .nullable()
      .required(
        formatMessage({
          id: 'taskAllocationEditor.pleaseSelectStartDate'
        })
      ),
    endDate: object()
      .nullable()
      .required(
        formatMessage({
          id: 'taskAllocationEditor.pleaseSelectEndDate'
        })
      ),
    allocatedHours: number()
      .required()
      .moreThan(0),
    resourceAvailabilityHours: number().required()
  });

const useFormState = ({
  startDate,
  endDate,
  otherTaskAllocationsSummary,
  resourceAllocation,
  initialEstimatedHours,
  taskResourceUserAllocation,
  resourceAvailabilityHours,
  taskId,
  userId,
  roleId,
  resourceEstimateId,
  projectId,
  onClose
}) => {
  const { formatMessage } = useIntl();
  const {
    tenantSlug,
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();

  const { scheduleRules: resourceAllocationScheduleRules } =
    resourceAllocation || {};

  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;

  const {
    setFieldValue,
    values: { resourceEstimates }
  } = useDrawerFormikContext();

  const handleSubmit = useOnSubmit({
    setFieldValue,
    resourceEstimates,
    isRmpTaskAllocationPhase2Enabled
  });

  const onSubmit = async (values, { setSubmitting }) => {
    await handleSubmit({
      setSubmitting,
      projectId,
      taskId,
      userId,
      resourceEstimateId,
      tenantSlug,
      roleId,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      values,
      taskResourceUserAllocation
    });

    onClose();
  };

  return {
    onSubmit,
    initialValues: getInitialValues({
      startDate,
      endDate,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      initialEstimatedHours,
      taskResourceUserAllocation,
      resourceAvailabilityHours
    }),
    validationSchema: buildValidationSchema({ formatMessage })
  };
};

export default useFormState;
